import { Modal } from 'components/newModal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddBlockModalForm } from 'pages/s05-01/type';
import { ErrorModal } from 'components/errorModal';
import { AddReason } from './addReason';
import { Confirm } from './cofirm';
import { Complete } from './complete';

enum ModalStep {
  ADD_REASONS = 1,
  CONFIRM = 2,
  COMPLETE = 3,
}

export function AddBlockModal(props: {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  name: string;
  reasonOptions: { label: string; value: string }[];
}) {
  const { isOpen, onClose, refetch, name, reasonOptions } = props;

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.ADD_REASONS);

  const [isError, setIsError] = useState<boolean>(false);
  const handleErrorHappen = () => setIsError(true);

  const { control, trigger, reset, getValues } = useForm<AddBlockModalForm>();

  const onModalClose = () => {
    onClose();
    reset();
    setModalStep(ModalStep.ADD_REASONS);
    refetch();
  };

  const addReasonContent = AddReason(control, trigger, reasonOptions, onModalClose, () =>
    setModalStep(ModalStep.CONFIRM)
  );

  const confirmContent = Confirm(
    name,
    getValues,
    onModalClose,
    () => setModalStep(ModalStep.ADD_REASONS),
    () => setModalStep(ModalStep.COMPLETE),
    handleErrorHappen
  );

  const completeContent = Complete(onModalClose);

  const modalContent = {
    [ModalStep.ADD_REASONS]: addReasonContent,
    [ModalStep.CONFIRM]: confirmContent,
    [ModalStep.COMPLETE]: completeContent,
  };

  return isError ? (
    <ErrorModal isOpen={isError} onClose={() => setIsError(false)} title="エラー" message="ブロックできませんでした" />
  ) : (
    <Modal isOpen={isOpen} onClose={onModalClose} overflow="visible" {...modalContent[modalStep]} />
  );
}
