import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { AddBlockModalForm, ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ResultRows } from 'components/const';

export function Confirm(
  name: string,
  getValues: UseFormGetValues<AddBlockModalForm>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void,
  onErrorHappen: () => void
): ModalRet {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const [addTBtmemBlockMutation] = gql.useAddTBtmemBlockMutation({
    variables: {
      block_id: btmemId,
      id: tpmemId,
      reason: `${getValues('reason')} ${getValues('reason_other') ?? ''}`,
    },
  });

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック 確認
      </Text>
    ),
    content: !isMobile ? (
      <Box display="flex" height="100%" flexDirection="column" flex="1" gap={16} pa={8}>
        <Text variant="body14">以下のバイトラベルをブロックします。よろしいですか？</Text>
        <Text variant="body14">・{name}</Text>
        <Divider option="horizontal" />
        <Text variant="body14">ブロック理由（正当な理由がない場合、運営がブロックを解除する場合があります）</Text>
        <Box display="flex" flexDirection="column">
          <Text variant="body14">・{getValues('reason')}</Text>
          {getValues('reason') === 'その他' && <Text variant="body14">{getValues('reason_other')}</Text>}
        </Box>
      </Box>
    ): (
      /* feat_screen_05-01-01-06_start */
      <Box display="flex" height="100%" flexDirection="column" flex="1" gap={12}>
        <Text variant="body14">以下のバイトラベルをブロックします。よろしいですか？</Text>
        <Text variant="body14">・{name}</Text>
        <Divider option="horizontal" />
        <Text variant="body14">ブロック理由（正当な理由がない場合、運営がブロックを解除する場合があります）</Text>
        <Box display="flex" flexDirection="column" gap={12}  overflow="scroll" maxHeight={50}>
          <Text variant="body14">・{getValues('reason')}</Text>
          {getValues('reason') === 'その他' && <Text variant="body14">{getValues('reason_other')}</Text>}
        </Box>
      </Box>
      /* feat_screen_05-01-01-06_end */
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          variant="warn"
          onClick={() => {
            addTBtmemBlockMutation()
              .then((result) => {
                if (result.data?.addTBtmemBlock?.resultRows === ResultRows.UPDATED) {
                  onNext();
                } else {
                  onClose();
                  onErrorHappen();
                }
              })
              .catch(() => {
                onClose();
                onErrorHappen();
              });
          }}
        >
          ブロック
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_05-01-01-06_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={96} 
          customHeight={32}
          thin
          variant="warn"
          onClick={() => {
            addTBtmemBlockMutation()
              .then((result) => {
                if (result.data?.addTBtmemBlock?.resultRows === ResultRows.UPDATED) {
                  onNext();
                } else {
                  onClose();
                  onErrorHappen();
                }
              })
              .catch(() => {
                onClose();
                onErrorHappen();
              });
          }}
        >
          ブロック
        </MainButton>
      </Box>
      /* feat_screen_05-01-01-06_end */
    ),
    /* feat_screen_05-01-01-06_start */
    width: !isMobile ? 800 : "100%",
    height: !isMobile ? 344 : 355,
    /* feat_screen_05-01-01-06_end */
  };

  return modalContent;
}
