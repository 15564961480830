import { MainMenu } from 'components/mainMenuBar/types';

export const PausedMemberStoreMainMenu: MainMenu[] = [
  {
    pageNo: 4,
    title: 'ウォレット',
    icon: { normal: 'payment', selected: 'payment' },
    link: '/s07-01',
    matchedRoutes: ['/s07-01'],
  },
  {
    pageNo: 6,
    title: 'マイページ',
    icon: { normal: 'store', selected: 'store' },
    link: '/s09',
    matchedRoutes: ['/s09', '/'],
  },
];
