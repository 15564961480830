import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  children: ReactNode;
  htmlFor?: string;
  width?: number;
  fullWidth?: boolean;
  optional?: boolean;
  required?: boolean;
  subLabel?: string;
  subLabelHref?: string;
}

const StyledDiv = styled.div<{ width: number; fullWidth?: boolean }>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};

  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label``;

const StyledOptional = styled.div`
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    height: 24px;
    width: 46px;
  }
  /* feat screen_01-01-02_end */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.OK_BLUE};
  border-radius: 2px;
`;
const StyledRequired = styled.div`
  /* feat screen_01-01-06_01_start */
  @media only screen and (max-width: 768px) {
    height: 24px;
    font-size: 14px;
    padding: 0 8px;
  }
  /* feat screen_01-01-06_01_end */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.CAUTION_RED};
  border-radius: 2px;
`;
const StyledSubLabel = styled.a`
  /* feat screen_01-01-04_start */
  @media only screen and (max-width: 768px) {
    height: 24px;
    font-size: 14px;
    padding: 0 8px;
  }
  /* feat screen_01-01-04_end */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.OK_BLUE};
  border-radius: 2px;
  cursor: pointer;
`;

export function InputLabel({
  children,
  htmlFor,
  width = 328,
  optional,
  subLabel,
  fullWidth,
  required,
  subLabelHref,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  return (
    <StyledDiv width={width} fullWidth={fullWidth}>
      <StyledLabel htmlFor={htmlFor}>
        <Text variant="body14" bold color="blueGray">
          {children}
        </Text>
      </StyledLabel>
      {required && (
        <StyledRequired>
          {/* feat screen 01-01-04 start */}
          <Text variant={!isMobile ? "caption11" : "body14"} color="cautionRed">
          {/* feat screen 01-01-04 end */}
            必須
          </Text>
        </StyledRequired>
      )}
      {optional && (
        <StyledOptional>
          {/* feat screen 01-01-04 start */}
          <Text variant={!isMobile ? "caption11" : "body14"} color="okBlue">
          {/* feat screen 01-01-04 end */}
            任意
          </Text>
        </StyledOptional>
      )}
      {subLabel && (
        <StyledSubLabel href={subLabelHref} target="_blank" rel="noopener noreferrer">
          {/* feat screen 01-01-04 start */}
          <Text variant={!isMobile ? "caption11" : "body14"} color="okBlue">
          {/* feat screen 01-01-04 end */}
            {subLabel}
          </Text>
        </StyledSubLabel>
      )}
    </StyledDiv>
  );
}
