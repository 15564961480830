/**
 * Figma ID: 08-07-04
 * 名称: 店舗ブロック
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { Control, FieldErrors } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import * as gql from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import type { AddBlockInfoModel } from '.';

type InputReasonContentModel = {
  control: Control<AddBlockInfoModel>;
  selectedReason?: string;
  errors: FieldErrors<AddBlockInfoModel>;
};
export function InputReasonContent(props: InputReasonContentModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { control, selectedReason, errors } = props;
  const rejectReasonKindNumberForMaster = 2;
  const { openErrorModal } = useErrorModal();
  const {
    data,
    loading,
    error: getMRejectReasonByKindError,
  } = gql.useGetMRejectReasonByKindQuery({
    variables: { kind: rejectReasonKindNumberForMaster },
    context: { clientName: 'master' },
  });
  React.useEffect(() => {
    if (getMRejectReasonByKindError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [getMRejectReasonByKindError, openErrorModal]);

  // feat screen 08-07-04 start
  return !isMobile ? (
    <Box width="100%">
      {loading && <Loading />}
      <Text variant="body14">ブロック理由を選択してください。</Text>
      <Box pt={17}>
        <InputLabel>ブロック理由</InputLabel>
        <RhfSelect
          placeholder="ブロック理由を選択"
          control={control}
          options={
            data?.getMRejectReasonByKind?.map((val) => ({ label: val?.reason ?? '', value: val?.reason ?? '' })) ?? []
          }
          name="reason"
        />
      </Box>
      {selectedReason === 'その他' && (
        <Box pt={30}>
          <InputLabel>コメント</InputLabel>
          <RhfInput
            rules={{ required: '理由を記入してください。' }}
            name="comment"
            control={control}
            placeholder="コメントを記入"
          />
          {errors.comment?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.comment.message}
            </Text>
          )}
        </Box>
      )}
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%">
      {loading && <Loading />}
      <Text variant="body14">ブロック理由を選択してください。</Text>
      <Box pt={16}>
        <InputLabel fullWidth>ブロック理由</InputLabel>
        <RhfSelect
          fullWidth
          height={40}
          placeholder="ブロック理由を選択"
          control={control}
          options={
            data?.getMRejectReasonByKind?.map((val) => ({ label: val?.reason ?? '', value: val?.reason ?? '' })) ?? []
          }
          name="reason"
        />
      </Box>
      {selectedReason === 'その他' && (
        <Box pt={20}>
          <InputLabel fullWidth>コメント</InputLabel>
          <RhfInput
            fullWidth
            rules={{ required: '理由を記入してください。' }}
            name="comment"
            control={control}
            placeholder="コメントを記入"
          />
          {errors.comment?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.comment.message}
            </Text>
          )}
        </Box>
      )}
    </Box>
  // feat screen 08-07-04 end
  );
}

type InputReasonFooterModel = {
  nextPhase: () => void;
  close: () => void;
  backPhase: () => void;
  disabledNextPhase: boolean;
};
export function InputReasonFooter(props: InputReasonFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { nextPhase, close, backPhase, disabledNextPhase } = props;
  // feat screen 08-07-04 start
  return !isMobile ? (
    <Box display="flex" gap={8} justifyContent="flex-end">
      <MainButton width={104} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={104} variant="secondary" onClick={backPhase}>
        戻る
      </MainButton>
      <MainButton width={104} onClick={nextPhase} disabled={disabledNextPhase}>
        確定
      </MainButton>
    </Box>
  ) : (
    // Responsive Component
    <Box display="flex" gap={8} justifyContent="flex-end">
      <MainButton width={96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={96} variant="secondary" onClick={backPhase}>
        戻る
      </MainButton>
      <MainButton width={96} onClick={nextPhase} disabled={disabledNextPhase}>
        確定
      </MainButton>
    </Box>
  // feat screen 08-07-04 end
  );
}
