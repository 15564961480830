/**
 * Figma ID: 01-01-06-01
 * 名称: 募集用記載内容の必須事項
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  toNext: (requireRecruitmentInfo: { socialInsurance: string; preventEts: string; inSmokingArea: boolean }) => void;
  toBack: () => void;
};
export function RequiredRecruitment({ toNext, toBack }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    socialInsurance: string;
    passiveSmokingMeasure: string;
    isWorkingSmokingArea: string;
  }>({
    reValidateMode: 'onChange',
  });

  const toNextPhase = handleSubmit((data) => {
    toNext({
      socialInsurance: data.socialInsurance,
      preventEts: data.passiveSmokingMeasure,
      inSmokingArea: data.isWorkingSmokingArea === 'yes',
    });
  });
  return (
    // feat screen 01-01-06-01
    <div>
      {!isMobile ? (
        <Box width={752} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 募集用記載内容の必須事項" />

      <Box>
        <Text variant="body14">バイト募集時に労働通知書に記載される必須項目です。</Text>
        <br />
        <Text variant="body14">各項目の文章を記入してください（最大32文字）。</Text>
      </Box>

      <Box>
        <Box display="flex" flexDirection="column" gap={4} mb={17}>
          <InputLabel fullWidth required>
            社会保険等
          </InputLabel>
          <RhfInput
            control={control}
            name="socialInsurance"
            defaultValue=""
            placeholder="文章を記入"
            rules={{
              required: '入力してください。',
              validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
            }}
          />
          {errors.socialInsurance?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.socialInsurance.message}
            </Text>
          )}
          <Box>
            <Text variant="body14" color="liteGray">
              例) 労災保険の適用あり。雇用保険、健康保険及び厚生年金の適用無し。など。
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={4} mb={17}>
          <InputLabel fullWidth required>
            受動喫煙防止措置
          </InputLabel>
          <RhfInput
            control={control}
            name="passiveSmokingMeasure"
            defaultValue=""
            placeholder="文章を記入"
            rules={{
              required: '入力してください。',
              validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
            }}
          />
          {errors.passiveSmokingMeasure?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.passiveSmokingMeasure.message}
            </Text>
          )}
          <Box>
            <Text variant="body14" color="liteGray">
              例) 屋内禁煙 など。
            </Text>
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel width={200} required>
            喫煙可能エリアでの作業
          </InputLabel>
          <RhfRadioGroup
            name="isWorkingSmokingArea"
            control={control}
            defaultValue=""
            rules={{ required: '選択してください。' }}
            options={[
              {
                value: 'yes',
                label: (
                  <Text color="blueGray" variant="body14">
                    有
                  </Text>
                ),
              },
              {
                value: 'no',
                label: (
                  <Text color="blueGray" variant="body14">
                    無
                  </Text>
                ),
              },
            ]}
          />
          {errors.isWorkingSmokingArea?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.isWorkingSmokingArea.message}
            </Text>
          )}
        </Box>
      </Box>

          <Box display="flex" gap={5} justifyContent="center" pt={40}>
            <MainButton variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              onClick={() => {
                toNextPhase();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      ) :(
        // Responsive Component
        <Box width="100vw" display="flex" flexDirection="column"px={16}>
          <Box>
            <ContentTitle title="新規登録 - 募集用記載内容の必須事項" />
          </Box>

          <Box mt={18}>
            <Text display="block" variant="body14">
              バイト募集時に労働通知書に記載される必須項目です。各項目の文章を記入してください（最大32文字）。
            </Text>
          </Box>

          <Box mt={24}>
            <Box display="flex" flexDirection="column" gap={2} mb={17}>
              <InputLabel fullWidth required>
                社会保険等
              </InputLabel>
              <RhfInput
                fullWidth
                control={control}
                name="socialInsurance"
                defaultValue=""
                placeholder="文章を記入"
                rules={{
                  required: '入力してください。',
                  validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
                }}
              />
              {errors.socialInsurance?.message && (
                <Text variant="caption12" color="cautionRed">
                  {errors.socialInsurance.message}
                </Text>
              )}
              <Box mt={6}>
                <Text display="block" variant="caption12" color="liteGray">
                  例) 労災保険の適用あり。雇用保険、健康保険及び厚生年金の適用無し。など。
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} mb={18}>
              <InputLabel fullWidth required>
                受動喫煙防止措置
              </InputLabel>
              <RhfInput
                fullWidth
                control={control}
                name="passiveSmokingMeasure"
                defaultValue=""
                placeholder="文章を記入"
                rules={{
                  required: '入力してください。',
                  validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
                }}
              />
              {errors.passiveSmokingMeasure?.message && (
                <Text variant="caption12" color="cautionRed">
                  {errors.passiveSmokingMeasure.message}
                </Text>
              )}
              <Box mt={6}>
                <Text display="block" variant="caption12" color="liteGray">
                  例) 屋内禁煙 など。
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={4}>
              <InputLabel width={210} required>
                喫煙可能エリアでの作業
              </InputLabel>
              <RhfRadioGroup
                name="isWorkingSmokingArea"
                control={control}
                defaultValue=""
                rules={{ required: '選択してください。' }}
                options={[
                  {
                    value: 'yes',
                    label: (
                      <Text color="blueGray" variant="body14">
                        有
                      </Text>
                    ),
                  },
                  {
                    value: 'no',
                    label: (
                      <Text color="blueGray" variant="body14">
                        無
                      </Text>
                    ),
                  },
                ]}
              />
              {errors.isWorkingSmokingArea?.message && (
                <Text variant="caption12" color="cautionRed">
                  {errors.isWorkingSmokingArea.message}
                </Text>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" py={24} gap={5}>
            <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              fullWidth
              customHeight={40}
              onClick={() => {
                toNextPhase();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      )}
    </div>
    // feat screen 01-01-06-01
  );
}
