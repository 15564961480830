/**
 * Figma ID: 02-02-01
 * 名称: パスワード変更
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { MainButton } from 'components/mainButton';
import { TextLink } from 'components/textLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  onSendMail: (email: string) => Promise<void>;
};
export default function SendMail({ onSendMail }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ storeCode: string }>({ reValidateMode: 'onChange' });

  const storeCodeLength = 12;

  const onSubmit = handleSubmit(async (data) => {
    await onSendMail(data.storeCode);
  });

  // feat screen 02-02-01 start
  return !isMobile ? (
    <Box display="grid" justifyContent="center" pt={34}>
      <ContentTitle title="パスワード再設定" />
      <Box display="flex" justifyContent="center" pt={24}>
        <Text variant="body14" align="center">
          パスワード再設定用の確認コードをメールで送信します。
          <br />
          店舗コードを入力して送信を押してください。
        </Text>
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>店舗コード</InputLabel>
        <RhfInput
          placeholder="店舗コード"
          name="storeCode"
          control={control}
          rules={{
            required: '店舗コードを入力してください。',
            maxLength: { value: storeCodeLength, message: `${storeCodeLength}文字以内で入力してください` },
          }}
        />
        {errors.storeCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.storeCode.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            onSubmit();
          }}
        >
          送信
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
    </Box>
  ) : (
    <Box px={16}>
      <ContentTitle title="パスワード再設定" />
      <Box mt={16}>
        <Text display="block" variant="body14" align="left">
          パスワード再設定用の確認コードをメールで送信します。
          店舗コードを入力して送信を押してください。
        </Text>
      </Box>
      <Box display='flex' flexDirection='column' pt={24} gap={2}>
        <InputLabel>店舗コード</InputLabel>
        <RhfInput
          fullWidth
          placeholder="店舗コード"
          name="storeCode"
          control={control}
          rules={{
            required: '店舗コードを入力してください。',
            maxLength: { value: storeCodeLength, message: `${storeCodeLength}文字以内で入力してください` },
          }}
        />
        {errors.storeCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.storeCode.message}`}</Text>
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt={52}>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            onSubmit();
          }}
        >
          送信
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink variant='body14' to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
    </Box>
  );
  // feat screen 02-02-01 end
}
