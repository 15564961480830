import React, { ReactNode } from 'react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';

/**
 * Figma ID
 * 06-03-01,06-03-02,06-03-04,06-03-05
 * 承認,否認
 */

interface Props {
  title: string;
  contentText: ReactNode;
  step: number;
  onModalClose: () => void;
  onClickSubmit: () => void;
  submitText?: string;
}

function LocalModal({ title, contentText, submitText, step, onModalClose, onClickSubmit }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        {title}
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          {contentText}
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {step === 1 ? (
          <>
            {/* // feat screen 06-03-01_06 start */}
            <MainButton
              width={!isMobile ? 104 : 96}
              thin={!isMobile}
              variant="secondary"
              onClick={() => onModalClose()}
            >
              {/* // feat screen 06-03-01_06 end */}
              キャンセル
            </MainButton>
            {/* // feat screen 06-03-01_06 start */}
            <MainButton width={!isMobile ? 104 : 96} thin={!isMobile} onClick={() => onClickSubmit()}>
              {/* // feat screen 06-03-01_06 end */}
              {submitText}
            </MainButton>
          </>
        ) : (
          // feat screen 06-03-01_06 start
          <MainButton width={!isMobile ? 104 : 96} thin={!isMobile} variant="secondary" onClick={() => onModalClose()}>
            {/* // feat screen 06-03-01_06 end */}
            閉じる
          </MainButton>
        )}
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={step !== 0}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat screen 06-03-01_06 start
      width={!isMobile ? 384 : '100%'}
      // feat screen 06-03-01_06 end
    />
  );
}

export default LocalModal;
