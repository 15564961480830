import React from 'react';

export class BitravelError {
  readonly title: string = 'エラー';

  readonly message: string | React.ReactNode;

  constructor(props: { message: string | React.ReactNode; title?: string }) {
    const { message, title } = props;
    this.title = title || this.title;
    this.message = message;
  }
}
