import { AgreementStatus } from 'components/const';
import { Icon } from 'components/icon';
import React, { createContext } from 'react';

interface CheckInInfo {
  imageURL: string;
  status: AgreementStatus;
  brandId: number;
  tpmemId: number;
  isFaceRecognized: boolean;
}

export const CheckInInfoContext = createContext<CheckInInfo>({
  imageURL: '',
  status: AgreementStatus.ALL,
  brandId: -1,
  tpmemId: -1,
  isFaceRecognized: false,
});

export function scoreToStars(
  score: number, 
  /* feat_screen_04-02-07-06_add_prop_start */
  isMobile?: boolean
  /* feat_screen_04-02-07-06_add_prop_end */
  ) {
  const fullScore = 5;
  /* feat_screen_04-02-07-06_start */
  const solidStar = !isMobile ? <Icon name="star" /> : <Icon size={32} name="star" />;
  const voidStar = !isMobile ? <Icon name="starBorder" /> :  <Icon size={32} name="starBorder" />;
  /* feat_screen_04-02-07-06_end */


  function repeatPush(arr: JSX.Element[], el: JSX.Element, count: number): JSX.Element[] {
    if (count > 0) {
      arr.push(el);
      return repeatPush(arr, el, count - 1);
    }
    return arr;
  }

  const solidStars = repeatPush([], solidStar, score);
  const voidStars = repeatPush([], voidStar, fullScore - score);

  return solidStars.concat(voidStars);
}
