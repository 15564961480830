import { Chip, chipColors } from 'components/chip/chip';
import React, { createContext } from 'react';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { Icon } from 'components/icon';
import { Text } from 'components/text';
import { AgreementStatus } from 'components/const';
import { orderBy } from 'lodash';
import { VtWorkingList } from 'graphql/graphql-mw';
import { RecruitType } from './const';
import { ListDataType } from './type';

export function convertStatusToLabel(status?: AgreementStatus | null): string {
  switch (status) {
    case AgreementStatus.ALL:
      return '全て';
    case AgreementStatus.BEFORE:
      return 'バイト前';
    case AgreementStatus.STAND_BY:
      return 'スタンバイ';
    case AgreementStatus.IN_PROGRESS:
      return 'バイト中';
    case AgreementStatus.NO_CHECK_IN:
      return 'チェックインなし';
    case AgreementStatus.NO_CHECK_OUT:
      return 'チェックアウトなし';
    case AgreementStatus.NO_CHECK_IN_OUT:
      return 'イン/アウトなし';
    case AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS:
    case AgreementStatus.REQUEST_CORRECTION_REJECTED:
      return '修正依頼';
    case AgreementStatus.CANCEL:
      return 'キャンセル';
    case AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED:
    case AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT:
      return '完了待ち';
    case AgreementStatus.COMPLETE:
      return '完了';
    case AgreementStatus.NO_SHOW:
      return 'バックレ';
    default:
      return '';
  }
}

export function convertRecruitTypeToLabel(recruitType?: number | null): string {
  switch (recruitType) {
    case RecruitType.BELONGED:
      return '自店';
    case RecruitType.GROUP:
      return 'グループ';
    case RecruitType.HIRED:
      return '他店/一般';
    case RecruitType.SUB:
      return 'サブ';
    default:
      return '';
  }
}

export function convertStatusToChipColor(status?: AgreementStatus | null): keyof typeof chipColors {
  switch (status) {
    case AgreementStatus.BEFORE:
      return 'okBlue';
    case AgreementStatus.STAND_BY:
      return 'warningYellow';
    case AgreementStatus.IN_PROGRESS:
    case AgreementStatus.NO_CHECK_IN:
    case AgreementStatus.NO_CHECK_OUT:
    case AgreementStatus.NO_CHECK_IN_OUT:
      return 'darkBlue';
    case AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS:
    case AgreementStatus.REQUEST_CORRECTION_REJECTED:
    case AgreementStatus.CANCEL:
      return 'cautionRed';
    case AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED:
    case AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT:
    case AgreementStatus.COMPLETE:
    case AgreementStatus.NO_SHOW:
      return 'liteGray';
    default:
      return 'blue';
  }
}

export function convertRecruitTypeToChipColor(recruitType?: number | null): keyof typeof chipColors {
  switch (recruitType) {
    case RecruitType.HIRED:
      return 'okBlue';
    case RecruitType.BELONGED:
    case RecruitType.SUB:
    case RecruitType.GROUP:
      return 'darkBlue';
    default:
      return 'blue';
  }
}

export function convertVTWorkingListToObject(rawData: VtWorkingList[]): ListDataType[] {
  return rawData.map((d) => ({
    status: d.status,
    approvalDueDate: d.time_left,
    publishedNumber: d.offer_number,
    membershipNumber: d.btmem_id?.toString(),
    name: d.btmem_name,
    recruitType: d.type_name,
    arbeitStartDate: d.begin_date,
    arbeitEndDate: d.end_date,
    checkIn: d.qr_checkin,
    checkOut: d.qr_checkout,
    correctedCheckIn: d.fix_checkin,
    correctedCheckOut: d.fix_checkout,
    totalCorrectionRequest: d.fix_count,
    applicationNumber: d.entry_number,
    offerId: d.offer_id,
    workId: d.work_id,
    hasNotification: d.newly === 1,
  }));
}

export const header = [
  {
    columnName: '',
    key: 'eye',
    width: 24,
  },
  {
    columnName: '状態',
    key: 'status',
    width: 120,
  },
  {
    columnName: '修正承認期限',
    key: 'approvalDueDate',
    width: 120,
  },
  {
    columnName: '掲載No.',
    key: 'publishedNumber',
    width: 120,
  },
  {
    columnName: '会員番号',
    key: 'membershipNumber',
    width: 120,
  },
  {
    columnName: '氏名',
    key: 'name',
    width: 120,
  },
  {
    columnName: '所属',
    key: 'recruitType',
    width: 120,
  },
  {
    columnName: 'バイト開始期日',
    key: 'arbeitStartDate',
    width: 136,
  },
  {
    columnName: 'バイト終了期日',
    key: 'arbeitEndDate',
    width: 136,
  },
  {
    columnName: 'チェックイン実績',
    key: 'checkIn',
    width: 136,
  },
  {
    columnName: 'チェックアウト実績',
    key: 'checkOut',
    // feat screen 04-02-01 start
    width: 145,
    // feat screen 04-02-01 end
  },
  {
    columnName: '修正チェックイン',
    key: 'correctedCheckIn',
    width: 136,
  },
  {
    columnName: '修正チェックアウト',
    key: 'correctedCheckOut',
    // feat screen 04-02-01 start
    width: 145,
    // feat screen 04-02-01 end
  },
  {
    columnName: '修正依頼回数',
    key: 'totalCorrectionRequest',
    width: 120,
  },
  {
    columnName: '応募No.',
    key: 'applicationNumber',
    width: 120,
  },
];

const statusToCautionCheckIn = [AgreementStatus.NO_CHECK_IN, AgreementStatus.NO_CHECK_IN_OUT];
const statusToCautionCheckOut = [AgreementStatus.NO_CHECK_OUT, AgreementStatus.NO_CHECK_IN_OUT];

export function generateListRow(
  data: ListDataType[],
  limit: number,
  page: number,
  sort?: { key: string; direction: 'asc' | 'desc' },
  /* feat_screen_04-02-01-01_start */
  isMobile?: boolean
  /* feat_screen_04-02-01-01_start */
) {
  function convert(d: ListDataType, index: number) {
    const Indicator = styled.div`
      position: absolute;
      top: -6px;
      right: 0;
    `;

    // feat check conditions to navigate to 勤務時間 / 修正 tab start
    const isRequestCorrectionData =
      d.status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
      d.status === AgreementStatus.REQUEST_CORRECTION_REJECTED;
    const state = isMobile && isRequestCorrectionData ? { menuIndex: 2 } : undefined;
    // feat check conditions to navigate to 勤務時間 / 修正 tab end

    return {
      eye: (
        <div style={{ position: 'relative' }}>
          {/* feat_screen_04-02-01-01_start */}
          <Link to={`/s04-02/${String(d.offerId || '')}`} state={state}>
            <Icon name="eye" size={!isMobile ? 24 : 20} />
            {d.hasNotification && (
              <Indicator>
                <Icon name="indicatorRed" size={8} />
              </Indicator>
            )}
          </Link>
          {/* feat_screen_04-02-01-01_end */}
        </div>
      ),
      status: typeof d?.status === 'number' && (
        <Chip
          color={convertStatusToChipColor(d.status)}
          /* feat_screen_04-02-01-01_start */
          width={!isMobile ? 96 : 110}
          /* feat_screen_04-02-01-01_end */
        >
          {convertStatusToLabel(d.status)}
        </Chip>
      ),
      approvalDueDate: d.approvalDueDate,
      publishedNumber: d.publishedNumber,
      membershipNumber: d.membershipNumber,
      name: d.name,
      recruitType: d.recruitType,
      arbeitStartDate: d.arbeitStartDate,
      arbeitEndDate: d.arbeitEndDate,
      checkIn:
        typeof d?.status === 'number' && statusToCautionCheckIn.includes(d.status) ? (
          <Text variant="body14" color="cautionRed">
            {d.checkIn}
          </Text>
        ) : (
          d.checkIn
        ),
      checkOut:
        typeof d?.status === 'number' && statusToCautionCheckOut.includes(d.status) ? (
          <Text variant="body14" color="cautionRed">
            {d.checkOut}
          </Text>
        ) : (
          d.checkOut
        ),
      correctedCheckIn: d.correctedCheckIn,
      correctedCheckOut: d.correctedCheckOut,
      totalCorrectionRequest: d.totalCorrectionRequest,
      applicationNumber: d.applicationNumber,
      uniqueKey: `key-${index}`,
    };
  }

  return orderBy(data, sort?.key, sort?.direction)
    .slice(limit * (page - 1), limit * page)
    .map(convert);
}

export const StyledListWrapper = styled.div<{
  // feat_screen_04-02-02-01_start
  showStatusFilter?: boolean;
  isShowFilter?: boolean;
  // feat_screen_04-02-02-01_end
}>`
  max-height: calc(100vh - 352px);
  min-height: calc(100vh - 352px);
  overflow: scroll;
  /* feat screen_04-02-01-01_start */
  @media only screen and (max-width: 768px) {
    max-height: ${({ showStatusFilter, isShowFilter }) => {
      if (showStatusFilter) {
        if (isShowFilter) {
          return 'calc(100svh - 444px)';
        }
        return 'calc(100svh - 264px)';
      }
      if (isShowFilter) {
        return 'calc(100svh - 426px)';
      }
      return 'calc(100svh - 264px)';
    }};
    min-height: ${({ showStatusFilter, isShowFilter }) => {
      if (showStatusFilter) {
        if (isShowFilter) {
          return 'calc(100svh - 462px)';
        }
        return 'calc(100svh - 319px)';
      }
      if (isShowFilter) {
        return 'calc(100svh - 426px)';
      }
      return 'calc(100svh - 318px)';
    }};
    height: ${({ isShowFilter }) => (isShowFilter ? 'calc(100svh - 276px)' : 'calc(100svh - 362px)')};
    overflow-y: auto;
    overscroll-behavior: none;
  }
  @media only screen and (max-height: 500px) {
    max-height: 'calc(100svh - 264px)';
    min-height: ${({ showStatusFilter }) => {
      if (showStatusFilter) {
        return 'calc(100svh - 318px)';
      }
      return 'calc(100svh - 318px)';
    }};
    height: ${({ isShowFilter }) => (isShowFilter ? 'calc(100svh - 276px)' : 'calc(100svh - 362px)')};
    overflow-y: auto;
    overscroll-behavior: auto;
  }
  /* feat screen_04-02-01-01_end */
`;

type ListDataContext = {
  data?: ListDataType[];
};

export const CurrentListDataContext = createContext<ListDataContext>({});
