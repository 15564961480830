import { Box } from 'components/box';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from 'components/avatar';
import { Chip } from 'components/chip';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { useAuth } from 'hooks/authProvider';
import {
  VbMemberBase,
  VtBizBtmemInfo,
  useAddelTFavoriteBtmemByIdBtmemIdMutation,
  useGetVbMemberBaseByIdQuery,
} from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { styled } from '@linaria/react';
import { ErrorModal } from 'components/errorModal';
import { Favorite, ResultRows } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import { ImageModal } from 'components/newModal';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { UnrestrictedContent } from './unrestrictedContent';
import { RestrictedContent } from './restrictedContent';
import { ImgWrapper } from '../components/imgWrapper';
import { BlockFlag, EmployeeType, MemberType } from '../const';

const StyledBorderBox = styled.div<{
  borderColor: string;
}>`
  box-sizing: border-box;
  padding: 2px 8px;
  border-radius: 12px;
  border: 1px solid ${({ borderColor }) => borderColor};
  height: 22px;
  align-items: center;
  display: flex;
  /* feat screen_05-02-01-02_start */
  @media only screen and (max-width: 768px) {
    height: 24px;
  }
  /* feat screen_05-02-01-02_end */
`;

const StyledHeaderBG = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
  gap: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  /* feat screen_05-02-01-02_start */
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    align-items: start;
  }
  /* feat screen_05-02-01-02_end */
`;


/* feat_screen_05-02-01-02_start */
const StyledAvatar = styled.div`
  width: 80px;
  height: 60px;
  background-color: black;
`;

const StyledBorderBoxRed = styled.div<{
  borderColor: string;
}>`
  box-sizing: border-box;
  padding: 0px 8px;
  border-radius: 12px;
  border: 1px solid ${({ borderColor }) => borderColor};
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: row;
`;
/* feat_screen_05-02-01-02_end */

export function S0502Detail() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const auth = useAuth();
  const { openErrorModal } = useErrorModal();
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const tpmemId = auth?.tpmemId;
  const btmemId = parseInt(params.id ?? '0', 10);
  if (tpmemId === null) navigate('/signin');

  const [isError, setIsError] = React.useState(false);

  const {
    data: { getVBMemberBaseById: { info, base } } = {
      getVBMemberBaseById: {
        info: {} as VtBizBtmemInfo,
        base: {} as VbMemberBase,
      },
    },
    loading: useGetVbMemberBaseByIdQueryLoading,
    error: useGetVbMemberBaseByIdQueryError,
    refetch,
    error,
  } = useGetVbMemberBaseByIdQuery({
    variables: {
      id: parseInt(params.id ?? '0', 10),
      tpmem_id: tpmemId ?? 0,
    },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (useGetVbMemberBaseByIdQueryError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [useGetVbMemberBaseByIdQueryError, openErrorModal]);

  const [updateFavoriteStatus, { loading: updateFavoriteStatusLoading }] = useAddelTFavoriteBtmemByIdBtmemIdMutation();

  const [imageModalStep, setImageModalStep] = React.useState<boolean>(false);

  const handleFavorite = () => {
    updateFavoriteStatus({
      variables: {
        btmem_id: btmemId,
        id: tpmemId ?? 0,
        on_off: info.favorite_flag === Favorite.YES ? Favorite.NO : Favorite.YES,
      },
    })
      .then((result) => {
        if (result.data?.addelTFavoriteBtmemByIdBtmemId.resultRows === ResultRows.UPDATED) {
          refetch();
        } else {
          setIsError(true);
        }
      })
      .catch(() => setIsError(true));
  };

  const generateChip = (type: number) => {
    switch (type) {
      case EmployeeType.OTHER_STORE:
        return <Chip color="okBlue">他店/一般</Chip>;
      case EmployeeType.OWN_STORE:
        return <Chip color="darkBlue">自店</Chip>;
      case EmployeeType.SUB:
        return <Chip color="darkBlue">サブ</Chip>;
      case EmployeeType.GROUP:
        return <Chip color="darkBlue">グループ</Chip>;
      default:
        return '';
    }
  };

  if (error) {
    return <div>該当データが存在しません</div>;
  }
  // ヘッダーの背景判定
  const headerBackgroundColor = (identification: number | undefined) => {
    switch (identification) {
      case MemberType.PERMANENTLY_DISABLE:
      case MemberType.TEMPORARILY_DISABLE:
      case MemberType.SUSPENSION:
        return 'rgba(229, 0, 0, 0.1)';
      default:
        return '';
    }
  };

  // Imageのラベル取得
  const rowLabel = (identification: number | undefined) => {
    switch (identification) {
      case MemberType.PERMANENTLY_DISABLE:
        return '一時停止';
      case MemberType.TEMPORARILY_DISABLE:
        return '停止';
      case MemberType.SUSPENSION:
        return '休止';
      default:
        return '';
    }
  };
  // ImageのColor取得
  const rowImageColor = (identification: number | undefined) => {
    switch (identification) {
      case MemberType.PERMANENTLY_DISABLE:
        return 'rgba(0, 0, 0, 0.7)';
      case MemberType.TEMPORARILY_DISABLE:
        return 'rgba(0, 0, 0, 0.7)';
      case MemberType.SUSPENSION:
        return 'rgba(229, 0, 0, 0.7)';
      default:
        return '';
    }
  };

  return (
    <>
      {(updateFavoriteStatusLoading || useGetVbMemberBaseByIdQueryLoading) && <Loading />}
      {
        !isMobile ? (
          <Box display="flex" flexDirection="column" height="calc(100%)" gap={26}>
            <Box display="flex" flexDirection="row" height={60} width="100%" gap={24} alignItems="center">
              <StyledHeaderBG backgroundColor={info.block_flag === BlockFlag.TRUE ? 'rgba(229, 0, 0, 0.1)' : ''}>
                {info.status === MemberType.SUSPENSION ||
                info.status === MemberType.PERMANENTLY_DISABLE ||
                info.status === MemberType.TEMPORARILY_DISABLE ? (
                  <ImgWrapper
                    width={80}
                    height={60}
                    membertype={info.status}
                    backgroundcolor={rowImageColor(info?.status)}
                    isMobile={isMobile}
                    text={
                      <Text variant="body14" color="white" bold>
                          {rowLabel(info.status)}
                      </Text>
                    }
                  >
                    <Avatar src={info.image ?? NoImageLarge} width={80} height={60} />
                  </ImgWrapper>
                ) : (
                  <button
                    onClick={() => {
                      setImageModalStep(true);
                    }}
                    type="button"
                    style={{width: '80px', height: '60px', padding: 0, border: 'none', background: 'none'}}
                  >
                    <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                  </button>
                )}
                <Box display="flex" flexDirection="column" width="100%" gap={1}>
                    <Box width={56}>{generateChip(info.type ?? 0)}</Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                      <Text variant="h1">{base.btmem_number}</Text>
                      {info.favorite_flag === Favorite.YES && (
                        <StyledBorderBox borderColor="#008599">
                          <Text variant="caption12" bold color="primaryBlue">
                            お気に入り
                          </Text>
                        </StyledBorderBox>
                      )}
                      {info.block_flag === BlockFlag.TRUE && (
                        <StyledBorderBox borderColor="#E50000">
                          <Box display="flex" flexDirection="row" gap={8}>
                            <Text variant="caption12" bold color="cautionRed">
                              ブロック中
                            </Text>
                            <Text variant="caption12" color="cautionRed">
                              {info.block_reason}
                            </Text>
                          </Box>
                        </StyledBorderBox>
                      )}
                    </Box>
                    {info.favorite_flag === Favorite.YES ? (
                      <MainButton variant="secondary" icon="favoriteBorder" iconColor="#008599" onClick={handleFavorite}>
                        お気に入り解除
                      </MainButton>
                    ) : (
                      <MainButton icon="favoriteBorder" iconColor="#FFFFFF" onClick={handleFavorite}>
                        お気に入り追加
                      </MainButton>
                    )}
                  </Box>
                </Box>
              </StyledHeaderBG>
            </Box>
            <Box display="flex" flex="auto">
              {info.status === MemberType.SUSPENSION || (info.type === EmployeeType.OTHER_STORE) === false ? (
                <RestrictedContent refetchHeaderData={refetch} />
              ) : (
                <UnrestrictedContent refetchHeaderData={refetch} />
              )}
            </Box>
          </Box>
        ) : (
          // feat_screen_05-02-01-01_start
          <Box pb={24}>
            <MultiMenuHeader
              items={
                [
                  {
                    label: "バイトラベラー"
                  },
                  {
                    label: "他店/一般一覧"
                  }
                ]
              }
              mainHeader="他店/一般一覧"
            />
            <Box display="flex" flexDirection="column" height="100%" gap={16} pt={8}>
              <Box display="flex" flexDirection="column" width="100%" alignItems="center">
                <StyledHeaderBG backgroundColor={info.block_flag === BlockFlag.TRUE ? 'rgba(229, 0, 0, 0.1)' : ''}>
                  <Box display="flex" flexDirection="column" pl={16} pt={7} gap={8}>
                    <Box display="flex" flexDirection="row" gap={16}>
                      {info.status === MemberType.SUSPENSION ||
                      info.status === MemberType.PERMANENTLY_DISABLE ||
                      info.status === MemberType.TEMPORARILY_DISABLE ? (
                        <ImgWrapper
                          width={80}
                          height={60}
                          membertype={info.status}
                          backgroundcolor={rowImageColor(info?.status)}
                          isMobile={isMobile}
                          text={
                              <Text variant="body14" color="white" bold>
                                  {rowLabel(info.status)}
                              </Text>
                          }
                        >
                          <Avatar src={info.image ?? NoImageLarge} width={80} height={60} />
                        </ImgWrapper>
                      ) : (
                      <StyledAvatar>
                        <button
                          onClick={() => {
                            setImageModalStep(true);
                          }}
                          type="button"
                          style={{width: '80px', height: '60px', padding: 0, border: 'none', background: 'none'}}
                        >
                          <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                        </button>
                      </StyledAvatar>
                      )}
                      <Box display="flex" flexDirection="column" width="100%" gap={4} pt={4}>
                          <Box width={56}>{generateChip(info.type ?? 0)}</Box>
                          <Box display="flex" flexDirection="column" justifyContent="space-between">
                          <Box display="flex" flexDirection="row" gap={16} alignItems="center" pt={6}>
                            <Text variant="body16" bold>{base.btmem_number}</Text>
                            {info.favorite_flag === Favorite.YES && (
                              <StyledBorderBox borderColor="#008599">
                                <Text variant="caption12" bold color="primaryBlue">
                                  お気に入り
                                </Text>
                              </StyledBorderBox>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {info.block_flag === BlockFlag.TRUE && (
                      <StyledBorderBoxRed borderColor="#E50000">
                        <Box display="flex" flexDirection="row">
                          <Text variant="chip9" bold color="cautionRed">
                            ブロック中
                          </Text>
                          <Text variant="chip9" color="cautionRed">
                            {info.block_reason}
                          </Text>
                        </Box>
                      </StyledBorderBoxRed>
                    )}
                    <Box pt={info.block_flag === BlockFlag.TRUE ? 6 : 8} pb={info.block_flag === BlockFlag.TRUE ? 8 : 0}>
                      {info.favorite_flag === Favorite.YES ? (
                        <MainButton variant="secondary" icon="favoriteBorder" iconColor="#008599" iconSize={20} onClick={handleFavorite} width={165}>
                          お気に入り解除
                        </MainButton>
                      ) : (
                        <MainButton icon="favoriteBorder" iconColor="#FFFFFF" iconSize={20} onClick={handleFavorite} width={158}>
                          お気に入り追加
                        </MainButton>
                      )}
                    </Box>
                  </Box>
                </StyledHeaderBG>
              </Box>
              <Box display="flex" flex="auto" px={16}>
                {info.status === MemberType.SUSPENSION || (info.type === EmployeeType.OTHER_STORE) === false ? (
                  <RestrictedContent refetchHeaderData={refetch} />
                ) : (
                  <UnrestrictedContent refetchHeaderData={refetch} />
                )}
              </Box>
            </Box>
          </Box>
          // feat_screen_05-02-01-01_end
        )
      }
      <ImageModal src={info?.image || ''} isOpen={imageModalStep} onClose={() => setImageModalStep(false)} />
      <ErrorModal
        isOpen={isError}
        onClose={() => setIsError(false)}
        title="エラー"
        message={
          info.favorite_flag === Favorite.YES ? 'お気に入りの解除に失敗しました。' : 'お気に入りの登録に失敗しました。'
        }
      />
    </>
  );
}
