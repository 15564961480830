import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetTBaseByCodeLazyQuery, GetTBaseByCodeQuery, useUpdateTMemberEmailByIdMutation } from 'graphql/graphql-mw';
import type { CognitoUser } from '@aws-amplify/auth';
import AwsConfigAuth from '../aws-config/auth';

Amplify.configure({ Auth: AwsConfigAuth });

type ProvideAuthProps = {
  children: React.ReactNode;
};

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  storeInfo: {
    // このアプリケーション内ではtpmemIdという方が通りがいい
    id: number;
    brandId: number;
    // cognito上ではusernameというプロパティ名になる
    // RDS上では t_base_tbl.code または t_member_tbl.login_id に当たる
    code: string;
    name: string;
    kana: string;
    zipCode: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    phone: string;
    createdDate: string;
    lat: number;
    lng: number;
  } | null;
  tpmemId: number | null;
  signUp: (userName: string, email: string, password: string) => Promise<void>;
  confirmSignUp: (userName: string, confirmationCode: string) => Promise<void>;
  reSendConfirmationCode: (userName: string) => Promise<void>;
  signIn: (userName: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  sendEmailToResetPassword: (userName: string) => Promise<void>;
  resetPassword: (userName: string, confirmationCode: string, password: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  confirmUpdateEmail: (confirmationCode: string, newEmail: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  reSendcurrentConfirmationCode: () => Promise<void>;
}

type ExtendCognitoUserType = CognitoUser & {
  attributes: {
    email: string;
    email_verified: boolean;
  };
  username: string;
};

const authContext = createContext({} as UseAuth);

export const useAuth = () => useContext(authContext);

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [storeInfo, setStoreInfo] = useState<{
    // このアプリケーション内ではtpmemIdという方が通りがいい
    id: number;
    brandId: number;
    // cognito上ではusernameというプロパティ名になる
    // RDS上では t_base_tbl.code または t_member_tbl.login_id に当たる
    code: string;
    name: string;
    kana: string;
    zipCode: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    phone: string;
    createdDate: string;
    lat: number;
    lng: number;
  } | null>(null);
  const [tpmemId, setTpmemId] = useState<number | null>(null);

  const [getTBaseByCodeLazyQuery] = useGetTBaseByCodeLazyQuery();
  const [updateTMemberEmailByIdMutation] = useUpdateTMemberEmailByIdMutation();

  const fetchStoreInfo = async (loginId: string): Promise<NonNullable<GetTBaseByCodeQuery['getTBaseByCode']>> => {
    const { data: { getTBaseByCode } = {}, error } = await getTBaseByCodeLazyQuery({
      variables: { code: loginId },
    });
    if (error) {
      throw error;
    }
    if (getTBaseByCode !== null && getTBaseByCode !== undefined) {
      return getTBaseByCode;
    }
    throw new Error('ユーザー情報の取得に失敗しました');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (result: ExtendCognitoUserType) => {
        // ここのresult.usernameはcognito上ではusernameというプロパティ名になるが
        // RDS上では t_base_tbl.code または t_member_tbl.login_id に当たる
        const getTBaseByCode = await fetchStoreInfo(result.username);
        setStoreInfo({
          id: getTBaseByCode.id,
          brandId: getTBaseByCode.brand_id ?? 0,
          code: getTBaseByCode.code ?? '',
          name: getTBaseByCode.name ?? '',
          kana: getTBaseByCode.kana ?? '',
          zipCode: getTBaseByCode.zip_code ?? '',
          state: getTBaseByCode.state ?? '',
          city: getTBaseByCode.city ?? '',
          address1: getTBaseByCode.address1 ?? '',
          address2: getTBaseByCode.address2 ?? '',
          phone: getTBaseByCode.phone ?? '',
          createdDate: getTBaseByCode.created_date ?? '',
          lat: getTBaseByCode.lat ?? 0,
          lng: getTBaseByCode.lng ?? 0,
        });
        setTpmemId(getTBaseByCode.id);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setStoreInfo(null);
        setTpmemId(null);
        setIsAuthenticated(false);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // パスワード再設定用認証コードの送信
  const sendEmailToResetPassword = async (userName: string) => {
    await Auth.forgotPassword(userName);
  };

  // パスワード再設定
  const resetPassword = async (userName: string, confirmationCode: string, _password: string) => {
    await Auth.forgotPasswordSubmit(userName, confirmationCode, _password);
  };

  // サインイン
  const signIn = async (userName: string, password: string): Promise<void> => {
    const result = (await Auth.signIn(userName, password)) as ExtendCognitoUserType;
    const getTBaseByCode = await fetchStoreInfo(result.username);
    setStoreInfo({
      id: getTBaseByCode.id,
      brandId: getTBaseByCode.brand_id ?? 0,
      code: getTBaseByCode.code ?? '',
      name: getTBaseByCode.name ?? '',
      kana: getTBaseByCode.kana ?? '',
      zipCode: getTBaseByCode.zip_code ?? '',
      state: getTBaseByCode.state ?? '',
      city: getTBaseByCode.city ?? '',
      address1: getTBaseByCode.address1 ?? '',
      address2: getTBaseByCode.address2 ?? '',
      phone: getTBaseByCode.phone ?? '',
      createdDate: getTBaseByCode.created_date ?? '',
      lat: getTBaseByCode.lat ?? 0,
      lng: getTBaseByCode.lng ?? 0,
    });
    setTpmemId(getTBaseByCode.id);
    setIsAuthenticated(true);
  };

  // サインアウト
  const signOut = async () => {
    await Auth.signOut();
    setIsAuthenticated(false);
    setStoreInfo(null);
  };

  const signUp = async (userName: string, email: string, password: string) => {
    await Auth.signUp({ username: userName, password, attributes: { email } });
  };

  // サインアップ認証
  const confirmSignUp = async (userName: string, confirmationCode: string) => {
    await Auth.confirmSignUp(userName, confirmationCode);
  };

  // Cognitoのユーザー作成時の確認コードを再送する
  const reSendConfirmationCode = async (userName: string) => {
    await Auth.resendSignUp(userName);
  };

  // Cognito上のメールアドレスの更新のリクエストをかけ、メールアドレス宛に確認コードを送信する
  const updateEmail = async (email: string): Promise<void> => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    await Auth.updateUserAttributes(currentUser, { email });
  };

  // 確認コードをCognitoに送信し、DB上のメールアドレスも変更し更新を完了する
  const confirmUpdateEmail = async (confirmationCode: string, newEmail: string): Promise<void> => {
    await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);
    (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    if (storeInfo) {
      await updateTMemberEmailByIdMutation({ variables: { id: storeInfo.id, email: newEmail } });
    }
  };

  const reSendcurrentConfirmationCode = async (): Promise<void> => {
    await Auth.verifyCurrentUserAttribute('email');
  };

  // Cognito上のパスワードを変更する
  const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    await Auth.changePassword(currentUser, oldPassword, newPassword);
  };

  return {
    isLoading,
    isAuthenticated,
    storeInfo,
    tpmemId,
    signUp,
    confirmSignUp,
    reSendConfirmationCode,
    signIn,
    signOut,
    sendEmailToResetPassword,
    resetPassword,
    updateEmail,
    confirmUpdateEmail,
    changePassword,
    reSendcurrentConfirmationCode,
  };
};

export function AuthProvider(props: ProvideAuthProps) {
  const { children } = props;
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
