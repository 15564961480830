/* eslint-disable */
import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { MessageStatus } from 'components/const';

/**
 *［API］HOME一覧取得
 */
export function useHomeListApi(tpmemId: number, sysId: number, kind: number) {
  // 「レビュー」タブ用HOMEデータ取得
  if (kind === MessageStatus.REVIEW) {
    const { data, loading, error, refetch, called } = getTRecvNotifyListByTpmemIdSysIdK389(tpmemId, sysId);
    return {
      data,
      loading,
      error,
      called,
      refetch: async (): Promise<gql.TRecvNotifyHome> => {
        const data = await refetch();
        const result = data?.data?.getTRecvNotifyListByTpmemIdSysIdK389;
        return result;
      },
    };
  }

  // HOMEデータ取得
  const { data, loading, error, called, refetch } = getTRecvNotifyListByTpmemIdSysIdKind(tpmemId, sysId, kind);
  return {
    data,
    loading,
    error,
    called,
    refetch: async (): Promise<gql.TRecvNotifyHome> => {
      const data = await refetch();
      const result = data?.data?.getTRecvNotifyListByTpmemIdSysIdKind;
      return result;
    },
  };
}

// HOME画面用データ取得
// ※「レビュー」タブの以外のデータ取得用
function getTRecvNotifyListByTpmemIdSysIdKind(tpmemId: number, sysId: number, kind: number) {
  const { data, loading, error, refetch, called } = gql.useGetTRecvNotifyListByTpmemIdSysIdKindQuery({
    variables: {
      tpmem_id: tpmemId,
      sys_id: sysId,
      kind: kind,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data: data?.getTRecvNotifyListByTpmemIdSysIdKind,
    loading,
    error,
    refetch,
    called,
  };
}

// 「レビュー」タブ用のHOMEデータ取得
function getTRecvNotifyListByTpmemIdSysIdK389(tpmemId: number, sysId: number) {
  const { data, loading, error, refetch, called } = gql.useGetTRecvNotifyListByTpmemIdSysIdK389Query({
    variables: {
      tpmem_id: tpmemId,
      sys_id: sysId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data: data?.getTRecvNotifyListByTpmemIdSysIdK389,
    loading,
    error,
    refetch,
    called,
  };
}
