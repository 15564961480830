import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text';
import {ColorKeyType} from "../text/text";

export interface Props {
  items: {
    label: string;
    labelBold?: boolean;
    content: ReactNode;
    contentBold?: boolean;
    colorContent?: ColorKeyType;
    align?: string;
    pt?: number;
    isShow?: boolean;
  }[];
}

const StyledWrapper = styled.div<{
  align?: string;
}>`
  width: 100%;
`;

const StyledRow = styled.div<{
  align?: string;
  /* feat_screen_03-01-09_start */
  isCheckFinal?: boolean;
  /* feat_screen_03-01-09_start */
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  padding: 8px 0px;
  border-bottom: #dbdbdb 1px solid;
  /* feat_screen_03-01-09_start */
  @media only screen and (max-width: 768px) {
    padding: 16px 0px;
    border-bottom: ${({ isCheckFinal }) => ( isCheckFinal ? `none` : `#dbdbdb 1px solid` )};
    align-items: start;
    gap: 16px;
  }
  /* feat_screen_03-01-09_end */
`;

const StyledLabel = styled.div<{
  pt?: number;
}>`
  width: 168px;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : 'auto')};
  line-height: 28px;
   /* feat_screen_03-01-09_start */
  @media only screen and (max-width: 768px) {
    width: unset;
    min-width: 85px;
    align-items: start;
    line-height: 21px;
  }
  /* feat_screen_03-01-09_end */
`;

const StyledContent = styled.div`
  width: 100%;
  flex: auto;
  line-height: 28px;
  /* feat_screen_03-01-09_start */
  @media only screen and (max-width: 768px) {
    text-align: right;
    line-height: 21px;
  }
  /* feat_screen_03-01-09_end */
`;

export function Info({ items }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <StyledWrapper>
      {items.map(
        (item) =>
          item.isShow !== false && (
            <StyledRow key={item.label} align={item.align}>
              <StyledLabel pt={item.pt}>
                <Text variant="body14" color="darkGray" bold={item.labelBold} wordBreak="break-all">
                  {item.label}
                </Text>
              </StyledLabel>
              <StyledContent>
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text bold={item.contentBold} color={item.colorContent} variant="body14">{item.content}</Text>
                ) : (
                  item.content
                )}
              </StyledContent>
            </StyledRow>
          )
      )}
    </StyledWrapper>
  ): (
    /* feat_screen_03-01-09_start */
    <StyledWrapper>
      {items.map(
        (item, index) =>
          item.isShow !== false && (
            <StyledRow key={item.label} align={item.align} isCheckFinal={index === (items.length - 1)}>
              <StyledLabel pt={item.pt}>
                <Text display="block" variant="body14" color="darkGray" bold={item.labelBold}>
                  {item.label}
                </Text>
              </StyledLabel>
              <StyledContent>
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text display="block" align="right" variant="body14">{item.content}</Text>
                ) : (
                  item.content
                )}
              </StyledContent>
            </StyledRow>
          )
      )}
    </StyledWrapper>
    /* feat_screen_03-01-09_end */
  );
}
