import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useForm } from 'react-hook-form';
import React from 'react';
import { GetupdVtMemberBaseByIdMutation, useAddTManagerMutation } from 'graphql/graphql-mw';
import { uploadFile } from 'components/api';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import { MemberStoreStatus } from 'components/const';
import { TextLink } from 'components/textLink';

import useIsMobile from 'hooks/responsive/useIsMobile';
import EditStoreInfoModal from './modals/basicInfo/EditStoreInfoModal';
import EditBusinessInfoModal from './modals/basicInfo/EditBusinessInfoModal';
import ConfirmModal from './modals/basicInfo/ConfirmModal';
import { BusinessFormType, StoreFormType } from './types';
import { PaymentMethod } from '../const';
import CompleteModal from './modals/basicInfo/CompleteModal';
import TrialTicketModal from './modals/basicInfo/TrialTicketModal';

const topHeight = 224;
const bottomHeight = 32;

const ScrollArea = styled.div`
  width: 100%;
  height: calc(100vh - ${topHeight + bottomHeight}px);
  overflow: auto;
  /* feat_common_responsive_layout_start */
  @media only screen and (max-width: 768px) {
    height: calc(100svh - ${topHeight + bottomHeight}px);
  }
  /* feat_common_responsive_layout_end */
`;

enum ModalTypes {
  EDIT_STORE_INFO = 'edit_store_info',
  EDIT_BUSINESS_INFO = 'edit_business_info',
  CONFIRM = 'confirm',
  COMPLETE = 'complete',
  TICKET = 'ticket',
}
// 1:法人 2:個人事業主
enum CorporateType {
  Corporation = 1,
  SoleProprietorship = 2,
}
// 個人事業主の場合空白で渡す
const initialBusinessFormType: BusinessFormType = {
  representativeFamilyName: '',
  representativeFirstName: '',
  representativeFamilyNameKana: '',
  representativeFirstNameKana: '',
  businessManagerFamilyName: '',
  businessManagerFirstName: '',
  businessManagerFamilyNameKana: '',
  businessManagerFirstNameKana: '',
  businessManagerPhone: '',
};

type BasicInfoProps = {
  basicData?: GetupdVtMemberBaseByIdMutation | null;
  refetchBasicInfo: () => void;
};
function BasicInfo(props: BasicInfoProps) {
  const { basicData, refetchBasicInfo } = props;
  const [activeModal, setActiveModal] = React.useState<ModalTypes | null>(null);
  const [updateStoreInfo, { error: updateStoreInfoError }] = useAddTManagerMutation();

  const [storeEditInfo, setStoreEditInfo] = React.useState<StoreFormType | null>(null);
  const [businessEditInfo, setBusinessEditInfo] = React.useState<BusinessFormType | null>(null);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const storeData = basicData?.getupdVTMemberBaseById;

  const storeForm = useForm<StoreFormType>();
  const businessForm = useForm<BusinessFormType>();

  const memberStoreStatus = useMemberStoreStatus();

  function onCloseModal() {
    storeForm.reset();
    businessForm.reset();
    setStoreEditInfo(null);
    setBusinessEditInfo(null);
    setActiveModal(null);
  }

  const { openErrorModal } = useErrorModal();

  // 郵便番号にハイフンを挿入する
  function formatZipCode(zipCode: string) {
    return zipCode.replace(/(\d{3})(\d{4})/, '$1-$2');
  }

  const setPpaymentMethod = (type: PaymentMethod) => {
    switch (type) {
      case PaymentMethod.Giro:
        return (
          <Text variant="body14" color="okBlue" bold>
            振替
          </Text>
        );
      case PaymentMethod.TransferRequesting:
        return (
          <Text variant="body14" color="cautionRed" bold>
            振替申請中
          </Text>
        );
        return '';
      case PaymentMethod.Transfer:
      default:
        return (
          <Text variant="body14" color="black" bold>
            振込
          </Text>
        );
    }
  };

  return (
    <>
      {
        !isMobile ? (
          <Box px={16} py={24} width="100%">
            <Box mb={16} display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                基本情報
              </Text>
              {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                <MainButton icon="edit" thin onClick={() => setActiveModal(ModalTypes.EDIT_STORE_INFO)}>
                  編集
                </MainButton>
              )}
            </Box>
            <ScrollArea>
              <Divider option="horizontal" />
              <Info
                items={[
                  {
                    content: storeData?.code,
                    label: '店舗コード',
                  },
                  {
                    content: storeData?.store_name,
                    label: '店舗名',
                  },
                  {
                    content: setPpaymentMethod(storeData?.payment_method ?? 0),
                    label: '支払方法',
                  },
                  {
                    content: `〒${formatZipCode(storeData?.zip_code ?? '') ?? ''} ${storeData?.state ?? ''}${
                      storeData?.city ?? ''
                    }${storeData?.address ?? ''}${storeData?.address2 ?? ''}`,
                    label: '住所',
                  },
                  {
                    content: storeData?.phone,
                    label: '電話番号',
                  },
                  {
                    content: storeData?.email,
                    label: 'メールアドレス(ID)',
                  },
                  {
                    // 営業時間が HH:mm:ss で入っているので、HH:mm に変換
                    content:
                      storeData?.open_time === storeData?.close_time
                        ? '24時間営業'
                        : `${storeData?.open_time?.slice(0, 5) ?? ''} ~ ${storeData?.close_time?.slice(0, 5) ?? ''}`,
                    label: '営業時間',
                  },
                  {
                    content: storeData?.parking_val ? '有' : '無',
                    label: '従業員駐車場',
                  },
                  {
                    content: `${storeData?.mgr_last_name ?? ''} ${storeData?.mgr_first_name ?? ''}`,
                    label: '責任者氏名',
                  },
                  {
                    content: storeData?.mgr_birthday ?? '',
                    label: '責任者生年月日',
                  },
                  {
                    content: storeData?.mgr_phone,
                    label: '責任者電話番号',
                  },
                  {
                    content: (
                      <Box display="flex" flexDirection="column" gap={8}>
                        <Box>
                          <Text variant="body14">{storeData?.mco_name}</Text>
                        </Box>
                        <Box>
                          <Text variant="body14">
                            〒{formatZipCode(storeData?.mco_zip_code ?? '')} {storeData?.mco_state}
                            {storeData?.mco_city}
                            {storeData?.mco_address}
                            {storeData?.address2}
                          </Text>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>
                            <Text variant="body14">法人番号</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">{storeData?.corporate_no}</Text>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>
                            <Text variant="body14">代表者</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">
                              {storeData?.mco_m_last_name} {storeData?.mco_m_first_name}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>&nbsp;</Box>
                          <Box>
                            <Text variant="body14">
                              {storeData?.mco_m_last_kana} {storeData?.mco_m_first_kana}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>
                            <Text variant="body14">担当者</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">
                              {storeData?.mco_t_last_name} {storeData?.mco_t_first_name}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>&nbsp;</Box>
                          <Box>
                            <Text variant="body14">
                              {storeData?.mco_t_last_kana} {storeData?.mco_t_first_kana}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box width={112}>
                            <Text variant="body14">担当者電話番号</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">{storeData?.mco_t_phone}</Text>
                          </Box>
                        </Box>
                      </Box>
                    ),
                    label: '事業者',
                  },
                  {
                    content: storeData?.biz_name,
                    label: '業種',
                  },
                  {
                    content: storeData?.brand_name,
                    label: 'ブランド',
                  },
                  {
                    content: storeData?.ticket_num ? (
                      <TextLink
                        variant="body14"
                        onClick={() => {
                          setActiveModal(ModalTypes.TICKET);
                        }}
                      >
                        {storeData?.ticket_num}
                      </TextLink>
                    ) : (
                      <Text variant="body14">-</Text>
                    ),
                    label: 'トライアルチケット',
                  },
                  {
                    content: storeData?.again,
                    label: 'またバイトしたい',
                  },
                  {
                    content: storeData?.invite_code,
                    label: '友達招待コード',
                  },
                  {
                    content: storeData?.total_adopt,
                    label: '総雇用回数',
                  },
                  {
                    content: storeData?.favorite_num,
                    label: 'お気に入り登録数',
                  },
                ]}
              />
            </ScrollArea>
          </Box>
        ) : (
          // feat_screen_09-01-01_start
          <Box px={16} pb={8} pt={16} width="100%" height="calc(100% - 51px)" overflow="auto">
            <Box mb={13} display="flex" flexDirection="column" alignItems="center" gap={15}>
              <Text variant="h2" color="darkBlue">
                基本情報
              </Text>
              {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                <MainButton icon="edit" thin onClick={() => setActiveModal(ModalTypes.EDIT_STORE_INFO)} customHeight={32} width={124} iconSize={20}>
                  編集
                </MainButton>
              )}
            </Box>
            <Box>
              <Divider option="horizontal" />
              <Info
                items={[
                  {
                    content: storeData?.code,
                    label: '店舗コード',
                  },
                  {
                    content: storeData?.store_name,
                    label: '店舗名',
                  },
                  {
                    content: setPpaymentMethod(storeData?.payment_method ?? 0),
                    label: '支払方法',
                  },
                  {
                    content: `〒${formatZipCode(storeData?.zip_code ?? '') ?? ''} ${storeData?.state ?? ''}${
                      storeData?.city ?? ''
                    }${storeData?.address ?? ''}${storeData?.address2 ?? ''}`,
                    label: '住所',
                  },
                  {
                    content: storeData?.phone,
                    label: '電話番号',
                  },
                  {
                    content: storeData?.email,
                    label: 'メールアドレス(ID)',
                  },
                  {
                    // 営業時間が HH:mm:ss で入っているので、HH:mm に変換
                    content:
                      storeData?.open_time === storeData?.close_time
                        ? '24時間営業'
                        : `${storeData?.open_time?.slice(0, 5) ?? ''} ~ ${storeData?.close_time?.slice(0, 5) ?? ''}`,
                    label: '営業時間',
                  },
                  {
                    content: storeData?.parking_val ? '有' : '無',
                    label: '従業員駐車場',
                  },
                  {
                    content: `${storeData?.mgr_last_name ?? ''} ${storeData?.mgr_first_name ?? ''}`,
                    label: '責任者氏名',
                  },
                  {
                    content: storeData?.mgr_birthday ?? '',
                    label: '責任者生年月日',
                  },
                  {
                    content: storeData?.mgr_phone,
                    label: '責任者電話番号',
                  },
                  {
                    content: (
                      <Box display="flex" flexDirection="column" gap={8}>
                        <Box display="flex" justifyContent="flex-start">
                          <Text variant="body14">{storeData?.mco_name}</Text>
                        </Box>
                        <Divider option="horizontal"/>
                        <Box display="flex" justifyContent="flex-start">
                          <Text variant="body14">
                            〒{formatZipCode(storeData?.mco_zip_code ?? '')} {storeData?.mco_state}
                            {storeData?.mco_city}
                            {storeData?.mco_address}
                            {storeData?.address2}
                          </Text>
                        </Box>
                        <Divider option="horizontal"/>
                        <Box display="flex" justifyContent="space-between">
                          <Box width={80} display="flex">
                            <Text align="left" variant="body14">法人番号</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">{storeData?.corporate_no}</Text>
                          </Box>
                        </Box>
                        <Divider option="horizontal"/>
                        <Box display="flex" justifyContent="space-between">
                          <Box width={80} display="flex">
                            <Text align="left" variant="body14">代表者</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">
                              {storeData?.mco_m_last_name} {storeData?.mco_m_first_name}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <Box width={80}>&nbsp;</Box>
                          <Box display="flex">
                            <Text align="right" variant="body14">
                              {storeData?.mco_m_last_kana} {storeData?.mco_m_first_kana}
                            </Text>
                          </Box>
                        </Box>
                        <Divider option="horizontal"/>
                        <Box display="flex" justifyContent="space-between">
                          <Box width={80} display="flex">
                            <Text align="right" variant="body14">担当者</Text>
                          </Box>
                          <Box>
                            <Text wordBreak='keep-all' variant="body14">
                              {storeData?.mco_t_last_name} {storeData?.mco_t_first_name}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <Box width={80}>&nbsp;</Box>
                          <Box display="flex">
                            <Text variant="body14" align="right">
                              {storeData?.mco_t_last_kana} {storeData?.mco_t_first_kana}
                            </Text>
                          </Box>
                        </Box>
                        <Divider option="horizontal"/>
                        <Box display="flex">
                          <Box width={200} display="flex">
                            <Text align="right" variant="body14">担当者電話番号</Text>
                          </Box>
                          <Box>
                            <Text variant="body14">{storeData?.mco_t_phone}</Text>
                          </Box>
                        </Box>
                      </Box>
                    ),
                    label: '事業者',
                  },
                  {
                    content: storeData?.biz_name,
                    label: '業種',
                  },
                  {
                    content: storeData?.brand_name,
                    label: 'ブランド',
                  },
                  {
                    content: storeData?.ticket_num ? (
                      <TextLink
                        variant="body14"
                        onClick={() => {
                          setActiveModal(ModalTypes.TICKET);
                        }}
                      >
                        {storeData?.ticket_num}
                      </TextLink>
                    ) : (
                      <Text variant="body14">-</Text>
                    ),
                    label: 'トライアルチケット',
                  },
                  {
                    content: storeData?.again,
                    label: 'またバイトしたい',
                  },
                  {
                    content: storeData?.invite_code,
                    label: '友達招待コード',
                  },
                  {
                    content: storeData?.total_adopt,
                    label: '総雇用回数',
                  },
                  {
                    content: storeData?.favorite_num,
                    label: 'お気に入り登録数',
                  },
                ]}
              />
            </Box>
          </Box>
          // feat_screen_09-01-01_end
        )
      }
      <EditStoreInfoModal
        isOpen={activeModal === ModalTypes.EDIT_STORE_INFO}
        onClose={() => onCloseModal()}
        onNext={(data) => {
          setStoreEditInfo(data);
          if (storeData?.corporate_type === CorporateType.SoleProprietorship) {
            // 次でこけるので空白データセット
            setBusinessEditInfo(initialBusinessFormType);
            setActiveModal(ModalTypes.CONFIRM);
          } else {
            setActiveModal(ModalTypes.EDIT_BUSINESS_INFO);
          }
        }}
        data={storeData}
        form={storeForm}
      />
      <EditBusinessInfoModal
        isOpen={activeModal === ModalTypes.EDIT_BUSINESS_INFO}
        onClose={() => onCloseModal()}
        onBack={() => setActiveModal(ModalTypes.EDIT_STORE_INFO)}
        onNext={(data) => {
          setBusinessEditInfo(data);
          setActiveModal(ModalTypes.CONFIRM);
        }}
        form={businessForm}
        data={storeData}
      />
      <ConfirmModal
        isOpen={activeModal === ModalTypes.CONFIRM}
        storeData={storeData}
        formData={storeEditInfo && businessEditInfo ? { ...storeEditInfo, ...businessEditInfo } : undefined}
        onClose={() => onCloseModal()}
        onBack={() =>
          storeData?.corporate_type === CorporateType.SoleProprietorship
            ? setActiveModal(ModalTypes.EDIT_STORE_INFO)
            : setActiveModal(ModalTypes.EDIT_BUSINESS_INFO)
        }
        onComplete={() => {
          const openTime = storeEditInfo?.is24Hour
            ? '00:00'
            : `${storeEditInfo?.fromHour ?? ''}:${storeEditInfo?.fromMin ?? ''}`;
          const closeTime = storeEditInfo?.is24Hour
            ? '00:00'
            : `${storeEditInfo?.toHour ?? ''}:${storeEditInfo?.toMin ?? ''}`;

          const photo = storeEditInfo?.photo?.[0] ?? null;
          const imageFileName = photo?.name || storeData?.image || '';
          const imagePath = `store-data/${storeData?.code || ''}.${imageFileName.split('.').pop() ?? ''}`;

          if (photo) {
            uploadFile({
              filePath: imagePath,
              file: photo,
            });
          }

          updateStoreInfo({
            variables: {
              first_name: storeEditInfo?.managerFirstName ?? '',
              last_name: storeEditInfo?.managerFamilyName ?? '',
              first_kana: storeEditInfo?.managerFirstNameKana ?? '',
              last_kana: storeEditInfo?.managerFamilyNameKana ?? '',
              dob:
                `${String(storeEditInfo?.managerBirthDate.getFullYear())}-${String(
                  (storeEditInfo?.managerBirthDate.getMonth() ?? 0) + 1
                )}-${String(storeEditInfo?.managerBirthDate.getDate())}` ?? '',
              phone: storeEditInfo?.managerPhone ?? '',
              id: storeData?.id ?? 0,
              open_time: openTime,
              close_time: closeTime,
              parking: storeEditInfo?.hasParking === 'yes',
              image: `${process.env.REACT_APP_FILE_READ_URL || ''}/${imagePath}`,
              corporate_type: storeData?.corporate_type ?? 0,
              mco_m_first_name: businessEditInfo?.businessManagerFirstName,
              mco_m_last_name: businessEditInfo?.businessManagerFamilyName,
              mco_m_first_kana: businessEditInfo?.businessManagerFirstNameKana,
              mco_m_last_kana: businessEditInfo?.businessManagerFamilyNameKana,
              mco_t_first_name: businessEditInfo?.representativeFirstName,
              mco_t_last_name: businessEditInfo?.representativeFamilyName,
              mco_t_first_kana: businessEditInfo?.representativeFirstNameKana,
              mco_t_last_kana: businessEditInfo?.representativeFamilyNameKana,
              mco_t_phone: businessEditInfo?.businessManagerPhone ?? '',
            },
          })
            .then(() => {
              if (updateStoreInfoError) {
                setActiveModal(null);
                openErrorModal({
                  message: '店舗情報の更新に失敗しました。もう一度やり直してください',
                });
              } else {
                setActiveModal(ModalTypes.COMPLETE);
                refetchBasicInfo();
              }
            })
            .catch(() => {
              setActiveModal(null);
              openErrorModal({
                message: '店舗情報の更新に失敗しました。もう一度やり直してください',
              });
            });
        }}
      />
      <CompleteModal
        isOpen={activeModal === ModalTypes.COMPLETE}
        onClose={() => {
          onCloseModal();
        }}
      />
      <TrialTicketModal
        isOpen={activeModal === ModalTypes.TICKET}
        tpmemId={storeData?.id ?? 0}
        onClose={() => {
          onCloseModal();
        }}
      />
    </>
  );
}

export default BasicInfo;
