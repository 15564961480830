/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React from 'react';
import { BitravelLogo } from 'components/assets/css/pages/s01/screen01Style';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { MainButton } from 'components/mainButton';
import { TextLink } from 'components/textLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { styled } from '@linaria/react';

type Props = {
  onSignIn: (args: { storeCode: string; password: string }) => Promise<void>;
};

// feat screen 02-01-01 fix bug icon start
const StyledInputPassWord = styled.div`
  input {
    padding-right: 40px;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
  }
`;
// feat screen 02-01-01 fix bug icon end

export function Screen020101({ onSignIn }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const storeCodeLength = 12;
  const passwordLength = 8;
  const regExp = /^(?=.*[A-Z])(?=.*[^$*.\\[\]{}()?&quot;!@#%&/\\,&gt;&lt;&prime;:;|_~`+=])[a-zA-Z0-9.?/-]/;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ storeCode: string; password: string }>({ reValidateMode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    onSignIn(data);
  });
  // feat screen 02-01-01 start
  return !isMobile ? (
    <Box>
      <Box height="100%" width="100%" alignItems="center" justifyContent="center" display="grid">
        <BitravelLogo width={200} height={35} src="/bitravel_logo.png" alt="bitravel_logo" />
        <Box justifyContent="center" alignItems="center" display="grid" pt={10}>
          <Text color="darkBlue" variant="body18" bold>
            加盟店管理
          </Text>
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>店舗コード</InputLabel>
        <RhfInput
          placeholder="店舗コード"
          name="storeCode"
          control={control}
          rules={{
            required: '店舗コードを入力してください。',
            maxLength: { value: storeCodeLength, message: '12文字以内で入力してください' },
          }}
        />
        {errors.storeCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.storeCode.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>パスワード</InputLabel>
        <RhfInput
          placeholder="パスワード"
          type="password"
          name="password"
          control={control}
          rules={{
            validate: (value) => regExp.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
            minLength: { value: passwordLength, message: '8文字以上で入力してください' },
          }}
        />
        {errors.password?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.password.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            onSubmit();
          }}
        >
          ログイン
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/Screen020201">パスワードを忘れた方はこちら</TextLink>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box pt={81}>
      <Box height="100%" width="100%" alignItems="center" justifyContent="center" display="grid">
        <BitravelLogo width={200} height={35} src="/bitravel_logo.png" alt="bitravel_logo" />
        <Box justifyContent="center" alignItems="center" display="grid" pt={24} mb={84}>
          <Text color="darkBlue" variant="body16" bold>
            加盟店管理
          </Text>
        </Box>
      </Box>
      <Box px={16}>
        <Box display='flex' flexDirection='column' gap={2} pb={18}>
          <InputLabel>店舗コード</InputLabel>
          <RhfInput
            fullWidth
            placeholder="店舗コード"
            name="storeCode"
            control={control}
            rules={{
              required: '店舗コードを入力してください。',
              maxLength: { value: storeCodeLength, message: '12文字以内で入力してください' },
            }}
          />
          {errors.storeCode?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.storeCode.message}`}</Text>
          )}
        </Box>
        <Box  display='flex' flexDirection='column'gap={2} pb={14}>
          <InputLabel>パスワード</InputLabel>
          <StyledInputPassWord>
            <RhfInput
              fullWidth
              placeholder="パスワード"
              type="password"
              name="password"
              control={control}
              rules={{
                validate: (value) => regExp.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
                minLength: { value: passwordLength, message: '8文字以上で入力してください' },
              }}
            />
            {errors.password?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.password.message}`}</Text>
            )}
          </StyledInputPassWord>
        </Box>
        <Box alignItems="center" pt={36}>
          <MainButton
            customHeight={40}
            fullWidth
            onClick={() => {
              onSubmit();
            }}
          >
            ログイン
          </MainButton>
        </Box>
        <Box pt={24} display="grid" justifyContent="center">
          <TextLink variant='body14' to="/Screen020201">パスワードを忘れた方はこちら</TextLink>
        </Box>
      </Box>
    </Box>
  );
  // feat screen 02-01-01 end
}
