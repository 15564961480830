import React from 'react';
import { Box } from 'components/box';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { mainContentHeaderMobileHeight } from './MainContentWrapper';
import { mainContentHeaderHeight } from './MainContentHeader';

const fixedHeight = `calc(100% - ${mainContentHeaderHeight}px)`;

/* feat_common_response_var_header_tab_start */
const mainHeaderTabHeight = 101;
const fixedHeightMobile = `100svh - ${mainContentHeaderMobileHeight + mainHeaderTabHeight}`;
/* feat_common_response_var_header_tab_end */

export function MainContentArea({ children }: { children: React.ReactNode }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <Box minHeight={fixedHeight} maxHeight={fixedHeight} height={fixedHeight}>
      {children}
    </Box>
  ): (
    /* feat_common_response_content_area_start */
    <Box minHeight={fixedHeightMobile} maxHeight={fixedHeightMobile} height="100%">
      {children}
    </Box>
    /* feat_common_response_content_area_end */
  );
}
