import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ModalRet } from 'pages/s05-01/type';
import React from 'react';

export function Complete(
  onClose: ()=> void,
): ModalRet{

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        通報 完了
      </Text>
    ),
    content: (
      <Box display='flex' height='100%' flexDirection='row' alignItems='center' flex='1'>
        <Text variant='body14'>
          このバイトラベラーを通報しました。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen_05-01-01-13_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          /* feat_screen_05-01-01-13_end */
          variant="secondary" 
          onClick={() => {
            onClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
   /* feat_screen_05-01-01-13_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : 172 
   /* feat_screen_05-01-01-13_end */
  };

  return modalContent;
};