/* eslint-disable */
import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { format, subMilliseconds, addDays, parse } from 'date-fns';
import { FormData } from './formState';
import { DateFormatForIso8601 } from 'components/utils/dateFormatForIso8601';
import { NoticeOfEmploymentPdfProps } from 'pdfComponent';
import { ScheduleType } from './scheduleTypeContext';

enum BinaryDayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 4,
  THURSDAY = 8,
  FRIDAY = 16,
  SATURDAY = 32,
  SUNDAY = 64,
}

function daysToMilliseconds(days: number) {
  const hours = days * 24;
  const minutes = hours * 60;
  const seconds = minutes * 60;
  const milliseconds = seconds * 1000;
  return milliseconds;
}

function daysAndHoursToMilliseconds(days: number, hours: number) {
  const hoursInDays = days * 24;
  const totalHours = hoursInDays + hours;
  const minutes = totalHours * 60;
  const seconds = minutes * 60;
  const milliseconds = seconds * 1000;
  return milliseconds;
}

export const calcPostDate = (
  beginDate?: string, // "yyyy-MM-dd H:m"
  postBeginDays?: string, // "30"
  postEndDays?: string, // "7"
  postEndHours?: string // "3"
) => {
  if (!beginDate || !postEndHours) {
    return {
      start: '',
      startHyphen: '',
      end: '',
      endHyphen: '',
      startDay: '',
      startDayHyphen: '',
      startTime: '',
      endDay: '',
      endDayHyphen: '',
      endTime: '',
    };
  }

  /**
   * 募集開始日
   */
  const currentDate = new Date();
  const currentDay = currentDate.getDate().toString().padStart(2,'0');
  const currentMonth = (currentDate.getMonth() + 1 ).toString().padStart(2,'0');
  const currentYear = currentDate.getFullYear().toString().padStart(2,'0');
  const currentHours = currentDate.getHours().toString().padStart(2,'0');
  const currentMinutes = currentDate.getMinutes().toString().padStart(2,'0');
  const postBeginDate = new Date(DateFormatForIso8601(`${currentYear}-${currentMonth}-${currentDay} ${currentHours}:${currentMinutes}`));
  // const postBeginDateMilliseconds = daysToMilliseconds(Number(postBeginDays));
  // const postBeginDate = subMilliseconds(new Date(DateFormatForIso8601(beginDate || ' ')), postBeginDateMilliseconds);

  /**
   * 募集終了日
   */
  const postEndDateMilliseconds = daysAndHoursToMilliseconds(Number(postEndDays), Number(postEndHours));
  const postEndDate = subMilliseconds(new Date(DateFormatForIso8601(beginDate || ' ')), postEndDateMilliseconds);

  return {
    start: `${format(postBeginDate, 'yyyy年MM月dd日 HH:mm')}`,
    startHyphen: `${format(postBeginDate, 'yyyy-MM-dd HH:mm')}`,
    startDay: `${format(postBeginDate, 'yyyy年MM月dd日')}`,
    startDayHyphen: `${format(postBeginDate, 'yyyy-MM-dd')}`,
    startTime: `${format(postBeginDate, 'HH:mm')}`,
    end: `${format(postEndDate, 'yyyy年MM月dd日 HH:mm')}`,
    endHyphen: `${format(postEndDate, 'yyyy-MM-dd HH:mm')}`,
    endDay: `${format(postEndDate, 'yyyy年MM月dd日')}`,
    endDayHyphen: `${format(postEndDate, 'yyyy-MM-dd')}`,
    endTime: `${format(postEndDate, 'HH:mm')}`,
  };
};

export function hasKey(obj?: object | null, key?: string): boolean {
  if (!obj || !key) return false;
  return key in obj;
}

export function usePagenation() {
  const [limit, setLimit] = React.useState<10 | 20 | 30>(10);
  const [page, setPage] = React.useState<number>(1);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  return {
    limit,
    page,
    handleChangeLimit,
    handleChangePage,
    setLimit,
    setPage,
  };
}

export function useSort({ onBeforeSort }: { onBeforeSort?: () => void } = {}) {
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeSort = (key: string) => {
    onBeforeSort?.();
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };
  return {
    sort,
    handleChangeSort,
  };
}

type WeekData = {
  date: string;
  dayOfWeek: string;
};

type MappedData = {
  id: number;
  label: string;
  date: string;
};

const dayOfWeeks = ['日', '月', '火', '水', '木', '金', '土'];

/**
 * 開始日と終了日の間の日付に曜日をつけて全て取得する
 */
export function getDatesBetween(start: Date, end: Date): WeekData[] {
  const datesArray: WeekData[] = [];
  const currentDate: Date = new Date(start);
  while (currentDate <= end) {
    datesArray.push({
      date: format(new Date(currentDate), 'yyyy-MM-dd'),
      dayOfWeek: dayOfWeeks[currentDate.getDay()],
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesArray;
}

/**
 * 選択された曜日のみを取得する
 */
export function filterByDayOfWeek(weeks: WeekData[], week: string[]): WeekData[] {
  return weeks.filter((w) => week.includes(w.dayOfWeek));
}

/**
 * 週定期シフト一覧を生成する
 */
export function generateWeeklyShiftList(fixedFormData: FormData, weeks: WeekData[]): MappedData[] {
  const getCalcPostDate = (fixedFormData: FormData, data: string) =>
    calcPostDate(
      `${String(data)} ${fixedFormData.weekly_shift_hour}:${fixedFormData.weekly_shift_minute}`,
      fixedFormData?.shift_day_from || '',
      fixedFormData?.shift_day_to || '',
      fixedFormData?.shift_hour_to || ''
    );

  const genarateLabel = (fixedFormData: FormData, date: string) =>
    `${format(new Date(date), 'yyyy年MM月dd日')}（配信期間:${String(
      getCalcPostDate(fixedFormData, date).startDay
    )} ~ ${String(getCalcPostDate(fixedFormData, date).endDay)}）`;

  return weeks.map((w, i) => ({
    id: i + 1,
    label: genarateLabel(fixedFormData, w.date),
    date: w.date,
  }));
}

/**
 * 曜日をバイナリに変換
 */
export function convertDaysToBinary(days: number | null): string {
  if (!days) return '';
  const binaryString = days.toString(2).padStart(7, '0');
  return binaryString;
}

/**
 * バイナリを整数に変換
 */
export function convertBinaryToDays(binaryString: string): number {
  return parseInt(binaryString, 2);
}

/**
 * 曜日を扱う文字列からバイナリ数値を取得
 * getVTOfferDraftInfoByIdTypeのw_week_bitを扱う
 */

export function extractNumber(input: string): number | null {
  const dataMatch = input.match(/data=\[(\d+)\]/);
  if (dataMatch && dataMatch[1]) {
    return parseInt(dataMatch[1], 10);
  }

  // If no match is found, return null.
  return null;
}

export function getSelectedDays(binaryString: string): string[] {
  // 曜日のマッピング
  const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  // 選択された曜日を格納するための配列
  let selectedDays: string[] = [];

  // バイナリ文字列を逆順にしてループ（曜日マッピングと一致させるため）
  for (let i = binaryString.length - 1; i >= 0; i--) {
    // ビットが1の場合、対応する曜日をselectedDaysに追加
    if (binaryString[i] === '1') {
      selectedDays.push(dayNames[binaryString.length - 1 - i]);
    }
  }

  return selectedDays;
}

/**
 * 文字列からISO8601形式の文字列を生成
 */
export function createIsoString(
  dateStr: string | undefined,
  hour: string | undefined,
  minute: string | undefined
): string {
  if (!dateStr || !hour || !minute) {
    return '';
  }
  const fullDateStr = `${dateStr}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
  const dateObj = new Date(fullDateStr);
  if (isNaN(dateObj.getTime())) {
    return '';
  }
  return dateObj.toISOString();
}

// PDFプレビュー用プロパティ日時フォーマット
const formatPdfPropDateTime = (shiftDate?: string, hour?: string, minute?: string) => {
  if (!shiftDate || !hour || !minute) return '';
  return `${format(new Date(shiftDate || 0), 'yyyy年MM月dd日')} ${hour?.padStart(2, '0') || ''}:${
    minute?.padStart(2, '0') || ''
  }`;
};

// PDFプレビュー用プロパティ取得
export function getPdfPreviewProps(fixedFormData: FormData, _shiftDate?: string) {
  // シフト日
  const shiftDate = _shiftDate || fixedFormData?.shift_date;

  // 開始時間
  let beginTime = '';
  // 終了時間
  let endTime = '';

  // 週定期の場合と日定期の場合で開始時間と終了時間を変更
  if (fixedFormData.check_schedule_type === ScheduleType.WEEKLY) {
    beginTime = formatPdfPropDateTime(shiftDate, fixedFormData.weekly_shift_hour, fixedFormData.weekly_shift_minute);
    endTime = formatPdfPropDateTime(shiftDate, fixedFormData.weekly_end_hour, fixedFormData.weekly_end_minute);
  } else {
    beginTime = formatPdfPropDateTime(shiftDate, fixedFormData.daily_start_hour, fixedFormData.daily_start_minute);
    endTime = formatPdfPropDateTime(shiftDate, fixedFormData.daily_end_hour, fixedFormData.daily_end_minute);
  }

  // PDFプロパティ設定
  const pdfProps: NoticeOfEmploymentPdfProps = {
    employmentDate: fixedFormData && format(new Date(shiftDate || 0), 'yyyy年MM月dd日'),
    description: fixedFormData?.description,
    beginTime,
    endTime,
    breakTime: fixedFormData.break_time ? Number(fixedFormData.break_time) : undefined,
    hourWage: fixedFormData?.hour_wage_display,
    transFee: fixedFormData?.trans_fee,
  };

  // 設定したPDFのプロパティを返す
  return pdfProps;
}

/**
 * 日付のフォーマットを変換
 * yyyy-MM-dd -> yyyy年MM月dd日
 */
export const formatDate = (dateString?: string): string => {
  if (!dateString) return '';
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'yyyy年MM月dd日');
};

/**
 * 日付を1日足す
 */
export const addDay = (dateString?: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const newDate = addDays(date, 1);
  return format(newDate, 'yyyy年MM月dd日');
};

export function generateBeginEndDateTime(data?: FormData) {
  if (!data?.daily_start_hour || !data?.daily_start_minute || !data?.daily_end_hour || !data?.daily_end_minute)
    return null;
  const beginHour = Number(data?.daily_start_hour);
  const endHour = Number(data?.daily_end_hour);
  const difHour = endHour - beginHour;
  let addedDay = '';
  if (difHour < 0) {
    addedDay = addDay(data?.shift_date);
  } else {
    addedDay = formatDate(data?.shift_date);
  }
  const dispBegin = `${formatDate(data?.shift_date)} ${data?.daily_start_hour.padStart(2, '0')}:${String(
    data?.daily_start_minute
  )}`;
  const dispEnd = `${addedDay} ${data?.daily_end_hour.padStart(2, '0')}:${String(data?.daily_end_minute)}`;
  return {
    begin: dispBegin,
    end: dispEnd,
  };
}

/**
 * 募集開始日時を計算
 */
export const generatePostBiginDate = (data: FormData): string =>
  `${String(
    calcPostDate(
      `${String(data?.shift_date || '')} ${String(data?.daily_start_hour || '')}:${String(
        data?.daily_start_minute || ''
      )}`,
      data?.shift_day_from || '',
      data?.shift_day_to || '',
      data?.shift_hour_to || ''
    ).start
  )}`;

/**
 * 募集終了日時を計算
 */
export const generatePostEndDate = (data: FormData): string =>
  `${String(
    calcPostDate(
      `${String(data?.shift_date || '')} ${String(data?.daily_start_hour || '')}:${String(
        data?.daily_start_minute || ''
      )}`,
      data?.shift_day_from || '',
      data?.shift_day_to || '',
      data?.shift_hour_to || ''
    ).end
  )}`;

/**
 * YYYY年MM月DD日をYYYY-MM-DDに変換
 */
function replaceDateFormat(date?: string): string {
  if (!date) return '';
  return date.replace('年', '-').replace('月', '-').replace('日', '');
}

function mapAutoRecruitFields(formData: FormData) {
  return {
    auto_decide: Number(formData.auto_decide),
    bt_rank_id: formData.rank ? Number(formData.rank) : undefined,
    score: formData.score ? Number(formData.score) : undefined,
    cancel_per: formData.cancel_per ? Number(formData.cancel_per) : undefined,
    imminent_per: formData.imminent_per ? Number(formData.imminent_per) : undefined,
    hire_fee_total: formData.hire_fee ? Number(formData.hire_fee) : undefined,
  };
}

/**
 * FormのデータをAPIのデータにマッピング（新規）（単日）
 */
export function mappingDailyFormData(tpmemId: number, formData: FormData) {
  /**
   * バイト開始日と終了日を計算
   */
  const dispBeginEnd = generateBeginEndDateTime(formData);

  return {
    tpmem_id: tpmemId,
    title: formData.title || '',
    post_begin: replaceDateFormat(generatePostBiginDate(formData)),
    post_end: replaceDateFormat(generatePostEndDate(formData)),
    begin_date: replaceDateFormat(dispBeginEnd?.begin || ''),
    end_date: replaceDateFormat(dispBeginEnd?.end || ''),
    hour_wage: Number(formData.hour_wage_display) || 0,
    trans_fee: formData.check_trans_fee === '1' ? Number(formData.trans_fee) : 0,
    break_time: formData.check_break_time === '1' ? Number(formData.break_time) : 0,
    description: formData.description || '',
    conditions: formData.conditions || '',
    caution: formData.caution || '',
    belongings: formData.belongings || '',
    access: formData.access || '',
    chief: formData.chief || '',
    attached_file: formData.attachment_flag === '1' ? formData.attached_file || '' : '',
    image1: formData.image1_flag ? String(formData.image1_path) : '',
    image2: formData.image2_flag ? String(formData.image2_path) : '',
    image3: formData.image3_flag ? String(formData.image3_path) : '',
    image4: formData.image4_flag ? String(formData.image4_path) : '',
    image5: formData.image5_flag ? String(formData.image5_path) : '',
    ...mapAutoRecruitFields(formData),
  };
}

/**
 * FormのデータをAPIのデータにマッピング（新規）（週定期）
 */
export function mappingWeeklyFormData(tpmemId: number, formData: FormData) {
  /**
   * 曜日を数値に変換
   */
  const arrWeekNum: number[] = [];
  if (formData.monday_flag) arrWeekNum.push(BinaryDayOfWeek.MONDAY);
  if (formData.tuesday_flag) arrWeekNum.push(BinaryDayOfWeek.TUESDAY);
  if (formData.wednesday_flag) arrWeekNum.push(BinaryDayOfWeek.WEDNESDAY);
  if (formData.thursday_flag) arrWeekNum.push(BinaryDayOfWeek.THURSDAY);
  if (formData.friday_flag) arrWeekNum.push(BinaryDayOfWeek.FRIDAY);
  if (formData.saturday_flag) arrWeekNum.push(BinaryDayOfWeek.SATURDAY);
  if (formData.sunday_flag) arrWeekNum.push(BinaryDayOfWeek.SUNDAY);
  const weekNum: number = arrWeekNum.reduce((a, b) => a + b, 0);

  return {
    tpmem_id: tpmemId,
    title: formData.title || '',
    access: formData.access || '',
    attached_file: formData.attachment_flag === '1' ? formData.attached_file || '' : '',
    begin_date: formData.repeat_start_date || '',
    begin_time: `${formData.weekly_shift_hour?.padStart(2, '0')}:${formData.weekly_shift_minute?.padStart(2, '0')}`,
    belongings: formData.belongings || '',
    break_time: formData.check_break_time === '1' ? Number(formData.break_time) : 0,
    caution: formData.caution || '',
    chief: formData.chief || '',
    conditions: formData.conditions || '',
    description: formData.description || '',
    end_date: formData.repeat_end_date || '',
    end_time: `${formData.weekly_end_hour?.padStart(2, '0')}:${formData.weekly_end_minute?.padStart(2, '0')}`,
    hour_wage: Number(formData.hour_wage_display) || 0,
    image1: formData.image1_flag ? String(formData.image1_path) : '',
    image2: formData.image2_flag ? String(formData.image2_path) : '',
    image3: formData.image3_flag ? String(formData.image3_path) : '',
    image4: formData.image4_flag ? String(formData.image4_path) : '',
    image5: formData.image5_flag ? String(formData.image5_path) : '',
    post_begin_days: 0,
    post_end_days: Math.floor(Number(formData.shift_hour_to)/24),
    post_end_hours: Number(formData.shift_hour_to) / 24 >= 1 ? Number(formData.shift_hour_to) % 24 : Number(formData.shift_hour_to),
    trans_fee: formData.check_trans_fee === '1' ? Number(formData.trans_fee) : 0,
    w_week_bit: weekNum,
    ...mapAutoRecruitFields(formData),
  };
}

/**
 * FormのデータをAPIのデータにマッピング（ドラフト）（単日）
 */
export function mappingDailyDraftFormData(id: number, formData: FormData) {
  /**
   * バイト開始日と終了日を計算
   */
  const dispBeginEnd = generateBeginEndDateTime(formData);

  return {
    id,
    title: formData.title || '',
    post_begin: replaceDateFormat(generatePostBiginDate(formData)),
    post_end: replaceDateFormat(generatePostEndDate(formData)),
    begin_date: replaceDateFormat(dispBeginEnd?.begin || ''),
    end_date: replaceDateFormat(dispBeginEnd?.end || ''),
    hour_wage: Number(formData.hour_wage_display) || 0,
    trans_fee: formData.check_trans_fee === '1' ? Number(formData.trans_fee) : 0,
    break_time: formData.check_break_time === '1' ? Number(formData.break_time) : 0,
    description: formData.description || '',
    conditions: formData.conditions || '',
    caution: formData.caution || '',
    belongings: formData.belongings || '',
    access: formData.access || '',
    chief: formData.chief || '',
    attached_file: formData.attachment_flag === '1' ? formData.attached_file || '' : '',
    image1: formData.image1_flag ? String(formData.image1_path) : '',
    image2: formData.image2_flag ? String(formData.image2_path) : '',
    image3: formData.image3_flag ? String(formData.image3_path) : '',
    image4: formData.image4_flag ? String(formData.image4_path) : '',
    image5: formData.image5_flag ? String(formData.image5_path) : '',
    ...mapAutoRecruitFields(formData),
  };
}

/**
 * FormのデータをAPIのデータにマッピング（ドラフト）（週定期）
 */
export function mappingWeeklyDraftFormData(id: number, formData: FormData) {
  /**
   * 曜日を数値に変換
   */
  const arrWeekNum: number[] = [];
  if (formData.monday_flag) arrWeekNum.push(BinaryDayOfWeek.MONDAY);
  if (formData.tuesday_flag) arrWeekNum.push(BinaryDayOfWeek.TUESDAY);
  if (formData.wednesday_flag) arrWeekNum.push(BinaryDayOfWeek.WEDNESDAY);
  if (formData.thursday_flag) arrWeekNum.push(BinaryDayOfWeek.THURSDAY);
  if (formData.friday_flag) arrWeekNum.push(BinaryDayOfWeek.FRIDAY);
  if (formData.saturday_flag) arrWeekNum.push(BinaryDayOfWeek.SATURDAY);
  if (formData.sunday_flag) arrWeekNum.push(BinaryDayOfWeek.SUNDAY);
  const weekNum: number = arrWeekNum.reduce((a, b) => a + b, 0);

  return {
    id,
    title: formData.title || '',
    access: formData.access || '',
    attached_file: formData.attachment_flag === '1' ? formData.attached_file || '' : '',
    begin_date: formData.repeat_start_date || '',
    begin_time: `${formData.weekly_shift_hour?.padStart(2, '0')}:${formData.weekly_shift_minute?.padStart(2, '0')}`,
    belongings: formData.belongings || '',
    break_time: formData.check_break_time === '1' ? Number(formData.break_time) : 0,
    caution: formData.caution || '',
    chief: formData.chief || '',
    conditions: formData.conditions || '',
    description: formData.description || '',
    end_date: formData.repeat_end_date || '',
    end_time: `${formData.weekly_end_hour?.padStart(2, '0')}:${formData.weekly_end_minute?.padStart(2, '0')}`,
    hour_wage: Number(formData.hour_wage_display) || 0,
    image1: formData.image1_flag ? String(formData.image1_path) : '',
    image2: formData.image2_flag ? String(formData.image2_path) : '',
    image3: formData.image3_flag ? String(formData.image3_path) : '',
    image4: formData.image4_flag ? String(formData.image4_path) : '',
    image5: formData.image5_flag ? String(formData.image5_path) : '',
    post_begin_days: Number(formData.shift_day_from),
    post_end_days: Number(formData.shift_day_to),
    post_end_hours: Number(formData.shift_hour_to),
    trans_fee: formData.check_trans_fee === '1' ? Number(formData.trans_fee) : 0,
    w_week_bit: weekNum,
    ...mapAutoRecruitFields(formData),
  };
}

/**
 * FormのデータをAPIのデータにマッピング（編集）（単日のみ）
 * 募集停止中の処理も混在しているのでバグが多いようであれば分けた方が良い
 */
export function mapFormDataForDaily(id: number, formData: FormData, apiResponseData?: gql.VtArbeitOfferInfo) {
  /**
   * バイト開始日と終了日を計算
   */
  const dispBeginEnd = generateBeginEndDateTime(formData);

  return {
    id: id,
    post_begin: replaceDateFormat(apiResponseData?.post_begin || ''),
    post_end: replaceDateFormat(generatePostEndDate(formData)),
    title: formData.title || '',
    begin_date: dispBeginEnd ? replaceDateFormat(dispBeginEnd?.begin || '') : apiResponseData?.begin_date,
    end_date: dispBeginEnd ? replaceDateFormat(dispBeginEnd?.end || '') : apiResponseData?.end_date,
    hour_wage: formData.hour_wage_display ? Number(formData.hour_wage_display) : apiResponseData?.hour_wage || 0,
    trans_fee:
      formData.check_trans_fee && formData.check_trans_fee === '1'
        ? Number(formData.trans_fee)
        : apiResponseData?.trans_fee_val ?? 0,
    break_time:
      formData.check_break_time && formData.check_break_time === '1'
        ? Number(formData.break_time)
        : apiResponseData?.break_time ?? 0,
    description: formData.description || '',
    conditions: formData.conditions || '',
    caution: formData.caution || '',
    belongings: formData.belongings || '',
    access: formData.access || '',
    chief: formData.chief || '',
    attached_file: formData.attachment_flag === '1' ? formData.attached_file || '' : '',
    image1: formData.image1_flag ? String(formData.image1_path) : '',
    image2: formData.image2_flag ? String(formData.image2_path) : '',
    image3: formData.image3_flag ? String(formData.image3_path) : '',
    image4: formData.image4_flag ? String(formData.image4_path) : '',
    image5: formData.image5_flag ? String(formData.image5_path) : '',
    ...mapAutoRecruitFields(formData),
  };
}
