/**
 * Figma ID: 01-01-06
 * 名称: 口座登録
 */
import React, { useMemo } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Controller, useForm } from 'react-hook-form';
import { MainButton } from 'components/mainButton';
import { InputLabel } from 'components/inputLabel';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { RhfInput } from 'components/rhfInput';
import { useGetMBankCodeQuery, useGetMBankBranchByCodeLazyQuery } from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ComboBox } from 'components/comboBox';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  toNext: (bankInfo: {
    bankName: string;
    bankCode: string;
    branchNo: string;
    branchName: string;
    accountType: string;
    accountNo: string;
    accountName: string;
    isPersonal: boolean;
  }) => void;
  toBack: () => void;
};
export function BankInfo({ toNext, toBack }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { openErrorModal } = useErrorModal();
  const { data: { getMBankCode } = { getMBankCode: [] }, error: getMBankCodeError } = useGetMBankCodeQuery({
    context: { clientName: 'master' },
  });
  const [fetchMBankBranchByCodeId, { error: fetchMBankBranchByCodeIdError }] = useGetMBankBranchByCodeLazyQuery();
  React.useEffect(() => {
    if (getMBankCodeError || fetchMBankBranchByCodeIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getMBankCodeError, fetchMBankBranchByCodeIdError, openErrorModal]);

  const [branchOptions, setBranchOptions] = React.useState<{ label: string; value: string; no: string }[]>([]);
  const [bankName, setBankName] = React.useState<string>('');
  const [branchNo, setBranchNo] = React.useState<string>('');

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    bankCode: string;
    branchName: string;
    accountType: string;
    accountNumber: string;
    accountName: string;
    isPersonal: string;
  }>({
    mode: 'onChange',
  });

  const bankOptions = useMemo<{ label: string; value: string }[]>(() => {
    if (!getMBankCode) {
      return [];
    }
    return getMBankCode.map((item) => ({
      label: item?.name ?? '',
      value: item?.code ?? '',
    }));
  }, [getMBankCode]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'bankCode' && type === 'change') {
        const selectedBank = getMBankCode?.find((item) => item?.code === value.bankCode);
        if (selectedBank) {
          fetchMBankBranchByCodeId({
            context: { clientName: 'master' },
            variables: { mcode: selectedBank.code },
          }).then(({ data: { getMBankBranchByCode } = { getMBankBranchByCode: [] } }) => {
            if (getMBankBranchByCode) {
              setBranchOptions(
                getMBankBranchByCode.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                  no: item?.branch ?? '',
                }))
              );
              setValue('branchName', '', { shouldValidate: true });
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMBankBranchByCodeId, getMBankCode, setValue]);

  const toNextPhase = handleSubmit((data) => {
    toNext({
      bankName,
      bankCode: data.bankCode,
      branchNo,
      branchName: data.branchName,
      accountType: data.accountType,
      accountNo: data.accountNumber,
      accountName: data.accountName,
      isPersonal: data.isPersonal === '個人',
    });
  });

  return (
    // feat_screen_01-01-05_start
    <div>
      {!isMobile ? (
        <Box width={752} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 口座登録" />

      <Box display="flex" flexDirection="column">
        <Text variant="body16" bold>
          １．お支払い用振替口座
        </Text>
        <Box pt={8}>
          <Text variant="body14">
            月会費など利用料金の支払いは決済代行会社（マネーフォワードケッサイ株式会社）を通じてご請求いたします。
            <br />
          </Text>
          <Text variant="body14" color="cautionRed" bold>
            設定方法に関しては後ほどメールにてご案内いたします。必ずご確認をお願いいたします。
          </Text>
        </Box>
        <Box pt={24}>
          <Text variant="body16" bold>
            ２．振り込み用口座
          </Text>
        </Box>
        <Box pt={8}>
          <Text variant="body14">運営から振り込みがある場合に利用する口座です。</Text>
          <Text variant="body14" color="cautionRed" bold>
            銀行口座情報を以下に入力して次へを押してください。
          </Text>
        </Box>
      </Box>

      <Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>法人/個人</InputLabel>
          <RhfRadioGroup
            rules={{ required: '法人/個人を選択してください。' }}
            defaultValue=""
            control={control}
            name="isPersonal"
            options={[
              { label: '法人', value: '法人' },
              { label: '個人', value: '個人' },
            ]}
          />
          {errors.isPersonal?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.isPersonal.message}
            </Text>
          )}
        </Box>
        <Box display="flex" gap={10}>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel fullWidth>銀行</InputLabel>
            <Controller
              name="bankCode"
              control={control}
              rules={{ required: '銀行を選択してください。' }}
              render={({ field }) => (
                <ComboBox
                  width={328}
                  options={bankOptions}
                  onChange={(newValue) => {
                    field.onChange((newValue as { label: string; value: string })?.value);
                    setBankName((newValue as { label: string; value: string })?.label ?? '');
                  }}
                  value={bankOptions.find((option) => option.value === field.value)}
                />
              )}
            />

            {errors.bankCode?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.bankCode.message}
              </Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel fullWidth>支店</InputLabel>
            <Controller
              name="branchName"
              control={control}
              rules={{ required: '支店を選択してください。' }}
              render={({ field }) => (
                <ComboBox
                  width={328}
                  options={branchOptions}
                  onChange={(newValue) => {
                    field.onChange((newValue as { label: string; value: string })?.value);
                    const selectedBank = branchOptions.find(
                      (branch) => branch.value === (newValue as { label: string; value: string })?.value ?? ''
                    );
                    // 支店noセット
                    if (selectedBank) {
                      setBranchNo((newValue as { label: string; value: string; no: string })?.no ?? '');
                    }
                  }}
                  value={branchOptions.find((option) => option.value === field.value)}
                />
              )}
            />
            {errors.branchName?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.branchName.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>口座種別</InputLabel>
          <RhfRadioGroup
            rules={{ required: '口座種別を選択してください。' }}
            control={control}
            name="accountType"
            options={[
              { label: '普通預金', value: '普通預金' },
              { label: '当座預金', value: '当座預金' },
            ]}
          />
          {errors.accountType?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.accountType.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>口座番号</InputLabel>
          <RhfInput
            width={368}
            control={control}
            name="accountNumber"
            placeholder="口座番号を入力（7桁）"
            rules={{
              required: '口座番号を入力してください。',
              minLength: { value: 7, message: '7桁の数値で入力してください。' },
              maxLength: { value: 7, message: '7桁の数値で入力してください。' },
              pattern: {
                value: /^\d*$/,
                message: '7桁の数値で入力してください。',
              },
            }}
          />
          {errors.accountNumber?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.accountNumber.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>口座名義</InputLabel>
          <RhfInput
            width={368}
            rules={{
              required: '口座名義を入力してください。',
              pattern: {
                value: /^[ア-ンA-Z0-9 ()\-.]+$/g,
                message:
                  '口座名等で使用できる文字は、全角カナ（ヲと小文字を除く）、半角英大文字（A～Z）、半角数字（0～9）、半角スペース、半角記号 4 種類（ （ ） -〔ﾊｲﾌﾝ〕 . 〔ﾋﾟﾘｵﾄﾞ〕）です',
              },
            }}
            control={control}
            name="accountName"
            placeholder="口座名義を入力（セイとメイを分けてカタカナで入力）"
          />
          {errors.accountName?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.accountName.message}
            </Text>
          )}
        </Box>
      </Box>

          <Box display="flex" gap={5} justifyContent="center" pt={40}>
            <MainButton variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              onClick={() => {
                toNextPhase();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      ) : (
        // Mobile Responsive components
        <Box width="100vw" display="flex" flexDirection="column" px={16}>
          <Box>
            <ContentTitle title="新規登録 - 口座登録" />
          </Box>

          {/* feat update 2024/03/18 not responsive yet start */}
          <Box display="flex" flexDirection="column" pt={18}>
            <Box>
              <Text display='block' variant="body14" bold>
                1.お支払い用振替口座
              </Text>
            </Box>
            <Box pt={8} display='flex' flexDirection='column'>
              <Text variant="body14">
                月会費など利用料金の支払いは決済代行会社（マネーフォワードケッサイ株式会社）を通じてご請求いたします。
              </Text>
              <Text variant="body14" color="cautionRed" bold>
                設定方法に関しては後ほどメールにてご案内いたします。必ずご確認をお願いいたします。
              </Text>
            </Box>
            <Box pt={14}>
              <Text display='block' variant="body14" bold>
                2.振り込み用口座
              </Text>
            </Box>
            <Box pt={8} display='flex' flexDirection='column'>
              <Text variant="body14">
                運営から振り込みがある場合に利用する口座です。
              </Text>
              <Text variant="body14" color="cautionRed" bold>
                銀行口座情報を以下に入力して次へを押してください。
              </Text>
            </Box>
          </Box>
          {/* feat update 2024/03/18 not responsive yet end */}

          <Box mt={24}>
            <Box display="flex" flexDirection="column" gap={2} pb={16}>
              <InputLabel>法人/個人</InputLabel>
              <RhfRadioGroup
                rules={{ required: '法人/個人を選択してください。' }}
                defaultValue=""
                control={control}
                name="isPersonal"
                options={[
                  { label: '法人', value: '法人' },
                  { label: '個人', value: '個人' },
                ]}
              />
              {errors.isPersonal?.message && (
                <Box mt={-4}>
                  <Text variant="caption12" color="cautionRed">
                    {errors.isPersonal.message}
                  </Text>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection='column'>
              <Box display="flex" flexDirection="column" gap={2} pb={16}>
                <InputLabel fullWidth>銀行</InputLabel>
                <Controller
                  name="bankCode"
                  control={control}
                  rules={{ required: '銀行を選択してください。' }}
                  render={({ field }) => (
                    <ComboBox
                      width="100%"
                      options={bankOptions}
                      onChange={(newValue) => {
                        field.onChange((newValue as { label: string; value: string })?.value);
                        setBankName((newValue as { label: string; value: string })?.label ?? '');
                      }}
                      value={bankOptions.find((option) => option.value === field.value)}
                    />
                  )}
                />

                {errors.bankCode?.message && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.bankCode.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={2} pb={16}>
                <InputLabel fullWidth>支店</InputLabel>
                <Controller
                  name="branchName"
                  control={control}
                  rules={{ required: '支店を選択してください。' }}
                  render={({ field }) => (
                    <ComboBox
                      width="100%"
                      options={branchOptions}
                      onChange={(newValue) => {
                        field.onChange((newValue as { label: string; value: string })?.value);
                        const selectedBank = branchOptions.find(
                          (branch) => branch.value === (newValue as { label: string; value: string })?.value ?? ''
                        );
                        // 支店noセット
                        if (selectedBank) {
                          setBranchNo((newValue as { label: string; value: string; no: string })?.no ?? '');
                        }
                      }}
                      value={branchOptions.find((option) => option.value === field.value)}
                    />
                  )}
                />
                {errors.branchName?.message && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.branchName.message}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={6} pb={16}>
              <InputLabel>口座種別</InputLabel>
              <RhfRadioGroup
                rules={{ required: '口座種別を選択してください。' }}
                control={control}
                name="accountType"
                options={[
                  { label: '普通預金', value: '普通預金' },
                  { label: '当座預金', value: '当座預金' },
                ]}
              />
              {errors.accountType?.message && (
                <Box mt={-4}>
                  <Text variant="body14" color="cautionRed">
                    {errors.accountType.message}
                  </Text>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={2} pb={16}>
              <InputLabel fullWidth>口座番号</InputLabel>
              <RhfInput
                fullWidth
                width={368}
                control={control}
                name="accountNumber"
                placeholder="口座番号を入力（7桁）"
                rules={{
                  required: '口座番号を入力してください。',
                  minLength: { value: 7, message: '7桁の数値で入力してください。' },
                  maxLength: { value: 7, message: '7桁の数値で入力してください。' },
                  pattern: {
                    value: /^\d*$/,
                    message: '7桁の数値で入力してください。',
                  },
                }}
              />
              {errors.accountNumber?.message && (
                <Box mt={-4}>
                  <Text variant="caption12" color="cautionRed">
                    {errors.accountNumber.message}
                  </Text>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <InputLabel fullWidth>口座名義</InputLabel>
              <RhfInput
                fullWidth
                width={368}
                rules={{
                  required: '口座名義を入力してください。',
                  pattern: {
                    value: /^[ア-ンA-Z0-9 ()\-.]+$/g,
                    message:
                      '口座名等で使用できる文字は、全角カナ（ヲと小文字を除く）、半角英大文字（A～Z）、半角数字（0～9）、半角スペース、半角記号 4 種類（ （ ） -〔ﾊｲﾌﾝ〕 . 〔ﾋﾟﾘｵﾄﾞ〕）です',
                  },
                }}
                control={control}
                name="accountName"
                placeholder="口座名義を入力（セイとメイを分けてカタカナで入力）"
              />
              {errors.accountName?.message && (
                <Box mt={-4}>
                  <Text variant="caption12" color="cautionRed">
                    {errors.accountName.message}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" py={24} gap={5}>
            <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              fullWidth
              customHeight={40}
              onClick={() => {
                toNextPhase();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      )}
    </div>
    // feat_screen_01-01-05_end
  );
}
