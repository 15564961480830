import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { handleMutationResult } from 'error';
import { FormData } from './formState';
import {
  mapFormDataForDaily,
  mappingDailyFormData,
  mappingWeeklyFormData,
  mappingDailyDraftFormData,
  mappingWeeklyDraftFormData,
} from './utils';

/**
 * ［API］バイト募集一覧取得
 */

export function useOfferListConnectApi(initTpmemId: number) {
  const { data, loading, error, refetch } = gql.useGetVtArbeitOfferListByTpmemIdQuery({
    variables: {
      tpmem_id: initTpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    error,
    refetch,
  };
}

/**
 * ［API］バイト募集詳細取得
 */

export function useOfferInfoConnectApi(initId: number) {
  const { data, loading, error, refetch } = gql.useGetVtArbeitOfferInfoByIdQuery({
    variables: {
      id: initId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    error,
    refetch,
  };
}

/**
 *［API］下書き一覧情報取得
 */
export function useDraftListConnectApi(initTpmemId: number) {
  const { data, loading, refetch, error } = gql.useGetVtOfferDraftListByTpmemIdQuery({
    variables: {
      tpmem_id: initTpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    refetch,
    error,
  };
}

/**
 *［API］下書き詳細情報取得
 */
export function useDraftDetailConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [type, setType] = React.useState<number>(0);
  const { data, loading, error } = gql.useGetVtOfferDraftInfoByIdTypeQuery({
    variables: {
      id: Number(id),
      type: Number(type),
    },
    fetchPolicy: 'no-cache',
  });
  return {
    setId,
    setType,
    data,
    loading,
    error,
  };
}
/**
 * 下書きバリテーション用取得するAPI
 * 下書きで使用
 * ※ モーダルの下書きで使用（日程種別が変更されても大丈夫なようにtpmem_id追加）
 */
export function useGetTemplateDraftDataConnenctApi() {
  const [getData, { data }] = gql.useGetVtOfferDraftInfoByIdTypeTpmemIdLazyQuery({ fetchPolicy: 'no-cache' });
  return {
    getData,
    data,
  };
}

/**
 * Figma ID: 04-01-01-13
 * 名称: バイト募集（応募TOP）
 */

export function useEntryListConnectApi(initId: number) {
  /**
   * Type: VTArbeitEntryList
   */
  const [getVtArbeitOfferListByIdMutation, { data, loading }] = gql.useGetVtArbeitOfferListByIdMutation({
    variables: {
      id: initId,
    },
  });
  return {
    getVtArbeitOfferListByIdMutation,
    data,
    loading,
  };
}

/**
 * Figma ID: 04-01-01-23, 04-01-01-34, 04-01-01-37
 * 名称: バイト募集（応募/採用履歴）
 */
export function useLoggingListConnectApi(initTpmemId: number, initOfferId: number) {
  const { data, loading, error } = gql.useGetTLoggingListByTpmemIdQuery({
    variables: {
      tpmem_id: initTpmemId,
      offer_id: initOfferId,
    },
  });
  return {
    data,
    loading,
    error,
  };
}

export function useBaseSkillCostConnectApi() {
  /**
   * Type: VtBtmemBaseSkillCost
   * Type: VtBtSkillCheck
   * Type: VtBtCostInfo
   */
  const [id, setId] = React.useState<number>(0);
  const { data, loading, error, refetch } = gql.useGetVtBtmemBaseSkillCostByEntryIdQuery({
    variables: {
      entry_id: id,
    },
  });
  return {
    setId,
    data,
    loading,
    error,
    refetch,
  };
}

/**
 * フォーム送信（単日）
 */
export function usePostDailyFormConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [apiResponseData, setApiResponseData] = React.useState<gql.VtArbeitOfferInfo>();
  const [updateTArbeitOfferByIdMutation] = gql.useUpdateTArbeitOfferByIdMutation({
    variables: formData && mapFormDataForDaily(id, formData, apiResponseData),
  });

  return {
    updateTArbeitOfferByIdMutation,
    setId,
    setFormData,
    setApiResponseData,
  };
}

/**
 * Figma ID: 04-01-02-09
 * 名称: バイト募集（下書き削除 / 短日）
 */
export function useDeleteDailyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [deleteTArbeitOfferByIdMutation, { data, loading }] = gql.useDeleteTArbeitOfferByIdMutation({
    variables: {
      id,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.deleteTArbeitOfferById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    deleteTArbeitOfferByIdMutation,
    setId,
    data,
    loading,
    error,
  };
}

/**
 * Figma ID: 04-01-01-01
 * [API] Mutation（Delete MultiPost）
 */
export function useDeleteMultiplePostConnectApi() {
  const [ids, setIds] = React.useState<number[]>([]);
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [deleteTArbeitOfferByIdsMutation, { data, loading }] = gql.useDeleteTArbeitOfferByIdsMutation({
    variables: {
      ids,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.deleteTArbeitOfferByIds);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    ids,
    deleteTArbeitOfferByIdsMutation,
    setIds,
    data,
    loading,
    error,
  };
}

/**
 * Figma ID: 04-01-02
 * 名称: バイト募集（下書き削除 / 週定期）
 */
export function useDeleteWeeklyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [deleteTRegularlyOfferByIdMutation, { data, loading }] = gql.useDeleteTRegularlyOfferByIdMutation({
    variables: {
      id,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.deleteTRegularlyOfferById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    deleteTRegularlyOfferByIdMutation,
    setId,
    data,
    loading,
    error,
  };
}

/**
 * 当日担当者を取得するAPI
 */
export function useGetCheifConnenctApi(tpmemId: number) {
  const { data, error } = gql.useGetTManagerByTpmemIdQuery({
    variables: {
      tpmem_id: tpmemId,
    },
  });
  return {
    data,
    error,
  };
}

/**
 * 募集テンプレート情報をIDから取得するAPI
 * 複製/下書き/編集で使用
 */
export function useGetTemplateDataConnenctApi() {
  const [getData, { data }] = gql.useGetVtArbeitOfferMakeInfoByIdLazyQuery({ fetchPolicy: 'no-cache' });
  return {
    getData,
    data,
  };
}

/**
 * 募集テンプレート情報を店舗IDから取得するAPI
 * 新規募集作成時に使用
 */
export function useGetTemplateDataByTpmemIdConnenctApi(tpmemId: number) {
  const [getData, { data }] = gql.useGetVtArbeitOfferMakeInfoByTpmemIdLazyQuery({
    variables: {
      tpmem_id: tpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    getData,
    data,
  };
}

/**
 * Figma ID: 04-01-01-17
 * 名称: 採用
 */

export function useEmployConnectApi(initId: number, initEntryId: number) {
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTArbeitOfferStatus3ByIdMutation, { data, loading }] = gql.useUpdateTArbeitOfferStatus3ByIdMutation({
    variables: {
      entry_id: initEntryId,
      id: initId,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTArbeitOfferStatus3ById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTArbeitOfferStatus3ByIdMutation,
    data,
    loading,
    error,
  };
}

/**
 * Figma ID: 04-01-01-20
 * 名称: 不採用
 */
export function useRejectionConnectApi(initId: number) {
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTArbeitEntryStatus4ByIdMutation, { data, loading }] = gql.useUpdateTArbeitEntryStatus4ByIdMutation({
    variables: {
      id: initId,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTArbeitEntryStatus4ById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTArbeitEntryStatus4ByIdMutation,
    data,
    loading,
    error,
  };
}

/**
 * 募集新規作成：下書き：単日
 */
export function useCreateDailyDraftConnectApi(initTpmemId: number) {
  const [formData, setFormData] = React.useState<FormData>({});
  const [addTArbeitOfferMutation, { data, loading, error }] = gql.useAddTArbeitOfferMutation({
    variables: formData && mappingDailyFormData(initTpmemId, formData),
  });
  return {
    data,
    loading,
    error,
    addTArbeitOfferMutation,
    setFormData,
  };
}

/**
 * 募集新規作成：下書き：週定期
 */
export function useCreateWeeklyDraftConnectApi(initTpmemId: number) {
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [addTRegularlyOfferMutation] = gql.useAddTRegularlyOfferMutation({
    variables: formData && mappingWeeklyFormData(initTpmemId, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.addTRegularlyOffer);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    addTRegularlyOfferMutation,
    setFormData,
    error,
  };
}

/**
 * 募集新規作成：配信：単日
 */
export function useCreateDailyDeliveryConnectApi(initTpmemId: number) {
  const [formData, setFormData] = React.useState<FormData>({});
  const [addTArbeitOfferResMutation] = gql.useAddTArbeitOfferResMutation({
    variables: formData && mappingDailyFormData(initTpmemId, formData),
  });
  return {
    addTArbeitOfferResMutation,
    setFormData,
  };
}

/**
 * 募集新規作成：配信：週定期
 */
export function useCreateWeeklyDeliveryConnectApi(initTpmemId: number) {
  const [formData, setFormData] = React.useState<FormData>({});
  const [addTRegularlyOfferResMutation] = gql.useAddTRegularlyOfferResMutation({
    variables: formData && mappingWeeklyFormData(initTpmemId, formData),
  });
  return {
    addTRegularlyOfferResMutation,
    setFormData,
  };
}

/**
 * 募集下書き 下書き保存/単日
 */
export function useSaveDailyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTArbeitOfferDraftByIdMutation] = gql.useUpdateTArbeitOfferDraftByIdMutation({
    variables: formData && mappingDailyDraftFormData(id, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTArbeitOfferDraftById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTArbeitOfferDraftByIdMutation,
    setId,
    setFormData,
    error,
  };
}
/**
 * 募集下書き 下書き保存/週定期から単日
 */
export function useSaveWeeklyToDailyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [changeTRegularlyOfferToTArbeitOfferByIdMutation] = gql.useChangeTRegularlyOfferToTArbeitOfferByIdMutation({
    variables: formData && mappingDailyDraftFormData(id, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.changeTRegularlyOfferToTArbeitOfferById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    changeTRegularlyOfferToTArbeitOfferByIdMutation,
    setId,
    setFormData,
    error,
  };
}

/**
 * 募集下書き 下書き保存/週定期
 */
export function useSaveWeeklyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();

  const [updateTRegularlyOfferByIdMutation] = gql.useUpdateTRegularlyOfferByIdMutation({
    variables: formData && mappingWeeklyDraftFormData(id, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTRegularlyOfferById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });

  return {
    updateTRegularlyOfferByIdMutation,
    setId,
    setFormData,
    error,
  };
}

/**
 * 募集下書き 下書き保存/単日から週定期
 */
export function useSaveDailyToWeeklyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();

  const [changeTArbeitOfferToTRegularlyOfferByIdMutation] = gql.useChangeTArbeitOfferToTRegularlyOfferByIdMutation({
    variables: formData && mappingWeeklyDraftFormData(id, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.changeTArbeitOfferToTRegularlyOfferById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });

  return {
    changeTArbeitOfferToTRegularlyOfferByIdMutation,
    setId,
    setFormData,
    error,
  };
}

/**
 * 募集下書き 配信/単日
 */
export function useDeliveryDailyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [updateTArbeitOfferResByIdMutation] = gql.useUpdateTArbeitOfferResByIdMutation({
    variables: formData && mappingDailyDraftFormData(id, formData),
  });
  return {
    updateTArbeitOfferResByIdMutation,
    setId,
    setFormData,
  };
}

/**
 * 募集下書き 配信/週定期から単日
 */
export function useDeliveryWeeklyToDailyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [changeTRegularlyOfferToTArbeitOfferResByIdMutation] =
    gql.useChangeTRegularlyOfferToTArbeitOfferResByIdMutation({
      variables: formData && mappingDailyDraftFormData(id, formData),
    });
  return {
    changeTRegularlyOfferToTArbeitOfferResByIdMutation,
    setId,
    setFormData,
  };
}

/**
 * 募集下書き 確定/週定期
 */
export function useDeliveryWeeklyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTRegularlyOfferResByIdMutation] = gql.useUpdateTRegularlyOfferResByIdMutation({
    variables: formData && mappingWeeklyDraftFormData(id, formData),
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTRegularlyOfferResById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTRegularlyOfferResByIdMutation,
    setId,
    setFormData,
    error,
  };
}

/**
 * 募集下書き 確定/単日から週定期
 */
export function useDeliveryDailyToWeeklyDraftConnectApi() {
  const [id, setId] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<FormData>({});
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [changeTArbeitOfferToTRegularlyOfferResByIdMutation] =
    gql.useChangeTArbeitOfferToTRegularlyOfferResByIdMutation({
      variables: formData && mappingWeeklyDraftFormData(id, formData),
      onCompleted: (result) => {
        const r = handleMutationResult(result.changeTArbeitOfferToTRegularlyOfferResById);
        if (r.isSuccessful) {
          setError(undefined);
        } else {
          setError(r.error);
        }
      },
    });
  return {
    changeTArbeitOfferToTRegularlyOfferResByIdMutation,
    setId,
    setFormData,
    error,
  };
}

/**
 * 04-01-01-03
 * ステータス変更（アクティブ--->募集停止）
 */
export function useUpdateOfferStatus6ByIdConnectApi(initId: number) {
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTArbeitOfferStatus6ByIdMutation, { loading }] = gql.useUpdateTArbeitOfferStatus6ByIdMutation({
    variables: {
      id: initId,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTArbeitOfferStatus6ById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTArbeitOfferStatus6ByIdMutation,
    loading,
    error,
  };
}

/**
 * 04-01-01-25
 * ステータス変更（募集停止--->アクティブ）
 */
export function useUpdateOfferStatus1ByIdConnectApi(initId: number) {
  const [error, setError] = React.useState<RequiringCallbackError>();
  const [updateTArbeitOfferStatus1ByIdMutation, { loading }] = gql.useUpdateTArbeitOfferStatus1ByIdMutation({
    variables: {
      id: initId,
    },
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTArbeitOfferStatus1ById);
      if (r.isSuccessful) {
        setError(undefined);
      } else {
        setError(r.error);
      }
    },
  });
  return {
    updateTArbeitOfferStatus1ByIdMutation,
    loading,
    error,
  };
}
