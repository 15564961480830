/**
 * Figma ID: 04-01-03-11
 * 名称: 週定期日程作成 / プレビュー確認 シフト日一覧（モーダル）
 */
import React from 'react';
import { styled } from '@linaria/react';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { ThreeDotLeader } from 'components/threeDotLeader';
import useIsMobile from 'hooks/responsive/useIsMobile';

const StyledSpan = styled.span`
  cursor: pointer;
`;
// feat screen 04-01-03-16 start
const StyledTransitionTop = styled.div`
  transform: translateY(-5px);
`;
// feat screen 04-01-03-16 end

type MappedData = {
  id: number;
  label: string;
  date: string;
};

type Props = {
  mappedWeeks?: MappedData[];
  previewPdf: (shiftDate : string) => void;
};

export function WeeklyPreviewList({ mappedWeeks, previewPdf }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat screen 04-01-03-16 start
  return !isMobile ? (
    <Info
      items={[
        {
          content: `${String(mappedWeeks?.length)}件`,
          label: '配信予定件数',
        },
        {
          content: mappedWeeks?.map((item) => (
            <React.Fragment key={`${String(item.id)}${Math.random()}`}>
              <Box alignItems="center" display="flex" justifyContent="space-between" py={12}>
                <Text color="black" variant="body14">
                  <StyledSpan onClick={() => {}}>{item.label}</StyledSpan>
                </Text>
                <ThreeDotLeader
                  menu={[
                    {
                      label: '労働条件通知書プレビュー',
                      value: 'preview',
                    },
                  ]}
                  right={0}
                  width={160}
                  onClick={() => {previewPdf(item.date);}}
                />
              </Box>
              <Divider length={0} option="horizontal" />
            </React.Fragment>
          )),
          label: 'シフト日一覧',
          align: 'start',
          pt: 14,
        },
      ]}
    />
  ) : (
    // Responsive Component
    <Info
      items={[
        {
          content: `${String(mappedWeeks?.length)}件`,
          label: '配信予定件数',
        },
        {
          content: (
            <Box mt={-8}>
              {mappedWeeks?.map((item) => (
                  <React.Fragment key={`${String(item.id)}${Math.random()}`}>
                    <Box alignItems="flex-start" display="flex" justifyContent="space-between" py={8}>
                      <Text color="black" variant="body14">
                        <StyledSpan onClick={() => {}}>{item.label}</StyledSpan>
                      </Text>
                      <StyledTransitionTop>
                        <ThreeDotLeader
                          menu={[
                            {
                              label: '労働条件通知書プレビュー',
                              value: 'preview',
                            },
                          ]}
                          right={0}
                          width={188}
                          onClick={() => {previewPdf(item.date);}}
                        />
                      </StyledTransitionTop>
                    </Box>
                    <Divider length={0} option="horizontal" />
                  </React.Fragment>
                ))
              }
            </Box>
          ),
          label: 'シフト日一覧',
          align: 'start',
        },
      ]}
    />
    // feat screen 04-01-03-16 end
  );
}
