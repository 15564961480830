import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { Header } from 'layout/header';
import { MainMenuBar } from 'components/mainMenuBar/mainMenuBar';
import { MainMenu } from 'components/mainMenuBar/types';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Tab } from './tab';

export type Props = {
  children: React.ReactNode;
  currentRoute: string;
  mailAddress: string;
  userName: string;
  onClickLogout: () => void;
  mainMenuBarData: MainMenu[];
  headerContent?: JSX.Element;
};

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  /* feat_common_responsive_layout_start */
  @media only screen and (max-width: 768px) {
    height: 100svh;
  }
  /* feat_common_responsive_layout_end */
`;

const StyledColumn = styled.div`
  display: flex;
  height: calc(100vh - 64px);
  /* feat_common_responsive_layout_start */
  @media only screen and (max-width: 768px) {
    height: calc(100svh - 64px);
  }
  /* feat_common_responsive_layout_end */
`;

const StyledOpen = styled.div`
  flex: auto;
  height: calc(100vh - 96px);
  overflow: auto;
  padding: 24px;
  /* feat_common_responsive_layout_start */
  @media only screen and (max-width: 768px) {
    padding: 0;
    height: 100%;
  }
  /* feat_common_responsive_layout_end */
`;

// 各画面の呼び出しベース（左にサイドメニュー）
export function Layout({
  children,
  currentRoute,
  mailAddress,
  userName,
  onClickLogout,
  mainMenuBarData,
  headerContent,
}: Props) {
  // サイドメニュー状態
  /* feat_bottom_tab_responsive_mobile_start */
  const isMobile = useIsMobile();
  /* feat_bottom_tab_responsive_mobile_end */
  // feat screen_03-01-01 start
  const [isOpenMainMenuBar, setIsOpenMainMenuBar] = useState<boolean>(() => !(window.innerWidth < 768));
  // feat screen_03-01-01 end
  return (
    <StyledWrapper>
      <Header
        isOpenMainMenuBar={isOpenMainMenuBar}
        currentRoute={currentRoute}
        mailAddress={mailAddress}
        userName={userName}
        toggleMainMenubar={(value) => setIsOpenMainMenuBar(value)}
        onClickLogout={onClickLogout}
      />
      <StyledColumn>
        <MainMenuBar
          currentRoute={currentRoute}
          isOpenMainMenuBar={isOpenMainMenuBar}
          toggleMainMenubar={(value) => setIsOpenMainMenuBar(value)}
          mainMenuBarData={mainMenuBarData}
          headerContent={headerContent}
        />
        <StyledOpen>{children}</StyledOpen>
      </StyledColumn>
      {/* feat_bottom_tab_responsive_mobile_start */}
      {isMobile && (
        <Tab currentRoute={currentRoute} mainMenuBarData={mainMenuBarData} isOpenMainMenuBar={isOpenMainMenuBar} />
      )}
      {/* feat_bottom_tab_responsive_mobile_end */}

    </StyledWrapper>
  );
}
