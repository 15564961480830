import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-04-05
 * 招待完了
 */

function InvitationCompletedModal(props: { isOpen: boolean; onModalClose: () => void }) {
  const { isOpen, onModalClose } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        グループ招待 完了
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Text variant="body14" color="black">
          グループ招待が完了しました。
          <br />
          先方が招待を承認するまでお待ち下さい。
        </Text>
      </Box>
    ) : (
      // feat_screen_09-04-05_start
      <Box>
        <Text display="block" variant="body14" color="black">
          グループ招待が完了しました。
          <br />
          先方が招待を承認するまでお待ち下さい。
        </Text>
      </Box>
      // feat_screen_09-04-05_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton 
          // feat_screen_09-04-05_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-05_end
          variant="secondary" 
          onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat_screen_09-04-05_start
      width={!isMobile ? 384 : "100%"}
      // feat_screen_09-04-05_end
    />
  );
}

export default InvitationCompletedModal;
