import React, { useCallback, useEffect } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { useForm } from 'react-hook-form';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 05-01-04-02
 * Thanksギフト支給
 */

interface FormType {
  giftCode: string;
  paymentType: 'wallet' | 'transfer';
}

function SelectGiftModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onClickNext: (giftType: string, paymentType: string) => void;
  giftCodeOptions: {
    label: string;
    value: string;
    price: number;
    issuance_fee: number;
  }[];
  walletAmount: number;
  shouldInitializeForm: boolean;
}) {
  const { isOpen, onModalClose, onClickNext, giftCodeOptions, walletAmount, shouldInitializeForm } = props;
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: { giftCode: '', paymentType: 'wallet' },
  });

  const currentGiftCode = watch('giftCode');
  const currentGift = giftCodeOptions.find(({ value }) => value === currentGiftCode);
  const currentPaymentType = watch('paymentType');

  const numberFormatter = Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const initializeForm = useCallback(() => {
    setValue('giftCode', '');
    setValue('paymentType', 'wallet');
  }, [setValue]);

  function onClose() {
    initializeForm();
    clearErrors();
    onModalClose();
  }

  useEffect(() => {
    if (shouldInitializeForm) {
      initializeForm();
    }
  }, [shouldInitializeForm, initializeForm]);

  function validate() {
    if (currentPaymentType === 'transfer') {
      return true;
    }

    if (!currentGift) {
      return 'Thanksギフトを選択してください';
    }

    if (currentGift.price > walletAmount) {
      return 'ウォレットは残高不足で選択できません';
    }

    return true;
  }

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        Thanksギフト支給
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box my={8}>
          <Text variant="body14" color="black">
            支給するThanksギフトを選択し、ギフトの購入方法を選択してください。
          </Text>
        </Box>
        <Box my={16}>
          <Text variant="h3" color="blueGray">
            Thanksギフトを選択
          </Text>
          <Box my={4}>
            <RhfSelect control={control} name="giftCode" options={giftCodeOptions} placeholder="選択" />
          </Box>
        </Box>
        <Box mt={24}>
          <Text variant="h3" color="blueGray">
            購入方法
          </Text>
          <Box my={8}>
            <RhfRadioGroup
              control={control}
              name="paymentType"
              options={[
                { label: 'ウォレットから', value: 'wallet' },
                { label: '請求支払', value: 'transfer' },
              ]}
              defaultValue="wallet"
            />
            {errors.paymentType && (
              <Text variant="caption11" color="cautionRed">
                {errors.paymentType.message}
              </Text>
            )}
          </Box>
          {currentPaymentType === 'wallet' && (
            <Box display="flex" flexDirection="column" gap={16} mt={24}>
              <Text variant="caption11" color="blueGray">
                ウォレット残高より購入します。現在残高は
                <Text variant="caption12" color="blueGray" bold>
                  {numberFormatter.format(walletAmount)}
                </Text>
                です。
              </Text>
              <Text variant="caption11" color="blueGray">
                ＊ウォレット支払はギフト単価にのみ適用され、ギフト発行手数料は請求されます。
              </Text>
            </Box>
          )}
          {currentPaymentType === 'transfer' && (
            <Box display="flex" flexDirection="column" gap={16} mt={24}>
              <Text variant="caption11" color="blueGray">
                月次の振替時にまとめてご請求します。
              </Text>
              <Text variant="caption11" color="blueGray">
                ＊ウォレット支払はギフト単価にのみ適用され、ギフト発行手数料は請求されます。
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    ) : (
      // feat_screen_05-01-04-02_start
      <Box display="flex" flexDirection="column" gap={16}>
        <Box>
          <Text display="block" variant="body14" color="black">
            支給するThanksギフトを選択し、ギフトの購入方法を選択してください。
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            Thanksギフトを選択
          </Text>
          <Box>
            <RhfSelect height={40} fullWidth control={control} name="giftCode" options={giftCodeOptions} placeholder="選択" />
          </Box>
        </Box>
        <Box>
          <Box display='flex' flexDirection='column'>
            <Text variant="h3" color="blueGray">
              購入方法
            </Text>
            <Box my={10}>
              <RhfRadioGroup
                control={control}
                name="paymentType"
                options={[
                  { label: 'ウォレットから', value: 'wallet' },
                  { label: '請求支払', value: 'transfer' },
                ]}
                defaultValue="wallet"
              />
              {errors.paymentType && (
                <Text variant="caption11" color="cautionRed">
                  {errors.paymentType.message}
                </Text>
              )}
            </Box>
          </Box>
          {currentPaymentType === 'wallet' && (
            <Box display="flex" flexDirection="column" gap={16} mt={6}>
              <Text variant="caption11" color="blueGray">
                ウォレット残高より購入します。現在残高は
                <Text variant="caption12" color="blueGray" bold>
                  {numberFormatter.format(walletAmount)}
                </Text>
                です。
              </Text>
              <Text variant="caption11" color="blueGray">
                ＊ウォレット支払はギフト単価にのみ適用され、ギフト発行手数料は請求されます。
              </Text>
            </Box>
          )}
          {currentPaymentType === 'transfer' && (
            <Box display="flex" flexDirection="column" gap={16} mt={6}>
              <Text variant="caption11" color="blueGray">
                月次の振替時にまとめてご請求します。
              </Text>
              <Text variant="caption11" color="blueGray">
                ＊ウォレット支払はギフト単価にのみ適用され、ギフト発行手数料は請求されます。
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      // feat_screen_05-01-04-02_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
        // feat_screen_05-01-04-02_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_05-01-04-02_end
        variant="secondary" 
        onClick={() => onClose()}>
          閉じる
        </MainButton>
        <MainButton
          // feat_screen_05-01-04-02_end
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-04-02_end
          variant="primary"
          onClick={() => {
            const validationResult = validate();

            if (validationResult === true) {
              clearErrors();
              onClickNext(currentGiftCode, currentPaymentType);
            } else {
              setError('paymentType', { message: validationResult });
            }
          }}
          disabled={currentGiftCode === ''}
        >
          次へ
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat_screen_05-01-04-02_start
      width={!isMobile ? 384 : "100%"}
      // feat_screen_05-01-04-02_end
    />
  );
}

export default SelectGiftModal;
