import React,{ useMemo } from 'react';
import QRCodeReact from "qrcode.react";
import { Box } from 'components/box';
import useIsMobile from 'hooks/responsive/useIsMobile';

// QRコードの種類（01:チェックイン、02:チェックアウト）
export enum QrCodeType {
  CHECKIN = '01',
  CHECKOUT = '02',
}

type Props = {
  type: QrCodeType;
  brandId: number|undefined;
  tpmemId: number|undefined;
  size: number;
  padding?: number;
};

// QRコード表示
export function QrCode(props: Props) {
  const { type, brandId, tpmemId, size, padding} = props;
  
  // QRコードの余白(デフォルトで30px)
  const qrCodePadding = padding || 30;

  // QRコードデータ
  const qrcodeValue = useMemo<string>(() => {
    // 店舗メンバID 10桁0埋め
    const wtmpMemId =('0'.repeat(10) + String(tpmemId)).slice(-10);
    // ブランドID 5桁0埋め
    const wbrandId =('0'.repeat(5)+ String(brandId)).slice(-5);
    // QRコードデータ
    // チェックイン/アウト + 店舗メンバID + ブランドIDを空白で連結
    const qrValue = `${type} ${wtmpMemId} ${wbrandId}`;
    
    return qrValue;
  }, [type, brandId, tpmemId]);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box width={size} height={size} display="flex" justifyContent="center" alignItems="center">
      <QRCodeReact size={size - qrCodePadding} value={qrcodeValue} level="H"/>
    </Box>
  ): (
    /* feat_screen_04-02-01-08_start */
    <Box width={size} height={size} display="flex" justifyContent="center" alignItems="center">
      <QRCodeReact size={size} value={qrcodeValue} level="H"/>
    </Box>
    /* feat_screen_04-02-01-08_end */
  );
}
