// feat mobile responsive filter toggle button start
import React from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { Icon } from '../icon/icon';

const StyledRelative = styled.div`
    position: relative;
    width: 100%;
    button {
        cursor: none;
    }
    &.m-active {
        min-height: ${(isOpen) => !isOpen ? 0 : "30px"};
    }
`;

const StyledAbsolute = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 8px;   
    border-radius: 4px 4px 0px 0px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
`;

const StyledIcon = styled.div`
    text-align: center;
    width: 24px;
    height: 24px;
    background-color: transparent;
    overflow: hidden;
`;

function MobileFilterButton(props: {
    setState: React.Dispatch<React.SetStateAction<boolean>>;
    state: boolean;
  }): JSX.Element {
    const { setState, state } = props;
    const handleShowFilter = () => {
        setState((prev) => !prev);
    };
  return (
    <StyledRelative className={!state ? cx('m-active') : cx('')} onClick={handleShowFilter}>
        <StyledAbsolute>
        <StyledIcon>
            <Icon size={24} name= {state ? "chevronLess" : "chevronMore"} />
        </StyledIcon>
        </StyledAbsolute>
    </StyledRelative> 
  );
}

export default MobileFilterButton ;
// feat mobile responsive filter toggle button end