// feat screen 00 start
/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { useNavigate } from 'react-router-dom';
import { styled } from '@linaria/react';
import { MainButton } from 'components/mainButton';
import { Icon } from 'components/icon';

const StyledNavBlock = styled.button`
  width: 100%;
  height:70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008599;
  border-radius: 10px;
  font-size: 2rem;
  line-height: 2.5rem;
  border: none;
  color: #fff;
;`;

const StyledRightSide = styled.div`
  display: block;
  height: 24px;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transform: translateY(-15px);
`;

export function QuickLinks() {
  const navigate = useNavigate();

  const handleNavigate = (link?: string) => {
    if (link) {
      navigate(link);
    }
  };
  return (
    // feat_common_responsive_layout_start
    <Box>
      <Box width="100%" display='flex' alignItems='center' flexDirection='column' height={24}>
        <Box width="100%" display='flex' justifyContent='flex-end'>
          <StyledRightSide> 
            <Icon
              name="helpOutline"
              color="#008599"
              onClick={() => {
                const helpOfServiceUrl = process.env.REACT_APP_HELP_SERVICE;
                if (helpOfServiceUrl) {
                  window.open(helpOfServiceUrl, '_blank');
                }
              }}
            />
          </StyledRightSide>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' flexDirection='column' gap={40}>
        <StyledNavBlock onClick={() => {
          handleNavigate('/s06');
        }}>
          所属登録対応
        </StyledNavBlock>
        <StyledNavBlock onClick={() => {
          handleNavigate('/s05-01');
        }}>
          所属一覧
        </StyledNavBlock>
        <StyledNavBlock onClick={() => {
          handleNavigate('/s08/0');
        }}>
          ランク別育成料
        </StyledNavBlock>
      </Box>
      <Box mt={22} display='flex' alignItems='center' justifyContent='center' pb={30}>
        <Box width={342} display='flex' justifyContent='flex-end' mt={2}>
          <MainButton customHeight={40} width={171.5} variant='secondary' onClick={() => {
            handleNavigate("/s00");
          }}>
            戻る
          </MainButton>
        </Box>
      </Box>
    </Box>
  );
}
// feat screen 00 end