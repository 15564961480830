import { Box } from 'components/box';
import { Card } from 'components/card';
import { Text } from 'components/text';
import {
  VtBelongList,
  useAddelTFavoriteBtmemByIdBtmemIdMutation,
  useGetVtBelongListByTpmemIdQuery,
} from 'graphql/graphql-mw';
import React, { useCallback, useMemo, useState } from 'react';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Loading } from 'components/loading/loading';
import { useAuth } from 'hooks/authProvider';
import { List } from 'components/list';
import { Avatar } from 'components/avatar';
import { Chip } from 'components/chip';
import { Icon } from 'components/icon';
import { useErrorModal } from 'components/error/errorModalProvider';
import { orderBy } from 'lodash';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { ListHeaderType, ListItemsType } from './type';
import { IsFavorite, BelongBtmemKind, MemberStatus, BtmemBlock } from './const';
import { ImgWrapper } from './components/imgWrapper';

const StyledText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

/* feat_screen_05-01-01-01_start */
const StyledAvatar = styled.div`
  position: relative;
  right: 4px;
`;

const StyledFavorite = styled.div`
  position: relative;
  top: 2px;
  right: 3px;
`;

const StyledChip = styled.div`
  position: relative;
  top: 0.5px;
`;

const StyledEye = styled.div`
  position: relative;
  top: 1.4px;
`;
/* feat_screen_05-01-01-01_end */

export function S0501() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { handleChangeLimit, handleChangePage, limit, page, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort();
  const { openErrorModal } = useErrorModal();
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end

  const tpmemId = auth?.tpmemId;
  if (tpmemId === null) navigate('/signin');

  const [filterValue, setFilterValue] = useState<{
    btmem_id?: string;
    attribute?: string;
    favorite?: string;
  }>();
  const [btmemId, setBtmemId] = useState<string>('');

  const {
    data: { getVTBelongListByTpmemId: data } = {
      getVTBelongListByTpmemId: [] as VtBelongList[],
    },
    loading: vtBelongListLoading,
    error: vtBelongListError,
    refetch,
  } = useGetVtBelongListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const handleFilterValueClear = () => {
    setFilterValue(undefined);
    setBtmemId('');
  };

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'eye', width: 24 },
      { key: 'favorite', width: 24 },
      { key: 'image', width: 40 },
      /* feat_screen_05-01-01-01_start */
      { key: 'kind', width: !isMobile ? 56 : 71, columnName: '種別' },
      { key: 'btmem_number', width: !isMobile ? 112 : 92, columnName: '会員番号' },
      /* feat_screen_05-01-01-01_end */
      { key: 'age', width: 56, columnName: '年齢' },
      /* feat_screen_05-01-01-01_start */
      { key: 'rank_name', width: !isMobile ? 64 : 70, columnName: 'ランク' },
      { key: 'score', width: !isMobile ? 64 : 70, columnName: 'スコア' },
      /* feat_screen_05-01-01-01_end */
      // { key: 'score', width: 88, columnName: '自店スコア' }, // DBにないため削除？
      /* feat_screen_05-01-01-01_start */
      { key: 'attribute', width: !isMobile ? 104 : 90, columnName: '種類' },
      /* feat_screen_05-01-01-01_end */
      { key: 'gender', width: 56, columnName: '性別' },
      /* feat_screen_05-01-01-01_start */
      { key: 'uniform_size', width: !isMobile ? 88 : 100, columnName: '制服サイズ' },
      /* feat_screen_05-01-01-01_end */
    ],
    [
      /* feat_screen_05-01-01-01_start */
      isMobile,
      /* feat_screen_05-01-01-01_end */
    ]
  );

  const [updateFavoriteStatus, { loading: updateFavoriteStatusLoading, error: updateFavoriteStatusError }] =
    useAddelTFavoriteBtmemByIdBtmemIdMutation();

  React.useEffect(() => {
    if (vtBelongListError || updateFavoriteStatusError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vtBelongListError, updateFavoriteStatusError, openErrorModal]);

  const toggleFavoriteStatus = useCallback(
    (isFavorite?: number | null, btmemNumber?: number | null) => {
      if (typeof isFavorite !== 'number' || typeof btmemNumber !== 'number' || typeof tpmemId !== 'number') {
        return new Promise(() => {});
      }
      return updateFavoriteStatus({
        variables: {
          btmem_id: btmemNumber,
          id: tpmemId,
          on_off: isFavorite === IsFavorite.TRUE ? IsFavorite.FALSE : IsFavorite.TRUE,
        },
      });
    },
    [tpmemId, updateFavoriteStatus]
  );

  const onClickFavorite = useCallback(
    (isFavorite?: number | null, btmemNumber?: number | null) => {
      if (updateFavoriteStatusLoading) {
        return;
      }
      toggleFavoriteStatus(isFavorite, btmemNumber).then(() => {
        refetch();
      });
    },
    [updateFavoriteStatusLoading, toggleFavoriteStatus, refetch]
  );

  const listItems: ListItemsType = useMemo(() => {
    if (vtBelongListLoading) return [];
    let items = data;

    if (filterValue?.btmem_id && filterValue.btmem_id !== '全て') {
      const regexp = new RegExp(filterValue.btmem_id, 'i');
      items = items.filter(({ btmem_number }) => btmem_number?.match(regexp));
    }
    if (filterValue?.attribute && filterValue.attribute !== '全て') {
      items = items.filter(({ kind }) => {
        if (filterValue.attribute === '自店') {
          return kind === BelongBtmemKind.PRIMARY;
        }
        return kind === BelongBtmemKind.SECONDARY;
      });
    }
    if (filterValue?.favorite && filterValue.favorite !== '全て') {
      items = items.filter(({ favorite }) => {
        if (filterValue.favorite === 'お気に入り済') {
          return favorite === IsFavorite.TRUE;
        }
        return favorite === IsFavorite.FALSE;
      });
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    // リストの背景判定
    const rowBackgroundColor = (identification: number | undefined) => {
      switch (identification) {
        case MemberStatus.PERMANENTLY_DISABLE:
        case MemberStatus.TEMPORARILY_DISABLE:
        case MemberStatus.SUSPENSION:
          return 'red';
        default:
          return 'white';
      }
    };
    // Imageのラベル取得
    const rowLabel = (identification: number | undefined) => {
      switch (identification) {
        case MemberStatus.PERMANENTLY_DISABLE:
          return '一時停止';
        case MemberStatus.TEMPORARILY_DISABLE:
          return '停止';
        case MemberStatus.SUSPENSION:
          return '休止';
        default:
          return '';
      }
    };
    // ImageのColor取得
    const rowImageColor = (identification: number | undefined) => {
      switch (identification) {
        case MemberStatus.PERMANENTLY_DISABLE:
          return 'rgba(0, 0, 0, 0.7)';
        case MemberStatus.TEMPORARILY_DISABLE:
          return 'rgba(0, 0, 0, 0.7)';
        case MemberStatus.SUSPENSION:
          return 'rgba(229, 0, 0, 0.7)';
        default:
          return '';
      }
    };

    return items.map((item, key) => {
      /* feat_screen_05-01-01-01_start */
      const renderImage = () => {
        if (item?.status === MemberStatus.SUSPENSION ||
            item?.status === MemberStatus.PERMANENTLY_DISABLE ||
            item?.status === MemberStatus.TEMPORARILY_DISABLE) {
          if (!isMobile) {
            return (
                <ImgWrapper
                    width={40}
                    height={40}
                    memberstatus={item?.status}
                    backgroundcolor={rowImageColor(item?.status)}
                    isMobile={isMobile}
                    text={<StyledText>{rowLabel(item?.status)}</StyledText>}
                >
                    <Avatar src={item.image ?? ''} width={40} height={40} />
                </ImgWrapper>
            );
          }
          return (
            <StyledAvatar>
                <ImgWrapper
                    width={40}
                    height={40}
                    memberstatus={item?.status}
                    backgroundcolor={rowImageColor(item?.status)}
                    isMobile={isMobile}
                    text={<StyledText>{rowLabel(item?.status)}</StyledText>}
                >
                    <Avatar src={item.image ?? ''} width={40} height={40} />
                </ImgWrapper>
            </StyledAvatar>
          );
        }
        if (!isMobile) {
          return <Avatar src={item.image ?? ''} width={40} height={40} />;
        }
        return (
          <StyledAvatar>
            <Avatar src={item.image ?? ''} width={40} height={40} />
          </StyledAvatar>
        );
      };

      const renderEye = () =>
        !isMobile ? (
          <Link to={`${location.pathname}/${item.btmem_number ?? ''}`}>
            <Icon name="eye" />
          </Link>
        ) : (
          <StyledEye>
            <Link to={`${location.pathname}/${item.btmem_number ?? ''}`}>
              <Icon name="eye" size={20} />
            </Link>
          </StyledEye>
        );

      const renderFavorite = () =>
        !isMobile ? (
          <Icon
            name={item.favorite === IsFavorite.TRUE ? 'favoriteBorderO' : 'favoriteBorder'}
            onClick={() => onClickFavorite(item.favorite, parseInt(item.btmem_number ?? '0', 10))}
          />
        ) : (
          <StyledFavorite>
            <Icon
              size={20}
              name={item.favorite === IsFavorite.TRUE ? 'favoriteBorderO' : 'favoriteBorder'}
              onClick={() => onClickFavorite(item.favorite, parseInt(item.btmem_number ?? '0', 10))}
            />
          </StyledFavorite>
        );

      const renderKind = () =>
        !isMobile ? (
          <Chip width={56} color="darkBlue">
            {item?.kind === BelongBtmemKind.PRIMARY ? '所属' : 'サブ'}
          </Chip>
        ) : (
          <StyledChip>
            <Chip width={56} height={14} color="darkBlue">
              {item?.kind === BelongBtmemKind.PRIMARY ? '所属' : 'サブ'}
            </Chip>
          </StyledChip>
        );

      return {
        image: renderImage(),
        eye: renderEye(),
        favorite: renderFavorite(),
        kind: renderKind(),
        btmem_number: item?.btmem_number,
        age: item?.age,
        rank_name: item?.rank_name,
        score: item?.score,
        attribute: item?.attribute,
        gender: item?.gender,
        uniform_size: item?.uniform_size,
        backgroundColor: item.block === BtmemBlock.TRUE ? 'red' : 'white',
        uniqueKey: key,
      };
      /* feat_screen_05-01-01-01_end */
    });
  }, [
    data,
    filterValue,
    vtBelongListLoading,
    location.pathname,
    sort,
    onClickFavorite,
    /* feat_screen_05-01-01-01_start */
    isMobile,
    /* feat_screen_05-01-01-01_end */
  ]);

  return !isMobile ? (
    <>
      {vtBelongListLoading && <Loading />}
      <Box display="flex" flexDirection="column" height="100%" gap={26}>
        <Box display="flex" height={30}>
          <Text variant="h1" color="darkBlue">
            自店・サブ一覧
          </Text>
        </Box>
        <Box display="flex" flex="auto">
          <Card overflow="hidden">
            <Box display="flex" flexDirection="row" pa={16} gap={16} alignItems="center">
              <Input
                name="btmem_number"
                onChange={(e) => setBtmemId(e.target.value)}
                value={btmemId}
                width={160}
                placeholder="会員番号"
              />
              <MainButton
                icon="search"
                width={80}
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  setFilterValue({ ...filterValue, btmem_id: btmemId });
                }}
              >
                検索
              </MainButton>
              <Divider option="vertical" length={24} />
              <Select
                name="attribute"
                options={[
                  { value: '全て', label: '全て' },
                  { value: '自店', label: '自店' },
                  { value: 'サブ', label: 'サブ' },
                ]}
                onChange={(value) => {
                  // ページ初期化
                  setPage(1);
                  setFilterValue({ ...filterValue, attribute: value });
                }}
                value={filterValue?.attribute ?? ''}
                width={120}
                placeholder="種別"
              />
              <Select
                name="favorite"
                options={[
                  { value: '全て', label: '全て' },
                  { value: 'お気に入り済', label: 'お気に入り済' },
                  { value: 'お気に入り未', label: 'お気に入り未' },
                ]}
                onChange={(value) => {
                  // ページ初期化
                  setPage(1);
                  setFilterValue({ ...filterValue, favorite: value });
                }}
                value={filterValue?.favorite ?? ''}
                width={140}
                placeholder="お気に入り"
              />
              <MainButton
                variant="clear"
                width={44}
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  handleFilterValueClear();
                }}
              >
                クリア
              </MainButton>
            </Box>
            <Divider option="horizontal" />
            <Box flex="auto" width="100%" px={16}>
              <PaginationBox
                dataSize={listItems.length}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={317}>
                  <List
                    header={listHeader}
                    items={listItems.slice(limit * (page - 1), limit * page)}
                    key={String(limit) + String(page) + String(sort?.key || '')}
                    sort={sort}
                    onChangeSort={handleChangeSort}
                    rowWidth="100%"
                    rowHeight={56}
                    width="100%"
                  />
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  ) : (
    /* feat_screen_05-01-01-01_start */
    <>
      {vtBelongListLoading && <Loading />}
      <Box display="flex" flexDirection="column" columnGap={16} width="100vw" height="100%">
        <Box>
          <MultiMenuHeader
            items={[
              {
                label: 'バイトラベラー',
              },
              {
                label: '自店・サブ一覧',
              },
            ]}
            mainHeader="自店・サブ一覧"
          />
        </Box>
        <Box display="flex" overflow="auto" flex="auto" px={16} pt={16} pb={isMobile ? 76 : 16}>
          <Card overflow="auto">
            <Box display={isShowFilter ? 'block' : 'none'}>
              <Box display="flex" flexDirection="column" gap={12} px={16} pt={16}>
                <Box display="flex" gap={12} justifyContent="space-between">
                  <Box flex="1">
                    <Input
                      name="btmem_number"
                      onChange={(e) => setBtmemId(e.target.value)}
                      value={btmemId}
                      fullWidth
                      placeholder="会員番号"
                    />
                  </Box>
                  <MainButton
                    icon="search"
                    width={80}
                    customHeight={40}
                    onClick={() => {
                      // ページ初期化
                      setPage(1);
                      setFilterValue({ ...filterValue, btmem_id: btmemId });
                    }}
                  >
                    検索
                  </MainButton>
                </Box>
                <Box display="flex" gap={12}>
                  <Select
                    fullWidth
                    height={40}
                    name="attribute"
                    options={[
                      { value: '全て', label: '全て' },
                      { value: '所属', label: '所属' },
                      { value: 'サブ', label: 'サブ' },
                    ]}
                    onChange={(value) => {
                      // ページ初期化
                      setPage(1);
                      setFilterValue({ ...filterValue, attribute: value });
                    }}
                    value={filterValue?.attribute ?? ''}
                    placeholder="種別"
                  />
                  <Select
                    fullWidth
                    height={40}
                    name="favorite"
                    options={[
                      { value: '全て', label: '全て' },
                      { value: 'お気に入り済', label: 'お気に入り済' },
                      { value: 'お気に入り未', label: 'お気に入り未' },
                    ]}
                    onChange={(value) => {
                      // ページ初期化
                      setPage(1);
                      setFilterValue({ ...filterValue, favorite: value });
                    }}
                    value={filterValue?.favorite ?? ''}
                    placeholder="お気に入り"
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" pr={20} mt={4} pb={8.5}>
                <MainButton
                  variant="clear"
                  width={44}
                  onClick={() => {
                    // ページ初期化
                    setPage(1);
                    handleFilterValueClear();
                  }}
                >
                  クリア
                </MainButton>
              </Box>
            </Box>
            <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
            <Box flex="auto" width="100%">
              <PaginationBox
                dataSize={listItems.length}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={isShowFilter ? 393 : 270} bottomSE={270}>
                  <List
                    header={listHeader}
                    items={listItems.slice(limit * (page - 1), limit * page)}
                    key={String(limit) + String(page) + String(sort?.key || '')}
                    sort={sort}
                    onChangeSort={handleChangeSort}
                    rowWidth="100%"
                    rowHeight={58}
                    width="100%"
                  />
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
    /* feat_screen_05-01-01-01_end */
  );
}
