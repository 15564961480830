import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 05-01-04-05
 * Thanksギフト支給確認
 */

function ConfirmGiftModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onClickBack: () => void;
  onClickSubmit: () => void;
  giftTypeName: string;
  giftTypePrice: number;
  giftTypeIssuanceFee: number;
  walletAmount: number;
  paymentType: string;
}) {
  const {
    isOpen,
    onModalClose,
    onClickBack,
    onClickSubmit,
    giftTypeName,
    giftTypePrice,
    giftTypeIssuanceFee,
    walletAmount,
    paymentType,
  } = props;

  const numberFormatter = Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });

  function onClose() {
    onModalClose();
  }

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        Thanksギフト支給 確認
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box my={8}>
          <Text variant="body14" color="black">
            以下でThanksギフトを支給します。 <br />
            よろしいですか？
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Box my={8} display="flex">
          <Box width={200}>
            <Box>
              <Text variant="body14">{giftTypeName}</Text>
            </Box>
            <Box>
              <Text variant="caption12" color="blueGray">
                {paymentType === 'wallet' ? 'ウォレットから購入' : '請求支払'}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant="caption12">{numberFormatter.format(giftTypePrice)}(内税)</Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box my={8} display="flex">
          <Box width={200}>
            <Box>
              <Text variant="body14">ギフト発行手数料</Text>
            </Box>
            <Box>
              <Text variant="caption12" color="blueGray">
                請求支払
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant="caption12">{numberFormatter.format(giftTypeIssuanceFee)}(外税)</Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        {paymentType === 'wallet' && (
          <Box my={8} display="flex">
            <Box width={200}>
              <Text variant="body14">ウォレット残高</Text>
            </Box>
            <Box>
              <Text variant="caption12">{numberFormatter.format(walletAmount - giftTypePrice)}</Text>
            </Box>
          </Box>
        )}
      </Box>
    ) : (
      // feat_screen_05-01-04-05_start
      <Box display="flex" flexDirection="column" gap={15}>
        <Box pt={-4}>
          <Text display="block" variant="body14" color="black">
            以下でThanksギフトを支給します。 <br />
            よろしいですか？
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" justifyContent="space-between">
          <Box width={200}>
            <Box>
              <Text variant="body14">{giftTypeName}</Text>
            </Box>
            <Box>
              <Text variant="caption12" color="blueGray">
                {paymentType === 'wallet' ? 'ウォレットから購入' : '請求支払'}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant="caption12">{numberFormatter.format(giftTypePrice)}</Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" justifyContent='space-between'>
          <Box width={200}>
            <Box>
              <Text variant="body14">ギフト発行手数料</Text>
            </Box>
            <Box>
              <Text variant="caption12" color="blueGray">
                請求支払
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant="caption12">{numberFormatter.format(giftTypeIssuanceFee)}(外税)</Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        {paymentType === 'wallet' && (
          <Box display="flex" flexDirection="column" gap={16}>
            <Box display="flex" justifyContent="space-between">
              <Box width={200}>
                <Text variant="body14">ウォレット残高</Text>
              </Box>
              <Box>
                <Text align="right" variant="caption12">{numberFormatter.format(walletAmount - giftTypePrice)}</Text>
              </Box>
            </Box>
            <Divider option="horizontal" />
          </Box>
        )}
      </Box>
      // feat_screen_05-01-04-05_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_05-01-04-05_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-04-05_end
          variant="secondary"
          onClick={() => onClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          // feat_screen_05-01-04-05_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-04-05_end
          variant="secondary"
          onClick={() => onClickBack()}
        >
          戻る
        </MainButton>
        <MainButton
          // feat_screen_05-01-04-05_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-04-05_end
          variant="primary"
          onClick={() => onClickSubmit()}
        >
          支給
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat_screen_05-01-04-05_start
      width={!isMobile ? 384 : "100%"}
      // feat_screen_05-01-04-05_end
    />
  );
}

export default ConfirmGiftModal;
