import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function PasswordChangedModal(props: Props) {
  const { isOpen, onClose } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const header = (
    <Text variant="h2" color="darkBlue">
      パスワード編集 完了
    </Text>
  );

  const content = (
    <Box>
      <Text variant="body14">パスワードの変更が完了しました。</Text>
    </Box>
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-03-06_start
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        // feat_screen_09-03-06_end
        variant="secondary" 
        onClick={() => onClose()}
      >
        閉じる
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}
      content={content}
      footer={footer}
      height="auto"
      // feat_screen_09-03-06_start
      width={!isMobile ? 384 : "100%"}
      // feat_screen_09-03-06_end
    />
  );
}

export default PasswordChangedModal;
