import React, { HTMLInputTypeAttribute, useId } from 'react';
import { styled } from '@linaria/react';
import { Input } from '../input';
import { InputLabel } from '../inputLabel';
import { Text } from '../text';
import { ErrorMessage } from '../errorMessage';

export interface Props {
  label: string;
  optional?: boolean;
  name?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  errorMessage?: string;
  width?: number;
  fullWidth?: boolean;
  readonly?: boolean;
}

const StyledDiv = styled.div<{ width: number; fullWidth?: boolean }>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  height: 78px;
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    height: unset;
    margin-bottom: 10px;
  }
  /* feat screen_01-01-03_end */
`;

const StyledLabelWrapper = styled.div`
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
  /* feat screen_01-01-03_end */
  margin-bottom: 4px;
  height: 24px;
`;

const StyledReadOnlyWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    height: unset;
  }
  /* feat screen_01-01-03_end */
`;

export function BasicForm({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  optional,
  errorMessage,
  width = 328,
  fullWidth,
  readonly,
}: Props) {
  const id = useId();

  return (
    <StyledDiv width={width} fullWidth={fullWidth}>
      <StyledLabelWrapper>
        <InputLabel htmlFor={id} optional={optional} width={width} fullWidth={fullWidth}>
          {label}
        </InputLabel>
      </StyledLabelWrapper>
      {readonly ? (
        <StyledReadOnlyWrapper>
          <Text variant="body14">{value}</Text>
        </StyledReadOnlyWrapper>
      ) : (
        <Input
          id={id}
          name={name}
          type={type}
          value={value}
          width={width}
          placeholder={placeholder}
          fullWidth={fullWidth}
          onChange={onChange}
          error={Boolean(errorMessage)}
        />
      )}
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </StyledDiv>
  );
}
