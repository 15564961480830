import React from 'react';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider/divider';
import { Card } from 'components/card';
import { Box } from 'components/box';
import { AgreementStatus, FixWorkingHoursMwStatus, ResultRows } from 'components/const';
import { CorrectionStatus } from 'pages/s04-02/components/const';
import { MainButton } from 'components/mainButton';
import { useUpdateTWorkModByIdMutation, useFcGetTotalSalaryLazyQuery } from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { format as formatDate } from 'date-fns';
import { useErrorModal } from 'components/error/errorModalProvider';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { CorrectionModel, WorkLogModel, RejectionDataModel } from './types';
import RejectionModal from './modal/workLog/RejectionModal';
import DenialModal from './modal/workLog/DenialModal';
import DenialCompletedModal from './modal/workLog/DenialCompletedModal';
import ApprovalModal from './modal/workLog/ApprovalModal';
import ApprovalCompletedModal from './modal/workLog/ApprovalCompletedModal';
import RejectionConfirmationModal from './modal/workLog/RejectionConfirmationModal';
import RejectionCompletedModal from './modal/workLog/RejectionCompletedModal';

/**
 * Figma ID: 04-02-04-02,04-02-05-02,04-02-05-03,04-02-05-04,04-02-06-02,04-02-06-05,04-02-06-11,04-02-06-12,04-02-06-13,,04-02-09-03
 * 名称: 勤務時間/修正
 */

const noShowText = '出勤実態無し';

const StatusToCautionCheckIn = [AgreementStatus.NO_CHECK_IN, AgreementStatus.NO_CHECK_IN_OUT];
const StatusToCautionCheckOut = [AgreementStatus.NO_CHECK_OUT, AgreementStatus.NO_CHECK_IN_OUT];

const enum ModalType {
  APPROVAL = 'approval',
  APPROVAL_COMPLETED = 'approvalCompleted',
  REJECTION = 'rejection',
  REJECTION_CONFIRMATION_PREPARE = 'rejectionConfirmationPrepare',
  REJECTION_CONFIRMATION = 'rejectionConfirmation',
  REJECTION_COMPLETED = 'rejectionCompleted',
  DENIAL = 'denial',
  DENIAL_COMPLETED = 'denialCompleted',
}

interface CorrectionTargetModel {
  requestedDateTime: string;
  breakTime: string;
  totalSalary: string;
  reason: string;
}

function WorkLog(props: {
  workLogData?: WorkLogModel;
  correctionData?: CorrectionModel[];
  status?: AgreementStatus | null;
  refetch?: () => void;
}) {
  const { workLogData, correctionData, status, refetch } = props;

  const shouldCautionCheckIn = status !== undefined && status !== null && StatusToCautionCheckIn.includes(status);
  const shouldCautionCheckOut = status !== undefined && status !== null && StatusToCautionCheckOut.includes(status);

  const [activeModal, setActiveModal] = React.useState<ModalType | null>(null);
  const [correctionTarget, setCorrectionTarget] = React.useState<CorrectionTargetModel | null>(null);

  const [rejectionData, setRejectionData] = React.useState<RejectionDataModel | null>(null);

  const { openErrorModal } = useErrorModal();

  // 承認・否認処理に使う修正依頼のID。
  // correctionDataの最初(最新)のIDを使う。
  const modId = correctionData?.[0]?.id;
  const workId = correctionData?.[0]?.work_id;

  const requestedCheckIn = correctionData?.[0]?.requestedCheckInTime || new Date();
  const requestedCheckOut = correctionData?.[0]?.requestedCheckOutTime || new Date();

  const [approveRequest, { loading: loadingApproval }] = useUpdateTWorkModByIdMutation({
    variables: {
      id: modId || 0,
      status: FixWorkingHoursMwStatus.APPROVE_REQUEST,
    },
  });

  const [rejectRequest, { loading: loadingRejection }] = useUpdateTWorkModByIdMutation({
    variables: {
      id: modId || 0,
      status: FixWorkingHoursMwStatus.REJECTED_REQUEST,
      advise_checkin: formatDate(
        new Date(
          rejectionData?.checkInDate?.getFullYear() || 0,
          rejectionData?.checkInDate?.getMonth() || 0,
          rejectionData?.checkInDate?.getDate() || 0,
          Number(rejectionData?.checkInHour || 0),
          Number(rejectionData?.checkInMin || 0)
        ),
        'yyyy-MM-dd HH:mm:ss'
      ),
      advise_checkout: formatDate(
        new Date(
          rejectionData?.checkOutDate?.getFullYear() || 0,
          rejectionData?.checkOutDate?.getMonth() || 0,
          rejectionData?.checkOutDate?.getDate() || 0,
          Number(rejectionData?.checkOutHour || 0),
          Number(rejectionData?.checkOutMin || 0)
        ),
        'yyyy-MM-dd HH:mm:ss'
      ),
      deny_reason: rejectionData?.comment,
    },
  });

  const [denyRequest, { loading: loadingDenial }] = useUpdateTWorkModByIdMutation({
    variables: {
      id: modId || 0,
      status: FixWorkingHoursMwStatus.REJECTED_REQUEST,
      advise_notwork: true,
    },
  });
  // 業務報酬合計取得
  const [fcGetTotalSalary, { data: totalSalaryData, loading: totalSalaryLoading }] = useFcGetTotalSalaryLazyQuery({
    variables: {
      work_id: workId || 0,
      fix_in: formatDate(
        new Date(
          rejectionData?.checkInDate?.getFullYear() || 0,
          rejectionData?.checkInDate?.getMonth() || 0,
          rejectionData?.checkInDate?.getDate() || 0,
          Number(rejectionData?.checkInHour || 0),
          Number(rejectionData?.checkInMin || 0)
        ),
        'yyyy-MM-dd HH:mm:ss'
      ),
      fix_out: formatDate(
        new Date(
          rejectionData?.checkOutDate?.getFullYear() || 0,
          rejectionData?.checkOutDate?.getMonth() || 0,
          rejectionData?.checkOutDate?.getDate() || 0,
          Number(rejectionData?.checkOutHour || 0),
          Number(rejectionData?.checkOutMin || 0)
        ),
        'yyyy-MM-dd HH:mm:ss'
      ),
    },
  });
  // 業務報酬の合計
  const [totalSalary, setTotalSalary] = React.useState(0);

  const maxSalary = 24000; // 1日の報酬最大金額
  const [isExceededSalary, setSalary] = React.useState(false);

  // 否認確認で合計取得
  React.useEffect(() => {
    if (activeModal === ModalType.REJECTION_CONFIRMATION_PREPARE) {
      fcGetTotalSalary()
        .then((result) => {
          if (result.error) {
            setActiveModal(null);
            openErrorModal({ message: '業務報酬合計取得エラーが発生しました' });
          } else if (result.data && result.data.FcGetTotalSalary.total && result.data.FcGetTotalSalary.salary) {
            setActiveModal(ModalType.REJECTION_CONFIRMATION);
            setTotalSalary(result.data.FcGetTotalSalary.total);
            setSalary(result.data.FcGetTotalSalary.salary > maxSalary); // 1日の報酬最大金額を報酬金額（交通費を除いた金額）超えているかどうか
          }
        })
        .catch(() => {
          setActiveModal(null);
          openErrorModal({ message: '業務報酬合計取得でエラーが発生しました' });
        });
    }
  }, [activeModal, fcGetTotalSalary, openErrorModal, totalSalaryData]);

  // 修正依頼が2個以上あるか = 再修正依頼があるかを判断する
  const isResubmitted =
    correctionData && correctionData?.filter((data) => data.status === CorrectionStatus.REQUEST_SUBMITTED).length > 1;

  function renderCheckInOutTime(checkInOutTime: string | undefined, shouldCaution: boolean) {
    return (
      <Text variant="body14" color={shouldCaution ? 'cautionRed' : 'black'} bold>
        {checkInOutTime}
      </Text>
    );
  }

  function renderCorrectionData(
    data: CorrectionModel,
    index: number,
    arr: CorrectionModel[],
    agreementStatus?: AgreementStatus | null,
    /* feat_screen_04-02-06-02_start */
    isMobile?: boolean
    /* feat_screen_04-02-06-02_end */
  ) {
    const shouldRenderButtons =
      agreementStatus === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS &&
      data.status === CorrectionStatus.REQUEST_SUBMITTED &&
      index === 0;

    function renderLabel() {
      switch (data.status) {
        case CorrectionStatus.REQUEST_SUBMITTED:
          return (
            // 最初の修正依頼のみ「修正依頼」、それ以降は「再修正依頼」を表示する
            <Text variant="body14" color={index === 0 ? 'black' : 'liteGray'} bold>
              {isResubmitted && index < arr.length - 1 ? '再修正依頼がありました。' : '修正依頼がありました。'}
            </Text>
          );
        case CorrectionStatus.APPROVED:
          return (
            <Text variant="body14" color="okBlue" bold>
              修正依頼を承認しました。
            </Text>
          );
        case CorrectionStatus.REJECTED:
          return (
            <Text variant="body14" color="cautionRed" bold>
              {data.requestedDateTime === noShowText ? '修正依頼を拒否しました。' : '修正依頼を否認しました。'}
            </Text>
          );
        default:
          return null;
      }
    }

    function renderButtons() {
      return (
        /* feat_screen_04-02-06-03_start */
        <Box display="flex" gap={!isMobile ? 8 : 12} mt={!isMobile ? 0 : -2}>
        {/* feat_screen_04-02-06-03_end */}
          <MainButton
            /* feat_screen_04-02-06-03_start */
            width={!isMobile ? 104 : 96}
            /* feat_screen_04-02-06-03_end */
            variant="warnSecondary"
            thin
            onClick={() => {
              setCorrectionTarget({
                requestedDateTime: data.requestedDateTime,
                breakTime: data.breakTime,
                totalSalary: data.totalSalary,
                reason: data.comment || '',
              });
              setActiveModal(ModalType.REJECTION);
            }}
          >
            否認
          </MainButton>
          <MainButton
            /* feat_screen_04-02-06-03_start */
            width={!isMobile ? 104 : 96}
            /* feat_screen_04-02-06-03_end */
            thin
            onClick={() => {
              setActiveModal(ModalType.APPROVAL);
            }}
          >
            承認
          </MainButton>
        </Box>
      );
    }

    function renderTimeLabel() {
      if (data.status === CorrectionStatus.REJECTED) {
        return '修正案';
      }
      return '修正依頼時間';
    }

    function renderTime() {
      if (data.requestedDateTime === noShowText) {
        return (
            <Text variant="body14" color="cautionRed" >
            {noShowText}
          </Text>
        );
      }

      if (isMobile) {
        return (
            <Box width={200} display="flex" flexDirection="column" alignItems="flex-end">
              <Text variant="body14">
                {data.requestedDateTime?.includes('～')
                    ? `${data.requestedDateTime.split('～')[0]}～`
                    : data.requestedDateTime || ''}
              </Text>
              {data.requestedDateTime?.includes('～') && (
                  <Text variant="body14">
                    {data.requestedDateTime.split('～')[1]}&nbsp;&nbsp;休憩{data.breakTime || ''}
                  </Text>
              )}
            </Box>
        );
      }

      return (
        <Text variant="body14">
          {data.requestedDateTime || ''}&nbsp;&nbsp;休憩{data.breakTime || ''}
        </Text>
      );
    }

    function renderCommentLabel() {
      switch (data.status) {
        case CorrectionStatus.REJECTED:
          return 'コメント';
        case CorrectionStatus.REQUEST_SUBMITTED:
        case CorrectionStatus.APPROVED:
        default:
          return '修正依頼理由';
      }
    }

    return !isMobile ? (
      <Box mb={12} key={`correction-requests-${index}`}>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" gap={8} pt={8}>
          <Box display="flex" justifyContent="space-between">
            {renderLabel()}
            {shouldRenderButtons && renderButtons()}
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                {renderTimeLabel()}
              </Text>
            </Box>
            <Box>
              <Text variant="body14">{renderTime()}</Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                業務報酬合計（交通費込み）
              </Text>
            </Box>
            <Box>
              <Text variant="body14">¥{data.totalSalary}</Text>
            </Box>
          </Box>
          {data.status !== CorrectionStatus.APPROVED && (
            <Box display="flex" alignItems="center">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  {renderCommentLabel()}
                </Text>
              </Box>
              <Box>
                <Text variant="body14">{data.comment || '-'}</Text>
              </Box>
            </Box>
          )}
          <Box>
            <Text variant="caption11" color="darkGray">
              {data.submittedDateTime}
            </Text>
          </Box>
        </Box>
      </Box>
    ) : (
    /* feat_screen_04-02-06-02_start */
      <Box key={`correction-requests-${index}`}>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" gap={8} pt={16}>
          <Box display="flex" flexDirection="column" gap={8}>
            {renderLabel()}
            {shouldRenderButtons && renderButtons()}
          </Box>
          <Box display="flex" alignItems="start" justifyContent="space-between" pt={8}>
            <Box width={180}>
              <Text variant="body14" color="darkGray">
                {renderTimeLabel()}
              </Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Text align="right" variant="caption12">{renderTime()}</Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="start" justifyContent="space-between">
            <Box width={130} display="flex" flexDirection="column">
              <Text variant="body14" color="darkGray">
                業務報酬合計
              </Text>
              <Text variant="body14" color="darkGray">
                (交通費込み)
              </Text>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Text align="right" variant="body14">¥{data.totalSalary}</Text>
            </Box>
          </Box>
          {data.status !== CorrectionStatus.APPROVED && (
            <Box display="flex" alignItems="start" justifyContent='space-between'>
              <Box width={130}>
                <Text variant="body14" color="darkGray">
                  {renderCommentLabel()}
                </Text>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Text align="right" variant="body14">{data.comment || '-'}</Text>
              </Box>
            </Box>
          )}
          <Box>
            <Text variant="caption11" color="darkGray">
              {data.submittedDateTime}
            </Text>
          </Box>
        </Box>
      </Box>
    );
    /* feat_screen_04-02-06-02_end */
  }

  const loading = loadingApproval || loadingRejection || loadingDenial|| totalSalaryLoading;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <>
      {loading && <Loading />}
      {
        !isMobile ? (
          <Box pa={18} width="100%">
            <Box display="flex" alignItems="center" mb={16}>
              <Text variant="h2" bold color="darkBlue">
                勤務時間 / 修正
              </Text>
            </Box>
            <Divider option="horizontal" />
            <Box my={16}>
              <Card>
                <Box pa={16}>
                  <Box>
                    <Text variant="body14" bold color="darkBlue">
                      勤怠実績
                    </Text>
                  </Box>
                  <Box display="flex" mt={8} gap={16}>
                    <Box width="33%">
                      <Box>
                        <Text variant="body14" color="darkGray">
                          バイト期間 / 時間
                        </Text>
                      </Box>
                      <Box my={8}>
                        <Text variant="body14" bold>
                          {workLogData?.beginEnd} {/* APIから整形済み文字列で取得 */}
                        </Text>
                      </Box>
                    </Box>
                    <Divider option="vertical" length={50} />
                    <Box width="33%">
                      <Box>
                        <Text variant="body14">チェックイン（実績）</Text>
                      </Box>
                      <Box my={8}>{renderCheckInOutTime(workLogData?.checkInTime, shouldCautionCheckIn)}</Box>
                    </Box>
                    <Divider option="vertical" length={50} />
                    <Box width="33%">
                      <Box>
                        <Text variant="body14">チェックアウト（実績）</Text>
                      </Box>
                      <Box my={8}>{renderCheckInOutTime(workLogData?.checkOutTime, shouldCautionCheckOut)}</Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
            {correctionData && correctionData.length > 0 && (
              <Box height="calc(100% - 176px)" overflow="scroll" mb={8}>
                {correctionData.map((data, index, arr) => renderCorrectionData(data, index, arr, status))}
                <Divider option="horizontal" />
              </Box>
            )}
          </Box>
        ): (
          /* feat_screen_04-02-04-02_start */
          <Box height="calc(100% - 48px)" overflow="auto">
            <Box
              display="flex"
              flexDirection="column"
              gap={16}
              pa={16}
              width="100%"
              pb={ correctionData && correctionData.length > 0 ? 20 : 200}
            >
              <Box display="flex" alignItems="center" justifyContent="center" py={2}>
                <Text variant="h2" bold color="darkBlue">
                  勤務時間 / 修正
                </Text>
              </Box>
              <Divider option="horizontal" />
              <Box>
                <Card>
                  <Box display="flex" flexDirection="column" py={12} px={16} gap={4}>
                    <Box>
                      <Text variant="body14" bold color="darkBlue">
                        勤怠実績
                      </Text>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={16}>
                      <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                          <Text variant="body14" color="darkGray">
                            バイト期間 / 時間
                          </Text>
                        </Box>
                        <Box>
                          <Text variant="body14" bold>
                            {workLogData?.beginEnd} {/* APIから整形済み文字列で取得 */}
                          </Text>
                        </Box>
                      </Box>
                      <Divider option="horizontal"/>
                      <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                          <Text variant="body14">チェックイン（実績）</Text>
                        </Box>
                        <Box>{renderCheckInOutTime(workLogData?.checkInTime, shouldCautionCheckIn)}</Box>
                      </Box>
                      <Divider option="horizontal"/>
                      <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                          <Text variant="body14">チェックアウト（実績）</Text>
                        </Box>
                        <Box>{renderCheckInOutTime(workLogData?.checkOutTime, shouldCautionCheckOut)}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
              {correctionData && correctionData.length > 0 && (
                <Box display="flex" flexDirection="column" gap={16}>
                  {correctionData.map((data, index, arr) => renderCorrectionData(data, index, arr, status, isMobile))}
                  <Divider option="horizontal" />
                </Box>
              )}
            </Box>
          </Box>
          /* feat_screen_04-02-04-02_end */
        )
      }
      <RejectionModal
        requestedCheckIn={requestedCheckIn}
        requestedCheckOut={requestedCheckOut}
        requestedDateTime={correctionTarget?.requestedDateTime || ''}
        breakTime={correctionTarget?.breakTime || ''}
        totalSalary={correctionTarget?.totalSalary || ''}
        reason={correctionTarget?.reason || ''}
        isOpen={activeModal === ModalType.REJECTION}
        onModalClose={() => setActiveModal(null)}
        onDeny={() => setActiveModal(ModalType.DENIAL)}
        onReject={(data) => {
          setRejectionData(data);
          setActiveModal(ModalType.REJECTION_CONFIRMATION_PREPARE);
        }}
      />
      {rejectionData && (
        <RejectionConfirmationModal
          isOpen={activeModal === ModalType.REJECTION_CONFIRMATION}
          onModalClose={() => setActiveModal(null)}
          onClickBack={() => {
            setSalary(false);
            setActiveModal(ModalType.REJECTION);
          }}
          onClickSubmit={() => {
            rejectRequest()
              .then((result) => {
                if (!result.errors) {
                  // ResultRowsをみないと登録できたかわからないため
                  if (result.data?.updateTWorkModById.resultRows === ResultRows.NOT_UPDATED) {
                    setActiveModal(null);
                    openErrorModal({ message: '否認できませんでした。' });
                  } else {
                    setActiveModal(ModalType.REJECTION_COMPLETED);
                    refetch?.();
                  }
                } else {
                  setActiveModal(null);
                  openErrorModal({ message: '否認できませんでした。' });
                }
              })
              .catch(() => {
                setActiveModal(null);
                openErrorModal({
                  message: '否認できませんでした。',
                });
              });
          }}
          rejectionData={rejectionData}
          totalSalary={totalSalary}
          isExceededSalary={isExceededSalary}
        />
      )}
      <RejectionCompletedModal
        isOpen={activeModal === ModalType.REJECTION_COMPLETED}
        onModalClose={() => setActiveModal(null)}
      />
      <DenialModal
        isOpen={activeModal === ModalType.DENIAL}
        onModalClose={() => setActiveModal(null)}
        onClickBack={() => setActiveModal(ModalType.REJECTION)}
        onClickSubmit={() => {
          denyRequest().then((result) => {
            if (!result.errors) {
              setActiveModal(ModalType.DENIAL_COMPLETED);
              refetch?.();
            } else {
              setActiveModal(null);
              openErrorModal({
                title: 'エラー',
                message: 'バックレ認定処理中にエラーが発生しました。もう一度やり直してください。',
              });
            }
          });
        }}
      />
      <DenialCompletedModal
        isOpen={activeModal === ModalType.DENIAL_COMPLETED}
        onModalClose={() => setActiveModal(null)}
      />
      <ApprovalModal
        /* feat_screen_04-02-06-03_add_prop_start */
        isMobile={isMobile}
        /* feat_screen_04-02-06-03_add_prop_end */
        isOpen={activeModal === ModalType.APPROVAL}
        onModalClose={() => setActiveModal(null)}
        onClickSubmit={() => {
          approveRequest()
            .then((result) => {
              if (!result.errors) {
                // ResultRowsをみないと登録できたかわからないため
                if (result.data?.updateTWorkModById.resultRows === ResultRows.NOT_UPDATED) {
                  setActiveModal(null);
                  openErrorModal({ message: '承認できませんでした。' });
                } else {
                  setActiveModal(ModalType.APPROVAL_COMPLETED);
                  refetch?.();
                }
              } else {
                setActiveModal(null);
                openErrorModal({ message: '承認できませんでした。' });
              }
            })
            .catch(() => {
              setActiveModal(null);
              openErrorModal({
                message: '承認できませんでした。',
              });
            });
        }}
      />
      <ApprovalCompletedModal
        /* feat_screen_04-02-06-04_add_prop_start */
        isMobile={isMobile}
        /* feat_screen_04-02-06-04_add_prop_end */
        isOpen={activeModal === ModalType.APPROVAL_COMPLETED}
        onModalClose={() => setActiveModal(null)}
      />
    </>
  );
}

export default WorkLog;
