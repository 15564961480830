import React from 'react';
import { colorPalette } from 'components/text/text';
import { Vertical, Horizontal } from 'components/assets/css/components/divider/divider';

/* feat_common_divider_add_type_color_start */
export type ColorKeyType = keyof typeof colorPalette;
/* feat_common_divider_add_type_color_end */

export default function Divider(props: { 
    length?: number; 
    option: 'vertical' | 'horizontal';
    /* feat_common_divider_add_props_start */
    color?: ColorKeyType
    /* feat_common_divider_add_props_end */
  }): JSX.Element {
  const { 
    length = 0, 
    option,
    /* feat_common_divider_add_props_start */
    color = 'superLiteGray'
    /* feat_common_divider_add_props_end */
  } = props;

  return option === 'vertical' ? 
  <Vertical 
      height={length}
      /* feat_common_divider_add_props_start */
      color={color}
      /* feat_common_divider_add_props_end */
    /> : 
    <Horizontal 
    width={length} 
    /* feat_common_divider_add_props_start */
    color={color}
    /* feat_common_divider_add_props_end */
    />;
}
