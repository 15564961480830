import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { Modal } from 'components/newModal';
import useIsMobile from 'hooks/responsive/useIsMobile';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClickManual: () => void;
}

function FailModal(props: Props) {
  const { isOpen, onClose, onClickManual } = props;
  /* feat_common_responsive_useMobile_logic_start */  
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        顔認証 失敗
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14">
          顔認証に失敗しました。
          <br /> 再度行うか、手動認証を行ってください。
        </Text>
      </Box>
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClickManual()}>
          手動認証
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_04-02-02-05_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClickManual()}>
          手動認証
        </MainButton>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
      /* feat_screen_04-02-02-05_end */
    ),
    /* feat_screen_04-02-02-05_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : "unset",
    /* feat_screen_04-02-02-05_end */

  };

  return (
    <Modal
      header={modalContent.header}
      width={modalContent.width}
      height={modalContent.height}
      content={modalContent.content}
      footer={modalContent.footer}
      isOpen={isOpen}
      onClose={() => {}}
    />
  );
}

export default FailModal;
