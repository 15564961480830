import { GENERIC_COLOR } from 'components/assets/css/style';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import React from 'react';
import { useFcCheckCanQuitTpmemLazyQuery, useUpdateTMemberQuitByIdMutation } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { MemberStoreStatus } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import ConfirmationModal from '../../modals/cancelMembership/ConfirmationModal';
import FinalConfirmationModal from '../../modals/cancelMembership/FinalConfirmationModal';
import CompleteModal from '../../modals/cancelMembership/CompleteModal';
import CannotCancelModal from '../../modals/cancelMembership/CannotCancelModal';

/**
 * Figma ID
 * 09-05-01
 * 退会
 */

enum ModalType {
  CONFIRMATION = 'CONFIRMATION',
  FINAL_CONFIRMATION = 'FINAL_CONFIRMATION',
  COMPLETE = 'COMPLETE',
  CANNOT_CANCEL = 'CANNOT_CANCEL',
}

function CancelMembershipScreen(props: { refetch: () => void }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { refetch } = props;
  const { tpmemId: loginId } = useAuth();
  const { openErrorModal } = useErrorModal();

  const [activeModal, setActiveModal] = React.useState<ModalType | null>(null);

  const [checkCanQuit, { loading: checkCanQuitLoading }] = useFcCheckCanQuitTpmemLazyQuery();

  const [cancelMembership, { loading: cancelMembershipLoading }] = useUpdateTMemberQuitByIdMutation({
    variables: {
      id: loginId || 0,
    },
  });

  async function checkCanCancel() {
    if (!loginId) {
      return false;
    }

    const checkCanQuitData = await checkCanQuit({
      variables: {
        tpmem_id: loginId,
      },
    });
    return checkCanQuitData.data?.FcCheckCanQuitTpmem?.is_quit || false;
  }

  const loading = checkCanQuitLoading || cancelMembershipLoading;

  const memberStoreStatus = useMemberStoreStatus();

  // feat screen 09-05-01 start
  const defaultContent = !isMobile ? (
    <>
      <Box ma={8}>
        <Text variant="body14" color="blueGray" bold>
          退会可能条件
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14" color="cautionRed" bold>
          以下に当てはまる場合は退会が可能です。
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14">
          ・ウォレット内に残高がない。
          <br />
          ・退会手続日が25日以前の場合、翌月以降の採用者がいない。
          <br />
          ・退会手続日が26日以降の場合、翌々月以降の採用者がいない。
          <br />
          ・自店に所属するバイトラベラーの実施中バイトがない。
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14" color="darkGray">
          ※ウォレット残高が払い戻し手数料以下の場合、ウォレットの消滅をもって退会が可能となります。
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14" color="cautionRed" bold>
          退会を行うと以下の権利が消滅、または解除となります。
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14">
          ・グループは手続日に即解除となります。
          <br /> ・店舗ブロックは退会日に削除となります。
          <br />
          ・バイトラベラーのブロックは退会日に削除となります。
          <br />
          ・トライアルチケットは退会日に消滅します。
          <br />
          ・お気に入りは退会日に削除します。
          <br />
          ・退会日後の募集は強制停止になります。
          <br />
          ・自店に所属するバイトラベラーやサブのバイトラベラーは手続日に解除されます。
          <br />
          ・退会手続後はウォレットへの入金は出来ません。
          <br />
          ・解除前の応募採用バイトの育成料は運営が徴収します。
          <br />
          ・自店に所属するバイトラベラーのグループ店への応募中バイトは強制キャンセルされます。
          <br />
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Text variant="body14" color="darkGray">
          ※退会日は手続きが25日までの場合は当月末日、26日以降の場合は翌月末日となります。
          <br />
          ※バイト終了日時が退会日前までの募集はご利用できます。
          <br />
          ※採用手数料は翌月に振替いたします。
        </Text>
      </Box>
      <Box mx={8} display="flex" justifyContent="end">
        <MainButton
          icon="wavingHand"
          iconColor={GENERIC_COLOR.CAUTION_RED}
          variant="warnSecondary"
          thin
          onClick={() => {
            checkCanCancel().then((result) => {
              if (result) {
                setActiveModal(ModalType.CONFIRMATION);
              } else {
                setActiveModal(ModalType.CANNOT_CANCEL);
              }
            });
          }}
        >
          退会手続き
        </MainButton>
      </Box>
    </>
  ) : (
    // Responsive Component
    <>
      <Box py={16} pb={14}>
        <Text variant="body14" color="blueGray" bold>
          退会可能条件
        </Text>
      </Box>
      <Box pb={16}>
        <Text variant="body14" color="cautionRed" bold>
          以下に当てはまる場合は退会が可能です。
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" gap={8} pb={14}>
        <Text display="block" variant="body14">
          ・ウォレット内に残高がない。
        </Text>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            退会手続日が25日以前の場合、翌月以降の採用者がいない。
          </Text>
        </Box>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            退会手続日が26日以降の場合、翌々月以降の採用者がいない。
          </Text>
        </Box>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            自店に所属するバイトラベラーの実施中バイトがない。
          </Text>
        </Box>
      </Box>
      <Box pb={14} display="flex">
        <Text display="block" variant="body14">
          ※&nbsp;&nbsp;
        </Text>
        <Text display="block" variant="body14" color="darkGray">
          ウォレット残高が払い戻し手数料以下の場合、ウォレットの消滅をもって退会が可能となります。
        </Text>
      </Box>
      <Box pb={15}>
        <Text variant="body14" color="cautionRed" bold>
          退会を行うと以下の権利が消滅、または解除となります。
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" gap={8}>
        <Text display="block" variant="body14">
          ・グループは手続日に即解除となります。
        </Text>
        <Text display="block" variant="body14">
          ・店舗ブロックは退会日に削除となります。
        </Text>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            バイトラベラーのブロックは退会日に削除となります。
          </Text>
        </Box>
        <Text display="block" variant="body14">
          ・トライアルチケットは退会日に消滅します。
        </Text>
        <Text display="block" variant="body14">
          ・お気に入りは退会日に削除します。
        </Text>
        <Text display="block" variant="body14">
          ・退会日後の募集は強制停止になります。
        </Text>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            自店に所属するバイトラベラーやサブのバイトラベラーは手続日に解除されます。
          </Text>
        </Box>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            退会手続後はウォレットへの入金は出来ません。
          </Text>
        </Box>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            解除前の応募採用バイトの育成料は運営が徴収します。
          </Text>
        </Box>
        <Box display="flex">
          <Text display="block" variant="body14">
            ・
          </Text>
          <Text display="block" variant="body14">
            自店に所属するバイトラベラーのグループ店への応募中バイトは強制キャンセルされます。
          </Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={16} my={16}>
        <Text display="block" variant="body14" color="darkGray">
          ※ 退会日は月末末日になります。
        </Text>
        <Box display="flex">
          <Text display="block" variant="body14">
            ※&nbsp;
          </Text>
          <Text display="block" variant="body14" color="darkGray">
            バイト終了日時が退会日前までの募集はご利用できます。
          </Text>
        </Box>
        <Text display="block" variant="body14" color="darkGray">
          ※ 採用手数料は翌月に振替いたします。
        </Text>
      </Box>
      <Box display="flex" justifyContent="start" width="100%" pb={24}>
        <MainButton
          icon="wavingHand"
          iconColor={GENERIC_COLOR.CAUTION_RED}
          iconSize={20}
          variant="warnSecondary"
          width={120}
          onClick={() => {
            checkCanCancel().then((result) => {
              if (result) {
                setActiveModal(ModalType.CONFIRMATION);
              } else {
                setActiveModal(ModalType.CANNOT_CANCEL);
              }
            });
          }}
        >
          退会手続き
        </MainButton>
      </Box>
    </>
  // feat screen 09-05-01 end
  );

  const pausedUserContent = (
    // feat screen 09-05-01 start
    <Box mx={8} py={!isMobile ? 0 : 16} display="flex">
      {/* feat screen 09-05-01 end */}
      <MainButton
        icon="wavingHand"
        iconColor={GENERIC_COLOR.CAUTION_RED}
        variant="warnSecondary"
        // feat screen 09-05-05 start
        thin={!isMobile}
        // feat screen 09-05-05 end
        onClick={() => {
          checkCanCancel().then((result) => {
            if (result) {
              setActiveModal(ModalType.CONFIRMATION);
            } else {
              setActiveModal(ModalType.CANNOT_CANCEL);
            }
          });
        }}
      >
        退会する
      </MainButton>
    </Box>
  );

  const content = memberStoreStatus === MemberStoreStatus.PAUSE ? pausedUserContent : defaultContent;

  // feat screen 09-05-01 start
  return !isMobile ? (
    <>
      {loading && <Loading />}
      <Box overflow="scroll" px={16} py={18} width="100%">
        <Box mx={8} mb={8}>
          <Text variant="h2" color="darkBlue">
            退会
          </Text>
        </Box>
        <Box mx={8} my={16}>
          <Divider option="horizontal" />
        </Box>
        {content}
      </Box>
      <ConfirmationModal
        isOpen={activeModal === ModalType.CONFIRMATION}
        onModalClose={() => setActiveModal(null)}
        onClickNext={() => setActiveModal(ModalType.FINAL_CONFIRMATION)}
      />
      <FinalConfirmationModal
        isOpen={activeModal === ModalType.FINAL_CONFIRMATION}
        onModalClose={() => setActiveModal(null)}
        onClickNext={() => {
          function onError() {
            setActiveModal(null);
            openErrorModal({
              message: '退会処理に失敗しました。時間をおいてもう一度お試しください。',
            });
          }
          // ログインIDが取れなければ退会処理をさせない
          if (!loginId) {
            onError();
            return;
          }
          cancelMembership()
            .then((result) => {
              if (result.errors) {
                onError();
              } else {
                setActiveModal(ModalType.COMPLETE);
              }
            })
            .catch(() => {
              onError();
            });
        }}
      />
      <CompleteModal
        isOpen={activeModal === ModalType.COMPLETE}
        onModalClose={() => {
          setActiveModal(null);
          refetch();
        }}
      />
      <CannotCancelModal isOpen={activeModal === ModalType.CANNOT_CANCEL} onModalClose={() => setActiveModal(null)} />
    </>
  ) : (
    // Responsive Component
    <>
      {loading && <Loading />}
      <Box overflow="scroll" height="calc(100% - 54px)" px={16} width="100%">
        <Box py={16} pb={14}>
          <Text align="center" variant="h2" color="darkBlue">
            退会
          </Text>
        </Box>
        <Box>
          <Divider option="horizontal" />
        </Box>
        {content}
      </Box>
      <ConfirmationModal
        isOpen={activeModal === ModalType.CONFIRMATION}
        onModalClose={() => setActiveModal(null)}
        onClickNext={() => setActiveModal(ModalType.FINAL_CONFIRMATION)}
      />
      <FinalConfirmationModal
        isOpen={activeModal === ModalType.FINAL_CONFIRMATION}
        onModalClose={() => setActiveModal(null)}
        onClickNext={() => {
          function onError() {
            setActiveModal(null);
            openErrorModal({
              message: '退会処理に失敗しました。時間をおいてもう一度お試しください。',
            });
          }
          // ログインIDが取れなければ退会処理をさせない
          if (!loginId) {
            onError();
            return;
          }
          cancelMembership()
            .then((result) => {
              if (result.errors) {
                onError();
              } else {
                setActiveModal(ModalType.COMPLETE);
              }
            })
            .catch(() => {
              onError();
            });
        }}
      />
      <CompleteModal
        isOpen={activeModal === ModalType.COMPLETE}
        onModalClose={() => {
          setActiveModal(null);
          refetch();
        }}
      />
      <CannotCancelModal isOpen={activeModal === ModalType.CANNOT_CANCEL} onModalClose={() => setActiveModal(null)} />
    </>
  );
  // feat screen 09-05-01 end
}

export default CancelMembershipScreen;
