import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as gql from 'graphql/graphql-mw';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { useAuth } from 'hooks/authProvider';
import { useWithholdSlipPdfDownload } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function WithholdingSlip(props: {
  /* feat_screen_05-02-01-02_add_props_start */ 
  blogFlag?: boolean;
  /* feat_screen_05-02-01-02_add_props_end */
}) {

  const { 
    /* feat_screen_05-02-01-02_add_props_start */ 
    blogFlag
    /* feat_screen_05-02-01-02_add_props_end */ 
  } = props;

  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const { openErrorModal } = useErrorModal();

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const {
    data: { getVTEmployListWithholdByTpmemIdBtmemId: { employ, withhold } } = {
      getVTEmployListWithholdByTpmemIdBtmemId: {
        employ: {} as gql.VtEmployList,
        withhold: [] as gql.VrWithholdSlipList[],
      },
    },
    loading,
    error: withholdError,
  } = gql.useGetVtEmployListWithholdByTpmemIdBtmemIdQuery({
    variables: { tpmem_id: tpmemId, btmem_id: btmemId },
  });
  React.useEffect(() => {
    if (withholdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [withholdError, openErrorModal]);

  // 一覧のタイプ
  type HistoryListItemType = {
    // ユニークキー
    uniqueKey: string | number;
    // テキスト
    text: string;
    // 日時
    dateTime: string;
    // 年度
    year: number;
    // バイト会員ID
    btmemId: number;
    // 法人番号
    corporateNo: string;
  };

  const historyListItems: HistoryListItemType[] = useMemo(() => {
    if (loading) return [];
    if (!withhold) return [];

    const items: (gql.VrWithholdSlipList | null)[] = withhold;

    return items.map((item, index) => ({
      text: `${item?.year_val ?? '0000'}年分発行`,
      dateTime: item?.last_date ?? '0000年00月00日 00:00',
      uniqueKey: index,
      year: Number(item?.year_val),
      btmemId: Number(item?.btmem_id),
      corporateNo: String(item?.corporate_no),
    }));
  }, [loading, withhold]);

  // [hook] 源泉徴収票PDFダウンロード
  const { downloadPdf, loading: downloadLoading } = useWithholdSlipPdfDownload();

  /**
   * [onClick] 源泉徴収票PDFダウンロード
   */
  const onClickDownload = (btMemberId: number, year: number, corporateNo: string) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (downloadLoading) return;

        // PDFダウンロード処理
        const result = await downloadPdf(btMemberId, year, corporateNo);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        openErrorModal({ message: 'ダウンロード処理に失敗しました。' });
      }
    })();
  };

  return !isMobile ? (
    <Box px={16} display="flex" flex="1" flexDirection="column">
      <Box display="flex" flexDirection="row" py={18} justifyContent="space-between">
        <Box display="flex" flexDirection="row" gap={8} alignItems="center">
          <Text variant="h2" color="darkBlue">
            源泉徴収票一覧
          </Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <div style={{ position: 'sticky', display: 'flex' }}>
        <Box flex="auto" width="100%" px={0}>
          <Box display="flex" flexDirection="column" overflow="auto" py={8}>
            <ScrollWrapper bottom={270}>
              {historyListItems.map((item) => (
                <>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Box display="flex" flexDirection="column" gap={4} py={8}>
                      <Text variant="body14">{item.text}</Text>
                      <Text variant="caption11">{item.dateTime}</Text>
                    </Box>
                    <ThreeDotLeader
                      menu={[{ value: 'ダウンロード', label: 'ダウンロード' }]}
                      right={2}
                      onClick={() => {
                        onClickDownload(item.btmemId, item.year, item.corporateNo);
                      }}
                    />
                  </Box>
                  <Divider option="horizontal" />
                </>
              ))}
            </ScrollWrapper>
          </Box>
        </Box>
      </div>
    </Box>
  ) : (
    // feat_screen_05-01-09-02_start
    <Box px={16} display="flex" flex="1" flexDirection="column" height="calc(100svh - 380px)">
      <Box display="flex" flexDirection="row" pt={18} pb={15} justifyContent="center">
        <Box display="flex" flexDirection="row" gap={8} alignItems="center" justifyContent="center">
          <Text variant="h2" color="darkBlue">
            源泉徴収票一覧
          </Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <div style={{ position: 'sticky', display: 'flex' }}>
        <Box flex="auto" width="100%" px={0}>
          <Box display="flex" flexDirection="column" overflow="auto" py={8}>
            <ScrollWrapper bottom={410}>
              {historyListItems.map((item) => (
                <>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Box display="flex" flexDirection="column" gap={4} pt={8} pb={12}>
                      <Text variant="body14">{item.text}</Text>
                      <Text variant="caption11">{item.dateTime}</Text>
                    </Box>
                    <ThreeDotLeader
                      menu={[{ value: 'ダウンロード', label: 'ダウンロード' }]}
                      right={2}
                      onClick={() => {
                        onClickDownload(item.btmemId, item.year, item.corporateNo);
                      }}
                    />
                  </Box>
                  <Divider option="horizontal" />
                </>
              ))}
            </ScrollWrapper>
          </Box>
        </Box>
      </div>
    </Box>
    // feat_screen_05-01-09-02_end
  );
}
