/**
 * Figma ID: 04-03-02-01, 04-03-02-02, 04-03-02-03, 04-03-02-04, 04-03-02-05
 *           04-03-03-01, 04-03-03-02, 04-03-03-03, 04-03-03-04,
 * 名称: 逆オファー詳細
 */
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as gql from 'graphql/graphql-mw';
import { styled } from '@linaria/react';
import { PageBody, ContentsCard, StickyHeader } from 'components/assets/css/pages/pageStyle';
import { ScrollableArea } from 'components/scrollableArea';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { Chip } from 'components/chip';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { Icon } from 'components/icon';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { CategorizedInfo } from 'components/categorizedInfo';
import { MainButton } from 'components/mainButton';
import { useAuth } from 'hooks/authProvider';
import { NoticeOfEmploymentPdfProps, useNoticeOfEmploymentPdfPreview } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { add, format } from 'date-fns';
// feat screen 04-03-02-01 start
import useIsMobile from 'hooks/responsive/useIsMobile';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { Dropdown } from 'components/dropdown';
// feat screen 04-03-02-01 end
import { useGetInfoSkillDataConnectApi } from '../common/hooks';
import { ModalComponent } from './components/modal/modal';
import { DeleteModalComponent } from './components/modal/deleteModal';
import { VerifyModalComponent } from './components/modal/verifyModal';

const StyledImgBox = styled.div`
  width: 80px;
  height: 60px;
  background-color: #000;
  text-align: center;
  /* feat screen 04-03-02-01 start */
  @media only screen and (max-width: 768px) {
    width: 60px;
    padding: 0 10px;
  }
  /* feat screen 04-03-02-01 end */
`;

export function S0403Details() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(0);
  // feat_common_responsive_tab_end
  const auth = useAuth();
  const { tpmemId } = auth;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean>(false);

  /**
   * 親から遷移してきていない場合は、一覧画面に戻す
   */
  useEffect(() => {
    if (!location.state) {
      navigate('/s04-03');
    }
  }, [location, navigate]);

  const linkToDisp0402 = 's04-02';

  /**
   * エラーモーダルダイアログ
   */
  const { openErrorModal } = useErrorModal();

  /**
   * [API] スキルデータの取得
   */

  const {
    getVtBtmemOfferInfoSkillByIdMutation: getInfoSkillData,
    data: infoSkillData,
    loading: infoSkillLoading,
    error: infoSkillEroor,
  } = useGetInfoSkillDataConnectApi(Number(id), Number(tpmemId));

  React.useEffect(() => {
    if (infoSkillEroor) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [infoSkillEroor, openErrorModal]);

  useEffect(() => {
    if (!id || !tpmemId) return;
    getInfoSkillData();
  }, [getInfoSkillData, id, tpmemId]);

  const dispInfoData = infoSkillData?.getVTBtmemOfferInfoSkillById?.info as gql.VtBtmemOfferInfo;
  const dispSkillData = useMemo(
    () => (infoSkillData?.getVTBtmemOfferInfoSkillById?.skill as gql.VbSkillInfo[]) || [],
    [infoSkillData]
  );

  /**
   * チップを生成
   * 0:逆オファー中 1:募集送信済 3:成約 4:他店成約 5:期限終了 6:削除 7:強制停止
   */
  const generateHeaderChip = (status?: number | null) => {
    switch (status) {
      case 0:
        return (
          <Chip variant="big" color="darkBlue">
            逆オファー
          </Chip>
        );
      case 1:
        return (
          <Chip variant="big" color="okBlue">
            募集送信済
          </Chip>
        );
      case 3:
        return (
          <Chip variant="big" color="okBlue">
            成約
          </Chip>
        );
      case 4:
        return (
          <Chip variant="big" color="liteGray">
            他店成約
          </Chip>
        );
      case 5:
        return (
          <Chip variant="big" color="liteGray">
            期限終了
          </Chip>
        );
      case 6:
        return (
          <Chip variant="big" color="liteGray">
            削除
          </Chip>
        );
      case 7:
        return (
          <Chip variant="big" color="cautionRed">
            強制停止
          </Chip>
        );
      default:
        return null;
    }
  };

  const generateChip = (type: number) => {
    switch (type) {
      case 0:
        return <Chip color="okBlue">他店/一般</Chip>;
      case 1:
        return <Chip color="darkBlue">自店</Chip>;
      case 2:
        return <Chip color="darkBlue">サブ</Chip>;
      case 3:
        return <Chip color="darkBlue">グループ</Chip>;
      default:
        return <Chip color="okBlue">一般</Chip>;
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const onVerifyModalClose = () => {
    setIsVerifyModalOpen(false);
  };

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf: previewNoticePdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPdfPreview = (arbeitOfferData?: gql.TArbeitOffer) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFプロパティ設定
        const pdfProps: NoticeOfEmploymentPdfProps = {
          employmentDate:
            (arbeitOfferData?.begin_date && format(new Date(arbeitOfferData.begin_date || 0), 'yyyy年MM月dd日')) || '',
          description: arbeitOfferData?.description || '',
          beginTime:
            (arbeitOfferData?.begin_date &&
              format(add(new Date(arbeitOfferData.begin_date || 0), { hours: -9 }), 'yyyy年MM月dd日 HH:mm')) ||
            '',
          endTime:
            (arbeitOfferData?.end_date &&
              format(add(new Date(arbeitOfferData.end_date || 0), { hours: -9 }), 'yyyy年MM月dd日 HH:mm')) ||
            '',
          breakTime: arbeitOfferData?.break_time ? Number(arbeitOfferData.break_time) : undefined,
          hourWage: (arbeitOfferData?.hour_wage && String(arbeitOfferData?.hour_wage)) || '',
          transFee: (arbeitOfferData?.trans_fee && String(arbeitOfferData?.trans_fee)) || '',
        };

        // PDFプレビュー
        const result = await previewNoticePdf(Number(auth?.tpmemId), pdfProps);

        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          onVerifyModalClose();
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        onVerifyModalClose();
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  const groupedByName = useMemo(
    () =>
      dispSkillData?.reduce((acc: { [section_no: number]: gql.VbSkillInfo[] }, item: gql.VbSkillInfo) => {
        if (item.section_no) {
          if (!acc[item.section_no]) {
            acc[item.section_no] = [];
          }
          acc[item.section_no].push(item);
        }
        return acc;
      }, {}),
    [dispSkillData]
  );

  const convertedData = Object.keys(groupedByName as gql.VbSkillInfo).map((key) => {
    if (!groupedByName) return null;
    const value = groupedByName[Number(key)];
    return {
      label: value?.[0].section_name || '',
      skills: value?.map((v) => ({
        name: v.item_name,
        checked: v.checked,
      })),
    };
  });
  // feat screen 04-03-02-01 start
  return !isMobile ? (
    <>
      <Box display="flex" alignItems="center" gap={24} mb={24}>
        <StyledImgBox>
          {dispInfoData && <img src={dispInfoData?.btmem_image || ''} alt="" width={60} height={60} />}
        </StyledImgBox>
        <div style={{ flexGrow: 2 }}>
          <Box display="flex" alignItems="center" gap={8}>
            <div>{generateHeaderChip(dispInfoData && dispInfoData?.status)}</div>
            <Text variant="h1">{dispInfoData?.offer_no}</Text>
          </Box>
        </div>
        <div>
          {dispInfoData?.status === 0 && (
            <MainButton icon="handExtended" iconColor="white" onClick={() => setIsModalOpen(true)}>
              募集新規作成
            </MainButton>
          )}
          {dispInfoData?.status === 1 && (
            <MainButton onClick={() => setIsDeleteModalOpen(true)} variant="warnSecondary">
              募集削除
            </MainButton>
          )}
          {dispInfoData?.status === 3 && (
            <MainButton
              onClick={() => navigate(`/${linkToDisp0402}/${String(dispInfoData?.accept_offer_id)}`)}
              variant="secondary"
            >
              成約バイト状況
            </MainButton>
          )}
        </div>
      </Box>
      <PageBody bottom={92}>
        <ContentsCard>
          {infoSkillLoading ? (
            <Loading />
          ) : (
            <SubMenu
              items={[
                {
                  content: (
                    <Box px={16} pt={0} pb={72} width="100%">
                      <StickyHeader height={60}>
                        <Box display="flex" alignItems="center" width="100%" gap={8}>
                          <div style={{ flex: '0 0 115px' }}>
                            <Text color="darkBlue" variant="h2">
                              逆オファー情報
                            </Text>
                          </div>
                          {dispInfoData?.status === 0 && (
                            <Text color="darkBlue" variant="caption12">
                              このオファーを受け入れるには募集新規作成を押して募集を作成し、バイトラベラーに送信してください。
                            </Text>
                          )}
                          {dispInfoData?.status === 1 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                この逆オファーに募集を送信済です。作成送信した募集内容は募集内容確認より確認できます。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                          {dispInfoData?.status === 3 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                逆オファーが成約しました。バイト当日のオペレーションは成約バイト状況から確認してください。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                          {dispInfoData?.status === 4 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                この逆オファーは他のお店が成約しました。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                          {dispInfoData?.status === 5 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                この逆オファーは予定バイト日に達したので自動的に逆オファー募集終了しました。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                          {dispInfoData?.status === 6 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                この逆オファーはバイトラベラー側から削除されました。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                          {dispInfoData?.status === 7 && (
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={8}>
                              <Text color="darkBlue" variant="caption12">
                                この逆オファーは強制停止されました。
                              </Text>
                              <MainButton onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                募集内容確認
                              </MainButton>
                            </Box>
                          )}
                        </Box>
                      </StickyHeader>
                      <ScrollableArea>
                        {dispInfoData && (
                          <Info
                            items={[
                              {
                                label: 'オファーNo.',
                                content: dispInfoData?.offer_no,
                              },
                              {
                                label: 'オファーした人（カナ）',
                                content: dispInfoData?.btmem_kana,
                                isShow: !!dispInfoData?.btmem_kana,
                              },
                              {
                                label: 'オファー者会員番号',
                                content: (
                                  <Box display="flex" alignItems="center" gap={4}>
                                    <Chip color="okBlue">{generateChip(dispInfoData?.type || 0)}</Chip>
                                    <Text color="black" variant="body14">
                                      {dispInfoData?.btmem_number}
                                    </Text>
                                  </Box>
                                ),
                              },
                              {
                                label: '居住都道府県',
                                content: dispInfoData?.state,
                              },
                              {
                                label: 'オファー登録日時',
                                content: dispInfoData?.created_date,
                              },
                              {
                                label: 'バイト期間',
                                content: dispInfoData?.begin_end,
                              },
                              {
                                label: '希望時間',
                                content: dispInfoData?.work_time,
                              },
                              {
                                label: '希望時給',
                                content: (
                                  <Box display="flex" alignItems="center" gap={8}>
                                    <Text color="black" variant="body14">
                                      {dispInfoData?.hour_wage === '0' ? '-' : `¥${String(dispInfoData?.hour_wage)}`}
                                    </Text>
                                    <Text color="blueGray" variant="body14">
                                      {dispInfoData?.hour_wage === '0'
                                        ? ''
                                        : '＊深夜時間帯にかかる労働時間の場合、別途割増料金が必要になります。ご注意ください。'}
                                    </Text>
                                  </Box>
                                ),
                              },
                              {
                                label: '希望地域',
                                content: dispInfoData?.state_city,
                              },
                              {
                                label: '年齢',
                                content: dispInfoData?.age,
                              },
                              {
                                label: 'ランク',
                                content: dispInfoData?.rank_name,
                              },
                              {
                                label: 'スコア',
                                content: String(dispInfoData?.score || ''),
                              },
                              {
                                label: '所属店舗',
                                content: dispInfoData?.belong,
                              },
                              {
                                label: '性別',
                                content: dispInfoData?.gender,
                              },
                              {
                                label: '国籍',
                                content: dispInfoData?.nationality || '',
                                isShow: !!dispInfoData?.nationality,
                              },
                              {
                                label: '在留期限',
                                content: dispInfoData?.period_date || '',
                                isShow: !!dispInfoData?.period_date,
                              },
                              {
                                label: '保有資格',
                                content: dispInfoData?.general_licenses ? dispInfoData?.general_licenses : '-',
                              },
                              {
                                label: `${dispInfoData?.biz_name || ''}資格`,
                                content: dispInfoData?.biz_license ? dispInfoData?.biz_license : '-',
                              },
                              {
                                label: '遅刻率',
                                content: `${String(dispInfoData?.late_count)}回 / ${String(dispInfoData?.late_per)}%`,
                              },
                              {
                                label: 'キャンセル率',
                                content: `${String(dispInfoData?.cancel_count)}回 / ${String(
                                  dispInfoData?.cancel_per
                                )}%`,
                              },
                              {
                                label: 'ドタキャン率',
                                content: `${String(dispInfoData?.imminent_count)}回 / ${String(
                                  dispInfoData?.imminent_per
                                )}%`,
                              },
                              {
                                label: 'コメント',
                                content: dispInfoData?.comment,
                              },
                            ]}
                          />
                        )}
                      </ScrollableArea>
                    </Box>
                  ),
                  label: '逆オファー情報',
                },
                {
                  content: (
                    <Box my={20} mx={16} pb={48} width="100%">
                      <Box>
                        <Box mb={20}>
                          <Text color="darkBlue" variant="h2">
                            スキルチェック
                          </Text>
                        </Box>
                        <Divider option="horizontal" />
                      </Box>
                      <ScrollableArea>
                        <Box>
                          <Box my={16}>
                            <Text variant="body14" color="darkBlue">
                              ランク&nbsp;
                            </Text>
                            <Text variant="body16" color="darkBlue" bold>
                              {dispInfoData?.rank_name}
                            </Text>
                          </Box>
                        </Box>
                        <Divider option="horizontal" />
                        {convertedData.length > 0 && (
                          <CategorizedInfo
                            items={convertedData?.map((d) => ({
                              label: d?.label || '',
                              contents: d?.skills.map((s) => (
                                <Box display="flex" alignItems="center" gap={8}>
                                  {s.checked ? <Icon color="#008599" name="check" /> : <Box width={24}>&nbsp;</Box>}
                                  <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                                    {s.name}
                                  </Text>
                                </Box>
                              )) as React.ReactNode[],
                            }))}
                          />
                        )}
                      </ScrollableArea>
                    </Box>
                  ),
                  label: 'スキルチェック',
                },
              ]}
            />
          )}
        </ContentsCard>
      </PageBody>
      <ModalComponent
        isOpen={isModalOpen}
        onClose={() => onModalClose()}
        defaultValues={{
          wage: dispInfoData?.hour_wage_min || 0,
          date: dispInfoData?.work_day || '',
          beginTime: dispInfoData?.begin_time || '00:00',
          endTime: dispInfoData?.end_time || '00:00',
        }}
      />
      <DeleteModalComponent
        isOpen={isDeleteModalOpen}
        onClose={() => onDeleteModalClose()}
        dispInfoData={dispInfoData}
      />
      <VerifyModalComponent
        isOpen={isVerifyModalOpen}
        onClose={() => onVerifyModalClose()}
        bOfferId={dispInfoData?.id}
        onPdfPreview={(arbeitOfferData) => onClickPdfPreview(arbeitOfferData)}
      />
    </>
  ) : (
    // Responsive Component
    <>
      <MultiMenuHeader items={
        [
          {
            label: "募集・採用"
          },
          {
            label: "逆オファー"
          },
        ]}
        mainHeader="逆オファー"
      />
      <Box px={16}>
        <Box display="flex" py={16} pb={18}>
          <StyledImgBox>
            {dispInfoData && <img src={dispInfoData?.btmem_image || ''} alt="" width={60} height={60} style={{display: "block"}} />}
          </StyledImgBox>
          <Box display="flex" ml={16} flexDirection="column" justifyContent="space-between" gap={8}>
            <div>{generateHeaderChip(dispInfoData && dispInfoData?.status)}</div>
            <Text variant="body16" bold>{dispInfoData?.offer_no}</Text>
          </Box>
        </Box>
        {dispInfoData?.status === 0 && (
          <Box mt={2} pb={14}>
            <MainButton width={144} icon="handExtended" iconColor="white" onClick={() => setIsModalOpen(true)}>
              募集新規作成
            </MainButton>
          </Box>
        )}
        {dispInfoData?.status === 1 && (
          <Box mt={2} pb={14}>
            <MainButton width={92} onClick={() => setIsDeleteModalOpen(true)} variant="warnSecondary">
              募集削除
            </MainButton>
          </Box>
        )}
        {dispInfoData?.status === 3 && (
          <Box mt={2} pb={14}>
            <MainButton 
              width={137}
              onClick={() => navigate(`/${linkToDisp0402}/${String(dispInfoData?.accept_offer_id)}`)}
              variant="secondary"
            >
              成約バイト状況
            </MainButton>
          </Box>
        )}
        <PageBody bottom={[0, 1, 3].includes(dispInfoData?.status) ? 341 : 291}>
          <ContentsCard>
            {infoSkillLoading ? (
              <Loading />
            ) : (
              <Dropdown
                tab={tab}
                setTab={setTab}
                fullWidth
                items={[
                  {
                    content: (
                      <Box px={16} width="100%" height="calc(100% - 52px)" overflow="auto">
                        <Box pt={15} pb={15}>
                          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                            <Text display="block" align="center" color="darkBlue" variant="body16" bold>
                              逆オファー情報
                            </Text>
                            {dispInfoData?.status === 0 && (
                              <Text display="block" align="center" color="darkBlue" variant="caption12">
                                このオファーを受け入れるには募集新規作成を押して募集を作成し、バイトラベラーに送信してください。
                              </Text>
                            )}
                            {dispInfoData?.status === 1 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  この逆オファーに募集を送信済です。作成送信した募集内容は募集内容確認より確認できます。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                            {dispInfoData?.status === 3 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  逆オファーが成約しました。バイト当日のオペレーションは成約バイト状況から確認してください。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                            {dispInfoData?.status === 4 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  この逆オファーは他のお店が成約しました。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                            {dispInfoData?.status === 5 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  この逆オファーは予定バイト日に達したので自動的に逆オファー募集終了しました。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                            {dispInfoData?.status === 6 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  この逆オファーはバイトラベラー側から削除されました。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                            {dispInfoData?.status === 7 && (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" gap={13}>
                                <Text display="block" align="center" color="darkBlue" variant="caption12">
                                  この逆オファーは強制停止されました。
                                </Text>
                                <MainButton width={122} onClick={() => setIsVerifyModalOpen(true)} variant="secondary">
                                  募集内容確認
                                </MainButton>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Divider option="horizontal" />
                        {dispInfoData && (
                          <Info
                            items={[
                              {
                                label: 'オファーNo.',
                                content: dispInfoData?.offer_no,
                              },
                              {
                                label: 'オファーした人（カナ）',
                                content: dispInfoData?.btmem_kana,
                                isShow: !!dispInfoData?.btmem_kana,
                              },
                              {
                                label: 'オファー者会員番号',
                                content: (
                                  <Box display="flex" alignItems="center" width="100%" justifyContent="flex-end" gap={4}>
                                    <Chip color="okBlue">{generateChip(dispInfoData?.type || 0)}</Chip>
                                    <Text color="black" variant="body14">
                                      {dispInfoData?.btmem_number}
                                    </Text>
                                  </Box>
                                ),
                              },
                              {
                                label: '居住都道府県',
                                content: dispInfoData?.state,
                              },
                              {
                                label: 'オファー登録日時',
                                content: dispInfoData?.created_date,
                              },
                              {
                                label: 'バイト期間',
                                content: dispInfoData?.begin_end,
                              },
                              {
                                label: '希望時間',
                                content: dispInfoData?.work_time,
                              },
                              {
                                label: '希望時給',
                                content: (
                                  <Box display="flex" flexDirection="column" alignItems="flex-end" gap={4}>
                                    <Text color="black" variant="body14">
                                      {dispInfoData?.hour_wage === '0' ? '-' : `¥${String(dispInfoData?.hour_wage)}`}
                                    </Text>
                                    <Text color="blueGray" align="right" variant="body14">
                                      {dispInfoData?.hour_wage === '0'
                                        ? ''
                                        : '＊深夜時間帯にかかる労働時間の場合、別途割増料金が必要になります。ご注意ください。'}
                                    </Text>
                                  </Box>
                                ),
                              },
                              {
                                label: '希望地域',
                                content: dispInfoData?.state_city,
                              },
                              {
                                label: '年齢',
                                content: dispInfoData?.age,
                              },
                              {
                                label: 'ランク',
                                content: dispInfoData?.rank_name,
                              },
                              {
                                label: 'スコア',
                                content: String(dispInfoData?.score || ''),
                              },
                              {
                                label: '所属店舗',
                                content: dispInfoData?.belong,
                              },
                              {
                                label: '性別',
                                content: dispInfoData?.gender,
                              },
                              {
                                label: '国籍',
                                content: dispInfoData?.nationality || '',
                                isShow: !!dispInfoData?.nationality,
                              },
                              {
                                label: '在留期限',
                                content: dispInfoData?.period_date || '',
                                isShow: !!dispInfoData?.period_date,
                              },
                              {
                                label: '保有資格',
                                content: dispInfoData?.general_licenses ? dispInfoData?.general_licenses : '-',
                              },
                              {
                                label: `${dispInfoData?.biz_name || ''}資格`,
                                content: dispInfoData?.biz_license ? dispInfoData?.biz_license : '-',
                              },
                              {
                                label: '遅刻率',
                                content: `${String(dispInfoData?.late_count)}回 / ${String(dispInfoData?.late_per)}%`,
                              },
                              {
                                label: 'キャンセル率',
                                content: `${String(dispInfoData?.cancel_count)}回 / ${String(
                                  dispInfoData?.cancel_per
                                )}%`,
                              },
                              {
                                label: 'ドタキャン率',
                                content: `${String(dispInfoData?.imminent_count)}回 / ${String(
                                  dispInfoData?.imminent_per
                                )}%`,
                              },
                              {
                                label: 'コメント',
                                content: dispInfoData?.comment,
                              },
                            ]}
                          />
                        )}
                      </Box>
                    ),
                    label: '逆オファー情報',
                  },
                  {
                    content: (
                      <Box width="100%" px={16} height="calc(100% - 52px)" overflow="auto">
                        <Box>
                          <Box pt={16} pb={15}>
                            <Text color="darkBlue" align="center" variant="h2">
                              スキルチェック
                            </Text>
                          </Box>
                          <Divider option="horizontal" />
                        </Box>
                        <Box>
                          <Box>
                            <Box my={15}>
                              <Text variant="body14" color="darkBlue">
                                ランク&nbsp;
                              </Text>
                              <Text variant="body14" color="darkBlue" bold>
                                {dispInfoData?.rank_name}
                              </Text>
                            </Box>
                          </Box>
                          <Divider option="horizontal" />
                          <Box pt={8}>
                            {convertedData.length > 0 && (
                              <CategorizedInfo
                                items={convertedData?.map((d) => ({
                                  label: d?.label || '',
                                  contents: d?.skills.map((s) => (
                                    <Box display="flex" alignItems="flex-start" gap={8} >
                                      {s.checked ? <Icon color="#008599" name="check" /> : <Box minWidth={24} width={24}>&nbsp;</Box>}
                                      <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                                        {s.name}
                                      </Text>
                                    </Box>
                                  )) as React.ReactNode[],
                                }))}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ),
                    label: 'スキルチェック',
                  },
                ]}
              />
            )}
          </ContentsCard>
        </PageBody>
      </Box>
      <ModalComponent
        isOpen={isModalOpen}
        onClose={() => onModalClose()}
        defaultValues={{
          wage: dispInfoData?.hour_wage_min || 0,
          date: dispInfoData?.work_day || '',
          beginTime: dispInfoData?.begin_time || '00:00',
          endTime: dispInfoData?.end_time || '00:00',
        }}
      />
      <DeleteModalComponent
        isOpen={isDeleteModalOpen}
        onClose={() => onDeleteModalClose()}
        dispInfoData={dispInfoData}
      />
      <VerifyModalComponent
        isOpen={isVerifyModalOpen}
        onClose={() => onVerifyModalClose()}
        bOfferId={dispInfoData?.id}
        onPdfPreview={(arbeitOfferData) => onClickPdfPreview(arbeitOfferData)}
      />
    </>
    // feat screen 04-03-02-01 end
  );
}
