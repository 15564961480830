// feat screen 00 start
/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@linaria/react';
import { format } from 'date-fns';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ja } from 'date-fns/locale';
import { QUICK_ACCESS_COLOR } from 'components/assets/css/style';
import { Icon } from 'components/icon';

interface PropState {
  dateValue: Date;
  isConfirmCorrection: boolean;
  hasApplicantConfirmation: boolean;
  hasConfirmReverseOffer: boolean;
  rowObject: {
    link: string,
    id?: number | null,
    id2?: number | null,
    status?: number | null,
    mainCount?: number | null,
    subCount?: number | null,
    normalCount?: number | null
  }
} 

const StyledLabel= styled.span`
  font-size: 2.5rem;
  line-height: 3.62rem;
  color: #008599;
  font-weight: 700;
  letter-spacing: 2px;
  /* feat_common_responsive_divider_start */
  @media only screen and (max-width: 350px) {
    font-size: 2rem;
    line-height: 3.12rem;
  }
  /* feat_common_responsive_modal_end */
;`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  border-radius: 10px;
  padding: 26px 17px;
  box-shadow: 0px 0px 5px 0px #00000040;
`;

const StyledSecondWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 10px;
  padding: 22px 18px;
  box-shadow: 0px 0px 5px 0px #00000040;
`;

const StyledCustomButton = styled.div<{
  bgColor: string;
}>`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  background-color: ${({bgColor}) => bgColor};
  color: ${({bgColor}) => bgColor === "#fff" ? "#008599" : "#fff"};
  border: 1px solid;
  border-color:  ${({bgColor}) => bgColor === "#fff" ? "#008599" : bgColor};
  font-weight: 700;
`;

const StyledRightSide = styled.div`
  display: block;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export function QuickApplies() {
  const navigate = useNavigate();
  const location = useLocation(); 
  const { dateValue = new Date(), isConfirmCorrection, hasApplicantConfirmation, hasConfirmReverseOffer, rowObject } = (location?.state || {} ) as PropState;
  const handleNavigate = (link?: string, state?: object) => {
    if (link) {
      navigate(link, {state});
    }
  };

  return (
    // feat_common_responsive_layout_start
    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mt={-16}>
      <Box display='flex' width='100%' justifyContent='space-between'>
        <Box display='flex' justifyContent='flex-start' flexDirection='column'>
          <StyledLabel>
            {format(dateValue  , "yyyy年")}
          </StyledLabel>
          <StyledLabel>
            {format(dateValue, "MM月dd日")}&nbsp;{`(${format(dateValue , "EEE", {locale: ja})})`}
          </StyledLabel>
        </Box>
        <Box height={30} display='flex' justifyContent='flex-start'>
          <StyledRightSide>
            <Icon
              name="helpOutline"
              color="#008599"
              onClick={() => {
                const helpOfServiceUrl = process.env.REACT_APP_HELP_SERVICE;
                if (helpOfServiceUrl) {
                  window.open(helpOfServiceUrl, '_blank');
                }
              }}
            />
          </StyledRightSide>
        </Box>
      </Box>
      <Box width="100%" mt={62} mb={30}>
        <StyledWrapper>
          <StyledCustomButton 
            bgColor={isConfirmCorrection ? QUICK_ACCESS_COLOR.APPLY_SUCCESS : "#fff"} 
            onClick={() => {
              handleNavigate("/s04-02", {
                tabIndex: 5,
                dateValue
              });
            }}
          >
            修正依頼確認
          </StyledCustomButton>
          <StyledCustomButton 
            bgColor={hasApplicantConfirmation ? QUICK_ACCESS_COLOR.WARNING : "#fff"} 
            onClick={() => {
              if (!hasApplicantConfirmation) {
                return handleNavigate('/s04-01-01', {
                  dateValue
                });
              }
              return handleNavigate(`/${rowObject?.link}`, {
                menuIndex: 1,
                id : rowObject?.id || 0,
                id2 : rowObject?.id2 || "",
                status : rowObject?.status || "",
                mainCount : rowObject?.mainCount || "",
                subCount : rowObject?.subCount || "",
                normalCount : rowObject?.normalCount || "",
              });
            }}
          >
            応募者確認
          </StyledCustomButton>
          <StyledCustomButton 
            bgColor={hasConfirmReverseOffer ? QUICK_ACCESS_COLOR.INFO : "#fff"}
            onClick={() => {
              handleNavigate(`/s04-03`, {
                dateValue
              });
            }}
          >
            逆オファー確認
          </StyledCustomButton>
        </StyledWrapper>
        <Box mt={32}>
          <StyledSecondWrapper>
            <MainButton width={91.33} customHeight={60} onClick={() => {
              handleNavigate('/s04-01-01', {
                openModal: true,
                dateValue
              });
            }}>
              <Box display='flex' flexDirection='column'>
                <Text variant='body14' align='center' bold color='white' >新規</Text>
                <Text variant='body14' align='center' bold color='white' >募集</Text>
              </Box>
            </MainButton>
            <MainButton width={91.33} customHeight={60} onClick={() => {
              if(rowObject?.id){
                return handleNavigate(`/${rowObject?.link}`, {
                  id : rowObject?.id || 0,
                  id2 : rowObject?.id2 || "",
                  status : rowObject?.status || "",
                  mainCount : rowObject?.mainCount || "",
                  subCount : rowObject?.subCount || "",
                  normalCount : rowObject?.normalCount || "",
                });
              }
              return handleNavigate(`/s04-01-01`, {
                dateValue
              });
            }}>
              <Box display='flex' flexDirection='column'>
                <Text variant='body14' align='center' bold color='white' >募集</Text>
                <Text variant='body14' align='center' bold color='white' >情報</Text>
              </Box>
            </MainButton>
            <MainButton width={91.33} customHeight={60} onClick={() => {
              handleNavigate('/s04-02', {
                dateValue
              });
            }}>
              <Box display='flex' flexDirection='column'>
                <Text variant='body14' align='center' bold color='white' >成約</Text>
                <Text variant='body14' align='center' bold color='white'>バイト</Text>
              </Box>
            </MainButton>
          </StyledSecondWrapper>
        </Box>
        <Box mt={66} display='flex' justifyContent='flex-end'>
          <MainButton customHeight={40} width={171.5} variant='secondary' onClick={() => {
            handleNavigate("/s00");
          }}> 
            戻る
          </MainButton>
        </Box>
      </Box>
    </Box>
  );
}
// feat screen 00 end