export enum MemberStatus {
  MEMBER = 1,
  PERMANENTLY_DISABLE = 2,
  TEMPORARILY_DISABLE = 3,
  SUSPENSION = 4,
}

export enum IsFavorite {
  TRUE = 1,
  FALSE = 0,
}

export enum BtmemBlock {
  TRUE = 2,
  FALSE = 1,
}

export enum BtmemBlockFlag {
  TRUE = 1,
  FALSE = 0,
}

export enum ChangeFavorite {
  OFF = 0,
  ON = 1,
}

export enum BelongBtmemKind {
  PRIMARY = 0,
  SECONDARY = 1,
}

export enum BelongType {
  OWN_STORE = 1,
  SUB = 2,
}
export enum EmployeeType {
  OTHER_STORE = 0,
  GROUP = 3,
}

export enum Identification {
  NOT_SUBMITTED = 0,
  NOT_VARIFIED = 1,
  VARIFIED = 2,
  DENIED = 3,
  RESUBMIT_REQUIRED = 4,
}

export enum MenuIndex {
  THANK_MESSAGE = 3, // THANKメッセージ
}

export enum PaymentType {
  Wallet = 'wallet',
  Transfer = 'transfer',
}

export enum WorkStatus {
  COMPLETE = 10, // 完了
  CANCEL = 11, // キャンセル
  NO_SHOW = 12, // バックレ
  ARBEIT_END = 22, // （所属・サブ・グループ）勤務完了
}
