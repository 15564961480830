import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { AddThanksMessageModalForm, ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { useUpdateBMemberAchievementByIdMutation } from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ResultRows } from 'components/const';

export function Confirm(
  getValues: UseFormGetValues<AddThanksMessageModalForm>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const [addThanksMessageMutation, { loading: addThanksMessageLoading }] = useUpdateBMemberAchievementByIdMutation();

  function addThanksMessage() {
    const previousSentence = getValues('previousSentence');
    const followingSentence = getValues('followingSentence');

    return addThanksMessageMutation({
      variables: {
        id: btmemId,
        achievement: `${previousSentence}${followingSentence}`,
        tpmem_id: tpmemId,
      },
    });
  }

  const { openErrorModal } = useErrorModal();

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        Thanksメッセージ付与
      </Text>
    ),
    content: !isMobile ? (
      <Box display="flex" height="100%" flexDirection="column" flex="1" gap={16} pa={8}>
        {addThanksMessageLoading && <Loading />}
        <Text variant="body14">以下のThanksメッセージを付与しますか？</Text>
        <Text variant="body14">
          ・{getValues('previousSentence')}
          {getValues('followingSentence')}
        </Text>
      </Box>
    ) : (
      // feat_screen_05-01-05-03_start
      <Box display="flex" height="100%" flexDirection="column" flex="1">
        {addThanksMessageLoading && <Loading />}
        <Text variant="body14">以下のThanksメッセージを付与しますか？</Text>
        <Text variant="body14">
          ・{getValues('previousSentence')}
          {getValues('followingSentence')}
        </Text>
      </Box>
      // feat_screen_05-01-05-03_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_05-01-05-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-05-03_end
          variant="secondary" 
          onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton 
          // feat_screen_05-01-05-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-05-03_end
          variant="secondary" 
          onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          // feat_screen_05-01-05-03_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-05-03_end
          onClick={() => {
            const errorMessage = 'メッセージの付与に失敗しました。時間を置いてもう一度お試しください。';
            addThanksMessage()
              .then((result) => {
                if (result.data?.updateBMemberAchievementById?.resultRows === ResultRows.UPDATED) {
                  onNext();
                } else {
                  onClose();
                  openErrorModal({
                    message: errorMessage,
                  });
                }
              })
              .catch(() => {
                onClose();
                openErrorModal({
                  message: errorMessage,
                });
              });
          }}
        >
          付与
        </MainButton>
      </Box>
    ),
    // feat_screen_05-01-05-03_start
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 226 : "auto",
    // feat_screen_05-01-05-03_end
  };

  return modalContent;
}
