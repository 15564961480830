/**
 * Figma ID: 04-01-03-11
 * 名称: 週定期日程作成 / プレビュー確認 シフト日一覧（モーダル）
 */

import React, { useState } from 'react';
import { VtArbeitOfferInfo } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Info } from 'components/info';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { ImageModal } from 'components/newModal';
import { Divider } from 'components/newDivider';
import { Box } from 'components/box';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { FormData } from '../../common/formState';
import { generateBeginEndDateTime, generatePostBiginDate, generatePostEndDate } from '../../common/utils';
import { ScheduleTypeLabel, ScheduleType } from '../../common/scheduleTypeContext';
import { useAutoRecruitPreviewItems } from '../preview/useAutoRecruitPreviewItems';

type Props = {
  fixedFormData?: FormData;
  basicInfoData?: VtArbeitOfferInfo;
};

export enum AutoDecide {
  MANUAL = 0,
  AUTO = 1,
  COUNTEROFFER = 2,
}

export function DailyPreviewInfo({ fixedFormData, basicInfoData }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const dispBeginEnd = generateBeginEndDateTime(fixedFormData);
  // 店舗情報
  const { storeInfo } = useAuth();

  /**
   * 休憩時間を生成する
   * フォームデータが無ければAPIのデータを表示する
   */
  function generateBreakTime() {
    if (!fixedFormData?.check_break_time) {
      return basicInfoData?.break_time !== 0 ? `${String(basicInfoData?.break_time)}分` : '無';
    }
    if (fixedFormData?.check_break_time === '0') {
      return '無';
    }
    return `${String(fixedFormData?.break_time)}分`;
  }

  /*
   * 交通費を生成する
   * フォームデータが無ければAPIのデータを表示する
   */
  function generateTransFee() {
    if (!fixedFormData?.check_trans_fee) {
      return basicInfoData?.trans_fee_val !== 0 ? `¥${String(basicInfoData?.trans_fee_val)}` : '無';
    }
    if (fixedFormData?.check_trans_fee === '0') {
      return '無';
    }
    return `¥${String(fixedFormData?.trans_fee)}`;
  }

  const infoItems: { content: React.ReactNode; label: string }[] = [
    {
      content:
        fixedFormData?.check_schedule_type === ScheduleType.DAILY ? ScheduleTypeLabel.DAILY : ScheduleTypeLabel.WEEKLY,
      label: '日程種別',
    },
    {
      content: Number(fixedFormData?.auto_decide) === AutoDecide.MANUAL ? '手動' : '自動',
      label: '採用方式',
    },
    {
      content: fixedFormData?.title || '',
      label: '掲載タイトル',
    },
    {
      content: storeInfo?.name || '',
      label: '募集店',
    },
    {
      content: dispBeginEnd
        ? `${String(dispBeginEnd?.begin)} ~ ${String(dispBeginEnd?.end)}`
        : basicInfoData?.begin_end,
      label: 'バイト時間',
    },
    {
      // 募集開始日が編集されることはないので、固定値を優先する
      content: basicInfoData?.post_begin || (fixedFormData && generatePostBiginDate(fixedFormData)),
      label: '募集開始',
    },
    {
      content: fixedFormData && (generatePostEndDate(fixedFormData) || basicInfoData?.post_end),
      label: '募集終了',
    },
    {
      content: generateBreakTime(),
      label: '休憩時間',
    },
    {
      content: fixedFormData?.hour_wage_display
        ? `¥${String(fixedFormData?.hour_wage_display)}`
        : `¥${String(basicInfoData?.hour_wage)}`,
      label: '時給',
    },
  ];

  const { createAutoRecruitePreviewItems } = useAutoRecruitPreviewItems();

  if (fixedFormData?.auto_decide === AutoDecide.AUTO.toString()) {
    infoItems.push(
      ...createAutoRecruitePreviewItems({
        rank: fixedFormData?.rank,
        score: fixedFormData?.score,
        hireFee: fixedFormData?.hire_fee,
        cancelPer: fixedFormData?.cancel_per,
        imminentPer: fixedFormData?.imminent_per,
      })
    );
  }

  infoItems.push(
    {
      content: fixedFormData?.chief || '',
      label: '当日の店舗責任者',
    },
    {
      content: generateTransFee(),
      label: '交通費',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Text align={!isMobile ? "left" : "right"} wordBreak="break-all" variant="body14">
          {charcterReplaceBr(fixedFormData?.description || '')}
        </Text>
        // feat screen 04-01-01-07 end
      ),
      label: '業務詳細',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Text align={!isMobile ? "left" : "right"} wordBreak="break-all" variant="body14">
          {charcterReplaceBr(fixedFormData?.conditions || '')}
        </Text>
        // feat screen 04-01-01-07 end
      ),
      label: '働くための条件',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Text align={!isMobile ? "left" : "right"} wordBreak="break-all" variant="body14">
          {charcterReplaceBr(fixedFormData?.caution || '')}
        </Text>
        // feat screen 04-01-01-07 end
      ),
      label: '注意事項',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Text align={!isMobile ? "left" : "right"} wordBreak="break-all" variant="body14">
          {charcterReplaceBr(fixedFormData?.belongings || '')}
        </Text>
        // feat screen 04-01-01-07 end
      ),
      label: '持ち物',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Text align={!isMobile ? "left" : "right"} wordBreak="break-all" variant="body14">
          {charcterReplaceBr(fixedFormData?.access || '')}
        </Text>
        // feat screen 04-01-01-07 end
      ),
      label: 'アクセス',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: !isMobile ? "flex-start" : "flex-end"}}>
        {/* feat screen 04-01-01-07 end */}
          {fixedFormData?.image1_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image1_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image1_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image2_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image2_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image2_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image3_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image3_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image3_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image4_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image4_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image4_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image5_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image5_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image5_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
        </div>
      ),
      label: 'メイン画像',
    },
    {
      content: (
        // feat screen 04-01-01-07 start
        <Box mb={-16}>
        {/* feat screen 04-01-01-07 end */}
          {fixedFormData?.attachment_flag === '1' ? (
            <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
              {fixedFormData?.attached_name || ''}
            </TextLink>
          ) : (
            <Text variant="body14">添付無し</Text>
          )}
        </Box>
      ),
      label: '添付ファイル',
    }
  );

  return (
    <>
      {/* feat screen 04-01-01-07 start */}
      <Box mb={16}>
        <Text color="darkBlue" variant={!isMobile ? "h2" : "body14"} bold>
          募集情報
        </Text>
      </Box>
      {/* feat screen 04-01-01-07 end */}
      <Divider length={0} option="horizontal" />
      <Info items={infoItems} />
      <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
    </>
  );
}
