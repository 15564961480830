/* eslint-disable @typescript-eslint/no-misused-promises */

import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { useErrorModal } from 'components/error/errorModalProvider';
import { isInteger } from 'lodash';

/**
 * Figma ID
 * 04-02-01-11
 * ウォレット使用、ウォレットNG
 */

interface WalletData {
  amountToUse: number;
}

function UseWalletModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  amount: number;
  maxToUse: number;
  submit: (amount: number) => Promise<boolean>;
}) {
  const { isOpen, onModalClose, amount, maxToUse, submit } = props;
  const { openErrorModal } = useErrorModal();

  const currencyFormatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  });

  const numberFormatter = new Intl.NumberFormat('ja-JP');

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<WalletData>({ reValidateMode: 'onChange' });

  function onClose() {
    setValue('amountToUse', 0);
    clearErrors();
    onModalClose();
  }

  const onSubmit = handleSubmit((data) => {
    submit(data.amountToUse * 100).then((isSuccess) => {
      onClose();
      if (!isSuccess) {
        openErrorModal({
          message: 'ウォレットの使用に失敗しました。もう一度やり直してください。',
        });
      }
    });
  });
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = !isMobile
    ? {
        header: (
          <Text variant="h2" color="darkBlue">
            ウォレット使用
          </Text>
        ),
        content: (
          <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
            <Box>
              <Text variant="body14" color="black">
                ウォレットを使用しますか？
              </Text>
            </Box>
            <Box>
              <Box>
                <Text variant="body14" color="black">
                  現在の残高: {currencyFormatter.format(amount)}
                </Text>
              </Box>
              <Box>
                <Text variant="caption11" color="blueGray">
                  本件の採用手数料で使用できる金額上限は
                </Text>
                <Text variant="caption12" color="blueGray" bold>
                  {numberFormatter.format(maxToUse)}円
                </Text>
                <Text variant="caption11" color="blueGray">
                  です。
                </Text>
              </Box>
            </Box>
            <Box>
              <Box>
                <Text variant="h3" color="blueGray">
                  使用金額
                </Text>
              </Box>
              <Box display="flex" alignItems="flex-end" gap={8}>
                <RhfInput
                  name="amountToUse"
                  control={control}
                  defaultValue={0}
                  type="number"
                  width={48}
                  min={0}
                  rules={{
                    min: { value: 1, message: '1以上の値を入力してください' },
                    max: { value: maxToUse / 100, message: '本件で使用できる金額を超えています' },
                    validate: (value) => {
                      // valueはstring型でわたってくるので、Numberに変換してから判定する
                      const valueAsNumber = Number(value);
                      if (!isInteger(valueAsNumber) || String(value) === '') {
                        return '整数を入力してください';
                      }

                      if (valueAsNumber * 100 > amount) {
                        return 'ウォレット残高を超えています';
                      }
                      return true;
                    },
                  }}
                />
                <Text variant="body14" color="blueGray">
                  00円
                </Text>
              </Box>
              <Box>
                {errors.amountToUse && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.amountToUse.message}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        ),
        footer: (
          <Box display="flex" justifyContent="flex-end" columnGap={8}>
            <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
              キャンセル
            </MainButton>
            <MainButton width={104} thin onClick={() => onSubmit()} disabled={!!errors.amountToUse}>
              使用
            </MainButton>
          </Box>
        ),
        height: 'auto',
        width: 384,
      }
    : {
        // feat_screen_04-02-01-11_start
        header: (
          <Text variant="h2" color="darkBlue">
            ウォレット使用
          </Text>
        ),
        content: (
          <Box display="flex" flexDirection="column" rowGap={12}>
            <Box>
              <Text variant="body14" color="black">
                ウォレットを使用しますか？
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" gap={8}>
              <Box>
                <Text variant="body14" color="black">
                  現在の残高: {currencyFormatter.format(amount)}
                </Text>
              </Box>
              <Box>
                <Text variant="body14" color="blueGray">
                  本件の採用手数料で使用できる金額上限は
                </Text>
                <Text variant="body14" color="blueGray" bold>
                  {numberFormatter.format(maxToUse)}円
                </Text>
                <Text variant="body14" color="blueGray">
                  です。
                </Text>
              </Box>
            </Box>
            <Box>
              <Box display="flex" flexDirection="column" gap={4}>
                <Box>
                  <Text variant="h3" color="blueGray">
                    使用金額
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" gap={8}>
                  <RhfInput
                    name="amountToUse"
                    control={control}
                    defaultValue={0}
                    type="number"
                    width={93}
                    min={0}
                    rules={{
                      min: { value: 1, message: '1以上の値を入力してください' },
                      max: { value: maxToUse / 100, message: '本件で使用できる金額を超えています' },
                      validate: (value) => {
                        // valueはstring型でわたってくるので、Numberに変換してから判定する
                        const valueAsNumber = Number(value);
                        if (!isInteger(valueAsNumber) || String(value) === '') {
                          return '整数を入力してください';
                        }

                        if (valueAsNumber * 100 > amount) {
                          return 'ウォレット残高を超えています';
                        }
                        return true;
                      },
                    }}
                  />
                  <Text variant="body14" color="blueGray">
                    00円
                  </Text>
                </Box>
              </Box>
              <Box>
                {errors.amountToUse && (
                  <Text variant="caption12" color="cautionRed">
                    {errors.amountToUse.message}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        ),
        footer: (
          <Box display="flex" justifyContent="flex-end" columnGap={8}>
            <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClose()}>
              キャンセル
            </MainButton>
            <MainButton width={96} customHeight={32} thin onClick={() => onSubmit()} disabled={!!errors.amountToUse}>
              使用
            </MainButton>
          </Box>
        ),
        height: 'auto',
        width: 384,
        // feat_screen_04-02-01-11_end
      };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={modalContent?.height}
      width={modalContent?.width}
    />
  );
}

export default UseWalletModal;
