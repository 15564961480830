import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import { ChangeRateModalForm, ModalRet, RhfSelectOptionsType } from 'pages/s05-01/type';
import React from 'react';
import { Control, UseFormTrigger } from 'react-hook-form';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function ChangeRate(
  control: Control<ChangeRateModalForm>,
  trigger: UseFormTrigger<ChangeRateModalForm>,
  educRateRange: { min: number; max: number },
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const educRateRangeList = [...(Array(educRateRange.max - educRateRange.min + 1) as number[])]
    .map((i, j) => j + educRateRange.min)
    .filter((item) => item % 5 === 0);
  const educOptions: RhfSelectOptionsType = educRateRangeList.map((item) => ({ label: `${item}%`, value: `${item}` }));
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          料率変更
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: !isMobile ? (
      <Box display="flex" flexDirection="column" gap={26} flex="1">
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            料率 (%)
          </Text>
          <RhfSelect
            name="rate"
            control={control}
            options={educOptions}
            fullWidth
            placeholder="選択"
            rules={{ required: true }}
          />
        </Box>
        <Text variant="body14">{`設定可能範囲：${educRateRange.min}% ~ ${educRateRange.max}%`}</Text>
      </Box>
    ) : (
      // feat_screen_05-01-03-02_start
      <Box display="flex" flexDirection="column" gap={16} flex="1">
        <Box display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            料率 (%)
          </Text>
          <RhfSelect
            height={40}
            name="rate"
            control={control}
            options={educOptions}
            fullWidth
            placeholder="選択"
            rules={{ required: true }}
            />
        </Box>
        <Text variant="body14">{`設定可能範囲：${educRateRange.min}% ~ ${educRateRange.max}%`}</Text>
      </Box>
      // feat_screen_05-01-03-02_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_05-01-03-02_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-03-02_end
          variant="secondary" 
          onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          // feat_screen_05-01-03-02_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-03-02_end
          onClick={() => {
            trigger('rate').then((isValid) => {
              if (isValid) {
                onNext();
              }
            });
          }}
        >
          保存
        </MainButton>
      </Box>
    ),
    // feat_screen_05-01-03-02_start
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 275 : "unset",
    // feat_screen_05-01-03-02_end
  };

  return modalContent;
}
