export const COLORS_NAME = {
  White: 'white',
  Gray: 'gray',
  Red: 'red',
  Yellow: 'yellow',
};

export enum StatusName {
  APPLYING = 0,
  APPROVED = 1,
  DENIAL = 2,
}

export enum PageNumber {
  initialValue = 1,
}
