/**
 * Figma ID: 04-03-03-02
 * 名称: 逆オファー受入 募集内容
 */
import React, { useState } from 'react';
import { useAuth } from 'hooks/authProvider';
import { format } from 'date-fns';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Info } from 'components/info';
import { TextLink } from 'components/textLink';
import { MainButton } from 'components/mainButton';
import { Modal, ImageModal } from 'components/newModal';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { Divider } from 'components/newDivider';
import { DateFormatForIso8601 } from 'components/utils/dateFormatForIso8601';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import { useErrorModal } from 'components/error/errorModalProvider';
import { TArbeitOffer } from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useVerifyInfoConnectApi } from '../../../common/hooks';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  bOfferId?: number;
  onPdfPreview: (arbeitOfferData?: TArbeitOffer) => void;
}

export function VerifyModalComponent({ isOpen, onClose, bOfferId, onPdfPreview }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;

  const { data, error: arbeitOfferError } = useVerifyInfoConnectApi(Number(tpmemId), bOfferId || 0);
  const dispData = data?.getTArbeitOfferByTpmemIdBOfferId[0];
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const { openErrorModal } = useErrorModal();
  /**
   * エラー処理
   */
  React.useEffect(() => {
    if (arbeitOfferError) {
      openErrorModal({
        message: '募集内容が取得できませんでした。',
      });
    }
  }, [arbeitOfferError, openErrorModal]);
  /**
   * 添付ファイルのパス、ファイル名をセット
   */
  const attachedFilePath = dispData?.attached_file;
  const attachedFileName = dispData?.attached_name || '';

  const clearEvent = () => {
    onClose();
  };

  const displayModal = () => ({
    // feat screen 04-03-03-02 start
    width: !isMobile ? 800 : '100%',
    height: !isMobile ? '80%' : '72.5%',
    // feat screen 04-03-03-02 end
    header: (
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          逆オファー受入 募集内容
        </Text>
        {
          // feat screen 04-03-03-02 start
          !isMobile && 
          <MainButton
            onClick={() => {
              onPdfPreview(dispData);
            }}
            variant="secondary"
            width={200}
          >
            労働条件通知書プレビュー
          </MainButton>
          // feat screen 04-03-03-02 end
        }
      </Box>
    ),
    // feat screen 04-03-03-02 start
    content: !isMobile ?(
      <Box>
        <Box mt={16} mb={16}>
          <Text color="darkBlue" variant="h2">
            募集情報
          </Text>
        </Box>
        <Divider length={0} option="horizontal" />
        {dispData ? (
          <Box>
            <Info
              items={[
                {
                  content: '逆オファー',
                  label: '採用方式',
                },
                {
                  content: dispData?.title || '',
                  label: '掲載タイトル',
                },
                {
                  content: dispData?.employer || '',
                  label: '募集店',
                },
                {
                  content: `${format(
                    new Date(DateFormatForIso8601(dispData?.begin_date || ' ')),
                    'yyyy年MM月dd日 HH:mm'
                  )} ~ ${format(new Date(DateFormatForIso8601(dispData?.end_date || ' ')), 'yyyy年MM月dd日 HH:mm')}`,
                  label: 'バイト期間',
                },
                {
                  content: dispData?.break_time ? `${String(dispData?.break_time)}分` : '無',
                  label: '休憩時間',
                },
                {
                  content: dispData?.hour_wage ? `¥${String(dispData?.hour_wage)}` : '',
                  label: '時給',
                },
                {
                  content: dispData?.chief || '',
                  label: '当日の店舗責任者',
                },
                {
                  content: dispData?.trans_fee ? `¥${String(dispData?.trans_fee)}` : '',
                  label: '交通費',
                },
                {
                  content: (
                    <Text wordBreak="break-all" variant="body14">
                      {charcterReplaceBr(dispData?.description)}
                    </Text>
                  ),
                  label: '業務詳細',
                },
                {
                  content: (
                    <Text wordBreak="break-all" variant="body14">
                      {charcterReplaceBr(dispData?.conditions)}
                    </Text>
                  ),
                  label: '働くための条件',
                },
                {
                  content: (
                    <Text wordBreak="break-all" variant="body14">
                      {charcterReplaceBr(dispData?.caution)}
                    </Text>
                  ),
                  label: '注意事項',
                },
                {
                  content: (
                    <Text wordBreak="break-all" variant="body14">
                      {charcterReplaceBr(dispData?.belongings)}
                    </Text>
                  ),
                  label: '持ち物',
                },
                {
                  content: (
                    <Text wordBreak="break-all" variant="body14">
                      {charcterReplaceBr(dispData?.access)}
                    </Text>
                  ),
                  label: 'アクセス',
                },
                {
                  content: (
                    <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                      {dispData?.image1 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image1 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '94.5px' }}
                            src={dispData?.image1 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image2 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image2 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '94.5px' }}
                            src={dispData?.image2 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image3 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image3 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '94.5px' }}
                            src={dispData?.image3 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image4 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image4 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '94.5px' }}
                            src={dispData?.image4 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image5 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image5 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '94.5px' }}
                            src={dispData?.image5 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                    </div>
                  ),
                  label: 'メイン画像',
                },
                {
                  content: attachedFilePath ? (
                    <TextLink href={attachedFilePath || '#'} variant="body14">
                      {attachedFileName}
                    </TextLink>
                  ) : (
                    '無'
                  ),
                  label: '添付ファイル',
                },
              ]}
            />
            <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
          </Box>
        ) : (
          <Box pt={24}>
            <Text variant="body14">データがありません</Text>
          </Box>
        )}
      </Box>
    ) : (
      // Responsive Component
      <Box>
        <Box pb={15}>
          <Box display="flex" alignItems="center" justifyContent="center" pb={24}>
            <MainButton
              onClick={() => {
                onPdfPreview(dispData);
              }}
              variant="secondary"
              width={212}
            >
              労働条件通知書プレビュー
            </MainButton>
          </Box>
          <Text color="darkBlue" variant="body14" bold>
            募集情報
          </Text>
        </Box>
        <Divider length={0} option="horizontal" />
        {dispData ? (
          <Box>
            <Info
              items={[
                {
                  content: '逆オファー',
                  label: '採用方式',
                },
                {
                  content: dispData?.title || '',
                  label: '掲載タイトル',
                },
                {
                  content: dispData?.employer || '',
                  label: '募集店',
                },
                {
                  content: `${format(
                    new Date(DateFormatForIso8601(dispData?.begin_date || ' ')),
                    'yyyy年MM月dd日 HH:mm'
                  )} ~ ${format(new Date(DateFormatForIso8601(dispData?.end_date || ' ')), 'yyyy年MM月dd日 HH:mm')}`,
                  label: 'バイト期間',
                },
                {
                  content: dispData?.break_time ? `${String(dispData?.break_time)}分` : '無',
                  label: '休憩時間',
                },
                {
                  content: dispData?.hour_wage ? `¥${String(dispData?.hour_wage)}` : '',
                  label: '時給',
                },
                {
                  content: dispData?.chief || '',
                  label: '当日の店舗責任者',
                },
                {
                  content: dispData?.trans_fee ? `¥${String(dispData?.trans_fee)}` : '',
                  label: '交通費',
                },
                {
                  content: (
                    <Text wordBreak="break-all" align="right" variant="body14">
                      {charcterReplaceBr(dispData?.description)}
                    </Text>
                  ),
                  label: '業務詳細',
                },
                {
                  content: (
                    <Text wordBreak="break-all" align="right" variant="body14">
                      {charcterReplaceBr(dispData?.conditions)}
                    </Text>
                  ),
                  label: '働くための条件',
                },
                {
                  content: (
                    <Text wordBreak="break-all" align="right" variant="body14">
                      {charcterReplaceBr(dispData?.caution)}
                    </Text>
                  ),
                  label: '注意事項',
                },
                {
                  content: (
                    <Text wordBreak="break-all" align="right" variant="body14">
                      {charcterReplaceBr(dispData?.belongings)}
                    </Text>
                  ),
                  label: '持ち物',
                },
                {
                  content: (
                    <Text wordBreak="break-all" align="right" variant="body14">
                      {charcterReplaceBr(dispData?.access)}
                    </Text>
                  ),
                  label: 'アクセス',
                },
                {
                  content: (
                    <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '4px', justifyContent: "flex-end" }}>
                      {dispData?.image1 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image1 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '95px' }}
                            src={dispData?.image1 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image2 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image2 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '95px' }}
                            src={dispData?.image2 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image3 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image3 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '95px' }}
                            src={dispData?.image3 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image4 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image4 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '95px' }}
                            src={dispData?.image4 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                      {dispData?.image5 && (
                        <ImgWrapper
                          onClick={() => {
                            setModalImageSrc(dispData?.image5 || '');
                            setIsImageModalOpen(true);
                          }}
                        >
                          <img
                            style={{ width: '126px', height: '95px' }}
                            src={dispData?.image5 || ''}
                            alt="サムネイル"
                          />
                        </ImgWrapper>
                      )}
                    </div>
                  ),
                  label: 'メイン画像',
                },
                {
                  content: attachedFilePath ? (
                    <TextLink href={attachedFilePath || '#'} variant="body14">
                      {attachedFileName}
                    </TextLink>
                  ) : (
                    '無'
                  ),
                  label: '添付ファイル',
                },
              ]}
            />
            <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
          </Box>
        ) : (
          <Box pt={24}>
            <Text variant="body14">データがありません</Text>
          </Box>
        )}
      </Box>
      // feat screen 04-03-03-02 end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {/* feat screen 04-03-03-02 start */}
        <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
          閉じる
        </MainButton>
        {/* feat screen 04-03-03-02 end */}
      </Box>
    ),
  });
  const modalContents = displayModal();
  return (
    <Modal
      width={modalContents?.width}
      height={modalContents?.height}
      header={modalContents?.header}
      content={modalContents?.content}
      footer={modalContents?.footer}
      isOpen={isOpen}
      onClose={() => clearEvent()}
    />
  );
}
