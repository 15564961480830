/**
 * Figma ID: 04-01-01-13
 * 名称: バイト募集（応募TOP）
 */

import React, { ReactNode, useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as gql from 'graphql/graphql-mw';
import { ScrollWrapper, HideWrapper, ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination/paginationBox';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import { Box } from 'components/box';
import { Chip } from 'components/chip';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { orderBy } from 'lodash';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import BasicInfoModal from './modal/BasicInfoModal';
import { useSort, usePagenation } from '../../common/utils';
import { useBaseSkillCostConnectApi, useEntryListConnectApi } from '../../common/hooks';
import { KindName, EmploymentDecision } from '../../common/const';

type Props = {
  count: number; // 応募数
};

export function RecruitInfo({ count }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  const { id } = useParams();
  const [dataSize, setDataSize] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [offerList, setOfferList] = useState<gql.VtArbeitEntryList>();
  // エラー処理の判断用
  const [entryId, setEntryId] = useState<number>(0);
  /**
   * [API] 応募一覧取得
   */
  const {
    getVtArbeitOfferListByIdMutation,
    data: entryListResponse,
    loading: entryListLoading,
  } = useEntryListConnectApi(Number(id));

  const dataEntryList = useMemo(() => entryListResponse?.getVTArbeitOfferListById.entry || [], [entryListResponse]);
  const dataOfferStatus = entryListResponse?.getVTArbeitOfferListById.offer.status;

  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (!id) return;
    if (count === undefined || count === null) return;

    getVtArbeitOfferListByIdMutation({
      variables: { id: Number(id) },
    })
      .then((result) => {
        if (!result.errors) {
          // 既読で必要であれば
        } else {
          openErrorModal({
            message: '既読更新に失敗しました。',
          });
        }
      })
      .catch(() => {
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  }, [id, count, getVtArbeitOfferListByIdMutation, openErrorModal]);

  /**
   * [API] 基本情報、スキル、コスト取得
   */

  const {
    setId: setIdBSC,
    data: dataBSC,
    loading: loadingBSC,
    error: errorBsc,
    refetch: refetchBSC,
  } = useBaseSkillCostConnectApi();

  /**
   * エラー処理
   */
  useEffect(() => {
    if (errorBsc && entryId > 0) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [errorBsc, openErrorModal, entryId]);

  /**
   * 基本情報
   */
  const dataBase = useMemo(() => (dataBSC?.getVTBtmemBaseSkillCostByEntryId?.base || []) as gql.VtBtmemBase, [dataBSC]);
  /**
   * スキル情報
   */
  const dataSkill = useMemo(
    () => (dataBSC?.getVTBtmemBaseSkillCostByEntryId?.skill || []) as gql.VtBtSkillCheck[],
    [dataBSC]
  );
  /**
   * コスト情報
   */
  const dataCost = useMemo(
    () => (dataBSC?.getVTBtmemBaseSkillCostByEntryId?.cost || {}) as gql.VtBtCostInfo,
    [dataBSC]
  );

  const onClickRefetchBasicInfo = () => {
    refetchBSC();
  };

  const onClickEye = useCallback(
    (item: gql.VtArbeitEntryList | null) => {
      if (!item) return;
      setIdBSC(item?.entry_id || 0);
      setEntryId(item?.entry_id);
      setOfferList(item);
      setIsModalOpen(true);
    },
    [setIsModalOpen, setIdBSC]
  );

  /**
   * ソートとページネーション
   */
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  const getChip = (status: number) => {
    switch (status) {
      case EmploymentDecision.YES:
        return <Chip color="okBlue">採用確定</Chip>;
      case EmploymentDecision.NO:
        return <Chip color="liteGray">不採用</Chip>;
      default:
        return null;
    }
  };

  const generateBtmemNumber = useCallback((btmemNumber?: string | null, status?: number | null) => {
    if (!btmemNumber || typeof status !== 'number') return '';
    return (
      <>
        {getChip(status)}
        <Text variant="caption12">{String(btmemNumber)}</Text>
      </>
    );
  }, []);

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    {
      columnName: '',
      key: 'eye',
      // feat screen 04-01-01-14 start
      width: !isMobile ? 24 : 20,
      // feat screen 04-01-01-14 end
    },
    {
      columnName: '',
      key: 'image',
      // feat screen 04-01-01-14 start
      width: !isMobile ? 40 : 32,
      // feat screen 04-01-01-14 end
    },
    {
      columnName: '会員番号',
      key: 'btmem_number',
      // feat screen 04-01-01-14 start
      width: !isMobile ? 190 : 162,
      // feat screen 04-01-01-14 end
    },
    {
      columnName: '応募No',
      key: 'entry_no',
      width: 120,
    },
    {
      columnName: '採用手数料',
      key: 'total_hire_fee',
      width: 93,
    },
    {
      columnName: '所属',
      key: 'kind_name',
      width: 100,
    },
    {
      columnName: 'スコア',
      key: 'score',
      width: 80,
    },
    {
      columnName: 'ランク',
      key: 'rank_name',
      width: 80,
    },
    {
      columnName: '返答期限',
      key: 'deadline_date',
      width: !isMobile ? 160 : 140,
    },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let items = dataEntryList || [];

    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'identification', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items
      .map((item) => ({
        uniqueKey: `${String(item?.offer_id)}_${Math.random()}`,
        // feat screen 04-01-01-13 change size start
        eye: <Icon name="eye" size={!isMobile ? 24 : 20} onClick={() => onClickEye(item)} />,
        // feat screen 04-01-01-13 change size end
        image: (
          <ImgWrapper>
            {/* feat screen 04-01-01-14 start */}
            {!isMobile ? (
              <img style={{ width: '40px', height: '40px' }} src={item?.image || ''} alt="" />
            ) : (
              <img style={{ width: '32px', height: '32px', display: 'block' }} src={item?.image || ''} alt="" />
            )}
            {/* feat screen 04-01-01-14 end */}
          </ImgWrapper>
        ),
        btmem_number: generateBtmemNumber(item?.btmem_number, item?.status),
        total_hire_fee: `¥${item?.total_hire_fee || '0'}`,
        kind_name: item?.kind_name,
        score: item?.score,
        rank_name: item?.rank_name,
        entry_no: item?.entry_no,
        entry_id: item?.entry_id,
        deadline_date: item?.deadline_date,
      }))
      .slice(limit * (page - 1), limit * page);
    // feat screen 04-01-01-14 add deps start
  }, [isMobile, sort, page, limit, dataEntryList, onClickEye, generateBtmemNumber]);
  //  feat screen 04-01-01-14 add deps  end

  const countKindName = useMemo(
    () =>
      dataEntryList?.reduce((acc, cur) => {
        if (!cur) return acc;
        const { kind_name: kindName } = cur;
        if (kindName) {
          if (kindName in acc) {
            acc[kindName] += 1;
          } else {
            acc[kindName] = 1;
          }
        }
        return acc;
      }, {} as { [key: string]: number }),
    [dataEntryList]
  );

  // feat screen 04-01-01-13 start
  return !isMobile ? (
    <HideWrapper>
      <Box px={16} pt={0} pb={24}>
        <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
          <div style={{ flexGrow: 2 }}>
            <Text color="darkBlue" variant="h2">
              応募（{String(dataEntryList?.length || 0)}）
            </Text>
          </div>
          <div>
            <MainButton onClick={() => {}} variant="secondary" width={103}>
              <Box display="flex" alignItems="center" gap={4}>
                <div>{KindName.GENERAL}</div>
                <Text variant="h2">{countKindName?.[KindName.GENERAL] || '0'}</Text>
              </Box>
            </MainButton>
          </div>
          <div>
            <MainButton onClick={() => {}} variant="secondary" width={63}>
              <Box display="flex" alignItems="center" gap={4}>
                <div>{KindName.BELONGED}</div> <Text variant="h2">{countKindName?.[KindName.BELONGED] || '0'}</Text>
              </Box>
            </MainButton>
          </div>
          <div>
            <MainButton onClick={() => {}} variant="secondary" width={63}>
              <Box display="flex" alignItems="center" gap={4}>
                <div>{KindName.SUB}</div> <Text variant="h2">{countKindName?.[KindName.SUB] || '0'}</Text>
              </Box>
            </MainButton>
          </div>
          <div>
            <MainButton onClick={() => {}} variant="secondary" width={91}>
              <Box display="flex" alignItems="center" gap={4}>
                <div>{KindName.GROUP}</div> <Text variant="h2">{countKindName?.[KindName.GROUP] || '0'}</Text>
              </Box>
            </MainButton>
          </div>
        </Box>
        <Divider option="horizontal" />
        {loadingBSC || entryListLoading ? (
          <Loading />
        ) : (
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <ScrollWrapper bottom={325}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                width={1584}
                rowWidth={1584}
              />
            </ScrollWrapper>
          </PaginationBox>
        )}
      </Box>
      {offerList && dataBSC && dataEntryList && (
        <BasicInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          offerList={offerList}
          base={dataBase}
          skill={dataSkill}
          cost={dataCost}
          offerStatus={dataOfferStatus}
          refetchBasicInfo={() => onClickRefetchBasicInfo()}
          refetch={() => {
            getVtArbeitOfferListByIdMutation();
          }}
        />
      )}
    </HideWrapper>
  ) : (
    // Responsive Component
    <Box height="100%" width="100%">
      <Box pt={0}>
        <Box px={12} pt={0} display={isShowFilter ? 'flex' : 'none'} width="100%" flexDirection="column">
          <div style={{ flexGrow: 2, padding: '10px 0' }}>
            <Text color="darkBlue" align="center" variant="h2">
              応募（{String(dataEntryList?.length || 0)}）
            </Text>
          </div>
          <Box display="flex" flexWrap="wrap" gap={12} pb={8}>
            <div>
              <MainButton onClick={() => {}} variant="secondary" width={103}>
                <Box display="flex" alignItems="center" gap={4}>
                  <div>{KindName.GENERAL}</div>
                  <Text variant="h2">{countKindName?.[KindName.GENERAL] || '0'}</Text>
                </Box>
              </MainButton>
            </div>
            <div>
              <MainButton onClick={() => {}} variant="secondary" width={71}>
                <Box display="flex" alignItems="center" gap={4}>
                  <div>{KindName.BELONGED}</div> <Text variant="h2">{countKindName?.[KindName.BELONGED] || '0'}</Text>
                </Box>
              </MainButton>
            </div>
            <div>
              <MainButton onClick={() => {}} variant="secondary" width={71}>
                <Box display="flex" alignItems="center" gap={4}>
                  <div>{KindName.SUB}</div> <Text variant="h2">{countKindName?.[KindName.SUB] || '0'}</Text>
                </Box>
              </MainButton>
            </div>
            <div>
              <MainButton onClick={() => {}} variant="secondary" width={100}>
                <Box display="flex" alignItems="center" gap={4}>
                  <div>{KindName.GROUP}</div> <Text variant="h2">{countKindName?.[KindName.GROUP] || '0'}</Text>
                </Box>
              </MainButton>
            </div>
          </Box>
        </Box>
      </Box>
      <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      {loadingBSC || entryListLoading ? (
        <Loading />
      ) : (
        <Box flex="auto" py={0} height={`calc(100% - ${isShowFilter ? '124' : '26'}px)`}>
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <List
              key={String(limit) + String(page) + (sort?.key || '')}
              header={header}
              items={displayItems}
              sort={sort}
              onChangeSort={handleChangeSort}
              width="100%"
              rowWidth="100%"
              rowHeight={48}
            />
          </PaginationBox>
        </Box>
      )}
      {offerList && dataBSC && dataEntryList && (
        <BasicInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          offerList={offerList}
          base={dataBase}
          skill={dataSkill}
          cost={dataCost}
          offerStatus={dataOfferStatus}
          refetchBasicInfo={() => onClickRefetchBasicInfo()}
          refetch={() => {
            getVtArbeitOfferListByIdMutation();
          }}
        />
      )}
    </Box>
  );
  // feat screen 04-01-01-13 end
}
