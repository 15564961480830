import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ModalRet } from 'pages/s05-01/type';
import React from 'react';

export function Complete(
  onClose: ()=> void,
): ModalRet {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック 完了
      </Text>
    ),
    content: (
      <Box display='flex' height='100%' flexDirection='row' alignItems='center' flex='1'>
        <Text variant='body14'>
          このバイトラベラーをブロックしました。
        </Text>
      </Box>
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton 
          width={104} variant="secondary" thin 
          onClick={() => {
            onClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_05-01-01-07_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          customHeight={32}
          width={96}
          variant="secondary" 
          thin 
          onClick={() => {
            onClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
      /* feat_screen_05-01-01-07_end */
    ),
    /* feat_screen_05-01-01-07_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : 171
    /* feat_screen_05-01-01-07_end */
  };

  return modalContent;
}