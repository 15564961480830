import { Box } from 'components/box';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { ThreeDotLeader } from 'components/threeDotLeader';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { ChangeRateModal } from 'pages/s05-01/modal';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ErrorModal } from 'components/errorModal';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';

enum ModalType {
  NONE = 'none',
  CHANGE_RATE = 'changeRate',
}

export function EducationalCost() {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth?.tpmemId ?? 0;

  const [isError, setIsError] = React.useState(false);

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.NONE);
  const { openErrorModal } = useErrorModal();

  const {
    data: { getVTBtmemEducInfoByTpmemIdBtmemId: data } = {
      getVTBtmemEducInfoByTpmemIdBtmemId: {} as gql.VtBtmemEducInfo,
    },
    loading,
    error: educError,
    refetch,
  } = gql.useGetVtBtmemEducInfoByTpmemIdBtmemIdQuery({
    variables: { tpmem_id: tpmemId, btmem_id: btmemId },
  });
  React.useEffect(() => {
    if (educError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [educError, openErrorModal]);

  const [updateTBelongBtmemEducFeeByIdBtmemIdKindMutation] = gql.useUpdateTBelongBtmemEducFeeByIdBtmemIdKindMutation({
    variables: {
      btmem_id: btmemId,
      educ_fee: data.default_educ ?? 0,
      id: tpmemId,
      // このページがメインのときのみ表示されるので、kindは0固定
      kind: 0,
    },
  });
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <>
      {loading && <Loading />}
      {
        !isMobile ? (
          <Box px={16} display="flex" flex="1" flexDirection="column">
            <Box display="flex" flexDirection="row" py={18} justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="h2" color="darkBlue">
                  育成料
                </Text>
                <Text variant="caption12" color="darkBlue">
                  育成料の割合をバイトラベラーごとに変更できます。
                </Text>
              </Box>
              <Box display="flex" flexDirection="row" gap={16}>
                <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                  <Text variant="body14" color="darkBlue">
                    {data.name}
                  </Text>
                  <Text variant="h2" color="darkBlue">
                    {data.rank_name}ランク
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                  <Text variant="body14" color="darkBlue">
                    初期値
                  </Text>
                  <Text variant="h2" color="darkBlue">
                    {data.default_educ ?? 0}%
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                  <Text variant="body14" color="darkBlue">
                    設定可能範囲
                  </Text>
                  <Text variant="h2" color="darkBlue">
                    {data.min_educ ?? 0}% ~ {data.max_educ ?? 0}%
                  </Text>
                </Box>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="column" flex="auto">
              <Box display="flex" flexDirection="row" justifyContent="space-between" py={13}>
                <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                  <Box width={160}>
                    <Text variant="caption12" bold color="darkBlue">
                      現在の育成料料率
                    </Text>
                  </Box>
                  <Divider option="vertical" length={18} />
                  <Text variant="caption12" color="darkBlue">
                    {data.educ_per ?? 0}%
                  </Text>
                </Box>
                <ThreeDotLeader
                  menu={[
                    { label: '料率を変更', value: '料率を変更' },
                    { label: '初期値に戻す', value: '初期値に戻す' },
                  ]}
                  onClick={(value) => {
                    if (value === '料率を変更') {
                      setActiveModalType(ModalType.CHANGE_RATE);
                    }
                    if (value === '初期値に戻す') {
                      updateTBelongBtmemEducFeeByIdBtmemIdKindMutation()
                        .then((result) => {
                          if (result.data?.updateTBelongBtmemEducFeeByIdBtmemIdKind.resultRows === ResultRows.UPDATED) {
                            refetch();
                          } else {
                            setIsError(true);
                          }
                        })
                        .catch(() => setIsError(true));
                    }
                  }}
                  right={0}
                />
              </Box>
              <Divider option="horizontal" />
            </Box>
          </Box>
        ): (
          /* feat_screen_05-01-03-01_start */
          <Box width="calc(100vw - 32px)">
            <Box px={16} display="flex" flex="1" flexDirection="column" height="calc(100svh - 380px)" width="100%">
              <Box display="flex" flexDirection="column" pt={16} pb={13} gap={12}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Text variant="h2" color="darkBlue">
                    育成料
                  </Text>
                  <Text variant="caption12" color="darkBlue">
                    育成料の割合をバイトラベラーごとに変更できます。
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" gap={40} pt={2}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Text variant="caption11" color="darkBlue">
                      {data.name}
                    </Text>
                    <Text variant="caption11" color="darkBlue" bold>
                      {data.rank_name}ランク
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" >
                      <Text variant="caption11" color="darkBlue">
                        初期値
                      </Text>
                      <Text variant="caption11" color="darkBlue" bold>
                        {data.default_educ ?? 0}%
                      </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center">
                      <Text variant="caption11" color="darkBlue">
                        設定可能範囲
                      </Text>
                      <Text variant="caption11" color="darkBlue" bold>
                        {data.min_educ ?? 0}% ~ {data.max_educ ?? 0}%
                      </Text>
                  </Box>
                </Box>
              </Box>
              <Divider option="horizontal" />
              <Box display="flex" flexDirection="column" flex="auto">
                <Box display="flex" flexDirection="row" justifyContent="space-between" py={13}>
                  <Box display="flex" flexDirection="row" gap={6} alignItems="center">
                    <Box width={112}>
                      <Text variant="body14" bold color="darkBlue">
                        現在の育成料料率
                      </Text>
                    </Box>
                    <Divider option="vertical" length={18} />
                    <Text variant="caption12" color="darkBlue">
                      {data.educ_per ?? 0}%
                    </Text>
                  </Box>
                  <ThreeDotLeader
                    menu={[
                      { label: '料率を変更', value: '料率を変更' },
                      { label: '初期値に戻す', value: '初期値に戻す' },
                    ]}
                    onClick={(value) => {
                      if (value === '料率を変更') {
                        setActiveModalType(ModalType.CHANGE_RATE);
                      }
                      if (value === '初期値に戻す') {
                        updateTBelongBtmemEducFeeByIdBtmemIdKindMutation()
                          .then((result) => {
                            if (result.data?.updateTBelongBtmemEducFeeByIdBtmemIdKind.resultRows === ResultRows.UPDATED) {
                              refetch();
                            } else {
                              setIsError(true);
                            }
                          })
                          .catch(() => setIsError(true));
                      }
                    }}
                    right={0}
                  />
                </Box>
                <Divider option="horizontal" />
              </Box>
            </Box>
          </Box>
          /* feat_screen_05-01-03-01_end */
        )
      }
      <ChangeRateModal
        isOpen={activeModalType === ModalType.CHANGE_RATE}
        onClose={() => setActiveModalType(ModalType.NONE)}
        educRateRange={{ min: data.min_educ ?? 0, max: data.max_educ ?? 0 }}
        refetch={refetch}
      />
      <ErrorModal
        isOpen={isError}
        onClose={() => setIsError(false)}
        title="エラー"
        message="料率を初期値に変更できませんでした"
      />
    </>
  );
}
