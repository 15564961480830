/**
 * Figma ID: 04-01-01-02
 * 名称: バイト募集（募集情報）
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useMemo, useEffect, useReducer } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { VtArbeitOfferInfo, RoRes } from 'graphql/graphql-mw';
import { useForm } from 'react-hook-form';
import { useScrollToFormError } from 'components/utils/useScrollToFormError';
import { styled } from '@linaria/react';
import { useAuth } from 'hooks/authProvider';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { RecruitStatus, ResultRows } from 'components/const';
import { Modal, ImageModal } from 'components/newModal';
import { StickyHeader, ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { useNoticeOfEmploymentPdfPreview, useNoticeOfEmploymentPdfDownload } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import { handleMutationResult } from 'components/graphqlResult/handleMutationResult';
import { useRank } from 'hooks/master/useRank';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { getPdfPreviewProps } from '../../common/utils';
import { FormData } from '../../common/formState';
import {
  usePostDailyFormConnectApi,
  useCreateDailyDraftConnectApi,
  useCreateWeeklyDraftConnectApi,
  useCreateDailyDeliveryConnectApi,
  useCreateWeeklyDeliveryConnectApi,
} from '../../common/hooks';
import { ModalCreateNew } from '../../components/modal/form';
import { ModalNewPreview } from '../../components/modal/preview';
import { ScheduleType } from '../../common/scheduleTypeContext';
import {
  State as WeeklyOfferNumberState,
  Action as WeekluOfferNumberAction,
  WeeklyOfferNumberContext,
} from '../../common/weeklyOfferNumberContext';

const StyledDate = styled.div`
  font-size: 11px;
  color: #000;
`;

enum ModalStep {
  NULL = 0,
  EDIT_OPEN = 1,
  EDIT_NORMAL = 2,
  EDIT_PREVIEW = 3,
  EDIT_SAVE = 4,
  EDIT_DONE = 5,
  DUPLICATE_START = 6,
  DUPLICATE_EDIT = 7,
  DUPLICATE_PREVIEW = 8,
  DUPLICATE_DONE = 9,
  DELIVERY = 10,
  REGULAR_WEEKLY = 11,
  DELIVERY_DONE = 12,
  REGULAR_WEEKLY_DONE = 13,
}

enum AutoDecide {
  MANUAL = 0,
  AUTO = 1,
  COUNTEROFFER = 2,
}

type Props = {
  status: number; // 最新の状態
  basicInfoData?: VtArbeitOfferInfo;
  location: ReturnType<typeof useLocation>;
  setOrderNumber: React.Dispatch<React.SetStateAction<string>>;
};

type State = {
  status: number;
};

/**
 * 配信予定件数Reducer
 */
const weeklyOfferNumberInitialState: WeeklyOfferNumberState = { offerNumber: 0 };
const weeklyOfferNumberReducer = (
  state: WeeklyOfferNumberState,
  action: WeekluOfferNumberAction
): WeeklyOfferNumberState => {
  switch (action.type) {
    case 'SET_OFFER_NUMBER':
      return { offerNumber: action.content };
    default:
      return { offerNumber: state.offerNumber };
  }
};

// feat screen 04-01-01-02 start
const StickyMobileHeader = styled.div<{
  height: number;
}>`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 3px;
  right: 2px;
  gap: 8px;
`;
// feat screen 04-01-01-02 end

export function BasicInfo({ status, basicInfoData, location, setOrderNumber }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // 認証情報
  const auth = useAuth();
  const { tpmemId } = auth;
  const { id } = useParams();
  const navigate = useNavigate();
  // 最新の状態
  const latestStatus = status;

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.NULL);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const [fixedFormData, setFixedFormData] = useState<FormData>({});
  const [dailyOrderNumber, setDailyOrderNumber] = useState<string>('');
  const [weeklyOrderNumbers, setWeeklyOrderNumbers] = useState<(RoRes | null)[]>([]);
  const [state, dispatch] = useReducer(weeklyOfferNumberReducer, weeklyOfferNumberInitialState);
  const [isDraftClicked, setIsDraftClicked] = useState<boolean>(false);
  // バリテーション取得用 OfferNumber
  const [draftOfferId, setDraftOfferId] = React.useState<number>(0);

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormData>({ reValidateMode: 'onSubmit' });

  useScrollToFormError({ isSubmitting, errors });

  const { getRankLabel } = useRank();

  /**
   * エラーダイアログ
   */
  const { openErrorModal } = useErrorModal();

  /**
   * [API] Mutation（単日のみ/編集保存）
   */
  const {
    updateTArbeitOfferByIdMutation,
    setId: setDailySaveId,
    setFormData: setDailyFormData,
    setApiResponseData,
  } = usePostDailyFormConnectApi();

  /**
   * [API] Mutation（単日/下書き保存）
   */
  const { addTArbeitOfferMutation: createDailyDraftMutation, setFormData: setDailyDraftFormData } =
    useCreateDailyDraftConnectApi(Number(tpmemId));

  /**
   * [API] Mutation（週定期/下書き保存）
   */

  const {
    addTRegularlyOfferMutation: createWeeklyDraftMutation,
    setFormData: setWeeklyDraftFormData,
    error: weeklyDraftCreationError,
  } = useCreateWeeklyDraftConnectApi(Number(tpmemId));

  /**
   * [API] Mutation（単日/配信）
   */
  const { addTArbeitOfferResMutation: createDailyMutation, setFormData: setCreateDailyDeliveryFormData } =
    useCreateDailyDeliveryConnectApi(Number(tpmemId));

  /**
   * [API] Mutation（週定期/確定）
   */
  const { addTRegularlyOfferResMutation: createWeeklyMutation, setFormData: setCreateWeeklyDeliveryFormData } =
    useCreateWeeklyDeliveryConnectApi(Number(tpmemId));

  const apiError = weeklyDraftCreationError;

  useEffect(() => {
    if (apiError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  /**
   * ［onSubmit］複製画面下書き保存
   */
  const onClickDraft = handleSubmit((data) => {
    switch (data.check_schedule_type) {
      case ScheduleType.DAILY:
        setDailyDraftFormData(data);
        /**
         * Timeout入れないと送信データが更新されない
         */
        setTimeout(() => {
          createDailyDraftMutation()
            .then((result) => {
              if (!result.errors) {
                // ResultRowsをみないと登録できたかわからないため
                if (result.data?.addTArbeitOffer.resultRows === ResultRows.NOT_UPDATED) {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                } else {
                  setModalStep(ModalStep.DUPLICATE_DONE);
                }
              } else {
                setModalStep(ModalStep.NULL);
                openErrorModal({ message: '下書き保存に失敗しました。' });
              }
            })
            .catch(() => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }, 500);
        break;
      case ScheduleType.WEEKLY:
        setWeeklyDraftFormData(data);
        /**
         * Timeout入れないと送信データが更新されない
         */
        setTimeout(() => {
          createWeeklyDraftMutation()
            .then((result) => {
              if (!result.errors) {
                // ResultRowsをみないと登録できたかわからないため
                if (result.data?.addTRegularlyOffer.resultRows === ResultRows.NOT_UPDATED) {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                } else {
                  setModalStep(ModalStep.DUPLICATE_DONE);
                }
              } else {
                setModalStep(ModalStep.NULL);
                openErrorModal({ message: '下書き保存に失敗しました。' });
              }
            })
            .catch(() => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }, 500);
        break;
      default:
        break;
    }
  });

  /**
   * ［onSubmit］プレビュー（編集）
   */
  const onClickEditConfirm = handleSubmit((data) => {
    /**
     * 編集画面ではフォームが無いケースがあるので
     * APIのレスポンスデータをそのまま格納する
     */
    setApiResponseData(basicInfoData);

    /**
     * Mutation用フォームデータをセット
     */
    setDailyFormData(data);
    setDailySaveId(Number(id));

    /**
     * Preview用フォームデータをセット
     */
    setFixedFormData(data);
    setModalStep(ModalStep.EDIT_PREVIEW);
  });

  /**
   * ［onSubmit］プレビュー（複製）
   */
  const onClickDuplicateConfirm = handleSubmit((data) => {
    /**
     * Mutation用フォームデータをセット
     */
    switch (data.check_schedule_type) {
      case ScheduleType.DAILY:
        setCreateDailyDeliveryFormData(data);
        break;
      case ScheduleType.WEEKLY:
        setCreateWeeklyDeliveryFormData(data);
        break;
      default:
        break;
    }
    /**
     * Preview用フォームデータをセット
     */
    setFixedFormData(data);
    setModalStep(ModalStep.DUPLICATE_PREVIEW);
  });

  /**
   * 親コンポーネントにOrderNumberを渡す
   */
  useEffect(() => {
    setOrderNumber(basicInfoData?.offer_number || '');
  }, [basicInfoData, setOrderNumber]);

  /**
   * [API] 単日配信（保存）
   */
  const onClickDailySave = () => {
    createDailyMutation()
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        } else {
          const errorCode = result.data?.addTArbeitOfferRes.ErrorCode;
          if (errorCode != null) {
            handleMutationResult(
              errorCode,
              () => {
                setDailyOrderNumber(result.data?.addTArbeitOfferRes.offer_number?.toString() || '');
                setModalStep(ModalStep.DELIVERY_DONE);
              },
              ({ error }) => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  title: '配信できませんでした',
                  message: (
                    <Text variant="body14">
                      以下の理由により募集を配信できませんでした。
                      <br />
                      <Box pt={16}>{error.message}</Box>
                    </Text>
                  ),
                  onClose: error.requiredCallback,
                });
              }
            );
          } else {
            setModalStep(ModalStep.NULL);
            openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
          }
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  /**
   * [API] 週定期配信（確定）
   */
  const onClickWeeklySave = () => {
    createWeeklyMutation()
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        } else {
          const errorCode = result.data?.addTRegularlyOfferRes.ErrorCode;
          if (errorCode != null) {
            handleMutationResult(
              errorCode,
              () => {
                setWeeklyOrderNumbers(result.data?.addTRegularlyOfferRes.offer || []);
                setModalStep(ModalStep.REGULAR_WEEKLY_DONE);
              },
              ({ error }) => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  title: '配信できませんでした',
                  message: (
                    <Text variant="body14">
                      以下の理由により募集を配信できませんでした。
                      <br />
                      <Box pt={16}>{error.message}</Box>
                    </Text>
                  ),
                  onClose: error.requiredCallback,
                });
              }
            );
          } else {
            setModalStep(ModalStep.NULL);
            openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
          }
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  const onClickEditSave = () => {
    updateTArbeitOfferByIdMutation()
      .then((result) => {
        if (!result.errors) {
          setModalStep(ModalStep.EDIT_DONE);
        } else {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: '配信に失敗しました。' });
      });
  };

  const clearEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();

    /**
     * basicInfoDataをリセット
     */
    setApiResponseData(undefined);
  };

  const doneEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();
    /**
     * Topへ戻る
     */
    navigate('/s04-01-01', { state: { refetch: true }, replace: true });
  };

  // [hook] 労働条件通知書PDFダウンロード
  const { downloadPdf, loading: downloadLoading } = useNoticeOfEmploymentPdfDownload();

  /**
   * [onClick] 労働条件通知書PDFダウンロード
   */
  const onClickDownload = () => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (downloadLoading) return;

        // PDFダウンロード処理
        const result = await downloadPdf(Number(id));
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        openErrorModal({ message: 'ダウンロード処理に失敗しました。' });
      }
    })();
  };

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = (shiftDate?: string) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFパラメータ取得
        const pdfProps = getPdfPreviewProps(fixedFormData, shiftDate);

        // PDFプレビュー
        const result = await previewPdf(Number(basicInfoData?.tpmem_id), pdfProps);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  const weeklyOrderNumberList = weeklyOrderNumbers?.map((item, index) => (
    <React.Fragment key={`${String(item?.id)}${Math.random()}`}>
      <Box py={8}>
        <Text color="black" variant="body14">
          掲載No. {item?.offer_number || ''}
        </Text>
        <StyledDate>
          {item?.work_day || ''}（{item?.post_days || ''}）
        </StyledDate>
      </Box>
      <Divider option="horizontal" />
    </React.Fragment>
  ));

  const displayModal = () => {
    switch (modalStep) {
      case ModalStep.EDIT_OPEN /* 1 */:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              編集
            </Text>
          ),
          content: <Text variant="body14">募集内容を編集しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                  /**
                   * バリテーション用のID(OfferNumber)をセット
                   */
                  setDraftOfferId(basicInfoData?.id || 0);
                  setModalStep(ModalStep.EDIT_NORMAL);
              }}
                  >
                  編集
            </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.EDIT_NORMAL /* 2 */:
        return {
          // feat screen 04-01-01-06 start
          width: !isMobile ?  800 : "100%",
          height: !isMobile ?  '80%' : "72.5%",
          // feat screen 04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              募集情報編集
            </Text>
          ),
          content: (
            <ModalCreateNew
              offerData={basicInfoData || null}
              status={latestStatus}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              mode="edit"
              offerId={draftOfferId}
            />
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickEditConfirm()}>
                確認
              </MainButton>
              {/* feat screen 04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.EDIT_PREVIEW /* 3 */:
        return {
          // feat screen 04-01-01-07 start
          width: !isMobile ?  800 : "100%",
          height: !isMobile ?  '80%' : "72.5%",
          // feat screen 04-01-01-07 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集情報編集 - プレビュー
              </Text>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY && (
                // feat screen 04-01-01-07 start
                !isMobile &&
								<MainButton onClick={() => onClickPreview()} variant="secondary" width={200}>
									労働条件通知書プレビュー
								</MainButton>
                // feat screen 04-01-01-07 end
              )}
            </Box>
          ),
          content: (
            <ModalNewPreview
              fixedFormData={fixedFormData}
              basicInfoData={basicInfoData}
              previewPdf={(shiftDate) => {
                onClickPreview(shiftDate);
              }}
              // feat screen 04-01-01-07 start
              onClickPreview={onClickPreview}
              // feat screen 04-01-01-07 end
            />
          ),
          footer: (
            // feat screen 04-01-01-12 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.EDIT_NORMAL)}>
                戻る
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  setModalStep(ModalStep.EDIT_SAVE);
                }}
              >
                作成
              </MainButton>
            </Box>
            // feat screen 04-01-01-12 end
          ),
        };
      case ModalStep.EDIT_SAVE /* 4 */:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 210 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              募集情報編集 保存
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">募集の編集を保存しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            // feat screen 04-01-01-12 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.EDIT_PREVIEW)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickEditSave()}>
                保存
              </MainButton>
            </Box>
            // feat screen 04-01-01-12 end
          ),
        };
      case ModalStep.EDIT_DONE /* 5 */:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              募集情報編集 完了
            </Text>
          ),
          content: <Text variant="body14">編集内容を保存しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.DUPLICATE_START /* 6 */:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              複製
            </Text>
          ),
          content: <Text variant="body14">募集を複製しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => {
                  /**
                   * バリテーション用のID(OfferNumber)をセット
                   */
                  setDraftOfferId(basicInfoData?.id || 0);
                  setModalStep(ModalStep.DUPLICATE_EDIT);
                }}
              >
                複製を編集
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.DUPLICATE_EDIT /* 7 */:
        return {
          // feat screen 04-01-01-08 start
          width: !isMobile ?  800 : "100%",
          height: !isMobile ?  '80%' : "72.5%",
          // feat screen 04-01-01-08 end
          header: (
            <Text variant="h2" color="darkBlue">
              募集情報編集
            </Text>
          ),
          content: (
            <ModalCreateNew
              offerData={basicInfoData || null}
              status={latestStatus}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              mode="duplicate"
              isDraftClicked={isDraftClicked}
              offerId={draftOfferId}
            />
          ),
          footer: (
            // feat screen 04-01-01-12 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => {
                  setIsDraftClicked(true);
                  onClickDraft();
                }}
              >
                下書き保存
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  setIsDraftClicked(false);
                  onClickDuplicateConfirm();
                }}
              >
                確認
              </MainButton>
            </Box>
            // feat screen 04-01-01-12 end
          ),
        };
      case ModalStep.DUPLICATE_PREVIEW /* 8 */:
        return {
          // feat screen 04-01-01-08 start
          width: !isMobile ?  800 : "100%",
          height: !isMobile ?  '80%' : "72.5%",
          // feat screen 04-01-01-08 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集情報編集 - プレビュー
              </Text>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY && (
                // feat screen 04-01-01-12 start
                !isMobile && (
                  <MainButton onClick={() => onClickPreview()} variant="secondary" width={200}>
                    労働条件通知書プレビュー
                  </MainButton>
                )
                // feat screen 04-01-01-12 end
              )}
            </Box>
          ),
          content: (
            // feat screen 04-01-01-12 start
            <ModalNewPreview fixedFormData={fixedFormData} previewPdf={(shiftDate) => onClickPreview(shiftDate)} onClickPreview={onClickPreview}/>
            // feat screen 04-01-01-12 end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-01-12 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.DUPLICATE_EDIT)}>
                戻る
              </MainButton>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY ? (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.DELIVERY);
                  }}
                >
                  作成
                </MainButton>
              ) : (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.REGULAR_WEEKLY);
                  }}
                >
                  作成
                </MainButton>
              )}
              {/* feat screen 04-01-01-12 end */}
            </Box>
          ),
        };
      case ModalStep.DUPLICATE_DONE /* 9 */:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              下書き保存
            </Text>
          ),
          content: <Text variant="body14">募集の下書きを保存しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.DELIVERY:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 210 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">作成した募集を配信しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.EDIT_PREVIEW)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickDailySave()}>
                配信
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.REGULAR_WEEKLY:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 231 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              週定期募集作成
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">週定期募集の作成を確定しますか？</Text>
              <br />
              <Text variant="body14">合計{state.offerNumber}件の募集の配信が予約されます。</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.EDIT_PREVIEW)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickWeeklySave()}>
                確定
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.DELIVERY_DONE:
        return {
          width: 384,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 210 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信 完了
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">募集の配信が完了しました。</Text>
              <br />
              <Text variant="body14">掲載No. {dailyOrderNumber}</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      case ModalStep.REGULAR_WEEKLY_DONE:
        return {
          width: 424,
          // feat screen_04-01-01-06 start
          height: !isMobile ? 396 : "unset" ,
          // feat screen_04-01-01-06 end
          header: (
            <Text variant="h2" color="darkBlue">
              週定期募集作成 完了
            </Text>
          ),
          content: (
            <>
              <Box>
                <Text variant="body14">週定期募集の作成が完了しました。</Text>
                <br />
                <Text variant="body14">以下の項目が掲載予約されました。</Text>
              </Box>
              <Box mt={4}>{weeklyOrderNumberList}</Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-06 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen_04-01-01-06 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  };

  const modalContent = displayModal();

  const generateAutoDecide = (auto_decide: AutoDecide) => {
    switch (auto_decide) {
      case AutoDecide.MANUAL:
        return '手動';
      case AutoDecide.AUTO:
        return '自動';
      case AutoDecide.COUNTEROFFER:
        return '逆オファー';
      default:
        return '-';
    }
  };

  const memorizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const editableStatuses: number[] = [
    RecruitStatus.BEFORE_RECRUITMENT,
    RecruitStatus.RECRUITING,
    RecruitStatus.RECRUITING_STOP,
  ];

  const duplicatableStatuses: number[] = [
    RecruitStatus.BEFORE_RECRUITMENT,
    RecruitStatus.RECRUITING,
    RecruitStatus.RECRUITING_STOP,
    RecruitStatus.ACCEPTED,
    RecruitStatus.FINISHED,
  ];

  return (
    <WeeklyOfferNumberContext.Provider value={memorizedValue}>
      <ScrollableArea>
        {/* feat screen 04-01-01-02 start */}
        {!isMobile ? (
          <Box px={16} pt={0} pb={24}>
            <StickyHeader height={60}>
              <div style={{ flexGrow: 2 }}>
                <Text color="darkBlue" variant="h2">
                  募集情報
                </Text>
              </div>
              {duplicatableStatuses.includes(latestStatus) && (
                <div>
                  <MainButton
                    icon="contentCopyNormal"
                    onClick={() => setModalStep(ModalStep.DUPLICATE_START)}
                    variant="secondary"
                  >
                    複製
                  </MainButton>
                </div>
              )}
              {editableStatuses.includes(latestStatus) && (
                <div>
                  <MainButton icon="edit" onClick={() => setModalStep(ModalStep.EDIT_OPEN)} variant="primary">
                    編集
                  </MainButton>
                </div>
              )}
            </StickyHeader>
            {basicInfoData && (
              <Info
                items={[
                  {
                    content: basicInfoData?.offer_number,
                    label: '掲載No.',
                  },
                  {
                    content: generateAutoDecide(basicInfoData?.auto_decide as AutoDecide),
                    label: '採用方式',
                  },
                  {
                    content: basicInfoData?.title,
                    label: '掲載タイトル',
                  },
                  {
                    content: basicInfoData?.name,
                    label: '募集店',
                  },
                  {
                    content: basicInfoData?.begin_end,
                    label: 'バイト期間',
                  },
                  {
                    content: `${String(basicInfoData?.post_begin)}`,
                    label: '募集開始',
                  },
                  {
                    content: `${String(basicInfoData?.post_end)}`,
                    label: '募集締切',
                  },
                  {
                    content: basicInfoData?.break_time !== 0 ? `${String(basicInfoData?.break_time)}分` : '無',
                    label: '休憩時間',
                  },
                  {
                    content: `¥${String(basicInfoData?.hour_wage?.toLocaleString())}`,
                    label: '時給',
                  },
                  {
                    content: basicInfoData?.bt_rank_id ? `${getRankLabel(basicInfoData.bt_rank_id) || ''}以上` : '',
                    label: '募集ランク',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.score ? `${String(basicInfoData?.score) || ''}以上` : '',
                    label: 'スコア',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.hire_fee_total ? `¥${String(basicInfoData?.hire_fee_total) || ''}以下` : '',
                    label: '採用手数料',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content:
                      typeof basicInfoData?.cancel_per === 'number' ? `${String(basicInfoData.cancel_per)}%以下` : '',
                    label: 'キャンセル率',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content:
                      typeof basicInfoData?.imminent_per === 'number' ? `${String(basicInfoData.imminent_per)}%以下` : '',
                    label: 'ドタキャン率',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.chief,
                    label: '当日の店舗責任者',
                  },
                  {
                    content:
                      basicInfoData?.trans_fee_val !== 0
                        ? `¥${String(basicInfoData?.trans_fee_val?.toLocaleString())}`
                        : '無',
                    label: '交通費',
                  },
                  {
                    content: (
                      <Text wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.description)}
                      </Text>
                    ),
                    label: '業務詳細',
                  },
                  {
                    content: (
                      <Text wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.conditions)}
                      </Text>
                    ),
                    label: '働くための条件',
                  },
                  {
                    content: (
                      <Text wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.caution)}
                      </Text>
                    ),
                    label: '注意事項',
                  },
                  {
                    content: (
                      <Text wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.belongings)}
                      </Text>
                    ),
                    label: '持ち物',
                  },
                  {
                    content: (
                      <Text wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.access)}
                      </Text>
                    ),
                    label: 'アクセス',
                  },
                  {
                    content: (
                      <div
                        role="button"
                        style={{ cursor: 'pointer', width: '125px' }}
                        tabIndex={0}
                        onClick={onClickDownload}
                        onKeyDown={() => {}}
                      >
                        <Text variant="body14" underline color="primaryBlue">
                          労働条件通知書.pdf
                        </Text>
                      </div>
                    ),
                    label: '労働条件通知書',
                  },
                  {
                    content: (
                      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                        {basicInfoData?.image1 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image2 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image3 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image4 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image5 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {!basicInfoData?.image1 &&
                          !basicInfoData?.image2 &&
                          !basicInfoData?.image3 &&
                          !basicInfoData?.image4 &&
                          !basicInfoData?.image5 && <Text variant="body14">-</Text>}
                      </div>
                    ),
                    label: 'メイン画像',
                  },
                  {
                    content: basicInfoData?.attached_file ? (
                      <TextLink href={basicInfoData?.attached_file} variant="body14">
                        {basicInfoData?.attached_file?.split('/').pop()}
                      </TextLink>
                    ) : (
                      <Text variant="body14">-</Text>
                    ),
                    label: '添付ファイル',
                  },
                ]}
              />
            )}
          </Box>
        ): (
          // Responsive Component
          <Box px={12} pt={0}>
            <StickyMobileHeader height={60}>
              {duplicatableStatuses.includes(latestStatus) && (
                <div>
                  <MainButton
                    width={85}
                    customHeight={40}
                    icon="contentCopyNormal"
                    onClick={() => setModalStep(ModalStep.DUPLICATE_START)}
                    variant="secondary"
                  >
                    複製
                  </MainButton>
                </div>
              )}
              {editableStatuses.includes(latestStatus) && (
                <div>
                  <MainButton customHeight={40} width={85} icon="edit" onClick={() => setModalStep(ModalStep.EDIT_OPEN)} variant="primary">
                    編集
                  </MainButton>
                </div>
              )}
            </StickyMobileHeader>
            <Box display='flex' justifyContent='center' alignItems='center' py={16} pb={12}>
              <Text display="block" align='center' color="darkBlue" variant="h2">
                募集情報
              </Text>
            </Box>
            <Divider option="horizontal"/>
            {basicInfoData && (
              <Info
                items={[
                  {
                    content: basicInfoData?.offer_number,
                    label: '掲載No.',
                  },
                  {
                    content: generateAutoDecide(basicInfoData?.auto_decide as AutoDecide),
                    label: '採用方式',
                  },
                  {
                    content: basicInfoData?.title,
                    label: '掲載タイトル',
                  },
                  {
                    content: basicInfoData?.name,
                    label: '募集店',
                  },
                  {
                    content: basicInfoData?.begin_end,
                    label: 'バイト期間',
                  },
                  {
                    content: `${String(basicInfoData?.post_begin)}`,
                    label: '募集開始',
                  },
                  {
                    content: `${String(basicInfoData?.post_end)}`,
                    label: '募集締切',
                  },
                  {
                    content: basicInfoData?.break_time !== 0 ? `${String(basicInfoData?.break_time)}分` : '無',
                    label: '休憩時間',
                  },
                  {
                    content: `¥${String(basicInfoData?.hour_wage?.toLocaleString())}`,
                    label: '時給',
                  },
                  {
                    content: basicInfoData?.bt_rank_id ? `${getRankLabel(basicInfoData.bt_rank_id) || ''}以上` : '',
                    label: '募集ランク',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.score ? `${String(basicInfoData?.score) || ''}以上` : '',
                    label: 'スコア',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.hire_fee_total ? `¥${String(basicInfoData?.hire_fee_total) || ''}以下` : '',
                    label: '採用手数料',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content:
                      typeof basicInfoData?.cancel_per === 'number' ? `${String(basicInfoData.cancel_per)}%以下` : '',
                    label: 'キャンセル率',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content:
                      typeof basicInfoData?.imminent_per === 'number' ? `${String(basicInfoData.imminent_per)}%以下` : '',
                    label: 'ドタキャン率',
                    isShow: basicInfoData?.auto_decide === AutoDecide.AUTO,
                  },
                  {
                    content: basicInfoData?.chief,
                    label: '当日の店舗責任者',
                  },
                  {
                    content:
                      basicInfoData?.trans_fee_val !== 0
                        ? `¥${String(basicInfoData?.trans_fee_val?.toLocaleString())}`
                        : '無',
                    label: '交通費',
                  },
                  {
                    content: (
                      <Text align="right" wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.description)}
                      </Text>
                    ),
                    label: '業務詳細',
                  },
                  {
                    content: (
                      <Text align="right" wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.conditions)}
                      </Text>
                    ),
                    label: '働くための条件',
                  },
                  {
                    content: (
                      <Text align="right" wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.caution)}
                      </Text>
                    ),
                    label: '注意事項',
                  },
                  {
                    content: (
                      <Text align="right" wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.belongings)}
                      </Text>
                    ),
                    label: '持ち物',
                  },
                  {
                    content: (
                      <Text align="right" wordBreak="break-all" variant="body14">
                        {charcterReplaceBr(basicInfoData?.access)}
                      </Text>
                    ),
                    label: 'アクセス',
                  },
                  {
                    content: (
                      <div
                        role="button"
                        // feat screen 04-01-02 start
                        style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'flex-end' }}
                        // feat screen 04-01-02 end
                        tabIndex={0}
                        onClick={onClickDownload}
                        onKeyDown={() => {}}
                      >
                        <Text variant="body14" underline color="primaryBlue">
                          労働条件通知書.pdf
                        </Text>
                      </div>
                    ),
                    label: '労働条件通知書',
                  },
                  {
                    content: (
                      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'flex-end' }}>
                        {basicInfoData?.image1 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image2 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image3 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image4 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {basicInfoData?.image5 && (
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(basicInfoData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={basicInfoData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                        )}
                        {!basicInfoData?.image1 &&
                          !basicInfoData?.image2 &&
                          !basicInfoData?.image3 &&
                          !basicInfoData?.image4 &&
                          !basicInfoData?.image5 && <Text variant="body14">-</Text>}
                      </div>
                    ),
                    label: 'メイン画像',
                  },
                  {
                    content: basicInfoData?.attached_file ? (
                      <TextLink href={basicInfoData?.attached_file} variant="body14">
                        {basicInfoData?.attached_file?.split('/').pop()}
                      </TextLink>
                    ) : (
                      <Text variant="body14">-</Text>
                    ),
                    label: '添付ファイル',
                  },
                ]}
              />
            )}
          </Box>
        )}
        {/* feat screen 04-01-01-02 end */}
        <Modal
          width={modalContent.width}
          height={modalContent.height}
          isOpen={modalStep !== 0}
          onClose={() => clearEvent()}
          header={modalContent.header}
          content={modalContent.content}
          footer={modalContent.footer}
        />
        <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
      </ScrollableArea>
    </WeeklyOfferNumberContext.Provider>
  );
}
