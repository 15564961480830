import React from 'react';
import { styled } from '@linaria/react';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Icon } from '../icon';
import { Text } from '../text';

export interface Props {
  name: string;
  options: {
    label: string | React.ReactNode;
    value: string;
  }[];
  value: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paddingLeft?: number;
  isList?: boolean;
  display?: string;
}

const StyledWrapper = styled.div<{ display: string }>`
  display: ${({ display }) => display};
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledListRaw = styled.div`
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    padding: 16px 0;
  }
  /* feat screen_01-01-02_end */
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label<{ disabled: boolean; isList: boolean }>`
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    column-gap: unset;
    align-items: ${({ isList }) => (isList ? 'flex-start' : 'center')};;
  }
  /* feat screen_01-01-02_end */
  display: flex;
  align-items: center;
  column-gap: ${({ isList }) => (isList ? '16px' : '4px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const StyledRadioWrapper = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    padding-right: 6px;
    margin-top: 2px;
  }
  /* feat screen_01-01-02_end */
`;

const StyledInvisibleInput = styled.input<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export function RadioGroup({
  name,
  options,
  value,
  disabled = false,
  onChange,
  paddingLeft = 0,
  isList = false,
  display = 'flex',
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const iconName = (checked: boolean) => {
    if (disabled) {
      return 'radioDisabled';
    }
    if (checked) {
      return 'radioOn';
    }
    return 'radioOff';
  };

  if (isList) {
    return (
      <StyledListWrapper>
        {options.map((option) => (
          <React.Fragment key={option.value}>
            <StyledListRaw>
              <StyledLabel disabled={disabled} isList={isList}>
                <StyledRadioWrapper>
                  <StyledInvisibleInput
                    type="radio"
                    name={name}
                    value={option.value}
                    disabled={disabled}
                    onChange={onChange}
                  />
                  <div style={{ paddingLeft }}>
                    <Icon name={iconName(value === option.value)} />
                  </div>
                </StyledRadioWrapper>
                {typeof option.label === 'string' ? <Text variant="caption12">{option.label}</Text> : option.label}
              </StyledLabel>
            </StyledListRaw>
            <Divider option="horizontal" />
          </React.Fragment>
        ))}
      </StyledListWrapper>
    );
  }

  return (
    <StyledWrapper display={display}>
      {options.map((option) => (
        <StyledLabel key={option.value} disabled={disabled} isList={isList}>
          <StyledRadioWrapper>
            <StyledInvisibleInput
              type="radio"
              name={name}
              value={option.value}
              disabled={disabled}
              onChange={onChange}
            />
            <div style={{ paddingLeft }}>
              <Icon name={iconName(value === option.value)} />
            </div>
          </StyledRadioWrapper>
          {/* feat screen 01-01-06 start */}
          {typeof option.label === 'string' ? <Text variant={isMobile ? "body14" : "caption12"}>{option.label}</Text> : option.label}
          {/* feat screen 01-01-06 end */}
        </StyledLabel>
      ))}
    </StyledWrapper>
  );
}
