import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { PRIMARY_COLOR, GENERIC_COLOR } from '../assets/css/style';
import { Text, ColorKeyType } from '../text/text';
import { Icon, Name } from '../icon/icon';

type VariantType = 'primary' | 'secondary' | 'warn' | 'warnSecondary' | 'clear';

export interface Props {
  children: ReactNode;
  variant?: VariantType;
  thin?: boolean;
  width?: number;
  icon?: Name;
  iconColor?: string;
  disabled?: boolean;
  // feat_common_responsive_adding_props_start
  customHeight?: number;
  fullWidth?: boolean;
  divider?: boolean;
  iconSize?: number;
  // feat_common_responsive_adding_props_end
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button<{
  // feat_common_responsive_adding_props_start
  fullWidth?: boolean;
  // feat_common_responsive_adding_props_end
  width: number;
  height: number;
  backgroundColor: string;
  hoverBackground: string;
  border: string;
  disabled?: boolean;
  variant: VariantType;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  /* feat_common_responsive_change_width_logic_start */
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  /* feat_common_responsive_change_width_logic_end */
  height: ${({ height }) => height}px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${({ variant }) => (variant === 'clear' ? '0' : '0px 8px')};
  gap: 8px;
  border-radius: 4px;
  border: ${({ border }) => border};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }
  // feat reponsive common hover button start
  @media only screen and (max-width: 768px) {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'default')};
    &:hover {
      background: ${({ backgroundColor }) => backgroundColor};
    }
  }
  // feat reponsive common hover button end
`;

/* feat_common_responsive_main_button_start */
const StyledDivider = styled.div`
  border-bottom: 1px solid ${PRIMARY_COLOR.PRIMARY_BLUE};
  border-radius: 0px;
`;
/* feat_common_responsive_main_button_end */

export function MainButton({
  children,
  variant = 'primary',
  width = 160,
  /* feat_common_responsive_props_start */
  customHeight,
  fullWidth,
  divider,
  iconSize,
  /* feat_common_responsive_props_end */
  thin = false,
  icon,
  iconColor,
  disabled,
  onClick,
}: Props) {
  const styleProps = React.useMemo<{
    backgroundColor: string;
    hoverBackground: string;
    border: string;
    textColor: ColorKeyType;
  }>(() => {
    switch (variant) {
      case 'primary':
        return {
          backgroundColor: PRIMARY_COLOR.PRIMARY_BLUE,
          hoverBackground: disabled
            ? PRIMARY_COLOR.PRIMARY_BLUE
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${PRIMARY_COLOR.PRIMARY_BLUE}`,
          border: 'none',
          textColor: 'white',
        };
      case 'secondary':
        return {
          backgroundColor: GENERIC_COLOR.WHITE,
          hoverBackground: disabled
            ? GENERIC_COLOR.WHITE
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${GENERIC_COLOR.WHITE}`,
          border: `1px solid ${PRIMARY_COLOR.PRIMARY_BLUE}`,
          textColor: 'primaryBlue',
        };
      case 'warn':
        return {
          backgroundColor: GENERIC_COLOR.CAUTION_RED,
          hoverBackground: disabled
            ? GENERIC_COLOR.CAUTION_RED
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${GENERIC_COLOR.CAUTION_RED}`,
          border: 'none',
          textColor: 'white',
        };
      case 'warnSecondary':
        return {
          backgroundColor: GENERIC_COLOR.WHITE,
          hoverBackground: disabled
            ? GENERIC_COLOR.WHITE
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${GENERIC_COLOR.WHITE}`,
          border: `1px solid ${GENERIC_COLOR.CAUTION_RED}`,
          textColor: 'cautionRed',
        };
      case 'clear':
        return {
          backgroundColor: 'transparent',
          hoverBackground: 'transparent',
          border: `none`,
          textColor: 'primaryBlue',
        };
      default:
        return {
          backgroundColor: PRIMARY_COLOR.PRIMARY_BLUE,
          hoverBackground: disabled
            ? PRIMARY_COLOR.PRIMARY_BLUE
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${PRIMARY_COLOR.PRIMARY_BLUE}`,
          border: 'none',
          textColor: 'white',
        };
    }
  }, [variant, disabled]);

  return (
    <StyledButton
      type="button"
      disabled={disabled}
      width={width}
      /* feat_common_responsive_props_start */
      fullWidth={fullWidth}
      height={customHeight || (thin ? 28 : 32)}
      /* feat_common_responsive_props_end */
      variant={variant}
      backgroundColor={styleProps.backgroundColor}
      hoverBackground={styleProps.hoverBackground}
      border={styleProps.border}
      onClick={onClick}
    >
      {/* feat_common_responsive_main_button_start */}
      {icon && <Icon name={icon} color={iconColor} size={iconSize}/>}
      {/* feat_common_responsive_main_button_end */}      
      {
        /* feat_common_responsive_main_button_start */
        divider === true && variant === 'clear' ?  (
          <StyledDivider>
            <Text variant="body14" color={styleProps.textColor} bold={variant !== 'clear'}>
              {children}
            </Text>
          </StyledDivider>
        /* feat_common_responsive_main_button_end */
        ):(
          <Text variant="body14" color={styleProps.textColor} bold={variant !== 'clear'}>
            {children}
          </Text>
        )
      }
    </StyledButton>
  );
}
