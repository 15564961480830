/**
 * Figma ID: 01-01-03
 * 名称: 店舗情報
 */
import React, { useEffect, useRef } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import * as gql from 'graphql/graphql-mw';
import { MainButton } from 'components/mainButton';
import { InputLabel } from 'components/inputLabel';
import { RhfSelect } from 'components/rhfSelect';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { RhfInput } from 'components/rhfInput';
import { DatePicker } from 'components/datePicker';
import { Hours, Minutes } from 'components/select/selectOptions';
import { useForm, Controller } from 'react-hook-form';
import { Checkbox } from 'components/newCheckbox';
import { BasicForm } from 'components/basicForm';
import { useErrorModal } from 'components/error/errorModalProvider';
import _ from 'lodash';
import getYear from 'date-fns/getYear';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  tpmemId: number;
  toNext: (tpmemInfo: {
    bizName: string;
    brandName: string;
    tpmemName: string;
    tpmemAddress: string;
    tpmemPhone: string;
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    dob: Date;
    phone: string;
    loginId: string;
    openTime: string;
    closeTime: string;
    parking: boolean;
    image: File;
    tpmemId: number;
    invitedInput: string;
  }) => void;
  toBack: () => void;
};
export function DetailTpmem({ tpmemId, toBack, toNext }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { data: { getVTMemberBaseById } = {}, error } = gql.useGetVtMemberBaseByIdQuery({
    context: { clientName: 'api_key' },
    variables: { id: tpmemId },
  });
  const [is24Hours, setIs24Hours] = React.useState<boolean>(false);
  const { openErrorModal } = useErrorModal();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    clearErrors,
  } = useForm<{
    image: FileList;
    startHour: string;
    startMinutes: string;
    endHour: string;
    endMinutes: string;
    parking: string;
    managerFamilyName: string;
    managerFirstName: string;
    managerFamilyNameKana: string;
    managerFirstNameKana: string;
    managerBirthday: Date;
    managerPhone: string;
    inviteCode: string;
  }>({
    reValidateMode: 'onChange',
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [imageData, setImageData] = React.useState<string | undefined>(undefined);

  const onImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length <= 0) return;
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageData(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  };

  const { ref, ...rest } = register('image', {
    onChange: onImageSelected,
    required: '店舗画像を設定してください',
  });

  const startHour = watch('startHour');
  const endHour = watch('endHour');

  useEffect(() => {
    if (parseInt(startHour, 10) > parseInt(endHour, 10)) {
      setError('startHour', { type: 'manual', message: '開始時間は終了時間より前に設定してください' });
    } else {
      clearErrors('startHour');
    }
  }, [startHour, endHour, setError, clearErrors]);

  useEffect(() => {
    if (is24Hours) {
      setValue('startHour', '0', { shouldValidate: true });
      setValue('startMinutes', '00', { shouldValidate: true });
      setValue('endHour', '0', { shouldValidate: true });
      setValue('endMinutes', '00', { shouldValidate: true });
    }
  }, [is24Hours, setValue]);

  const onSubmit = handleSubmit((data) => {
    if (!getVTMemberBaseById) {
      openErrorModal({ message: '店舗情報が取得できませんでした。' });
      return;
    }
    toNext({
      bizName: getVTMemberBaseById.biz_name ?? '',
      brandName: getVTMemberBaseById.brand_name ?? '',
      tpmemName: getVTMemberBaseById.store_name ?? '',
      tpmemAddress: `${getVTMemberBaseById.zip_code ?? ''} ${getVTMemberBaseById.state ?? ''}${
        getVTMemberBaseById.city ?? ''
      }${getVTMemberBaseById.address ?? ''}${getVTMemberBaseById.address2 ?? ''}`,
      tpmemPhone: getVTMemberBaseById.phone ?? '',
      firstName: data.managerFirstName,
      lastName: data.managerFamilyName,
      firstKana: data.managerFirstNameKana,
      lastKana: data.managerFamilyNameKana,
      dob: data.managerBirthday,
      phone: data.managerPhone,
      loginId: getVTMemberBaseById.code ?? '',
      openTime: `${data.startHour}:${data.startMinutes}`,
      closeTime: `${data.endHour}:${data.endMinutes}`,
      parking: data.parking === 'yes',
      image: data.image[0],
      tpmemId: getVTMemberBaseById.id ?? Number(tpmemId),
      invitedInput: data.inviteCode,
    });
  });

  useEffect(() => {
    if (error) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        onClose: () => {
          toBack();
        },
      });
    }
  }, [error, openErrorModal, toBack]);

  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = getVTMemberBaseById?.biz_name ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = getVTMemberBaseById?.brand_name ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  return (
    /* feat_screen_01-01-03_start */
    <div>
      {!isMobile ? (
        <Box width={752} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 店舗情報" />
          <Text variant="body14">店舗情報を修正、入力して次へを押してください。項目は全て入力してください。</Text>
          <Box>
            <Box display="flex">
              <BasicForm label="業種" value={editBizName} readonly onChange={() => {}} />
              <BasicForm label="ブランド" value={editBrandName} readonly onChange={() => {}} />
            </Box>
            <BasicForm label="店舗名" value={getVTMemberBaseById?.store_name ?? ''} readonly onChange={() => {}} />
            <Box display="flex" flexDirection="column" gap={4} mb={18}>
              <InputLabel>店舗画像</InputLabel>
              <MainButton
                variant="secondary"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                画像を選択
              </MainButton>
              <input
                type="file"
                accept="image/*"
                hidden
                ref={(e) => {
                  ref(e);
                  fileInputRef.current = e;
                }}
                {...rest}
              />
              <Box pt={5}>
                {imageData && <img src={imageData} alt="tpmemImage" height={70} style={{ objectFit: 'cover' }} />}
              </Box>
              {errors.image?.message && (
                <Text variant="caption12" color="cautionRed">
                  {errors.image?.message}
                </Text>
              )}
            </Box>
            <BasicForm label="郵便番号" value={getVTMemberBaseById?.zip_code ?? ''} readonly onChange={() => {}} />
            <BasicForm
              label="住所"
              value={`${getVTMemberBaseById?.state ?? ''}
                  ${getVTMemberBaseById?.city ?? ''}
                  ${getVTMemberBaseById?.address ?? ''}
                  ${getVTMemberBaseById?.address2 ?? ''}`}
              readonly
              onChange={() => {}}
            />
            <BasicForm label="電話番号" value={getVTMemberBaseById?.phone ?? ''} readonly onChange={() => {}} />
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <Box>
                <InputLabel>営業時間</InputLabel>
              </Box>
              <Box display="flex" alignItems="center" gap={8}>
                <RhfSelect
                  defaultValue=""
                  width={80}
                  options={Hours}
                  control={control}
                  name="startHour"
                  disabled={is24Hours}
                  rules={{
                    required: '営業時間を入力してください。',
                  }}
                />
                <Text variant="body14">:</Text>
                <RhfSelect
                  defaultValue=""
                  width={80}
                  options={Minutes}
                  control={control}
                  name="startMinutes"
                  disabled={is24Hours}
                  rules={{
                    required: '営業時間を入力してください。',
                  }}
                />
                <Text variant="body14">〜</Text>
                <RhfSelect
                  defaultValue=""
                  width={80}
                  options={Hours}
                  control={control}
                  name="endHour"
                  disabled={is24Hours}
                  rules={{
                    required: '営業時間を入力してください。',
                  }}
                />
                <Text variant="body14">:</Text>
                <RhfSelect
                  defaultValue=""
                  width={80}
                  options={Minutes}
                  control={control}
                  name="endMinutes"
                  rules={{ required: '営業時間を入力してください。' }}
                  disabled={is24Hours}
                />
                <Checkbox checked={is24Hours} onChange={() => setIs24Hours(!is24Hours)}>
                  <Text variant="body14" color="blueGray">
                    24時間営業
                  </Text>
                </Checkbox>
              </Box>
              {(errors.startHour?.message ||
                errors.startMinutes?.message ||
                errors.endHour?.message ||
                errors.endMinutes?.message) && (
                <Text variant="caption12" color="cautionRed">
                  {errors.startHour?.message ||
                    errors.startMinutes?.message ||
                    errors.endHour?.message ||
                    errors.endMinutes?.message}
                </Text>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>従業員駐車場</InputLabel>
              <RhfRadioGroup
                rules={{ required: '選択してください。' }}
                name="parking"
                control={control}
                defaultValue=""
                options={[
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        有
                      </Text>
                    ),
                    value: 'yes',
                  },
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        無
                      </Text>
                    ),
                    value: 'no',
                  },
                ]}
              />
              {errors.parking?.message && (
                <Text variant="caption12" color="cautionRed">
                  {errors.parking.message}
                </Text>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>責任者氏名</InputLabel>
              <Box display="flex" gap={10}>
                <RhfInput
                  defaultValue=""
                  rules={{ required: '責任者氏名を入力してください。' }}
                  width={120}
                  control={control}
                  name="managerFamilyName"
                  placeholder="姓"
                />
                <RhfInput
                  defaultValue=""
                  rules={{ required: '責任者氏名を入力してください。' }}
                  width={120}
                  control={control}
                  name="managerFirstName"
                  placeholder="名"
                />
              </Box>
              {(errors.managerFamilyName || errors.managerFirstName) && (
                <Text variant="caption12" color="cautionRed">
                  {errors.managerFamilyName?.message || errors.managerFirstName?.message}
                </Text>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>責任者氏名（フリガナ）</InputLabel>
              <Box display="flex" gap={10}>
                <RhfInput
                  defaultValue=""
                  rules={{
                    required: '責任者氏名（フリガナ）を入力してください。',
                    pattern: {
                      value: /^[ァ-ンヴー]*$/i,
                      message: 'カナ文字を入力してください',
                    },
                  }}
                  width={120}
                  control={control}
                  name="managerFamilyNameKana"
                  placeholder="セイ"
                />
                <RhfInput
                  defaultValue=""
                  rules={{
                    required: '責任者氏名（フリガナ）を入力してください。',
                    pattern: {
                      value: /^[ァ-ンヴー]*$/i,
                      message: 'カナ文字を入力してください',
                    },
                  }}
                  width={120}
                  control={control}
                  name="managerFirstNameKana"
                  placeholder="メイ"
                />
              </Box>
              {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
                <Text variant="caption12" color="cautionRed">
                  {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
                </Text>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>責任者生年月日</InputLabel>
              <Controller
                name="managerBirthday"
                control={control}
                rules={{ required: '生年月日を入力してください' }}
                render={({ field }) => (
                  <DatePicker
                    onChange={(newValue) => {
                      if (!newValue) {
                        return;
                      }
                      field.onChange(newValue);
                    }}
                    selected={field.value}
                    yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                    placeholderText="生年月日を入力"
                  />
                )}
              />
              {errors.managerBirthday && (
                <Box>
                  <Text variant="caption12" color="cautionRed">
                    {errors.managerBirthday.message}
                  </Text>
                </Box>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel optional>責任者電話番号</InputLabel>
              <RhfInput
                defaultValue=""
                width={328}
                control={control}
                name="managerPhone"
                rules={{
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                }}
                placeholder="電話番号を入力"
              />
              {errors.managerPhone && (
                <Box>
                  <Text variant="caption12" color="cautionRed">
                    {errors.managerPhone.message}
                  </Text>
                </Box>
              )}
            </Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel optional>友達招待コード</InputLabel>
              <RhfInput
                defaultValue=""
                width={328}
                control={control}
                name="inviteCode"
                placeholder="友達招待コードを入力"
                rules={{
                  pattern: {
                    value: /^[a-z0-9]+$/,
                    message: '英字小文字・数字を使用してください。',
                  },
                  minLength: { value: 8, message: `友達招待コードは８桁で入力してください。` },
                  maxLength: { value: 8, message: `友達招待コードは８桁で入力してください。` },
                }}
              />
              {errors.inviteCode && (
                <Box>
                  <Text variant="caption12" color="cautionRed">
                    {errors.inviteCode.message}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" gap={5}>
            <MainButton variant="secondary" onClick={toBack}>
              戻る
            </MainButton>
            <Box display="grid" justifyContent="center">
              <MainButton
                onClick={() => {
                  onSubmit();
                }}
              >
                次へ
              </MainButton>
            </Box>
          </Box>
        </Box>
      ) : (
        // Mobile Responsive components
        <div>
          <Box width="100vw" display="flex" flexDirection="column" px={16}>
            <ContentTitle title="新規登録 - 店舗情報" />
            <Box mt={16}>
              <Text variant="body14">店舗情報を修正、入力して次へを押してください。項目は全て入力してください。</Text>
            </Box>
            <Box mt={20}>
              <Box display="flex" flexDirection="column" mb={-2}>
                <BasicForm label="業種" value={editBizName} readonly onChange={() => {}} />
                <BasicForm
                  label="ブランド"
                  value={editBrandName}
                  readonly
                  onChange={() => {}}
                />
              </Box>
              <BasicForm label="店舗名" value={getVTMemberBaseById?.store_name ?? ''} readonly onChange={() => {}} />
              <Box display="flex" flexDirection="column" mb={11}>
                <InputLabel>店舗画像</InputLabel>
                <MainButton
                  customHeight={40}
                  width={110}
                  variant="secondary"
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  画像を選択
                </MainButton>
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  ref={(e) => {
                    ref(e);
                    fileInputRef.current = e;
                  }}
                  {...rest}
                />
                <Box pt={5}>
                  {imageData && <img src={imageData} alt="tpmemImage" height={120} width={160} style={{ objectFit: 'cover' }} />}
                </Box>
                {errors.image?.message && (
                  <Text variant="caption12" color="cautionRed">
                    {errors.image?.message}
                  </Text>
                )}
              </Box>
              <BasicForm label="郵便番号" value={getVTMemberBaseById?.zip_code ?? ''} readonly onChange={() => {}} />
              <BasicForm
                label="住所"
                value={`${getVTMemberBaseById?.state ?? ''}
                  ${getVTMemberBaseById?.city ?? ''}
                  ${getVTMemberBaseById?.address ?? ''}
                  ${getVTMemberBaseById?.address2 ?? ''}`}
                readonly
                onChange={() => {}}
              />
              <BasicForm label="電話番号" value={getVTMemberBaseById?.phone ?? ''} readonly onChange={() => {}} />
              <Box display="flex" flexDirection="column" gap={4}  pb={14}>
                <Box>
                  <InputLabel>営業時間</InputLabel>
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box display="flex">
                    <div style={{width: "100%"}}>
                      <RhfSelect
                        defaultValue=""
                        fullWidth
                        height={40}
                        options={Hours}
                        control={control}
                        name="startHour"
                        disabled={is24Hours}
                        rules={{
                          required: '営業時間を入力してください。',
                        }}
                      />
                    </div>
                    <Box px={4} display="flex" alignItems="center">
                      <Text variant="body14">:</Text> 
                    </Box>
                    <div style={{width: "100%"}}>
                      <RhfSelect
                        defaultValue=""
                        fullWidth
                        height={40}
                        options={Minutes}
                        control={control}
                        name="startMinutes"
                        disabled={is24Hours}
                        rules={{
                          required: '営業時間を入力してください。',
                        }}
                      />
                    </div>
                  </Box>
                  <Box height={22} display="flex" alignItems="center" justifyContent="flex-start" py={4}>
                    <Text variant="caption12">〜</Text>
                  </Box>
                </Box>
                <Box display="flex" width="100%" mb={4}>
                  <RhfSelect
                    defaultValue=""
                    fullWidth
                    height={40}
                    options={Hours}
                    control={control}
                    name="endHour"
                    disabled={is24Hours}
                    rules={{
                      required: '営業時間を入力してください。',
                    }}
                  />
                  <Box px={4} display="flex" alignItems="center">
                    <Text variant="body14">:</Text> 
                  </Box>
                  <RhfSelect
                    defaultValue=""
                    fullWidth
                    height={40}
                    options={Minutes}
                    control={control}
                    name="endMinutes"
                    rules={{ required: '営業時間を入力してください。' }}
                    disabled={is24Hours}
                  />
                </Box>
                <Box>
                  <Checkbox checked={is24Hours} onChange={() => setIs24Hours(!is24Hours)}>
                    <Text variant="body14" color="blueGray">
                      24時間営業
                    </Text>
                  </Checkbox>
                </Box>
                {(errors.startHour?.message ||
                  errors.startMinutes?.message ||
                  errors.endHour?.message ||
                  errors.endMinutes?.message) && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.startHour?.message ||
                        errors.startMinutes?.message ||
                        errors.endHour?.message ||
                        errors.endMinutes?.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={16}>
                <InputLabel>従業員駐車場</InputLabel>
                <RhfRadioGroup
                  rules={{ required: '選択してください。' }}
                  name="parking"
                  control={control}
                  defaultValue=""
                  options={[
                    {
                      label: (
                        <Text color="blueGray" variant="body14">
                          有
                        </Text>
                      ),
                      value: 'yes',
                    },
                    {
                      label: (
                        <Text color="blueGray" variant="body14">
                          無
                        </Text>
                      ),
                      value: 'no',
                    },
                  ]}
                />
                {errors.parking?.message && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.parking.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={16}>
                <InputLabel>責任者氏名</InputLabel>
                <Box display="flex" justifyContent="space-between" gap={10}>
                  <div style={{width: "100%"}}>
                    <RhfInput
                      defaultValue=""
                      rules={{ required: '責任者氏名を入力してください。' }}
                      fullWidth
                      control={control}
                      name="managerFamilyName"
                      placeholder="姓"
                    />
                  </div>
                  <div style={{width: "100%"}}>
                    <RhfInput
                      defaultValue=""
                      rules={{ required: '責任者氏名を入力してください。' }}
                      fullWidth
                      control={control}
                      name="managerFirstName"
                      placeholder="名"
                    />
                  </div>
                </Box>
                {(errors.managerFamilyName || errors.managerFirstName) && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerFamilyName?.message || errors.managerFirstName?.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={16}>
                <InputLabel>責任者氏名（フリガナ）</InputLabel>
                <Box display="flex" justifyContent="space-between" gap={10}>
                  <div style={{width: "100%"}}>
                    <RhfInput
                      defaultValue=""
                      rules={{
                        required: '責任者氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        },
                      }}
                      fullWidth
                      control={control}
                      name="managerFamilyNameKana"
                      placeholder="セイ"
                    />
                  </div>
                  <div style={{width: "100%"}}>
                    <RhfInput
                      defaultValue=""
                      rules={{
                        required: '責任者氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        },
                      }}
                      fullWidth
                      control={control}
                      name="managerFirstNameKana"
                      placeholder="メイ"
                    />
                  </div>
                </Box>
                {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={16}>
                <InputLabel>責任者生年月日</InputLabel>
                <Controller
                  name="managerBirthday"
                  control={control}
                  rules={{ required: '生年月日を入力してください' }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={(newValue) => {
                        if (!newValue) {
                          return;
                        }
                        field.onChange(newValue);
                      }}
                      selected={field.value}
                      yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                      placeholderText="生年月日を入力"
                      popperPlacement="top"
                    />
                  )}
                />
                {errors.managerBirthday && (
                  <Box mt={-4}>
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerBirthday.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={14}>
                <InputLabel fullWidth optional>
                  責任者電話番号
                </InputLabel>
                <RhfInput
                  defaultValue=""
                  fullWidth
                  control={control}
                  name="managerPhone"
                  rules={{
                    pattern: {
                      value: /^\d*$/,
                      message: '数字を使用してください。',
                    },
                  }}
                  placeholder="電話番号を入力"
                />
                {errors.managerPhone && (
                  <Box mt={-4}>
                    <Text variant="body14" color="cautionRed">
                      {errors.managerPhone.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={4}>
                <InputLabel fullWidth optional>
                  友達招待コード
                </InputLabel>
                <RhfInput
                  defaultValue=""
                  fullWidth
                  control={control}
                  name="inviteCode"
                  placeholder="友達招待コードを入力"
                  rules={{
                    pattern: {
                      value: /^[a-z0-9]+$/,
                      message: '英字小文字・数字を使用してください。',
                    },
                    minLength: { value: 8, message: `友達招待コードは８桁で入力してください。` },
                    maxLength: { value: 8, message: `友達招待コードは８桁で入力してください。` },
                  }}
                />
                {errors.inviteCode && (
                  <Box mt={-4}>
                    <Text variant="body14" color="cautionRed">
                      {errors.inviteCode.message}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" py={24} gap={5}>
              <MainButton customHeight={40} fullWidth variant="secondary" onClick={toBack}>
                戻る
              </MainButton>
              <MainButton
                fullWidth
                customHeight={40}
                onClick={() => {
                  onSubmit();
                }}
              >
                次へ
              </MainButton>
            </Box>
          </Box>
        </div>
      )}
    </div>
    /* feat_screen_01-01-03_end */
  );
}
