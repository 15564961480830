/**
 * Figma ID: 08-06-01
 * 名称: マニュアル
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { Modal } from 'components/newModal';
import {
  useGetSTpmemRecruitByTpmemIdQuery,
  useUpdateSTpmemRecruitMnClByTIdMutation,
  useUpdateSTpmemRecruitUpMnByTIdMutation,
} from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { downloadFromUrl } from 'components/pdf';
import { uploadFile } from 'components/api';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ResultRows } from 'components/const';

type DeleteManualPhase = 'confirm' | 'complete';
export function Manual() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { tpmemId } = useAuth();
  const {
    data,
    loading,
    error: sTpmemRecruitByTpmemIdError,
    refetch,
  } = useGetSTpmemRecruitByTpmemIdQuery({ variables: { tpmem_id: Number(tpmemId) } });
  const [updateManual] = useUpdateSTpmemRecruitUpMnByTIdMutation();
  const [deleteManual] = useUpdateSTpmemRecruitMnClByTIdMutation();
  const [deletePhase, setDeletePhase] = React.useState<DeleteManualPhase>();
  const [isOpenCompleteModal, setIsOpenCompleteModal] = React.useState(false);
  const [inputFile, setInputFile] = React.useState<File | null>();
  const { openErrorModal } = useErrorModal();
  const deleteValue = 'delete';
  const downloadValue = 'download';
  const inputManualId = 'inputManualId';
  const manualName = `${String(tpmemId)}-就業マニュアル.pdf`;
  const today = new Date();
  const date = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const uploadUrl = `store-data/${manualName}`;
  const s3Url = `${process.env.REACT_APP_FILE_READ_URL ?? ''}/${uploadUrl}` || '';
  // 入力ファイルクリア
  const inputClear = () => {
    const inputElement = document.getElementById(inputManualId) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = '';
    }
  };

  React.useEffect(() => {
    if (sTpmemRecruitByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [sTpmemRecruitByTpmemIdError, openErrorModal]);

  const deleteModalHeader = () => {
    switch (deletePhase) {
      case 'complete':
        return '削除 完了';
      default:
        return '削除';
    }
  };
  const deleteModalContent = () => {
    switch (deletePhase) {
      case 'complete':
        return 'ファイルの削除が完了しました。';
      default:
        return 'ファイルを削除しますか？';
    }
  };
  const deleteModalFooter = () => {
    switch (deletePhase) {
      case 'complete':
        return (
          <Box display="flex" justifyContent="flex-end">
            {/* feat screen 08-06-07 start */}
            <MainButton
              width={!isMobile ? 104 : 96}
              variant="secondary"
              onClick={() => {
                setDeletePhase(undefined);
                refetch();
              }}
            >
              閉じる
            </MainButton>
            {/* feat screen 08-06-07 end */}
          </Box>
        );
      default:
        return (
          <Box display="flex" justifyContent="flex-end" gap={8}>
            {/* feat screen 08-06-06 start */}
            <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setDeletePhase(undefined)}>
              キャンセル
            </MainButton>
            <MainButton
              width={!isMobile ? 104 : 96}
              onClick={() => {
                setDeletePhase('complete');
                deleteManual({ variables: { tpmem_id: Number(tpmemId) } })
                  .then((result) => {
                    if (result.data?.updateSTpmemRecruitMnClByTId.resultRows === ResultRows.UPDATED) {
                      setInputFile(null);
                    } else {
                      setDeletePhase(undefined);
                      openErrorModal({ message: 'ファイルの削除に失敗しました。時間をおいて再度お試しください。' });
                    }
                  })
                  .catch(() => {
                    setDeletePhase(undefined);
                    openErrorModal({ message: 'ファイルの削除に失敗しました。時間をおいて再度お試しください。' });
                  });
              }}
            >
              削除
            </MainButton>
            {/* feat screen 08-06-06 end */}
          </Box>
        );
    }
  };
  React.useEffect(() => {
    if (!inputFile) return;
    uploadFile({ filePath: uploadUrl, file: inputFile })
      .then(async () => {
        await updateManual({
          variables: { tpmem_id: Number(tpmemId), manual: s3Url, manual_name: manualName, manual_date: date },
        }).then((result) => {
          if (result.data?.updateSTpmemRecruitUpMnByTId.resultRows === ResultRows.UPDATED) {
            refetch();
            setIsOpenCompleteModal(true);
            setInputFile(null);
            inputClear();
          } else {
            setIsOpenCompleteModal(false);
            setInputFile(null);
            inputClear();
            openErrorModal({ message: 'ファイルのアップロードに失敗しました。時間をおいて再度お試しください。' });
          }
        });
      })
      .catch(() => {
        setIsOpenCompleteModal(false);
        setInputFile(null);
        inputClear();
        openErrorModal({ message: 'ファイルのアップロードに失敗しました。時間をおいて再度お試しください。' });
      });
  }, [date, inputFile, manualName, openErrorModal, refetch, s3Url, tpmemId, updateManual, uploadUrl]);

  // feat screen 08-06-01 start
  return !isMobile ? (
    <Box px={24} width="100%">
      {loading && <Loading />}
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={8} py={18}>
        <Box display="flex" alignItems="center" gap={5}>
          <Text variant="h2" color="darkBlue">
            マニュアル
          </Text>
          <Text variant="caption12" color="darkBlue">
            就業マニュアルのアップロード・ダウンロードができます。
          </Text>
        </Box>
        <MainButton
          icon="upload"
          thin
          onClick={() => {
            // ファイルアップロードのためのダイアログを表示する。
            document.getElementById(inputManualId)?.click();
          }}
        >
          アップロード
        </MainButton>
      </Box>
      <Box>
        <Divider option="horizontal" />
      </Box>
      {data?.getSTpmemRecruitByTpmemId.manual_name ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box pt={16}>
              <Text variant="body14">{data?.getSTpmemRecruitByTpmemId.manual_name}</Text>
            </Box>
            <Box pt={5}>
              <Text variant="caption11" color="darkGray">
                {data?.getSTpmemRecruitByTpmemId.manual_date}
              </Text>
            </Box>
          </Box>
          <ThreeDotLeader
            right={0}
            menu={[
              { label: 'ダウンロード', value: downloadValue },
              { label: '削除', value: deleteValue },
            ]}
            onClick={(val) => {
              if (val === deleteValue) {
                setDeletePhase('confirm');
              } else if (val === downloadValue) {
                downloadFromUrl(manualName, data?.getSTpmemRecruitByTpmemId.manual ?? '');
              }
            }}
          />
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pt={16}>
            <Text variant="body14">未設定</Text>
          </Box>
        </Box>
      )}
      <input
        type="file"
        id={inputManualId}
        hidden
        onChange={(e) => {
          setInputFile(e.target.files?.[0]);
        }}
      />
      <Modal
        isOpen={deletePhase !== undefined}
        header={
          <Text variant="h2" color="darkBlue">
            {deleteModalHeader()}
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Text variant="body14">{deleteModalContent()}</Text>
          </Box>
        }
        footer={deleteModalFooter()}
        onClose={() => setDeletePhase(undefined)}
        height={200}
      />
      <Modal
        isOpen={isOpenCompleteModal}
        header={
          <Text variant="h2" color="darkBlue">
            ファイルアップロード完了
          </Text>
        }
        content={
          <Box display="grid" height="100%" alignItems="center">
            <Text variant="body14">ファイルアップロードが完了しました。</Text>
          </Box>
        }
        footer={
          <Box display="flex" justifyContent="flex-end" gap={5}>
            <MainButton width={104} variant="secondary" onClick={() => setIsOpenCompleteModal(false)}>
              閉じる
            </MainButton>
          </Box>
        }
        onClose={() => setIsOpenCompleteModal(false)}
        height={200}
      />
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%" px={16} height="calc(100% - 49px)" overflow="auto">
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" pt={17}>
          <Text variant="h2" color="darkBlue">
            マニュアル
          </Text>
          <Text display="block" align="center" variant="body14" color="darkBlue">
            就業マニュアルのアップロード・ダウンロードができます。
          </Text>
        </Box>
        <Box pt={14} pb={16}>
          <MainButton
            width={150}
            icon="upload"
            onClick={() => {
              // ファイルアップロードのためのダイアログを表示する。
              document.getElementById(inputManualId)?.click();
            }}
          >
            アップロード
          </MainButton>
        </Box>
      </Box>
      <Divider option="horizontal" />
      {data?.getSTpmemRecruitByTpmemId.manual_name ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" py={16}>
          <Box>
            <Box>
              <Text display="block" variant="body14">{data?.getSTpmemRecruitByTpmemId.manual_name}</Text>
            </Box>
            <Box pt={3}>
              <Text display="block" variant="caption12" color="darkGray">
                {data?.getSTpmemRecruitByTpmemId.manual_date}
              </Text>
            </Box>
          </Box>
          <ThreeDotLeader
            right={0}
            menu={[
              { label: 'ダウンロード', value: downloadValue },
              { label: '削除', value: deleteValue },
            ]}
            onClick={(val) => {
              if (val === deleteValue) {
                setDeletePhase('confirm');
              } else if (val === downloadValue) {
                downloadFromUrl(manualName, data?.getSTpmemRecruitByTpmemId.manual ?? '');
              }
            }}
          />
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box py={16}>
            <Text variant="body14">未設定</Text>
          </Box>
        </Box>
      )}
      <input type="file" id={inputManualId} hidden onChange={(e) => setInputFile(e.target.files?.[0])} />
      <Divider option="horizontal" />
      <Modal
        isOpen={deletePhase !== undefined}
        header={
          <Text variant="h2" color="darkBlue">
            {deleteModalHeader()}
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Text variant="body14">{deleteModalContent()}</Text>
          </Box>
        }
        footer={deleteModalFooter()}
        onClose={() => setDeletePhase(undefined)}
        height="unset"
      />
      <Modal
        isOpen={isOpenCompleteModal}
        header={
          <Text variant="h2" color="darkBlue">
            ファイルアップロード完了
          </Text>
        }
        content={
          <Box display="grid" height="100%" alignItems="center">
            <Text variant="body14">ファイルアップロードが完了しました。</Text>
          </Box>
        }
        footer={
          <Box display="flex" justifyContent="flex-end" gap={5}>
            <MainButton width={96} variant="secondary" onClick={() => setIsOpenCompleteModal(false)}>
              閉じる
            </MainButton>
          </Box>
        }
        onClose={() => setIsOpenCompleteModal(false)}
        height="unset"
      />
    </Box>
  // feat screen 08-06-01 end
  );
}
