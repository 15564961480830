/* feat_common_responsive_multi_menu_header_start */
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from '../text';
import { Icon } from '../icon';

export interface Props {
    items: {
        label: string;
    }[];
    mainHeader?: string;
}

const StyleHeader = styled.div`
    height: 50px;
    width: 100%;
    background: #F8FEFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: default;
`;

const StyledBackLink = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 6px;
  cursor: default;
`;

export function MultiMenuHeader({ items, mainHeader = ""}: Props) {
    const location = useLocation();
    const navigate = useNavigate();
    const isShowBackLink = useMemo<boolean>(() => {
        if(location?.pathname.includes('/s08') || location?.pathname.includes('/s07-02')) return false;
        const routingArray = location?.pathname.split('/').filter((route) => route.length > 0);
        return routingArray.length > 1;
    }, [location?.pathname]);

    return (
        <StyleHeader>
                <Box py={13} px={16}>
                    {isShowBackLink ? (
                            <StyledBackLink onClick={() => navigate(-1)}
                            >
                                <Icon name="chevronRwd" color="#16495F"/>
                                <Text variant="h2" color="darkBlue">戻る</Text>
                            </StyledBackLink>
                        ) : (
                            null
                    )}
                    {
                        !isShowBackLink ? (
                            <Box display="flex">
                                {items.map((item, index) => (
                                    <Box display='flex' alignItems='center' gap={4} key={item.label}>
                                        <Text display="block" variant="body16" color="darkBlue" bold>
                                            {item.label} 
                                        </Text>
                                        {items.length - 1 !== index && <Icon name="chevronFwd" color="#16495F"/>}
                                    </Box>
                                ))}
                            </Box> 
                        ) : (
                            null
                        )
                    }
                </Box>
        </StyleHeader>
    );
}
/* feat_common_responsive_multi_menu_header_end */