/**
 * Figma ID: 08-04-01
 * 名称: 労働者名簿
 */

import React from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { DatePicker } from 'components/datePicker';
import { MainButton } from 'components/mainButton';
import { useAuth } from 'hooks/authProvider';
import * as gql from 'graphql/graphql-mw';
import { dateFormat } from 'pages/s08/component/util';
import { downloadCsvFile } from 'components/csv';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';

// feat screen 08-04-02 start
const StyledFlexWrap = styled.div`
  @media only screen and (max-width: 350px) {
    > div {
      flex-wrap: wrap;
    }
  }
`;
// feat screen 08-04-02 end

export function WorkerList() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;
  const myId = Number(tpmemId);

  const [startDate, setStartDate] = React.useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = React.useState<Date | null | undefined>(null);
  const { openErrorModal } = useErrorModal();
  const { data: workerData, error: workerError } = gql.useGetVtWorkerDataRecordQuery({
    variables: { id: myId, from_date: dateFormat(startDate), to_date: dateFormat(endDate) },
  });
  React.useEffect(() => {
    if (workerError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [workerError, openErrorModal]);
  const today = new Date();
  const fileName = `労働者名簿_${String(today.getFullYear())}${String(today.getMonth() + 1)}${String(
    today.getDate()
  )}.csv`;
  const isDownloadActive = startDate && endDate && startDate <= endDate;
  // feat screen 08-04-02 start
  return !isMobile ? (
    <Box width="100%">
      <Box display="flex" alignItems="center" gap={8} py={18} pl={24}>
        <Text variant="h2" color="darkBlue">
          労働者名簿閲覧 - ダウンロード
        </Text>
        <Text variant="caption12" color="darkBlue">
          出力したい期間を設定し、CSVダウンロードボタンを押してください。
        </Text>
      </Box>
      <Box px={24}>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" alignItems="center" gap={8} pt={18} pl={24}>
        <DatePicker
          width={118}
          placeholderText="日付を選択"
          selected={startDate}
          onChange={(val) => setStartDate(val)}
        />
        <Box px={16}>
          <Text color="liteGray">~</Text>
        </Box>
        <DatePicker width={118} placeholderText="日付を選択" selected={endDate} onChange={(val) => setEndDate(val)} />
        <Box px={16}>
          <MainButton
            width={80}
            variant="clear"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            クリア
          </MainButton>
        </Box>
      </Box>
      <Box px={24} pb={18}>
        {workerData?.getVTWorkerDataRecord?.length === 0 && (
          <Text color="cautionRed" variant="caption12">
            指定の日付では、データがありません。
          </Text>
        )}
      </Box>
      <Box px={24}>
        <Divider option="horizontal" />
      </Box>
      <Box display="grid" justifyContent="center" py={30}>
        <MainButton
          icon="download"
          iconColor="#fff"
          thin
          width={162}
          disabled={!isDownloadActive}
          onClick={() => {
            const columns: { header: string; key: string }[] = [{ header: '労働者名簿', key: 'record' }];
            const rows: { record?: string | null }[] = [];
            workerData?.getVTWorkerDataRecord?.forEach((val) => {
              rows.push({ record: val?.record });
            });
            downloadCsvFile(columns, rows, fileName);
          }}
        >
          <Text variant="body14" bold color="white">
            CSVダウンロード
          </Text>
        </MainButton>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%" height="calc(100% - 49px)" overflow="auto">
      <Box display="flex" flexDirection="column" alignItems="center" pa={16} pb={14}>
        <Text variant="h2" color="darkBlue">
          労働者名簿閲覧 - ダウンロード
        </Text>
        <Text variant="caption12" align="center" color="darkBlue">
          出力したい期間を設定し、CSVダウンロードボタンを押してください。
        </Text>
      </Box>
      <Box px={16}>
        <Divider option="horizontal" />
      </Box>
      <StyledFlexWrap>
        <Box display="flex" alignItems="center" justifyContent="space-between" px={16} pt={16}>
          <div style={{width: "100%"}}>
            <DatePicker
              placeholderText="日付を選択"
              selected={startDate}
              onChange={(val) => setStartDate(val)}
            />
          </div>
          <Box width={12} px={2}>
            <Text display="block" variant="chip10" color="liteGray">~</Text>
          </Box>
          <div style={{width: "100%"}}>
            <DatePicker width={118} placeholderText="日付を選択" selected={endDate} onChange={(val) => setEndDate(val)} />
          </div>
        </Box>
      </StyledFlexWrap>
      {workerData?.getVTWorkerDataRecord?.length === 0 && (
        <Box px={16} pt={4}>
          <Text color="cautionRed" variant="caption12">
            指定の日付では、データがありません。
          </Text>
        </Box>
      )}
      <Box width="100%" display="flex" justifyContent="flex-end" px={16} mb={3}>
        <MainButton
          width={46}
          customHeight={40}
          variant="clear"
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
          }}
        >
          クリア
        </MainButton>
      </Box>
      <Box px={16}>
        <Divider option="horizontal" />
      </Box>
      <Box display="grid" justifyContent="center" py={16}>
        <MainButton
          icon="download"
          iconColor="#fff"
          width={180}
          disabled={!isDownloadActive}
          onClick={() => {
            const columns: { header: string; key: string }[] = [{ header: '労働者名簿', key: 'record' }];
            const rows: { record?: string | null }[] = [];
            workerData?.getVTWorkerDataRecord?.forEach((val) => {
              rows.push({ record: val?.record });
            });
            downloadCsvFile(columns, rows, fileName);
          }}
        >
          <Text variant="body14" bold color="white">
            CSVダウンロード
          </Text>
        </MainButton>
      </Box>
    </Box>
  // feat screen 08-04-02 end
  );
}
