/**
 * Figma ID: 08-05-01
 * 名称: 労働条件通知書
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Modal } from 'components/newModal';
import { useForm } from 'react-hook-form';
import { useAuth } from 'hooks/authProvider';
import { useGetVtNoticeTemplateByTpmemIdQuery, useUpdateSTpmemRecruitTemplateByTIdMutation } from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { useNoticeOfEmploymentPdfPreview, useNoticeOfEmploymentPdfTemplateDownload } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { CompleteContent, ConfirmContent, EditContent } from './EditModalContent';
import { CompleteFooter, ConfirmFooter, EditFooter } from './EditModalFooter';

export type EditContentModel = {
  socialInsurance: string;
  passiveSmokingPrevention: string;
  isWorkingInSmokingArea: string;
};
type EditPhaseModel = 'Edit' | 'Confirm' | 'Complete';
export function NoticeWorkingConditions() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const id = useAuth().tpmemId;
  const [isOpenEditModal, setIsOpenEditModal] = React.useState(false);
  const {
    data: templateData,
    loading: templateDataLoading,
    error: templateDataError,
    refetch: refetchTemplateData,
  } = useGetVtNoticeTemplateByTpmemIdQuery({
    variables: { tpmem_id: id ?? 0 },
    notifyOnNetworkStatusChange: true,
  });
  const [updateTemplateData, { loading: updateLoading }] = useUpdateSTpmemRecruitTemplateByTIdMutation();
  const [phase, setPhase] = React.useState<EditPhaseModel>('Edit');
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<EditContentModel>({ reValidateMode: 'onChange' });

  /**
   * エラーダイアログ
   */
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (templateDataError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [templateDataError, openErrorModal]);
  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf: previewNoticePdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = () => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFプレビュー
        const result = await previewNoticePdf(Number(id), {});
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  /**
   * [hook] 労働条件通知書PDFテンプレートダウンロード
   */
  const { downloadPdf, loading: downloadLoading } = useNoticeOfEmploymentPdfTemplateDownload();

  /**
   * [onClick] 労働条件通知書PDFダウンロード
   */
  const onClickDownload = () => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (downloadLoading) return;

        // PDFダウンロード
        const result = await downloadPdf(Number(id));
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        openErrorModal({ message: 'ダウンロード処理に失敗しました。' });
      }
    })();
  };

  const currentSocialInsurance = watch('socialInsurance');
  const currentPassiveSmokingPrevention = watch('passiveSmokingPrevention');
  const currentIsWorkingInSmokingArea = watch('isWorkingInSmokingArea');
  const nextPhase = () => {
    switch (phase) {
      case 'Confirm':
        setPhase('Complete');
        refetchTemplateData();
        break;
      default:
        setPhase('Confirm');
        break;
    }
  };

  const closeModal = () => {
    setIsOpenEditModal(false);
    setPhase('Edit');
    reset();
  };

  function onSubmit() {
    switch (phase) {
      case 'Confirm':
        nextPhase();
        // 登録処理
        break;
      default:
        handleSubmit(() => {
          updateTemplateData({
            variables: {
              tpmem_id: id ?? 0,
              social_insurance: currentSocialInsurance,
              prevent_ets: currentPassiveSmokingPrevention,
              in_smoking_area: currentIsWorkingInSmokingArea === 'yes',
            },
          })
            .then((result) => {
              if (result.data?.updateSTpmemRecruitTemplateByTId.resultRows === ResultRows.UPDATED) {
                nextPhase();
              } else {
                closeModal();
                openErrorModal({ message: '登録処理に失敗しました。' });
              }
            })
            .catch(() => {
              closeModal();
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        })();
        break;
    }
  }

  const editModalHeader = () => {
    switch (phase) {
      case 'Confirm':
        return '記載内容の編集 確認';
      case 'Complete':
        return '記載内容の編集 完了';
      default:
        return '記載内容の編集';
    }
  };

  const editModalContent = () => {
    switch (phase) {
      case 'Confirm':
        return (
          <ConfirmContent
            currentSocialInsurance={currentSocialInsurance}
            currentPassiveSmokingPrevention={currentPassiveSmokingPrevention}
            currentIsWorkingInSmokingArea={currentIsWorkingInSmokingArea}
          />
        );
      case 'Complete':
        return <CompleteContent />;
      default:
        return <EditContent control={control} errors={errors} />;
    }
  };

  const editModalFooter = () => {
    switch (phase) {
      case 'Confirm':
        return <ConfirmFooter onSubmit={() => onSubmit()} close={() => closeModal()} back={() => setPhase('Edit')} />;
      case 'Complete':
        return <CompleteFooter close={() => closeModal()} />;
      default:
        return <EditFooter onSubmit={() => onSubmit()} close={() => closeModal()} />;
    }
  };

  const modalwidth = () => {
    switch (phase) {
      case 'Confirm':
        return 640;
      case 'Complete':
        return 384;
      default:
        return 640;
    }
  };
  // feat screen 08-05-01 start
  return !isMobile ? (
    <Box width="100%" px={24}>
      {(templateDataLoading || updateLoading) && <Loading />}
      <Box display="flex" justifyContent="space-between" py={18}>
        <Box display="flex" alignItems="center" gap={8}>
          <Text variant="h2" color="darkBlue">
            労働条件通知書
          </Text>
          <Text variant="caption12" color="darkBlue">
            記載文章編集、テンプレートの確認・ダウンロードができます。
          </Text>
        </Box>
        <Box display="flex" gap={10}>
          <MainButton
            variant="secondary"
            icon="eye"
            width={160}
            thin
            onClick={() => {
              onClickPreview();
            }}
          >
            プレビュー
          </MainButton>
          <MainButton
            variant="secondary"
            icon="download"
            width={160}
            thin
            onClick={() => {
              onClickDownload();
            }}
          >
            ダウンロード
          </MainButton>
        </Box>
      </Box>
      <Box>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" width="100%">
          <Box width="100%">
            <Box display="flex" py={16}>
              <Box width="30%">
                <Text variant="caption12" bold color="darkBlue">
                  社会保険等
                </Text>
              </Box>
              <Box px={16}>
                <Divider option="vertical" />
              </Box>
              <Box width="80%">
                <Text variant="caption12" color="blueGray">
                  {templateData?.getVTNoticeTemplateByTpmemId.social_insurance ?? '未設定'}
                </Text>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" py={16}>
              <Box width="30%">
                <Text variant="caption12" bold color="darkBlue">
                  受動喫煙防止措置
                </Text>
              </Box>
              <Box px={16}>
                <Divider option="vertical" />
              </Box>
              <Box width="80%">
                <Text variant="caption12" color="blueGray">
                  {templateData?.getVTNoticeTemplateByTpmemId.prevent_ets ?? '未設定'}
                </Text>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" py={16}>
              <Box width="30%">
                <Text variant="caption12" bold color="darkBlue">
                  喫煙可能エリアでの作業
                </Text>
              </Box>
              <Box px={16}>
                <Divider option="vertical" />
              </Box>
              <Box width="80%">
                <Text variant="caption12" color="blueGray">
                  {templateData?.getVTNoticeTemplateByTpmemId.in_smoking_area ? '有' : '無'}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box pa={16}>
            <Divider option="vertical" />
          </Box>
        </Box>
        <Box display="flex" height="100%">
          <MainButton
            variant="secondary"
            icon="edit"
            iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
            width={92}
            thin
            onClick={() => setIsOpenEditModal(true)}
          >
            編集
          </MainButton>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Modal
        isOpen={isOpenEditModal}
        header={
          <Text variant="h2" color="darkBlue">
            {editModalHeader()}
          </Text>
        }
        width={modalwidth()}
        footer={editModalFooter()}
        content={editModalContent()}
        onClose={() => setIsOpenEditModal(false)}
        height="auto"
      />
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%" px={16} height="calc(100% - 49px)" overflow="auto">
      {(templateDataLoading || updateLoading) && <Loading />}
      <Box display="flex" flexDirection="column" pb={16}>
        <Box display="flex" flexDirection="column" alignItems="center" pt={16} pb={14}>
          <Text variant="h2" color="darkBlue">
            労働条件通知書
          </Text>
          <Text display="block" align="center" variant="caption12" color="darkBlue">
            記載文章編集、テンプレートの確認・ダウンロードができます。
          </Text>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={10}>
          <MainButton
            variant="secondary"
            icon="eye"
            fullWidth
            onClick={() => {
              onClickPreview();
            }}
          >
            プレビュー
          </MainButton>
          <MainButton
            variant="secondary"
            icon="download"
            fullWidth
            onClick={() => {
              onClickDownload();
            }}
          >
            ダウンロード
          </MainButton>
        </Box>
      </Box>
      <Box>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" py={15}>
          <Box minWidth={135} display="flex" alignItems="center">
            <Text display="block" variant="caption12" bold color="darkBlue">
              社会保険等
            </Text>
          </Box>
          <Box px={16} pr={13} display="flex" alignItems="center">
            <Divider option="vertical" length={20} />
          </Box>
          <Box width="80%">
            <Text display="block" variant="caption12" color="blueGray">
              {templateData?.getVTNoticeTemplateByTpmemId.social_insurance ?? '未設定'}
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" py={15}>
          <Box minWidth={135} display="flex" alignItems="center">
            <Text display="block" variant="caption12" bold color="darkBlue">
              受動喫煙防止措置
            </Text>
          </Box>
          <Box px={16} pr={13} display="flex" alignItems="center">
            <Divider option="vertical" length={20}/>
          </Box>
          <Box width="80%">
            <Text display="block" variant="caption12" color="blueGray">
              {templateData?.getVTNoticeTemplateByTpmemId.prevent_ets ?? '未設定'}
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" py={15}>
          <Box minWidth={135} display="flex" alignItems="center">
            <Text display="block" variant="caption12" bold color="darkBlue">
              喫煙可能エリアでの作業
            </Text>
          </Box>
          <Box px={16} pr={13} display="flex" alignItems="center">
            <Divider option="vertical" length={20}/>
          </Box>
          <Box width="80%">
            <Text display="block" variant="caption12" color="blueGray">
              {templateData?.getVTNoticeTemplateByTpmemId.in_smoking_area ? '有' : '無'}
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" py={16}>
        <MainButton
          variant="secondary"
          icon="edit"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          fullWidth
          onClick={() => setIsOpenEditModal(true)}
        >
          編集
        </MainButton>
      </Box>
      <Modal
        isOpen={isOpenEditModal}
        header={
          <Text variant="h2" color="darkBlue">
            {editModalHeader()}
          </Text>
        }
        width="100%"
        footer={editModalFooter()}
        content={editModalContent()}
        onClose={() => setIsOpenEditModal(false)}
        height="unset"
      />
    </Box>
  // feat screen 08-05-01 end
  );
}
