import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';

export interface StoreDetails {
  storeName: string;
  zipcode: string;
  address: string;
  image: string;
  phone: string;
  email: string;
  managerName: string;
  corporationName: string;
  corporationZipcode: string;
  corporationAddress: string;
  corporationNumber: string;
  corporationPhone: string;
  representativeName: string; // 代表者氏名
  representativeNameKana: string;
  chiefName: string; // 担当者氏名
  chiefNameKana: string;
  chiefPhone: string;
}

interface Props {
  isOpen: boolean;
  onModalClose: () => void;
  storeDetails: StoreDetails;
}

function StoreDetailsModal(props: Props) {
  const { isOpen, onModalClose, storeDetails } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const header = (
    <Text variant="h2" color="darkBlue">
      {storeDetails.storeName}
    </Text>
  );

  const content = !isMobile ? (
    <Box>
      <Box>
        <Text color="darkGray" variant="body14">
          店舗画像
        </Text>
      </Box>
      <Box>
        <img width={160} height={120} src={storeDetails.image} alt="" />
      </Box>
      <Box mt={8}>
        <Divider option="horizontal" />
        <Info
          items={[
            {
              label: '店舗名',
              content: storeDetails.storeName,
            },
            {
              label: '住所',
              content: `〒${storeDetails.zipcode.slice(0, 3)}-${storeDetails.zipcode.slice(3)} ${storeDetails.address}`,
            },
            { label: '電話番号', content: storeDetails.phone },
            { label: 'メールアドレス(ID)', content: storeDetails.email },
            { label: '責任者氏名', content: storeDetails.managerName },
            {
              label: '事業者',
              content: (
                <Box display="flex" flexDirection="column" gap={8}>
                  <Box>
                    <Text variant="body14">{storeDetails.corporationName}</Text>
                  </Box>
                  <Box>
                    <Text variant="body14">
                      〒{storeDetails.corporationZipcode.slice(0, 3)}-{storeDetails.corporationZipcode.slice(3)}{' '}
                      {storeDetails.corporationAddress}
                    </Text>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>
                      <Text variant="body14">法人番号</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{storeDetails.corporationNumber}</Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>
                      <Text variant="body14">電話番号</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{storeDetails.corporationPhone}</Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>
                      <Text variant="body14">代表者</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{storeDetails.representativeName}</Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>&nbsp;</Box>
                    <Box>
                      <Text variant="body14">{storeDetails.representativeNameKana}</Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>
                      <Text variant="body14">担当者</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">
                        <Text variant="body14">{storeDetails?.chiefName}</Text>
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>&nbsp;</Box>
                    <Box>
                      <Text variant="body14">
                        <Text variant="body14">{storeDetails.chiefNameKana}</Text>
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={112}>
                      <Text variant="body14">担当者電話番号</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{storeDetails.chiefPhone}</Text>
                    </Box>
                  </Box>
                </Box>
              ),
            },
          ]}
        />
        <Divider option="horizontal" />
      </Box>
    </Box>
  ) : (
    // feat_screen_09-04-06_start
    <Box>
      <Box display="flex" justifyContent='space-between'>
        <Text color="darkGray" variant="body14">
          店舗画像
        </Text>
        <Box>
          <img width={126} height={95} src={storeDetails.image} alt="" />
        </Box>
      </Box>

      <Box mt={8}>
        <Divider option="horizontal" />
        <Info
          items={[
            {
              label: '店舗名',
              content: storeDetails.storeName,
            },
            {
              label: '住所',
              content: `〒${storeDetails.zipcode.slice(0, 3)}-${storeDetails.zipcode.slice(3)} ${storeDetails.address}`,
            },
            { label: '電話番号', content: storeDetails.phone },
            { label: 'メールアドレス(ID)', content: storeDetails.email },
            { label: '責任者氏名', content: storeDetails.managerName },
            {
              label: '事業者',
              content: (
                <Box display="flex" flexDirection="column" gap={8}>
                  <Box>
                    <Text variant="body14">{storeDetails.corporationName}</Text>
                  </Box>

                  <Divider option="horizontal" />

                  <Box>
                    <Text variant="body14">
                      〒{storeDetails.corporationZipcode.slice(0, 3)}-{storeDetails.corporationZipcode.slice(3)}{' '}
                      {storeDetails.corporationAddress}
                    </Text>
                  </Box>

                  <Divider option="horizontal" />

                  <Box display="flex" justifyContent='space-between'>
                    <Text variant="body14">法人番号</Text>
                    <Box>
                      <Text variant="body14">{storeDetails.corporationNumber}</Text>
                    </Box>
                  </Box>

                  <Divider option="horizontal" />

                  <Box display="flex" justifyContent='space-between'>
                    <Text variant="body14">電話番号</Text>
                    <Box>
                      <Text variant="body14">{storeDetails.corporationPhone}</Text>
                    </Box>
                  </Box>

                  <Divider option="horizontal" />

                  <Box display="flex" justifyContent='space-between'>
                    <Text variant="body14">代表者</Text>
                    <Box>
                      <Text variant="body14">{storeDetails.representativeName}</Text>
                    </Box>
                  </Box>


                  <Box display="flex" >
                    <Box width={90}>&nbsp;</Box>
                    <Box>
                      <Text variant="body14">{storeDetails.representativeNameKana}</Text>
                    </Box>
                  </Box>

                  <Divider option="horizontal" />


                  <Box display="flex" justifyContent='space-between'>
                    <Text variant="body14">担当者</Text>
                    <Box>
                      <Text variant="body14">
                        <Text variant="body14">{storeDetails?.chiefName}</Text>
                      </Text>
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box width={90}>&nbsp;</Box>
                    <Box>
                      <Text variant="body14">
                        <Text variant="body14">{storeDetails.chiefNameKana}</Text>
                      </Text>
                    </Box>
                  </Box>

                   <Divider option="horizontal" />

                  <Box display="flex" justifyContent="space-between">
                    <Text variant="body14">担当者電話番号</Text>
                    <Box>
                      <Text variant="body14">{storeDetails.chiefPhone}</Text>
                    </Box>
                  </Box>
                </Box>
              ),
            },
          ]}
        />
        <Divider option="horizontal" />
      </Box>
    </Box>
    // feat_screen_09-04-06_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton 
        // feat_screen_09-04-06_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-04-06_end
        variant="secondary" 
        onClick={() => onModalClose()}>
        閉じる
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      // feat_screen_09-04-06_start
      height={!isMobile ? "90vh" : 589} 
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-04-06_end
    />
  );
}

export default StoreDetailsModal;
