/**
 * Figma ID: 01-01-13
 * 名称: メールアドレス変更依頼 送信完了
 */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

export function CompleteEmail() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const navigate = useNavigate();
  // feat screen No02 start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="メールアドレス変更依頼 送信完了" />
      <Box height={50} display="flex" alignItems="center">
        <Text variant="body14">メールアドレス変更依頼の送信が完了しました。</Text>
        <br />
        <Text variant="body14">運営からの連絡をお待ち下さい。</Text>
      </Box>

      <Box display="grid" justifyContent="center">
        <TextLink to="" onClick={() => navigate('/signIn')}>
          閉じる
        </TextLink>
      </Box>
    </Box>
  ) : (
    // Responsive component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="メールアドレス変更依頼 送信完了" />
      <Box display="flex" flexDirection='column' alignItems="flex-start" pt={18} pb={24}>
        <Text variant="body14">メールアドレス変更依頼の送信が完了しました。</Text>
        <Text variant="body14">運営からの連絡をお待ち下さい。</Text>
      </Box>
      <Box display="grid" justifyContent="center">
        <TextLink variant="body14" to="" onClick={() => navigate('/signIn')}>
          閉じる
        </TextLink>
      </Box>
    </Box>
  );
  // feat screen No02 end
}
