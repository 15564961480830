/**
 * Figma ID: 08-02-01, 08-02-02, 08-02-03, 08-02-04
 * 名称: ランク別育成料
 */

import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';
import type { UpdateFeePhase } from '.';

type EducationFeeByRankModel = {
  phase?: UpdateFeePhase;
  toNextPhase?: () => void;
  toBackPhase?: () => void;
  close: () => void;
};
export function SetValueFooter(props: EducationFeeByRankModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { phase, toNextPhase, toBackPhase, close } = props;
  const content = () => {
    switch (phase) {
      case 'confirm':
        return (
          // feat screen 08-02-01 start
          <Box justifyContent="end" display="flex" gap={8}>
            <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
              キャンセル
            </MainButton>
            <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={toBackPhase}>
              戻る
            </MainButton>
            <MainButton width={!isMobile ? 104 : 96} onClick={toNextPhase}>
              保存
            </MainButton>
          </Box>
          // feat screen 08-02-01 end
        );
      case 'complete':
        return (
          // feat screen 08-02-01 start
          <Box justifyContent="end" display="flex" gap={5}>
            <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
              閉じる
            </MainButton>
          </Box>
          // feat screen 08-02-01 end
        );

      default:
        return (
          // feat screen 08-02-01 start
          <Box justifyContent="end" display="flex" gap={8}>
            <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
              キャンセル
            </MainButton>
            <MainButton width={!isMobile ? 104 : 96} onClick={toNextPhase}>
              次へ
            </MainButton>
          </Box>
          // feat screen 08-02-01 end
        );
    }
  };
  return <Box width="100%">{content()}</Box>;
}
