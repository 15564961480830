/**
 * Figma ID: 01-01-05
 * 名称: 事業者情報
 */
import React, { useMemo } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import * as gql from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  toNext: (mcoInfo: {
    mcoName: string;
    mcoZipCode: string;
    mcoState: string;
    mcoCity: string;
    mcoAddress1: string;
    mcoAddress2: string;
    mcoPhone: string;
  }) => void;
  toBack: () => void;
};
export function EmployerInfo({ toNext, toBack }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { openErrorModal } = useErrorModal();
  const { data: { getMState } = { getMState: [] }, error: getMStateError } = gql.useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [fetchMCityByStateId, { error: fetchMCityByStateIdError }] = gql.useGetMCityByStateIdLazyQuery();
  React.useEffect(() => {
    if (getMStateError || fetchMCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getMStateError, fetchMCityByStateIdError, openErrorModal]);

  const [cityOptions, setCityOptions] = React.useState<{ label: string; value: string }[]>([]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
    zipCode1: string;
    zipCode2: string;
    prefecture?: string;
    city?: string;
    address1?: string;
    address2?: string;
    phone: string;
  }>({
    mode: 'onChange',
  });

  const prefectureOptions = useMemo<{ label: string; value: string }[]>(() => {
    if (!getMState) {
      return [];
    }
    return getMState.map((item) => ({
      label: item?.name ?? '',
      value: item?.name ?? '',
    }));
  }, [getMState]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'prefecture' && type === 'change') {
        const selectedPrefecture = getMState?.find((item) => item?.name === value.prefecture);
        if (selectedPrefecture) {
          fetchMCityByStateId({
            context: { clientName: 'master' },
            variables: { sid: Number(selectedPrefecture.id) },
          }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
            if (getMCityByStateId) {
              setCityOptions(
                getMCityByStateId.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                }))
              );
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMCityByStateId, getMState]);

  const onSubmit = handleSubmit((data) => {
    toNext({
      mcoName: data.name,
      mcoZipCode: `${data.zipCode1}${data.zipCode2}`,
      mcoState: data.prefecture || '',
      mcoCity: data.city || '',
      mcoAddress1: data.address1 || '',
      mcoAddress2: data.address2 || '',
      mcoPhone: data.phone,
    });
  });

  return (
    // feat screen 01-01-05 start
    <div>
    {!isMobile ? (
      <Box width={752} display="flex" flexDirection="column" gap={24}>
        <ContentTitle title="新規登録 - 事業者情報" />

      <Box>
        <Text variant="body14">必要情報を入力してください。</Text>
        <br />
        <Text variant="body14">入力した情報は、雇用主(給与支払者)として労働契約書や源泉徴収票に記載されます。</Text>
      </Box>

      <Box display="flex" flexDirection="column">
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>氏名/屋号</InputLabel>
          <RhfInput
            fullWidth
            control={control}
            name="name"
            defaultValue=""
            placeholder="氏名/屋号を入力"
            rules={{ required: '氏名/屋号を入力してください。' }}
          />
          {errors.name?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.name.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>郵便番号</InputLabel>
          <Box display="flex" alignItems="center">
            <RhfInput
              width={120}
              control={control}
              name="zipCode1"
              defaultValue=""
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: 3, message: `郵便番号の形式で入力してください` },
                minLength: { value: 3, message: `郵便番号の形式で入力してください` },
              }}
            />
            <Box px={13}>
              <Text variant="body14" color="liteGray">
                -
              </Text>
            </Box>
            <RhfInput
              width={120}
              control={control}
              name="zipCode2"
              defaultValue=""
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: 4, message: `郵便番号の形式で入力してください` },
                minLength: { value: 4, message: `郵便番号の形式で入力してください` },
              }}
            />
          </Box>
          {(errors.zipCode1?.message || errors.zipCode2?.message) && (
            <Text variant="caption12" color="cautionRed">
              {errors.zipCode1?.message || errors.zipCode2?.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel optional fullWidth>
            住所
          </InputLabel>
          <Box display="flex" gap={5}>
            <Box>
              <RhfSelect
                width={176}
                control={control}
                name="prefecture"
                defaultValue=""
                placeholder="都道府県"
                options={prefectureOptions}
                rules={{ required: '都道府県を選択してください' }}
              />
              {errors.prefecture && (
                <Text variant="caption12" color="cautionRed">
                  {errors.prefecture?.message}
                </Text>
              )}
            </Box>
            <Box>
              <RhfSelect
                width={176}
                control={control}
                name="city"
                defaultValue=""
                placeholder="市区町村"
                options={cityOptions}
                rules={{ required: '市区町村を選択してください' }}
              />
              {errors.city && (
                <Text variant="caption12" color="cautionRed">
                  {errors.city?.message}
                </Text>
              )}
            </Box>
            <Box>
              <RhfInput
                width={176}
                control={control}
                name="address1"
                defaultValue=""
                placeholder="番地等"
                rules={{ required: '番地等を入力してください' }}
              />
              {errors.address1 && (
                <Text variant="caption12" color="cautionRed">
                  {errors.address1?.message}
                </Text>
              )}
            </Box>
            <RhfInput width={176} control={control} name="address2" defaultValue="" placeholder="建物名・部屋番号" />
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>電話番号</InputLabel>
          <RhfInput
            control={control}
            name="phone"
            defaultValue=""
            placeholder="電話番号を入力"
            rules={{
              required: '電話番号を入力してください。',
              pattern: {
                value: /^\d*$/,
                message: '数字を使用してください。',
              },
            }}
          />
          {errors.name?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.name.message}
            </Text>
          )}
        </Box>
      </Box>

        <Box display="flex" gap={5} justifyContent="center">
          <MainButton variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            onClick={() => {
              onSubmit();
            }}
          >
            次へ
          </MainButton>
        </Box>
      </Box>
    ) : (
      // Responsive Component
      <Box width="100vw" display="flex" flexDirection="column" px={16}>
        <Box>
          <ContentTitle title="新規登録 - 事業者情報" />
        </Box>

        <Box mt={16}>
          <Text variant="body14">必要情報を入力してください。</Text>
          <Text variant="body14">入力した情報は、雇用主(給与支払者)として労働契約書や源泉徴収票に記載されます。</Text>
        </Box>

        <Box display="flex" flexDirection="column" mt={18}>
          <Box display="flex" flexDirection="column" gap={2} pb={16}>
            <InputLabel>氏名/屋号</InputLabel>
            <RhfInput
              fullWidth
              control={control}
              name="name"
              defaultValue=""
              placeholder="氏名/屋号を入力"
              rules={{ required: '氏名/屋号を入力してください。' }}
            />
            {errors.name?.message && (
              <Box mt={-4}>
                <Text variant="caption12" color="cautionRed">
                  {errors.name.message}
                </Text>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={2} pb={18}>
            <InputLabel>郵便番号</InputLabel>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <div style={{width: "100%"}}>
                <RhfInput
                  fullWidth
                  control={control}
                  name="zipCode1"
                  defaultValue=""
                  rules={{
                    required: '郵便番号を入力してください。',
                    pattern: {
                      value: /^\d*$/,
                      message: '数字を使用してください。',
                    },
                    maxLength: { value: 3, message: `郵便番号の形式で入力してください` },
                    minLength: { value: 3, message: `郵便番号の形式で入力してください` },
                  }}
                />
              </div>
              <Box px={13}>
                <Text variant="body14" color="liteGray">
                  -
                </Text>
              </Box>
              <div style={{width: "100%"}}>
                <RhfInput
                  fullWidth
                  control={control}
                  name="zipCode2"
                  defaultValue=""
                  rules={{
                    required: '郵便番号を入力してください。',
                    pattern: {
                      value: /^\d*$/,
                      message: '数字を使用してください。',
                    },
                    maxLength: { value: 4, message: `郵便番号の形式で入力してください` },
                    minLength: { value: 4, message: `郵便番号の形式で入力してください` },
                  }}
                />
              </div>
            </Box>
            {(errors.zipCode1?.message || errors.zipCode2?.message) && (
              <Box mt={-4}>
                <Text variant="caption12" color="cautionRed">
                  {errors.zipCode1?.message || errors.zipCode2?.message}
                </Text>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={4} pb={16}>
            <InputLabel optional fullWidth>
              住所
            </InputLabel>
            <Box display="flex" alignItems='center' justifyContent="space-between" mb={8}>
              <div style={{width: "100%"}}>
                <RhfSelect
                  fullWidth
                  height={40}
                  control={control}
                  name="prefecture"
                  defaultValue=""
                  placeholder="都道府県"
                  options={prefectureOptions}
                />
              </div>
              <Box px={13}>
                <Text variant="body14" color="liteGray">
                  -
                </Text>
              </Box>
              <div style={{width: "100%"}}>
                <RhfSelect
                  fullWidth
                  height={40}
                  control={control}
                  name="city"
                  defaultValue=""
                  placeholder="市区町村"
                  options={cityOptions}
                />
              </div>
            </Box>
            <Box display="flex" alignItems='center' justifyContent="space-between" mt={4}>
              <div style={{width: "100%"}}>
                <RhfInput fullWidth control={control} name="address1" defaultValue="" placeholder="番地等" />
              </div>

              <Box px={13}>
                <Text variant="body14" color="liteGray">
                  -
                </Text>
              </Box>
              <div style={{width: "100%"}}>
                <RhfInput fullWidth control={control} name="address2" defaultValue="" placeholder="建物名・部屋番号" />
              </div>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <InputLabel>電話番号</InputLabel>
            <RhfInput
              fullWidth
              control={control}
              name="phone"
              defaultValue=""
              placeholder="電話番号を入力"
              rules={{
                required: '電話番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
              }}
            />
            {errors.name?.message && (
              <Box mt={-4}>
                <Text variant="caption12" color="cautionRed">
                  {errors.name.message}
                </Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" py={24} justifyContent="space-between" gap={6}>
          <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            fullWidth
            customHeight={40}
            onClick={() => {
              onSubmit();
            }}
          >
            次へ
          </MainButton>
        </Box>
      </Box>
    )}
    </div>
    // feat screen 01-01-05 end
  );
}
