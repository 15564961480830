import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 04-02-06-10
 * 出勤実態無し完了
 */

function DenialCompletedModal(props: { isOpen: boolean; onModalClose: () => void }) {
  const { isOpen, onModalClose } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        出勤実態無し 完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          出勤実態無しを送信しました。
        </Text>
      </Box>
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ) : (
      // feat_screen_04-02-06-09_start
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
      // feat_screen_04-02-06-09_end
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default DenialCompletedModal;
