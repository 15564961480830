import React from 'react';
import { GraphQLErrorCode, ResultRows } from 'components/const';
import { Text } from 'components/text';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';

type HasResultRows = {
  resultRows: number;
};

type HasErrorCode = {
  ErrorCode?: number | null;
};

type HasStatusCode = {
  statusCode?: number | null;
};

class Result {
  readonly isSuccessful: boolean;

  readonly error: RequiringCallbackError | undefined;

  constructor(props: { isSuccessful: boolean; error?: RequiringCallbackError }) {
    const { isSuccessful, error } = props;
    this.isSuccessful = isSuccessful;
    this.error = error;
  }
}

function isHasResultRows(arg: any): arg is HasResultRows {
  return 'resultRows' in arg;
}

function isHasErrorCode(arg: any): arg is HasErrorCode {
  return 'ErrorCode' in arg;
}

function isInvalidStatusError(arg: HasResultRows | HasErrorCode | HasStatusCode): boolean {
  const PAUSE_STATUS_ERROR = -99;

  if (isHasResultRows(arg)) {
    return arg.resultRows === ResultRows.PAUSE_STATUS_ERROR;
  }

  if (isHasErrorCode(arg)) {
    return arg.ErrorCode === GraphQLErrorCode.PAUSE_STATUS_ERROR;
  }

  return arg.statusCode === PAUSE_STATUS_ERROR;
}

export function handleMutationResult(result: HasResultRows | HasErrorCode | HasStatusCode): Result {
  if (isInvalidStatusError(result)) {
    return new Result({
      isSuccessful: false,
      error: new RequiringCallbackError({
        title: '現在休止中です',
        message: (
          <Text variant="body14">
            現在運営により休止中になっています。
            <br />
            一部機能が制限されます。
            <br />
            詳細は運営にご確認ください。
          </Text>
        ),
        requiredCallback: () => {
          window.location.href = '/';
        },
      }),
    });
  }
  return new Result({ isSuccessful: true });
}
