import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';

interface UpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateImage: () => void;
}
export function UpdateModal(props: UpdateModalProps) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen = false, onClose, updateImage } = props;

  return (
    <Modal
      isOpen={isOpen}
      header={
        <Text variant="h2" color="darkBlue">
          上書き
        </Text>
      }
      content={
        <Box display="grid" alignItems="center" height="100%">
          <Box display="grid">
            {/* feat screen 08-08-02 start */}
            <Text display={!isMobile ? "inline" : "block"} variant="body14">画像を上書きしますか？</Text>
            <Text display={!isMobile ? "inline" : "block"} variant="body14">上書きされた画像は元に戻せません。</Text>
            {/* feat screen 08-08-02 end */}
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="flex-end" gap={8}>
          <MainButton
            // feat screen 08-08-02 start
            width={!isMobile ? 104 : 96}
            // feat screen 08-08-02 end
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            キャンセル
          </MainButton>
          <MainButton width={104} onClick={updateImage}>
            アップロード
          </MainButton>
        </Box>
      }
      onClose={onClose}
      // feat screen 08-08-02 start
      height={!isMobile ? 220 : "unset"}
      // feat screen 08-08-02 end
    />
  );
}
