/**
 * Figma ID: 04-01-01
 * 名称: バイト募集（募集）
 */
import React, { ReactNode, useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@linaria/react';
import { isSameDay } from 'date-fns';
import { useAuth } from 'hooks/authProvider';
import { MainButton } from 'components/mainButton';
import { Input } from 'components/input';
import { Select } from 'components/select';
import { List } from 'components/list';
import { PaginationBox } from 'components/pagination/paginationBox';
import { Icon } from 'components/icon';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Chip } from 'components/chip';
import { DatePicker } from 'components/datePicker';
import { Text } from 'components/text';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { orderBy } from 'lodash';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { RecruitStatus, ModalZindex } from 'components/const';
import { usePagenation, useSort } from 'components/utils/hooks';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { Checkbox } from 'components/newCheckbox';
import { Modal } from 'components/newModal';
import { useOfferListConnectApi, useDeleteMultiplePostConnectApi } from '../common/hooks';

const RelativeContainer = styled.div`
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  top: -8px;
  right: 0;
  /* feat screen 04-01-01-01 start */
  @media only screen and (max-width: 768px) {
    top: -10px;
    right: -4px;
  }
  /* feat screen 04-01-01-01 end */
`;

enum TitleMaxLength {
  NORMAL = 17,
  AUTO_DECIDE = 12,
}

type Props = {
  setHasNewlyItem: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRefetch?: boolean;
};

enum NewlyStatus {
  NEWLY = 1,
  NOT_NEWLY = 0,
}

// feat screen 04-01-01-01 add model start
enum ModalStep {
  NULL = 0,
  CONFIRM_DELETE = 1,
  DELETE_SUCCESS = 2,
}
// feat screen 04-01-01-01 add model end

/* feat screen_04-01_start */
interface PropState {
  statusAll: string;
  dateValue: Date;
}
/* feat screen_04-01_end */

export function Recruiting({ setHasNewlyItem, shouldRefetch }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat screen 04-01-01-01 start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.NULL);
  // feat screen 04-01-01-01 end
  const auth = useAuth();
  const { tpmemId } = auth;

  const navigate = useNavigate();
  const location = useLocation();
  /* feat screen_04-01_start */
  const state = (location?.state || {}) as PropState;
  const { statusAll, dateValue } = state;
  /* feat screen_04-01_end */
  const [dataSize, setDataSize] = useState<number>(0);

  // feat screen 04-01-01-01 start
  // const [checkList, setCheckList] = useState<string[]>([]);
  const [listItem, setListItem] = useState<{ uniqueKey: string | number; [key: string]: ReactNode }[]>([]);
  // feat screen 04-01-01-01 end

  /**
   * Filterに関するState
   */
  const [offerNumber, setOfferNumber] = useState<string>('');
  const [offerTitle, setOfferTitle] = useState<string>('');
  /* feat screen_04-01_start */
  const [workingDay, setWorkingDay] = React.useState<Date | null>(dateValue || null);
  const [statusSelect, setStatusSelect] = React.useState<string>(
    statusAll !== undefined ? statusAll : String(RecruitStatus.RECRUITING)
  );
  /* feat screen_04-01_end */
  const [searchConditions, setSearchConditions] = useState<{ offerNumber: string; offerTitle: string } | null>(null);

  /**
   * ［API］バイト募集一覧取得
   *  04-01-01-01
   */

  const { data, loading, error: getVtArbeitOfferListByTpmemIdError, refetch } = useOfferListConnectApi(Number(tpmemId));

  // feat screen 04-01-01 start
  /**
   * [API] Mutation（Delete MultiPost）
   */
  const {
    ids: checkList,
    deleteTArbeitOfferByIdsMutation,
    setIds: setCheckList,
    error: dailyMultiPostError,
  } = useDeleteMultiplePostConnectApi();
  // feat screen 04-01-01 end

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (getVtArbeitOfferListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtArbeitOfferListByTpmemIdError, openErrorModal]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  /**
   * Eyeボタンのクリックイベント
   */
  const handleOnClick = useCallback(
    (
      link: string,
      paraid?: number | null,
      paraid2?: number | null,
      parastatus?: number | null,
      paramain_count?: number | null,
      parasub_count?: number | null,
      paranormal_count?: number | null
    ) => {
      // クリックされた要素に応じた処理を行う。
      // パラメータをセット
      const id = paraid;
      const id2 = paraid2;
      const status = parastatus;
      const mainCount = paramain_count;
      const subCount = parasub_count;
      const normalCount = paranormal_count;
      navigate(link, { state: { id, id2, status, mainCount, subCount, normalCount } });
      // 選択されたデータをパラメータにセット
    },
    [navigate]
  );

  /**
   * チップを生成
   */
  const generateChip = (status?: number | null) => {
    switch (status) {
      case RecruitStatus.BEFORE_RECRUITMENT:
        return <Chip color="blueGray">募集前</Chip>;
      case RecruitStatus.RECRUITING:
        return <Chip color="darkBlue">募集中</Chip>;
      case RecruitStatus.ACCEPTED:
        return <Chip color="okBlue">採用確定</Chip>;
      case RecruitStatus.FINISHED:
        return <Chip color="liteGray">募集終了</Chip>;
      case RecruitStatus.RECRUITING_STOP:
        return <Chip color="redLite">募集停止</Chip>;
      case RecruitStatus.FORCED_STOP:
        return <Chip color="cautionRed">強制停止</Chip>;
      default:
        return <Chip color="blue">募集前</Chip>;
    }
  };

  /**
   * 背景色を生成
   */
  const generateBgColor = (status?: number | null) => {
    switch (status) {
      case 4:
        return 'gray';
      case 6:
      case 7:
        return 'red';
      default:
        return 'white';
    }
  };

  /**
   * タイトルを生成
   */
  const generateTitle = (title?: string | null, auto_decide?: number | null) => {
    const maxLength = auto_decide ? TitleMaxLength.AUTO_DECIDE : TitleMaxLength.NORMAL;
    let slicedTitle = title;
    if (slicedTitle && slicedTitle?.length >= maxLength) {
      slicedTitle = `${String(title)?.slice(0, maxLength)}...`;
    }
    return (
      <>
        {auto_decide ? <Chip color="liteGray">自動採用</Chip> : null}
        <Text variant="caption12">{slicedTitle}</Text>
      </>
    );
  };

  // feat screen 04-01-01-01 start
  /**
   * Check if check all is on the screen
   */
  const isCheckAll = () => {
    const isCheckAllOnTable = listItem.every((checkItem) => {
      const uniqueKey = String(checkItem?.uniqueKey || '');
      return checkList.toString().includes(uniqueKey.split('_')[0]);
    });
    return !!listItem.length && isCheckAllOnTable;
  };

  /**
   * Handle check all event
   */
  const handleCheckAll = () => {
    if (isCheckAll()) {
      const listItemId = listItem.map((checkItem) => {
        const uniqueKey = String(checkItem?.uniqueKey || '');
        return Number(uniqueKey.split('_')[0]);
      });
      setCheckList(checkList.filter((checkItem) => !listItemId.includes(checkItem)));
    } else {
      const listItemId = listItem.map((checkItem) => {
        const uniqueKey = String(checkItem?.uniqueKey || '');
        return Number(uniqueKey.split('_')[0]);
      });
      let aTotalCheckList = [...listItemId, ...checkList];
      aTotalCheckList = aTotalCheckList.filter((item, index) => aTotalCheckList.indexOf(item) === index);
      setCheckList(aTotalCheckList);
    }
  };

  /**
   * Handle click delete event
   */
  const handleClickDelete = () => {
    deleteTArbeitOfferByIdsMutation()
      .then((result) => {
        setModalStep(ModalStep.NULL);
        if (!result.errors) {
          refetch();
          setCheckList([]);
          setModalStep(ModalStep.DELETE_SUCCESS);
        } else {
          openErrorModal({ message: '募集の削除に失敗しました', zIndex: ModalZindex.ERRMODALZINDEX });
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message: '募集の削除に失敗しました',
          zIndex: ModalZindex.ERRMODALZINDEX,
        });
      });
  };

  /**
   * Handle click delete event
   */
  const onClickDelete = () => {
    setModalStep(ModalStep.CONFIRM_DELETE);
  };

  const handleCheck = useCallback(
    (checkItem: number) => {
      if (checkList.includes(checkItem)) {
        setCheckList((prevCheckList) => prevCheckList.filter((preCheckItem) => checkItem !== preCheckItem));
      } else {
        setCheckList((prevCheckList) => [...prevCheckList, checkItem]);
      }
    },
    [checkList, setCheckList]
  );

  /**
   * Clear model step
   */
  const clearEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
  };
  // feat screen 04-01-01-01 end

  /**
   * リストのヘッダー
   */
  const header: {
    width: number;
    // feat screen 04-01-01-01 start
    columnName?: string | ReactNode;
    // feat screen 04-01-01-01 end
    key: string;
  }[] = [
    // feat screen 04-01-01-01 start
    {
      columnName: (
        <Checkbox
          checked={isCheckAll()}
          disabled={!(listItem.length > 0)}
          onChange={() => {
            handleCheckAll();
          }}
        />
      ),
      key: 'check',
      width: 28,
    },
    // feat screen 04-01-01-01 end

    // feat screen 04-01-01-01 start
    { columnName: '', key: 'eye', width: !isMobile ? 24 : 20 },
    // feat screen 04-01-01-01 end
    { columnName: '状態', key: 'status', width: 60 },
    { columnName: '掲載No.', key: 'offer_number', width: 100 },
    // feat screen 04-01-01-01 start
    { columnName: '掲載タイトル', key: 'title', width: !isMobile ? 270 : 220 },
    { columnName: '応募人数', key: 'entry_count', width: !isMobile ? 76 : 80 },
    // feat screen 04-01-01-01 end
    { columnName: '募集開始', key: 'post_begin', width: 140 },
    { columnName: '募集締切', key: 'post_end', width: 140 },
    { columnName: 'バイト開始期日', key: 'begin_date', width: 160 },
    { columnName: 'バイト終了期日', key: 'end_date', width: 160 },
  ];

  /**
   * データをソート
   */
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  /**
   * APIから取得したデータを表示用に整形
   */
  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = useMemo(() => {
    if (loading) {
      return [];
    }
    let items = data?.getVTArbeitOfferListByTpmemId.list || [];
    // feat screen 04-01-01 notification on mobile start
    if (isMobile) {
      setHasNewlyItem(items.some((element) => element.newly === NewlyStatus.NEWLY));
    }
    // feat screen 04-01-01 notification on mobile end

    /**
     * Statusでフィルター
     */
    if (statusSelect) {
      items = items.filter(({ status }) => {
        switch (statusSelect) {
          case '1':
            return status === RecruitStatus.BEFORE_RECRUITMENT;
          case '2':
            return status === RecruitStatus.RECRUITING;
          case '3':
            return status === RecruitStatus.ACCEPTED;
          case '4':
            return status === RecruitStatus.FINISHED;
          case '6':
            return status === RecruitStatus.RECRUITING_STOP;
          case '7':
            return status === RecruitStatus.FORCED_STOP;
          case '':
          default:
            return true;
        }
      });
    }

    /**
     * キーワードでフィルター
     */
    if (searchConditions) {
      items = items
        .filter(({ offer_number }) => {
          if (!searchConditions.offerNumber) {
            return true;
          }
          const reg = new RegExp(searchConditions.offerNumber);
          return reg.test(String(offer_number));
        })
        .filter(({ title }) => {
          if (!searchConditions.offerTitle) {
            return true;
          }
          const reg = new RegExp(searchConditions.offerTitle);
          return reg.test(title || '');
        });
    }

    /**
     * バイト日でフィルター
     */
    if (workingDay) {
      items = items.filter(({ begin_date }) => {
        if (!begin_date) {
          return false;
        }
        const regex = /(\d{4})年(\d{2})月(\d{2})日/;
        const matches = begin_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return isSameDay(new Date(year, month - 1, day), workingDay);
        }
        return false;
      });
    }

    setDataSize(items.length);

    /**
     * Newlyが存在したら親コンポーネントに通知
     */
    // feat screen 04-01-01 notification on mobile start
    if (!isMobile) {
      setHasNewlyItem(items.some((element) => element.newly === NewlyStatus.NEWLY));
    }
    // feat screen 04-01-01 notification on mobile end

    if (sort) {
      if (sort.key === 'offer_number') {
        items = orderBy(items, 'offer_number', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    // feat screen 04-01-01-01 start
    const resultList = items
      .map((item) => ({
        uniqueKey: `${item?.id}_${Math.random()}`,
        backgroundColor: generateBgColor(item?.status),
        check: (
          <Checkbox
            checked={checkList.includes(Number(item?.id))}
            onChange={() => {
              handleCheck(item?.id);
            }}
          />
        ),
        eye: (
          <RelativeContainer>
            <Icon
              // feat screen 04-01-01-01 start
              size={isMobile ? 20 : 24}
              // feat screen 04-01-01-01 end
              name="eye"
              onClick={() =>
                handleOnClick(
                  `${location.pathname}/${item?.id}`,
                  item?.id,
                  item?.tpmem_id,
                  item?.status,
                  item?.main_count,
                  item?.sub_count,
                  item?.normal_count
                )
              }
            />
            {item?.newly === NewlyStatus.NEWLY && (
              <Indicator>
                <Icon name="indicatorRed" size={8} />
              </Indicator>
            )}
          </RelativeContainer>
        ),
        status: generateChip(item?.status),
        offer_number: item?.offer_number,
        title: generateTitle(item?.title, item?.auto_decide),
        entry_count: item?.entry_count,
        post_begin: item?.post_begin,
        post_end: item?.post_end,
        begin_date: item?.begin_date,
        end_date: item?.end_date,
      }))
      .slice(limit * (page - 1), limit * page);
    setListItem(resultList);
    return resultList;
    // feat screen 04-01-01-01 end
  }, [
    sort,
    page,
    limit,
    handleOnClick,
    location.pathname,
    data,
    loading,
    searchConditions,
    statusSelect,
    workingDay,
    setHasNewlyItem,
    // feat screen 04-01-01-01 start
    handleCheck,
    isMobile,
    checkList,
    // feat screen 04-01-01-01 end
  ]);

  // feat screen 04-01-01-01 start
  const displayModal = () => {
    switch (modalStep) {
      case ModalStep.CONFIRM_DELETE:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: 'unset',
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                削除
              </Text>
            </Box>
          ),
          content: (
            <Box display="flex" flexDirection="column">
              <Text variant="body14">選択募集を削除しますか？</Text>
              <Text variant="body14">この操作は取り消せません。</Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton width={96} variant="warnSecondary" onClick={() => handleClickDelete()}>
                削除
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.DELETE_SUCCESS:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: 'unset',
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                削除 完了
              </Text>
            </Box>
          ),
          content: (
            <Box display="flex" flexDirection="column">
              <Text variant="body14">選択募集の削除が完了しました。</Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={96} variant="secondary" onClick={() => clearEvent()}>
                閉じる
              </MainButton>
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  };

  const modalContent = displayModal();
  // feat screen 04-01-01-01 end

  /**
   * Render
   */
  // feat screen 04-01-01-01 start
  return !isMobile ? (
    <>
      <Box display="flex" flexWrap="wrap" alignItems="center" pa={16} gap={16}>
        <div>
          <Input
            name="publicationNo"
            onChange={(event) => setOfferNumber(event?.target.value || '')}
            placeholder="掲載No."
            value={offerNumber}
            width={160}
          />
        </div>
        <div>
          <Input
            name="publicationTitle"
            onChange={(event) => setOfferTitle(event?.target.value || '')}
            placeholder="掲載タイトル"
            value={offerTitle}
            width={160}
          />
        </div>
        <div>
          <MainButton
            variant="primary"
            width={80}
            icon="search"
            onClick={() => {
              // ページ初期化
              setPage(1);
              setSearchConditions({
                offerNumber,
                offerTitle,
              });
              // feat screen 04-01-01-01 start
              setCheckList([]);
              // feat screen 04-01-01-01 end
            }}
          >
            検索
          </MainButton>
        </div>
        <Divider option="vertical" length={24} />
        <div>
          <DatePicker
            selected={workingDay}
            onChange={(value) => {
              // ページ初期化
              setPage(1);
              setWorkingDay(value);
              // feat screen 04-01-01-01 start
              setCheckList([]);
              // feat screen 04-01-01-01 end
            }}
            placeholderText="バイト日"
            width={160}
          />
        </div>
        <div>
          <Select
            onChange={(value) => {
              setPage(1);
              setStatusSelect(value);
              // feat screen 04-01-01-01 start
              setCheckList([]);
              // feat screen 04-01-01-01 end
            }}
            options={[
              {
                label: '全て',
                value: '',
              },
              {
                label: '募集前',
                value: '1',
              },
              {
                label: '募集中',
                value: '2',
              },
              {
                label: '採用確定',
                value: '3',
              },
              {
                label: '募集終了',
                value: '4',
              },
              {
                label: '募集停止',
                value: '6',
              },
              {
                label: '強制停止',
                value: '7',
              },
            ]}
            placeholder="状態"
            value={statusSelect}
            width={120}
          />
        </div>
        <div>
          <MainButton
            variant="clear"
            width={44}
            onClick={() => {
              setOfferNumber('');
              setOfferTitle('');
              setStatusSelect('');
              setWorkingDay(null);
              setSearchConditions(null);
              setPage(1);
              // feat screen 04-01-01-01 start
              setCheckList([]);
              // feat screen 04-01-01-01 end
            }}
          >
            クリア
          </MainButton>
        </div>
      </Box>
      <Divider option="horizontal" />
      {loading ? (
        <Loading />
      ) : (
        <Box flex="auto" py={0} px={24}>
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
            // feat screen 04-01-01-01 start
            checkList={checkList}
            onClickDelete={onClickDelete}
            // feat screen 04-01-01-01 end
          >
            <ScrollWrapper bottom={380}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                width={1584}
                rowWidth={1584}
              />
              {!!checkList?.length && <Box minHeight={26}>{null}</Box>}
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      )}
      {/* feat screen 04-01-01-01 start */}
      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== 0}
        onClose={() => {}}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
      {/* feat screen 04-01-01-01 end */}
    </>
  ) : (
    // Responsive Component
    <>
      <Box display={isShowFilter ? 'flex' : 'none'} flexWrap="wrap" alignItems="center" px={16} pt={14} gap={12}>
        <Box display="flex" width="100%" gap={12}>
          <div style={{ width: '100%' }}>
            <Input
              name="publicationNo"
              onChange={(event) => setOfferNumber(event?.target.value || '')}
              placeholder="掲載No."
              value={offerNumber}
              fullWidth
            />
          </div>
          <div style={{ width: '100%' }}>
            <Input
              name="publicationTitle"
              onChange={(event) => setOfferTitle(event?.target.value || '')}
              placeholder="掲載タイトル"
              value={offerTitle}
              fullWidth
            />
          </div>
          <div>
            <MainButton
              variant="primary"
              customHeight={40}
              width={80}
              icon="search"
              onClick={() => {
                // ページ初期化
                setPage(1);
                setSearchConditions({
                  offerNumber,
                  offerTitle,
                });
                setCheckList([]);
              }}
            >
              検索
            </MainButton>
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" gap={12}>
          <DatePicker
            selected={workingDay}
            onChange={(value) => {
              // ページ初期化
              setPage(1);
              setWorkingDay(value);
              setCheckList([]);
            }}
            placeholderText="バイト日"
          />
          <Box width="100%">
            <Select
              onChange={(value) => {
                setPage(1);
                setStatusSelect(value);
                setCheckList([]);
              }}
              options={[
                {
                  label: '全て',
                  value: '',
                },
                {
                  label: '募集前',
                  value: '1',
                },
                {
                  label: '募集中',
                  value: '2',
                },
                {
                  label: '採用確定',
                  value: '3',
                },
                {
                  label: '募集終了',
                  value: '4',
                },
                {
                  label: '募集停止',
                  value: '6',
                },
                {
                  label: '強制停止',
                  value: '7',
                },
              ]}
              placeholder="状態"
              value={statusSelect}
              fullWidth
              height={40}
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" justifyContent="flex-end" mt={-10} mb={6}>
          <MainButton
            variant="clear"
            width={45}
            customHeight={40}
            onClick={() => {
              setOfferNumber('');
              setOfferTitle('');
              setStatusSelect('');
              setWorkingDay(null);
              setSearchConditions(null);
              setPage(1);
              setCheckList([]);
            }}
          >
            クリア
          </MainButton>
        </Box>
      </Box>
      <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      {loading ? (
        <Loading />
      ) : (
        <Box flex="auto">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
            // feat screen 04-01-01-01 start
            checkList={checkList}
            onClickDelete={onClickDelete}
            // feat screen 04-01-01-01 end
          >
            <ScrollWrapper bottom={isShowFilter ? 443 : 319} bottomSE={319}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={48}
                width="100%"
                rowWidth="100%"
              />
              {!!checkList?.length && <Box minHeight={49}>{null}</Box>}
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      )}
      {/* feat screen 04-01-01-01 start */}
      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== 0}
        onClose={() => {}}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
      {/* feat screen 04-01-01-01 end */}
    </>
  );
  // feat screen 04-01-01-01 end
}
