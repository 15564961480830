import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import React from 'react';
import { useAuth } from 'hooks/authProvider';
import {
  useRequestTMemberEmailByCodeCognitoMutation,
  useUpdateTMemberEmailByIdCognitoMutation,
} from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import { MemberStoreStatus, GraphQLErrorCode } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import EditEmailModal from './modals/accountManagement/EditEmailModal';
import ConfirmCodeModal from './modals/accountManagement/ConfirmCodeModal';
import EmailChangedModal from './modals/accountManagement/EmailChangedModal';
import EditPasswordModal from './modals/accountManagement/EditPasswordModal';
import PasswordChangedModal from './modals/accountManagement/PasswordChangedModal';

enum ModalTypes {
  EDIT_EMAIL = 'EDIT_EMAIL',
  CONFIRM_CODE = 'CONFIRM_CODE',
  EMAIl_CHANGED = 'EMAIL_CHANGED',
  EDIT_PASSWORD = 'EDIT_PASSWORD',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
}

function AccountManagement({ tpmemId, email, refetch }: { tpmemId: number; email: string; refetch: () => void }) {
  const [activeModal, setActiveModal] = React.useState<ModalTypes | null>(null);
  const [newEmail, setNewEmail] = React.useState<string>('');
  const [errorMessages, setErrorMessages] = React.useState<string>('');
  const auth = useAuth();
  const { openErrorModal } = useErrorModal();
  const memberStoreStatus = useMemberStoreStatus();

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const [requestTMemberEmailByCodeCognitoMutation] = useRequestTMemberEmailByCodeCognitoMutation();
  const [updateTMemberEmailByIdCognitoMutation] = useUpdateTMemberEmailByIdCognitoMutation();

  const reSendConfirmationCode = async () => {
    try {
      await auth.reSendcurrentConfirmationCode();
    } catch {
      openErrorModal({ message: 'エラーが発生しました' });
    }
  };

  return (
    <>
    {
      !isMobile ? (
        <Box px={16} py={24} width="100%">
          <Box mb={16} display="flex" justifyContent="space-between">
            <Text variant="h2" color="darkBlue">
              アカウント管理
            </Text>
          </Box>
          <Box>
            <Divider option="horizontal" />
            <Box display="flex" gap={16} flexDirection="row" alignItems="center" height={48}>
              <Box width={160}>
                <Text variant="body14">メールアドレス(ID)</Text>
              </Box>
              <Box width="calc(100% - 320px)">
                <Text variant="body14">{email}</Text>
              </Box>
              <Box width={160}>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <MainButton
                    icon="edit"
                    iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                    variant="secondary"
                    thin
                    onClick={() => setActiveModal(ModalTypes.EDIT_EMAIL)}
                  >
                    編集
                  </MainButton>
                )}
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box
              display="flex"
              gap={16}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              height={48}
            >
              <Box width={160}>
                <Text variant="body14">パスワード</Text>
              </Box>
              <Box width={160}>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <MainButton
                    icon="edit"
                    iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                    variant="secondary"
                    thin
                    onClick={() => setActiveModal(ModalTypes.EDIT_PASSWORD)}
                  >
                    編集
                  </MainButton>
                )}
              </Box>
            </Box>
            <Divider option="horizontal" />
          </Box>
        </Box>
      ) : (
        // feat_screen_09-03-01_start
        <Box px={16} pt={16} pb={24} width="100%" height="calc(100svh - 338px)" overflow="auto">
          <Box mb={15} display="flex" justifyContent="center">
            <Text variant="h2" color="darkBlue">
              アカウント管理
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" gap={15}>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box display="flex" flexDirection="column" gap={4}>
                <Text display="block" variant="caption12">メールアドレス(ID)</Text>
                <Text display="block" variant="caption12">{email}</Text>
              </Box>
              <Box>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <MainButton
                    icon="edit"
                    iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                    iconSize={20}                    
                    variant="secondary"
                    thin={!isMobile}
                    width={80}
                    onClick={() => setActiveModal(ModalTypes.EDIT_EMAIL)}
                  >
                    編集
                  </MainButton>
                )}
              </Box>
            </Box>
            
            <Divider option="horizontal" />
            <Box
              display="flex"
              gap={15}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text display="block"  variant="caption12">パスワード</Text>
              <Box>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <MainButton
                    icon="edit"
                    iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                    iconSize={20}
                    width={80}
                    variant="secondary"
                    thin={!isMobile}
                    onClick={() => setActiveModal(ModalTypes.EDIT_PASSWORD)}
                  >
                    編集
                  </MainButton>
                )}
              </Box>
            </Box>
            <Divider option="horizontal" />
          </Box>
        </Box>
        // feat_screen_09-03-01_end
      )
    }

      <EditEmailModal
        isOpen={activeModal === ModalTypes.EDIT_EMAIL}
        onClose={() => setActiveModal(null)}
        onSubmit={(data) => {
          try {
            requestTMemberEmailByCodeCognitoMutation({
              variables: {
                new_email: data.newEmail,
              },
            })
              .then((result) => {
                if (result.errors && result.errors.length > 0) {
                  openErrorModal({ message: 'メールアドレスの更新に失敗しました' });
                } else {
                  const errorCode = result.data?.requestTMemberEmailByCodeCognito.ErrorCode;
                  if (errorCode != null) {
                    if (errorCode < 0) {
                      switch (errorCode) {
                        case GraphQLErrorCode.SQL_ERROR:
                          openErrorModal({ message: 'SQLエラー' });
                          break;
                        case GraphQLErrorCode.COGNITOADD_ERROR:
                          openErrorModal({ message: 'cognito登録エラー' });
                          break;
                        case GraphQLErrorCode.ADDRESS_REGISTERED_ERROR:
                          openErrorModal({ message: 'メールアドレス重複エラー' });
                          break;
                        default:
                          openErrorModal({ message: 'メールアドレスの更新に失敗しました' });
                          break;
                      }
                    } else {
                      setNewEmail(data.newEmail);
                      setActiveModal(ModalTypes.CONFIRM_CODE);
                    }
                  } else {
                    openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
                  }
                }
              })
              .catch(() => {
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          } catch {
            openErrorModal({ message: 'メールアドレスの更新に失敗しました' });
          } finally {
            setActiveModal(null);
          }
        }}
        currentEmail={email}
      />
      <ConfirmCodeModal
        isOpen={activeModal === ModalTypes.CONFIRM_CODE}
        onClose={() => {
          setActiveModal(null);
          setErrorMessages('');
        }}
        errorMessages={errorMessages}
        onSubmit={(data) => {
          try {
            updateTMemberEmailByIdCognitoMutation({
              variables: {
                confirmation_code: data.code,
                new_email: newEmail,
                id: tpmemId,
              },
            })
              .then((result) => {
                if (result.errors && result.errors.length > 0) {
                  openErrorModal({ message: '認証に失敗しました' });
                } else {
                  const errorCode = result.data?.updateTMemberEmailByIdCognito.ErrorCode;
                  if (errorCode != null) {
                    if (errorCode < 0) {
                      switch (errorCode) {
                        case GraphQLErrorCode.SQL_ERROR:
                          openErrorModal({ message: 'SQLエラー' });
                          break;
                        case GraphQLErrorCode.COGNITOADD_ERROR:
                          openErrorModal({ message: 'cognito登録エラー' });
                          break;
                        case GraphQLErrorCode.ADDRESS_REGISTERED_ERROR:
                          openErrorModal({ message: 'メールアドレス重複エラー' });
                          break;
                        case GraphQLErrorCode.CONFIRMATION_CODE_ERROR:
                          openErrorModal({ message: '確認コード誤りエラー' });
                          break;
                        case GraphQLErrorCode.CONFIRMATION_CODE_EXPIRED_ERROR:
                          openErrorModal({ message: '確認コード期限切れエラー' });
                          break;
                        case GraphQLErrorCode.CONFIRMATION_CODE_EXCESS_ERROR:
                          openErrorModal({ message: '確認コード誤り回数超過エラー' });
                          break;
                        default:
                          openErrorModal({ message: '認証に失敗しました' });
                          break;
                      }
                    } else {
                      refetch();
                      setActiveModal(ModalTypes.EMAIl_CHANGED);
                    }
                  } else {
                    openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
                  }
                }
              })
              .catch(() => {
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          } catch {
            openErrorModal({ message: '認証に失敗しました' });
          } finally {
            setActiveModal(null);
          }
        }}
        newEmail={newEmail}
        toBack={() => {
          reSendConfirmationCode();
        }}
      />
      <EmailChangedModal isOpen={activeModal === ModalTypes.EMAIl_CHANGED} onClose={() => setActiveModal(null)} />
      <EditPasswordModal
        isOpen={activeModal === ModalTypes.EDIT_PASSWORD}
        onClose={() => {
          setActiveModal(null);
          setErrorMessages('');
        }}
        errorMessages={errorMessages}
        onSubmit={(data) => {
          auth
            .changePassword(data.currentPassword, data.newPassword)
            .then(() => {
              setActiveModal(ModalTypes.PASSWORD_CHANGED);
            })
            .catch(() => {
              setErrorMessages('現在のパスワードが正しくありません。');
            });
        }}
      />
      <PasswordChangedModal isOpen={activeModal === ModalTypes.PASSWORD_CHANGED} onClose={() => setActiveModal(null)} />
    </>
  );
}

export default AccountManagement;
