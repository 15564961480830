import LocalModal from './Modal';

/**
 * Figma ID
 * 06-03-02
 * 承認完了
 */

interface Props {
  step: number;
  onModalClose: () => void;
  onClickSubmit: () => void;
}

export function ApprovalCompletedModal({ step, onClickSubmit, onModalClose }: Props) {
  return LocalModal({
    title: '承認  完了',
    contentText: 'ユーザーの所属店舗登録申請を承認しました。',
    step,
    onClickSubmit,
    onModalClose,
  });
}
