/**
 * Figma ID: 01-01-08
 * 名称: パスワードの設定
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { emailPattern, passwordPattern, passwordLength } from 'components/const';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { styled } from '@linaria/react';
import { ContentTitle } from './component/ContentTitle';

export type SetPasswordControlModel = {
  mailAddress: string;
  confirmMailAddress: string;
  password: string;
  confirmPassword: string;
};

type Props = {
  toNext: (args: { email: string; password: string }) => void;
  toBack: () => void;
};

// feat screen 02-01-01 fix bug B-Travel 164 start
const StyledInputPassWord = styled.div`
  input {
    padding-right: 40px;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
  }
`;
// feat screen 02-01-01 fix bug B-Travel 164 end

export function InputMailAndPassword(props: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { toNext, toBack } = props;

  const passwordRegExp = /^(?=.*[A-Z])(?=.*[^$*.\\[\]{}()?&quot;!@#%&/\\,&gt;&lt;&prime;:;|_~`+=])[a-zA-Z0-9.?/-]/;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SetPasswordControlModel>({
    reValidateMode: 'onChange',
  });

  const mailAddressWatch = watch('mailAddress');
  const passwordWatch = watch('password');

  const toNextPhase = handleSubmit((data) => {
    toNext({
      email: data.mailAddress,
      password: data.password,
    });
  });

  // feat screen_01-01-08_start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="新規登録 - パスワード設定" />

      <Box>
        <Text variant="body14">ログイン用のメールアドレスとパスワードを設定してください。</Text>
      </Box>
      <Box display="flex" width="100%" justifyContent="center">
        <Box width={328}>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>メールアドレス</InputLabel>
            <RhfInput
              name="mailAddress"
              control={control}
              placeholder="メールアドレス"
              rules={{
                required: 'メールアドレスを入力してください。',
                pattern: {
                  value: emailPattern,
                  message: '入力形式がメールアドレスではありません。',
                },
              }}
            />
            {errors.mailAddress?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.mailAddress.message}
              </Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>メールアドレス（確認）</InputLabel>
            <RhfInput
              name="confirmMailAddress"
              control={control}
              fullWidth
              defaultValue=""
              placeholder="メールアドレス（確認）"
              rules={{
                required: 'メールアドレスを入力してください。',
                validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
              }}
            />
            {errors.confirmMailAddress?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.confirmMailAddress.message}
              </Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>パスワード</InputLabel>
            <RhfInput
              name="password"
              control={control}
              type="password"
              placeholder="パスワード"
              rules={{
                required: 'パスワードを入力してください。',
                validate: (value) =>
                  passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
                minLength: { value: passwordLength, message: '8文字以上で入力してください' },
              }}
            />
            {errors.password?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.password.message}
              </Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>パスワード（確認）</InputLabel>
            <RhfInput
              name="confirmPassword"
              control={control}
              type="password"
              placeholder="パスワード（確認）"
              rules={{
                required: 'パスワードを入力してください。',
                validate: (value) => value === passwordWatch || 'パスワードが一致しません。',
              }}
            />
            {errors.confirmPassword?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.confirmPassword.message}
              </Text>
            )}
          </Box>
          <Box width={300} pt={26}>
            <Text color="cautionRed" variant="caption12">
              パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
              &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
            </Text>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap={5} justifyContent="center">
        <MainButton variant="secondary" onClick={() => toBack()}>
          戻る
        </MainButton>
        <MainButton
          onClick={() => {
            toNextPhase();
          }}
        >
          送信
        </MainButton>
      </Box>
    </Box>
  ) : (
    // Mobile Responsive
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="新規登録 - パスワード設定" />

      <Box mt={16}>
        <Text variant="body14">ログイン用のメールアドレスとパスワードを設定してください。</Text>
      </Box>

      <Box mt={20}>
        <Box display="flex" flexDirection="column" gap={16}>
          <Box display="flex" flexDirection="column" gap={2} >
            <InputLabel>メールアドレス</InputLabel>
            <RhfInput
              fullWidth
              name="mailAddress"
              control={control}
              placeholder="メールアドレス"
              rules={{
                required: 'メールアドレスを入力してください。',
                pattern: {
                  value: emailPattern,
                  message: '入力形式がメールアドレスではありません。',
                },
              }}
            />
            {errors.mailAddress?.message && (
              <Box mt={-4}>
                <Text variant="caption12" color="cautionRed">
                  {errors.mailAddress.message}
                </Text>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <InputLabel>メールアドレス（確認）</InputLabel>
            <RhfInput
              name="confirmMailAddress"
              control={control}
              fullWidth
              defaultValue=""
              placeholder="メールアドレス（確認）"
              rules={{
                required: 'メールアドレスを入力してください。',
                validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
              }}
            />
            {errors.confirmMailAddress?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.confirmMailAddress.message}
              </Text>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <InputLabel>パスワード</InputLabel>
            <StyledInputPassWord>
              <RhfInput
                fullWidth
                name="password"
                control={control}
                type="password"
                placeholder="パスワード"
                rules={{
                    required: 'パスワードを入力してください。',
                    validate: (value) =>
                        passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
                    minLength: { value: passwordLength, message: '8文字以上で入力してください' },
                }}
              />
              {errors.password?.message && (
                <Box mt={-4}>
                  <Text variant="caption12" color="cautionRed">
                    {errors.password.message}
                  </Text>
                </Box>
              )}
            </StyledInputPassWord>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <InputLabel>パスワード（確認）</InputLabel>
            <StyledInputPassWord>
              <RhfInput
                fullWidth
                name="confirmPassword"
                control={control}
                type="password"
                placeholder="パスワード（確認）"
                rules={{
                  required: 'パスワードを入力してください。',
                  validate: (value) => value === passwordWatch || 'パスワードが一致しません。',
                }}
              />
              {errors.confirmPassword?.message && (
                <Box mt={-4}>
                  <Text variant="caption12" color="cautionRed">
                    {errors.confirmPassword.message}
                  </Text>
                </Box>
              )}
            </StyledInputPassWord>
          </Box>
        </Box>
      </Box>

      <Box mt={6} mb={-2}>
        <Text display="block" color="cautionRed" variant="caption12">
          パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
          &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
        </Text>
      </Box>
      <Box display="flex" py={24} justifyContent="space-between" gap={5}>
        <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
          戻る
        </MainButton>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            toNextPhase();
          }}
        >
          送信
        </MainButton>
      </Box>
    </Box>
  );
  // feat screen_01-01-08_end
}
