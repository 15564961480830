import React from 'react';
import { styled } from '@linaria/react';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  length?: number;
  option: 'vertical' | 'horizontal';
}

const StyledVertical = styled.div<{
  height: number;
}>`
  width: 1px;
  height: ${({ height }) => (height === 0 ? '100%' : `${height}px`)};
  background: ${GENERIC_COLOR.SUPERLITE_GRAY};
`;

const StyledHorizontal = styled.div<{
  width: number;
}>`
  width: ${({ width }) => (width === 0 ? '100%' : `${width}px`)};
  height: 1px;
  /* feat_common_styled_divider_start */
  @media only screen and (max-width: 768px) {
    min-height: 1px;
  }
  /* feat_common_styled_divider_end */
  background: ${GENERIC_COLOR.SUPERLITE_GRAY};
`;

export function Divider({ length = 0, option }: Props) {
  return option === 'vertical' ? <StyledVertical height={length} /> : <StyledHorizontal width={length} />;
}
