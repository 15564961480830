import React, { createContext, useContext, useEffect, useState } from 'react';
import { MemberStoreStatus } from 'components/const';
import { useGetTMemberStatusByIdLazyQuery } from 'graphql/graphql-mw';
import { useAuth } from './authProvider';

enum NotInitializedMemberStoreStatus {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
}

type ExtendedMemberStoreStatus = MemberStoreStatus | NotInitializedMemberStoreStatus;

const memberStoreStatusContext = createContext<ExtendedMemberStoreStatus>(
  NotInitializedMemberStoreStatus.NOT_INITIALIZED
);

function useCurrentMemberStoreStatus() {
  const defaultPollingInterval = 1800000;
  const [getMemberStoreStatus] = useGetTMemberStatusByIdLazyQuery({
    pollInterval: Number(process.env.REACT_APP_USER_STATUS_POLLING_INTERVAL) || defaultPollingInterval,
  });
  const [currentStatus, setCurrentStatus] = useState<ExtendedMemberStoreStatus>(
    NotInitializedMemberStoreStatus.NOT_INITIALIZED
  );
  const { tpmemId } = useAuth();

  useEffect(() => {
    if (!tpmemId) {
      return;
    }

    getMemberStoreStatus({ variables: { id: tpmemId } }).then((result) => {
      const status = result.data?.getTMemberStatusById?.status;

      if (typeof status === 'number') {
        setCurrentStatus(status);
      }
    });
  }, [tpmemId, getMemberStoreStatus]);

  return currentStatus;
}

export function MemberStoreStatusProvider({ children }: { children: React.ReactNode }) {
  const memberStoreStatus = useCurrentMemberStoreStatus();

  return <memberStoreStatusContext.Provider value={memberStoreStatus}>{children}</memberStoreStatusContext.Provider>;
}

export const useMemberStoreStatus = () => useContext(memberStoreStatusContext);
