import React from 'react';
import { Box } from 'components/box';
import useIsMobile from 'hooks/responsive/useIsMobile';

const mainHeaderHeight = 48;
const mainLayoutPadding = 24;

/* feat_common_reponsive_var_main_content_start */
const mainHeaderMobile = 64;
const mainHeaderTitleHeight = 50;
const mainMarginBottom = 24;
const mainMarginBottomMobile = 78;
const mainGapContentHeight = 16;
export const mainContentHeaderMobileHeight = mainHeaderMobile + mainHeaderTitleHeight + mainMarginBottom + mainGapContentHeight;
/* feat_common_reponsive_var_main_content_end */

export function MainContentWrapper({ children }: { children: React.ReactNode }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile? (
    <Box height={`calc(100vh - ${mainHeaderHeight + mainLayoutPadding * 2}px)`}>{children}</Box>
  ): (
    /* feat_common_reponsive_main_content_start */
    <Box height={`calc(100svh - ${mainHeaderMobile + mainGapContentHeight + mainHeaderTitleHeight + mainMarginBottomMobile}px)`}>{children}</Box>
    /* feat_common_reponsive_main_content_end */
  );
}
