import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Store } from '../../types';

/**
 * Figma ID
 * 09-04-04
 * グループ招待確認モーダル
 */

function ConfirmInvitationModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onBack: () => void;
  onClickSubmit: () => void;
  selectedGroupStores: Store[];
}) {
  const { isOpen, onModalClose, onBack, onClickSubmit, selectedGroupStores } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        グループ招待 確認
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box>
          <Text variant="body14" color="black">
            以下の店舗にグループ招待を行います。
          </Text>
        </Box>
        <Box mx={24} my={16}>
          <ul>
            {selectedGroupStores.map((store) => (
              <li key={`store-${store.id}`}>
                <Text variant="body14">{store.name}</Text>
              </li>
            ))}
          </ul>
        </Box>
        <Box>
          <Text variant="body14" color="black">
            よろしいですか？
          </Text>
        </Box>
      </Box>
    ) : (
      // feat_screen_09-04-04_start
      <Box display="flex" flexDirection="column" gap={12}>
        <Text variant="body14" color="black">
          以下の店舗にグループ招待を行います。
        </Text>
        <Box mx={20}>
          <ul>
            {selectedGroupStores.map((store) => (
              <li key={`store-${store.id}`}>
                <Text variant="body14">{store.name}</Text>
              </li>
            ))}
          </ul>
        </Box>
        <Text variant="body14" color="black">
          よろしいですか？
        </Text>
      </Box>
       // feat_screen_09-04-04_start
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_09-04-04_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile} 
          // feat_screen_09-04-04_end
          variant="secondary" 
          onClick={() => onModalClose()}
          >
          キャンセル
        </MainButton>
        <MainButton 
          // feat_screen_09-04-04_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile} 
          // feat_screen_09-04-04_end
          variant="secondary" 
          onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton 
          // feat_screen_09-04-04_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile} 
          // feat_screen_09-04-04_end
          onClick={() => onClickSubmit()}>
          招待
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      // feat_screen_09-04-04_start
      height={!isMobile ? 300 : "auto"}
      width={!isMobile ? 384 : "100%"}
      // feat_screen_09-04-04_end
    />
  );
}

export default ConfirmInvitationModal;
