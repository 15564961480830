import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';

type Props = {
  title: string;
};
export function ContentTitle(props: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { title } = props;
  return (
    // feat_screen_01_01_01_start
    <Box width="100%" display="flex" flexDirection="column" gap={isMobile ? 13 : 4}>
      <Text variant="body16" bold color="primaryBlue">
        {title}
      </Text>
      <Box width="100%" height={1} backgroundColor="primaryBlue">
        {' '}
      </Box>
    </Box>
    // feat_screen_01_01_01_end
  );
}
