/* feat_bottom_tab_responsive_mobile_start */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@linaria/react';
import { MainMenu, AccordionType } from 'components/mainMenuBar/types';
import { Icon } from 'components/icon';
import { matchPath, useNavigate } from 'react-router-dom';

export type Props = {
  currentRoute: string;
  mainMenuBarData: MainMenu[];
  isOpenMainMenuBar: boolean;
};

const StyledOpenMenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #f8feff;
  box-shadow: #00000075 0px 4px 10px;
  display: flex;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  transition: linear 0.2s;
  z-index: 4;
`;

const StyledIconIndicator = styled.div`
  position: absolute;
  left: 1px;
  top: -4px;
`;

const StyledIconIndicatorChild = styled.div`
  position: absolute;
  left: 5px;
  top: 8px;
`;

const StyledOpenMenu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 3px;
  padding: 2px 0;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StyledIconWrapper = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#16495F' : 'transparent')};
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDropdownMenu = styled.div<{ isFirst: boolean; isLast: boolean }>`
  position: absolute;
  background-color: #ffffff;
  width: 140px;
  border-radius: 8px;
  bottom: 50px;
  box-shadow: #00000047 0px 4px 8px;
  z-index: 9999;
  left: ${({ isFirst, isLast }) => {
    if (isFirst) {
      return '-2px';
    }
    if (isLast) {
      return '-102px';
    }
    return 'unset';
  }};
`;

const StyledDropdownList = styled.ul`
  list-style: none;
  border-radius: 8px 8px 0 0;
  li:first-child {
    border-radius: 8px 8px 0 0;
  }
  li:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const StyledDropdownItem = styled.li<{ active: boolean; isFirst: boolean; isLast: boolean; isLastChild: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  padding-left: 18px;
  justify-content: flex-start;
  background-color: ${({ active }) => (active ? '#00A5BF' : '#ffffff')};
  color: ${({ active }) => (active ? '#ffffff' : '#16495f')};
  font-size: 14px;
  &::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -8px;
    left: ${({ isFirst, isLast }) => {
      if (isFirst) {
        return '13%';
      }
      if (isLast) {
        return '88%';
      }
      return '50%';
    }};
    margin-left: -10px;
    border-style: solid;
    border-width: ${({ isLastChild }) => (isLastChild ? '10px 10px 0px 10px' : '0')};
    border-color: ${({ active }) =>
      active ? '#00a5bf transparent transparent transparent' : '#ffffff transparent transparent transparent'};
  }
  &:hover {
    svg {
      fill: #ffffff !important;
      path {
        fill: #ffffff !important;
      }
    }
  }
`;

const StyledDropdownItemTitle = styled.p`
  margin: 0;
`;

const StyledMenuTitle = styled.p`
  font-size: 9px;
  margin: 0;
  padding: 2px 0;
  color: #008599;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const checkMatchPath = (route: string, currentRoute: string) => {
  const currentPath = currentRoute.split('/')[1] || '';
  return `/${currentPath}` === route;
};

export function Tab({ currentRoute, mainMenuBarData, isOpenMainMenuBar }: Props) {
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const isRowActive = (matchedRoutes: string[]) =>
    Boolean(matchedRoutes.find((route) => matchPath(route, currentRoute) || checkMatchPath(route, currentRoute)));
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (link?: string) => {
      if (link) {
        navigate(link);
      }
    },
    [navigate]
  );
  const [mainMenuBarDataConverted, setMainMenuBarDataConverted] = useState<MainMenu[]>([]);
  const [indexShowDropdown, setIndexShowDropdown] = useState<number>(-1);
  const [previousRef, setPreviousRef] = useState<HTMLElement>();
  useEffect(() => {
    if (!mainMenuBarData.length) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const mainMenuBarDataClone: MainMenu[] = JSON.parse(JSON.stringify([...mainMenuBarData]));
    if (mainMenuBarData.length === 2) {
      setMainMenuBarDataConverted(mainMenuBarDataClone.slice(0, 1));
      return;
    }
    const mainMenuExcluded = mainMenuBarDataClone.slice(0, 5);

    const homeAccordionList: AccordionType[] = [
      {
        accordionTitle: '通常ホーム',
        accordionLink: '/',
        matchedRoutes: ['/'],
        highlighted: mainMenuBarDataClone[0].highlighted,
      },
      {
        accordionTitle: 'かんたんホーム',
        accordionLink: '/s00',
        matchedRoutes: ['/s00'],
      },
    ];

    const settingAccordionListS08: AccordionType[] = [
      {
        accordionTitle: 'ランク別育成料',
        accordionLink: '/s08/0',
        matchedRoutes: ['/s08/0'],
      },
      {
        accordionTitle: '労働者名簿',
        accordionLink: '/s08/1',
        matchedRoutes: ['/s08/1'],
      },
      {
        accordionTitle: '労働条件通知書',
        accordionLink: '/s08/2',
        matchedRoutes: ['/s08/2'],
      },
      {
        accordionTitle: '募集画像',
        accordionLink: '/s08/3',
        matchedRoutes: ['/s08/3'],
      },
      {
        accordionTitle: '添付ファイル',
        accordionLink: '/s08/4',
        matchedRoutes: ['/s08/4'],
      },
      {
        accordionTitle: 'マニュアル',
        accordionLink: '/s08/5',
        matchedRoutes: ['/s08/5'],
      },
      {
        accordionTitle: '店舗ブロック',
        accordionLink: '/s08/6',
        matchedRoutes: ['/s08/6'],
      },
    ];

    const settingAccordionListS0702 = [
      {
        accordionTitle: '受入費用',
        accordionLink: '/s07-02/0',
        matchedRoutes: ['/s07-02/0'],
      },
      {
        accordionTitle: '育成料明細',
        accordionLink: '/s07-02/1',
        matchedRoutes: ['/s07-02/1'],
      },
      {
        accordionTitle: '源泉徴収(即日)',
        accordionLink: '/s07-02/2',
        matchedRoutes: ['/s07-02/2'],
      },
      {
        accordionTitle: '源泉徴収(月次)',
        accordionLink: '/s07-02/3',
        matchedRoutes: ['/s07-02/3'],
      },
    ];

    mainMenuExcluded[2].accordionList = mainMenuExcluded[2].accordionList
      ?.slice(0, mainMenuExcluded[2].accordionList.length - 1)
      .concat(settingAccordionListS0702);

    mainMenuExcluded[0].accordionList = homeAccordionList;
    mainMenuExcluded[4].matchedRoutes = ['/s08/*'];
    mainMenuExcluded[4].accordionList = settingAccordionListS08;

    const mainMenu: MainMenu[] = mainMenuExcluded;

    setMainMenuBarDataConverted(mainMenu);
  }, [mainMenuBarData]);

  /**
   * handle show dropdown when focus
   */
  const onShowDropdown = useCallback(
    (e: React.MouseEvent<HTMLElement>, index: number, link: string | undefined) => {
      if ((index === 3 && link) || mainMenuBarDataConverted.length === 1) {
        handleNavigate(link);
      }
      if (previousRef === e.currentTarget) {
        if (indexShowDropdown !== -1) {
          setIndexShowDropdown(-1);
          setPreviousRef(undefined);
          return;
        }
        setIndexShowDropdown(index);
        const targetElement = e.currentTarget as HTMLElement;
        if (targetElement instanceof HTMLDivElement) {
          setPreviousRef(targetElement);
        }
      }
      setIndexShowDropdown(index === indexShowDropdown ? -1 : index);
      const targetElement = e.currentTarget as HTMLElement;
      if (targetElement instanceof HTMLDivElement) {
        setPreviousRef(targetElement);
      }
    },
    [handleNavigate, indexShowDropdown, previousRef, mainMenuBarDataConverted]
  );

  /**
   * handle close menu dropdown when blur
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownMenuRef.current &&
        previousRef &&
        dropdownMenuRef.current instanceof HTMLElement &&
        !dropdownMenuRef.current.contains(event.target as Node) &&
        !previousRef.contains(event.target as Node)
      ) {
        setIndexShowDropdown(-1);
        setPreviousRef(undefined);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownMenuRef, previousRef]);

  return (
    <StyledOpenMenuWrapper>
      {mainMenuBarDataConverted.map((menubarItem: MainMenu, index: number) => (
        <StyledOpenMenu
          key={menubarItem.pageNo}
          onClick={(e: React.MouseEvent<HTMLElement>) => onShowDropdown(e, index, menubarItem.link)}
        >
          <StyledIconWrapper active={isRowActive(menubarItem.matchedRoutes)}>
            {/**
             * Render the sub menu
             */}
            {indexShowDropdown === index && menubarItem.accordionList && (
              <StyledDropdownMenu ref={dropdownMenuRef} isFirst={index === 0} isLast={index === 4}>
                <StyledDropdownList>
                  {menubarItem.accordionList.map((menu: AccordionType, indexChild: number, parent: AccordionType[]) => (
                    <StyledDropdownItem
                      key={menu.accordionTitle}
                      active={isRowActive(menu.matchedRoutes)}
                      onClick={() => handleNavigate(menu.accordionLink)}
                      isLastChild={!!(parent.length - 1 === indexChild)}
                      isFirst={index === 0}
                      isLast={index === 4}
                    >
                      {menu.highlighted && (
                        <StyledIconIndicatorChild>
                          <Icon name="indicatorRed" size={8} />
                        </StyledIconIndicatorChild>
                      )}
                      <StyledDropdownItemTitle>{menu.accordionTitle}</StyledDropdownItemTitle>
                    </StyledDropdownItem>
                  ))}
                </StyledDropdownList>
              </StyledDropdownMenu>
            )}
            {menubarItem.highlighted && (
              <StyledIconIndicator>
                <Icon name="indicatorRed" size={8} />
              </StyledIconIndicator>
            )}
            <Icon
              size={24}
              color={isRowActive(menubarItem.matchedRoutes) ? 'white' : '#008599'}
              name={menubarItem.icon.normal}
            />
          </StyledIconWrapper>
          <StyledMenuTitle>{menubarItem.title}</StyledMenuTitle>
        </StyledOpenMenu>
      ))}
    </StyledOpenMenuWrapper>
  );
}
/* feat_bottom_tab_responsive_mobile_end */
