/**
 * Figma ID: 04-01-03
 * 名称: バイト募集新規作成（モーダル）
 */

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from 'hooks/authProvider';
import { RadioGroup } from 'components/radioGroup';
import { Control, UseFormSetValue, FieldErrors, UseFormGetValues, Controller } from 'react-hook-form';
import { format, differenceInMilliseconds, subDays, subHours, addDays } from 'date-fns';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { RhfTextarea } from 'components/rhfTextarea';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { Box } from 'components/box';
import { ComboBox } from 'components/comboBox';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { ErrorMessage } from 'components/errorMessage';
import { DatePicker } from 'components/datePicker';
import { Hours, Every15Minutes, Score, HireFee, Rate, Before48Hours } from 'components/select/selectOptions';
import * as gql from 'graphql/graphql-mw';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { ImageModal } from 'components/newModal';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { useRank } from 'hooks/master/useRank';
import { useLocation } from 'react-router-dom';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { TextSet } from '../../[id]/components/textSet';
import { ScheduleType } from '../../common/scheduleTypeContext';
import {
  extractNumber,
  convertDaysToBinary,
  getSelectedDays,
  getDatesBetween,
  filterByDayOfWeek,
} from '../../common/utils';
import { FormData } from '../../common/formState';
import {
  useGetCheifConnenctApi,
  useGetTemplateDataConnenctApi,
  useGetTemplateDataByTpmemIdConnenctApi,
  useGetTemplateDraftDataConnenctApi,
} from '../../common/hooks';

enum RecruitmentStatus {
  BEFORE_RECRUITMENT = 1,
  WANTED = 2,
  OFFER_FINALIZED = 3,
  RECRUITMENT_CLOSED = 4,
  SUSPENSION_OF_RECRUITMENT = 6,
  FORCED_STOP = 7,
}

export enum AutoDecide {
  MANUAL = 0,
  AUTO = 1,
  COUNTEROFFER = 2,
}

export enum BreakTime {
  NONE = 0,
  ONE = 1,
}

export enum TransFee {
  NONE = 0,
  ONE = 1,
}

type Props = {
  offerData?: gql.VtArbeitOfferInfo | null;
  draftData?: gql.VtOfferDraftInfo | null;
  control?: Control<FormData>;
  setValue?: UseFormSetValue<FormData>;
  getValues?: UseFormGetValues<FormData>;
  errors?: FieldErrors<FormData>;
  status?: number;
  mode: 'create' | 'draft' | 'edit' | 'duplicate';
  isDraftClicked?: boolean;
  offerId?: number;
};

type TimeObject = {
  hour?: number;
  minute?: number;
  second?: number;
};

type WeekData = {
  date: string;
  dayOfWeek: string;
};

type PropState = {
  dateValue: Date;
}

export function ModalCreateNew({
  offerData,
  draftData,
  control,
  setValue,
  getValues,
  errors,
  status,
  mode,
  isDraftClicked,
  offerId,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;

  /**
   * [API] 当日責任者情報取得
   */
  const { data: cheifData, error: cheifError } = useGetCheifConnenctApi(Number(tpmemId));
  const dispChiefData = cheifData?.getTManagerByTpmemId as gql.Tmanager;

  /**
   * 必須エラーラベル
   */
  const requiredLabel = '必須項目です';

  /**
   * 下書きと編集でAPIスキーマが異なるので表示するデータを切り替える
   */
  const dispData = mode === 'draft' ? draftData : offerData;
  const adjustTransFee = mode === 'draft' ? draftData?.trans_fee : offerData?.trans_fee_val;
  const adjustScheduleType = mode === 'draft' ? draftData?.type : 1;
  const adjustWeekString: string = adjustScheduleType === ScheduleType.WEEKLY ? draftData?.w_week_bit || '' : '';
  const adjustOfferNumber = mode === 'draft' ? '' : offerData?.offer_number || '';
  const adjustImage1Flag = mode === 'draft' ? !!draftData?.image1_flag : !!offerData?.image1;
  const adjustImage2Flag = mode === 'draft' ? !!draftData?.image2_flag : !!offerData?.image2;
  const adjustImage3Flag = mode === 'draft' ? !!draftData?.image3_flag : !!offerData?.image3;
  const adjustImage4Flag = mode === 'draft' ? !!draftData?.image4_flag : !!offerData?.image4;
  const adjustImage5Flag = mode === 'draft' ? !!draftData?.image5_flag : !!offerData?.image5;

  /**
   * [API] テンプレート情報取得
   */
  // ※下書きの場合、バリテーション用のデータをOfferNumberで取得する。
  const { getData: getTemplateById, data: templateData } = useGetTemplateDataConnenctApi();
  const { getData: getTemplateByTpmemId, data: templateDataByTpmemId } = useGetTemplateDataByTpmemIdConnenctApi(
    Number(tpmemId)
  );
  /**
   * ［API］Query（下書き詳細取得）
   * ※ 親の画面で1度呼んでるが親から渡すのにはリスクが大きい為、再度バリテーション用にCALL
   */
  const { getData: getOfferId, data: templateDraftData } = useGetTemplateDraftDataConnenctApi();

  const templateMakeData =
    mode === 'create'
      ? (templateDataByTpmemId?.getVTArbeitOfferMakeInfoByTpmemId.make as gql.VtArbeitMakeInfo)
      : mode === 'draft'
      ? (templateDraftData?.getVTOfferDraftInfoByIdTypeTpmemId.make as gql.VtArbeitMakeInfo)
      : (templateData?.getVTArbeitOfferMakeInfoById.make as gql.VtArbeitMakeInfo);

  const templateOfferData = templateData?.getVTArbeitOfferMakeInfoById.offer as gql.VtArbeitOfferInfo;

  const image1Path = templateMakeData?.image1 || draftData?.image1 || offerData?.image1;
  const image2Path = templateMakeData?.image2 || draftData?.image2 || offerData?.image2;
  const image3Path = templateMakeData?.image3 || draftData?.image3 || offerData?.image3;
  const image4Path = templateMakeData?.image4 || draftData?.image4 || offerData?.image4;
  const image5Path = templateMakeData?.image5 || draftData?.image5 || offerData?.image5;

  const attachedFilePath = templateMakeData?.attached_file || draftData?.attached_file || offerData?.attached_file;
  const attachedFileName = templateMakeData?.attached_name || draftData?.attached_name || offerData?.attached_name;
  const attachmentFlag = offerData || draftData ? !!offerData?.attached_file || !!draftData?.attached_flag : true;

  const { openErrorModal } = useErrorModal();

  /**
   * RadioGroup（ScheduleType）
   * 型の変換があるため、親側のuseStateと子側のuseStateを併用
   * ※getOfferIdでエラーになるので位置を移動
   */
  const [scheduleType, setScheduleType] = useState<number>(adjustScheduleType || ScheduleType.DAILY);
  useEffect(() => {
    setScheduleType(adjustScheduleType || ScheduleType.DAILY);
  }, [adjustScheduleType, setScheduleType]);

  /**
   * テンプレート基本情報を取得
   */
  useEffect(() => {
    if (mode === 'create') {
      getTemplateByTpmemId()
        .then((result) => {
          if (result.error) {
            openErrorModal({ message: '募集情報の取得ができませんでした。', zIndex: ModalZindex.ERRMODALZINDEX });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    } else if (mode === 'draft') {
      const offerNumber = offerId || 0;
      getOfferId({
        variables: {
          id: offerNumber,
          tpmem_id: tpmemId || 0,
          type: scheduleType,
        },
      })
        .then((result) => {
          if (result.error) {
            openErrorModal({ message: '募集情報の取得ができませんでした。', zIndex: ModalZindex.ERRMODALZINDEX });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    } else {
      // バリテーションデータはOfferNomber
      const offerNumber = offerId || 0;

      getTemplateById({
        variables: {
          id: offerNumber,
        },
      })
        .then((result) => {
          if (result.error) {
            openErrorModal({ message: '募集情報の取得ができませんでした。', zIndex: ModalZindex.ERRMODALZINDEX });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    }
  }, [mode, tpmemId, offerId, getOfferId, scheduleType, getTemplateById, getTemplateByTpmemId, openErrorModal]);

  /**
   * エラー処理
   */
  useEffect(() => {
    if (cheifError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [cheifError, openErrorModal]);

  /**
   * 最低賃金
   */
  const minWage = templateMakeData?.min_wage || 0;

  /**
   * 業務詳細/働くための条件/注意事項/持ち物/アクセスのデータを切り替える
   */
  let adjustDescription: string | undefined;
  let adjustConditions: string | undefined;
  let adjustCaution: string | undefined;
  let adjustBelongings: string | undefined;
  let adjustAccess: string | undefined;
  if (mode === 'draft') {
    adjustDescription = draftData?.description || '';
    adjustConditions = draftData?.conditions || '';
    adjustCaution = draftData?.caution || '';
    adjustBelongings = draftData?.belongings || '';
    adjustAccess = draftData?.access || '';
  } else if (mode === 'edit' || mode === 'duplicate') {
    adjustDescription = offerData?.description || '';
    adjustConditions = offerData?.conditions || '';
    adjustCaution = offerData?.caution || '';
    adjustBelongings = offerData?.belongings || '';
    adjustAccess = offerData?.access || '';
  } else {
    adjustDescription = templateOfferData?.description || '';
    adjustConditions = templateOfferData?.conditions || '';
    adjustCaution = templateOfferData?.caution || '';
    adjustBelongings = templateOfferData?.belongings || '';
    adjustAccess = templateOfferData?.access || '';
  }

  /**
   * 画面表示モード
   * 新規フォーム画面もしくはフォーム表示データがある場合はTrue
   */
  const isCreateMode = dispData?.id !== undefined || mode === 'create';

  /**
   * 週定期の際に選択されている曜日を算出し配列に格納
   */
  const weekNumber = extractNumber(adjustWeekString);
  const selectedWeek = useMemo(() => {
    const binary = convertDaysToBinary(weekNumber);
    return getSelectedDays(binary);
  }, [weekNumber]);

  /**
   * 時間計算に使用する変数を生成
   * begin_time, end_time, post_begin_days, post_end_days, post_end_hoursを生成
   * mode === 'create'では動作しない
   */
  const genarateOfferDateTime = (
    begin_date?: string,
    end_date?: string,
    post_begin_date?: string,
    post_end_date?: string
  ) => {
    if (!begin_date || !end_date || !post_begin_date || !post_end_date) return null;
    const beginTime = format(new Date(begin_date), 'HH:mm');
    const endTime = format(new Date(end_date), 'HH:mm');

    const postBeginDate = new Date(post_begin_date);
    const postEndDate = new Date(post_end_date);
    const beginDate = new Date(begin_date);

    const postStart = differenceInMilliseconds(beginDate, postBeginDate);
    const postStartDiffInDays = Math.floor(postStart / (1000 * 60 * 60 * 24));

    const postEnd = differenceInMilliseconds(beginDate, postEndDate);
    const postEndDiffInDays = Math.floor(postEnd / (1000 * 60 * 60 * 24));
    const postEndDiffInHours = Math.floor((postEnd / (1000 * 60 * 60)) % 24);
    return {
      begin_time: beginTime,
      end_time: endTime,
      post_begin_days: String(postStartDiffInDays),
      post_end_days: String(postEndDiffInDays),
      post_end_hours: String(postEndDiffInHours),
    };
  };

  /**
   * 時間計算に使用する変数を生成
   * 下書きと編集でスキーマが異なるので場合分けしている
   */
  const genarateDraftDateTime = (data: gql.VtOfferDraftInfo) => {
    if (mode !== 'draft' || !data) return null;
    return {
      begin_time: `${String(data.begin_time?.slice(0, 5))}`,
      end_time: `${String(data.end_time?.slice(0, 5))}`,
      post_begin_days: String(data?.post_begin_days) || '',
      post_end_days: String(data?.post_end_days) || '',
      post_end_hours: String(data?.post_end_hours) || '',
    };
  };
  let { beginTime, endTime, postBeginDays, postEndDays, postEndHours } = {
    beginTime: '',
    endTime: '',
    postBeginDays: '',
    postEndDays: '',
    postEndHours: '',
  };
  if (mode === 'edit' || mode === 'duplicate') {
    const resGenarateOfferDateTime = genarateOfferDateTime(
      dispData?.begin_date || '',
      dispData?.end_date || '',
      offerData?.post_begin_date || '',
      offerData?.post_end_date || ''
    );
    beginTime = resGenarateOfferDateTime?.begin_time || '';
    endTime = resGenarateOfferDateTime?.end_time || '';
    postBeginDays = resGenarateOfferDateTime?.post_begin_days || '';
    postEndDays = resGenarateOfferDateTime?.post_end_days || '';
    postEndHours = resGenarateOfferDateTime?.post_end_hours || '';
  } else if (mode === 'draft') {
    const resGenarateDraftDateTime = genarateDraftDateTime(draftData as gql.VtOfferDraftInfo);
    beginTime = String(resGenarateDraftDateTime?.begin_time) || '';
    endTime = String(resGenarateDraftDateTime?.end_time) || '';
    postBeginDays = String(resGenarateDraftDateTime?.post_begin_days) || '';
    postEndDays = String(resGenarateDraftDateTime?.post_end_days) || '';
    postEndHours = String(resGenarateDraftDateTime?.post_end_hours) || '';
  } else {
    beginTime = '';
    endTime = '';
    postBeginDays = '';
    postEndDays = '';
    postEndHours = '';
  }

  /**
   * DATE型の日付にN日を加算する
   */
  function addNDay(addDay: number, date?: Date | null): Date {
    if (!date) return new Date();
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + addDay);
    return newDate;
  }

  /**
   * 時間をパースしてオブジェクトに格納
   * コンポーネント側にもあるが調整する必要があり、影響範囲が大きそうなので切り取った
   */
  const parseTimeString = useCallback(
    (timeString: string): TimeObject => {
      const [HH, mm, ss] = timeString.split(':').map(Number);
      /**
       * 新規作成時は時間を初期化
       */
      if (mode === 'create') return { hour: undefined, minute: undefined, second: undefined };
      /**
       * ここでバグがあったので影響範囲が不明なので一応残しておく
       * if (timeString.split(':').map(Number)[0] === 0) return { hour: undefined, minute: undefined, second: undefined };
       */
      return { hour: HH, minute: mm, second: ss };
    },
    [mode]
  );

  /**
   * DataPicker付属Input要素のステイと更新
   */
  const location = useLocation();
  const state = (location?.state || {}) as PropState;
  const { dateValue } = state;
  const [shiftDate, setShiftDate] = useState<Date | null>(null);
  const [repeatStartDate, setRepeatStartDate] = useState<Date | null>(null);
  const [repeatEndDate, setRepeatEndDate] = useState<Date | null>(null);

  /**
   * 時間をパース
   * useEffで使用するのでメモ化
   */
  const objBeginTime = useMemo(() => parseTimeString(beginTime || ''), [beginTime, parseTimeString]);
  const objEndTime = useMemo(() => parseTimeString(endTime || ''), [endTime, parseTimeString]);

  /**
   * 画像モーダルstate
   */
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');

  /**
   * RadioGroup（AutoDecide）
   * 型の変換があるため、親側のuseStateと子側のuseStateを併用
   */
  const [autoDecide, setAutoDecide] = useState<number>(dispData?.auto_decide ?? AutoDecide.MANUAL);
  /**
   * ReacttHookFormの初期値がundefinedで設定されてしまうので
   * useEffectで初期値を設定する
   */
  useEffect(() => {
    if (!dispData?.auto_decide) return;
    setAutoDecide(dispData?.auto_decide);
  }, [dispData?.auto_decide]);

  /**
   * RadioGroup（BreakTime）
   * 型の変換があるため、親側のuseStateと子側のuseStateを併用
   */
  const [breakTime, setBreakTime] = useState<string>(
    dispData?.break_time === BreakTime.NONE ? String(BreakTime.NONE) : String(BreakTime.ONE)
  );

  /**
   * RadioGroup（radioTransFee）
   * 型の変換があるため、親側のuseStateと子側のuseStateを併用
   */
  const [radioTransFee, setRadioTransFee] = useState<string>(
    adjustTransFee === TransFee.NONE ? String(TransFee.NONE) : String(TransFee.ONE)
  );

  /**
   * RadioGroup（添付）
   */

  const [radioAttachment, setAttachment] = useState<string>(attachmentFlag ? '1' : '0');

  /**
   * 採用方式を生成
   * @param auto_decide: number 0 | 1 | 2
   * @returns: string 手動 | 自動 | 逆オファー
   */
  const generateAutoDecide = (auto_decide: AutoDecide) => {
    switch (auto_decide) {
      case AutoDecide.MANUAL:
        return '手動';
      case AutoDecide.AUTO:
        return '自動';
      case AutoDecide.COUNTEROFFER:
        return '逆オファー';
      default:
        return '-';
    }
  };

  const generateScheduleType = (schedule_type: ScheduleType) => {
    switch (schedule_type) {
      case ScheduleType.DAILY:
        return '単日';
      case ScheduleType.WEEKLY:
        return '週定期';
      default:
        return '-';
    }
  };

  /**
   * 選択した曜日が繰り返し期間内に存在するかチェック
   */
  const checkIfSelectedDaysOfWeekExistInPeriod = (
    startDate?: string,
    endDate?: string,
    mondayFlag?: boolean,
    tuesdayFlag?: boolean,
    wednesdayFlag?: boolean,
    thursdayFlag?: boolean,
    fridayFlag?: boolean,
    saturdayFlag?: boolean,
    sundayFlag?: boolean
  ) => {
    if (!startDate || !endDate) return true;
    const start: Date = new Date(startDate);
    const end: Date = new Date(endDate);
    const datesArray: WeekData[] = getDatesBetween(start, end);
    const week: string[] = [];
    if (mondayFlag) {
      week.push('月');
    }
    if (tuesdayFlag) {
      week.push('火');
    }
    if (wednesdayFlag) {
      week.push('水');
    }
    if (thursdayFlag) {
      week.push('木');
    }
    if (fridayFlag) {
      week.push('金');
    }
    if (saturdayFlag) {
      week.push('土');
    }
    if (sundayFlag) {
      week.push('日');
    }
    const filteredWeeks: WeekData[] = filterByDayOfWeek(datesArray, week);
    return filteredWeeks.length > 0;
  };

  /**
   * 初期判定で交通費入力枠の表示/非表示を切り替えるために使用
   */
  useEffect(() => {
    const out = adjustTransFee === 0 ? '0' : '1';
    setRadioTransFee(out);
  }, [adjustTransFee, setRadioTransFee]);

  /**
   * 初期判定で休憩入力枠の表示/非表示を切り替えるために使用
   */
  useEffect(() => {
    const out = dispData?.break_time === 0 ? '0' : '1';
    setBreakTime(out);
  }, [dispData?.break_time, setBreakTime]);

  /**
   * 新規画面でPreview画面から戻ってきた時、Datapicker、Checkbox、Radioボタン（React Hook Formを使っていないもの）が更新されないための処理
   */
  useEffect(() => {
    if (!getValues) return;

    /**
     * 日程種別
     */
    if (getValues('check_schedule_type')) {
      setScheduleType(Number(getValues('check_schedule_type')));
    }

    /**
     * 採用方法
     */
    if (getValues('auto_decide')) {
      setAutoDecide(Number(getValues('auto_decide')));
    }

    /**
     * Datepickerの値を更新
     */
    // set_shift_date_with_params start
    if (getValues('shift_date')) {
      if (dateValue && dateValue > new Date()){
        setShiftDate(dateValue);
      } else {
        setShiftDate(new Date(getValues('shift_date') || new Date()));
      }
    }
    // set_shift_date_with_params end
    if (getValues('repeat_start_date')) {
      setRepeatStartDate(new Date(getValues('repeat_start_date') || new Date()));
    }

    if (getValues('repeat_end_date')) {
      setRepeatEndDate(new Date(getValues('repeat_end_date') || new Date()));
    }

    /**
     * 休憩
     */
    if (getValues('check_break_time')) {
      setBreakTime(getValues('check_break_time') === '1' ? '1' : '0');
    }

    /**
     * 交通費
     */
    if (getValues('check_trans_fee')) {
      setRadioTransFee(getValues('check_trans_fee') === '1' ? '1' : '0');
    }

    /**
     * 添付ファイル
     */
    if (getValues('attachment_flag')) {
      setAttachment(getValues('attachment_flag') === '1' ? '1' : '0');
    }
    // set_shift_date_with_params start
  }, [getValues, dateValue]);
  // set_shift_date_with_params end
  /**
   * ReactHookFormではdefaultValueの更新が効かないため、ここで初期値を設定する
   */
  useEffect(() => {
    if (!dispData || !setValue || !getValues || mode === 'create') return;
    setShiftDate(new Date(getValues('shift_date') || dispData?.begin_date || new Date()));
    setRepeatStartDate(new Date(getValues('repeat_start_date') || dispData?.begin_date || new Date()));
    setRepeatEndDate(new Date(getValues('repeat_end_date') || dispData?.end_date || addNDay(1, new Date())));
  }, [dispData, setValue, getValues, mode, setShiftDate, setRepeatStartDate, setRepeatEndDate]);

  /**
   * 繰り返し期間の開始日と終了日の整合性をチェック
   * 開始日が終了日よりも後の場合、終了日を開始日に合わせる
   */
  useEffect(() => {
    if (!setValue) return;
    const differenceRepeatDate = differenceInMilliseconds(repeatEndDate || 0, repeatStartDate || 0);
    if (differenceRepeatDate < 0) {
      setRepeatEndDate(addNDay(1, repeatStartDate));
      setValue('repeat_end_date', format(addNDay(1, repeatStartDate) || 0, 'yyyy-MM-dd'));
    }
  }, [repeatEndDate, repeatStartDate, setRepeatEndDate, setValue]);

  /**
   * 下書きの際は出勤時間のバリデーションを無効に
   */
  function disableStartHourValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        validate: () => true,
      };
    }
    return {
      required: requiredLabel,
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        if (!getValues || !values.daily_start_hour || !values.daily_start_minute) return false;
        const hours = values.daily_start_hour;
        const minutes = values.daily_start_minute;
        const nowDate = new Date();
        const shiftStartDate = new Date(
          `${String(getValues('shift_date'))} ${String(hours.padStart(2, '0'))}:${String(minutes)}`
        );
        if (shiftStartDate < nowDate) {
          return '過去日は指定できません';
        }
        return true;
      },
    };
  }

  /**
   * 下書きの際は募集掲載期間(From)のバリデーションを無効に
   * 整数チェック追加
   */
  function disableShiftDayFromValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        min: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
        validate: () => true,
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: 1,
        message: '1以上を入力してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        const postStartDate = Number(values.shift_day_from) || 0;
        const postEndDate = Number(values.shift_day_to) || 0;

        if (postStartDate <= postEndDate) {
          return '募集終了日は募集開始日より未来を指定してください';
        }

        return true;
      },
    };
  }

  /**
   * 下書きの際は募集掲載期間(To)のバリデーションを無効に
   * 整数チェック追加
   */
  function disableShiftDayToValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        min: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: 0,
        message: '0以上を指定してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
    };
  }

  /**
   * 下書きの際は必須項目のバリデーションを無効に
   */
  function disableRequiredValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
      };
    }
    return {
      required: requiredLabel,
    };
  }

  /**
   * 下書きの際は曜日のバリデーションを無効に
   */
  function disableMondayFlagValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        validate: () => true,
      };
    }
    return {
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        if (isDraftClicked) return true;
        if (
          values.monday_flag ||
          values.tuesday_flag ||
          values.wednesday_flag ||
          values.thursday_flag ||
          values.friday_flag ||
          values.saturday_flag ||
          values.sunday_flag
        ) {
          return true;
        }
        return '曜日を1つ以上選択してください';
      },
    };
  }

  /**
   * 下書きの際は休憩時間のバリデーションを無効に
   * 整数チェック追加
   */
  function disableBreakeTimeValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        min: undefined,
        max: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: 10,
        message: '10分以上を指定してください',
      },
      max: {
        value: 120,
        message: '120分以下を指定してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
    };
  }

  /**
   * 下書きの際は休憩時間のバリデーションを無効に
   * 整数チェック追加
   */
  function disableTransFeeValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        min: undefined,
        max: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: 1,
        message: '1円以上を指定してください',
      },
      max: {
        value: 30000,
        message: '30,000円以下を指定してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
    };
  }

  /**
   * 下書きの際は時給のバリデーションを無効に
   * 整数チェック追加
   */
  function disableWageValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        min: undefined,
        max: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: minWage,
        message: '都道府県指定の最低時給を下回っています',
      },
      max: {
        value: 9999,
        message: '10,000円未満を指定してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
    };
  }

  /**
   * シフト日が今日かどうかを判定
   */

  function isToday(date?: Date | null): boolean {
    if (!date) return false;
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  /**
   * 繰り返し期間の開始日と曜日から、最初のシフト日を計算する
   * @param periodBeginDate 繰り返し期間開始日
   * @param dayOfWeekFlags 曜日フラグを「日・月・火・水・木・金・土」の順で格納した配列
   */
  function calculateFirstShiftDate(periodBeginDate: Date, dayOfWeekFlags: boolean[]) {
    if (dayOfWeekFlags.every((flag) => flag === false)) {
      return undefined;
    }

    for (let i = 0; i < 7; i += 1) {
      const date = addDays(periodBeginDate, i);

      if (dayOfWeekFlags[date.getDay()]) {
        return date;
      }
    }

    return undefined;
  }

  /**
   * 下書きの際は過去日のバリデーションを無効に
   */
  function disableWeeklyShiftHourValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        validate: () => true,
      };
    }
    return {
      required: requiredLabel,
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        if (!getValues) return false;
        const hours = Number(values.weekly_shift_hour);
        const minutes = Number(values.weekly_shift_hour);
        const nowDate = new Date();
        const firstShiftDateTime = calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
          values.sunday_flag || false,
          values.monday_flag || false,
          values.tuesday_flag || false,
          values.wednesday_flag || false,
          values.thursday_flag || false,
          values.friday_flag || false,
          values.saturday_flag || false,
        ]);

        if (!firstShiftDateTime) return false;

        firstShiftDateTime.setHours(hours);
        firstShiftDateTime.setMinutes(minutes);

        if (firstShiftDateTime.getTime() <= nowDate.getTime()) {
          return '過去日は指定できません';
        }
        return true;
      },
    };
  }

  /**
   * 下書きの際は募集掲載期間(Hour)のバリデーションを無効に
   * 整数チェック追加
   */
  function disableShiftHourToValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        validate: () => true,
        min: undefined,
        max: undefined,
        pattern: {
          value: /^[0-9]+$/, // 正の整数を表す正規表現
          message: '整数のみを入力してください',
        },
      };
    }
    return {
      required: requiredLabel,
      min: {
        value: isToday(scheduleType === ScheduleType.DAILY ? shiftDate : repeatStartDate) ? 1 : 0,
        message: `${
          isToday(scheduleType === ScheduleType.DAILY ? shiftDate : repeatStartDate) ? '1' : '0'
        }以上を指定してください`,
      },
      max: {
        value: 23,
        message: '23以下を指定してください',
      },
      pattern: {
        value: /^[0-9]+$/, // 正の整数を表す正規表現
        message: '整数のみを入力してください',
      },
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        const shiftBeginDate =
          scheduleType === ScheduleType.DAILY
            ? new Date(values.shift_date || '')
            : calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
                values.sunday_flag || false,
                values.monday_flag || false,
                values.tuesday_flag || false,
                values.wednesday_flag || false,
                values.thursday_flag || false,
                values.friday_flag || false,
                values.saturday_flag || false,
              ]);
        const shiftBeginHour = scheduleType === ScheduleType.DAILY ? values.daily_start_hour : values.weekly_shift_hour;
        const shiftBeginMinute =
          scheduleType === ScheduleType.DAILY ? values.daily_start_minute : values.weekly_shift_minute;

        // シフト開始時間が未入力の場合はバリデーション不要
        if (shiftBeginDate === undefined || shiftBeginHour === undefined || shiftBeginMinute === undefined) {
          return true;
        }

        const shiftBeginDateTime = new Date(
          `${shiftBeginDate.getFullYear()}-${(shiftBeginDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${shiftBeginDate.getDate().toString().padStart(2, '0')}T${shiftBeginHour.padStart(
            2,
            '0'
          )}:${shiftBeginMinute.padStart(2, '0')}:00`
        );

        const postEndDate = Number(values.shift_day_to) || 0;
        const postEndHour = Number(values.shift_hour_to) || 0;
        const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

        const now = new Date();

        return now.getTime() < postEndDateTime.getTime() || '募集終了日時は現在時刻より未来を指定してください';
      },
    };
  }

  /**
   * 下書きの際は開始日以降のバリデーションを無効に
   */
  function disableRepeatEndDateValidationForDraftButtonClicked() {
    if (isDraftClicked) {
      return {
        required: false,
        validate: () => true,
      };
    }
    return {
      required: requiredLabel,
      validate: (_: string | number | boolean | null | undefined, values: FormData) => {
        if (!values.repeat_start_date || !values.repeat_end_date) return false;
        const repStartDate = new Date(values.repeat_start_date);
        const repEndDate = new Date(values.repeat_end_date);
        if (repStartDate >= repEndDate) {
          return '終了日は開始日以降を指定してください';
        }
        return true;
      },
    };
  }

  const { rankSelectOptions } = useRank();

  // feat screen 04-01-01-06 start
  return !isMobile ? (
    <Box pr={16}>
      <Box mb={18}>
        <Text variant="body14">募集情報を入力して確認を押してください。</Text>
      </Box>
      {isCreateMode && (
        <RhfInput
          name="offer_number"
          control={control}
          defaultValue={adjustOfferNumber || ''}
          placeholder=""
          type="hidden"
          width={300}
        />
      )}

      <Box mb={24}>
        <TextSet
          label="日程種別"
          content={
            mode !== 'edit' ? (
              <RadioGroup
                name="schedule_type"
                onChange={(e) => {
                  if (!setValue || !getValues) return;
                  setScheduleType(Number(e.target.value));
                  setValue('check_schedule_type', Number(e.target.value));
                  /**
                   * e.target.value === '2'は週定期を選択した場合のみ
                   */
                  if (e.target.value === String(ScheduleType.WEEKLY)) {
                    let repeatStart = new Date();
                    let repeatEnd;
                    if (mode === 'draft' || mode === 'duplicate') {
                      repeatStart = repeatStartDate ?? new Date();
                      repeatEnd = repeatEndDate ?? repeatStart;
                    }
                    // Datepickerの値を更新
                    setRepeatStartDate(repeatStart);
                    setRepeatEndDate(repeatEnd || addNDay(1, repeatStart));
                    // RHFの値を更新
                    setValue('repeat_start_date', format(repeatStart, 'yyyy-MM-dd'));
                    setValue('repeat_end_date', format(repeatEnd || addNDay(1, repeatStart), 'yyyy-MM-dd'));
                  }
                }}
                options={[
                  {
                    label: '単日',
                    value: '1',
                  },
                  {
                    label: '週定期',
                    value: '2',
                  },
                ]}
                value={scheduleType.toString()}
              />
            ) : (
              generateScheduleType(scheduleType)
            )
          }
        />
        {isCreateMode && (
          <RhfInput
            name="check_schedule_type"
            control={control}
            defaultValue={adjustScheduleType || 1}
            placeholder=""
            type="hidden"
            width={100}
          />
        )}
      </Box>
      <Box mb={24}>
        <TextSet
          label="採用方法"
          content={
            status === RecruitmentStatus.SUSPENSION_OF_RECRUITMENT || mode !== 'edit' ? (
              <RhfRadioGroup
                defaultValue={
                  dispData?.auto_decide !== undefined ? String(dispData.auto_decide) : String(AutoDecide.AUTO)
                }
                name="auto_decide"
                onChange={(e) => {
                  if (!setValue) return;
                  setAutoDecide(Number(e.target.value));
                }}
                options={[
                  {
                    label: '手動',
                    value: String(AutoDecide.MANUAL),
                  },
                  {
                    label: '自動',
                    value: String(AutoDecide.AUTO),
                  },
                ]}
                control={control}
              />
            ) : (
              <>
                {generateAutoDecide(dispData?.auto_decide as AutoDecide)}
                <RhfInput
                  name="auto_decide"
                  type="hidden"
                  defaultValue={dispData?.auto_decide?.toString()}
                  control={control}
                />
              </>
            )
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="掲載タイトル"
          content={
            isCreateMode && (
              <RhfInput
                name="title"
                control={control}
                defaultValue={dispData?.title || ''}
                type="text"
                fullWidth
                rules={{ required: '掲載タイトルは必須です' }}
              />
            )
          }
        />
        {errors?.title && <ErrorMessage>{errors?.title.message}</ErrorMessage>}
      </Box>
      {scheduleType === ScheduleType.WEEKLY && mode !== 'edit' && (
        <>
          <Box mb={24}>
            {(selectedWeek !== undefined || mode === 'create') && (
              <Box>
                <TextSet
                  label="曜日"
                  content={
                    <Box alignItems="center" display="flex" gap={16}>
                      <RhfCheckbox
                        name="monday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('monday')}
                        rules={disableMondayFlagValidationForDraftButtonClicked()}
                      >
                        月
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="tuesday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('tuesday')}
                      >
                        火
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="wednesday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('wednesday')}
                      >
                        水
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="thursday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('thursday')}
                      >
                        木
                      </RhfCheckbox>
                      <RhfCheckbox name="friday_flag" control={control} defaultValue={selectedWeek.includes('friday')}>
                        金
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="saturday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('saturday')}
                      >
                        土
                      </RhfCheckbox>
                      <RhfCheckbox name="sunday_flag" control={control} defaultValue={selectedWeek.includes('sunday')}>
                        日
                      </RhfCheckbox>
                    </Box>
                  }
                />
                {errors?.monday_flag && <ErrorMessage>{errors?.monday_flag.message}</ErrorMessage>}
              </Box>
            )}
          </Box>

          <Box mb={24}>
            <TextSet
              label="繰り返し期間"
              content={
                <Box alignItems="start" display="flex" gap={8}>
                  <Box>
                    <DatePicker
                      minDate={new Date()}
                      onChange={(value) => {
                        if (!setValue) return;
                        setRepeatStartDate?.(value);
                        setValue('repeat_start_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={repeatStartDate}
                      placeholderText="日付を選択"
                      width={168}
                    />
                    {errors?.repeat_start_date && <ErrorMessage>{errors?.repeat_start_date.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                    <Text color="blueGray" display="inline" variant="body14">
                      ~
                    </Text>
                  </Box>
                  <Box>
                    <DatePicker
                      minDate={addNDay(1, repeatStartDate)}
                      onChange={(value) => {
                        if (!setValue) return;
                        setRepeatEndDate?.(value);
                        setValue('repeat_end_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={repeatEndDate}
                      placeholderText="日付を選択"
                      width={168}
                    />
                    {errors?.repeat_end_date && <ErrorMessage>{errors?.repeat_end_date.message}</ErrorMessage>}
                  </Box>
                </Box>
              }
            />
            {isCreateMode && (
              <RhfInput
                name="repeat_start_date"
                control={control}
                defaultValue={
                  dispData?.begin_date && dispData?.begin_date !== '0000-00-00'
                    ? format(new Date(dispData?.begin_date), 'yyyy-MM-dd')
                    : format(new Date(), 'yyyy-MM-dd')
                }
                placeholder=""
                type="hidden"
                width={200}
                rules={{
                  required: requiredLabel,
                  validate: () => {
                    if (isDraftClicked) return true;
                    if (!getValues) return true;
                    const isInclude = checkIfSelectedDaysOfWeekExistInPeriod(
                      getValues('repeat_start_date'),
                      getValues('repeat_end_date'),
                      getValues('monday_flag'),
                      getValues('tuesday_flag'),
                      getValues('wednesday_flag'),
                      getValues('thursday_flag'),
                      getValues('friday_flag'),
                      getValues('saturday_flag'),
                      getValues('sunday_flag')
                    );
                    if (!isInclude) {
                      return '期間内に曜日が存在しません';
                    }
                    return true;
                  },
                }}
              />
            )}
            {isCreateMode && (
              <RhfInput
                name="repeat_end_date"
                control={control}
                defaultValue={dispData?.end_date ? format(new Date(dispData?.end_date), 'yyyy-MM-dd') : ''}
                placeholder=""
                type="hidden"
                width={200}
                rules={disableRepeatEndDateValidationForDraftButtonClicked()}
              />
            )}
          </Box>

          <Box alignItems="start" display="flex" gap={8} mb={24}>
            <TextSet
              label="募集シフト 出勤時間"
              content={
                <Box alignItems="start" display="flex" gap={8}>
                  <Box>
                    {isCreateMode && (
                      <RhfSelect
                        width={80}
                        control={control}
                        name="weekly_shift_hour"
                        options={Hours}
                        defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                        placeholder="時間"
                        rules={disableWeeklyShiftHourValidationForDraftButtonClicked()}
                      />
                    )}
                    {errors?.weekly_shift_hour && <ErrorMessage>{errors?.weekly_shift_hour.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                    <Text color="blueGray" display="inline" variant="body14">
                      :
                    </Text>
                  </Box>
                  <Box>
                    {isCreateMode && (
                      <RhfSelect
                        width={80}
                        control={control}
                        name="weekly_shift_minute"
                        options={Every15Minutes}
                        defaultValue={
                          objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                        }
                        placeholder="分"
                        rules={disableRequiredValidationForDraftButtonClicked()}
                      />
                    )}

                    {errors?.weekly_shift_minute && <ErrorMessage>{errors?.weekly_shift_minute.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                    <Text color="blueGray" display="inline" variant="body14">
                      ~
                    </Text>
                  </Box>
                </Box>
              }
            />
            <TextSet
              label="退勤時間"
              content={
                <Box alignItems="start" display="flex" gap={8}>
                  <Box>
                    {isCreateMode && (
                      <RhfSelect
                        width={80}
                        control={control}
                        name="weekly_end_hour"
                        options={Hours}
                        defaultValue={objEndTime?.hour !== undefined ? String(objEndTime?.hour) : ''}
                        placeholder="時間"
                        rules={disableRequiredValidationForDraftButtonClicked()}
                      />
                    )}

                    {errors?.weekly_end_hour && <ErrorMessage>{errors?.weekly_end_hour.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                    <Text color="blueGray" display="inline" variant="body14">
                      :
                    </Text>
                  </Box>
                  <Box>
                    {isCreateMode && (
                      <RhfSelect
                        width={80}
                        control={control}
                        name="weekly_end_minute"
                        options={Every15Minutes}
                        defaultValue={
                          objEndTime?.minute !== undefined ? String(objEndTime?.minute).padStart(2, '0') : ''
                        }
                        placeholder="分"
                        rules={disableRequiredValidationForDraftButtonClicked()}
                      />
                    )}

                    {errors?.weekly_end_minute && <ErrorMessage>{errors?.weekly_end_minute.message}</ErrorMessage>}
                  </Box>
                </Box>
              }
            />
          </Box>
        </>
      )}
      {scheduleType === ScheduleType.DAILY && (
        <Box alignItems="start" display="flex" gap={8} mb={24}>
          {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
            <Box>
              <TextSet
                label="募集シフト"
                content={`${String(
                  format(new Date(dispData?.begin_date || 0), 'yyyy年MM月dd日')
                )} / ${beginTime} ~ ${String(endTime)}`}
              />
            </Box>
          ) : (
            <>
              <div>
                <TextSet
                  label="募集シフト"
                  content={
                    <DatePicker
                      minDate={new Date()}
                      onChange={(value) => {
                        if (!setValue) return;
                        setShiftDate?.(value);
                        setValue('shift_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={shiftDate}
                      placeholderText="募集シフト"
                      width={168}
                    />
                  }
                />
                {errors?.shift_date && <ErrorMessage>{errors?.shift_date.message}</ErrorMessage>}
              </div>
              <div>
                <TextSet
                  label="出勤時間"
                  content={
                    <Box alignItems="start" display="flex" gap={8}>
                      <Box>
                        {isCreateMode && (
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_start_hour"
                            options={Hours}
                            defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                            placeholder="時間"
                            rules={disableStartHourValidationForDraftButtonClicked()}
                          />
                        )}

                        {errors?.daily_start_hour && <ErrorMessage>{errors?.daily_start_hour.message}</ErrorMessage>}
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                        <Text color="blueGray" display="inline" variant="body14">
                          :
                        </Text>
                      </Box>
                      <Box>
                        {isCreateMode && (
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_start_minute"
                            options={Every15Minutes}
                            defaultValue={
                              objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                            }
                            placeholder="分"
                            rules={disableRequiredValidationForDraftButtonClicked()}
                          />
                        )}
                        {errors?.daily_start_minute && (
                          <ErrorMessage>{errors?.daily_start_minute.message}</ErrorMessage>
                        )}
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                        <Text color="blueGray" display="inline" variant="body14">
                          ~
                        </Text>
                      </Box>
                    </Box>
                  }
                />
              </div>
              <div>
                <TextSet
                  label="退勤時間"
                  content={
                    <Box alignItems="start" display="flex" gap={8}>
                      <Box>
                        {isCreateMode && (
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_end_hour"
                            options={Hours}
                            defaultValue={objEndTime?.hour !== undefined ? String(objEndTime?.hour) : ''}
                            placeholder="時間"
                            rules={disableRequiredValidationForDraftButtonClicked()}
                          />
                        )}

                        {errors?.daily_end_hour && <ErrorMessage>{errors?.daily_end_hour.message}</ErrorMessage>}
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" height={32}>
                        <Text color="blueGray" display="inline" variant="body14">
                          :
                        </Text>
                      </Box>
                      <Box>
                        {isCreateMode && (
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_end_minute"
                            options={Every15Minutes}
                            defaultValue={
                              objEndTime?.minute !== undefined ? String(objEndTime?.minute).padStart(2, '0') : ''
                            }
                            placeholder="分"
                            rules={disableRequiredValidationForDraftButtonClicked()}
                          />
                        )}

                        {errors?.daily_end_minute && <ErrorMessage>{errors?.daily_end_minute.message}</ErrorMessage>}
                      </Box>
                    </Box>
                  }
                />
              </div>
              {isCreateMode && (
                <RhfInput
                  name="shift_date"
                  control={control}
                  defaultValue={format(
                    mode === 'create'
                      ? new Date()
                      : dispData?.begin_date === '0000-00-00'
                      ? new Date()
                      : new Date(dispData?.begin_date || 0),
                    'yyyy-MM-dd'
                  )}
                  placeholder=""
                  type="hidden"
                  width={200}
                  rules={{ required: requiredLabel }}
                />
              )}
            </>
          )}
        </Box>
      )}

      <Box mb={24}>
        <TextSet
          label="募集掲載期間"
          content={
            mode !== 'edit' ? (
              <Box alignItems="center" display="flex">
                <Box display="flex" gap={16}>
                  <Text color="blueGray" display="inline" variant="body14">
                    作成日時
                  </Text>
                  <Text color="blueGray" display="inline" variant="body14">
                  ~
                  </Text>
                  <Text color="blueGray" display="inline" variant="body14">
                    シフト出勤の
                  </Text>
                </Box>
                <Box>
                  {isCreateMode && (
                    <Box mx={8}>
                      <RhfSelect
                        width={80}
                        name="shift_hour_to"
                        control={control}
                        options={Before48Hours}
                        defaultValue={postEndHours || '1'}
                        placeholder="時間"
                        rules={{
                          required: requiredLabel,
                          validate: (_, values) => {
                            const shiftBeginDate =
                              scheduleType === ScheduleType.DAILY
                                ? new Date(values.shift_date || '')
                                : calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
                                    values.sunday_flag || false,
                                    values.monday_flag || false,
                                    values.tuesday_flag || false,
                                    values.wednesday_flag || false,
                                    values.thursday_flag || false,
                                    values.friday_flag || false,
                                    values.saturday_flag || false,
                                  ]);
                            const shiftBeginHour =
                              scheduleType === ScheduleType.DAILY ? values.daily_start_hour : values.weekly_shift_hour;
                            const shiftBeginMinute =
                              scheduleType === ScheduleType.DAILY
                                ? values.daily_start_minute
                                : values.weekly_shift_minute;

                            // シフト開始時間が未入力の場合はバリデーション不要
                            if (
                              shiftBeginDate === undefined ||
                              shiftBeginHour === undefined ||
                              shiftBeginMinute === undefined
                            ) {
                              return true;
                            }

                            const shiftBeginDateTime = new Date(
                              `${shiftBeginDate.getFullYear()}-${(shiftBeginDate.getMonth() + 1)
                                .toString()
                                .padStart(2, '0')}-${shiftBeginDate
                                .getDate()
                                .toString()
                                .padStart(2, '0')}T${shiftBeginHour.padStart(2, '0')}:${shiftBeginMinute.padStart(
                                2,
                                '0'
                              )}:00`
                            );

                            const postEndDate = Number(values.shift_day_to) || 0;
                            const postEndHour = Number(values.shift_hour_to) || 0;
                            const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                            const now = new Date();

                            return (
                              now.getTime() < postEndDateTime.getTime() ||
                              '募集終了日時は現在時刻より未来を指定してください'
                            );
                          },
                        }}
                      />
                    </Box>
                  )}

                  {errors?.shift_hour_to && <ErrorMessage>{errors?.shift_hour_to.message}</ErrorMessage>}
                </Box>
                <Box display="block">
                  <Text color="blueGray" display="inline" variant="body14">
                    &nbsp;時間前
                  </Text>
                </Box>
              </Box>
            ) : (
              offerData?.post_begin
            )
          }
        />
      </Box>

      {mode === 'edit' && (
        <Box mb={24}>
          <TextSet
            label="募集締切"
            content={
              <Box alignItems="center" display="flex">
                <Box display="flex" gap={16}>
                  <Text color="blueGray" display="inline" variant="body14">
                    シフト出勤の
                  </Text>
                </Box>
                {dispData?.title !== undefined && (
                  <Box>
                    <>
                      {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && (
                        <>
                          <RhfInput
                            name="shift_date"
                            control={control}
                            defaultValue={format(new Date(dispData?.begin_date || 0), 'yyyy-MM-dd')}
                            type="hidden"
                            width={200}
                          />
                          <RhfInput
                            name="daily_start_hour"
                            control={control}
                            defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                            type="hidden"
                            width={200}
                          />
                          <RhfInput
                            name="daily_start_minute"
                            control={control}
                            defaultValue={
                              objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                            }
                            type="hidden"
                            width={200}
                          />
                        </>
                      )}
                      <RhfInput
                        name="shift_day_from"
                        control={control}
                        defaultValue={postBeginDays || ''}
                        type="hidden"
                        width={80}
                      />
                    </>
                  </Box>
                )}
                {/* {dispData?.title !== undefined && (
                  <Box>
                    <RhfInput
                      name="shift_day_to"
                      control={control}
                      defaultValue={postEndDays || ''}
                      placeholder="0"
                      type="number"
                      width={80}
                      rules={{
                        required: requiredLabel,
                        max: {
                          value: 365,
                          message: '365日以下を指定してください',
                        },
                        min: {
                          value: 0,
                          message: '0日以上を指定してください',
                        },
                        validate: (_, values) => {
                          const shiftBeginDateTime = new Date(offerData?.begin_date || '');
                          const postBeginDateTime = new Date(offerData?.post_begin_date || '');

                          const postEndDate = Number(values.shift_day_to) || 0;
                          const postEndHour = Number(values.shift_hour_to) || 0;
                          const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                          return (
                            postBeginDateTime.getTime() < postEndDateTime.getTime() ||
                            '募集終了日時は募集開始日時より未来を指定してください'
                          );
                        },
                      }}
                    />
                    {errors?.shift_day_to && <ErrorMessage>{errors?.shift_day_to.message}</ErrorMessage>}
                  </Box>
                )} */}

                {/* <Box pt={15}>
                  <Text color="blueGray" display="inline" variant="body14">
                    &nbsp;日&nbsp;
                  </Text>
                </Box> */}
                {dispData?.title !== undefined && (
                  <Box mx={16}>
                    <RhfSelect
                      width={80}
                      name="shift_hour_to"
                      control={control}
                      options={Before48Hours}
                      defaultValue={postEndHours || '1'}
                      placeholder="時間"
                      rules={{
                        required: requiredLabel,
                        validate: (_, values) => {
                          const shiftBeginDate =
                            scheduleType === ScheduleType.DAILY
                              ? new Date(values.shift_date || '')
                              : calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
                                  values.sunday_flag || false,
                                  values.monday_flag || false,
                                  values.tuesday_flag || false,
                                  values.wednesday_flag || false,
                                  values.thursday_flag || false,
                                  values.friday_flag || false,
                                  values.saturday_flag || false,
                                ]);
                          const shiftBeginHour =
                            scheduleType === ScheduleType.DAILY ? values.daily_start_hour : values.weekly_shift_hour;
                          const shiftBeginMinute =
                            scheduleType === ScheduleType.DAILY
                              ? values.daily_start_minute
                              : values.weekly_shift_minute;

                          // シフト開始時間が未入力の場合はバリデーション不要
                          if (
                            shiftBeginDate === undefined ||
                            shiftBeginHour === undefined ||
                            shiftBeginMinute === undefined
                          ) {
                            return true;
                          }

                          const shiftBeginDateTime = new Date(
                            `${shiftBeginDate.getFullYear()}-${(shiftBeginDate.getMonth() + 1)
                              .toString()
                              .padStart(2, '0')}-${shiftBeginDate
                              .getDate()
                              .toString()
                              .padStart(2, '0')}T${shiftBeginHour.padStart(2, '0')}:${shiftBeginMinute.padStart(
                              2,
                              '0'
                            )}:00`
                          );

                          const postEndDate = Number(values.shift_day_to) || 0;
                          const postEndHour = Number(values.shift_hour_to) || 0;
                          const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                          const now = new Date();

                          return (
                            now.getTime() < postEndDateTime.getTime() ||
                            '募集終了日時は現在時刻より未来を指定してください'
                          );
                        },
                      }}
                    />
                    {errors?.shift_hour_to && <ErrorMessage>{errors?.shift_hour_to.message}</ErrorMessage>}
                  </Box>
                )}

                <Box display="block">
                  <Text color="blueGray" display="inline" variant="body14">
                    &nbsp;時間前
                  </Text>
                </Box>
              </Box>
            }
          />
        </Box>
      )}

      {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
        <Box mb={24}>
          <TextSet label="休憩" content={dispData?.break_time !== 0 ? `${String(dispData?.break_time)}分` : '無'} />
        </Box>
      ) : (
        <Box alignItems="start" display="flex" gap={20} mb={24}>
          {/**
           * break_time: number が 0 の時「無」
           */}
          <TextSet
            label="休憩"
            content={
              <RadioGroup
                name="is_break_time"
                onChange={(e) => {
                  if (!setValue) return;
                  setBreakTime(e.target.value);
                  setValue('check_break_time', e.target.value);
                }}
                options={[
                  {
                    label: '有',
                    value: '1',
                  },
                  {
                    label: '無',
                    value: '0',
                  },
                ]}
                value={breakTime}
              />
            }
          />
          {isCreateMode && (
            <RhfInput
              name="check_break_time"
              control={control}
              defaultValue={dispData?.break_time === 0 ? '0' : '1'}
              placeholder=""
              type="hidden"
              width={100}
            />
          )}

          {isCreateMode && breakTime === '1' && (
            <Box>
              <TextSet
                label="休憩時間（分）"
                visible={breakTime === '1' ? 'visible' : 'hidden'}
                content={
                  <RhfInput
                    name="break_time"
                    control={control}
                    defaultValue={mode === 'create' ? '' : String(dispData?.break_time)}
                    placeholder="分を入力"
                    type="number"
                    width={120}
                    rules={disableBreakeTimeValidationForDraftButtonClicked()}
                  />
                }
              />
              {errors?.break_time && <ErrorMessage>{errors?.break_time.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
      )}
      <Box mb={24}>
        <TextSet
          label="時給（¥）"
          content={
            status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit'
              ? `¥${String(dispData?.hour_wage?.toLocaleString())}`
              : isCreateMode && (
                  <RhfInput
                    name="hour_wage_display"
                    control={control}
                    defaultValue={mode === 'create' ? '' : String(dispData?.hour_wage)}
                    placeholder="金額を入力"
                    type="number"
                    width={120}
                    rules={disableWageValidationForDraftButtonClicked()}
                  />
                )
          }
        />
        {errors?.hour_wage_display && <ErrorMessage>{errors.hour_wage_display.message}</ErrorMessage>}
      </Box>
      {autoDecide !== AutoDecide.MANUAL && (
        <>
          <Box alignItems="center" display="flex" gap={40} mb={24}>
            <Box alignItems="end" display="flex">
              <TextSet
                label="募集ランク"
                content={
                  isCreateMode && (
                    <RhfSelect
                      width={168}
                      control={control}
                      name="rank"
                      options={[{ label: 'ランク選択', value: '' }].concat(rankSelectOptions)}
                      defaultValue={dispData?.bt_rank_id ? String(dispData?.bt_rank_id) : ''}
                      placeholder="ランク選択"
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以上
              </Text>
            </Box>

            <Box alignItems="end" display="flex">
              <TextSet
                label="スコア"
                content={
                  isCreateMode && (
                    <RhfSelect
                      width={168}
                      control={control}
                      name="score"
                      options={Score}
                      defaultValue={dispData?.score ? String(dispData?.score) : ''}
                      placeholder="スコア選択"
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以上
              </Text>
            </Box>

            <Box alignItems="end" display="flex">
              <TextSet
                label="採用手数料（¥）"
                content={
                  isCreateMode && (
                    <RhfSelect
                      width={168}
                      control={control}
                      name="hire_fee"
                      options={HireFee}
                      defaultValue={dispData?.hire_fee_total ? String(dispData?.hire_fee_total) : ''}
                      placeholder="採用手数料選択"
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以下
              </Text>
            </Box>
          </Box>

          <Box alignItems="center" display="flex" gap={40} mb={24}>
            <Box alignItems="end" display="flex">
              <TextSet
                label="キャンセル率（%）"
                content={
                  isCreateMode && (
                    <RhfSelect
                      width={168}
                      control={control}
                      name="cancel_per"
                      options={Rate}
                      defaultValue={Number.isInteger(dispData?.cancel_per) ? String(dispData?.cancel_per) : ''}
                      placeholder="キャンセル率選択"
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以下
              </Text>
            </Box>
            <Box alignItems="end" display="flex">
              <TextSet
                label="ドタキャン率（%）"
                content={
                  isCreateMode && (
                    <RhfSelect
                      width={168}
                      control={control}
                      name="imminent_per"
                      options={Rate}
                      defaultValue={Number.isInteger(dispData?.imminent_per) ? String(dispData?.imminent_per) : ''}
                      placeholder="ドタキャン率選択"
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以下
              </Text>
            </Box>
          </Box>
        </>
      )}
      <Box mb={24}>
        <TextSet
          label="当日の店舗責任者"
          content={
            isCreateMode && (
              <Controller
                name="chief"
                control={control}
                rules={{
                  validate: (value) => {
                    if (isDraftClicked) return true;
                    if (!value) return '店舗責任者を選択してください';
                    return true;
                  },
                }}
                defaultValue={getValues && getValues('chief') ? getValues('chief') : dispData?.chief || ''}
                render={({ field }) => (
                  <ComboBox
                    width={328}
                    creatable
                    options={[
                      {
                        label: `${String(dispChiefData?.last_name)} ${String(dispChiefData?.first_name)}`,
                        value: '1',
                      },
                    ]}
                    onChange={(newValue) => {
                      field.onChange((newValue as { label: string; value: string })?.label);
                    }}
                    defaultInputValue={getValues && getValues('chief') ? getValues('chief') : dispData?.chief || ''}
                  />
                )}
              />
            )
          }
        />
        {errors?.chief && <ErrorMessage>{errors.chief.message}</ErrorMessage>}
      </Box>

      {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
        /**
         * trans_fee: string が '' の時「無」
         */
        <Box mb={24}>
          <TextSet
            label="交通費支給"
            content={`${radioTransFee === '1' ? `有 ¥${String(dispData?.trans_fee?.toLocaleString())}` : '無'}`}
          />
        </Box>
      ) : (
        <Box alignItems="start" display="flex" gap={20} mb={24}>
          <TextSet
            label="交通費支給"
            content={
              <RadioGroup
                name="trans_fee_select"
                onChange={(e) => {
                  if (!setValue) return;
                  setRadioTransFee(e.target.value);
                  setValue('check_trans_fee', e.target.value);
                }}
                options={[
                  {
                    label: '有',
                    value: '1',
                  },
                  {
                    label: '無',
                    value: '0',
                  },
                ]}
                value={radioTransFee}
              />
            }
          />
          {isCreateMode && (
            <RhfInput
              name="check_trans_fee"
              control={control}
              defaultValue={adjustTransFee === 0 ? '0' : '1'}
              placeholder=""
              type="hidden"
              width={100}
            />
          )}

          {isCreateMode && radioTransFee === '1' && (
            <Box>
              <TextSet
                label="交通費（¥）"
                visible={radioTransFee === '1' ? 'visible' : 'hidden'}
                content={
                  isCreateMode && (
                    <RhfInput
                      name="trans_fee"
                      control={control}
                      defaultValue={mode === 'create' ? '' : String(adjustTransFee)}
                      placeholder="金額を入力"
                      type="number"
                      width={120}
                      rules={disableTransFeeValidationForDraftButtonClicked()}
                    />
                  )
                }
              />
              {errors?.trans_fee && <ErrorMessage>{errors?.trans_fee.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
      )}
      <Box mb={24}>
        <TextSet
          label="業務詳細"
          content={
            isCreateMode &&
            (adjustDescription ? (
              <RhfTextarea
                name="description"
                control={control}
                height={156}
                fullWidth
                placeholder=""
                defaultValue={adjustDescription || ''}
              />
            ) : (
              <RhfTextarea name="description" control={control} height={156} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="働くための条件"
          content={
            isCreateMode &&
            (adjustConditions ? (
              <RhfTextarea
                name="conditions"
                control={control}
                height={156}
                fullWidth
                placeholder=""
                defaultValue={adjustConditions || ''}
              />
            ) : (
              <RhfTextarea name="conditions" control={control} height={156} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="注意事項"
          content={
            isCreateMode &&
            (adjustCaution ? (
              <RhfTextarea
                name="caution"
                control={control}
                height={156}
                fullWidth
                placeholder=""
                defaultValue={adjustCaution || ''}
              />
            ) : (
              <RhfTextarea name="caution" control={control} height={156} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="持ち物"
          content={
            isCreateMode &&
            (adjustBelongings ? (
              <RhfTextarea
                name="belongings"
                control={control}
                height={156}
                fullWidth
                placeholder=""
                defaultValue={adjustBelongings || ''}
              />
            ) : (
              <RhfTextarea name="belongings" control={control} height={156} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="アクセス"
          content={
            isCreateMode &&
            (adjustAccess ? (
              <RhfTextarea
                name="access"
                control={control}
                height={156}
                fullWidth
                placeholder=""
                defaultValue={adjustAccess || ''}
              />
            ) : (
              <RhfTextarea name="access" control={control} height={156} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={24}>
        <TextSet
          label="メイン画像"
          content={
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={8}>
              {image1Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image1Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image1Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image1_flag" control={control} defaultValue={adjustImage1Flag} />
                    )}
                  </div>
                </div>
              )}
              {image2Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image2Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image2Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image2_flag" control={control} defaultValue={adjustImage2Flag} />
                    )}
                  </div>
                </div>
              )}
              {image3Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image3Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image3Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image3_flag" control={control} defaultValue={adjustImage3Flag} />
                    )}
                  </div>
                </div>
              )}
              {image4Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image4Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image4Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image4_flag" control={control} defaultValue={adjustImage4Flag} />
                    )}
                  </div>
                </div>
              )}
              {image5Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image5Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image5Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image5_flag" control={control} defaultValue={adjustImage5Flag} />
                    )}
                  </div>
                </div>
              )}
            </Box>
          }
        />
        {image1Path && (
          <RhfInput
            name="image1_path"
            control={control}
            defaultValue={image1Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image2Path && (
          <RhfInput
            name="image2_path"
            control={control}
            defaultValue={image2Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image3Path && (
          <RhfInput
            name="image3_path"
            control={control}
            defaultValue={image3Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image4Path && (
          <RhfInput
            name="image4_path"
            control={control}
            defaultValue={image4Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image5Path && (
          <RhfInput
            name="image5_path"
            control={control}
            defaultValue={image5Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
      </Box>
      <Box mb={24}>
        <TextSet
          label="添付ファイル"
          content={
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
              {attachedFilePath ? (
                <>
                  <div>
                    <TextLink href={attachedFilePath || '#'} variant="body14">
                      {attachedFileName}
                    </TextLink>
                  </div>
                  <div>
                    <RadioGroup
                      name="radioAttachment"
                      onChange={(e) => {
                        if (!setValue) return;
                        setAttachment(e.target.value);
                        setValue('attachment_flag', e.target.value);
                      }}
                      options={[
                        {
                          label: '添付する',
                          value: '1',
                        },
                        {
                          label: '添付しない',
                          value: '0',
                        },
                      ]}
                      value={radioAttachment}
                    />
                  </div>
                </>
              ) : (
                'なし'
              )}
            </Box>
          }
        />
        {attachmentFlag && (
          <RhfInput
            name="attachment_flag"
            control={control}
            defaultValue={attachmentFlag ? '1' : '0'}
            placeholder=""
            type="hidden"
            width={30}
          />
        )}
        {attachedFilePath && (
          <RhfInput
            name="attached_file"
            control={control}
            defaultValue={attachedFilePath || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {attachedFileName && (
          <RhfInput name="attached_name" control={control} defaultValue={attachedFileName || ''} type="hidden" />
        )}
      </Box>
      <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
    </Box>
  ) : (
    // Responsive Component
    <Box>
      <Box mb={14}>
        <Text variant="body14">募集情報を入力して確認を押してください。</Text>
      </Box>
      {isCreateMode && (
        <RhfInput
          name="offer_number"
          control={control}
          defaultValue={adjustOfferNumber || ''}
          placeholder=""
          type="hidden"
          width={300}
        />
      )}

      <Box mb={16}>
        <TextSet
          label="日程種別"
          content={
            mode !== 'edit' ? (
              <RadioGroup
                name="schedule_type"
                onChange={(e) => {
                  if (!setValue || !getValues) return;
                  setScheduleType(Number(e.target.value));
                  setValue('check_schedule_type', Number(e.target.value));
                  /**
                   * e.target.value === '2'は週定期を選択した場合のみ
                   */
                  if (e.target.value === String(ScheduleType.WEEKLY)) {
                    let repeatStart = new Date();
                    let repeatEnd;
                    if (mode === 'draft' || mode === 'duplicate') {
                      repeatStart = repeatStartDate ?? new Date();
                      repeatEnd = repeatEndDate ?? repeatStart;
                    }
                    // Datepickerの値を更新
                    setRepeatStartDate(repeatStart);
                    setRepeatEndDate(repeatEnd || addNDay(1, repeatStart));
                    // RHFの値を更新
                    setValue('repeat_start_date', format(repeatStart, 'yyyy-MM-dd'));
                    setValue('repeat_end_date', format(repeatEnd || addNDay(1, repeatStart), 'yyyy-MM-dd'));
                  }
                }}
                options={[
                  {
                    label: '単日',
                    value: '1',
                  },
                  {
                    label: '週定期',
                    value: '2',
                  },
                ]}
                value={scheduleType.toString()}
              />
            ) : (
              generateScheduleType(scheduleType)
            )
          }
        />
        {isCreateMode && (
          <RhfInput
            name="check_schedule_type"
            control={control}
            defaultValue={adjustScheduleType || 1}
            placeholder=""
            type="hidden"
            width={100}
          />
        )}
      </Box>
      <Box mb={16}>
        <TextSet
          label="採用方法"
          content={
            status === RecruitmentStatus.SUSPENSION_OF_RECRUITMENT || mode !== 'edit' ? (
              <RhfRadioGroup
                defaultValue={
                  dispData?.auto_decide !== undefined ? String(dispData.auto_decide) : String(AutoDecide.AUTO)
                }
                name="auto_decide"
                onChange={(e) => {
                  if (!setValue) return;
                  setAutoDecide(Number(e.target.value));
                }}
                options={[
                  {
                    label: '手動',
                    value: String(AutoDecide.MANUAL),
                  },
                  {
                    label: '自動',
                    value: String(AutoDecide.AUTO),
                  },
                ]}
                control={control}
              />
            ) : (
              <>
                {generateAutoDecide(dispData?.auto_decide as AutoDecide)}
                <RhfInput
                  name="auto_decide"
                  type="hidden"
                  defaultValue={dispData?.auto_decide?.toString()}
                  control={control}
                />
              </>
            )
          }
        />
      </Box>
      <Box mb={14}>
        <TextSet
          label="掲載タイトル"
          content={
            isCreateMode && (
              <RhfInput
                name="title"
                control={control}
                defaultValue={dispData?.title || ''}
                type="text"
                fullWidth
                rules={{ required: '掲載タイトルは必須です' }}
              />
            )
          }
        />
        {errors?.title && <ErrorMessage>{errors?.title.message}</ErrorMessage>}
      </Box>
      {scheduleType === ScheduleType.WEEKLY && mode !== 'edit' && (
        <>
          <Box mb={10}>
            {(selectedWeek !== undefined || mode === 'create') && (
              <Box>
                <TextSet
                  label="曜日"
                  content={
                    <Box alignItems="center" display="flex" flexWrap='wrap' gap={16}>
                      <RhfCheckbox
                        name="monday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('monday')}
                        rules={{
                          validate: (_, values) => {
                            if (isDraftClicked) return true;
                            if (
                              values.monday_flag ||
                              values.tuesday_flag ||
                              values.wednesday_flag ||
                              values.thursday_flag ||
                              values.friday_flag ||
                              values.saturday_flag ||
                              values.sunday_flag
                            ) {
                              return true;
                            }
                            return '曜日を1つ以上選択してください';
                          },
                        }}
                      >
                        月
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="tuesday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('tuesday')}
                      >
                        火
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="wednesday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('wednesday')}
                      >
                        水
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="thursday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('thursday')}
                      >
                        木
                      </RhfCheckbox>
                      <RhfCheckbox name="friday_flag" control={control} defaultValue={selectedWeek.includes('friday')}>
                        金
                      </RhfCheckbox>
                      <RhfCheckbox
                        name="saturday_flag"
                        control={control}
                        defaultValue={selectedWeek.includes('saturday')}
                      >
                        土
                      </RhfCheckbox>
                      <RhfCheckbox name="sunday_flag" control={control} defaultValue={selectedWeek.includes('sunday')}>
                        日
                      </RhfCheckbox>
                    </Box>
                  }
                />
                {errors?.monday_flag && <ErrorMessage>{errors?.monday_flag.message}</ErrorMessage>}
              </Box>
            )}
          </Box>

          <Box mb={8}>
            <TextSet
              label="繰り返し期間"
              content={
                <Box display="flex" flexDirection="column" gap={8}>
                  <Box>
                    <DatePicker
                      minDate={new Date()}
                      onChange={(value) => {
                        if (!setValue) return;
                        setRepeatStartDate?.(value);
                        setValue('repeat_start_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={repeatStartDate}
                      placeholderText="日付を選択"
                    />
                    {errors?.repeat_start_date && <ErrorMessage>{errors?.repeat_start_date.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" height={22}>
                    <Text color="blueGray" display="inline" variant="caption12">
                      ~
                    </Text>
                  </Box>
                  <Box>
                    <DatePicker
                      minDate={addNDay(1, repeatStartDate)}
                      onChange={(value) => {
                        if (!setValue) return;
                        setRepeatEndDate?.(value);
                        setValue('repeat_end_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={repeatEndDate}
                      placeholderText="日付を選択"
                    />
                    {errors?.repeat_end_date && <ErrorMessage>{errors?.repeat_end_date.message}</ErrorMessage>}
                  </Box>
                </Box>
              }
            />
            {isCreateMode && (
              <RhfInput
                name="repeat_start_date"
                control={control}
                defaultValue={dispData?.begin_date ? format(new Date(dispData?.begin_date), 'yyyy-MM-dd') : ''}
                placeholder=""
                type="hidden"
                width={200}
                rules={{
                  required: requiredLabel,
                  validate: () => {
                    if (isDraftClicked) return true;
                    if (!getValues) return true;
                    const isInclude = checkIfSelectedDaysOfWeekExistInPeriod(
                      getValues('repeat_start_date'),
                      getValues('repeat_end_date'),
                      getValues('monday_flag'),
                      getValues('tuesday_flag'),
                      getValues('wednesday_flag'),
                      getValues('thursday_flag'),
                      getValues('friday_flag'),
                      getValues('saturday_flag'),
                      getValues('sunday_flag')
                    );
                    if (!isInclude) {
                      return '期間内に曜日が存在しません';
                    }
                    return true;
                  },
                }}
              />
            )}
            {isCreateMode && (
              <RhfInput
                name="repeat_end_date"
                control={control}
                defaultValue={dispData?.end_date ? format(new Date(dispData?.end_date), 'yyyy-MM-dd') : ''}
                placeholder=""
                type="hidden"
                width={200}
                rules={{
                  required: requiredLabel,
                  validate: (_, values) => {
                    if (!values.repeat_start_date || !values.repeat_end_date) return false;
                    const repStartDate = new Date(values.repeat_start_date);
                    const repEndDate = new Date(values.repeat_end_date);
                    if (repStartDate >= repEndDate) {
                      return '終了日は開始日以降を指定してください';
                    }
                    return true;
                  },
                }}
              />
            )}
          </Box>

          <Box mb={16} width="100%">
            <TextSet
              label="募集シフト 出勤時間"
              content={
                <Box display="flex" flexDirection="column" width="100%" gap={8}>
                  <Box display="flex" justifyContent="space-between">
                    <Box width="100%">
                      {isCreateMode && (
                        <RhfSelect
                          fullWidth
                          height={40}
                          control={control}
                          name="weekly_shift_hour"
                          options={Hours}
                          defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                          placeholder="時間"
                          rules={{
                            required: requiredLabel,
                            validate: (_, values) => {
                              if (!getValues) return false;
                              const hours = Number(values.weekly_shift_hour);
                              const minutes = Number(values.weekly_shift_minute);
                              const nowDate = new Date();
                              const firstShiftDateTime = calculateFirstShiftDate(
                                new Date(values.repeat_start_date || ''),
                                [
                                  values.sunday_flag || false,
                                  values.monday_flag || false,
                                  values.tuesday_flag || false,
                                  values.wednesday_flag || false,
                                  values.thursday_flag || false,
                                  values.friday_flag || false,
                                  values.saturday_flag || false,
                                ]
                              );

                              if (!firstShiftDateTime) return false;

                              firstShiftDateTime.setHours(hours);
                              firstShiftDateTime.setMinutes(minutes);

                              if (firstShiftDateTime.getTime() <= nowDate.getTime()) {
                                return '過去日は指定できません';
                              }
                              return true;
                            },
                          }}
                        />
                      )}
                      {errors?.weekly_shift_hour && <ErrorMessage>{errors?.weekly_shift_hour.message}</ErrorMessage>}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" height={40} px={4}>
                      <Text color="blueGray" display="inline" variant="body14">
                        :
                      </Text>
                    </Box>
                    <Box width="100%">
                      {isCreateMode && (
                        <RhfSelect
                          fullWidth
                          height={40}
                          control={control}
                          name="weekly_shift_minute"
                          options={Every15Minutes}
                          defaultValue={
                            objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                          }
                          placeholder="分"
                          rules={{ required: requiredLabel }}
                        />
                      )}

                      {errors?.weekly_shift_minute && <ErrorMessage>{errors?.weekly_shift_minute.message}</ErrorMessage>}
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" height={22} mb={8}>
                    <Text color="blueGray" display="inline" variant="caption12">
                      ~
                    </Text>
                  </Box>
                </Box>
              }
            />
            <TextSet
              label="退勤時間"
              content={
                <Box alignItems="start" display="flex" width="100%">
                  <Box width="100%">
                    {isCreateMode && (
                      <RhfSelect
                        fullWidth
                        height={40}
                        control={control}
                        name="weekly_end_hour"
                        options={Hours}
                        defaultValue={objEndTime?.hour !== undefined ? String(objEndTime?.hour) : ''}
                        placeholder="時間"
                        rules={{ required: requiredLabel }}
                      />
                    )}

                    {errors?.weekly_end_hour && <ErrorMessage>{errors?.weekly_end_hour.message}</ErrorMessage>}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" height={40} px={4}>
                    <Text color="blueGray" display="inline" variant="body14">
                      :
                    </Text>
                  </Box>
                  <Box width="100%">
                    {isCreateMode && (
                      <RhfSelect
                        fullWidth
                        height={40}
                        control={control}
                        name="weekly_end_minute"
                        options={Every15Minutes}
                        defaultValue={
                          objEndTime?.minute !== undefined ? String(objEndTime?.minute).padStart(2, '0') : ''
                        }
                        placeholder="分"
                        rules={{ required: requiredLabel }}
                      />
                    )}

                    {errors?.weekly_end_minute && <ErrorMessage>{errors?.weekly_end_minute.message}</ErrorMessage>}
                  </Box>
                </Box>
              }
            />
          </Box>
        </>
      )}
      {scheduleType === ScheduleType.DAILY && (
        <Box alignItems="start" display="flex" mb={11}>
          {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
            <Box>
              <TextSet
                label="募集シフト"
                content={`${String(
                  format(new Date(dispData?.begin_date || 0), 'yyyy年MM月dd日')
                )} / ${beginTime} ~ ${String(endTime)}`}
              />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" mb={8}>
                <TextSet
                  label="募集シフト"
                  content={
                    <DatePicker
                      minDate={new Date()}
                      onChange={(value) => {
                        if (!setValue) return;
                        setShiftDate?.(value);
                        setValue('shift_date', format(value || 0, 'yyyy-MM-dd'));
                      }}
                      selected={shiftDate}
                      placeholderText="募集シフト"
                      width={168}
                    />
                  }
                />
                {errors?.shift_date && <ErrorMessage>{errors?.shift_date.message}</ErrorMessage>}
              </Box>
              <Box width="100%" mb={8}>
                <TextSet
                  label="出勤時間"
                  content={
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between" mb={4}>
                        <Box width="100%">
                          {isCreateMode && (
                            <RhfSelect
                              fullWidth
                              height={40}
                              control={control}
                              name="daily_start_hour"
                              options={Hours}
                              defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                              placeholder="時間"
                              rules={{
                                required: requiredLabel,
                                validate: (_, values) => {
                                  if (!getValues || !values.daily_start_hour || !values.daily_start_minute) return false;
                                  const hours = values.daily_start_hour;
                                  const minutes = values.daily_start_minute;
                                  const nowDate = new Date();
                                  const shiftStartDate = new Date(
                                    `${String(getValues('shift_date'))} ${String(hours.padStart(2, '0'))}:${String(
                                      minutes
                                    )}`
                                  );
                                  if (shiftStartDate < nowDate) {
                                    return '過去日は指定できません';
                                  }
                                  return true;
                                },
                              }}
                            />
                          )}

                          {errors?.daily_start_hour && <ErrorMessage>{errors?.daily_start_hour.message}</ErrorMessage>}
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" height={40} px={4}>
                          <Text color="blueGray" display="inline" variant="body14">
                            :
                          </Text>
                        </Box>
                        <Box width="100%">
                          {isCreateMode && (
                            <RhfSelect
                              fullWidth
                              height={40}
                              control={control}
                              name="daily_start_minute"
                              options={Every15Minutes}
                              defaultValue={
                                objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                              }
                              placeholder="分"
                              rules={{
                                required: true,
                              }}
                            />
                          )}
                          {errors?.daily_start_minute && (
                            <ErrorMessage>{errors?.daily_start_minute.message}</ErrorMessage>
                          )}
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="flex-start" height={22}>
                        <Text color="blueGray" display="inline" variant="body14">
                          ~
                        </Text>
                      </Box>
                    </Box>
                  }
                />
              </Box>
              <Box width="100%">
                <TextSet
                  label="退勤時間"
                  content={
                    <Box display="flex">
                      <Box width="100%">
                        {isCreateMode && (
                          <RhfSelect
                            fullWidth
                            height={40}
                            control={control}
                            name="daily_end_hour"
                            options={Hours}
                            defaultValue={objEndTime?.hour !== undefined ? String(objEndTime?.hour) : ''}
                            placeholder="時間"
                            rules={{ required: requiredLabel }}
                          />
                        )}

                        {errors?.daily_end_hour && <ErrorMessage>{errors?.daily_end_hour.message}</ErrorMessage>}
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" height={40} px={4}>
                        <Text color="blueGray" display="inline" variant="body14">
                          :
                        </Text>
                      </Box>
                      <Box width="100%">
                        {isCreateMode && (
                          <RhfSelect
                            fullWidth
                            height={40}
                            control={control}
                            name="daily_end_minute"
                            options={Every15Minutes}
                            defaultValue={
                              objEndTime?.minute !== undefined ? String(objEndTime?.minute).padStart(2, '0') : ''
                            }
                            placeholder="分"
                            rules={{ required: requiredLabel }}
                          />
                        )}

                        {errors?.daily_end_minute && <ErrorMessage>{errors?.daily_end_minute.message}</ErrorMessage>}
                      </Box>
                    </Box>
                  }
                />
              </Box>
              {isCreateMode && (
                <RhfInput
                  name="shift_date"
                  control={control}
                  defaultValue={format(
                    mode === 'create' ? new Date() : new Date(dispData?.begin_date || 0),
                    'yyyy-MM-dd'
                  )}
                  placeholder=""
                  type="hidden"
                  width={200}
                  rules={{ required: requiredLabel }}
                />
              )}
            </Box>
          )}
        </Box>
      )}

      <Box mb={16}>
        <TextSet
          label="募集掲載期間"
          content={
            mode !== 'edit' ? (
              <Box alignItems="start" display="flex" flexDirection="column" justifyContent='space-between'>
                <Box display="flex" width="100%">
                  <Box display="flex" alignItems="center" gap={8}>
                    <Text color="blueGray" display="inline" variant="body14">
                      作成日時
                    </Text>
                    <Text color="blueGray" display="inline" variant="body14">
                    ~
                    </Text>
                    <Text color="blueGray" display="inline" variant="body14">
                      シフト出勤の
                    </Text>
                  </Box>
                  <Box mx={8}>
                    <RhfSelect
                      width={63}
                      height={40}
                      name="shift_hour_to"
                      control={control}
                      options={Before48Hours}
                      defaultValue={postEndHours || '1'}
                      placeholder="時間"
                      rules={{
                        required: requiredLabel,
                        validate: (_, values) => {
                          const shiftBeginDate =
                            scheduleType === ScheduleType.DAILY
                              ? new Date(values.shift_date || '')
                              : calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
                                  values.sunday_flag || false,
                                  values.monday_flag || false,
                                  values.tuesday_flag || false,
                                  values.wednesday_flag || false,
                                  values.thursday_flag || false,
                                  values.friday_flag || false,
                                  values.saturday_flag || false,
                                ]);
                          const shiftBeginHour =
                            scheduleType === ScheduleType.DAILY ? values.daily_start_hour : values.weekly_shift_hour;
                          const shiftBeginMinute =
                            scheduleType === ScheduleType.DAILY
                              ? values.daily_start_minute
                              : values.weekly_shift_minute;

                          // シフト開始時間が未入力の場合はバリデーション不要
                          if (
                            shiftBeginDate === undefined ||
                            shiftBeginHour === undefined ||
                            shiftBeginMinute === undefined
                          ) {
                            return true;
                          }

                          const shiftBeginDateTime = new Date(
                            `${shiftBeginDate.getFullYear()}-${(shiftBeginDate.getMonth() + 1)
                              .toString()
                              .padStart(2, '0')}-${shiftBeginDate
                              .getDate()
                              .toString()
                              .padStart(2, '0')}T${shiftBeginHour.padStart(2, '0')}:${shiftBeginMinute.padStart(
                              2,
                              '0'
                            )}:00`
                          );

                          const postEndDate = Number(values.shift_day_to) || 0;
                          const postEndHour = Number(values.shift_hour_to) || 0;
                          const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                          const now = new Date();

                          return (
                            now.getTime() < postEndDateTime.getTime() ||
                            '募集終了日時は現在時刻より未来を指定してください'
                          );
                        },
                      }}
                    />

                  </Box>
                  <Box display="flex" alignItems="center">
                    <Text color="blueGray" wordBreak="keep-all" display="inline" variant="body14">
                      &nbsp;時間前
                    </Text>
                  </Box>
                </Box>
                <Box display='block' mb={16}>
                  {errors?.shift_hour_to && <ErrorMessage>{errors?.shift_hour_to.message}</ErrorMessage>}
                </Box>
              </Box>
            ) : (
              offerData?.post_begin
            )
          }
        />
      </Box>

      {mode === 'edit' && (
        <Box mb={16}>
          <TextSet
            label="募集締切"
            content={
              <Box mt={2}>
                <Box alignItems="center" display="flex">
                  <Text color="blueGray" display="inline" variant="body14">
                    シフト出勤の
                  </Text>
                  {dispData?.title !== undefined && (
                    <Box>
                      <>
                        {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && (
                          <>
                            <RhfInput
                              name="shift_date"
                              control={control}
                              defaultValue={format(new Date(dispData?.begin_date || 0), 'yyyy-MM-dd')}
                              type="hidden"
                              width={200}
                            />
                            <RhfInput
                              name="daily_start_hour"
                              control={control}
                              defaultValue={objBeginTime?.hour !== undefined ? String(objBeginTime?.hour) : ''}
                              type="hidden"
                              width={200}
                            />
                            <RhfInput
                              name="daily_start_minute"
                              control={control}
                              defaultValue={
                                objBeginTime?.minute !== undefined ? String(objBeginTime?.minute).padStart(2, '0') : ''
                              }
                              type="hidden"
                              width={200}
                            />
                          </>
                        )}
                        <RhfInput
                          name="shift_day_from"
                          control={control}
                          defaultValue={postBeginDays || ''}
                          type="hidden"
                          width={80}
                        />
                      </>
                    </Box>
                  )}
                  {/* {dispData?.title !== undefined && (
                    <Box>
                      <RhfInput
                        name="shift_day_to"
                        control={control}
                        defaultValue={postEndDays || ''}
                        placeholder="0"
                        type="number"
                        width={80}
                        rules={{
                          required: requiredLabel,
                          max: {
                            value: 365,
                            message: '365日以下を指定してください',
                          },
                          min: {
                            value: 0,
                            message: '0日以上を指定してください',
                          },
                          validate: (_, values) => {
                            const shiftBeginDateTime = new Date(offerData?.begin_date || '');
                            const postBeginDateTime = new Date(offerData?.post_begin_date || '');

                            const postEndDate = Number(values.shift_day_to) || 0;
                            const postEndHour = Number(values.shift_hour_to) || 0;
                            const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                            return (
                              postBeginDateTime.getTime() < postEndDateTime.getTime() ||
                              '募集終了日時は募集開始日時より未来を指定してください'
                            );
                          },
                        }}
                      />
                      {errors?.shift_day_to && <ErrorMessage>{errors?.shift_day_to.message}</ErrorMessage>}
                    </Box>
                  )} */}

                  {/* <Box pt={15}>
                    <Text color="blueGray" display="inline" variant="body14">
                      &nbsp;日&nbsp;
                    </Text>
                  </Box> */}
                  {dispData?.title !== undefined && (
                    <Box mx={8}>
                      <RhfSelect
                        width={63}
                        height={40}
                        name="shift_hour_to"
                        control={control}
                        options={Before48Hours}
                        defaultValue={postEndHours || '1'}
                        placeholder="時間"
                        rules={{
                          required: requiredLabel,
                          validate: (_, values) => {
                            const shiftBeginDate =
                              scheduleType === ScheduleType.DAILY
                                ? new Date(values.shift_date || '')
                                : calculateFirstShiftDate(new Date(values.repeat_start_date || ''), [
                                    values.sunday_flag || false,
                                    values.monday_flag || false,
                                    values.tuesday_flag || false,
                                    values.wednesday_flag || false,
                                    values.thursday_flag || false,
                                    values.friday_flag || false,
                                    values.saturday_flag || false,
                                  ]);
                            const shiftBeginHour =
                              scheduleType === ScheduleType.DAILY ? values.daily_start_hour : values.weekly_shift_hour;
                            const shiftBeginMinute =
                              scheduleType === ScheduleType.DAILY
                                ? values.daily_start_minute
                                : values.weekly_shift_minute;

                            // シフト開始時間が未入力の場合はバリデーション不要
                            if (
                              shiftBeginDate === undefined ||
                              shiftBeginHour === undefined ||
                              shiftBeginMinute === undefined
                            ) {
                              return true;
                            }

                            const shiftBeginDateTime = new Date(
                              `${shiftBeginDate.getFullYear()}-${(shiftBeginDate.getMonth() + 1)
                                .toString()
                                .padStart(2, '0')}-${shiftBeginDate
                                .getDate()
                                .toString()
                                .padStart(2, '0')}T${shiftBeginHour.padStart(2, '0')}:${shiftBeginMinute.padStart(
                                2,
                                '0'
                              )}:00`
                            );

                            const postEndDate = Number(values.shift_day_to) || 0;
                            const postEndHour = Number(values.shift_hour_to) || 0;
                            const postEndDateTime = subHours(subDays(shiftBeginDateTime, postEndDate), postEndHour);

                            const now = new Date();

                            return (
                              now.getTime() < postEndDateTime.getTime() ||
                              '募集終了日時は現在時刻より未来を指定してください'
                            );
                          },
                        }}
                      />
                    </Box>
                  )}
                  <Box display="flex" alignItems="center">
                    <Text color="blueGray" wordBreak="keep-all" display="inline" variant="body14">
                      &nbsp;時間前
                    </Text>
                  </Box>
                </Box>
                <Box display="block">
                  {errors?.shift_hour_to && <ErrorMessage>{errors?.shift_hour_to.message}</ErrorMessage>}
                </Box>
              </Box>
            }
          />
        </Box>
      )}

      {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
        <Box mb={16}>
          <TextSet label="休憩" content={dispData?.break_time !== 0 ? `${String(dispData?.break_time)}分` : '無'} />
        </Box>
      ) : (
        <Box alignItems="start" display="flex" gap={16} mb={16}>
          {/**
           * break_time: number が 0 の時「無」
           */}
          <TextSet
            label="休憩"
            content={
              <Box pt={4}>
                <RadioGroup
                  name="is_break_time"
                  onChange={(e) => {
                    if (!setValue) return;
                    setBreakTime(e.target.value);
                    setValue('check_break_time', e.target.value);
                  }}
                  options={[
                    {
                      label: '有',
                      value: '1',
                    },
                    {
                      label: '無',
                      value: '0',
                    },
                  ]}
                  value={breakTime}
                />
              </Box>
            }
          />
          {isCreateMode && (
            <RhfInput
              name="check_break_time"
              control={control}
              defaultValue={dispData?.break_time === 0 ? '0' : '1'}
              placeholder=""
              type="hidden"
              width={100}
            />
          )}

          {isCreateMode && breakTime === '1' && (
            <Box>
              <TextSet
                label="休憩時間（分）"
                visible={breakTime === '1' ? 'visible' : 'hidden'}
                content={
                  <RhfInput
                    name="break_time"
                    control={control}
                    defaultValue={mode === 'create' ? '' : String(dispData?.break_time)}
                    placeholder="分を入力"
                    type="number"
                    width={120}
                    rules={disableBreakeTimeValidationForDraftButtonClicked()}
                  />
                }
              />
              {errors?.break_time && <ErrorMessage>{errors?.break_time.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
      )}
      <Box mb={16}>
        <TextSet
          label="時給（¥）"
          content={
            status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit'
              ? `¥${String(dispData?.hour_wage?.toLocaleString())}`
              : isCreateMode && (
                  <RhfInput
                    name="hour_wage_display"
                    control={control}
                    defaultValue={mode === 'create' ? '' : String(dispData?.hour_wage)}
                    placeholder="金額を入力"
                    type="number"
                    fullWidth
                    rules={disableWageValidationForDraftButtonClicked()}
                  />
                )
          }
        />
        {errors?.hour_wage_display && <ErrorMessage>{errors.hour_wage_display.message}</ErrorMessage>}
      </Box>
      {autoDecide !== AutoDecide.MANUAL && (
        <Box display="flex" flexDirection="column">
            <Box display='flex' justifyContent='space-between' mb={16}>
              <Box display='flex' alignItems='end'>
                <TextSet
                  label="募集ランク"
                  content={
                    isCreateMode && (
                      <RhfSelect
                        control={control}
                        name="rank"
                        options={[{ label: 'ランク選択', value: '' }].concat(rankSelectOptions)}
                        defaultValue={dispData?.bt_rank_id ? String(dispData?.bt_rank_id) : ''}
                        placeholder="ランク選択"
                        width={120}
                        height={40}
                      />
                    )
                  }
                />
                <Text color="blueGray" variant="body14" display="inline">
                  &nbsp;以上
                </Text>
              </Box>
              <Box display='flex' alignItems='end'>
                <TextSet
                  label="スコア"
                  content={
                    isCreateMode && (
                      <RhfSelect
                        control={control}
                        name="score"
                        options={Score}
                        defaultValue={dispData?.score ? String(dispData?.score) : ''}
                        placeholder="スコア選択"
                        width={120}
                        height={40}
                      />
                    )
                  }
                />
                <Text color="blueGray" variant="body14" display="inline">
                  &nbsp;以上
                </Text>
              </Box>
            </Box>
            <Box display='flex' alignItems='end' mb={16}>
              <TextSet
                label="採用手数料（¥）"
                content={
                  isCreateMode && (
                    <RhfSelect
                      control={control}
                      name="hire_fee"
                      options={HireFee}
                      defaultValue={dispData?.hire_fee_total ? String(dispData?.hire_fee_total) : ''}
                      placeholder="採用手数料選択"
                      width={120}
                      height={40}
                    />
                  )
                }
              />
              <Text color="blueGray" variant="body14" display="inline">
                &nbsp;以下
              </Text>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={16}>
              <Box display='flex' alignItems='end'>
                <TextSet
                  label="キャンセル率(%)"
                  content={
                    isCreateMode && (
                      <RhfSelect
                        control={control}
                        name="cancel_per"
                        options={Rate}
                        defaultValue={Number.isInteger(dispData?.cancel_per) ? String(dispData?.cancel_per) : ''}
                        placeholder="キャンセル率選択"
                        width={120}
                        height={40}
                      />
                    )
                  }
                />
                <Text color="blueGray" variant="body14" display="inline">
                  &nbsp;以下
                </Text>
              </Box>
              <Box display='flex' alignItems='end'>
                <TextSet
                  label="ドタキャン率(%)"
                  content={
                    isCreateMode && (
                      <RhfSelect
                        control={control}
                        name="imminent_per"
                        options={Rate}
                        defaultValue={Number.isInteger(dispData?.imminent_per) ? String(dispData?.imminent_per) : ''}
                        placeholder="ドタキャン率選択"
                        width={120}
                        height={40}
                      />
                    )
                  }
                />
                <Text color="blueGray" variant="body14" display="inline">
                  &nbsp;以下
                </Text>
              </Box>
            </Box>
          </Box>
      )}
      <Box mb={16}>
        <TextSet
          label="当日の店舗責任者"
          content={
            isCreateMode && (
              <Controller
                name="chief"
                control={control}
                rules={{
                  validate: (value) => {
                    if (isDraftClicked) return true;
                    if (!value) return '店舗責任者を選択してください';
                    return true;
                  },
                }}
                defaultValue={getValues && getValues('chief') ? getValues('chief') : dispData?.chief || ''}
                render={({ field }) => (
                  <ComboBox
                    creatable
                    options={[
                      {
                        label: `${String(dispChiefData?.last_name)} ${String(dispChiefData?.first_name)}`,
                        value: '1',
                      },
                    ]}
                    onChange={(newValue) => {
                      field.onChange((newValue as { label: string; value: string })?.label);
                    }}
                    defaultInputValue={getValues && getValues('chief') ? getValues('chief') : dispData?.chief || ''}
                  />
                )}
              />
            )
          }
        />
        {errors?.chief && <ErrorMessage>{errors.chief.message}</ErrorMessage>}
      </Box>

      {status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT && mode === 'edit' ? (
        /**
         * trans_fee: string が '' の時「無」
         */
        <Box mb={16}>
          <TextSet
            label="交通費支給"
            content={`${radioTransFee === '1' ? `有 ¥${String(dispData?.trans_fee?.toLocaleString())}` : '無'}`}
          />
        </Box>
      ) : (
        <Box alignItems="start" display="flex" gap={12} mb={16}>
          <TextSet
            label="交通費支給"
            content={
              <Box pt={4}>
                <RadioGroup
                  name="trans_fee_select"
                  onChange={(e) => {
                    if (!setValue) return;
                    setRadioTransFee(e.target.value);
                    setValue('check_trans_fee', e.target.value);
                  }}
                  options={[
                    {
                      label: '有',
                      value: '1',
                    },
                    {
                      label: '無',
                      value: '0',
                    },
                  ]}
                  value={radioTransFee}
                />
              </Box>
            }
          />
          {isCreateMode && (
            <RhfInput
              name="check_trans_fee"
              control={control}
              defaultValue={adjustTransFee === 0 ? '0' : '1'}
              placeholder=""
              type="hidden"
              width={100}
            />
          )}

          {isCreateMode && radioTransFee === '1' && (
            <Box>
              <TextSet
                label="交通費（¥）"
                visible={radioTransFee === '1' ? 'visible' : 'hidden'}
                content={
                  isCreateMode && (
                    <RhfInput
                      name="trans_fee"
                      control={control}
                      defaultValue={mode === 'create' ? '' : String(adjustTransFee?.toLocaleString())}
                      placeholder="金額を入力"
                      type="number"
                      width={120}
                      rules={disableTransFeeValidationForDraftButtonClicked()}
                    />
                  )
                }
              />
              {errors?.trans_fee && <ErrorMessage>{errors?.trans_fee.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
      )}
      <Box mb={16}>
        <TextSet
          label="業務詳細"
          content={
            isCreateMode &&
            (adjustDescription ? (
              <RhfTextarea
                name="description"
                control={control}
                height={100}
                fullWidth
                placeholder=""
                defaultValue={adjustDescription || ''}
              />
            ) : (
              <RhfTextarea name="description" control={control} height={100} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={16}>
        <TextSet
          label="働くための条件"
          content={
            isCreateMode &&
            (adjustConditions ? (
              <RhfTextarea
                name="conditions"
                control={control}
                height={100}
                fullWidth
                placeholder=""
                defaultValue={adjustConditions || ''}
              />
            ) : (
              <RhfTextarea name="conditions" control={control} height={100} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={16}>
        <TextSet
          label="注意事項"
          content={
            isCreateMode &&
            (adjustCaution ? (
              <RhfTextarea
                name="caution"
                control={control}
                height={100}
                fullWidth
                placeholder=""
                defaultValue={adjustCaution || ''}
              />
            ) : (
              <RhfTextarea name="caution" control={control} height={100} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={16}>
        <TextSet
          label="持ち物"
          content={
            isCreateMode &&
            (adjustBelongings ? (
              <RhfTextarea
                name="belongings"
                control={control}
                height={100}
                fullWidth
                placeholder=""
                defaultValue={adjustBelongings || ''}
              />
            ) : (
              <RhfTextarea name="belongings" control={control} height={100} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={16}>
        <TextSet
          label="アクセス"
          content={
            isCreateMode &&
            (adjustAccess ? (
              <RhfTextarea
                name="access"
                control={control}
                height={100}
                fullWidth
                placeholder=""
                defaultValue={adjustAccess || ''}
              />
            ) : (
              <RhfTextarea name="access" control={control} height={100} fullWidth placeholder="" defaultValue="" />
            ))
          }
        />
      </Box>
      <Box mb={16}>
        <TextSet
          label="メイン画像"
          content={
            <Box width="100%" display="flex" flexWrap="wrap" justifyContent='space-around' alignItems="center" gap={8}>
              {image1Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image1Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image1Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image1_flag" control={control} defaultValue={adjustImage1Flag} />
                    )}
                  </div>
                </div>
              )}
              {image2Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image2Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image2Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image2_flag" control={control} defaultValue={adjustImage2Flag} />
                    )}
                  </div>
                </div>
              )}
              {image3Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image3Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image3Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image3_flag" control={control} defaultValue={adjustImage3Flag} />
                    )}
                  </div>
                </div>
              )}
              {image4Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image4Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image4Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image4_flag" control={control} defaultValue={adjustImage4Flag} />
                    )}
                  </div>
                </div>
              )}
              {image5Path && (
                <div style={{ position: 'relative' }}>
                  <ImgWrapper
                    onClick={() => {
                      setModalImageSrc(image5Path || '');
                      setIsImageModalOpen(true);
                    }}
                  >
                    <img style={{ width: '126px', height: '94.5px' }} src={image5Path || ''} alt="サムネイル" />
                  </ImgWrapper>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {isCreateMode && (
                      <RhfCheckbox name="image5_flag" control={control} defaultValue={adjustImage5Flag} />
                    )}
                  </div>
                </div>
              )}
            </Box>
          }
        />
        {image1Path && (
          <RhfInput
            name="image1_path"
            control={control}
            defaultValue={image1Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image2Path && (
          <RhfInput
            name="image2_path"
            control={control}
            defaultValue={image2Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image3Path && (
          <RhfInput
            name="image3_path"
            control={control}
            defaultValue={image3Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image4Path && (
          <RhfInput
            name="image4_path"
            control={control}
            defaultValue={image4Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {image5Path && (
          <RhfInput
            name="image5_path"
            control={control}
            defaultValue={image5Path || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
      </Box>
      <Box>
        <TextSet
          label="添付ファイル"
          content={
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
              {attachedFilePath ? (
                <Box display='flex' flexDirection='column' alignItems='flex-start' gap={4}>
                  <div>
                    <TextLink href={attachedFilePath || '#'} variant="body14">
                      {attachedFileName}
                    </TextLink>
                  </div>
                  <div>
                    <RadioGroup
                      name="radioAttachment"
                      onChange={(e) => {
                        if (!setValue) return;
                        setAttachment(e.target.value);
                        setValue('attachment_flag', e.target.value);
                      }}
                      options={[
                        {
                          label: '添付する',
                          value: '1',
                        },
                        {
                          label: '添付しない',
                          value: '0',
                        },
                      ]}
                      value={radioAttachment}
                    />
                  </div>
                </Box>
              ) : (
                'なし'
              )}
            </Box>
          }
        />
        {attachmentFlag && (
          <RhfInput
            name="attachment_flag"
            control={control}
            defaultValue={attachmentFlag ? '1' : '0'}
            placeholder=""
            type="hidden"
            width={30}
          />
        )}
        {attachedFilePath && (
          <RhfInput
            name="attached_file"
            control={control}
            defaultValue={attachedFilePath || ''}
            placeholder=""
            type="hidden"
            width={200}
          />
        )}
        {attachedFileName && (
          <RhfInput name="attached_name" control={control} defaultValue={attachedFileName || ''} type="hidden" />
        )}
      </Box>
      <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
    </Box>
  );
  // feat screen 04-01-01-06 end
}
