/**
 * Figma ID: 01-01-13
 * 名称: メールアドレス変更登録
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { DatePicker } from 'components/datePicker';
import { emailPattern } from 'components/const';
import { useForm, Controller } from 'react-hook-form';
import { useErrorModal } from 'components/error/errorModalProvider';
import _ from 'lodash';
import useIsMobile from 'hooks/responsive/useIsMobile';
import getYear from 'date-fns/getYear';
import { ContentTitle } from './component/ContentTitle';

type EmailInfo = {
  firstName: string;
  lastName: string;
  firstKana: string;
  lastKana: string;
  managerBirthday: Date;
  businessName: string;
  zipCode1: string;
  zipCode2: string;
  phone: string;
  email: string;
};

type Props = {
  tpcode: string;
  emailInfo: EmailInfo | null;
  toNext: (args: {
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    managerBirthday: Date;
    businessName: string;
    zipCode1: string;
    zipCode2: string;
    phone: string;
    email: string;
  }) => void;
  toBack: () => void;
};
export function EmailUpdate({ tpcode, emailInfo, toBack, toNext }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { openErrorModal } = useErrorModal();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    clearErrors,
  } = useForm<{
    managerFamilyName: string;
    managerFirstName: string;
    managerFamilyNameKana: string;
    managerFirstNameKana: string;
    managerBirthday: Date;
    store_name: string;
    zipCode1: string;
    zipCode2: string;
    managerPhone: string;
    mailAddress: string;
    confirmMailAddress: string;
  }>({
    reValidateMode: 'onChange',
  });

  const mailAddressWatch = watch('mailAddress');

  const onSubmit = handleSubmit((data) => {
    toNext({
      firstName: data.managerFirstName,
      lastName: data.managerFamilyName,
      firstKana: data.managerFirstNameKana,
      lastKana: data.managerFamilyNameKana,
      managerBirthday: data.managerBirthday,
      businessName: data.store_name,
      zipCode1: data.zipCode1,
      zipCode2: data.zipCode2,
      phone: data.managerPhone,
      email: data.mailAddress,
    });
  });

  const zipCode1Length = 3;
  const zipCode2Length = 4;

  // feat screen No_10 start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="メールアドレス変更依頼" />
      <Box height={50} display="flex" alignItems="center">
        <Text variant="body14">
          登録情報を再度入力して「運営へ報告」を押してください。運営にてユーザー登録情報の確認を行います。
        </Text>
      </Box>
      <Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名</InputLabel>
          <Box display="flex" gap={10}>
            <RhfInput
              defaultValue={emailInfo?.lastName || ''}
              rules={{ required: '責任者氏名を入力してください。' }}
              width={120}
              control={control}
              name="managerFamilyName"
              placeholder="姓"
            />
            <RhfInput
              defaultValue={emailInfo?.firstName || ''}
              rules={{ required: '責任者氏名を入力してください。' }}
              width={120}
              control={control}
              name="managerFirstName"
              placeholder="名"
            />
          </Box>
          {(errors.managerFamilyName || errors.managerFirstName) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyName?.message || errors.managerFirstName?.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名（フリガナ）</InputLabel>
          <Box display="flex" gap={10}>
            <RhfInput
              defaultValue={emailInfo?.lastKana || ''}
              rules={{
                required: '責任者氏名（フリガナ）を入力してください。',
                pattern: {
                  value: /^[ァ-ンヴー]*$/i,
                  message: 'カナ文字を入力してください',
                },
              }}
              width={120}
              control={control}
              name="managerFamilyNameKana"
              placeholder="セイ"
            />
            <RhfInput
              defaultValue={emailInfo?.firstKana || ''}
              rules={{
                required: '責任者氏名（フリガナ）を入力してください。',
                pattern: {
                  value: /^[ァ-ンヴー]*$/i,
                  message: 'カナ文字を入力してください',
                },
              }}
              width={120}
              control={control}
              name="managerFirstNameKana"
              placeholder="メイ"
            />
          </Box>
          {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者生年月日</InputLabel>
          <Controller
            name="managerBirthday"
            control={control}
            rules={{ required: '生年月日を入力してください' }}
            render={({ field }) => (
              <DatePicker
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  field.onChange(newValue);
                }}
                selected={field.value}
                yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                placeholderText="生年月日を入力"
              />
            )}
          />
          {errors.managerBirthday && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerBirthday.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号
          </Text>
          <Box>
            <RhfInput
              name="store_name"
              control={control}
              defaultValue={emailInfo?.businessName || ''}
              fullWidth
              placeholder=""
              rules={{ required: '事業者名または事業者氏名/屋号を入力' }}
            />
            {errors.store_name?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.store_name?.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の所在地郵便番号
          </Text>
          <Box display="flex" flexDirection="row" gap={16} alignItems="center">
            <Box>
              <RhfInput
                name="zipCode1"
                control={control}
                width={120}
                defaultValue={emailInfo?.zipCode1 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </Box>
            <Divider option="horizontal" length={7} />
            <Box>
              <RhfInput
                name="zipCode2"
                control={control}
                width={120}
                defaultValue={emailInfo?.zipCode2 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </Box>
          </Box>
          {(errors?.zipCode1 || errors.zipCode2) && (
            <Text variant="caption12" color="cautionRed">
              {errors.zipCode1?.message ?? errors.zipCode2?.message}
            </Text>
          )}
        </Box>

        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の電話番号
          </Text>
          <RhfInput
            defaultValue={emailInfo?.phone || ''}
            width={328}
            control={control}
            name="managerPhone"
            rules={{
              pattern: {
                value: /^\d*$/,
                message: '数字を使用してください。',
              },
            }}
            placeholder="事業者名または事業者氏名/屋号の所在地電話番号を入力"
          />
          {errors.managerPhone && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerPhone.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス</InputLabel>
          <RhfInput
            name="mailAddress"
            control={control}
            fullWidth
            defaultValue={emailInfo?.email || ''}
            placeholder="メールアドレス"
            rules={{
              required: 'メールアドレスを入力してください。',
              pattern: {
                value: emailPattern,
                message: '入力形式がメールアドレスではありません。',
              },
            }}
          />
          {errors.mailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.mailAddress.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス（確認）</InputLabel>
          <RhfInput
            name="confirmMailAddress"
            control={control}
            fullWidth
            defaultValue=""
            placeholder="メールアドレス（確認）"
            rules={{
              required: 'メールアドレスを入力してください。',
              validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
            }}
          />
          {errors.confirmMailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.confirmMailAddress.message}
            </Text>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" gap={8}>
        <MainButton variant="secondary" onClick={toBack}>
          確認コード入力に戻る
        </MainButton>
        <Box display="grid" justifyContent="center">
          <MainButton
            onClick={() => {
              onSubmit();
            }}
          >
            運営へ報告
          </MainButton>
        </Box>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="メールアドレス変更依頼" />
      <Box display="flex" alignItems="center" pt={18}>
        <Text variant="body14">
          登録情報を再度入力して「運営へ報告」を押してください。運営にてユーザー登録情報の確認を行います。
        </Text>
      </Box>
      <Box pt={14}>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>責任者氏名</InputLabel>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                fullWidth
                defaultValue={emailInfo?.lastName || ''}
                rules={{ required: '責任者氏名を入力してください。' }}
                control={control}
                name="managerFamilyName"
                placeholder="姓"
              />
            </div>
            <div style={{width: "100%"}}>
              <RhfInput
                fullWidth
                defaultValue={emailInfo?.firstName || ''}
                rules={{ required: '責任者氏名を入力してください。' }}
                control={control}
                name="managerFirstName"
                placeholder="名"
              />
            </div>
          </Box>
          {(errors.managerFamilyName || errors.managerFirstName) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyName?.message || errors.managerFirstName?.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>責任者氏名（フリガナ）</InputLabel>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                fullWidth
                defaultValue={emailInfo?.lastKana || ''}
                rules={{
                  required: '責任者氏名（フリガナ）を入力してください。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/i,
                    message: 'カナ文字を入力してください',
                  },
                }}
                width={120}
                control={control}
                name="managerFamilyNameKana"
                placeholder="セイ"
              />
            </div>
            <div style={{width: "100%"}}>
              <RhfInput
                fullWidth
                defaultValue={emailInfo?.firstKana || ''}
                rules={{
                  required: '責任者氏名（フリガナ）を入力してください。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/i,
                    message: 'カナ文字を入力してください',
                  },
                }}
                width={120}
                control={control}
                name="managerFirstNameKana"
                placeholder="メイ"
              />
            </div>
          </Box>
          {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者生年月日</InputLabel>
          <Controller
            name="managerBirthday"
            control={control}
            rules={{ required: '生年月日を入力してください' }}
            render={({ field }) => (
              <DatePicker
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  field.onChange(newValue);
                }}
                selected={field.value}
                yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                placeholderText="生年月日を入力"
              />
            )}
          />
          {errors.managerBirthday && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerBirthday.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号
          </Text>
          <Box>
            <RhfInput
              name="store_name"
              control={control}
              defaultValue={emailInfo?.businessName || ''}
              fullWidth
              placeholder=""
              rules={{ required: '事業者名または事業者氏名/屋号を入力' }}
            />
            {errors.store_name?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.store_name?.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の所在地郵便番号
          </Text>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                fullWidth
                name="zipCode1"
                control={control}
                defaultValue={emailInfo?.zipCode1 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </div >
            <div style={{width: "100%"}}>
              <RhfInput
                name="zipCode2"
                control={control}
                fullWidth
                defaultValue={emailInfo?.zipCode2 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </div>
          </Box>
          {(errors?.zipCode1 || errors.zipCode2) && (
            <Text variant="caption12" color="cautionRed">
              {errors.zipCode1?.message ?? errors.zipCode2?.message}
            </Text>
          )}
        </Box>

        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の電話番号
          </Text>
          <RhfInput
            defaultValue={emailInfo?.phone || ''}
            fullWidth
            control={control}
            name="managerPhone"
            rules={{
              pattern: {
                value: /^\d*$/,
                message: '数字を使用してください。',
              },
            }}
            placeholder="事業者名または事業者氏名/屋号の所在地電話番号を入力"
          />
          {errors.managerPhone && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerPhone.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス</InputLabel>
          <RhfInput
            name="mailAddress"
            control={control}
            fullWidth
            defaultValue={emailInfo?.email || ''}
            placeholder="メールアドレス"
            rules={{
              required: 'メールアドレスを入力してください。',
              pattern: {
                value: emailPattern,
                message: '入力形式がメールアドレスではありません。',
              },
            }}
          />
          {errors.mailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.mailAddress.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス（確認）</InputLabel>
          <RhfInput
            name="confirmMailAddress"
            control={control}
            fullWidth
            defaultValue=""
            placeholder="メールアドレス（確認）"
            rules={{
              required: 'メールアドレスを入力してください。',
              validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
            }}
          />
          {errors.confirmMailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.confirmMailAddress.message}
            </Text>
          )}
        </Box>
      </Box>

      <Box display="flex" pb={24} pt={22} justifyContent="space-between" gap={5}>
        <MainButton fullWidth customHeight={40} variant="secondary" onClick={toBack}>
          確認コード入力に戻る
        </MainButton>
        <MainButton
          fullWidth 
          customHeight={40}
          onClick={() => {
            onSubmit();
          }}
        >
          運営へ報告
        </MainButton>
      </Box>
    </Box>
  );
  // feat screen No_10 end
}
