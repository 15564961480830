import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Option } from 'pages/s09/components/types';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-04-02
 * ブランド選択モーダル
 */

function SelectBrandModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onClickNext: () => void;
  options: Option[];
  selectedBrand: Option | null;
  onChangeBrand: (brand: Option) => void;
}) {
  const { isOpen, onModalClose, onClickNext, options, onChangeBrand, selectedBrand } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        グループ招待 ブランド選択
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box>
          <Text variant="body14" color="black">
            ブランドを選択して「次へ」を押してください。
          </Text>
        </Box>
        <Box my={16}>
          <Text variant="h3" color="blueGray">
            ブランド
          </Text>
          <Select
            placeholder="ブランドを選択してください"
            value={selectedBrand?.value || ''}
            options={options}
            onChange={(value, label) => onChangeBrand({ value, label })}
          />
        </Box>
      </Box>
    ) : (
      // feat_screen_09-04-02_start
      <Box display="flex" flexDirection="column" gap={12}>
          <Text display="block" variant="body14" color="black">
            ブランドを選択して「次へ」を押してください。
          </Text>
        <Box display="flex" flexDirection="column" gap={4}>
          <Text variant="body14" color="blueGray" bold>
            ブランド
          </Text>
          <Select
            placeholder="ブランドを選択してください"
            value={selectedBrand?.value || ''}
            options={options}
            onChange={(value, label) => onChangeBrand({ value, label })}
            height={40}
            fullWidth
          />
        </Box>
      </Box>
      // feat_screen_09-04-02_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_09-04-02_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-02_end
          variant="secondary" 
          onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          // feat_screen_09-04-02_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-02_end
          onClick={() => onClickNext()} 
          disabled={selectedBrand === null}
        >
          次へ
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat_screen_09-04-02_start
      width={!isMobile ? 384 : "100%"}
      // feat_screen_09-04-02_end
      overflow="visible"
    />
  );
}

export default SelectBrandModal;
