import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { List } from 'components/list';
import { PaginationBox } from 'components/pagination';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import React, { useMemo, useEffect } from 'react';
import * as gql from 'graphql/graphql-mw';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ListHeaderType, ListItemsType } from '../type';

export function MonthlyWithouthold() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const navigate = useNavigate();
  const auth = useAuth();
  const tpmemId = auth?.tpmemId;

  if (tpmemId === null) {
    navigate('/signin');
  }

  const {
    data: { getVTMWithholdListByTpmemId } = {
      getVTMWithholdListByTpmemId: [] as gql.VtWithholdList[],
    },
    loading,
    error: getVTMWithholdListByTpmemIdError,
  } = gql.useGetVtmWithholdListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (getVTMWithholdListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVTMWithholdListByTpmemIdError, openErrorModal]);

  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const listHeader: ListHeaderType = useMemo(
    () => [
      // feat_screen_07-02-01_04 start
      { key: 'pay_month', width: !isMobile ? 120 : 108, columnName: '集計年月' },
      { key: 'salary', width: !isMobile ? 98 : 115, columnName: '給与総額' },
      // feat_screen_07-02-01_04 start
      { key: 'tax', width: 136, columnName: '源泉徴収税総額' },
      { key: 'workers', width: 152, columnName: '対象人数' },
    ],
    // feat_screen_07-02-01_04 start
    [isMobile]
    // feat_screen_07-02-01_04 end
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading || getVTMWithholdListByTpmemId.length === 0) return [];
    let items = getVTMWithholdListByTpmemId as gql.VtWithholdList[];

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else if (sort.key === 'pay_month') {
        // 数値変換
        items = orderBy(items, (item) => parseInt(item.pay_month?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      pay_month: item.pay_month,
      salary: `¥${item.salary ? item.salary.toLocaleString() : 0}`,
      tax: `¥${item.tax ? item.tax.toLocaleString() : 0}`,
      workers: item.workers,
      uniqueKey: key,
    }));
  }, [loading, getVTMWithholdListByTpmemId, sort]);

  // feat_screen_07-02-01_04 start
  return !isMobile ? (
    // feat_screen_07-02-01_04 end
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" flex="auto">
        <Box flex="auto" width="100%" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={385}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={(key) => {
                  handleChangeSort(key);
                  handleChangePage(1);
                }}
                rowHeight={34}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
    </>
    // feat_screen_07-02-01_04 start
  ) : (
    <>
      {loading && <Loading />}
      <Box flex="auto" width="100%" mt={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={305}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={(key) => {
                  handleChangeSort(key);
                  handleChangePage(1);
                }}
                rowHeight={57}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
    </>
    // feat_screen_07-02-01_04 end
  );
}
