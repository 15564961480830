// feat custom hooks for mobile screen-start 
import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

// Custom hook use Mobile=> specified device is Mobile or not
const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return isMobile;
};

export default useIsMobile;
// feat custom hooks for mobile screen-end
