import React, { useEffect, useState } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { UseFormReturn } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { Hours, Minutes } from 'components/select/selectOptions';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { styled } from '@linaria/react';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { DatePicker } from 'components/datePicker';
import _ from 'lodash';
import getYear from 'date-fns/getYear';
import { parseTimeString } from 'components/utils';
import { GetVtMemberBaseByIdQuery } from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import Divider from 'components/divider/divider';
import { StoreFormType } from '../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onNext: (data: StoreFormType) => void;
  data?: GetVtMemberBaseByIdQuery['getVTMemberBaseById'] | null;
  form: UseFormReturn<StoreFormType>;
}

const StyledLabel = styled.label`
  cursor: pointer;
`;

const HiddenBox = styled.div`
  display: none;
`;

function EditStoreInfoModal(props: Props) {
  const { isOpen, onClose, onNext, form, data } = props;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = form;

  const [photoData, setPhotoData] = React.useState<string | undefined>(data?.image || undefined);
  const [photoErrorMessage, setPhotoErrorMessage] = useState<string | undefined>(undefined);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const currentPhoto = watch('photo');

  const isOpen24hour = data?.open_time === data?.close_time;
  const currentIs24Hour = watch('is24Hour', isOpen24hour);

  const openTime = parseTimeString(data?.open_time ?? '');
  const openHour = openTime.hour ? openTime.hour.toString() : '';
  const openMinutes = openTime.minute ? openTime.minute.toString().padStart(2, '0') : '';
  const closeTime = parseTimeString(data?.close_time ?? '');
  const closeHour = closeTime.hour ? closeTime.hour.toString() : '';
  const closeMinutes = closeTime.minute ? closeTime.minute.toString().padStart(2, '0') : '';

  const formattedManagerBirthday = data?.mgr_birthday?.replace(/年|月/g, '-').replace('日', '');
  const currentMangagerBirthDate = watch('managerBirthDate') || new Date(formattedManagerBirthday ?? '');

  useEffect(() => {
    if (currentIs24Hour) {
      setValue('fromHour', '0');
      setValue('fromMin', '00');
      setValue('toHour', '0');
      setValue('toMin', '00');
    }
  }, [currentIs24Hour, setValue]);

  useEffect(() => {
    setValue('managerBirthDate', new Date(formattedManagerBirthday ?? ''));
    if (currentPhoto && currentPhoto.length > 0) {
      setPhotoErrorMessage(undefined);
      const file = currentPhoto[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (result) {
          setPhotoData(result as string);
        }
      };
      reader.readAsDataURL(file);
    }
    return () => {
      setPhotoData(data?.image || '');
    };
  }, [currentPhoto, formattedManagerBirthday, setValue, data?.image]);

  const onClickNext = () => {
    if (!currentPhoto && !data?.image) {
      setPhotoErrorMessage('画像を選択してください');
      return;
    }
    setPhotoErrorMessage(undefined);
    handleSubmit((d) => {
      onNext(d);
    })();
  };

  const header = (
    <Text variant="h2" color="darkBlue">
      基本情報編集 店舗情報
    </Text>
  );
  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = data?.biz_name ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = data?.brand_name ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  const content = !isMobile ? (
    <Box>
      <Box>
        <Text variant="body14">店舗情報を修正、入力して次へを押してください。</Text>
      </Box>
      <Box display="flex" mt={20}>
        <Box width="50%">
          <Box>
            <Text variant="h3" color="blueGray">
              業種
            </Text>
          </Box>
          <Box>
            <Text variant="body14">{editBizName}</Text>
          </Box>
        </Box>
        <Box width="50%">
          <Box>
            <Text variant="h3" color="blueGray">
              ブランド
            </Text>
          </Box>
          <Box>
            <Text variant="body14">{editBrandName}</Text>
          </Box>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            店舗名
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.store_name}</Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            店舗画像
          </Text>
        </Box>
        <Box>
          <img src={photoData} alt="" width={160} height={120} />
          {/* eslint-disable jsx-a11y/label-has-associated-control */}
          <Box mt={8}>
            <MainButton variant="secondary" width={104}>
              <StyledLabel htmlFor="photo">画像を選択</StyledLabel>
            </MainButton>
            <HiddenBox>
              {/* RhfInputだとエラーになるため素のinputを使っています */}
              <input
                type="file"
                id="photo"
                onChange={(val) => {
                  setValue('photo', val.target.files);
                }}
                accept="image/jpeg, image/png"
              />
            </HiddenBox>
            <Box>
              {photoErrorMessage && (
                <Text variant="caption11" color="cautionRed">
                  {photoErrorMessage}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            郵便番号
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.zip_code}</Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            住所
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{`${data?.state ?? ''} ${data?.city ?? ''} ${data?.address ?? ''} ${
            data?.address2 ?? ''
          }`}</Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.phone}</Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            メールアドレス
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.email}</Text>
        </Box>
        <Box>
          <Text color="cautionRed" variant="caption11">
            メールアドレスの変更はアカウント管理からできます
          </Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            営業時間
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <RhfSelect
            name="fromHour"
            options={Hours}
            control={control}
            width={80}
            defaultValue={openHour}
            disabled={currentIs24Hour}
            rules={{ required: '営業開始時間を入力してください' }}
          />
          <Box>&nbsp;:&nbsp;</Box>
          <RhfSelect
            name="fromMin"
            options={Minutes}
            control={control}
            width={80}
            defaultValue={openMinutes.padStart(2, '0')}
            disabled={currentIs24Hour}
            rules={{ required: '営業開始時間（分）を入力してください' }}
          />
          <Box>&nbsp;〜&nbsp;</Box>
          <RhfSelect
            name="toHour"
            options={Hours}
            control={control}
            width={80}
            defaultValue={closeHour}
            disabled={currentIs24Hour}
            rules={{ required: '営業終了時間を入力してください' }}
          />
          <Box>&nbsp;:&nbsp;</Box>
          <RhfSelect
            name="toMin"
            options={Minutes}
            control={control}
            width={80}
            defaultValue={closeMinutes.padStart(2, '0')}
            disabled={currentIs24Hour}
            rules={{ required: '営業終了時間（分）を入力してください' }}
          />
          <Box ml={8}>
            <RhfCheckbox name="is24Hour" control={control} defaultValue={isOpen24hour}>
              24時間営業
            </RhfCheckbox>
          </Box>
        </Box>
        <Box>
          {errors.fromHour && (
            <Text variant="caption11" color="cautionRed">
              {errors.fromHour.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.fromMin && (
            <Text variant="caption11" color="cautionRed">
              {errors.fromMin.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.toHour && (
            <Text variant="caption11" color="cautionRed">
              {errors.toHour.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.toMin && (
            <Text variant="caption11" color="cautionRed">
              {errors.toMin.message}
            </Text>
          )}
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            従業員駐車場
          </Text>
        </Box>
        <Box>
          <RhfRadioGroup
            name="hasParking"
            options={[
              { label: '有', value: 'yes' },
              { label: '無', value: 'no' },
            ]}
            control={control}
            defaultValue={data?.parking_val ? 'yes' : 'no'}
          />
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            責任者氏名
          </Text>
        </Box>
        <Box display="flex" gap={16}>
          <Box>
            <RhfInput
              type="text"
              name="managerFamilyName"
              defaultValue={data?.mgr_last_name ?? ''}
              control={control}
              width={120}
              rules={{ required: '責任者氏名（名字）を入力してください' }}
            />
          </Box>
          <Box>
            <RhfInput
              type="text"
              name="managerFirstName"
              defaultValue={data?.mgr_first_name ?? ''}
              control={control}
              width={120}
              rules={{ required: '責任者氏名（名前）を入力してください' }}
            />
          </Box>
        </Box>
        <Box>
          {errors.managerFamilyName && (
            <Text variant="caption11" color="cautionRed">
              {errors.managerFamilyName.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.managerFirstName && (
            <Text variant="caption11" color="cautionRed">
              {errors.managerFirstName.message}
            </Text>
          )}
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              責任者氏名（フリガナ）
            </Text>
          </Box>
          <Box display="flex" gap={16}>
            <Box>
              <RhfInput
                type="text"
                name="managerFamilyNameKana"
                defaultValue={data?.mgr_last_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '責任者氏名（フリガナ）（名字）を入力してください' }}
              />
            </Box>
            <Box>
              <RhfInput
                type="text"
                name="managerFirstNameKana"
                defaultValue={data?.mgr_first_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '責任者氏名（フリガナ）（名前）を入力してください' }}
              />
            </Box>
          </Box>
          <Box>
            {errors.managerFamilyNameKana && (
              <Text variant="caption11" color="cautionRed">
                {errors.managerFamilyNameKana.message}
              </Text>
            )}
          </Box>
          <Box>
            {errors.managerFirstNameKana && (
              <Text variant="caption11" color="cautionRed">
                {errors.managerFirstNameKana.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              責任者生年月日
            </Text>
          </Box>
          <Box display="flex" gap={16}>
            <DatePicker
              onChange={(value) => {
                if (value) {
                  setValue('managerBirthDate', value);
                  trigger('managerBirthDate');
                }
              }}
              placeholderText="責任者生年月日"
              selected={currentMangagerBirthDate}
              width={168}
              yearRange={_.range(getYear(new Date()) - 80, getYear(new Date()) - 20, 1)}
            />
            <RhfInput
              name="managerBirthDate"
              type="hidden"
              control={control}
              defaultValue={new Date(formattedManagerBirthday || '')}
              rules={{ required: '責任者生年月日を入力してください' }}
            />
          </Box>
          <Box>
            {errors.managerBirthDate && (
              <Text variant="caption11" color="cautionRed">
                {errors.managerBirthDate.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box mt={20}>
          <Box display="flex" alignItems="center">
            <Box width={300}>
              <Text variant="h3" color="blueGray">
                責任者電話番号
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${GENERIC_COLOR.OK_BLUE}`}
              borderRadius={2}
              height={16}
              width={28}
            >
              <Text variant="caption11" color="okBlue">
                任意
              </Text>
            </Box>
          </Box>
          <Box>
            <RhfInput
              type="text"
              name="managerPhone"
              defaultValue={data?.mgr_phone ?? ''}
              control={control}
              width={328}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    // feat_screen_09-01-02_start
    <Box display="flex" flexDirection="column" gap={15.5}>

      <Box>
        <Text display="block" variant="body14">店舗情報を修正、入力して次へを押してください。</Text>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            業種
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{editBizName}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            ブランド
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{editBrandName}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>
      
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            店舗名
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{data?.store_name}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            店舗画像
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <img src={photoData} alt="" width={126} height={95} />
          {/* eslint-disable jsx-a11y/label-has-associated-control */}
          <Box mt={10}>
            <MainButton variant="secondary" width={96}>
              <StyledLabel htmlFor="photo">画像を選択</StyledLabel>
            </MainButton>
            <HiddenBox>
              {/* RhfInputだとエラーになるため素のinputを使っています */}
              <input
                type="file"
                id="photo"
                onChange={(val) => {
                  setValue('photo', val.target.files);
                }}
                accept="image/jpeg, image/png"
              />
            </HiddenBox>
            <Box>
              {photoErrorMessage && (
                <Text variant="caption11" color="cautionRed">
                  {photoErrorMessage}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            郵便番号
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{data?.zip_code}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            住所
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{`${data?.state ?? ''} ${data?.city ?? ''} ${data?.address ?? ''} ${
            data?.address2 ?? ''
          }`}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{data?.phone}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box width={85}>
            <Text variant="h3" color="blueGray">
              メールアドレス
            </Text>
          </Box>
          <Box display="flex">
            <Text variant="body14">{data?.email}</Text>
          </Box>
        </Box>
        <Box>
          <Text color="cautionRed" variant="caption11">
            メールアドレスの変更はアカウント管理からできます
          </Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        
        <Box width={85}>
          <Text variant="h3" color="blueGray">
            営業時間
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" justifyContent="space-between" gap={5}>
            <Box display="flex" flexDirection="column" width="100%">
              <RhfSelect
                name="fromHour"
                options={Hours}
                control={control}
                fullWidth
                height={40}
                defaultValue={openHour}
                disabled={currentIs24Hour}
                rules={{ required: '営業開始時間を入力してください' }}
              />
              {errors.fromHour && (
                <Text variant="caption11" color="cautionRed">
                  {errors.fromHour.message}
                </Text>
              )}
            </Box>
            <Box display="flex" alignItems="center">&nbsp;:&nbsp;</Box>
            <Box display="flex" flexDirection="column" width="100%">
              <RhfSelect
                name="fromMin"
                options={Minutes}
                control={control}
                fullWidth
                height={40}
                defaultValue={openMinutes.padStart(2, '0')}
                disabled={currentIs24Hour}
                rules={{ required: '営業開始時間（分）を入力してください' }}
              />
              {errors.fromMin && (
                <Text variant="caption11" color="cautionRed">
                  {errors.fromMin.message}
                </Text>
              )}
            </Box>
          </Box>

          <Box>&nbsp;〜&nbsp;</Box>

          <Box display="flex" justifyContent="space-between" flexDirection="column" gap={8}>
            <Box display="flex" justifyContent="space-between" gap={5}>
              <Box display="flex" flexDirection="column" width="100%">
                <RhfSelect
                  name="toHour"
                  options={Hours}
                  control={control}
                  fullWidth
                  height={40}
                  defaultValue={closeHour}
                  disabled={currentIs24Hour}
                  rules={{ required: '営業終了時間を入力してください' }}
                />
                {errors.toHour && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.toHour.message}
                  </Text>
                )}
              </Box>
              <Box display="flex" alignItems="center">&nbsp;:&nbsp;</Box>
              <Box display="flex" flexDirection="column" width="100%">
                <RhfSelect
                  name="toMin"
                  options={Minutes}
                  control={control}
                  fullWidth
                  height={40}
                  defaultValue={closeMinutes.padStart(2, '0')}
                  disabled={currentIs24Hour}
                  rules={{ required: '営業終了時間（分）を入力してください' }}
                />
                {errors.toMin && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.toMin.message}
                  </Text>
                )}
              </Box>
            </Box>
            <Box>
              <RhfCheckbox name="is24Hour" control={control} defaultValue={isOpen24hour}>
                24時間営業
              </RhfCheckbox>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box width={100}>
          <Text variant="h3" color="blueGray">
            従業員駐車場
          </Text>
        </Box>
        <Box>
          <RhfRadioGroup
            name="hasParking"
            options={[
              { label: '有', value: 'yes' },
              { label: '無', value: 'no' },
            ]}
            control={control}
            defaultValue={data?.parking_val ? 'yes' : 'no'}
          />
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Text variant="h3" color="blueGray">
          責任者氏名
        </Text>
        <Box display="flex" gap={15}>
          <div style={{width: "100%"}}>
            <Box display="flex" flexDirection="column">
              <RhfInput
                type="text"
                name="managerFamilyName"
                defaultValue={data?.mgr_last_name ?? ''}
                control={control}
                fullWidth
                rules={{ required: '責任者氏名（名字）を入力してください' }}
              />
              {errors.managerFamilyName && (
                <Text variant="caption11" color="cautionRed">
                  {errors.managerFamilyName.message}
                </Text>
              )}
            </Box>
          </div>
          <div style={{width: "100%"}}>
            <Box display="flex" flexDirection="column">
              <RhfInput
                type="text"
                name="managerFirstName"
                defaultValue={data?.mgr_first_name ?? ''}
                control={control}
                fullWidth
                rules={{ required: '責任者氏名（名前）を入力してください' }}
              />
              {errors.managerFirstName && (
                <Text variant="caption11" color="cautionRed">
                  {errors.managerFirstName.message}
                </Text>
              )}
            </Box>
          </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Text variant="h3" color="blueGray">
          責任者氏名（フリガナ）
        </Text>
        <Box display="flex" gap={15}>
          <div style={{width: "100%"}}>
            <Box display="flex" flexDirection="column">
              <RhfInput
                type="text"
                name="managerFamilyNameKana"
                defaultValue={data?.mgr_last_kana ?? ''}
                control={control}
                fullWidth
                rules={{ required: '責任者氏名（フリガナ）（名字）を入力してください' }}
              />
              {errors.managerFamilyNameKana && (
                <Text variant="caption11" color="cautionRed">
                  {errors.managerFamilyNameKana.message}
                </Text>
              )}
            </Box>
          </div>
          <div style={{width: "100%"}}>
            <Box display="flex" flexDirection="column">
              <RhfInput
                type="text"
                name="managerFirstNameKana"
                defaultValue={data?.mgr_first_kana ?? ''}
                control={control}
                fullWidth
                rules={{ required: '責任者氏名（フリガナ）（名前）を入力してください' }}
              />
              {errors.managerFirstNameKana && (
                <Text variant="caption11" color="cautionRed">
                  {errors.managerFirstNameKana.message}
                </Text>
              )}
            </Box>
          </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Text variant="h3" color="blueGray">
            責任者生年月日
          </Text>
        </Box>
        <Box display="flex">
          <Box width="100%">
            <DatePicker
              onChange={(value) => {
                if (value) {
                  setValue('managerBirthDate', value);
                  trigger('managerBirthDate');
                }
              }}
              placeholderText="責任者生年月日"
              selected={currentMangagerBirthDate}
              yearRange={_.range(getYear(new Date()) - 80, getYear(new Date()) - 20, 1)}
              popperPlacement="top"
            />
            <RhfInput
              name="managerBirthDate"
              type="hidden"
              control={control}
              defaultValue={new Date(formattedManagerBirthday || '')}
              rules={{ required: '責任者生年月日を入力してください' }}
            />
          </Box>
        </Box>
        {errors.managerBirthDate && (
          <Text variant="caption11" color="cautionRed">
            {errors.managerBirthDate.message}
          </Text>
        )}
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={120}>
            <Text variant="h3" color="blueGray">
              責任者電話番号
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            border={`1px solid ${GENERIC_COLOR.OK_BLUE}`}
            borderRadius={2}
            height={16}
            width={28}
          >
            <Text variant="caption11" color="okBlue">
              任意
            </Text>
          </Box>
        </Box>
        <Box>
          <RhfInput
            type="text"
            name="managerPhone"
            defaultValue={data?.mgr_phone ?? ''}
            control={control}
            fullWidth
          />
        </Box>
      </Box>

    </Box>
    // feat_screen_09-01-02_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-01-02_start
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        // feat_screen_09-01-02_end
        variant="secondary"
        onClick={() => {
          setPhotoData(data?.image || '');
          setPhotoErrorMessage(undefined);
          onClose();
        }}
      >
        キャンセル
      </MainButton>
      <MainButton 
        // feat_screen_09-01-02_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-02_end
        variant="primary" 
        onClick={() => onClickNext()}
      >
        次へ
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setPhotoData(data?.image || '');
        onClose();
      }}
      header={header}
      content={content}
      footer={footer}
      // feat_screen_09-01-02_start
      height={!isMobile ? "90vh" : "72.5svh"}
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-01-02_end
    />
  );
}

export default EditStoreInfoModal;
