import axios from 'axios';

export type ReportChangEmailProps = {
  tpcode: string;
  firstname: string;
  lastname: string;
  firstkana: string;
  lastkana: string;
  dob: string;
  mconame: string;
  zipcode: string;
  phoneno: string;
  newemail: string;
};

export default async function reportChangEmail(props: ReportChangEmailProps) {
  const { tpcode, firstname, lastname, firstkana, lastkana, dob, mconame, zipcode, phoneno, newemail } = props;

  const headers = {
    'x-api-key': process.env.REACT_APP_EMAIL_REPORT_API_KEY,
  };

  const data = {
    fieldName: 'sendMailRequestChangeEmail',
    arguments: {
      tp_code: tpcode,
      first_name: firstname,
      last_name: lastname,
      first_kana: firstkana,
      last_kana: lastkana,
      dob,
      mco_name: mconame,
      zip_code: zipcode,
      phone_no: phoneno,
      new_email: newemail,
    },
  };
  const response = await axios.post(process.env.REACT_APP_EMAIL_REPORT_API_URL || '', data, {
    headers,
  });
  return response;
}
