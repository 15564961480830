/**
 * Figma ID: 08-08-01
 * 名称: 募集画像
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { useGetSTpmemRecruitByTpmemIdQuery, useUpdateSTpmemRecruitImageByTIdMutation } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { uploadFile } from 'components/api';
import { downloadImage } from 'components/image';
import { ResultRows } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { DeleteModal } from './deleteModal';
import { UpdateModal } from './updateModal';

type ImageListModel = {
  indexName: string;
  imageURL: string;
  imageName: string;
  date: string;
};
export function RecruitmentImage() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { tpmemId } = useAuth();
  const {
    data: imageList,
    loading: imageListLoading,
    error: imageListError,
    refetch: imageListRefetch,
  } = useGetSTpmemRecruitByTpmemIdQuery({
    variables: { tpmem_id: Number(tpmemId) },
  });
  const [updateImageMutation] = useUpdateSTpmemRecruitImageByTIdMutation();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const inputImageId = 'inputImageId';
  const [selectedImage, setSelectedImage] = React.useState<string>();
  const [inputImageFile, setInputImageFile] = React.useState<FileList | null>();
  const [updatedImage, setUpdatedImage] = React.useState<string>();
  const [isNewImageUpload, setIsNewImageUpload] = React.useState<boolean>();
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (imageListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [imageListError, openErrorModal]);

  // 入力エリアクリア
  const inputClear = () => {
    const inputElement = document.getElementById(inputImageId) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = '';
    }
  };

  const updateImage = (inputImage: FileList | null) => {
    const today = new Date();
    const date = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}}`;
    if (inputImage) {
      const filePath = `/store-data/${String(tpmemId)}${String(selectedImage)}.png`;
      const uploadPath = (process.env.REACT_APP_FILE_READ_URL ?? '') + filePath;
      (async () => {
        try {
          await uploadFile({
            filePath,
            file: inputImage[0],
          });
          switch (selectedImage) {
            case 'image1':
              updateImageMutation({
                variables: { tpmem_id: tpmemId ?? 0, image1: uploadPath, image1_date: date },
              })
                .then((result) => {
                  if (result.data?.updateSTpmemRecruitImageByTId.resultRows === ResultRows.UPDATED) {
                    imageListRefetch();
                  } else {
                    openErrorModal({
                      message: isUpdateModalOpen
                        ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
                        : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
                    });
                  }
                  setIsUpdateModalOpen(false);
                })
                .catch(() => {
                  setIsUpdateModalOpen(false);
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
              break;
            case 'image2':
              updateImageMutation({
                variables: { tpmem_id: tpmemId ?? 0, image2: uploadPath, image2_date: date },
              })
                .then((result) => {
                  if (result.data?.updateSTpmemRecruitImageByTId.resultRows === ResultRows.UPDATED) {
                    imageListRefetch();
                  } else {
                    openErrorModal({
                      message: isUpdateModalOpen
                        ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
                        : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
                    });
                  }
                  setIsUpdateModalOpen(false);
                })
                .catch(() => {
                  setIsUpdateModalOpen(false);
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
              break;
            case 'image3':
              updateImageMutation({
                variables: { tpmem_id: tpmemId ?? 0, image3: uploadPath, image3_date: date },
              })
                .then((result) => {
                  if (result.data?.updateSTpmemRecruitImageByTId.resultRows === ResultRows.UPDATED) {
                    imageListRefetch();
                  } else {
                    openErrorModal({
                      message: isUpdateModalOpen
                        ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
                        : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
                    });
                  }
                  setIsUpdateModalOpen(false);
                })
                .catch(() => {
                  setIsUpdateModalOpen(false);
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
              break;
            case 'image4':
              updateImageMutation({
                variables: { tpmem_id: tpmemId ?? 0, image4: uploadPath, image4_date: date },
              })
                .then((result) => {
                  if (result.data?.updateSTpmemRecruitImageByTId.resultRows === ResultRows.UPDATED) {
                    imageListRefetch();
                  } else {
                    openErrorModal({
                      message: isUpdateModalOpen
                        ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
                        : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
                    });
                  }
                  setIsUpdateModalOpen(false);
                })
                .catch(() => {
                  setIsUpdateModalOpen(false);
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
              break;
            case 'image5':
              updateImageMutation({
                variables: { tpmem_id: tpmemId ?? 0, image5: uploadPath, image5_date: date },
              })
                .then((result) => {
                  if (result.data?.updateSTpmemRecruitImageByTId.resultRows === ResultRows.UPDATED) {
                    imageListRefetch();
                  } else {
                    openErrorModal({
                      message: isUpdateModalOpen
                        ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
                        : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
                    });
                  }
                  setIsUpdateModalOpen(false);
                })
                .catch(() => {
                  setIsUpdateModalOpen(false);
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
              break;
            default:
              break;
          }
          setIsNewImageUpload(undefined);
          setInputImageFile(null);
          // 入力エリアクリア
          inputClear();
        } catch (e) {
          setIsUpdateModalOpen(false);
          openErrorModal({
            message: isUpdateModalOpen
              ? '画像の上書きに失敗しました。時間を空けて再度お試しください。'
              : '画像のアップロードに失敗しました。時間を空けて再度お試しください。',
          });
        }
      })();
    }
    if (inputImageFile) {
      const file = inputImageFile[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (result) {
          setUpdatedImage(result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const getImageName = (imageName: string[] | undefined) => {
    if (imageName) {
      return imageName[imageName.length - 1];
    }
    return '';
  };

  function uploadImage(isNew: boolean) {
    setIsNewImageUpload(isNew);
    // ファイル選択ダイアログを開く
    document.getElementById(inputImageId)?.click();
  }
  // キャッシュクリア対応 画像名が同じ為、最新に変わらない
  const getUrl = (imageName: string): string => {
    const timestamp = new Date().getTime(); // タイムスタンプを取得
    const imageURL = `${imageName}?timestamp=${timestamp}`; // タイムスタンプと合わせる
    return imageURL;
  };

  const listContent = (item: ImageListModel, imageIndex: string) =>
    item.imageURL === undefined || item.imageURL.length === 0 || item.imageURL === 'noImage' ? (
      <Box alignItems="center">
        {/* feat screen 08-08-01 start */}
        <Box justifyContent="space-between" display="flex" alignItems="center" height={!isMobile ? 60 : "unset"}>
          {/* feat screen 08-08-01 end */}
          <Box display="flex" alignItems="center">
            {/* feat screen 08-08-01 start */}
            <Box pr={!isMobile ? 37 : 10} alignItems="center">
              {/* feat screen 08-08-01 end */}
              <Text variant="h3" color="blueGray">
                {item.indexName}
              </Text>
            </Box>
            <Text variant="body14">未設定</Text>
          </Box>
          <ThreeDotLeader
            right={0}
            menu={[{ label: 'アップロード', value: 'upload' }]}
            onClick={() => {
              setSelectedImage(imageIndex);
              uploadImage(true);
              setUpdatedImage(undefined);
            }}
          />
        </Box>
        {/* feat screen 08-08-01 start */}
        <Box py={!isMobile ? 8 : 12}>
          {/* feat screen 08-08-01 end */}
          <Divider option="horizontal" />
        </Box>
      </Box>
    ) : (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={10}>
            {/* feat screen 08-08-01 start */}
            <Box pr={!isMobile? 37 : 0}>
              {/* feat screen 08-08-01 end */}
              <Text variant="h3" color="blueGray">
                {item.indexName}
              </Text>
            </Box>
            <img
              // 画像のURLはいつも同じため、refetchをしても画像だけは更新されません。
              // その対策として、更新直後だけは、取得したイメージをupdatedImageにセットして、表示しています。
              // 上書きでおかしくなるのでタイムスタンプを付加
              src={getUrl(item.imageURL)}
              alt={item.imageName}
              width={80}
              height={60}
            />
            <Box display="grid">
              <Text variant="body14">{item.imageName}</Text>
              <Text variant="caption11" color="darkGray">
                {item.date}
              </Text>
            </Box>
          </Box>
          <ThreeDotLeader
            right={0}
            menu={[
              { label: '上書き', value: 'override' },
              { label: 'ダウンロード', value: 'download' },
              { label: '削除', value: 'delete' },
            ]}
            onClick={(value) => {
              setSelectedImage(imageIndex);
              switch (value) {
                case 'override':
                  uploadImage(false);
                  break;
                case 'delete':
                  setIsDeleteModalOpen(true);
                  break;
                case 'download':
                  downloadImage(item.imageName, item.imageURL);
                  break;
                default:
                  break;
              }
            }}
          />
        </Box>
        {/* feat screen 08-08-01 start */}
        <Box py={!isMobile ? 8 : 16}>
          {/* feat screen 08-08-01 end */}
          <Divider option="horizontal" />
        </Box>
      </Box>
    );
  //* feat screen 08-08-01 start
  return !isMobile ? (
    <Box width="100%" px={24}>
      {imageListLoading && <Loading />}
      <Box display="flex" alignItems="center" gap={8} py={18}>
        <Text variant="h2" color="darkBlue">
          募集画像
        </Text>
        <Text variant="caption12" color="darkBlue">
          募集用画像を最大５枚まで登録できます。（対応画像フォーマット：jpeg,jpg,png）
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box pt={16}>
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image1?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image1 ?? '',
            indexName: '画像1',
            date: imageList?.getSTpmemRecruitByTpmemId.image1_date ?? '',
          },
          'image1'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image2?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image2 ?? '',
            indexName: '画像2',
            date: imageList?.getSTpmemRecruitByTpmemId.image2_date ?? '',
          },
          'image2'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image3?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image3 ?? '',
            indexName: '画像3',
            date: imageList?.getSTpmemRecruitByTpmemId.image3_date ?? '',
          },
          'image3'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image4?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image4 ?? '',
            indexName: '画像4',
            date: imageList?.getSTpmemRecruitByTpmemId.image4_date ?? '',
          },
          'image4'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image5?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image5 ?? '',
            indexName: '画像5',
            date: imageList?.getSTpmemRecruitByTpmemId.image5_date ?? '',
          },
          'image5'
        )}
      </Box>
      <UpdateModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        updateImage={() => {
          updateImage(inputImageFile ?? null);
        }}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        tpmemId={Number(tpmemId)}
        selectedImageName={selectedImage ?? ''}
        refetch={() => {
          imageListRefetch();
        }}
      />
      <input
        type="file"
        id={inputImageId}
        hidden
        onChange={(e) => {
          setInputImageFile(e.target.files);
          if (isNewImageUpload) {
            updateImage(e.target.files);
          } else {
            setIsUpdateModalOpen(true);
          }
        }}
      />
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%" px={16} height="calc(100% - 49px)" overflow="auto">
      {imageListLoading && <Loading />}
      <Box display="flex" flexDirection="column" alignItems="center" pt={17} pb={14} px={8}>
        <Text variant="h2" color="darkBlue">
          募集画像
        </Text>
        <Text align="center" variant="caption12" color="darkBlue">
          募集用画像を最大５枚まで登録できます。（対応画像フォーマット：jpeg,jpg,png）
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box pt={16}>
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image1?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image1 ?? '',
            indexName: '画像1',
            date: imageList?.getSTpmemRecruitByTpmemId.image1_date ?? '',
          },
          'image1'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image2?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image2 ?? '',
            indexName: '画像2',
            date: imageList?.getSTpmemRecruitByTpmemId.image2_date ?? '',
          },
          'image2'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image3?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image3 ?? '',
            indexName: '画像3',
            date: imageList?.getSTpmemRecruitByTpmemId.image3_date ?? '',
          },
          'image3'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image4?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image4 ?? '',
            indexName: '画像4',
            date: imageList?.getSTpmemRecruitByTpmemId.image4_date ?? '',
          },
          'image4'
        )}
        {listContent(
          {
            imageName: getImageName(imageList?.getSTpmemRecruitByTpmemId.image5?.split('/')),
            imageURL: imageList?.getSTpmemRecruitByTpmemId.image5 ?? '',
            indexName: '画像5',
            date: imageList?.getSTpmemRecruitByTpmemId.image5_date ?? '',
          },
          'image5'
        )}
      </Box>
      <UpdateModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        updateImage={() => {
          updateImage(inputImageFile ?? null);
        }}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        tpmemId={Number(tpmemId)}
        selectedImageName={selectedImage ?? ''}
        refetch={() => {
          imageListRefetch();
        }}
      />
      <input
        type="file"
        id={inputImageId}
        hidden
        onChange={(e) => {
          setInputImageFile(e.target.files);
          if (isNewImageUpload) {
            updateImage(e.target.files);
          } else {
            setIsUpdateModalOpen(true);
          }
        }}
      />
    </Box>
  //* feat screen 08-08-01 end
  );
}
