// モーダルダイアログタイプ
export const enum ModalType {
  NOTICE = 'notice',
  GROUP_INVITE = 'group_invite',
}

// 一覧行クリック時のアクション
export const enum RowAction {
  NOTICE_MODAL = '03-01-15',
  GROUP_INVITE_MODAL = '03-01-09',
  TRANSIT_MYPAGE_GROUP_INVITE = '09-04-01',
  TRANSIT_MYPAGE_BASE_INFO = '09-01-01',
  TRANSIT_B_OFFER = '04-03-02-01',
  TRANSIT_B_OFFER_CONTRACT = '04-03-05-01',
  TRANSIT_NEW_ARBEIT_STAND_BY = '04-02-02-01',
  TRANSIT_NEW_ARBEIT_IN_PROGRESS = '04-02-03-01',
  TRANSIT_NEW_ARBEIT_WAITING_COMPLETE = '04-02-04-01',
  TRANSIT_NEW_ARBEIT_CHECK_IN_OUT = '04-02-05-01',
  TRANSIT_NEW_ARBEIT_REQUEST_CORRECTION = '04-02-06-01',
  TRANSIT_NEW_ARBEIT_COMPLETE = '04-02-07-01',
  TRANSIT_NEW_ARBEIT_CANCEL = '04-02-08-01',
  TRANSIT_NEW_ARBEIT_NO_SHOW = '04-02-09-01',
  TRANSIT_NEW_ARBEIT_REVIEW = '04-02-07-04',
  TRANSIT_ARBEIT_OFFER_APPLY = '04-01-01-13',
  TRANSIT_BT_BASE_INFO = '06-02-01',
  TRANSIT_BT_THANK_MESSAGE = '05-01-05-01',
}
