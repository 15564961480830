import { Modal } from 'components/newModal';
import { ChangeRateModalForm } from 'pages/s05-01/type';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { ApolloQueryResult } from '@apollo/client';
import { ChangeRate } from './changeRate';
import { Confirm } from './confirm';
import { Complete } from './complete';

enum ModalStep{
  CHANGE_RATE = 1,
  CONFIRM = 2,
  COMPOLETE = 3
}

export function ChangeRateModal(
  props: {
    isOpen: boolean,
    onClose: ()=> void;
    educRateRange: { min: number; max: number; }
    refetch: (variables?: Partial<gql.Exact<{ btmem_id: number; tpmem_id: number; }>> | undefined)
      => Promise<ApolloQueryResult<gql.GetVtBtmemEducInfoByTpmemIdBtmemIdQuery>>
  }
){
  const { isOpen, onClose, educRateRange, refetch } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CHANGE_RATE);

  const {
    control, reset, getValues, trigger
  } = useForm<ChangeRateModalForm>();

  const onModalClose = () => {
    reset();
    onClose();
    setModalStep(ModalStep.CHANGE_RATE);
  };

  const changeRateContent = ChangeRate(
    control, trigger, educRateRange, onModalClose,
    () => setModalStep(ModalStep.CONFIRM),
  );

  const confirmContent = Confirm(
    getValues, onModalClose,
    () => setModalStep(ModalStep.CHANGE_RATE),
    () => setModalStep(ModalStep.COMPOLETE)
  );

  const completeContent = Complete(
    onModalClose, refetch
  );

  const modalContent = {
    [ModalStep.CHANGE_RATE]: changeRateContent,
    [ModalStep.CONFIRM]: confirmContent,
    [ModalStep.COMPOLETE]: completeContent
  };


  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      overflow='visible'
      {...modalContent[modalStep]}
    />
  );
}