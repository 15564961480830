// feat screen 00 start
/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React, { useEffect } from 'react';
import { Box } from 'components/box';
import { Outlet, useNavigate } from 'react-router-dom';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function QuickAccess() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  useEffect(() => {
    if(window.innerWidth >= 768) {
      navigate("/", {replace: true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isMobile]);
  return (
    <Box width="100vw" height="100svh" justifyContent="center" pt={48} px={16}>
      <Outlet />
    </Box>
  );
}
// feat screen 00 end