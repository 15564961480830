import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import Divider from 'components/divider/divider';
import { Box } from 'components/box';
import { Text } from '../text';
import { Icon } from '../icon';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  items: {
    label: string;
    labelLength?: number;
    content: ReactNode;
    hasNotification?: boolean;
    callBack?: (index: number) => void;
  }[];
  defaultIndex?: number;
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTabWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  padding: 0 16px;
  gap: 4px;
  /* feat_common_responsive_tab_start */
  @media only screen and (max-width: 768px) {
    border-bottom: none;
    gap: 0;
  }
  /* feat_common_responsive_tab_end */
`;

const StyledTab = styled.button<{
  active: boolean;
  labelLength: number;
  /* feat_common_responsive_tab_add_props_start */
  position: number;
  /* feat_common_responsive_tab_add_props_end */
}>`
  position: relative;
  height: 40px;
  min-width: ${({ labelLength }) => labelLength}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  background-color: ${({ active }) => (active ? 'white' : '#FBFBFB')};
  cursor: pointer;
  /* feat_common_responsive_tab_start */
  @media only screen and (max-width: 768px) {
    background-color: white;
    border: none;
    width: 100%;
    padding: ${({ position }) => (position / 2 === 0 ? '0px 16px 0px 0px' : '0px 0px 0px 16px')};
    cursor: default;
  }
  /* feat_common_responsive_tab_end */
`;

const StyledWhiteBar = styled.div<{
  labelLength: number;
}>`
  height: 3px;
  width: ${({ labelLength }) => labelLength - 2}px;
  background-color: ${GENERIC_COLOR.WHITE};
  position: absolute;
  bottom: -2px;
  box-sizing: border-box;
`;

const StyledIndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
`;
// feat_navigate_to_screen_09-06-02_start
interface PropState {
  tabIndex: number;
}
// feat_navigate_to_screen_09-06-02_end
export function Tab({ items, defaultIndex }: Props) {
  // feat_navigate_to_screen_09-06-02_start
  const location = useLocation();
  const state = (location?.state || {}) as PropState;
  const tabIndex = state?.tabIndex;
  const [tab, setTab] = React.useState<number>((tabIndex || defaultIndex) || 0);
  // feat_navigate_to_screen_09-06-02_end
  React.useEffect(() => {
    if (defaultIndex !== undefined) setTab(defaultIndex);
  }, [defaultIndex]);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  
  return !isMobile ? (
    <StyledWrapper>
      <StyledTabWrapper>
        {items.map((item, index) => (
          <StyledTab
            // feat add keys warning start
            key={item.label}
            // feat add keys warning end
            type="button"
            role="tab"
            active={tab === index}
            /* feat_common_responsive_tab_add_props_start */
            position={index}
            /* feat_common_responsive_tab_add_props_end */
            onClick={() => {
              setTab(index);
              if (item.callBack) item.callBack(index);
            }}
            labelLength={item.labelLength || 120}
          >
            <Text variant="body14" bold color={tab === index ? 'primaryBlue' : 'liteGray'}>
              {item.label}
            </Text>
            {tab === index && <StyledWhiteBar labelLength={item.labelLength || 120} />}
            {item.hasNotification && (
              <StyledIndicatorWrapper>
                <Icon name="indicatorRed" size={8} />
              </StyledIndicatorWrapper>
            )}
          </StyledTab>
        ))}
      </StyledTabWrapper>
      {items[tab].content}
    </StyledWrapper>
  ): (
    /* feat_common_responsive_tab_start */
    <StyledWrapper>
      <StyledTabWrapper>
        {items.map((item, index) => (
            <Box display="flex" width="100%" key={item.label}>
              <StyledTab
                type="button"
                role="tab"
                active={tab === index}
                /* feat_common_responsive_tab_add_props_start */
                position={index}
                /* feat_common_responsive_tab_add_props_end */                
                onClick={() => {
                  setTab(index);
                  if (item.callBack) item.callBack(index);
                }}
                labelLength={item.labelLength || 124}
              >
                <Box>
                  <Text variant="body14" bold color={tab === index ? 'darkBlue' : 'liteGray'}>
                    {item.label}
                  </Text>
                  {tab === index && <Divider option="horizontal" length={120} color="darkBlue"/>}
                </Box>
                {item.hasNotification && (
                  <StyledIndicatorWrapper>
                    <Icon name="indicatorRed" size={8} />
                  </StyledIndicatorWrapper>
                )}
                  
              </StyledTab>
              {items.length - 1 !== index && 
                <Box display='flex' alignItems='center' justifyContent="center">
                  <Divider length={24} option="vertical"/>
                </Box>
              }
            </Box>
        ))}
      </StyledTabWrapper>
      {items[tab].content}
    </StyledWrapper>
    /* feat_common_responsive_tab_start */
  );
}
