/**
 * Figma ID: 01-01-02
 * 名称: 送信完了
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  tpmemId: string;
};

export function SendComplete({ tpmemId }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate({ pathname: '/signIn' });
  };
  // feat screen No_18 start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="新規登録 - 店舗未登録の場合  送信完了" />
      <Box>
        <Text variant="body14">未登録店舗情報の送信が完了しました。</Text>
        <br />
        <Text variant="body14">運営からの連絡をお待ち下さい。</Text>
      </Box>

      <Box display="grid" justifyContent="center">
        {/* <TextLink onClick={() => navigate({ pathname: '/signIn' })}>ログイン画面へ</TextLink> */}
        <TextLink onClick={() => handleLogout()}>ログイン画面へ</TextLink>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="新規登録 - 店舗未登録の場合  送信完了" />
      <Box pt={18}>
        <Text display='block' variant="body14">未登録店舗情報の送信が完了しました。</Text>
        <Text display='block' variant="body14">運営からの連絡をお待ち下さい。</Text>
      </Box>

      <Box mt={24} display="grid" justifyContent="center">
        {/* <TextLink onClick={() => navigate({ pathname: '/signIn' })}>ログイン画面へ</TextLink> */}
        <TextLink variant='body14' onClick={() => handleLogout()}>ログイン画面へ</TextLink>
      </Box>
    </Box>
  );
  // feat screen No_18 start
}
