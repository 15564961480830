import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import {
  MainContentArea,
  MainContentHeader,
  MainContentTitle,
  MainContentWrapper,
} from 'components/pageLayout/mainContent';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';
import { Home } from './components/home';
import { NotifyProvider } from './provider/notifyProvider';

/**
 * Figma ID: 03-01-01
 * 名称: HOME
 */

/* feat_screen_03_01_style_layout_start */
 const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px
`;

const StyleHeader = styled.div`
  height: 50px;
  width: 100%;
  background: #F8FEFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 18px auto 19px 16px;
`;

const StyleHeaderText = styled.div`
  margin: 18px auto 19px 16px;
`;

const StyleListContent = styled.div`
  padding: 0 16px;
`;
/* feat_screen_03_01_style_layout_end */

export function S03() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <NotifyProvider>
      <MainContentWrapper>
        <MainContentHeader>
          <MainContentTitle text="HOME" />
        </MainContentHeader>
        <MainContentArea>
          <Card overflow="hidden">
            <Box display="flex" height="100%" pt={16}>
              <Home />
            </Box>
          </Card>
        </MainContentArea>
      </MainContentWrapper>
    </NotifyProvider>
  ) : (
    /* feat_screen_03_01_layout_start */
    <NotifyProvider>
      <StyledListWrapper>
        <StyleHeader>
          <StyleHeaderText>
            <MainContentTitle text="HOME" />
          </StyleHeaderText>
        </StyleHeader>
        <StyleListContent>
          <MainContentWrapper>
            <MainContentArea>
              <Card>
                <Box display="flex" height="100%" pb={6}>
                  <Home />
                </Box>
              </Card>
            </MainContentArea>
          </MainContentWrapper>
        </StyleListContent>
      </StyledListWrapper>
    </NotifyProvider>
    /* feat_screen_03_01_end_start */
  );
}
