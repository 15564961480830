import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';

export interface Props {
  label: string;
  content: ReactNode;
  visible?: string;
  error?: boolean;
}

const StyledWrapper = styled.div<{
  visible?: string;
  error?: boolean;
}>`
  /* margin-bottom: ${({ error }) => (error === true ? 4 : 4)}px; */
  margin-bottom: 4px;
  visibility: ${({ visible = 'visible' }) => visible};
  /* feat screen_04-01-01-06_start */
  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
  /* feat screen_04-01-01-06_end */
`;

const StyledLabel = styled.div`
  margin-bottom: 6px;
  /* feat screen_04-01-01-06_start */
  @media only screen and (max-width: 768px) {
    margin-bottom: 4px;
  }
  /* feat screen_04-01-01-06_end */
`;

const StyledContent = styled.div`
  /* feat screen_04-01-01-06_start */
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
  /* feat screen_04-01-01-06_end */
`;

export function TextSet({ label, content, visible, error }: Props) {
  return (
    <StyledWrapper visible={visible} error={error}>
      <StyledLabel>{typeof label === 'string' ? <InputLabel fullWidth>{label}</InputLabel> : label}</StyledLabel>
      <StyledContent>
        {typeof content === 'string' ? (
          <Text color="blueGray" variant="body14">
            {content}
          </Text>
        ) : (
          content
        )}
      </StyledContent>
    </StyledWrapper>
  );
}
