/**
 * Figma ID: 01-00
 * 名称: 新規登録
 */
import React, { useState } from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { Checkbox } from 'components/newCheckbox';
import useIsMobile from 'hooks/responsive/useIsMobile';

type Props = {
  toNext: () => void;
};

export function TermsOfUsePrivacyPolicy({ toNext }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div>
      {/*  feat_screen_01_00_start */}
      {!isMobile ? (
        <Box width="100%" display="grid" justifyContent="center" alignItems="center" pt={140}>
          <Box display="grid" alignItems="center" justifyContent="center" pt={8}>
            <img src="/charactor_logo.png" width="200" height="244" alt="character" />
          </Box>
          <Box display="grid" alignItems="center" justifyContent="center" pt={8}>
            <Text variant="body18" bold color="darkBlue">
              加盟店管理
            </Text>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" pt={30}>
            <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
            <Box px={5}>
              <TextLink href={process.env.REACT_APP_TERMS_OF_SERVICE}>利用規約 </TextLink>
            </Box>
            <Box pr={5}>
              <TextLink href={process.env.REACT_APP_PRIVACY_POLICY}>プライバシーポリシー</TextLink>
            </Box>
            <Text variant="caption12">に同意</Text>
          </Box>
          <Box display="grid" alignItems="center" justifyContent="center" pt={19}>
            <MainButton disabled={!isChecked} onClick={() => toNext()}>
              新規登録を開始
            </MainButton>
          </Box>
        </Box>
      ) : (
        // Responsive Component
        <div>
          <Box width="100%" display="grid" justifyContent="center" alignItems="center" pt={121} px={16}>
            <Box display="grid" alignItems="center" justifyContent="center">
              <img src="/charactor_logo.png" width="200" height="241" alt="character" />
            </Box>
            <Box display="grid" alignItems="center" justifyContent="center" pt={24}>
              <Text variant="body16" bold color="darkBlue">
                加盟店管理
              </Text>
            </Box>
          </Box>
          <Box width="100%" display="flex" alignItems="center" justifyContent="center" px={16} pt={52}>
            <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
            <Box px={5}>
              <TextLink variant="body14" href={process.env.REACT_APP_TERMS_OF_SERVICE}>
                利用規約
              </TextLink>
            </Box>
            <Box pr={5}>
              <TextLink variant="body14" href={process.env.REACT_APP_PRIVACY_POLICY}>
                プライバシーポリシー
              </TextLink>
            </Box>
            <Text variant="body14">に同意</Text>
          </Box>
          <Box width="100%" display="grid" alignItems="center" justifyContent="center" pt={20}>
            <MainButton customHeight={40} width={343} disabled={!isChecked} onClick={() => toNext()}>
              新規登録を開始
            </MainButton>
          </Box>
        </div>
      )}
      {/*  feat_screen_01_00_end */}
    </div>
  );
}
