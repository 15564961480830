import { styled } from '@linaria/react';
import { colorPalette } from 'components/text/text';

/* feat_common_responsive_divider_add_type_start */
export type ColorKeyType = keyof typeof colorPalette;
/* feat_common_responsive_divider_add_type_end */

const Vertical = styled.div<{
  height: number;
  /* feat_common_responsive_divider_add_props_start */
  color: ColorKeyType;
  /* feat_common_responsive_divider_add_props_end */
}>`
  width: 1px;
  height: ${({height})=> height===0 ? '100%' : `${height}px`};

  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
  background: #DBDBDB;
  /* feat_common_responsive_divider_start */
  @media only screen and (max-width: 768px) {
    background: ${({ color }) => colorPalette[color]};
  }
  /* feat_common_responsive_modal_end */
`;

const Horizontal = styled.div<{
  width: number;
   /* feat_common_responsive_divider_add_props_start */
  color: ColorKeyType;
   /* feat_common_responsive_divider_add_props_end */
}>`
  width: ${({width})=> width===0 ? '100%' : `${width}px`};
  height: 1px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #DBDBDB;
  /* feat_common_responsive_divider_start */
  @media only screen and (max-width: 768px) {
    background: ${({ color }) => colorPalette[color]};
  }
  /* feat_common_responsive_modal_end */
`;



export { Vertical, Horizontal };