import { Box } from 'components/box';
import { Text } from 'components/text';
import { CategorizedInfo } from 'components/categorizedInfo';
import React from 'react';
import { Icon } from 'components/icon';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';

export interface SkillData {
  label: string;
  skills: { name: string; checked: boolean }[];
}

function SkillCheck(props: { rank: string; data: SkillData[] }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { rank, data } = props;
  return !isMobile ? (
    <Box>
      <Box my={16}>
        <Text variant="body14" color="darkBlue">
          ランク&nbsp;
        </Text>
        <Text variant="body16" color="darkBlue" bold>
          {rank}
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box mt={8}>
        <CategorizedInfo
          items={data.map((d) => ({
            label: d.label,
            contents: d.skills.map((s) => (
              <Box display="flex" alignItems="center" gap={8}>
                {s.checked ? <Icon name="check" color="primaryBlue" /> : <Box width={24}>&nbsp;</Box>}
                <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                  {s.name}
                </Text>
              </Box>
            )),
          }))}
        />
      </Box>
    </Box>
  ): (
    /* feat_screen_04-02-01-04_start */
    <Box>
      <Box mt={16} mb={10}>
        <Text variant="body14" color="darkBlue">
          ランク&nbsp;
        </Text>
        <Text variant="body14" color="darkBlue" bold>
          {rank}
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box mt={8}>
        <CategorizedInfo
          items={data.map((d) => ({
            label: d.label,
            contents: d.skills.map((s) => (
              <Box display="flex" alignItems="start" gap={8}>
                {s.checked ? <Icon name="check" color="primaryBlue" /> : <Box width={24}>&nbsp;</Box>}
                <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                  {s.name}
                </Text>
              </Box>
            )),
          }))}
        />
      </Box>
    </Box>
    /* feat_screen_04-02-01-04_end */
  );
}

export default SkillCheck;
