/**
 * Figma ID: 08-07-03
 * 名称: 店舗ブロック
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { Control, useForm } from 'react-hook-form';
import { MainButton } from 'components/mainButton';
import { FilterSearch } from 'components/filterSearch';
import { RhfSelect } from 'components/rhfSelect';
import Divider from 'components/divider/divider';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { Loading } from 'components/loading/loading';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  useGetMCityByStateIdLazyQuery,
  useGetMStateQuery,
  useGetVSelBlockListByMyIdBrandIdQuery,
} from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import type { AddBlockInfoModel } from '.';

type SelectTpmemContentModel = {
  id: number;
  brandId: number;
  brandName: string;
  control: Control<AddBlockInfoModel>;
};
export function SelectTpmemContent(props: SelectTpmemContentModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { id, brandId, brandName, control } = props;
  const {
    data,
    loading,
    error: getVSelBlockListMyIdBrandIdError,
  } = useGetVSelBlockListByMyIdBrandIdQuery({
    variables: { my_id: id, brand_id: brandId },
    fetchPolicy: 'no-cache',
  });const {
    control: tpmemControl,
    reset,
    watch,
  } = useForm<{ prefecture: string; city: string; filterSearchText: string }>({
    reValidateMode: 'onChange',
  });
  const { openErrorModal } = useErrorModal();
  const { data: { getMState } = { getMState: [] }, error: getMStateError } = useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [fetchMCityByStateId, { error: getMCityByStateIdError }] = useGetMCityByStateIdLazyQuery();
  const [cityOptions, setCityOptions] = React.useState<{ label: string; value: string }[]>([]);
  const currentPrefecture = watch('prefecture');
  const currentCity = watch('city');
  const [filterText, setFilterText] = React.useState<string>('');
  const currentFilterSearchText = watch('filterSearchText');
  const tpmemContent = (tpmemId: number, tpmemName: string, disabled: string | undefined | null) => (
    <Box>
      <Box width="100%" display="flex" alignItems="center" px={18} py={7} gap={10}>
        <RhfCheckbox
          name={`tpmemData.${tpmemId}`}
          defaultValue=""
          control={control}
          disabled={Number(disabled) === 1}
        />
        <Text variant="caption12">{tpmemName}</Text>
      </Box>
      <Divider option="horizontal" />
    </Box>
  );

  const tpmemList =
    data?.getVSelBlockListByMyIdBrandId.map((val) => ({
      id: val.my_id ?? '',
      tpmemId: val.tpmem_id,
      name: val.name ?? '',
      address: `${val.state ?? ''}${val.city ?? ''}`,
      disabled: val.blocked,
    })) ?? [];
  const filteredItems = tpmemList.filter(
    (val) =>
      val.name.includes(filterText ?? '') &&
      val.address.includes(currentPrefecture ?? '') &&
      val.address.includes(currentCity ?? '') &&
      tpmemContent(val.tpmemId, val.name, val.disabled)
  );

  const searchedTpmemCounts = filteredItems.length;

  const filteredContent = filteredItems.map((val) => (
    // feat screen 08-07-03 start
    !isMobile ? (
      <Box>
        <Box width="100%" display="flex" alignItems="center" px={18} py={7} gap={10}>
          <RhfCheckbox
            name={`tpmemData.${val.tpmemId}`}
            defaultValue=""
            control={control}
            disabled={Number(val.disabled) === 1}
          />
          <Text variant="caption12">{val.name}</Text>
        </Box>
        <Divider option="horizontal" />
      </Box>
    ) : (
      // Responsive Component
      <Box>
        <Box width="100%" display="flex" alignItems="center" px={16} py={21}>
          <RhfCheckbox
            name={`tpmemData.${val.tpmemId}`}
            defaultValue=""
            control={control}
            disabled={Number(val.disabled) === 1}
          />
          <Text variant="caption12">{val.name}</Text>
        </Box>
        <Divider option="horizontal" />
      </Box>
      // feat screen 08-07-03 end
    )
  ));

  const prefectureOptions = React.useMemo<{ label: string; value: string }[]>(() => {
    if (!getMState) {
      return [];
    }
    return getMState.map((item) => ({
      label: item?.name ?? '',
      value: item?.name ?? '',
    }));
  }, [getMState]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'prefecture' && type === 'change') {
        const selectedPrefecture = getMState?.find((item) => item?.name === value.prefecture);
        if (selectedPrefecture) {
          fetchMCityByStateId({
            context: { clientName: 'master' },
            variables: { sid: Number(selectedPrefecture.id) },
          }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
            if (getMCityByStateId) {
              setCityOptions(
                getMCityByStateId.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                }))
              );
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMCityByStateId, getMState]);

  React.useEffect(() => {
    if (getVSelBlockListMyIdBrandIdError || getMStateError || getMCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [getVSelBlockListMyIdBrandIdError, getMStateError, getMCityByStateIdError, openErrorModal]);

  // feat screen 08-07-03 start
  return !isMobile ? (
    <Box width="100%">
      {loading && <Loading />}
      <Box>
        <Text variant="caption11" color="cautionRed">
          検索をやり直すと選択されたものは解除されますのでご注意ください。
        </Text>
      </Box>
      <Box>
        <Text variant="caption12" color="blueGray">
          ブランド: {brandName}
        </Text>
      </Box>
      <Box width="100%" pt={17} alignItems="center" display="inline-flex" gap={2}>
        <Box width={200}>
          <InputLabel width={200}>キーワードで検索</InputLabel>
          <FilterSearch
            name="filterSearchText"
            placeholder="店舗名"
            control={tpmemControl}
            onClick={() => setFilterText(currentFilterSearchText)}
          />
        </Box>
        <Box width={250}>
          <InputLabel width={250}>都道府県選択</InputLabel>
          <RhfSelect
            width={250}
            control={tpmemControl}
            options={prefectureOptions}
            name="prefecture"
            placeholder="都道府県を選択"
          />
        </Box>
        <Box width={250}>
          <InputLabel width={250}>市区町村</InputLabel>
          <RhfSelect
            width={250}
            control={tpmemControl}
            options={cityOptions}
            name="city"
            placeholder="市区町村を選択"
          />
        </Box>
        <Box pt={20}>
          <MainButton
            variant="clear"
            width={50}
            onClick={() => {
              setFilterText('');
              reset({ filterSearchText: '', prefecture: '', city: '' });
            }}
          >
            クリア
          </MainButton>
        </Box>
      </Box>
      <Box pt={20}>
        <Text variant="caption11" color="darkGray">
          {searchedTpmemCounts}件の検索結果
        </Text>
        <Divider option="horizontal" />
      </Box>
      <Box height={300} overflow="auto">
        {filteredContent}
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%">
      {loading && <Loading />}
      <Box mb={12} mt={2}>
        <Text display="block" variant="body14" color="cautionRed">
          検索をやり直すと選択されたものは解除されますのでご注意ください。
        </Text>
      </Box>
      <Box>
        <Text display="block" variant="body14" color="blueGray">
          ブランド: {brandName}
        </Text>
      </Box>
      <Box width="100%" flexDirection="column" pt={16}>
        <Box width="100%" pb={16}>
          <InputLabel fullWidth>キーワードで検索</InputLabel>
          <FilterSearch
            fullWidth
            name="filterSearchText"
            placeholder="店舗名"
            control={tpmemControl}
            onClick={() => setFilterText(currentFilterSearchText)}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap={10}>
          <Box width="100%">
            <Box mb={4}>
              <InputLabel fullWidth>都道府県選択</InputLabel>
            </Box>
            <RhfSelect
              fullWidth
              height={40}
              control={tpmemControl}
              options={prefectureOptions}
              name="prefecture"
              placeholder="都道府県を選択"
            />
          </Box>
          <Box width="100%">
            <Box mb={4}>
              <InputLabel fullWidth>市区町村</InputLabel>
            </Box>
            <RhfSelect
              fullWidth
              height={40}
              control={tpmemControl}
              options={cityOptions}
              name="city"
              placeholder="市区町村を選択"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%" py={2}>
          <MainButton
            variant="clear"
            customHeight={40}
            width={46}
            onClick={() => {
              setFilterText('');
              reset({ filterSearchText: '', prefecture: '', city: '' });
            }}
          >
            クリア
          </MainButton>
        </Box>
      </Box>
      <Box>
        <Text variant="caption12" color="darkGray">
          {searchedTpmemCounts}件の検索結果
        </Text>
        <Divider option="horizontal" />
      </Box>
      <div style={{ height: 285, overflow: "auto", 'WebkitOverflowScrolling': "touch" }}>
        {filteredContent}
      </div>
    </Box>
  // feat screen 08-07-03 end
  );
}

type SelectTpmemFooterModel = {
  nextPhase: () => void;
  close: () => void;
  backPhase: () => void;
  disabledNextPhase: boolean;
};
export function SelectTpmemFooter(props: SelectTpmemFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { nextPhase, close, backPhase, disabledNextPhase } = props;
  return (
    <Box display="flex" gap={8} justifyContent="flex-end">
      {/* feat screen 08-07-03 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={backPhase}>
        戻る
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} onClick={nextPhase} disabled={disabledNextPhase}>
        次へ
      </MainButton>
      {/* feat screen 08-07-03 end */}
    </Box>
  );
}
