import React from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Box } from 'components/box';
import { Text } from '../text';

export interface Props {
  items: {
    // レンダリングの最適化のために一意なキーを設定して下さい
    uniqueKey: string | number;
    // text: string;
    text: React.ReactNode;
    dateTime: string;
  }[];
}

const StyledWrapper = styled.ul`
  width: 100%;
`;

const StyledListRow = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  border-bottom: 1px solid #dbdbdb;
  height: 59px;
  box-sizing: border-box;
  list-style-type: none;
  :first-child {
    border-top: 1px solid #dbdbdb;
  }
  /* feat_common_responsive_history_list_start */
  @media only screen and (max-width: 768px) {
    :first-child {
      border-top: none;
    }
    height: unset;
    padding: 9.8px 0 9.8px 2.8px;
  }
  /* feat_common_responsive_history_list_end */
`;

/* feat_common_responsive_span_row_start */
const StyledSpanContent = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;
/* feat_common_responsive_span_row_end */

export function HistoryList({ items }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat screen 04-01-01-23 start
  return !isMobile ? (
    <StyledWrapper>
      {items.map((item) => (
        <StyledListRow key={item.uniqueKey}>
          <Text variant="body14">{item.text}</Text>
          <Text variant="caption11" color="darkGray">
            {item.dateTime}
          </Text>
        </StyledListRow>
      ))}
    </StyledWrapper>
  ): (
    // Responsive Component
    <StyledWrapper>
      {items.map((item) => (
        <StyledListRow key={item.uniqueKey}>
          <Box mt={1} width="100%">
            <StyledSpanContent>
              {item.text}
            </StyledSpanContent>
          </Box>
          <Box mt={0.1} width="100%">
            <Text display="block" variant="caption12" color="darkGray">
              {item.dateTime}
            </Text>
          </Box>
        </StyledListRow>
      ))}
    </StyledWrapper>
  );
  // feat screen 04-01-01-23 end
}
