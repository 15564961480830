/*  feat screen 00 start */
import React from 'react';
import { styled } from '@linaria/react';
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { AgreementStatus,RecruitStatus } from 'components/const';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { subDays, addDays, getDate, getMonth, getYear, format as formatDate } from 'date-fns';
import _ from 'lodash';
import { ListDataType } from 'pages/s04-02/components/type';
// import {} from 'pages/s04-03/common/';
import './quickDatePicker.css';
import { GENERIC_COLOR, LABEL_COLOR, QUICK_ACCESS_COLOR } from '../assets/css/style';
import { Icon } from '../icon';

export type VtBtmemOfferList = {
  __typename?: 'VTBtmemOfferList';
  begin_day?: string | null;
  begin_end?: string | null;
  btmem_id: number | null;
  btmem_image?: string | null;
  btmem_kana?: string | null;
  btmem_number?: string | null;
  created_date?: string | null;
  end_day?: string | null;
  newly?: number | null;
  offer_id: number | null;
  offer_no?: string | null;
  state_city?: string | null;
  status?: number | null;
  tpmem_id: number | null;
  type?: number | null;
  work_time?: string | null;
};

export interface VTArbeitOfferInfo {
  id: number;
  status?: number | null;
  offer_number?: string | null;
  auto_decide?: number | null;
  title?: string | null;
  name?: string | null;
  entry_count?: number | null;
  begin_end?: string | null;
  post_begin?: string | null;
  post_end?: string | null;
  break_time?: number | null;
  hour_wage?: number | null;
  hour_wage_disp?: string | null;
  trans_fee?: string | null;
  chief?: string | null;
  description?: string | null;
  conditions?: string | null;
  caution?: string | null;
  belongings?: string | null;
  access?: string | null;
  image1?: string | null;
  image2?: string | null;
  image3?: string | null;
  image4?: string | null;
  image5?: string | null;
  attached_file?: string | null;
  notice?: string | null;
  post_begin_date?: string | null;
  post_end_date?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
}

export type Props = ReactDatePickerProps & {
  width?: number;
  yearRange?: number[];
  minDate?: Date | null;
  maxDate?: Date | null;
  // feat screen 07-02-01_04 start
  fromTo?: number;
  popperPlacement?: string;
  selected: Date;
  workingList: ListDataType[];
  offerData : VTArbeitOfferInfo[];
  reserveOffer: VtBtmemOfferList[];
  // feat screen 07-02-01_04 end
};


const StyledInputWrapper = styled.div`
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  /* feat screen_01-01-03_end */
  display: inline-block;
  position: relative;
`;

// feat screen 07-02-01_04 start
const StyledInput = styled.input<{ width: number; fromTo: number }>`
  // feat screen 07-02-01_04 end
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    // feat screen 07-02-01_04 start
    min-width: ${({ fromTo }) => (fromTo ? `${fromTo}px` : '150px')};
    // feat screen 07-02-01_04 end
  }
  /* feat screen_01-01-03_end */
  min-width: ${({ width }) => width}px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: ${GENERIC_COLOR.WHITE};
  border: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${GENERIC_COLOR.BLACK};
  cursor: pointer;
  outline: none;
  &:focus {
    border: 1px solid ${LABEL_COLOR.BLUE};
  }
  ::placeholder {
    color: ${GENERIC_COLOR.LITE_GRAY};
  }
`;

const StyledIconWrapper = styled.div`
  /* feat screen_01-01-03_start */
  @media only screen and (max-width: 768px) {
    top: 7px;
  }
  /* feat screen_01-01-03_end */
  position: absolute;
  top: 4px;
  right: 8px;
`;

const StyleCell = styled.span<{
  bgColor: string;
}>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${({bgColor}) => bgColor === "#EF873B" ? `${bgColor}` : bgColor} ;
  color: #000;
  font-size: 16px;
`;

const StyleCellImportant = styled.span<{
  bgColor: string;
  color: string;
}>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${({bgColor}) => bgColor} !important;
  color: ${({color}) => color} !important;;
  font-size: 16px;
`;

const StyledCard = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 3px;
  gap: 4px;
  left: 50%;
  width: 40px;
  height: 13px;
  transform: translateX(-50%);
`;

const StyledCardItems = styled.div<{
  bgColor: string;
}>`
  width: 10px;
  height: 13px;
  background-color: ${({bgColor}) => bgColor} !important;
`;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomInput = React.forwardRef((props: any, ref) => (
  /* eslint-disable @typescript-eslint/no-unsafe-member-access  */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment  */
  <StyledInputWrapper>
    <StyledInput {...props} ref={ref} />
    <StyledIconWrapper>
      <Icon name="dateRangeNormal" onClick={props.onClick} />
    </StyledIconWrapper>
  </StyledInputWrapper>
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */
));

// デフォルトの選択年(7年前から4年後まで)
const defaultYears = _.range(getYear(new Date()) - 7, getYear(new Date()) + 5, 1);

// 年選択範囲取得
// ※デフォルトでは、7年前から4年後までの範囲を取得する
//   但し、minDateが指定されている場合は、minDateの年を最小選択年とする
//   maxDateが指定されている場合は、maxDateの年を最大選択年とする
const getYearRange = (yearRange: number[] = defaultYears, minDate?: Date | null, maxDate?: Date | null) => {
  // 最小年
  const minYear = minDate ? getYear(minDate) : yearRange[0];
  // 最大年
  const maxYear = maxDate ? getYear(maxDate) : yearRange[yearRange.length - 1];

  return _.range(minYear, maxYear + 1, 1);
};

// feat screen 07-02-01_04 start
export function DatePicker({ width = 160, fromTo, yearRange, minDate, maxDate, popperPlacement, selected, workingList, offerData, reserveOffer, ...props }: Props) {
  // feat screen 07-02-01_04 end
  registerLocale('ja', ja);
  const requestCorrectionData = workingList.filter(
    (d) =>
      d.status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
      d.status === AgreementStatus.REQUEST_CORRECTION_REJECTED
  );

  const recruitingOffers = offerData.filter(
    (d) =>
      d.status === RecruitStatus.RECRUITING && (d.entry_count ?? 0) > 0
  );
  
  // 年選択範囲取得
  const yearSelectRange = getYearRange(yearRange, minDate, maxDate);
  // 最小選択日付
  const minSelectDate = minDate || new Date(`${yearSelectRange[0]}/01/01`);
  // 最大選択日付
  const maxSelectDate = maxDate || new Date(`${yearSelectRange[yearSelectRange.length - 1]}/12/31`);
  // console.log(String(format(new Date(), 'yyyy年MM月dd日')));
  // feat commons calendar end
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const renderDayContents = (day: number, date?: Date | undefined) => {
    const currentDate = getDate(new Date());
    const currentMonth = getMonth(new Date());
    const currentYear = getYear(new Date());
    const selectedMonth = getMonth(selected);
    const calendarMonth = getMonth(date as Date);
    const calendarDate = getDate(date as Date);
    const calendarYear = getYear(date as Date);
    const calendarDayTime = formatDate(date as Date, "yyyy年MM月dd日");
    const isConfirmCorrection = requestCorrectionData.find(d => d.arbeitStartDate?.match(calendarDayTime));
    const isRecruiting = recruitingOffers.find(d => d.begin_date?.match(calendarDayTime));
    const isReserve = reserveOffer.find(
      d => 
        {
          const formatBeginDay = d.begin_day ? formatDate(new Date(d.begin_day), 'yyyy年MM月dd日') : '';
          return formatBeginDay?.match(calendarDayTime);
        }
    );
    // Disable date
    if(selectedMonth !== calendarMonth) {
      return (
        <StyleCellImportant bgColor="#F0F6FF" color='#DBDBDB'>
          {getDate(date as Date)}
        </StyleCellImportant>
      );
    }
    
    if((currentDate === calendarDate) && (currentMonth === calendarMonth) && (currentYear === calendarYear)) {
      return (
        <StyleCellImportant bgColor="#EF873B" color='#000'>
          {getDate(date as Date)}
          <StyledCard>
            { isConfirmCorrection && <StyledCardItems bgColor={QUICK_ACCESS_COLOR.SUCCESS} />}
            { isRecruiting && <StyledCardItems bgColor={QUICK_ACCESS_COLOR.WARNING} />}
            { isReserve && < StyledCardItems bgColor={QUICK_ACCESS_COLOR.INFO} /> }
          </StyledCard>
        </StyleCellImportant>
      );
    }

    return (
      <StyleCell bgColor="#F8F8F8">
        {getDate(date as Date)}
        <StyledCard>
          { isConfirmCorrection && <StyledCardItems bgColor={QUICK_ACCESS_COLOR.SUCCESS} />}
          { isRecruiting && <StyledCardItems bgColor={QUICK_ACCESS_COLOR.WARNING} />}
          { isReserve && < StyledCardItems bgColor={QUICK_ACCESS_COLOR.INFO} /> }
        </StyledCard>
      </StyleCell>
    );
  };
  return (
    <div className='custom'>
      <ReactDatePicker
        // feat common datepicker add props config start 
        portalId="root-portal"
        // feat common datepicker add props config end
        {...props}
        selected={selected}
        locale="ja"
        dateFormat="yyyy年MM月dd日"
        // feat screen 07-02-01_04 start
        customInput={<CustomInput width={width} fromTo={fromTo} />}
        disabledKeyboardNavigation
        monthsShown={1}
        // feat screen 07-02-01_04 end
        minDate={minSelectDate}
        maxDate={maxSelectDate}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({ popper, reference }) => ({
                right: Math.min(popper.width, reference.width) - width / 2,
              }),
            },
          },
          
        ]}
        popperPlacement={popperPlacement}
        inline
        renderDayContents={renderDayContents}
        renderCustomHeader={() => null}
        excludeDateIntervals={[
          { 
            start: subDays(new Date(selected.getFullYear(), selected.getMonth(), 1), 7), 
            end: subDays(new Date(selected.getFullYear(), selected.getMonth(), 1), 1)
          },
          {
            start: addDays(new Date(selected.getFullYear(), selected.getMonth() + 1, 0), 1),
            end: addDays(new Date(selected.getFullYear(), selected.getMonth() + 1, 0), 7) 
          }
        ]}
      />
    </div>
  );
}
/*  feat screen 00 end */
