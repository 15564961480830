/* eslint-disable  @typescript-eslint/no-unsafe-assignment */
/* eslint-disable  @typescript-eslint/no-unsafe-call */
/* eslint-disable  @typescript-eslint/no-unsafe-member-access */
/* eslint-disable  @typescript-eslint/no-unsafe-argument */

import { useEffect, useState } from 'react';
import { useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery } from 'graphql/graphql-mw';
import { MemberStoreStatus, TransferProcessStatus } from 'components/const';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import { MainMenu } from 'components/mainMenuBar/types';
import { MemberStoreMainMenu } from './mainMenu';
import { PausedMemberStoreMainMenu } from './pausedMemberStoreMainMenu';

export function useMainMenu(tpmemId: number | null) {
  const [score, setScore] = useState(0);
  const [mainMenu, setMainMenu] = useState<MainMenu[]>([]);
  const [transferProcessStatus, setTransferProcessStatus] = useState<number>(1);

  const memberStoreStatus = useMemberStoreStatus();

  const defaultPollingInterval = 60000;

  const [
    getVtNewlyMenuHome,
    { data: { getVTNewlyMenuHomeArbeitByTpmemId } = { getVTNewlyMenuHomeArbeitByTpmemId: null } },
  ] = useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery({
    pollInterval: Number(process.env.REACT_APP_MENU_POLLING_INTERVAL) || defaultPollingInterval,
    // feat add fetch policy start
    fetchPolicy: 'network-only'
    // feat add fetch policy end
  });

  useEffect(() => {
    if (tpmemId === null) return;
    getVtNewlyMenuHome({ variables: { tpmem_id: tpmemId } }).then((result) => {
      const menuData = result.data?.getVTNewlyMenuHomeArbeitByTpmemId.menu;
      const scoreData = menuData?.score;
      const transferProcessStatusData = menuData?.mfk_status;

      if (typeof scoreData === 'number') {
        setScore(scoreData);
      }

      if (typeof transferProcessStatusData === 'number') {
        setTransferProcessStatus(transferProcessStatusData);
      }
    });
  }, [tpmemId, getVtNewlyMenuHome]);

  useEffect(() => {
    if (memberStoreStatus === MemberStoreStatus.PAUSE) {
      setMainMenu(PausedMemberStoreMainMenu);
      return;
    }
    // feat set default menu before get notifications start
    // オリジナルのメニューのオブジェクト配列をディープコピーする
    // 単純な代入だと、参照元の値が変更されてしまうため。
    const nextMenu = JSON.parse(JSON.stringify(MemberStoreMainMenu));
    setMainMenu(nextMenu);
     // feat set default menu before get notifications end

    const isUnApplied = transferProcessStatus === TransferProcessStatus.UNAPPLIED;

    const { menu: notifications } = getVTNewlyMenuHomeArbeitByTpmemId ?? {};
    if (!notifications) return;

    if (notifications.home) {
      // HOME
      nextMenu[0].highlighted = true;
    }
    if (isUnApplied) nextMenu[1].isDisable = true;
    if (notifications.arbeit) {
      // 募集・採用
      nextMenu[1].highlighted = true;
    }
    if (nextMenu[1].accordionList) {
      // バイト募集
      if (notifications.ar_offer) {
        nextMenu[1].accordionList[0].highlighted = true;
      }
      if (isUnApplied) nextMenu[1].accordionList[0].isDisable = true;
      // 成約バイト状況
      if (notifications.ar_worker) {
        nextMenu[1].accordionList[1].highlighted = true;
      }
      if (isUnApplied) nextMenu[1].accordionList[1].isDisable = true;
      // 逆オファー
      if (notifications.ar_bt_offer) {
        nextMenu[1].accordionList[2].highlighted = true;
      }
      if (isUnApplied) nextMenu[1].accordionList[2].isDisable = true;
    }
    if (notifications.baitra) {
      // バイトラベラー
      nextMenu[2].highlighted = true;
    }
    if (nextMenu[2].accordionList) {
      // 所属登録対応
      if (notifications.bt_belong) {
        nextMenu[2].accordionList[1].highlighted = true;
      }
      if (isUnApplied) nextMenu[2].accordionList[1].isDisable = true;
    }

    setMainMenu(nextMenu);
  }, [getVTNewlyMenuHomeArbeitByTpmemId, memberStoreStatus, transferProcessStatus]);

  return { mainMenu, score, transferProcessStatus };
}
