/**
 * Figma ID: 06-01-01
 * 名称: 所属登録対応
 */
import React, { ReactNode, useMemo, useState, useEffect } from 'react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import { Text } from 'components/text';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Select } from 'components/select';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'components/icon';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { orderBy } from 'lodash';
import { usePagenation, useSort } from 'components/utils';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { COLORS_NAME, PageNumber, StatusName } from './const/const';
import { generateChip } from './s06-utils';

function S06() {
  const auth = useAuth();
  const { tpmemId } = auth;
  const {
    data,
    loading,
    error: getVtRegBelongListByTpmemIdError,
  } = gql.useGetVtRegBelongListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (getVtRegBelongListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtRegBelongListByTpmemIdError, openErrorModal]);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  const navigate = useNavigate();
  const location = useLocation();

  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const [searchWord, setSearchWord] = useState<string>('');
  const [statusSelect, setStatusSelect] = useState<string>('');
  const [dataSize, setDataSize] = useState<number>(0);
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(PageNumber.initialValue),
  });
  const [searchConditions, setSearchConditions] = useState<{ name: string } | null>(null);

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    {
      columnName: '',
      key: 'eye',
      // feat screen 06-01-01 start
      width: !isMobile ? 24 : 20,
      // feat screen 06-01-01 end
    },
    {
      columnName: '状態',
      key: 'status',
      // feat screen 06-01-01 start
      width: !isMobile ? 96 : 64,
      // feat screen 06-01-01 end
    },
    {
      columnName: '氏名',
      key: 'name',
      // feat screen 06-01-01 start
      width: !isMobile ? 104 : 118,
      // feat screen 06-01-01 end
    },
    {
      columnName: '登録日時',
      key: 'begin_date',
      width: 150,
    },
    {
      columnName: '承認日時',
      key: 'req_date',
      width: 150,
    },
    {
      columnName: 'ランク',
      key: 'rank_name',
      width: 92,
    },
    {
      columnName: 'スコア',
      key: 'score',
      width: 160,
    },
  ];

  const rowBackgroundColor = (identification: number) => {
    switch (identification) {
      case StatusName.APPROVED:
        return COLORS_NAME.White;
      case StatusName.APPLYING:
        return COLORS_NAME.Yellow;
      case StatusName.DENIAL:
        return COLORS_NAME.Red;
      default:
        return COLORS_NAME.White;
    }
  };

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = useMemo(() => {
    if (loading) {
      return [];
    }
    let items = data?.getVTRegBelongListByTpmemId || [];

    /**
     * Statusでフィルター
     */
    if (statusSelect) {
      items = items.filter(({ status }) => {
        switch (statusSelect) {
          case '0':
            return status === StatusName.APPLYING;
          case '1':
            return status === StatusName.APPROVED;
          case '2':
            return status === StatusName.DENIAL;
          case '':
          default:
            return true;
        }
      });
    }

    /**
     * キーワードでフィルター
     */
    if (searchConditions) {
      items = items.filter(({ name }) => {
        if (!searchConditions.name) {
          return true;
        }
        const reg = new RegExp(searchConditions.name);
        return reg.test(String(name));
      });
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    setDataSize(items.length);

    const handleOnClick = (link: string, item?: gql.VtRegBelongList) => {
      navigate(link, { state: { parentData: item } });
    };
    /**
     * リスト生成
     */
    return items
      .map((item) => ({
        uniqueKey: `${String(item?.btmem_id)}_${Math.random()}`,
        backgroundColor: rowBackgroundColor(item.status || StatusName.APPLYING),
        eye: (
          <div style={{ position: 'relative' }}>
            <Icon
              name="eye"
              // feat screen 06-01-01 start
              size={!isMobile ? 24 : 20}
              // feat screen 06-01-01 end
              onClick={() => handleOnClick(`${location.pathname}/${String(item?.btmem_id)}`, item)}
            />
            {item?.newly === 1 && (
              <div style={{ position: 'absolute', top: -14, right: 0 }}>
                <Icon name="indicatorRed" onClick={() => {}} size={8} />
              </div>
            )}
          </div>
        ),
        status: generateChip(item.status || StatusName.APPLYING),
        name: item.name,
        begin_date: item.begin_date,
        req_date: item.req_date,
        rank_name: item.rank_name,
        score: item.score,
      }))
      .slice(limit * (page - 1), limit * page);
  }, [
    data?.getVTRegBelongListByTpmemId,
    limit,
    loading,
    location.pathname,
    navigate,
    page,
    searchConditions,
    sort,
    statusSelect,
    // feat screen 06-01-01 start
    isMobile,
    // feat screen 06-01-01 end
  ]);

  return (
    <>
      {loading && <Loading />}
      {!isMobile ? (
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          <Box height={30} mb={26} display="flex" alignItems="center">
            <Text color="darkBlue" variant="h1">
              所属登録対応
            </Text>
          </Box>
          <Box flex="auto">
            <Card overflow="hidden">
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" pa={16} gap={16} flexWrap="wrap">
                  <Input
                    value={searchWord}
                    onChange={(event) => setSearchWord(event?.target.value || '')}
                    width={120}
                    placeholder="氏名"
                  />
                  <MainButton
                    variant="primary"
                    width={80}
                    icon="search"
                    onClick={() => {
                      // ページ初期化
                      setPage(PageNumber.initialValue);
                      setSearchConditions({
                        name: searchWord,
                      });
                    }}
                  >
                    検索
                  </MainButton>
                  <Divider option="vertical" length={24} />
                  <Select
                    value={statusSelect}
                    options={[
                      {
                        value: '',
                        label: '状態',
                      },
                      {
                        value: '0',
                        label: '申請中',
                      },
                      {
                        value: '1',
                        label: '承認済',
                      },
                      {
                        value: '2',
                        label: '否認',
                      },
                    ]}
                    onChange={(value) => {
                      setPage(PageNumber.initialValue);
                      setStatusSelect(value);
                    }}
                    placeholder="状態"
                    width={120}
                  />
                  <MainButton
                    variant="clear"
                    width={42}
                    onClick={() => {
                      // ページ初期化
                      setPage(PageNumber.initialValue);
                      setSearchWord('');
                      setStatusSelect('');
                      setSearchConditions(null);
                    }}
                  >
                    クリア
                  </MainButton>
                </Box>
                <Divider option="horizontal" />
                <Box flex="auto" width="100%" px={16}>
                  <PaginationBox
                    dataSize={dataSize}
                    limit={limit}
                    page={page}
                    onChangeLimit={handleChangeLimit}
                    onChangePage={handleChangePage}
                  >
                    <ScrollWrapper bottom={317}>
                      <List
                        key={String(limit) + String(page) + (sort?.key || '')}
                        header={header}
                        items={displayItems}
                        sort={sort}
                        onChangeSort={handleChangeSort}
                        width="100%"
                        rowWidth="100%"
                        rowHeight={41}
                      />
                    </ScrollWrapper>
                  </PaginationBox>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      ) : (
        // feat screen 06-01-01 start
        <Box display="flex" flexDirection="column" width="100vw" height="100%" columnGap={16}>
          <Box>
            <MultiMenuHeader
              items={[
                {
                  label: 'バイトラベラー',
                },
                {
                  label: '所属登録対応',
                },
              ]}
              mainHeader="所属登録対応"
            />
          </Box>
          <Box display="flex" flex="auto" overflow="auto" pt={16} px={16} pb={isMobile ? 76 : 16}>
            <Card overflow="auto">
              <Box display={isShowFilter ? 'flex' : 'none'} flexDirection="column" gap={12} px={16} pt={16} mb={32}>
                <Box display="flex" gap={12} justifyContent="space-between">
                  <Box flex="1">
                    <Input
                      value={searchWord}
                      onChange={(event) => setSearchWord(event?.target.value || '')}
                      fullWidth
                      placeholder="氏名"
                    />
                  </Box>
                  <MainButton
                    customHeight={40}
                    variant="primary"
                    width={80}
                    icon="search"
                    onClick={() => {
                      // ページ初期化
                      setPage(PageNumber.initialValue);
                      setSearchConditions({
                        name: searchWord,
                      });
                    }}
                  >
                    検索
                  </MainButton>
                </Box>
                <Box display="flex" gap={12} mt={2} alignItems="center">
                  <Box flex="1">
                    <Select
                      value={statusSelect}
                      options={[
                        {
                          value: '',
                          label: '状態',
                        },
                        {
                          value: '0',
                          label: '申請中',
                        },
                        {
                          value: '1',
                          label: '承認済',
                        },
                        {
                          value: '2',
                          label: '否認',
                        },
                      ]}
                      onChange={(value) => {
                        setPage(PageNumber.initialValue);
                        setStatusSelect(value);
                      }}
                      placeholder="状態"
                      fullWidth
                      height={40}
                    />
                  </Box>
                  <MainButton
                    variant="clear"
                    width={80}
                    onClick={() => {
                      // ページ初期化
                      setPage(PageNumber.initialValue);
                      setSearchWord('');
                      setStatusSelect('');
                      setSearchConditions(null);
                    }}
                  >
                    クリア
                  </MainButton>
                </Box>
              </Box>
              <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
              <Box flex="auto" width="100%">
                <PaginationBox
                  dataSize={dataSize}
                  limit={limit}
                  page={page}
                  onChangeLimit={handleChangeLimit}
                  onChangePage={handleChangePage}
                >
                  <ScrollWrapper bottom={isShowFilter ? 382 : 270} bottomSE={isShowFilter ? 265 : 270}>
                    <List
                      key={String(limit) + String(page) + (sort?.key || '')}
                      header={header}
                      items={displayItems}
                      sort={sort}
                      onChangeSort={handleChangeSort}
                      width="100%"
                      rowWidth="100%"
                      rowHeight={48}
                    />
                  </ScrollWrapper>
                </PaginationBox>
              </Box>
            </Card>
          </Box>
        </Box>
        // feat screen 06-01-01 end
      )}
    </>
  );
}

export default S06;
