import { Modal } from 'components/newModal';
import { AddReportModalForm } from 'pages/s05-01/type';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { ApolloQueryResult } from '@apollo/client';
import { AddReport } from './addReport';
import { Confirm } from './confirm';
import { Complete } from './complete';

enum ModalStep {
  ADD_REPORT = 1,
  CONFIRM = 2,
  COMPLETE = 3,
}

export function AddReportModal(props: {
  isOpen: boolean;
  onClose: () => void;
  refetch: (
    variables?: Partial<gql.Exact<{ id: number; tpmem_id: number }>> | undefined
  ) => Promise<ApolloQueryResult<gql.GetVbMemberBaseByIdQuery>>;
  baitravelerId: number;
}) {
  const { isOpen, onClose, refetch, baitravelerId } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.ADD_REPORT);

  const {
    control,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AddReportModalForm>();

  const onModalClose = () => {
    reset();
    onClose();
    setModalStep(ModalStep.ADD_REPORT);
    refetch();
  };

  const addReportContent = AddReport(control, trigger, errors, onModalClose, () => setModalStep(ModalStep.CONFIRM));

  const reason = getValues('reason');
  const confirmContent = Confirm(
    onModalClose,
    () => setModalStep(ModalStep.ADD_REPORT),
    () => setModalStep(ModalStep.COMPLETE),
    baitravelerId,
    reason
  );

  const completeContent = Complete(onModalClose);

  const modalContent = {
    [ModalStep.ADD_REPORT]: addReportContent,
    [ModalStep.CONFIRM]: confirmContent,
    [ModalStep.COMPLETE]: completeContent,
  };

  return <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />;
}
