import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { usePagenation } from 'components/utils';
import { useAuth } from 'hooks/authProvider';
import { AddThanksMessageModal } from 'pages/s05-01/modal';
import { HistoryListItemsType } from 'pages/s05-01/type';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TLogging, useGetupdVtBizBtmemInfoByTpmemIdBtmemIdMutation } from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { HistoryList } from 'components/historyList';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';

enum ModalType {
  NONE = 'none',
  ADD_THANKS_MESSAGE = 'addThanksMessage',
}

function convertToHistoryListItems(thanksMessageLog: TLogging[]): HistoryListItemsType {
  return thanksMessageLog.map((log, index) => ({
    text: log.matter || '',
    dateTime: log.log_date || '',
    uniqueKey: index,
  }));
}

export function ThanksMassage() {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;
  const { openErrorModal } = useErrorModal();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.NONE);

  const [
    getThanksMessageHistory,
    { data: thanksMessageHistory, loading: thanksMessageHistoryLoading, error: thanksMessageHistoryError },
  ] = useGetupdVtBizBtmemInfoByTpmemIdBtmemIdMutation({
    variables: {
      tpmem_id: tpmemId,
      btmem_id: btmemId,
    },
  });

  const thanksMessageHistoryItems = convertToHistoryListItems(
    thanksMessageHistory?.getupdVTBizBtmemInfoByTpmemIdBtmemId.logging || []
  );

  useEffect(() => {
    getThanksMessageHistory();
  }, [getThanksMessageHistory, tpmemId, btmemId]);

  React.useEffect(() => {
    if (thanksMessageHistoryError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [thanksMessageHistoryError, openErrorModal]);

  /* feat_common_responsive_useMobile_logic_start */ 
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <>
      {thanksMessageHistoryLoading && <Loading />}
      {
        !isMobile ? (
          <Box px={16} display="flex" flex="1" flexDirection="column">
            <Box display="flex" flexDirection="row" py={16} justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="h2" color="darkBlue">
                  Thanksメッセージ
                </Text>
                <Text variant="caption12" color="darkBlue">
                  所属のバイトラベラーに感謝の言葉を贈ろう！
                </Text>
              </Box>
              <MainButton
                icon="militaryTech"
                iconColor="white"
                width={216}
                thin
                onClick={() => setActiveModalType(ModalType.ADD_THANKS_MESSAGE)}
              >
                Thanksメッセージ付与
              </MainButton>
            </Box>
            <Divider option="horizontal" />
            <Box flex="auto" width="100%">
              <PaginationBox
                dataSize={thanksMessageHistoryItems.length}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={370}>
                  <Box display="flex" flexDirection="column" overflow="auto" py={8}>
                    <HistoryList items={thanksMessageHistoryItems.slice(limit * (page - 1), limit * page)} />
                  </Box>
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Box>
        ): (
          /* feat_screen_05-01-05-01_start */
          <Box width="calc(100vw - 32px)">
            <Box px={16} display="flex" flex="1" flexDirection="column" mt={2}>
              <Box display="flex" flexDirection="column" gap={12} py={12} alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Text variant="h2" color="darkBlue">
                    Thanksメッセージ
                  </Text>
                  <Text variant="caption12" color="darkBlue">
                    所属のバイトラベラーに感謝の言葉を贈ろう！
                  </Text>
                </Box>
                <Box>
                  <MainButton
                    icon="militaryTech"
                    iconColor="white"
                    width={209}
                    onClick={() => setActiveModalType(ModalType.ADD_THANKS_MESSAGE)}
                  >
                    Thanksメッセージ付与
                  </MainButton>
                </Box>
              </Box>
              <Divider option="horizontal" />
            </Box>
            <Box flex="auto" width="100%">
              <PaginationBox
                dataSize={thanksMessageHistoryItems.length}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={526}>
                  <Box display="flex" flexDirection="column" overflow="auto" px={15}>
                    <HistoryList items={thanksMessageHistoryItems.slice(limit * (page - 1), limit * page)} />
                  </Box>
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Box>
          /* feat_screen_05-01-05-01_end */
        )
      }
      <AddThanksMessageModal
        isOpen={activeModalType === ModalType.ADD_THANKS_MESSAGE}
        onClose={() => setActiveModalType(ModalType.NONE)}
        refetch={() => {
          getThanksMessageHistory();
        }}
      />
    </>
  );
}
