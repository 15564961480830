/**
 * Figma ID: 02-02-01
 * 名称: パスワード変更
 */
import React, { useEffect } from 'react';
import { useAuth } from 'hooks/authProvider';

import { useGetTMemberEmailByCodeLazyQuery } from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';

import { Box } from 'components/box';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { TitleHeader } from '../component/TitleHeader';

import SendMail from './SendMail';
import CompleteSendMail from './CompleteSendMail';
import ResetPassword from './ResetPassword';
import CompleteUpdatePassword from './CompleteUpdatePassword';
import ConfirmEmailContent from './confirmEmailContent';
import EmailUpdate from './emailUpdate';
import CompleteEmail from './completeEmail';

type ForgotPasswordPhaseModel =
  | 'SendMail'
  | 'CompleteSendMail'
  | 'InputCodeAndPassword'
  | 'CompleteUpdatePassword'
  | 'ConfirmEmailContent'
  | 'EmailUpdate'
  | 'CompleteEmail';

export function Screen020201() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const [forgotPasswordPhase, setForgotPasswordPhase] = React.useState<ForgotPasswordPhaseModel>('SendMail');
  const [storeCode, setStoreCode] = React.useState<string>('');

  // メールアドレス変更情報
  const [emailInfo, setEmailInfo] = React.useState<{
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    managerBirthday: Date;
    businessName: string;
    zipCode1: string;
    zipCode2: string;
    phone: string;
    email: string;
  } | null>(null);

  const auth = useAuth();
  const { openErrorModal } = useErrorModal();

  const [getTMemberEmailByCode, { data, loading, error: getTMemberEmailByCodeError }] =
    useGetTMemberEmailByCodeLazyQuery({
      context: { clientName: 'api_key' },
    });

  useEffect(() => {
    if (getTMemberEmailByCodeError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
      setStoreCode('');
      setForgotPasswordPhase('SendMail');
    }
  }, [getTMemberEmailByCodeError, openErrorModal]);

  const handleSendMail = async (_storeCode: string) => {
    try {
      await auth.sendEmailToResetPassword(_storeCode);
      getTMemberEmailByCode({
        variables: {
          code: _storeCode,
        },
      });
      setStoreCode(_storeCode);
      setForgotPasswordPhase('CompleteSendMail');
    } catch (error) {
      openErrorModal({ message: 'メール送信に失敗しました。' });
    }
  };

  const handleResetPassword = async (args: { confirmationCode: string; password: string }) => {
    try {
      await auth.resetPassword(storeCode, args.confirmationCode, args.password);
      setForgotPasswordPhase('CompleteUpdatePassword');
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
            openErrorModal({ message: '確認コードが正しくありません。' });
            break;
          case 'ExpiredCodeException':
            openErrorModal({ message: '確認コードが期限切れです。再送してください。' });
            break;
          case 'LimitExceededException':
            openErrorModal({ message: '確認コード入力回数が上限を超えました。時間をおいて再実行してください。' });
            break;
          default:
            openErrorModal({ message: 'パスワードの再設定に失敗しました' });
            break;
        }
      }
    } finally {
      setStoreCode('');
    }
  };

  const content = () => {
    switch (forgotPasswordPhase) {
      case 'CompleteSendMail':
        return <CompleteSendMail nextPhase={() => setForgotPasswordPhase('InputCodeAndPassword')} />;
      case 'InputCodeAndPassword':
        return (
          <ResetPassword
            onResetPassword={handleResetPassword}
            onEmailUpdate={() => setForgotPasswordPhase('EmailUpdate')}
            email={data?.getTMemberEmailByCode.email}
          />
        );
      case 'CompleteUpdatePassword':
        return <CompleteUpdatePassword />;
      case 'EmailUpdate':
        return (
          <EmailUpdate
            tpcode={storeCode}
            emailInfo={emailInfo}
            toBack={() => {
              setForgotPasswordPhase('InputCodeAndPassword');
            }}
            toNext={(_emailInfo) => {
              setEmailInfo(_emailInfo as typeof emailInfo);
              setForgotPasswordPhase('ConfirmEmailContent');
            }}
          />
        );
      case 'ConfirmEmailContent':
        return (
          <ConfirmEmailContent
            tpCode={storeCode}
            firstName={emailInfo?.firstName ?? ''}
            lastName={emailInfo?.lastName ?? ''}
            firstKana={emailInfo?.firstKana ?? ''}
            lastKana={emailInfo?.lastKana ?? ''}
            managerBirthday={emailInfo?.managerBirthday ?? new Date()}
            businessName={emailInfo?.businessName ?? ''}
            zipCode1={emailInfo?.zipCode1 ?? ''}
            zipCode2={emailInfo?.zipCode2 ?? ''}
            phone={emailInfo?.phone ?? ''}
            email={emailInfo?.email ?? ''}
            toNext={() => {
              setForgotPasswordPhase('CompleteEmail');
            }}
            toBack={() => {
              setForgotPasswordPhase('EmailUpdate');
            }}
          />
        );
      case 'CompleteEmail':
        return <CompleteEmail />;
      default:
        return <SendMail onSendMail={handleSendMail} />;
    }
  };
  // feat screen 02-02-01 start
  return !isMobile ? (
    <Box width="100vw" height="100vh" justifyContent="center" pt={40}>
      {loading && <Loading />}
      <TitleHeader />
      {content()}
    </Box>
  ) : (
    // feat screen 02-02-05 start
    <Box width="100vw" height="100svh" justifyContent="center" pt={forgotPasswordPhase === "InputCodeAndPassword" ? 0: (forgotPasswordPhase === "EmailUpdate" ? 21 : 40 )}>
      {/* feat screen 02-02-05 end */}
      {loading && <Loading />}
      {/* feat screen 02-02-05 start */}
      <TitleHeader titlePadding={forgotPasswordPhase === "EmailUpdate" ? 0 : undefined}/>
       {/* feat screen 02-02-05 end */}
      {content()}
    </Box>
  );
  // feat screen 02-02-01 end
}
