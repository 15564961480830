import React from 'react';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function MainContentTitle({ text }: { text: string }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <Text variant="h1" color="darkBlue">
      {text}
    </Text>
  ) : (
    /* feat_common_response_size_title_start */
    <Text variant="h2" color="darkBlue">
      {text}
    </Text>
     /* feat_common_response_size_title_end */
  );
}
