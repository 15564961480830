/**
 * Figma ID: 04-01-01-14
 * 名称: 基本情報（タブ）
 */

import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { BelongTo, Sub } from 'pages/s04-02/components/const';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { KindName } from '../../../common/const';

function convertYesNoToLabel(param?: number | null) {
  switch (param) {
    case BelongTo.YES:
    case Sub.YES:
      return '有';
    case BelongTo.NO:
    case Sub.NO:
      return '無';
    default:
      return '';
  }
}

type Props = {
  info?: gql.VtBtmemBase;
  offerList?: gql.VtArbeitEntryList;
};

function BasicInfo({ info, offerList }: Props) {
  const idContent = typeof info?.identification === 'number' ? identificationLabel(info.identification, true) : '-';
  const bizName = info?.biz_name || '';

  return (
    <Box>
      <Info
        items={[
          {
            label: '会員番号',
            content: info?.btmem_id?.toString()?.padStart(10, '0'),
            isShow: offerList?.kind_name === KindName.GENERAL,
          },
          { label: '氏名', content: info?.name, isShow: offerList?.kind_name !== KindName.GENERAL },
          { label: '氏名（フリガナ）', content: info?.kana, isShow: offerList?.kind_name !== KindName.GENERAL },
          {
            label: '会員番号',
            content: info?.btmem_id?.toString().padStart(10, '0'),
            isShow: offerList?.kind_name !== KindName.GENERAL,
          },
          { label: '電話番号', content: info?.phone, isShow: offerList?.kind_name !== KindName.GENERAL },
          { label: '年齢', content: info?.age?.toString() },
          { label: 'ランク', content: info?.rank_name },
          { label: 'スコア', content: info?.score?.toString() },
          { label: 'バイト回数', content: info?.worked_count?.toString() },
          {
            label: '住所',
            content: `${String(info?.state)}${String(info?.city)}${String(info?.address)}${String(info?.address2)}`,
          },
          { label: `所属店舗(${bizName})`, content: convertYesNoToLabel(info?.belong) },
          { label: `サブ店舗(${bizName})`, content: convertYesNoToLabel(info?.sub) },
          { label: '性別', content: info?.gender },
          { label: '国籍', content: info?.nationality, isShow: !!info?.nationality },
          { label: '在留期限', content: info?.period_date, isShow: !!info?.period_date },
          { label: '保有資格', content: info?.general_licenses ? info?.general_licenses : '-' },
          { label: `${bizName}資格`, content: info?.biz_license ? info?.biz_license : '-' },
          { label: '制服サイズ', content: info?.uniform_size },
          { label: '遅刻率', content: `${String(info?.late_count)}回/${String(info?.late_per)}%` },
          { label: 'キャンセル率', content: `${String(info?.cancel_count)}回/${String(info?.cancel_per)}%` },
          { label: 'ドタキャン率', content: `${String(info?.imminent_count)}回/${String(info?.imminent_per)}%` },
          {
            label: '本人確認',
            content: idContent,
            isShow: offerList?.kind_name !== KindName.GENERAL,
          },
        ]}
      />
    </Box>
  );
}

export default BasicInfo;
