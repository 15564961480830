import {Box} from 'components/box';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Avatar} from 'components/avatar';
import {Chip} from 'components/chip';
import {Text} from 'components/text';
import {MainButton} from 'components/mainButton';
import {useErrorModal} from 'components/error/errorModalProvider';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import {useAuth} from 'hooks/authProvider';
import {
    useAddelTFavoriteBtmemByIdBtmemIdMutation,
    useGetVbMemberBaseByIdQuery,
    VbMemberBase,
    VtBizBtmemInfo,
} from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {MultiMenuHeader} from 'components/multiMenuHeader';
import {ResultRows} from 'components/const';
import {Loading} from 'components/loading/loading';
import {ImageModal} from 'components/newModal';
import {styled} from '@linaria/react';
import {BelongType, BtmemBlockFlag, IsFavorite, MemberStatus} from '../const';
import {UnrestrictedContent} from './unrestrictedContent';
import {RestrictedContent} from './restrictedContent';
import {ImgWrapper} from '../components/imgWrapper';

const StyledBorderBox = styled.div<{
    borderColor: string;
}>`
    box-sizing: border-box;
    padding: 2px 8px;
    border-radius: 12px;
    border: 1px solid ${({borderColor}) => borderColor};
    height: 22px;
    align-items: center;
    display: flex;
    /* feat_screen_05-01-01-02_start */
    @media only screen and (max-width: 768px) {
        height: 24px;
    }
    /* feat_screen_05-01-01-02_end */
`;

const StyledHeaderBG = styled.div<{
    backgroundColor: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    width: 100%;
    gap: 24px;
    background-color: ${({backgroundColor}) => backgroundColor};
    /* feat_screen_05-01-01-02_start */
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        align-items: start;
        gap: 0px;
    }
    /* feat_screen_05-01-01-02_end */
`;

/* feat_screen_05-01-01-02_start */
const StyledAvatar = styled.div`
    width: 80px;
    height: 60px;
    background-color: black;
`;

const StyledBorderBoxRed = styled.div<{
    borderColor: string;
}>`
    box-sizing: border-box;
    padding: 0px 8px;
    border-radius: 12px;
    border: 1px solid ${({borderColor}) => borderColor};
    height: auto;
    align-items: center;
    display: flex;
    flex-direction: row;
`;

/* feat_screen_05-01-01-02_end */

export function S0501Detail() {
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const auth = useAuth();
    const {openErrorModal} = useErrorModal();
    /* feat_common_responsive_useMobile_logic_start */
    const isMobile = useIsMobile();
    /* feat_common_responsive_useMobile_logic_end */

    const tpmemId = auth?.tpmemId;
    const btmemId = parseInt(params.id ?? '0', 10);
    if (tpmemId === null) navigate('/signin');

    const {
        data: {getVBMemberBaseById: {info, base}} = {
            getVBMemberBaseById: {
                info: {} as VtBizBtmemInfo,
                base: {} as VbMemberBase,
            },
        },
        loading: useGetVbMemberBaseByIdQueryLoading,
        refetch,
        error: useGetVbMemberBaseByIdQueryError,
    } = useGetVbMemberBaseByIdQuery({
        variables: {
            id: parseInt(params.id ?? '0', 10),
            tpmem_id: tpmemId ?? 0,
        },
        fetchPolicy: 'no-cache',
    });

    const [imageModalStep, setImageModalStep] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (useGetVbMemberBaseByIdQueryError) {
            openErrorModal({
                message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
        }
    }, [useGetVbMemberBaseByIdQueryError, openErrorModal]);

    const [updateFavoriteStatus, {loading: updateFavoriteStatusLoading}] = useAddelTFavoriteBtmemByIdBtmemIdMutation();

    const handleFavorite = () => {
        updateFavoriteStatus({
            variables: {
                btmem_id: btmemId,
                id: tpmemId ?? 0,
                on_off: info.favorite_flag === IsFavorite.TRUE ? IsFavorite.FALSE : IsFavorite.TRUE,
            },
        })
            .then((result) => {
                if (result.data?.addelTFavoriteBtmemByIdBtmemId.resultRows === ResultRows.UPDATED) {
                    refetch();
                } else {
                    openErrorModal({
                        message:
                            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                    });
                }
            })
            .catch((e) => {
                openErrorModal({
                    message:
                        'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
            });
    };

    if (useGetVbMemberBaseByIdQueryError) {
        return <div>該当データが存在しません</div>;
    }
    // ヘッダーの背景判定
    const headerBackgroundColor = (identification: number | undefined) => {
        switch (identification) {
            case MemberStatus.PERMANENTLY_DISABLE:
            case MemberStatus.TEMPORARILY_DISABLE:
            case MemberStatus.SUSPENSION:
                return 'rgba(229, 0, 0, 0.1)';
            default:
                return '';
        }
    };

    // Imageのラベル取得
    const rowLabel = (identification: number | undefined) => {
        switch (identification) {
            case MemberStatus.PERMANENTLY_DISABLE:
                return '一時停止';
            case MemberStatus.TEMPORARILY_DISABLE:
                return '停止';
            case MemberStatus.SUSPENSION:
                return '休止';
            default:
                return '';
        }
    };
    // ImageのColor取得
    const rowImageColor = (identification: number | undefined) => {
        switch (identification) {
            case MemberStatus.PERMANENTLY_DISABLE:
                return 'rgba(0, 0, 0, 0.7)';
            case MemberStatus.TEMPORARILY_DISABLE:
                return 'rgba(0, 0, 0, 0.7)';
            case MemberStatus.SUSPENSION:
                return 'rgba(229, 0, 0, 0.7)';
            default:
                return '';
        }
    };

    return !isMobile ? (
        <>
            {(updateFavoriteStatusLoading || useGetVbMemberBaseByIdQueryLoading) && <Loading/>}
            <Box display="flex" flexDirection="column" height="calc(100%)" gap={26}>
                <Box display="flex" flexDirection="row" height={60} width="100%" gap={24} alignItems="center">
                    <StyledHeaderBG
                        backgroundColor={
                            info.block_flag === BtmemBlockFlag.TRUE ? 'rgba(229, 0, 0, 0.1)' : headerBackgroundColor(info.status)
                        }
                    >
                        {info.status === MemberStatus.SUSPENSION ||
                        info.status === MemberStatus.PERMANENTLY_DISABLE ||
                        info.status === MemberStatus.TEMPORARILY_DISABLE ? (
                            <ImgWrapper
                                width={80}
                                height={60}
                                memberstatus={info.status}
                                backgroundcolor={rowImageColor(info?.status)}
                                isMobile={isMobile}
                                text={
                                    <Text variant="body14" color="white" bold>
                                        {rowLabel(info.status)}
                                    </Text>
                                }
                            >
                                <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                            </ImgWrapper>
                        ) : (
                            <button
                                onClick={() => {
                                    setImageModalStep(true);
                                }}
                                type="button"
                                style={{width: '80px', height: '60px', padding: 0, border: 'none', background: 'none'}}
                            >
                                <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                            </button>
                        )}
                        <Box display="flex" flexDirection="column" width="100%" gap={1}>
                            <Box width={56}>
                                <Chip color="darkBlue">{info.type === BelongType.OWN_STORE ? '自店' : 'サブ'}</Chip>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                                    <Text variant="h1">{base.btmem_number}</Text>
                                    {info.favorite_flag === IsFavorite.TRUE && (
                                        <StyledBorderBox borderColor="#008599">
                                            <Text variant="caption12" bold color="primaryBlue">
                                                お気に入り
                                            </Text>
                                        </StyledBorderBox>
                                    )}
                                    {typeof info.block_reason === 'string' && info.block_flag === BtmemBlockFlag.TRUE && (
                                        <StyledBorderBox borderColor="#E50000">
                                            <Box display="flex" flexDirection="row" gap={8}>
                                                <Text variant="caption12" bold color="cautionRed">
                                                    ブロック中
                                                </Text>
                                                <Text variant="caption12" color="cautionRed">
                                                    {info.block_reason}
                                                </Text>
                                            </Box>
                                        </StyledBorderBox>
                                    )}
                                </Box>
                                {info.favorite_flag === IsFavorite.TRUE ? (
                                    <MainButton variant="secondary" icon="favoriteBorder" iconColor="#008599"
                                                onClick={handleFavorite}>
                                        お気に入り解除
                                    </MainButton>
                                ) : (
                                    <MainButton icon="favoriteBorder" iconColor="#FFFFFF" onClick={handleFavorite}>
                                        お気に入り追加
                                    </MainButton>
                                )}
                            </Box>
                        </Box>
                    </StyledHeaderBG>
                </Box>
                <Box display="flex" flex="auto">
                    {info.status === MemberStatus.SUSPENSION || !(info.type === BelongType.OWN_STORE) ||
                    info.block_flag === BtmemBlockFlag.TRUE ? (
                        <RestrictedContent refetchHeaderData={refetch}/>
                    ) : (
                        <UnrestrictedContent refetchHeaderData={refetch}/>
                    )}
                </Box>
            </Box>
            <ImageModal src={info?.image || ''} isOpen={imageModalStep} onClose={() => setImageModalStep(false)}/>
        </>
    ) : (
        /* feat_screen_05-01-01-02_start */
        <>
            {(updateFavoriteStatusLoading || useGetVbMemberBaseByIdQueryLoading) && <Loading/>}
            <Box pb={24}>
                <MultiMenuHeader
                    items={
                        [
                            {
                                label: "バイトラベラー"
                            },
                            {
                                label: "自店・サブ一覧"
                            }
                        ]
                    }
                    mainHeader="自店・サブ一覧"
                />
                <Box display="flex" flexDirection="column" height="100%">
                    <Box display="flex" flexDirection="column" width="100%" alignItems="center" pb={16}>
                        <StyledHeaderBG
                            backgroundColor={
                                info.block_flag === BtmemBlockFlag.TRUE ? 'rgba(229, 0, 0, 0.1)' : headerBackgroundColor(info.status)
                            }>
                            <Box display="flex" flexDirection="column" pl={16} pt={16} pr={16} gap={8}>
                                <Box display="flex" flexDirection="row" gap={16}>
                                    {info.status === MemberStatus.SUSPENSION ||
                                    info.status === MemberStatus.PERMANENTLY_DISABLE ||
                                    info.status === MemberStatus.TEMPORARILY_DISABLE ? (
                                        <ImgWrapper
                                            width={80}
                                            height={60}
                                            memberstatus={info.status}
                                            backgroundcolor={rowImageColor(info?.status)}
                                            isMobile={isMobile}
                                            text={
                                                <Text variant="body14" color="white" bold>
                                                    {rowLabel(info.status)}
                                                </Text>
                                            }
                                        >
                                            <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                                        </ImgWrapper>
                                    ) : (
                                        <StyledAvatar>
                                            <button
                                                onClick={() => {
                                                    setImageModalStep(true);
                                                }}
                                                type="button"
                                                style={{
                                                    width: '80px',
                                                    height: '60px',
                                                    padding: 0,
                                                    border: 'none',
                                                    background: 'none'
                                                }}
                                            >
                                                <Avatar src={info.image ?? NoImageLarge} width={80} height={60}/>
                                            </button>
                                        </StyledAvatar>
                                    )}
                                    <Box display="flex" flexDirection="column" width="100%" gap={10} pt={4}>
                                        <Box>
                                            <Chip width={50}
                                                  color="darkBlue">{info.type === BelongType.OWN_STORE ? '自店' : 'サブ'}</Chip>
                                        </Box>
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                            <Box display="flex" flexDirection="column" gap={8}>
                                                <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                                                    <Text variant="h2">{base.btmem_number}</Text>
                                                    {info.favorite_flag === IsFavorite.TRUE && (
                                                        <StyledBorderBox borderColor="#008599">
                                                            <Text variant="chip10" bold color="primaryBlue">
                                                                お気に入り
                                                            </Text>
                                                        </StyledBorderBox>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    {typeof info.block_reason === 'string' && info.block_flag === BtmemBlockFlag.TRUE && (
                                        <StyledBorderBoxRed borderColor="#E50000">
                                            <Text variant="chip9" bold color="cautionRed">
                                                ブロック中
                                            </Text>
                                            <Text variant="chip9" color="cautionRed">
                                                {info.block_reason}
                                            </Text>
                                        </StyledBorderBoxRed>
                                    )}
                                </Box>
                                <Box
                                    pt={typeof info.block_reason === 'string' && info.block_flag === BtmemBlockFlag.TRUE ? 8 : 0}
                                    pb={typeof info.block_reason === 'string' && info.block_flag === BtmemBlockFlag.TRUE ? 16 : 0}>
                                    {info.favorite_flag === IsFavorite.TRUE ? (
                                        <MainButton variant="secondary" icon="favoriteBorder"
                                                    iconColor="#008599"
                                                    iconSize={20} onClick={handleFavorite} width={158}>
                                            お気に入り解除
                                        </MainButton>
                                    ) : (
                                        <MainButton icon="favoriteBorder" iconColor="#FFFFFF" iconSize={20}
                                                    onClick={handleFavorite} width={158}>
                                            お気に入り追加
                                        </MainButton>
                                    )}
                                </Box>
                            </Box>
                        </StyledHeaderBG>
                    </Box>
                    <Box display="flex" flex="auto" gap={16} px={16}>
                        {info.status === MemberStatus.SUSPENSION || !(info.type === BelongType.OWN_STORE) ||
                        info.block_flag === BtmemBlockFlag.TRUE ? (
                            <RestrictedContent refetchHeaderData={refetch} blogFlag={!!info.block_flag}/>
                        ) : (
                            <UnrestrictedContent refetchHeaderData={refetch} blogFlag={!!info.block_flag}/>
                        )}
                    </Box>
                </Box>
            </Box>
            <ImageModal src={info?.image || ''} isOpen={imageModalStep} onClose={() => setImageModalStep(false)}/>
        </>
        /* feat_screen_05-01-01-02_end */
    );
}
