import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { RefundRequestModalForm } from 'pages/s07-01/type';
import React, { useState } from 'react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useForm, useWatch } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';

enum ModalStep {
  REFUND_REQUEST = 1,
  CONFIRM = 2,
  COMPLETE = 3,
}

export function RefundRequestModal(props: {
  isOpen: boolean;
  onClose: () => void;
  price: number;
  refundCharge: number;
  refetchWalletSumLog: () => void;
}) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, onClose, price, refundCharge, refetchWalletSumLog } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.REFUND_REQUEST);

  const date = new Date();
  const nextMonth = (date.getMonth() + 2) % 12; // 0始まりの為、月が12を越えないよう判定
  const requestMonth = nextMonth === 0 ? 12 : nextMonth; // 請求月取得

  const auth = useAuth();
  const tpmemId = auth?.tpmemId;

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<RefundRequestModalForm>({ mode: 'onChange' });
  const formPrice = useWatch({ control, name: 'price' });

  const validate = (value: string) => {
    const num = Number(value);
    if (Number.isNaN(Number(value))) return '数値を入力してください';
    if (num > price) return '請求可能額を超えています';
    if (num <= refundCharge) return `月初回払戻の場合${refundCharge}円以下は払戻できません`;
    return true;
  };

  const onModalClose = () => {
    onClose();
    reset();
    setModalStep(ModalStep.REFUND_REQUEST);
  };

  const [addCPaymentMutation, { loading: loadingRefund }] = gql.useAddCPaymentMutation({
    variables: { mem_id: tpmemId ?? 0, price: Number(formPrice) },
  });

  const { openErrorModal } = useErrorModal();

  const handleClaim = async () => {
    if (loadingRefund) return;

    await addCPaymentMutation()
      .then((result) => {
        if (!result.errors) {
          setModalStep(ModalStep.COMPLETE);
        } else {
          onClose();
          openErrorModal({ message: '請求に失敗しました' });
        }
      })
      .catch(() => {
        onClose();
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  const refundRequest = {
    header: (
      <Text variant="h2" color="darkBlue">
        払戻請求
      </Text>
    ),
    // feat screen 07-01-02_04 start
    content: !isMobile ? (
      <Box display="flex" flexDirection="column" gap={8} pa={8} flex="1">
        <Text variant="body14">以下の金額を請求します。</Text>
        <Box display="flex" flexDirection="column" height="auto" gap={5.5}>
          <Text color="blueGray" variant="h3">
            請求額
          </Text>
          <Box display="flex" flexDirection="row" gap={8} alignItems="center">
            <RhfInput
              control={control}
              name="price"
              width={120}
              rules={{
                validate: {
                  message: (v) => validate(v),
                },
              }}
            />
            <Text variant="body14" color="blueGray">
              円
            </Text>
          </Box>
          <Text variant="caption11" color="cautionRed">
            {errors.price?.message}
          </Text>
        </Box>
        <Text variant="body14">請求可能額：¥{price.toLocaleString()}</Text>
      </Box>
    ) : (
      // feat screen 07-01-02_04 start
      <Box display="flex" flexDirection="column" gap={14}>
        <Text variant="body14">以下の金額を請求します。</Text>
        <Box display="flex" flexDirection="column" height="auto" gap={5.5}>
          <Text color="blueGray" variant="h3">
            請求額
          </Text>
          <Box display="flex" flexDirection="row" gap={9} alignItems="center">
            <RhfInput
              control={control}
              name="price"
              width={147}
              rules={{
                validate: {
                  message: (v) => validate(v),
                },
              }}
            />
            <Text variant="body14" color="blueGray">
              円
            </Text>
          </Box>
          <Text variant="caption11" color="cautionRed">
            {errors.price?.message}
          </Text>
        </Box>
        <Text variant="body14">請求可能額：¥{price.toLocaleString()}</Text>
      </Box>
      // feat screen 07-01-02_04 end
    ),
    // feat screen 07-01-02_04 end
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 97}
          variant="secondary"
          thin={!isMobile}
          onClick={() => onModalClose()}
          customHeight={!isMobile ? 28 : 32}
          // feat screen 07-01-02_04 end
        >
          キャンセル
        </MainButton>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 97}
          customHeight={!isMobile ? 28 : 32}
          thin={!isMobile}
          // feat screen 07-01-02_04 end
          onClick={() => {
            setModalStep(ModalStep.CONFIRM);
          }}
          disabled={(typeof formPrice === 'undefined' || errors.price) && true}
        >
          請求
        </MainButton>
      </Box>
    ),
    // feat screen 07-01-02_04 start
    width: !isMobile ? 384 : '100%',
    height: !isMobile ? 304 : 'auto',
    // feat screen 07-01-02_04 end
  };

  const confirm = {
    header: (
      <Text variant="h2" color="darkBlue">
        払戻請求 確認
      </Text>
    ),
    content: (
      // feat screen 07-01-02_04 start
      <Box display="flex" flexDirection="column" gap={!isMobile ? 16 : 19} pa={8} flex="1">
        {/* // feat screen 07-01-02_04 end */}
        <Text variant="body14">以下の費用で請求してよろしいですか？</Text>
        <Text variant="body14">・¥{Number(formPrice).toLocaleString()}</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8} pr={!isMobile ? 0 : 2}>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 96}
          variant="secondary"
          thin={!isMobile}
          // feat screen 07-01-02_04 end
          customHeight={!isMobile ? 28 : 32}
          onClick={() => onModalClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 96}
          customHeight={!isMobile ? 28 : 32}
          variant="secondary"
          thin={!isMobile}
          // feat screen 07-01-02_04 end
          onClick={() => setModalStep(ModalStep.REFUND_REQUEST)}
        >
          戻る
        </MainButton>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 96}
          customHeight={!isMobile ? 28 : 32}
          thin={!isMobile}
          // feat screen 07-01-02_04 end
          onClick={() => {
            handleClaim();
          }}
          disabled={loadingRefund}
        >
          請求
        </MainButton>
      </Box>
    ),
    // feat screen 07-01-02_04 start
    width: !isMobile ? 384 : '100%',
    height: !isMobile ? 226 : 'auto',
    // feat screen 07-01-02_04 end
  };

  const complete = {
    header: (
      <Text variant="h2" color="darkBlue">
        払戻請求 完了
      </Text>
    ),
    content: (
      // feat screen 07-01-02_04 start
      <Box display="flex" flexDirection="column" gap={!isMobile ? 16 : 0} pa={!isMobile ? 8 : 0} flex="1">
        {/* // feat screen 07-01-02_04 end */}
        <Text variant="body14">払戻請求が完了しました。</Text>
        <Text variant="body14">支払月は{requestMonth}月のお支払いになります。</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat screen 07-01-02_04 start
          width={!isMobile ? 104 : 100}
          customHeight={!isMobile ? 28 : 32}
          // feat screen 07-01-02_04 end
          variant="secondary"
          thin={!isMobile}
          onClick={() => {
            refetchWalletSumLog();
            onModalClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    // feat screen 07-01-02_04 start
    width: !isMobile ? 384 : '100%',
    height: !isMobile ? 238 : 190,
    // feat screen 07-01-02_04 end
  };

  const modalContent = {
    [ModalStep.REFUND_REQUEST]: refundRequest,
    [ModalStep.CONFIRM]: confirm,
    [ModalStep.COMPLETE]: complete,
  };

  return <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />;
}
