/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { Divider } from 'components/newDivider';
import { isInteger } from 'lodash';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 04-02-01-06
 * トライアルチケット使用、トライアルチケットNG
 */

interface TicketData {
  amountToUse: number;
}

function UseTicketModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  amount: number;
  onSubmit: (amount: number) => void;
  maxToUse: number;
  pricePerTicket: number;
}) {
  const { isOpen, onModalClose, amount, onSubmit, maxToUse, pricePerTicket } = props;
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  });

  const maxAmount = Math.ceil(maxToUse / pricePerTicket);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm<TicketData>({ reValidateMode: 'onChange' });

  const currentAmountToUse = watch('amountToUse');

  function onClose() {
    setValue('amountToUse', 0);
    clearErrors();
    onModalClose();
  }

  const submit = handleSubmit((data) => {
    onSubmit(data.amountToUse);
    onClose();
  });
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = !isMobile
    ? {
        header: (
          <Text variant="h2" color="darkBlue">
            トライアルチケット使用
          </Text>
        ),
        content: (
          <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
            <Box>
              <Text variant="body14" color="black">
                トライアルチケットを使用しますか？
              </Text>
            </Box>
            <Box>
              <Box>
                <Text variant="body14" color="black">
                  現在の所持数: {amount}
                </Text>
              </Box>
              <Box>
                <Text variant="caption11" color="blueGray">
                  トライアルチケットの使用限度枚数は
                </Text>
                <Text variant="caption12" color="blueGray" bold>
                  {maxAmount}
                </Text>
                <Text variant="caption11" color="blueGray">
                  枚です。
                </Text>
              </Box>
            </Box>
            <Box>
              <Box>
                <Text variant="h3" color="blueGray">
                  使用枚数
                </Text>
              </Box>
              <Box display="flex" alignItems="flex-end" gap={8}>
                <RhfInput
                  name="amountToUse"
                  control={control}
                  defaultValue={0}
                  type="number"
                  width={80}
                  min={0}
                  rules={{
                    max: { value: maxAmount, message: '使用限度枚数を超えています' },
                    min: { value: 1, message: '1以上の値を入力してください' },
                    validate: (value) => {
                      // valueはstring型でわたってくるので、Numberに変換してから判定する
                      if (!isInteger(Number(value)) || String(value) === '') {
                        return '整数を入力してください';
                      }
                      if (Number(value) > amount) {
                        return '所持枚数を超えています';
                      }
                      return true;
                    },
                  }}
                />
                <Text variant="body14" color="blueGray">
                  枚
                </Text>
              </Box>
              <Box mb={20}>
                {errors.amountToUse && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.amountToUse.message}
                  </Text>
                )}
              </Box>
              <Divider option="horizontal" />
              <Box display="flex" mt={20}>
                <Box width={160}>
                  <Text variant="body14" color="black">
                    割引金額
                  </Text>
                </Box>
                <Box width={160}>
                  <Text variant="body14" color="black">
                    {formatter.format(pricePerTicket * (currentAmountToUse || 0))}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text variant="caption11" color="cautionRed">
                  ＊おつりは出ませんのでご注意ください。
                </Text>
              </Box>
            </Box>
          </Box>
        ),
        footer: (
          <Box display="flex" justifyContent="flex-end" columnGap={8}>
            <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
              キャンセル
            </MainButton>
            <MainButton width={104} thin onClick={() => submit()} disabled={!!errors.amountToUse}>
              使用
            </MainButton>
          </Box>
        ),
        height: 'auto',
        width: 384,
      }
    : {
        // feat_screen_04-02-01-06_start
        header: (
          <Text variant="h2" color="darkBlue">
            トライアルチケット使用
          </Text>
        ),
        content: (
          <Box display="flex" flexDirection="column" rowGap={12}>
            <Box>
              <Text variant="body14" color="black">
                トライアルチケットを使用しますか？
              </Text>
            </Box>
            <Box>
              <Box mb={8}>
                <Text variant="body14" color="black">
                  現在の所持数: {amount}
                </Text>
              </Box>
              <Box>
                <Text variant="body14" color="blueGray">
                  トライアルチケットの使用限度枚数は
                </Text>
                <Text variant="body14" color="blueGray" bold>
                  {maxAmount}
                </Text>
                <Text variant="body14" color="blueGray">
                  枚です。
                </Text>
              </Box>
            </Box>
            <Box>
              <Box display="flex" flexDirection="column" gap={4}>
                <Box>
                  <Text variant="h3" color="blueGray">
                    使用枚数
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" gap={8}>
                  <RhfInput
                    name="amountToUse"
                    control={control}
                    defaultValue={0}
                    type="number"
                    width={90}
                    min={0}
                    rules={{
                      max: { value: maxAmount, message: '使用限度枚数を超えています' },
                      min: { value: 1, message: '1以上の値を入力してください' },
                      validate: (value) => {
                        // valueはstring型でわたってくるので、Numberに変換してから判定する
                        if (!isInteger(Number(value)) || String(value) === '') {
                          return '整数を入力してください';
                        }
                        if (Number(value) > amount) {
                          return '所持枚数を超えています';
                        }
                        return true;
                      },
                    }}
                  />
                  <Text variant="body14" color="blueGray">
                    枚
                  </Text>
                </Box>
                {errors.amountToUse && (
                  <Box>
                    <Text variant="caption12" color="cautionRed">
                      {errors.amountToUse.message}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={16}>
                <Box>{null}</Box>
                <Divider option="horizontal" />
                <Box display="flex" justifyContent="space-between">
                  <Box width={160}>
                    <Text variant="body14" color="black">
                      割引金額
                    </Text>
                  </Box>
                  <Text variant="body14" color="black">
                    {formatter.format(pricePerTicket * (currentAmountToUse || 0))}
                  </Text>
                </Box>
                <Box>
                  <Text variant="body14" color="cautionRed">
                    ＊おつりは出ませんのでご注意ください。
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ),
        footer: (
          <Box display="flex" justifyContent="flex-end" columnGap={8}>
            <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClose()}>
              キャンセル
            </MainButton>
            <MainButton width={96} customHeight={32} thin onClick={() => submit()} disabled={!!errors.amountToUse}>
              使用
            </MainButton>
          </Box>
        ),
        height: 'auto',
        width: '90%',
        // feat_screen_04-02-01-06_end
      };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={modalContent?.height}
      width={modalContent?.width}
    />
  );
}

export default UseTicketModal;
