import React, { useState } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { useErrorModal } from 'components/error/errorModalProvider';

const enum ModalStep {
  CONFIRM = 1,
  COMPLETE = 2,
}

/**
 * Figma ID
 * 04-02-01-12, 04-02-01-13
 * ウォレット解除、ウォレット解除完了
 */

function CancelWalletModal(props: { isOpen: boolean; onModalClose: () => void; submit: () => Promise<boolean> }) {
  const { isOpen, onModalClose, submit } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CONFIRM);
  const { openErrorModal } = useErrorModal();

  const confirmModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        解除
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          ウォレットの使用を解除しますか？
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            submit().then((isSuccess) => {
              if (isSuccess) {
                setModalStep(ModalStep.COMPLETE);
              } else {
                onModalClose();
                openErrorModal({
                  message: 'ウォレットの使用解除に失敗しました。もう一度やり直してください。',
                });
              }
            });
          }}
        >
          解除
        </MainButton>
      </Box>
    ),
  };

  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        解除 完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          ウォレットの使用を解除しました。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => {
            onModalClose();
            setModalStep(ModalStep.CONFIRM); // reset modalStep
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
  };

  const modalContent = modalStep === ModalStep.CONFIRM ? confirmModal : completeModal;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default CancelWalletModal;
