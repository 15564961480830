import React from 'react';
import { GraphQLErrorCode } from 'components/const';
import { Text } from 'components/text';

// TODO: 本コンポーネントは error/handleMutationResult.tsx と処理が重複しているので、将来的に統合してください

interface Error {
  title: string;
  message: string | React.ReactNode;
  requiredCallback?: () => void;
}

function toError(errorCode: number): Error {
  const defaultTitle = 'エラー';
  switch (errorCode) {
    case GraphQLErrorCode.MAX_LIMIT_REACHED_ERROR:
      return {
        title: defaultTitle,
        message: '登録が上限に達しました',
      };
    case GraphQLErrorCode.MONEY_TRANSFER_ERROR:
      return {
        title: defaultTitle,
        message: '与信審査がまだ通過していません',
      };
    case GraphQLErrorCode.INVALID_STATUS_ERROR:
      return {
        title: defaultTitle,
        message: 'ステータスエラー',
      };
    case GraphQLErrorCode.OUT_OF_DATE_RANGE_ERROR:
      return {
        title: defaultTitle,
        message: '募集可能期間外です',
      };
    case GraphQLErrorCode.LIMIT_SALARY_ERROR:
      return {
        title: defaultTitle,
        message: '給与の上限に達しました',
      };
    case GraphQLErrorCode.OVER_WORKINGHOURS_ERROR:
      return {
        title: defaultTitle,
        message: '休憩時間が募集シフト時間を超えています',
      };
    case GraphQLErrorCode.PAUSE_STATUS_ERROR:
      return {
        title: '現在休止中です',
        message: (
          <Text variant="body14">
            現在運営により休止中になっています。
            <br />
            一部機能が制限されます。
            <br />
            詳細は運営にご確認ください。
          </Text>
        ),
        requiredCallback: () => {
          window.location.href = '/';
        },
      };
    case GraphQLErrorCode.UNKNOWN_ERROR:
    default:
      return {
        title: defaultTitle,
        message: '不明なエラーです',
      };
  }
}

export function handleMutationResult(
  errorCode: number,
  onSuccess: () => void,
  onError: ({ error }: { error: Error }) => void
) {
  if (errorCode === GraphQLErrorCode.SUCCESS) {
    onSuccess();
  } else {
    const error = toError(errorCode);
    onError({ error });
  }
}
