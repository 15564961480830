/**
 * Figma ID: 08
 * 名称: 各種設定
 */

import React, { useEffect } from 'react';
import { Box } from 'components/box';
import { SubMenu } from 'components/subMenu';
import { ContentsCard, PageBody, PageContentsWrapper } from 'components/assets/css/pages/pageStyle';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { EducationFeeByRank } from './detail/educationFeeByRank';
import { WorkerList } from './detail/workerList';
import { NoticeWorkingConditions } from './detail/noticeWorkingConditions';
import { TpBlock } from './detail/tpBlock';
import { Manual } from './detail/manual';
import { RecruitmentImage } from './detail/recruitmentImage';
import { Attachment } from './detail/attachment';

function S08() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  /* feat_common_responsive_useMobile_logic_end */

  /* feat_screen 11 handle set tab select start */
  const { id } = useParams();
  const [tab, setTab] = React.useState<number>(Number(id) || 0);

  useEffect(() => {
    const iId = Number(id);
    const correctTab = [0,1,2,3,4,5,6];
    if ((iId || iId === 0)) {
      if (correctTab.includes(iId)) {
        setTab(Number(iId));
      } else {
        navigate('/*');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isMobile) {
      if (location.pathname !== `/s08/${tab}`) {
        navigate(`/s08/${tab}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
   /* feat_screen 11 handle set tab select end */

   // feat screen 08-01-01 start
  const arrayTab = ['ランク別育成料', '労働者名簿', '労働条件通知書','募集画像','添付ファイル','マニュアル','店舗ブロック'];
  return !isMobile ? (
    <PageContentsWrapper>
      <Box pb={24}>
        <Text variant="h1" color="darkBlue">
          各種設定
        </Text>
      </Box>
      <PageBody bottom={60}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                content: <EducationFeeByRank />,
                label: 'ランク別育成料',
              },
              {
                content: <WorkerList />,
                label: '労働者名簿',
              },
              {
                content: <NoticeWorkingConditions />,
                label: '労働条件通知書',
              },
              {
                content: <RecruitmentImage />,
                label: '募集画像',
              },
              {
                content: <Attachment />,
                label: '添付ファイル',
              },
              {
                content: <Manual />,
                label: 'マニュアル',
              },
              {
                content: <TpBlock />,
                label: '店舗ブロック',
              },
            ]}
          />
        </ContentsCard>
      </PageBody>
    </PageContentsWrapper>
  ) : (
    // Responsive Component
    <PageContentsWrapper>
      <MultiMenuHeader
        items={[
          {
            label: '各種設定',
          },
          {
            label: arrayTab[tab],
          },
        ]}
      />
      <Box px={16} mt={16}>
        <PageBody bottom={207}>
          <ContentsCard>
            <Dropdown
              fullWidth
              tab={tab}
              setTab={setTab}
              items={[
                {
                  content: <EducationFeeByRank />,
                  label: 'ランク別育成料',
                },
                {
                  content: <WorkerList />,
                  label: '労働者名簿',
                },
                {
                  content: <NoticeWorkingConditions />,
                  label: '労働条件通知書',
                },
                {
                  content: <RecruitmentImage />,
                  label: '募集画像',
                },
                {
                  content: <Attachment />,
                  label: '添付ファイル',
                },
                {
                  content: <Manual />,
                  label: 'マニュアル',
                },
                {
                  content: <TpBlock />,
                  label: '店舗ブロック',
                },
              ]}
            />
          </ContentsCard>
        </PageBody>
      </Box>
    </PageContentsWrapper>
  // feat screen 08-01-01 end
  );
}

export default S08;
