import React, { createContext, useContext, useState, useMemo } from "react";
import { VTNewlyHome } from "../common/types";

/*
 * HOMEタブの通知情報用Provider
 * 
 */
type NotifyContextType = {
  notification: VTNewlyHome;
  setNotification: React.Dispatch<
    React.SetStateAction<VTNewlyHome>
  >;
};

// Contextオブジェクト作成
const NotifyContext = createContext<NotifyContextType>(
  {} as NotifyContextType
);
type ProviderProps = {
  children: React.ReactNode;
};
export const useNotify = () => useContext(NotifyContext);

// Providerをコンポーネント化する
export function NotifyProvider(props: ProviderProps) {
  // Propsでchildrenを取り出す
  const { children } = props;

  // screenNoを0で指定
  const [notification, setNotification ] = useState<VTNewlyHome>({
    employment: 0, 
    worker: 0,
    work_time: 0,
    review: 0,
    b_offer: 0,
    belong: 0,
    group: 0,
    u_notice: 0
  });

  // グローバルで管理するState
  const NotifyProviderValue = useMemo(() => ({ notification, setNotification }), [notification, setNotification]);

  // valueを設定したProviderコンポーネントを返却
  return (
    <NotifyContext.Provider value={NotifyProviderValue}>{children}</NotifyContext.Provider>
  );
};