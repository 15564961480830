import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';

/**
 * Figma ID
 * 04-02-06-04
 * 承認完了
 */

function ApprovalCompletedModal(props: {
  /* feat_screen_04-02-06-04_add_prop_start */
  isMobile:boolean;
  /* feat_screen_04-02-06-04_add_prop_end */
  isOpen: boolean; onModalClose: () => void }) {
  const {isMobile, isOpen, onModalClose } = props;

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        承認完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          勤務時間の修正を承認しました。
        </Text>
      </Box>
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_04-02-06-04_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
      /* feat_screen_04-02-06-04_start */
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default ApprovalCompletedModal;
