import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function RequiredIcon() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat screen 08-05-02 start
  return !isMobile ? (
    <Box border="solid 1px red" width={30} display="grid" justifyContent="center" alignItems="center" borderRadius={4}>
      <Text variant="caption11" color="cautionRed">
        必須
      </Text>
    </Box>
  ) : (
    // Responsive Component
    <Box border="solid 1px red" minWidth={45} display="grid" justifyContent="center" alignItems="center" borderRadius={2}>
      <Text display="block" variant="body14" color="cautionRed">
        必須
      </Text>
    </Box>
  // feat screen 08-05-02 end
  );
}
