import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorModalProvider } from 'components/error/errorModalProvider';
import { MemberStoreStatusProvider } from 'hooks/memberStoreStatusProvider';
import App from './app';
import { AuthProvider } from './hooks/authProvider';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import ApolloWrapper from './graphql/use-graphql-mw';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <ErrorModalProvider>
      <ApolloWrapper>
        <AuthProvider>
          <MemberStoreStatusProvider>
            <App />
          </MemberStoreStatusProvider>
        </AuthProvider>
      </ApolloWrapper>
    </ErrorModalProvider>
  );
}

reportWebVitals();
