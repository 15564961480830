import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Control, useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Divider } from 'components/newDivider';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { Option, Store } from 'pages/s09/components/types';
import { Select } from 'components/select';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-04-03
 * グループ招待店舗検索モーダル
 */

interface FormData {
  storeName: string;
  corporateNumber: string;
  city: string;
  store: { [key: string]: boolean };
}

function generateListRow(
  store: Store, 
  control: Control<FormData, any>,
  // feat_screen_09-04-03_start
  isMobile?: boolean
  // feat_screen_09-04-03_end
  ) {
  return !isMobile ? (
    <React.Fragment key={`store.${store.id}`}>
      <Divider option="horizontal" />
      <Box display="flex">
        <Box ml={12}>
          <RhfCheckbox control={control} name={`store.${store.id}`} defaultValue={false} disabled={store.disabled} />
        </Box>
        <Box ml={16} display="flex" gap={16}>
          <Box width={180}>
            <Text variant="caption12">{store.name}</Text>
          </Box>
          <Box width={320}>
            <Text variant="caption12">
              〒{store.zipcode.slice(0, 3)}-{store.zipcode.slice(3)} {store.address}
            </Text>
          </Box>
          <Box display="flex" gap={8}>
            <Box>
              <Text variant="caption12" color="darkGray">
                法人番号
              </Text>
            </Box>
            <Box>
              <Text variant="caption12">{store.corporateNumber}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  ) : (
    // feat_screen_09-04-03_start
    <React.Fragment key={`store.${store.id}`}>
      <Divider option="horizontal" />
      <Box display="flex" flexDirection="row" gap={9} py={16}>
        <Box display="flex" alignItems='flex-start'>
          <RhfCheckbox control={control} name={`store.${store.id}`} defaultValue={false} disabled={store.disabled} /> 
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>

          <Text variant="caption12">{store.name}</Text>

          <Text variant="caption12">
            〒{store.zipcode.slice(0, 3)}-{store.zipcode.slice(3)} {store.address}
          </Text>

          <Box display="flex" gap={8}>
            <Text variant="caption12" color="darkGray">
              法人番号
            </Text>
            <Text variant="caption12">{store.corporateNumber}</Text>
          </Box>

        </Box>

      </Box>
    </React.Fragment>
    // feat_screen_09-04-03_end
  );
}

function applyFilter(
  data: Store[],
  filter: { storeName?: string; corporateNumber?: string; state?: string; city?: string }
): Store[] {
  return data.filter((d) => {
    if (filter.storeName && !d.name.includes(filter.storeName)) {
      return false;
    }
    if (filter.corporateNumber && d.corporateNumber !== filter.corporateNumber) {
      return false;
    }
    if (filter.state && d.state !== filter.state) {
      return false;
    }
    if (filter.city && d.city !== filter.city) {
      return false;
    }

    return true;
  });
}

function FindStoresModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onBack: () => void;
  onClickNext: (checkedStoreIds: string[]) => void;
  onChangeState: (state: Option | null) => void;
  selectedBrand: Option | null;
  selectedState: Option | null;
  shouldClearForm: boolean;
  storesList: Store[];
  statesOptions: Option[];
  citiesOptions: Option[];
}) {
  const {
    isOpen,
    onModalClose,
    onBack,
    onClickNext,
    onChangeState,
    shouldClearForm,
    selectedBrand,
    selectedState,
    storesList,
    statesOptions,
    citiesOptions,
  } = props;
  const { control, reset, handleSubmit, setValue, getValues, watch } = useForm<FormData>();

  const [filterStoreName, setFilterStoreName] = useState<string>('');
  const [filterCorporateNumber, setFilterCorporateNumber] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const currentCity = watch('city');

  const filteredStoresList = useMemo(
    () =>
      applyFilter(storesList, {
        storeName: filterStoreName,
        corporateNumber: filterCorporateNumber,
        state: statesOptions.find((o) => o.value === selectedState?.value)?.label,
        city: currentCity,
      }),
    [storesList, filterStoreName, filterCorporateNumber, statesOptions, selectedState, currentCity]
  );

  function onClose() {
    onModalClose();
    setErrorMessage('');
    reset();
  }

  function onGoBack() {
    setErrorMessage('');
    onBack();
  }

  function onGoNext() {
    handleSubmit((data) => {
      const checkedStores = Object.keys(data.store).filter((key) => data.store[key]);
      // TODO: React Hook Form のバリデーションで対応したい
      if (checkedStores.length === 0) {
        setErrorMessage('店舗を選択してください。');
        return;
      }
      setErrorMessage('');
      onClickNext(checkedStores);
    })();
  }

  function clearFilter() {
    setValue('storeName', '');
    setValue('corporateNumber', '');
    setValue('city', '');
    setFilterStoreName('');
    setFilterCorporateNumber('');
    onChangeState(null); // 都道府県をクリア
  }

  useEffect(() => {
    if (shouldClearForm) {
      reset();
    }
  }, [shouldClearForm, reset]);

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        グループ招待 店舗検索
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box>
          <Text variant="caption11" color="cautionRed">
            検索をやり直すと選択されたものは解除されますのでご注意ください。
          </Text>
        </Box>
        <Box mt={8}>
          <Text variant="caption12" color="blueGray">
            ブランド: {selectedBrand?.label || ''}
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap={16} mt={8}>
          <Box>
            <Box>
              <Text variant="h3" color="blueGray">
                キーワードで検索
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gap={12}>
              <RhfInput name="storeName" control={control} placeholder="店舗名" width={180} defaultValue="" />
              <RhfInput name="corporateNumber" control={control} placeholder="法人番号" width={180} defaultValue="" />
              <MainButton
                width={80}
                icon="search"
                iconColor="white"
                onClick={() => {
                  setFilterStoreName(getValues('storeName'));
                  setFilterCorporateNumber(getValues('corporateNumber'));
                }}
              >
                検索
              </MainButton>
            </Box>
          </Box>
          <Box display="flex" gap={12} alignItems="center">
            <Box>
              <Box>
                <Text variant="h3" color="blueGray">
                  都道府県
                </Text>
              </Box>
              <Box display="flex" alignItems="center" gap={12}>
                <Select
                  name="state"
                  width={200}
                  placeholder="都道府県を選択"
                  options={statesOptions}
                  value={selectedState?.value || ''}
                  onChange={(value, label) => {
                    setValue('city', '');
                    onChangeState({ value, label });
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <Text variant="h3" color="blueGray">
                  市区町村
                </Text>
              </Box>
              <Box display="flex" alignItems="center" gap={12}>
                <RhfSelect
                  name="city"
                  control={control}
                  width={200}
                  placeholder="市区町村を選択"
                  defaultValue=""
                  options={citiesOptions}
                />
                <MainButton width={45} variant="clear" onClick={() => clearFilter()}>
                  クリア
                </MainButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={40}>
          <Text variant="caption11" color="darkGray">
            {filteredStoresList.length}件の検索結果
          </Text>
          {errorMessage && (
            <Box>
              <Text variant="caption11" color="cautionRed">
                {errorMessage}
              </Text>
            </Box>
          )}
          <Box height={320} overflow="auto">
            {filteredStoresList.map((store) => generateListRow(store, control))}
          </Box>
        </Box>
      </Box>
    ): (
      // feat_screen_09-04-03_start
      <Box display="flex" flexDirection="column" gap={24}>

        <Box display="flex" flexDirection="column" gap={12}>
          <Text  variant="body14" color="cautionRed">
            検索をやり直すと選択されたものは解除されますのでご注意ください。
          </Text>
          <Text variant="body14" color="blueGray">
              ブランド: {selectedBrand?.label || ''}
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gap={16}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Text variant="h3" color="blueGray">
              キーワードで検索
            </Text>
            <Box display="flex" alignItems="center" gap={7}>
              <RhfInput 
                name="storeName" 
                control={control} 
                placeholder="店舗名" 
                fullWidth 
                defaultValue="" 
              />
              <RhfInput 
                name="corporateNumber" 
                control={control} 
                placeholder="法人番号" 
                fullWidth
                defaultValue="" 
              />
              <MainButton
                width={125}
                customHeight={40}
                icon="search"
                iconSize={20}
                iconColor="white"
                onClick={() => {
                  setFilterStoreName(getValues('storeName'));
                  setFilterCorporateNumber(getValues('corporateNumber'));
                }}
              >
                検索
              </MainButton>
            </Box>
          </Box>

          <Box display="flex" gap={8} flexDirection="column" alignItems='flex-end' width="100%">
            <Box display="flex" gap={12} width="100%">
              <Box display="flex" flexDirection="column" gap={4} width="100%">
                <Text variant="h3" color="blueGray">
                  都道府県
                </Text>

                <Box display="flex" alignItems="center" gap={12}>
                  <Select
                    name="state"
                    fullWidth
                    height={40}
                    placeholder="都道府県を選択"
                    options={statesOptions}
                    value={selectedState?.value || ''}
                    onChange={(value, label) => {
                      setValue('city', '');
                      onChangeState({ value, label });
                    }}
                  />
                </Box>

              </Box>
              <Box display="flex" flexDirection="column" gap={4} width="100%">
                <Text variant="h3" color="blueGray">
                  市区町村
                </Text>
                <Box display="flex" alignItems="center" gap={12} width="100%">
                  <RhfSelect
                    name="city"
                    control={control}
                    fullWidth
                    height={40}
                    placeholder="市区町村を選択"
                    defaultValue=""
                    options={citiesOptions}
                  />
                </Box>
              </Box>
            </Box>
            <MainButton width={45} variant="clear" onClick={() => clearFilter()}>
              クリア
            </MainButton>
          </Box>
        </Box>

        <Box mt={-10}>
          <Text variant="caption11" color="darkGray">
            {filteredStoresList.length}件の検索結果
          </Text>
          {errorMessage && (
            <Box>
              <Text variant="caption11" color="cautionRed">
                {errorMessage}
              </Text>
            </Box>
          )}

          <Box height={120} overflow="auto">
            {filteredStoresList.map((store) => generateListRow(store, control, isMobile))}
          </Box>
        </Box>

      </Box>
      // feat_screen_09-04-03_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton 
          // feat_screen_09-04-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-03_end
          variant="secondary" 
          onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton 
          // feat_screen_09-04-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-03_end
          variant="secondary" 
          onClick={() => onGoBack()}>
          戻る
        </MainButton>
        <MainButton
         // feat_screen_09-04-03_start
         width={!isMobile ? 104 : 96} 
         thin={!isMobile}
         // feat_screen_09-04-03_end
         onClick={() => onGoNext()}>
          申請
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      // feat_screen_09-04-03_start
      height={!isMobile ? "auto" : 589}
      width={!isMobile ? 1000 : "100%"}
      // feat_screen_09-04-03_end
    />
  );
}

export default FindStoresModal;
