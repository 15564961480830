export enum Status {
  ALL = -1,
  BEFORE_ARBEIT = 0,
  STAND_BY = 1,
  IN_PROGRESS = 2,
  NO_CHECK_IN = 3,
  NO_CHECK_OUT = 4,
  NO_CHECK_IN_OUT = 5,
  WAITING_COMPLETE = 6,
  REQUEST_CORRECTION = 8,
  COMPLETE = 10,
  CANCEL = 11,
  NO_SHOW = 12,
}

export enum RecruitType {
  HIRED = 0,
  BELONGED = 1,
  SUB = 2,
  GROUP = 3,
}

export enum RecruitTypeLabel {
  HIRED = '採用',
  BELONGED = '所属',
  SUB = 'サブ',
  GROUP = 'グループ',
}

export enum KindName {
  GENERAL = '他店/一般',
  BELONGED = '自店',
  SUB = 'サブ',
  GROUP = 'グループ',
}

export enum Favorite {
  YES = 1,
  NO = 0,
}

export enum BelongTo {
  YES = 1,
  NO = 0,
}

export enum Sub {
  YES = 1,
  NO = 0,
}

export enum Identification {
  NOT_SUBMITTED = 0, // 未提出
  IN_PROGRESS = 1, // 確認中
  APPROVED = 2, // 承認済
}

export enum MenuIndex {
  OFFER = 1, // 応募
}

export enum EmploymentDecision {
  YES = 1,
  NO = 2,
}

export enum RecruitmentStatus {
  BEFORE_RECRUITMENT = 1,
  WANTED = 2,
  OFFER_FINALIZED = 3,
  RECRUITMENT_CLOSED = 4,
  SUSPENSION_OF_RECRUITMENT = 6,
  FORCED_STOP = 7,
}

export enum ApplicationStatus {
  APPLYING = 0,
  OFFERED = 1,
  REJECTED = 2,
}
