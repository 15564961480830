import LocalModal from './Modal';

/**
 * Figma ID
 * 06-03-05
 * 否認確認完了
 */

interface Props {
  step: number;
  onModalClose: () => void;
  onClickSubmit: () => void;
}

export function RejectionCompletedModal({ step, onClickSubmit, onModalClose }: Props) {
  return LocalModal({
    title: '否認 完了',
    contentText: 'ユーザーの所属店舗登録申請を否認しました。',
    step,
    onClickSubmit,
    onModalClose,
  });
}
