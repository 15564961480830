/**
 * Figma ID: 08-05-02, 08-05-03, 08-05-04
 * 名称: 労働条件通知書
 */

import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

type EditFooterModel = {
  onSubmit: () => void;
  close: () => void;
};
export function EditFooter(props: EditFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { onSubmit, close } = props;
  return (
    <Box display="flex" gap={8} justifyContent="flex-end">
      {/* feat screen 08-05-02 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} onClick={onSubmit}>
        保存
      </MainButton>
      {/* feat screen 08-05-02 end */}
    </Box>
  );
}

type ConfirmFooterModel = {
  onSubmit: () => void;
  back: () => void;
  close: () => void;
};
export function ConfirmFooter(props: ConfirmFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { onSubmit, back, close } = props;
  return (
    <Box display="flex" gap={8} justifyContent="flex-end">
      {/* feat screen 08-05-02 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={back}>
        戻る
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} onClick={onSubmit}>
        登録
      </MainButton>
      {/* feat screen 08-05-02 end */}
    </Box>
  );
}

type CompleteFooterModel = {
  close: () => void;
};
export function CompleteFooter(props: CompleteFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { close } = props;
  return (
    <Box display="flex" gap={5} justifyContent="flex-end">
      {/* feat screen 08-05-02 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        閉じる
      </MainButton>
      {/* feat screen 08-05-02 end */}
    </Box>
  );
}
