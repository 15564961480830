/**
 * Figma ID: 08-02-01, 08-02-02, 08-02-03, 08-02-04
 * 名称: ランク別育成料
 */

import React from 'react';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { Control, FieldErrors } from 'react-hook-form';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';

type EducationFeeByRankModel = {
  educationFeeByRankId: number | undefined;
  educationFeeByRankName: string | undefined;
  educationFeeMax?: string | number;
  educationFeeMin?: string | number;
  control: Control<{ input: string }>;
  errors: FieldErrors<{ input: string }>;
};
export function SetValueContent(props: EducationFeeByRankModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { educationFeeByRankId, educationFeeByRankName, educationFeeMax, educationFeeMin, control, errors } = props;

  // feat screen 08-02-01 start
  return !isMobile ? (
    <Box>
      <InputLabel>{educationFeeByRankName}（%）</InputLabel>
      <RhfInput
        width={160}
        control={control}
        name="input"
        placeholder="10"
        type="number"
        rules={{
          required: '必須項目です。',
          validate: (value) =>
            (value <= (educationFeeMax ?? 0) && value >= (educationFeeMin ?? 0)) ||
            '設定可能範囲内で設定してください。',
        }}
      />
      {errors.input && (
        <Text variant="caption11" color="cautionRed">
          {errors.input.message}
        </Text>
      )}
      <Box pt={24}>
        <Text variant="body14">
          設定可能範囲:{educationFeeMin}% 〜 {educationFeeMax}%
        </Text>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box gap={4}>
      <InputLabel fullWidth>{educationFeeByRankName}（%）</InputLabel>
      <RhfInput
        width={160}
        control={control}
        name="input"
        placeholder="10"
        type="number"
        rules={{
          required: '必須項目です。',
          validate: (value) =>
            (value <= (educationFeeMax ?? 0) && value >= (educationFeeMin ?? 0)) ||
            '設定可能範囲内で設定してください。',
        }}
      />
      {errors.input && (
        <Text variant="caption11" color="cautionRed">
          {errors.input.message}
        </Text>
      )}
      <Box pt={16}>
        <Text variant="body14">
          設定可能範囲:{educationFeeMin}% 〜 {educationFeeMax}%
        </Text>
      </Box>
    </Box>
  // feat screen 08-02-01 end
  );
}

type ConfirmValueModel = {
  updatePer: string | number;
};
export function ConfirmValue(props: ConfirmValueModel) {
/* feat_common_responsive_useMobile_logic_start */
const isMobile = useIsMobile();
/* feat_common_responsive_useMobile_logic_end */
  const { updatePer } = props;
  // feat screen 08-02-03 start
  return !isMobile ? (
    <Box px={24} py={20}>
      <Box>
        <Text variant="body14">以下の費用で設定してよろしいですか？</Text>
      </Box>
      <Box pt={16}>
        <Text variant="body14">・{updatePer}%</Text>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box>
      <Box>
        <Text display="block" variant="body14">以下の費用で設定してよろしいですか？</Text>
      </Box>
      <Box pt={16}>
        <Text variant="body14">・{updatePer}%</Text>
      </Box>
    </Box>
  );
  // feat screen 08-02-03 end
}

export function CompleteValue() {
  return (
    <Box>
      <Text variant="body14">情報の設定が完了しました。</Text>
    </Box>
  );
}
