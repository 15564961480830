import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { TRecvNotify } from 'graphql/graphql-mw';
import { format } from 'date-fns';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 03-01-05
 * 運営通知モーダル
 */

function NoticeModal(props: { item: TRecvNotify | null, isOpen: boolean; onModalClose: () => void;}) {
  const { isOpen, onModalClose, item} = props;

  const title = item?.title;
  const body = item?.body;
  const recvDate = item?.recv_date;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        {title}
      </Text>
    ),
    content: !isMobile ? (
      <Box mt={9.5} ml={8} mr={8} mb={8}>
        <Box mb={17.5}>
          <Text variant="caption12" color="blueGray">
            配信日時
          </Text>
          &nbsp;
          <Text variant="body14" color="liteGray">
            {recvDate && format(new Date(recvDate || 0), 'yyyy年MM月dd日 HH:mm')}
          </Text>
        </Box>
        <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
          <Text variant="body14" color="black"> 
            {body}
          </Text>
        </div>
      </Box>
    ): (
      /* feat_screen_03-01-15_start */
      <Box>
        <Box>
          <Text variant="caption12" color="blueGray">
            配信日時
          </Text>
          &nbsp;
          <Text variant="caption12" color="liteGray">
            {recvDate && format(new Date(recvDate || 0), 'yyyy年MM月dd日 HH:mm')}
          </Text>
        </Box>
        <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
          <Text variant="body14" color="black"> 
            {body}
          </Text>
        </div>
      </Box>
      /* feat_screen_03-01-15_end */
    ),
    footer: !isMobile ?(
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ): (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
  };

  return !isMobile ? (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="80%"
      width={800}
    />
  ): (
    /* feat_screen_03-01-15_start */
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={200}
      width={800}
    />
     /* feat_screen_03-01-15_end */
  );
}

export default NoticeModal;
