/**
 * Figma ID: 04-03-02-02, 04-03-02-03, 04-03-02-04, 04-03-02-05
 * 名称: 募集新規作成
 */

/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useScrollToFormError } from 'components/utils/useScrollToFormError';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Modal, ImageModal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { RadioGroup } from 'components/radioGroup';
import { Box } from 'components/box';
import { ComboBox } from 'components/comboBox';
import { handleMutationResult } from 'components/graphqlResult/handleMutationResult';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { Info } from 'components/info';
import { FormData } from 'pages/s04-01-01/common/formState';
import { format, addDays, parse } from 'date-fns';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { RhfTextarea } from 'components/rhfTextarea';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { TextSet } from 'pages/s04-01-01/[id]/components/textSet';
import { TextLink } from 'components/textLink';
import { ErrorMessage } from 'components/errorMessage';
import { DatePicker } from 'components/datePicker';
import { Hours, Every15Minutes } from 'components/select/selectOptions';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import { useNoticeOfEmploymentPdfPreview } from 'pdfComponent';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { getPdfPreviewProps } from '../../../common/utils';
import { usePostTArbeitOfferConnectApi, useGetTemplateDataConnenctApi } from '../../../common/hooks';

enum ModalStep {
  NULL = 0,
  FORM = 1,
  PREVIEW = 2,
  CONFIRM = 3,
  DONE = 4,
}

export enum BreakTime {
  NONE = 0,
  ONE = 1,
}

interface DefaultValues {
  wage: number;
  date: string;
  beginTime: string;
  endTime: string;
}
// 長いエラーメッセージ
const StyledLongErrorWrapper = styled.div`
  padding-left: 8px;
  height: 18px;
  display: flex;
`;

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  defaultValues: DefaultValues;
}

export function ModalComponent({ isOpen, onClose, defaultValues }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId, storeInfo } = auth;
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormData>({ reValidateMode: 'onSubmit' });

  useScrollToFormError({ isSubmitting, errors });

  /**
   * テンプレートデータを取得
   */
  const { data: templateData, loading: templateLoading } = useGetTemplateDataConnenctApi(Number(tpmemId));
  const dispOfferTemplateData = templateData?.getSTpmemNoticetplMakeInfoByTpmemId;
  const dispMakeTemplateData = dispOfferTemplateData?.make;

  /**
   * [API] フォームデータをPOST
   */
  const { setFixedPostFormData, addTArbeitOfferAd2S2Mutation } = usePostTArbeitOfferConnectApi(
    Number(tpmemId),
    Number(id)
  );

  const [fixedFormData, setFixedFormData] = useState<FormData>({});
  const [beginEnd, setBeginEnd] = useState<string>('');
  const [modalStep, setModalStep] = useState<number>(ModalStep.FORM);
  const [shiftDate, setShiftDate] = useState<Date | null>(null);
  const [breakTime, setBreakTime] = useState<string>('1');
  const [radioTransFee, setRadioTransFee] = useState<string>('1');
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const [radioAttachment, setAttachment] = useState<string>('1');

  /**
   * エラーモーダルダイアログ
   */
  const { openErrorModal } = useErrorModal();

  /**
   * 最低賃金
   */
  const minWage = dispMakeTemplateData?.min_wage || 0;

  /**
   * yyyy-MM-dd -> yyyy年MM月dd日
   */
  const formatDate = (dateString?: string): string => {
    if (!dateString) return '';
    const date = parse(dateString, 'yyyy-MM-dd', new Date());
    return format(date, 'yyyy年MM月dd日');
  };

  /**
   * 日付を1日足す
   */
  const addDay = (dateString?: string): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const newDate = addDays(date, 1);
    return format(newDate, 'yyyy年MM月dd日');
  };

  /**
   * 添付ファイルのパス、ファイル名をセット
   */
  const attachedFilePath = dispOfferTemplateData?.attached_file;
  const attachedFileName = dispOfferTemplateData?.attached_name || '';

  const calcBeginEnd = (data: FormData): string | undefined => {
    if (!data.daily_start_hour || !data.daily_end_hour) return undefined;
    const beginHour = Number(data.daily_start_hour);
    const endHour = Number(data.daily_end_hour);
    const difHour = endHour - beginHour;
    let addedDay = '';
    if (difHour < 0) {
      /**
       * 日を超えた場合
       */
      addedDay = addDay(data.shift_date);
    } else {
      /**
       * 日を超えない場合
       */
      addedDay = formatDate(data.shift_date);
    }
    const dispBeginEnd = `${formatDate(data.shift_date)} ${data.daily_start_hour.padStart(2, '0')}:${String(
      data.daily_start_minute
    )} ~ ${addedDay} ${data.daily_end_hour.padStart(2, '0')}:${String(data.daily_end_minute)}`;
    return dispBeginEnd;
  };

  const onClickConfirm = handleSubmit((data) => {
    /**
     * バイト期間を計算して文字列としてStateにセットする
     * 時間の差分を見てマイナスであれば1日足す
     */

    setBeginEnd(calcBeginEnd(data) || '');
    /**
     * Mutation用にデータをセットする
     */
    setFixedPostFormData(data);
    /**
     * Preview用Stateにセットする
     */
    setFixedFormData(data);
    setModalStep(ModalStep.PREVIEW);
  });

  /**
   * [API] 募集新規作成をPOST
   */
  const onClickSubmit = () => {
    addTArbeitOfferAd2S2Mutation()
      .then((result) => {
        const errorCode = result.data?.addTArbeitOfferAD2S2.ErrorCode;
        if (errorCode != null) {
          handleMutationResult(
            errorCode,
            () => {
              setModalStep(ModalStep.DONE);
            },
            ({ error }) => {
              onClose();
              openErrorModal({ title: error.title, message: error.message, onClose: error.requiredCallback });
            }
          );
        } else {
          onClose();
          openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
        }
      })
      .catch(() => {
        onClose();
        openErrorModal({ message: '募集新規作成に失敗しました' });
      });
  };

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf: previewNoticePdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = () => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFパラメータ取得
        const pdfProps = getPdfPreviewProps(fixedFormData);

        // PDFプレビュー
        const result = await previewNoticePdf(Number(auth?.tpmemId), pdfProps);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  /**
   * 処理をクリアする
   * RHFのリセットとモーダルを閉じる
   */
  const clearEvent = (isDone: boolean) => {
    setModalStep(ModalStep.FORM);
    reset();
    onClose();
    /**
     * 画面をリロード
     */
    if (isDone) navigate(0);
  };

  function displayModal() {
    switch (modalStep) {
      case ModalStep.FORM:
        return {
          // feat screen 04-03-02-02 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-03-02-02 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集新規作成
              </Text>
            </Box>
          ),
          // feat screen 04-03-02-02 start
          content: !isMobile ?  (
            <Box pr={16}>
              <Box mb={18}>
                <Text variant="body14">
                  逆オファーを受入れて新規募集情報を作成します。項目内容を入力して確認を押してください。
                </Text>
              </Box>
              <Box mb={24}>
                <TextSet
                  label="掲載タイトル"
                  content={
                    <RhfInput
                      name="title"
                      control={control}
                      defaultValue=""
                      type="text"
                      fullWidth
                      rules={{ required: '掲載タイトルは必須です' }}
                    />
                  }
                />
                {errors?.title && <ErrorMessage>{errors?.title.message}</ErrorMessage>}
              </Box>
              <Box alignItems="start" display="flex" gap={8} mb={24}>
                <div>
                  <TextSet
                    label="募集シフト"
                    content={
                      <DatePicker
                        minDate={new Date()}
                        onChange={(value) => {
                          if (!setValue) return;
                          setShiftDate?.(value);
                          setValue('shift_date', format(value || 0, 'yyyy-MM-dd'));
                        }}
                        selected={shiftDate || (defaultValues.date ? new Date(defaultValues.date) : null)}
                        placeholderText="募集シフト"
                        width={168}
                      />
                    }
                  />
                  {errors?.shift_date && <ErrorMessage>{errors?.shift_date.message}</ErrorMessage>}
                </div>
                <div>
                  <TextSet
                    label="出勤時間"
                    content={
                      <Box alignItems="start" display="flex" gap={8}>
                        <Box>
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_start_hour"
                            options={Hours}
                            defaultValue={defaultValues.beginTime.split(':')[0] || ''}
                            placeholder="時間"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_start_hour && <ErrorMessage>{errors?.daily_start_hour.message}</ErrorMessage>}
                        </Box>
                        <Box pt={4}>
                          <Text color="blueGray" display="inline" variant="body14">
                            :
                          </Text>
                        </Box>
                        <Box>
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_start_minute"
                            options={Every15Minutes}
                            defaultValue={defaultValues.beginTime.split(':')[1] || ''}
                            placeholder="分"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_start_minute && (
                            <ErrorMessage>{errors?.daily_start_minute.message}</ErrorMessage>
                          )}
                        </Box>
                        <Box pt={5}>
                          <Text color="blueGray" display="inline" variant="body14">
                            ~
                          </Text>
                        </Box>
                      </Box>
                    }
                  />
                </div>
                <div>
                  <TextSet
                    label="退勤時間"
                    content={
                      <Box alignItems="start" display="flex" gap={8}>
                        <Box>
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_end_hour"
                            options={Hours}
                            defaultValue={defaultValues.endTime.split(':')[0] || ''}
                            placeholder="時間"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_end_hour && <ErrorMessage>{errors?.daily_end_hour.message}</ErrorMessage>}
                        </Box>
                        <Box pt={4}>
                          <Text color="blueGray" display="inline" variant="body14">
                            :
                          </Text>
                        </Box>
                        <Box>
                          <RhfSelect
                            width={80}
                            control={control}
                            name="daily_end_minute"
                            options={Every15Minutes}
                            defaultValue={defaultValues.endTime.split(':')[1] || ''}
                            placeholder="分"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_end_minute && <ErrorMessage>{errors?.daily_end_minute.message}</ErrorMessage>}
                        </Box>
                      </Box>
                    }
                  />
                </div>
                <RhfInput
                  name="shift_date"
                  control={control}
                  defaultValue={defaultValues.date}
                  placeholder=""
                  type="hidden"
                  width={200}
                  rules={{ required: '必須です' }}
                />
              </Box>
              <Box alignItems="start" display="flex" gap={20} mb={24}>
                <TextSet
                  label="休憩"
                  content={
                    <RadioGroup
                      name="is_break_time"
                      onChange={(e) => {
                        if (!setValue) return;
                        setBreakTime(e.target.value);
                        setValue('check_break_time', e.target.value);
                      }}
                      options={[
                        {
                          label: '有',
                          value: '1',
                        },
                        {
                          label: '無',
                          value: '0',
                        },
                      ]}
                      value={breakTime}
                    />
                  }
                />
                <RhfInput
                  name="check_break_time"
                  control={control}
                  defaultValue="1"
                  placeholder=""
                  type="hidden"
                  width={100}
                />
                <Box>
                  <TextSet
                    label="休憩時間（分）"
                    visible={breakTime === '1' ? 'visible' : 'hidden'}
                    content={
                      <RhfInput
                        name="break_time"
                        control={control}
                        defaultValue=""
                        placeholder="分を入力"
                        type="text"
                        width={120}
                        rules={{ required: breakTime === '1' ? '必須です' : false }}
                      />
                    }
                  />
                  {errors?.break_time && <ErrorMessage>{errors?.break_time.message}</ErrorMessage>}
                </Box>
              </Box>
              <Box mb={24}>
                <TextSet
                  label="時給（¥）"
                  content={
                    <RhfInput
                      name="hour_wage_display"
                      control={control}
                      defaultValue={defaultValues.wage}
                      placeholder="金額を入力"
                      type="text"
                      width={120}
                      rules={{
                        required: '必須です',
                        min: {
                          value: minWage,
                          message: '都道府県指定の最低時給を下回っています',
                        },
                      }}
                    />
                  }
                />
                {errors?.hour_wage_display && <ErrorMessage>{errors.hour_wage_display.message}</ErrorMessage>}
              </Box>
              <Box alignItems="start" display="flex" gap={8} mb={24}>
                <TextSet
                  label="当日の店舗責任者"
                  content={
                    <Controller
                      name="chief"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value) return '店舗責任者を選択してください';
                          return true;
                        },
                      }}
                      defaultValue={
                        getValues && getValues('chief') ? getValues('chief') : dispMakeTemplateData?.mgr_name || ''
                      }
                      render={({ field }) => (
                        <ComboBox
                          width={328}
                          creatable
                          options={[
                            {
                              label: dispMakeTemplateData?.mgr_name,
                              value: '1',
                            },
                          ]}
                          onChange={(newValue) => {
                            field.onChange((newValue as { label: string; value: string })?.label);
                          }}
                          defaultInputValue={
                            getValues && getValues('chief') ? getValues('chief') : dispMakeTemplateData?.mgr_name || ''
                          }
                        />
                      )}
                    />
                  }
                />
              </Box>
              <Box alignItems="center" display="flex" gap={20} mb={24}>
                <TextSet
                  label="交通費支給"
                  content={
                    <RadioGroup
                      name="trans_fee_select"
                      onChange={(e) => {
                        if (!setValue) return;
                        setRadioTransFee(e.target.value);
                        setValue('check_trans_fee', e.target.value);
                      }}
                      options={[
                        {
                          label: '有',
                          value: '1',
                        },
                        {
                          label: '無',
                          value: '0',
                        },
                      ]}
                      value={radioTransFee}
                    />
                  }
                />

                <RhfInput
                  name="check_trans_fee"
                  control={control}
                  defaultValue={radioTransFee}
                  placeholder=""
                  type="hidden"
                  width={100}
                />
                <Box>
                  <TextSet
                    label="交通費（¥）"
                    visible={radioTransFee === '1' ? 'visible' : 'hidden'}
                    content={
                      <RhfInput
                        name="trans_fee"
                        control={control}
                        defaultValue=""
                        placeholder="金額を入力"
                        type="text"
                        width={120}
                        rules={{ required: radioTransFee === '1' ? '必須です' : false }}
                      />
                    }
                  />
                  {errors?.trans_fee && <ErrorMessage>{errors.trans_fee.message}</ErrorMessage>}
                </Box>
              </Box>
              <Box mb={24}>
                <TextSet
                  label="業務詳細"
                  content={
                    dispOfferTemplateData?.description ? (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.description || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="働くための条件"
                  content={
                    dispOfferTemplateData?.conditions ? (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.conditions || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="注意事項"
                  content={
                    dispOfferTemplateData?.caution ? (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.caution || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="持ち物"
                  content={
                    dispOfferTemplateData?.belongings ? (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.belongings || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="アクセス"
                  content={
                    dispOfferTemplateData?.access ? (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.access || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="メイン画像"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={8}>
                      {!dispOfferTemplateData?.image1 &&
                        !dispOfferTemplateData?.image2 &&
                        !dispOfferTemplateData?.image3 &&
                        !dispOfferTemplateData?.image4 &&
                        !dispOfferTemplateData?.image5 &&
                        'なし'}
                      {dispOfferTemplateData?.image1 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispOfferTemplateData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image1_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image2 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispOfferTemplateData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image2_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image3 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispOfferTemplateData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image3_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image4 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispOfferTemplateData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image4_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image5 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispOfferTemplateData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image5_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image1 && (
                        <RhfInput
                          name="image1_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image1 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image2 && (
                        <RhfInput
                          name="image2_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image2 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image3 && (
                        <RhfInput
                          name="image3_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image3 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image4 && (
                        <RhfInput
                          name="image4_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image4 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image5 && (
                        <RhfInput
                          name="image5_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image5 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      <ImageModal
                        src={modalImageSrc}
                        isOpen={isImageModalOpen}
                        onClose={() => setIsImageModalOpen(false)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="添付ファイル"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
                      {attachedFilePath ? (
                        <>
                          <div>
                            <TextLink href={attachedFilePath || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          </div>
                          <div>
                            <RadioGroup
                              name="radioAttachment"
                              onChange={(e) => {
                                if (!setValue) return;
                                setAttachment(e.target.value);
                                setValue('attachment_flag', e.target.value);
                              }}
                              options={[
                                {
                                  label: '添付する',
                                  value: '1',
                                },
                                {
                                  label: '添付しない',
                                  value: '0',
                                },
                              ]}
                              value={radioAttachment}
                            />
                          </div>
                        </>
                      ) : (
                        'なし'
                      )}
                    </Box>
                  }
                />
                {attachedFilePath && (
                  <>
                    <RhfInput
                      name="attachment_flag"
                      control={control}
                      defaultValue="1"
                      placeholder=""
                      type="hidden"
                      width={30}
                    />
                    <RhfInput
                      name="attached_file"
                      control={control}
                      defaultValue={attachedFilePath || ''}
                      placeholder=""
                      type="hidden"
                      width={200}
                    />
                  </>
                )}
              </Box>
            </Box>
          ) : (
            // Responsive Component
            <Box>
              <Box mb={16}>
                <Text display="block" variant="body14">
                  逆オファーを受入れて新規募集情報を作成します。項目内容を入力して確認を押してください。
                </Text>
              </Box>
              <Box mb={14}>
                <TextSet
                  label="掲載タイトル"
                  content={
                    <RhfInput
                      name="title"
                      control={control}
                      defaultValue=""
                      type="text"
                      fullWidth
                      rules={{ required: '掲載タイトルは必須です' }}
                    />
                  }
                />
                {errors?.title && <ErrorMessage>{errors?.title.message}</ErrorMessage>}
              </Box>
              <Box alignItems="start" flexDirection="column" display="flex" mb={16}>
                <Box width="100%" mb={6}>
                  <TextSet
                    label="募集シフト"
                    content={
                      <DatePicker
                        minDate={new Date()}
                        onChange={(value) => {
                          if (!setValue) return;
                          setShiftDate?.(value);
                          setValue('shift_date', format(value || 0, 'yyyy-MM-dd'));
                        }}
                        selected={shiftDate || (defaultValues.date ? new Date(defaultValues.date) : null)}
                        placeholderText="募集シフト"
                        width={168}
                      />
                    }
                  />
                  {errors?.shift_date && <ErrorMessage>{errors?.shift_date.message}</ErrorMessage>}
                </Box>
                <Box width="100%">
                  <TextSet
                    label="出勤時間"
                    content={
                      <Box display="flex" flexDirection="column">
                        <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
                          <div style={{width: "100%"}}>
                            <RhfSelect
                              fullWidth
                              height={40}
                              control={control}
                              name="daily_start_hour"
                              options={Hours}
                              defaultValue={defaultValues.beginTime.split(':')[0] || ''}
                              placeholder="時間"
                              rules={{ required: '必須です' }}
                            />

                            {errors?.daily_start_hour && <ErrorMessage>{errors?.daily_start_hour.message}</ErrorMessage>}
                          </div>

                          <Box display="flex" alignItems='center' height={40} px={4}>
                            <Text color="blueGray" display="inline" variant="body14">
                              :
                            </Text>
                          </Box>
                          <div style={{width: "100%"}}>
                            <RhfSelect
                              fullWidth
                              height={40}
                              control={control}
                              name="daily_start_minute"
                              options={Every15Minutes}
                              defaultValue={defaultValues.beginTime.split(':')[1] || ''}
                              placeholder="分"
                              rules={{ required: '必須です' }}
                            />

                            {errors?.daily_start_minute && (
                              <ErrorMessage>{errors?.daily_start_minute.message}</ErrorMessage>
                            )}
                          </div>
                        </Box>
                        <Box pt={5} pb={8}>
                          <Text color="blueGray" display="inline" variant="body14">
                            ~
                          </Text>
                        </Box>
                      </Box>
                    }
                  />
                </Box>
                <Box width="100%">
                  <TextSet
                    label="退勤時間"
                    content={
                      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
                        <div style={{width: "100%"}}>
                          <RhfSelect
                            fullWidth
                            height={40}
                            control={control}
                            name="daily_end_hour"
                            options={Hours}
                            defaultValue={defaultValues.endTime.split(':')[0] || ''}
                            placeholder="時間"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_end_hour && <ErrorMessage>{errors?.daily_end_hour.message}</ErrorMessage>}
                        </div>
                        <Box height={40} px={4} display="flex" alignItems="center">
                          <Text color="blueGray" display="inline" variant="body14">
                            :
                          </Text>
                        </Box>
                        <div style={{width: "100%"}}>
                          <RhfSelect
                            fullWidth
                            height={40}
                            control={control}
                            name="daily_end_minute"
                            options={Every15Minutes}
                            defaultValue={defaultValues.endTime.split(':')[1] || ''}
                            placeholder="分"
                            rules={{ required: '必須です' }}
                          />

                          {errors?.daily_end_minute && <ErrorMessage>{errors?.daily_end_minute.message}</ErrorMessage>}
                        </div>
                      </Box>
                    }
                  />
                </Box>
                <RhfInput
                  name="shift_date"
                  control={control}
                  defaultValue={defaultValues.date}
                  placeholder=""
                  type="hidden"
                  width={200}
                  rules={{ required: '必須です' }}
                />
              </Box>
              <Box alignItems="start" display="flex" gap={12} mb={16}>
                <TextSet
                  label="休憩"
                  content={
                    <RadioGroup
                      name="is_break_time"
                      onChange={(e) => {
                        if (!setValue) return;
                        setBreakTime(e.target.value);
                        setValue('check_break_time', e.target.value);
                      }}
                      options={[
                        {
                          label: '有',
                          value: '1',
                        },
                        {
                          label: '無',
                          value: '0',
                        },
                      ]}
                      value={breakTime}
                    />
                  }
                />
                <RhfInput
                  name="check_break_time"
                  control={control}
                  defaultValue="1"
                  placeholder=""
                  type="hidden"
                  width={100}
                />
                <Box>
                  <TextSet
                    label="休憩時間（分）"
                    visible={breakTime === '1' ? 'visible' : 'hidden'}
                    content={
                      <RhfInput
                        name="break_time"
                        control={control}
                        defaultValue=""
                        placeholder="分を入力"
                        type="text"
                        width={120}
                        rules={{ required: breakTime === '1' ? '必須です' : false }}
                      />
                    }
                  />
                  {errors?.break_time && <ErrorMessage>{errors?.break_time.message}</ErrorMessage>}
                </Box>
              </Box>
              <Box mb={16}>
                <TextSet
                  label="時給（¥）"
                  content={
                    <RhfInput
                      name="hour_wage_display"
                      control={control}
                      defaultValue={defaultValues.wage}
                      placeholder="金額を入力"
                      type="text"
                      fullWidth
                      rules={{
                        required: '必須です',
                        min: {
                          value: minWage,
                          message: '都道府県指定の最低時給を下回っています',
                        },
                      }}
                    />
                  }
                />
                {errors?.hour_wage_display && <ErrorMessage>{errors.hour_wage_display.message}</ErrorMessage>}
              </Box>
              <Box width="100%" mb={16}>
                <TextSet
                  label="当日の店舗責任者"
                  content={
                    <Controller
                      name="chief"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value) return '店舗責任者を選択してください';
                          return true;
                        },
                      }}
                      defaultValue={
                        getValues && getValues('chief') ? getValues('chief') : dispMakeTemplateData?.mgr_name || ''
                      }
                      render={({ field }) => (
                        <ComboBox
                          width="100%"
                          creatable
                          options={[
                            {
                              label: dispMakeTemplateData?.mgr_name,
                              value: '1',
                            },
                          ]}
                          onChange={(newValue) => {
                            field.onChange((newValue as { label: string; value: string })?.label);
                          }}
                          defaultInputValue={
                            getValues && getValues('chief') ? getValues('chief') : dispMakeTemplateData?.mgr_name || ''
                          }
                        />
                      )}
                    />
                  }
                />
              </Box>
              <Box alignItems="center" display="flex" gap={12} mb={16}>
                <TextSet
                  label="交通費支給"
                  content={
                    <RadioGroup
                      name="trans_fee_select"
                      onChange={(e) => {
                        if (!setValue) return;
                        setRadioTransFee(e.target.value);
                        setValue('check_trans_fee', e.target.value);
                      }}
                      options={[
                        {
                          label: '有',
                          value: '1',
                        },
                        {
                          label: '無',
                          value: '0',
                        },
                      ]}
                      value={radioTransFee}
                    />
                  }
                />

                <RhfInput
                  name="check_trans_fee"
                  control={control}
                  defaultValue={radioTransFee}
                  placeholder=""
                  type="hidden"
                  width={100}
                />
                <Box>
                  <TextSet
                    label="交通費（¥）"
                    visible={radioTransFee === '1' ? 'visible' : 'hidden'}
                    content={
                      <RhfInput
                        name="trans_fee"
                        control={control}
                        defaultValue=""
                        placeholder="金額を入力"
                        type="text"
                        width={120}
                        rules={{ required: radioTransFee === '1' ? '必須です' : false }}
                      />
                    }
                  />
                  {errors?.trans_fee && <ErrorMessage>{errors.trans_fee.message}</ErrorMessage>}
                </Box>
              </Box>
              <Box mb={16}>
                <TextSet
                  label="業務詳細"
                  content={
                    dispOfferTemplateData?.description ? (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.description || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={16}>
                <TextSet
                  label="働くための条件"
                  content={
                    dispOfferTemplateData?.conditions ? (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.conditions || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={16}>
                <TextSet
                  label="注意事項"
                  content={
                    dispOfferTemplateData?.caution ? (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.caution || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={16}>
                <TextSet
                  label="持ち物"
                  content={
                    dispOfferTemplateData?.belongings ? (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.belongings || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={16}>
                <TextSet
                  label="アクセス"
                  content={
                    dispOfferTemplateData?.access ? (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispOfferTemplateData?.access || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={16}>
                <TextSet
                  label="メイン画像"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
                      {!dispOfferTemplateData?.image1 &&
                        !dispOfferTemplateData?.image2 &&
                        !dispOfferTemplateData?.image3 &&
                        !dispOfferTemplateData?.image4 &&
                        !dispOfferTemplateData?.image5 &&
                        'なし'}
                      {dispOfferTemplateData?.image1 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '95px' }}
                              src={dispOfferTemplateData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image1_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image2 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '95px' }}
                              src={dispOfferTemplateData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image2_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image3 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '95px' }}
                              src={dispOfferTemplateData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image3_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image4 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '95px' }}
                              src={dispOfferTemplateData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image4_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image5 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispOfferTemplateData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '95px' }}
                              src={dispOfferTemplateData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image5_flag" control={control} defaultValue="" />
                          </div>
                        </div>
                      )}

                      {dispOfferTemplateData?.image1 && (
                        <RhfInput
                          name="image1_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image1 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image2 && (
                        <RhfInput
                          name="image2_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image2 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image3 && (
                        <RhfInput
                          name="image3_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image3 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image4 && (
                        <RhfInput
                          name="image4_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image4 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispOfferTemplateData?.image5 && (
                        <RhfInput
                          name="image5_path"
                          control={control}
                          defaultValue={dispOfferTemplateData?.image5 || false}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      <ImageModal
                        src={modalImageSrc}
                        isOpen={isImageModalOpen}
                        onClose={() => setIsImageModalOpen(false)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box>
                <TextSet
                  label="添付ファイル"
                  content={
                    <Box>
                      {attachedFilePath ? (
                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={4}>
                          <div>
                            <TextLink href={attachedFilePath || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          </div>
                          <div>
                            <RadioGroup
                              name="radioAttachment"
                              onChange={(e) => {
                                if (!setValue) return;
                                setAttachment(e.target.value);
                                setValue('attachment_flag', e.target.value);
                              }}
                              options={[
                                {
                                  label: '添付する',
                                  value: '1',
                                },
                                {
                                  label: '添付しない',
                                  value: '0',
                                },
                              ]}
                              value={radioAttachment}
                            />
                          </div>
                        </Box>
                      ) : (
                        'なし'
                      )}
                    </Box>
                  }
                />
                {attachedFilePath && (
                  <>
                    <RhfInput
                      name="attachment_flag"
                      control={control}
                      defaultValue="1"
                      placeholder=""
                      type="hidden"
                      width={30}
                    />
                    <RhfInput
                      name="attached_file"
                      control={control}
                      defaultValue={attachedFilePath || ''}
                      placeholder=""
                      type="hidden"
                      width={200}
                    />
                  </>
                )}
              </Box>
            </Box>
            // feat screen 04-03-02-02 end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* // feat screen 04-03-02-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent(false)}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickConfirm()}>
                確認
              </MainButton>
              {/* // feat screen 04-03-02-02 end */}
            </Box>
          ),
        };
      case ModalStep.PREVIEW:
        return {
          // feat screen 04-03-02-03 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-03-02-03 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集新規作成 プレビュー
              </Text>
              {
                // feat screen 04-03-02-03 start
                !isMobile &&
                  <MainButton
                    onClick={() => {
                      onClickPreview();
                    }}
                    variant="secondary"
                    width={200}
                  >
                    労働条件通知書プレビュー
                  </MainButton>
                // feat screen 04-03-02-03 end
              }
            </Box>
          ),
          // feat screen 04-03-02-03 start
          content: !isMobile ? (
            <Box>
              <Box pr={8} pl={8}>
                <Box mb={18}>
                  <Text variant="body14">募集情報を確認して、募集を押してください。</Text>
                </Box>
                <Box mb={16}>
                  <Text color="darkBlue" variant="h2">
                    募集情報
                  </Text>
                </Box>
                <Divider length={0} option="horizontal" />
                <Info
                  items={[
                    {
                      content: '逆オファー',
                      label: '採用方式',
                    },
                    {
                      content: fixedFormData?.title || '',
                      label: '掲載タイトル',
                    },
                    {
                      content: storeInfo?.name || '',
                      label: '募集店',
                    },
                    {
                      content: beginEnd,
                      label: 'バイト期間',
                    },
                    {
                      content:
                        fixedFormData?.check_break_time === '1' ? `${String(fixedFormData?.break_time) || ''}分` : '無',
                      label: '休憩時間',
                    },
                    {
                      content: `¥${String(Number(fixedFormData?.hour_wage_display)?.toLocaleString() || '')}`,
                      label: '時給',
                    },
                    {
                      content: fixedFormData?.chief || '',
                      label: '当日の店舗責任者',
                    },
                    {
                      content:
                        fixedFormData?.check_trans_fee === '1'
                          ? `¥${String(Number(fixedFormData?.trans_fee)?.toLocaleString() || '')}`
                          : '無',
                      label: '交通費',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.description)}
                        </Text>
                      ),
                      label: '業務詳細',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.conditions)}
                        </Text>
                      ),
                      label: '働くための条件',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.caution)}
                        </Text>
                      ),
                      label: '注意事項',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.belongings)}
                        </Text>
                      ),
                      label: '持ち物',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.access)}
                        </Text>
                      ),
                      label: 'アクセス',
                    },
                    {
                      content: (
                        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                          {fixedFormData?.image1_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image1_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image1_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image2_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image2_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image2_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image3_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image3_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image3_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image4_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image4_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image4_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image5_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image5_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image5_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                        </div>
                      ),
                      label: 'メイン画像',
                    },
                    {
                      content: (
                        <Box>
                          {fixedFormData?.attachment_flag === '1' ? (
                            <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          ) : (
                            <Text variant="body14">添付無し</Text>
                          )}
                        </Box>
                      ),
                      label: '添付ファイル',
                    },
                  ]}
                />
              </Box>
            </Box>
          ) : (
            // Responsive Component
            <Box>
              <Box mb={-16}>
                <Box display="flex" alignItems="center" justifyContent="center" pb={24}>
                  <MainButton
                    onClick={() => {
                      onClickPreview();
                    }}
                    variant="secondary"
                    width={212}
                  >
                    労働条件通知書プレビュー
                  </MainButton>
                </Box>
                <Box display="block" mb={16}>
                  <Text variant="body14">募集情報を確認して、募集を押してください。</Text>
                </Box>
                <Box mb={12}>
                  <Text display="block" color="darkBlue" variant="body14" bold>
                    募集情報
                  </Text>
                </Box>
                <Divider length={0} option="horizontal" />
                <Info
                  items={[
                    {
                      content: '逆オファー',
                      label: '採用方式',
                    },
                    {
                      content: fixedFormData?.title || '',
                      label: '掲載タイトル',
                    },
                    {
                      content: storeInfo?.name || '',
                      label: '募集店',
                    },
                    {
                      content: beginEnd,
                      label: 'バイト期間',
                    },
                    {
                      content:
                        fixedFormData?.check_break_time === '1' ? `${String(fixedFormData?.break_time) || ''}分` : '無',
                      label: '休憩時間',
                    },
                    {
                      content: `¥${String(Number(fixedFormData?.hour_wage_display)?.toLocaleString() || '')}`,
                      label: '時給',
                    },
                    {
                      content: fixedFormData?.chief || '',
                      label: '当日の店舗責任者',
                    },
                    {
                      content:
                        fixedFormData?.check_trans_fee === '1'
                          ? `¥${String(Number(fixedFormData?.trans_fee)?.toLocaleString() || '')}`
                          : '無',
                      label: '交通費',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" align="right" variant="body14">
                          {charcterReplaceBr(fixedFormData?.description)}
                        </Text>
                      ),
                      label: '業務詳細',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" align="right" variant="body14">
                          {charcterReplaceBr(fixedFormData?.conditions)}
                        </Text>
                      ),
                      label: '働くための条件',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" align="right" variant="body14">
                          {charcterReplaceBr(fixedFormData?.caution)}
                        </Text>
                      ),
                      label: '注意事項',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" align="right" variant="body14">
                          {charcterReplaceBr(fixedFormData?.belongings)}
                        </Text>
                      ),
                      label: '持ち物',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" align="right" variant="body14">
                          {charcterReplaceBr(fixedFormData?.access)}
                        </Text>
                      ),
                      label: 'アクセス',
                    },
                    {
                      content: (
                        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '4px', justifyContent: 'flex-end' }}>
                          {fixedFormData?.image1_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image1_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '95px' }}
                                src={fixedFormData?.image1_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image2_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image2_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '95px' }}
                                src={fixedFormData?.image2_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image3_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image3_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '95px' }}
                                src={fixedFormData?.image3_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image4_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image4_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '95px' }}
                                src={fixedFormData?.image4_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image5_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image5_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '95px' }}
                                src={fixedFormData?.image5_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                        </div>
                      ),
                      label: 'メイン画像',
                    },
                    {
                      content: (
                        <Box>
                          {fixedFormData?.attachment_flag === '1' ? (
                            <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          ) : (
                            <Text variant="body14">添付無し</Text>
                          )}
                        </Box>
                      ),
                      label: '添付ファイル',
                    },
                  ]}
                />
              </Box>
            </Box>
            // feat screen 04-03-02-03 end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-02-03 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.FORM)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => setModalStep(ModalStep.CONFIRM)}>
                募集
              </MainButton>
              {/* feat screen 04-03-02-03 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM:
        return {
          // feat screen 04-03-02-04 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-03-02-04 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集新規作成 確認
              </Text>
            </Box>
          ),
          content: <Text variant="body14">この逆オファー新規募集をバイトラベラーに送信しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-02-04 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent(false)}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.PREVIEW)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickSubmit()}>
                送信
              </MainButton>
              {/* feat screen 04-03-02-04 end */}
            </Box>
          ),
        };
      case ModalStep.DONE:
        return {
          // feat screen 04-03-02-05 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 231 : 'unset',
          // feat screen 04-03-02-05 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集新規作成 送信完了
              </Text>
            </Box>
          ),
          content: (
            <Text variant="body14">
              送信が完了しました。
              <br />
              バイトラベラーから申し込みがあるまでお待ち下さい。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-02-05 start */}
              <MainButton width={!isMobile ? 104 : 96} onClick={() => clearEvent(true)}>
                閉じる
              </MainButton>
              {/* feat screen 04-03-02-05 start */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: (
            <MainButton width={104} onClick={() => clearEvent(false)}>
              閉じる
            </MainButton>
          ),
        };
    }
  }
  const modalContents = displayModal();

  if (templateLoading) {
    return <Loading />;
  }

  return (
    <Modal
      width={modalContents?.width}
      height={modalContents?.height}
      header={modalContents?.header}
      content={modalContents?.content}
      footer={modalContents?.footer}
      isOpen={isOpen}
      onClose={() => clearEvent(false)}
    />
  );
}
