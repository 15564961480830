/**
 * Figma ID: 04-01-02
 * 名称: バイト募集（下書き）
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ReactNode, useState, useMemo, useCallback, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useScrollToFormError } from 'components/utils/useScrollToFormError';
import { isSameDay } from 'date-fns';
import { RoRes, VtOfferDraftList } from 'graphql/graphql-mw';
import { styled } from '@linaria/react';
import { useAuth } from 'hooks/authProvider';
import { Modal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { Input } from 'components/input';
import { List } from 'components/list';
import { PaginationBox } from 'components/pagination/paginationBox';
import { Icon } from 'components/icon';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Chip } from 'components/chip';
import { Text } from 'components/text';
import { Loading } from 'components/loading/loading';
import { DatePicker } from 'components/datePicker';
import { orderBy } from 'lodash';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { handleMutationResult } from 'components/graphqlResult/handleMutationResult';
import { useNoticeOfEmploymentPdfPreview } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows, ModalZindex, RepeatsType } from 'components/const';
import { usePagenation, useSort } from 'components/utils/hooks';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { ModalNewPreview } from './modal/preview';
import { ModalCreateNew } from './modal/form';
import { getPdfPreviewProps } from '../common/utils';
import { FormData } from '../common/formState';
import { ScheduleTypeLabel, ScheduleType } from '../common/scheduleTypeContext';
import {
  useDraftListConnectApi,
  useDraftDetailConnectApi,
  useDeleteDailyDraftConnectApi,
  useDeleteWeeklyDraftConnectApi,
  useSaveDailyDraftConnectApi,
  useSaveWeeklyToDailyDraftConnectApi,
  useSaveWeeklyDraftConnectApi,
  useSaveDailyToWeeklyDraftConnectApi,
  useDeliveryDailyDraftConnectApi,
  useDeliveryWeeklyToDailyDraftConnectApi,
  useDeliveryWeeklyDraftConnectApi,
  useDeliveryDailyToWeeklyDraftConnectApi,
} from '../common/hooks';
import {
  State as WeeklyOfferNumberState,
  Action as WeekluOfferNumberAction,
  WeeklyOfferNumberContext,
} from '../common/weeklyOfferNumberContext';

const StyledDate = styled.div`
  font-size: 11px;
  color: #000;
`;

enum ModalStep {
  NULL = 0,
  DRAFT_NORMAL = 1,
  DRAFT_PREVIEW = 2,
  DRAFT_DONE = 3,
  CONFIRM_DELIVERY = 4,
  CONFIRM_DELIVERY_DONE = 5,
  CONFIRM_DELETE = 6,
  CONFIRM_DELETE_DONE = 7,
  DELIVERY = 8,
  DELIVERY_DONE = 9,
  REGULAR_WEEKLY = 10,
  REGULAR_WEEKLY_DONE = 11,
  SAVE_DRAFT = 12,
}

enum TitleMaxLength {
  NORMAL = 17,
  AUTO_DECIDE = 12,
}

/**
 * 配信予定件数Reducer
 */
const weeklyOfferNumberInitialState: WeeklyOfferNumberState = { offerNumber: 0 };
const weeklyOfferNumberReducer = (
  state: WeeklyOfferNumberState,
  action: WeekluOfferNumberAction
): WeeklyOfferNumberState => {
  switch (action.type) {
    case 'SET_OFFER_NUMBER':
      return { offerNumber: action.content };
    default:
      throw new Error();
  }
};

export function Draft() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  const auth = useAuth();
  const { tpmemId } = auth;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.NULL);
  const [fixedFormData, setFixedFormData] = useState<FormData>({});
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderNumbers, setOrderNumbers] = useState<(RoRes | null)[]>([]);
  const [isDraftClicked, setIsDraftClicked] = useState<boolean>(false);
  // エラー処理の判断用
  const [recruitId, setRecruitId] = useState<number>(0);
  // 日程種別の変更判断用（データ取得時）
  const [draftDetailOrgType, setDraftDetailOrgType] = React.useState<number>(0);
  // 下書きバリテーション取得用 OfferNumber
  const [draftOfferId, setDraftOfferId] = React.useState<number>(0);

  const navigate = useNavigate();

  /**
   * Filterに関するState
   */
  const [offerTitle, setOfferTitle] = useState<string>('');
  const [workingDay, setWorkingDay] = React.useState<Date | null>(null);
  const [searchConditions, setSearchConditions] = useState<{ offerTitle: string } | null>(null);

  /**
   * useForm（React Hool Form）
   */
  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormData>({ reValidateMode: 'onSubmit' });

  useScrollToFormError({ isSubmitting, errors });

  const [state, dispatch] = useReducer(weeklyOfferNumberReducer, weeklyOfferNumberInitialState);

  /**
   * エラーモーダルダイアログ
   */
  const { openErrorModal } = useErrorModal();

  /**
   * [API] Mutation（下書き保存 / 単日）
   */
  const {
    updateTArbeitOfferDraftByIdMutation: dailyDraftMutation,
    setFormData: setDailyDraftFormData,
    setId: setDailyDraftId,
    error: dailyDraftSavingError,
  } = useSaveDailyDraftConnectApi();
  /**
   * [API] Mutation（下書き保存 / 週定期から単日）
   */
  const {
    changeTRegularlyOfferToTArbeitOfferByIdMutation: weekToDailyDraftMutation,
    setFormData: setWeekToDailyDraftFormData,
    setId: setWeekToDailyDraftId,
    error: weekToDailyDraftSavingError,
  } = useSaveWeeklyToDailyDraftConnectApi();

  /**
   * [API] Mutation（下書き保存 / 週定期）
   */
  const {
    updateTRegularlyOfferByIdMutation: weeklyDraftMutation,
    setFormData: setWeeklyDraftFormData,
    setId: setWeeklyDraftId,
    error: weeklyDraftSavingError,
  } = useSaveWeeklyDraftConnectApi();

  /**
   * [API] Mutation（下書き保存 / 単日から週定期）
   */
  const {
    changeTArbeitOfferToTRegularlyOfferByIdMutation: dailyToWeeklyDraftMutation,
    setFormData: setDailyToWeeklyDraftFormData,
    setId: setDailyToWeekDraftId,
    error: dailyToWeekDraftSavingError,
  } = useSaveDailyToWeeklyDraftConnectApi();

  /**
   * [API] Mutation（配信 / 単日）
   */
  const {
    updateTArbeitOfferResByIdMutation: deliveryDailyMutation,
    setId: setDailyDeliveryId,
    setFormData: setDailyDeliveryFormData,
  } = useDeliveryDailyDraftConnectApi();

  /**
   * [API] Mutation（配信 / 週定期から単日）
   */
  const {
    changeTRegularlyOfferToTArbeitOfferResByIdMutation: deliveryWeeklyToDailyMutation,
    setId: setWeeklyToDailyDeliveryId,
    setFormData: setWeeklyToDailyDeliveryFormData,
  } = useDeliveryWeeklyToDailyDraftConnectApi();

  /**
   * [API] Mutation（配信 / 週定期）
   */
  const {
    updateTRegularlyOfferResByIdMutation: deliveryWeeklyMutation,
    setId: setWeeklyDeliveryId,
    setFormData: setWeeklyDeliveryFormData,
    error: weeklyDeliveryCreationError,
  } = useDeliveryWeeklyDraftConnectApi();

  /**
   * [API] Mutation（配信 / 単日から週定期）
   */
  const {
    changeTArbeitOfferToTRegularlyOfferResByIdMutation: deliveryDailyToWeeklyMutation,
    setId: setDailyToWeeklyDeliveryId,
    setFormData: setDailyToWeeklyDeliveryFormData,
    error: dailyToweeklyDeliveryCreationError,
  } = useDeliveryDailyToWeeklyDraftConnectApi();

  /**
   * [API] Mutation（下書き削除: 単日/週定期）
   */
  const {
    deleteTArbeitOfferByIdMutation,
    setId: setDailyDraftDeleteId,
    error: dailyDraftDeletingError,
  } = useDeleteDailyDraftConnectApi();

  const {
    deleteTRegularlyOfferByIdMutation,
    setId: setWeeklyDraftDeleteId,
    error: weeklyDraftDeletingError,
  } = useDeleteWeeklyDraftConnectApi();

  /**
   * ［API］Query（下書き一覧取得）
   */
  const [dataSize, setDataSize] = React.useState<number>(0);
  const {
    data: draftListData,
    loading: draftListLoading,
    refetch: refetchDraftList,
    error: errorDraftList,
  } = useDraftListConnectApi(Number(tpmemId));

  /**
   * エラー処理
   */
  useEffect(() => {
    if (errorDraftList) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [errorDraftList, openErrorModal]);

  /**
   * ［API］Query（下書き詳細取得）
   */
  const {
    setId: setDraftDetailId,
    setType: setDraftDetailType,
    data: draftDetailData,
    loading: draftDetailLoading,
    error: draftDetailError,
  } = useDraftDetailConnectApi();
  /**
   * エラー処理
   */
  useEffect(() => {
    if (draftDetailError && recruitId > 0) {
      // エラーの場合編集画面を閉じる
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [draftDetailError, openErrorModal, recruitId]);

  const apiError =
    dailyDraftSavingError ||
    weekToDailyDraftSavingError ||
    weeklyDraftSavingError ||
    dailyToWeekDraftSavingError ||
    dailyDraftDeletingError ||
    weeklyDraftDeletingError ||
    weeklyDeliveryCreationError ||
    dailyToweeklyDeliveryCreationError;

  useEffect(() => {
    if (apiError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  /**
   * [onSubmit] プレビュー
   */
  const onClickConfirm = handleSubmit((data) => {
    switch (data.check_schedule_type) {
      case ScheduleType.DAILY:
        setDailyDeliveryFormData(data);
        // 週定期から単日
        setWeeklyToDailyDeliveryFormData(data);
        break;
      case ScheduleType.WEEKLY:
        setWeeklyDeliveryFormData(data);
        // 単日から週定期
        setDailyToWeeklyDeliveryFormData(data);
        break;
      default:
        break;
    }
    setFixedFormData(data);
    setModalStep(ModalStep.DRAFT_PREVIEW);
  });

  /**
   * [onSubmit] 下書き保存
   */
  const onClickDraftConfirm = handleSubmit((data) => {
    // 日程種別によりAPI変更
    if (draftDetailOrgType === data.check_schedule_type) {
      // 日程種別変更なしの場合
      switch (data.check_schedule_type) {
        case ScheduleType.DAILY:
          setDailyDraftFormData(data);
          setTimeout(() => {
            dailyDraftMutation()
              .then((result) => {
                if (!result.errors) {
                  // ResultRowsをみないと登録できたかわからないため
                  if (result.data?.updateTArbeitOfferDraftById.resultRows === ResultRows.NOT_UPDATED) {
                    setModalStep(ModalStep.NULL);
                    openErrorModal({ message: '下書き保存に失敗しました。' });
                  } else {
                    refetchDraftList();
                    setModalStep(ModalStep.DRAFT_DONE);
                  }
                } else {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                }
              })
              .catch(() => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }, 1000);
          break;
        case ScheduleType.WEEKLY:
          setWeeklyDraftFormData(data);
          setTimeout(() => {
            weeklyDraftMutation()
              .then((result) => {
                if (!result.errors) {
                  // ResultRowsをみないと登録できたかわからないため
                  if (result.data?.updateTRegularlyOfferById.resultRows === ResultRows.NOT_UPDATED) {
                    setModalStep(ModalStep.NULL);
                    openErrorModal({ message: '下書き保存に失敗しました。' });
                  } else {
                    refetchDraftList();
                    setModalStep(ModalStep.DRAFT_DONE);
                  }
                } else {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                }
              })
              .catch(() => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }, 1000);
          break;
        default:
          break;
      }
    } else {
      // 日程種別変更ありの場合
      switch (data.check_schedule_type) {
        case ScheduleType.DAILY:
          setWeekToDailyDraftFormData(data);
          setTimeout(() => {
            weekToDailyDraftMutation()
              .then((result) => {
                if (!result.errors) {
                  // ResultRowsをみないと登録できたかわからないため
                  if (result.data?.changeTRegularlyOfferToTArbeitOfferById.resultRows === ResultRows.NOT_UPDATED) {
                    setModalStep(ModalStep.NULL);
                    openErrorModal({ message: '下書き保存に失敗しました。' });
                  } else {
                    refetchDraftList();
                    setModalStep(ModalStep.DRAFT_DONE);
                  }
                } else {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                }
              })
              .catch(() => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }, 1000);
          break;
        case ScheduleType.WEEKLY:
          setDailyToWeeklyDraftFormData(data);
          setTimeout(() => {
            dailyToWeeklyDraftMutation()
              .then((result) => {
                if (!result.errors) {
                  // ResultRowsをみないと登録できたかわからないため
                  if (result.data?.changeTArbeitOfferToTRegularlyOfferById.resultRows === ResultRows.NOT_UPDATED) {
                    setModalStep(ModalStep.NULL);
                    openErrorModal({ message: '下書き保存に失敗しました。' });
                  } else {
                    refetchDraftList();
                    setModalStep(ModalStep.DRAFT_DONE);
                  }
                } else {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                }
              })
              .catch(() => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }, 1000);
          break;
        default:
          break;
      }
    }
  });

  /**
   * 下書き配信（単日）
   * updateTArbeitOfferResById
   */
  const onClickDailySave = () => {
    // 日程種別の変更チェック
    if (draftDetailOrgType === RepeatsType.SINGLE_DAY) {
      // 変更なし
      deliveryDailyMutation()
        .then((result) => {
          const errorCode = result.data?.updateTArbeitOfferResById.ErrorCode;

          if (errorCode === null || errorCode === undefined) {
            throw Error('ErrorCode is null or undefined');
          }

          handleMutationResult(
            errorCode,
            () => {
              refetchDraftList();
              setOrderNumber(result.data?.updateTArbeitOfferResById.offer_number?.toString() || '');
              setModalStep(ModalStep.DELIVERY_DONE);
            },
            ({ error }) => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                title: error.title,
                message: error.message,
                onClose: error.requiredCallback,
              });
            }
          );
        })
        .catch(() => {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        });
    } else {
      // 変更あり
      deliveryWeeklyToDailyMutation()
        .then((result) => {
          const errorCode = result.data?.changeTRegularlyOfferToTArbeitOfferResById.ErrorCode;

          if (errorCode === null || errorCode === undefined) {
            throw Error('ErrorCode is null or undefined');
          }

          handleMutationResult(
            errorCode,
            () => {
              refetchDraftList();
              setOrderNumber(result.data?.changeTRegularlyOfferToTArbeitOfferResById.offer_number?.toString() || '');
              setModalStep(ModalStep.DELIVERY_DONE);
            },
            ({ error }) => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                title: error.title,
                message: error.message,
                onClose: error.requiredCallback,
              });
            }
          );
        })
        .catch(() => {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        });
    }
  };
  /**
   * 下書き確定（週定期）
   * updateTRegularlyOfferResById
   */
  const onClickWeeklySave = () => {
    // 日程種別の変更チェック
    if (draftDetailOrgType === RepeatsType.WEEKLY) {
      deliveryWeeklyMutation()
        .then((result) => {
          const errorCode = result.data?.updateTRegularlyOfferResById.ErrorCode;

          if (errorCode === null || errorCode === undefined) {
            throw Error('ErrorCode is null or undefined');
          }

          handleMutationResult(
            errorCode,
            () => {
              refetchDraftList();
              setOrderNumbers(result.data?.updateTRegularlyOfferResById.offer || []);
              setModalStep(ModalStep.REGULAR_WEEKLY_DONE);
            },
            ({ error }) => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                title: error.title,
                message: error.message,
                onClose: error.requiredCallback,
              });
            }
          );
        })
        .catch(() => {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        });
    } else {
      deliveryDailyToWeeklyMutation()
        .then((result) => {
          const errorCode = result.data?.changeTArbeitOfferToTRegularlyOfferResById.ErrorCode;

          if (errorCode === null || errorCode === undefined) {
            throw Error('ErrorCode is null or undefined');
          }

          handleMutationResult(
            errorCode,
            () => {
              refetchDraftList();
              setOrderNumbers(result.data?.changeTArbeitOfferToTRegularlyOfferResById.offer || []);
              setModalStep(ModalStep.REGULAR_WEEKLY_DONE);
            },
            ({ error }) => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                title: error.title,
                message: error.message,
                onClose: error.requiredCallback,
              });
            }
          );
        })
        .catch(() => {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        });
    }
  };

  /**
   * [onClick] 下書き削除
   */
  const onClickDelete = useCallback(() => {
    const targetId = draftDetailData?.getVTOfferDraftInfoByIdType?.id;
    if (!targetId) return;
    switch (draftDetailData?.getVTOfferDraftInfoByIdType?.type) {
      case ScheduleType.DAILY:
        /**
         * 単日
         */
        deleteTArbeitOfferByIdMutation()
          .then((result) => {
            if (!result.errors) {
              refetchDraftList();
              setModalStep(ModalStep.CONFIRM_DELETE_DONE);
            } else {
              openErrorModal({ message: '下書きの削除に失敗しました', zIndex: ModalZindex.ERRMODALZINDEX });
            }
          })
          .catch(() => {
            openErrorModal({
              message: '下書きの削除に失敗しました',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          });
        break;
      case ScheduleType.WEEKLY:
        /**
         * 週定期
         */
        deleteTRegularlyOfferByIdMutation()
          .then((result) => {
            if (!result.errors) {
              refetchDraftList();
              setModalStep(ModalStep.CONFIRM_DELETE_DONE);
            } else {
              openErrorModal({ message: '下書きの削除に失敗しました', zIndex: ModalZindex.ERRMODALZINDEX });
            }
          })
          .catch(() => {
            openErrorModal({
              message: '下書きの削除に失敗しました',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          });

        break;
      default:
        break;
    }
  }, [
    draftDetailData,
    deleteTArbeitOfferByIdMutation,
    deleteTRegularlyOfferByIdMutation,
    openErrorModal,
    refetchDraftList,
  ]);

  /**
   * [onClick] Eye Icon
   */
  const onClickDraftItem = useCallback(
    (id?: number | null, type?: number | null) => {
      if (!id || !type) return;
      /**
       * 表示用のIDをセット
       */
      setDraftDetailId(id);
      setDraftDetailType(type);
      /**
       * 日程種別が変更された時のAPI判断用（取得時）
       */
      setDraftDetailOrgType(type);
      // エラー判定用
      setRecruitId(id);
      /**
       * 下書き保存用のIDをセット
       */
      setDailyDraftId(id);
      setWeeklyDraftId(id);
      // 週定期から単日
      setWeekToDailyDraftId(id);
      // 単日から週定期
      setDailyToWeekDraftId(id);
      /**
       * 下書き配信用のIDをセット
       */
      setDailyDeliveryId(id);
      setWeeklyDeliveryId(id);
      // 週定期から単日
      setWeeklyToDailyDeliveryId(id);
      // 単日から週定期
      setDailyToWeeklyDeliveryId(id);
      /**
       * 削除用のIDをセット
       */
      setDailyDraftDeleteId(id);
      setWeeklyDraftDeleteId(id);
      /**
       * 下書きバリテーション用のID(OfferNumber)をセット
       */
      setDraftOfferId(id);

      /**
       * モーダルを変更
       */
      setModalStep(ModalStep.DRAFT_NORMAL);
    },
    [
      setModalStep,
      setDraftDetailId,
      setDraftDetailType,
      setDailyDraftDeleteId,
      setWeeklyDraftDeleteId,
      setDailyDraftId,
      setWeekToDailyDraftId,
      setWeeklyDraftId,
      setDailyToWeekDraftId,
      setDailyDeliveryId,
      setWeeklyToDailyDeliveryId,
      setWeeklyDeliveryId,
      setDailyToWeeklyDeliveryId,
    ]
  );

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = (shiftDate?: string) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFパラメータ取得
        const pdfProps = getPdfPreviewProps(fixedFormData, shiftDate);

        // 労働条件通知書PDFプレビュー
        const result = await previewPdf(Number(auth?.tpmemId), pdfProps);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  /**
   * Generator
   */
  const generateTitle = (title?: string | null, auto_decide?: number | null) => {
    const maxLength = auto_decide ? TitleMaxLength.AUTO_DECIDE : TitleMaxLength.NORMAL;
    let slicedTitle = title;
    if (slicedTitle && slicedTitle?.length >= maxLength) {
      slicedTitle = `${String(title)?.slice(0, maxLength)}...`;
    }
    return (
      <>
        {auto_decide ? <Chip color="liteGray">自動採用</Chip> : null}
        <Text variant="caption12">{slicedTitle}</Text>
      </>
    );
  };

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { columnName: '', key: 'eye', width: 24 },
    // feat screen 04-01-02-01 start
    { columnName: '掲載タイトル', key: 'title', width: !isMobile ? 233 : 135 },
    { columnName: '日程種別', key: 'type', width: 76 },
    { columnName: '募集開始', key: 'post_begin', width: !isMobile ? 130 : 140 },
    { columnName: '募集締切', key: 'post_end', width: !isMobile ? 130 : 140 },
    { columnName: 'バイト開始期日', key: 'begin_date', width: !isMobile ? 160 : 140 },
    { columnName: 'バイト終了期日', key: 'end_date', width: !isMobile ? 160 : 140 },
    // feat screen 04-01-02-01 end
  ];

  /**
   * ソートとページネーション
   */
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = useMemo(() => {
    if (draftListLoading) {
      return [];
    }

    let items = (draftListData?.getVTOfferDraftListByTpmemId?.list as VtOfferDraftList[]) ?? [];

    /**
     * キーワードでフィルター
     */
    if (searchConditions) {
      items = items.filter(({ title }) => {
        if (!searchConditions.offerTitle) {
          return true;
        }
        const reg = new RegExp(searchConditions.offerTitle);
        return reg.test(title || '');
      });
    }

    /**
     * バイト日でフィルター
     */
    if (workingDay) {
      items = items.filter(({ begin_date }) => {
        if (!begin_date) {
          return false;
        }
        const regex = /(\d{4})年(\d{2})月(\d{2})日/;
        const matches = begin_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return isSameDay(new Date(year, month - 1, day), workingDay);
        }
        return false;
      });
    }

    setDataSize(items.length);

    if (sort) {
      if (sort.key === 'title') {
        items = orderBy(items, 'title', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items
      .map((item) => ({
        uniqueKey: `${String(item?.id)}_${Math.random()}`,
        // feat screen 04-01-02-01 start
        backgroundColor: 'white',
        eye: <Icon name="eye" size={isMobile ? 20 : 24} onClick={() => onClickDraftItem(item?.id, item?.type)} />,
        // feat screen 04-01-02-01 end
        tpmem_id: item?.tpmem_id,
        auto_decide: item?.auto_decide,
        title: generateTitle(item?.title, item?.auto_decide),
        type: item?.type === 1 ? ScheduleTypeLabel.DAILY : ScheduleTypeLabel.WEEKLY,
        post_begin: item?.post_begin,
        post_end: item?.post_end,
        begin_date: item?.begin_date,
        end_date: item?.end_date,
        id: item?.id,
      }))
      .slice(limit * (page - 1), limit * page);
    // feat screen 04-01-02-01 add dept start
  }, [sort, page, limit, draftListData, onClickDraftItem, draftListLoading, searchConditions, workingDay, isMobile]);
  // feat screen 04-01-02-01 add dept end

  const clearEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();
  };

  const doneEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();
    /**
     * リロード
     * refetchが子コンポーネントあるのでnavigateでリロード
     */
    navigate(0);
  };

  const weeklyOrderNumberList = orderNumbers?.map((item, index) => (
    <React.Fragment key={`${String(item?.id)}${Math.random()}`}>
      <Box py={8}>
        <Text color="black" variant="body14">
          掲載No. {item?.offer_number || ''}
        </Text>
        <StyledDate>
          {item?.work_day || ''}（{item?.post_days || ''}）
        </StyledDate>
      </Box>
      <Divider option="horizontal" />
    </React.Fragment>
  ));

  const displayModal = () => {
    switch (modalStep) {
      case ModalStep.DRAFT_NORMAL:
        return {
          // feat screen 04-01-02-02 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-01-02-02 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集情報下書き編集
              </Text>
              {/* feat screen 04-01-02-02 start */}
              <MainButton onClick={() => setModalStep(ModalStep.CONFIRM_DELETE)} variant="secondary" width={60}>
                {/* feat screen 04-01-02-02 end */}
                削除
              </MainButton>
            </Box>
          ),
          content: draftDetailData && (
            <ModalCreateNew
              draftData={draftDetailData?.getVTOfferDraftInfoByIdType || null}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              mode="draft"
              isDraftClicked={isDraftClicked}
              offerId={draftOfferId}
            />
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 change button width start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => {
                  setIsDraftClicked(true);
                  onClickDraftConfirm();
                }}
              >
                下書き保存
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  setIsDraftClicked(false);
                  onClickConfirm();
                }}
              >
                確認
              </MainButton>
              {/* feat screen 04-01-02-02 change button width end */}
            </Box>
          ),
        };
      case ModalStep.DRAFT_PREVIEW:
        return {
          // feat screen 04-01-02-02 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-01-02-02 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集情報下書き編集 - プレビュー
              </Text>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY && !isMobile && (
                <MainButton
                  onClick={() => {
                    onClickPreview();
                  }}
                  variant="secondary"
                  width={200}
                >
                  労働条件通知書プレビュー
                </MainButton>
              )}
            </Box>
          ),
          content: (
            // feat screen 04-01-02-03 start add onClickPreview start
            <ModalNewPreview
              fixedFormData={fixedFormData}
              previewPdf={(shiftDate) => onClickPreview(shiftDate)}
              onClickPreview={onClickPreview}
            />
            // feat screen 04-01-02-03 start add onClickPreview end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 change button width start */}
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.DRAFT_NORMAL)}
              >
                戻る
              </MainButton>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY ? (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.DELIVERY);
                  }}
                >
                  作成
                </MainButton>
              ) : (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.REGULAR_WEEKLY);
                  }}
                >
                  配信
                </MainButton>
              )}
              {/* feat screen 04-01-02-02 change button width end */}
            </Box>
          ),
        };
      case ModalStep.DRAFT_DONE:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: !isMobile ? 189 : 'unset',
          // feat screen 04-01-02-02 end
          header: (
            <Text variant="h2" color="darkBlue">
              下書き保存
            </Text>
          ),
          content: <Text variant="body14">募集の下書きを保存しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-02-02 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM_DELETE:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-01-02-02 end
          header: (
            <Text variant="h2" color="darkBlue">
              削除
            </Text>
          ),
          content: (
            <Text variant="body14">
              本当に下書きを削除しますか？
              <br />
              削除した下書きは元に戻せません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 start */}
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.DRAFT_NORMAL)}
              >
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickDelete()}>
                削除
              </MainButton>
              {/* feat screen 04-01-02-02 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM_DELETE_DONE:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: !isMobile ? 189 : 'unset',
          // feat screen 04-01-02-02 end
          header: (
            <Text variant="h2" color="darkBlue">
              削除 完了
            </Text>
          ),
          content: <Text variant="body14">下書きの削除が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-02-02 end */}
            </Box>
          ),
        };
      case ModalStep.DELIVERY:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-01-02-02 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">作成した募集を配信しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 change button width start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.DRAFT_PREVIEW)}
              >
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickDailySave()}>
                配信
              </MainButton>
              {/* feat screen 04-01-02-02 change button width end */}
            </Box>
          ),
        };
      case ModalStep.DELIVERY_DONE:
        return {
          width: 384,
          // feat screen 04-01-02-02 start
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-01-02-02 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信 完了
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">募集の配信が完了しました。</Text>
              <br />
              <Text variant="body14">掲載No. {orderNumber}</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-02-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-02-02 end */}
            </Box>
          ),
        };
      case ModalStep.REGULAR_WEEKLY:
        return {
          width: 384,
          // feat screen 04-01-03-10 start
          height: !isMobile ? 231 : 'unset',
          // feat screen  04-01-03-10 end
          header: (
            <Text variant="h2" color="darkBlue">
              週定期募集作成
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">週定期募集の作成を確定しますか？</Text>
              <br />
              <Text variant="body14">合計{state.offerNumber}件の募集の配信が予約されます。</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-10 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.DRAFT_PREVIEW)}
              >
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickWeeklySave()}>
                確定
              </MainButton>
              {/* feat screen 04-01-03-10 end */}
            </Box>
          ),
        };
      case ModalStep.REGULAR_WEEKLY_DONE:
        return {
          width: 424,
          // feat screen 04-01-03-10 start
          height: !isMobile ? 396 : 'unset',
          // feat screen  04-01-03-10 end
          header: (
            <Text variant="h2" color="darkBlue">
              週定期募集作成 完了
            </Text>
          ),
          content: (
            <>
              <Box>
                <Text variant="body14">週定期募集の作成が完了しました。</Text>
                <br />
                <Text variant="body14">以下の項目が掲載予約されました。</Text>
              </Box>
              <Box mt={4}>{weeklyOrderNumberList}</Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-10 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-03-10 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  };

  const modalContent = displayModal();
  const memorizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  /**
   * Render
   */
  // feat screen 04-01-02-01 start
  return !isMobile ? (
    <WeeklyOfferNumberContext.Provider value={memorizedValue}>
      <Box display="flex" flexWrap="wrap" alignItems="center" pa={16} gap={16}>
        <div>
          <Input
            name="publicationTitle"
            onChange={(event) => setOfferTitle(event?.target.value || '')}
            placeholder="掲載タイトル"
            value={offerTitle}
            width={160}
          />
        </div>
        <div>
          <MainButton
            variant="primary"
            width={80}
            icon="search"
            onClick={() => {
              // ページ初期化
              setPage(1);
              setSearchConditions({
                offerTitle,
              });
            }}
          >
            検索
          </MainButton>
        </div>
        <div>
          <DatePicker
            selected={workingDay}
            onChange={(value) => {
              // ページ初期化
              setPage(1);
              setWorkingDay(value);
            }}
            placeholderText="バイト日"
            width={160}
          />
        </div>
        <div>
          <MainButton
            variant="clear"
            width={44}
            onClick={() => {
              // ページ初期化
              setPage(1);
              setOfferTitle('');
              setWorkingDay(null);
              setSearchConditions(null);
            }}
          >
            クリア
          </MainButton>
        </div>
      </Box>
      <Divider option="horizontal" />
      {draftListLoading || draftDetailLoading ? (
        <Loading />
      ) : (
        <Box flex="auto" py={0} px={24}>
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <ScrollWrapper bottom={380}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                width={1584}
                rowWidth={1584}
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      )}

      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== 0}
        onClose={() => clearEvent()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
    </WeeklyOfferNumberContext.Provider>
  ) : (
    // Responsive Component
    <WeeklyOfferNumberContext.Provider value={memorizedValue}>
      <Box
        display={isShowFilter ? 'flex' : 'none'}
        flexWrap="wrap"
        alignItems="center"
        px={12}
        py={15}
        gap={12}
        pb={32}
      >
        <Box display="flex" width="100%" gap={12}>
          <div style={{ width: '100%' }}>
            <Input
              name="publicationTitle"
              onChange={(event) => setOfferTitle(event?.target.value || '')}
              placeholder="掲載タイトル"
              value={offerTitle}
              fullWidth
            />
          </div>
          <div>
            <MainButton
              variant="primary"
              width={80}
              customHeight={40}
              icon="search"
              onClick={() => {
                // ページ初期化
                setPage(1);
                setSearchConditions({
                  offerTitle,
                });
              }}
            >
              検索
            </MainButton>
          </div>
        </Box>
        <Box display="flex" width="100%" gap={12}>
          <Box width="100%">
            <DatePicker
              selected={workingDay}
              onChange={(value) => {
                // ページ初期化
                setPage(1);
                setWorkingDay(value);
              }}
              placeholderText="バイト日"
            />
          </Box>
          <div>
            <MainButton
              variant="clear"
              width={80}
              customHeight={40}
              onClick={() => {
                // ページ初期化
                setPage(1);
                setOfferTitle('');
                setWorkingDay(null);
                setSearchConditions(null);
              }}
            >
              クリア
            </MainButton>
          </div>
        </Box>
      </Box>
      <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      {draftListLoading || draftDetailLoading ? (
        <Loading />
      ) : (
        <Box flex="auto" py={0}>
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <ScrollWrapper bottom={isShowFilter ? 430 : 319} bottomSE={319}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={48}
                width="100%"
                rowWidth="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      )}

      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== 0}
        onClose={() => clearEvent()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
    </WeeklyOfferNumberContext.Provider>
  );
  // feat screen 04-01-02-01 end
}
