/**
 * Figma ID: 01-01-12
 * 名称: 登録完了
 */
import React from 'react';
import { useForm } from 'react-hook-form';

import * as gql from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { RhfInput } from 'components/rhfInput';
import { Divider } from 'components/newDivider';
import { RhfSelect } from 'components/rhfSelect';
import { ErrorMessage } from 'components/errorMessage';
import { MainButton } from 'components/mainButton';
import { BasicForm } from 'components/basicForm';
import { RhfTextarea } from 'components/rhfTextarea';
import { InputLabel } from 'components/inputLabel';
import { emailPattern } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type StoreInfo = {
  bizName: string;
  brandName: string;
  store_name: string;
  zipCode1: string;
  zipCode2: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  phone: string;
  mailAddress: string;
  managerName: string;
  lat: string;
  lng: string;
  comment: string;
};

type Props = {
  storeInfo: StoreInfo | null;
  toNext: (storeInfo: {
    bizName: string;
    brandName: string;
    store_name: string;
    zipCode1: string;
    zipCode2: string;
    prefecture: string;
    city: string;
    address1: string;
    address2: string;
    phone: string;
    mailAddress: string;
    managerName: string;
    lat: string;
    lng: string;
    comment: string;
  }) => void;

  toBack: () => void;
};
export function StopRegistration({ storeInfo, toNext, toBack }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    store_name: string;
    zipCode1: string;
    zipCode2: string;
    prefecture: string;
    city: string;
    address1: string;
    address2: string;
    phone: string;
    mailAddress: string;
    managerName: string;
    lat: string;
    lng: string;
    comment: string;
  }>({
    reValidateMode: 'onChange',
  });

  const onSubmit = handleSubmit((data) => {
    toNext({
      bizName: storeInfo?.bizName ?? '',
      brandName: storeInfo?.brandName ?? '',
      store_name: data.store_name,
      zipCode1: data.zipCode1,
      zipCode2: data.zipCode2,
      prefecture: data.prefecture,
      city: data.city,
      address1: data.address1,
      address2: data.address2,
      phone: data.phone,
      lat: data.lat,
      lng: data.lng,
      mailAddress: data.mailAddress,
      managerName: data.managerName,
      comment: data.comment,
    });
  });
  const { openErrorModal } = useErrorModal();
  const { data: { getMState } = { getMState: [] }, error: getMStateError } = gql.useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [fetchMCityByStateId, { error: fetchMCityByStateIdError }] = gql.useGetMCityByStateIdLazyQuery();
  React.useEffect(() => {
    if (getMStateError || fetchMCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getMStateError, fetchMCityByStateIdError, openErrorModal]);

  const [cityOptions, setCityOptions] = React.useState<{ label: string; value: string }[]>([]);

  const prefectureOptions = React.useMemo<{ label: string; value: string }[]>(() => {
    if (!getMState) {
      return [];
    }
    return getMState.map((item) => ({
      label: item?.name ?? '',
      value: item?.name ?? '',
    }));
  }, [getMState]);
  React.useEffect(() => {
    const subscription = watch((value, { name, type, ...rest }) => {
      if (name === 'prefecture' && type === 'change') {
        const selectedPrefecture = getMState?.find((item) => item?.name === value.prefecture);
        if (selectedPrefecture) {
          fetchMCityByStateId({
            context: { clientName: 'master' },
            variables: { sid: Number(selectedPrefecture.id) },
          }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
            if (getMCityByStateId) {
              setCityOptions(
                getMCityByStateId.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                }))
              );
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMCityByStateId, getMState]);

  // 「戻る」ボタンからの再表示（入力済なら市区町村取得）
  React.useEffect(() => {
    if (storeInfo?.prefecture !== '' && storeInfo?.city !== '' && cityOptions.length === 0) {
      const selectedPrefecture = getMState?.find((item) => item?.name === storeInfo?.prefecture);
      if (selectedPrefecture) {
        fetchMCityByStateId({
          context: { clientName: 'master' },
          variables: { sid: Number(selectedPrefecture.id) },
        }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
          if (getMCityByStateId) {
            setCityOptions(
              getMCityByStateId.map((item) => ({
                label: item?.name ?? '',
                value: item?.name ?? '',
              }))
            );
          }
        });
      }
    }
  }, [fetchMCityByStateId, getMState, storeInfo, cityOptions]);

  const zipCode1Length = 3;
  const zipCode2Length = 4;

  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = storeInfo?.bizName ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = storeInfo?.brandName ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  // feat screen_01-01-02 additional start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="新規登録 - 店舗未登録の場合" />
      <Box display="flex" flexDirection="column" justifyContent="center" gap={8}>
        <Text variant="body14" bold color="blueGray" align="center">
          大変申し訳ありません
          <br />
          ご指定の店舗はまだ未登録です
        </Text>
        <Text variant="body14" align="center">
          運営にて店舗登録対応を行います。店舗情報を入力して「送信」を押してください。
        </Text>
      </Box>
      <Box display="flex">
        <BasicForm label="業種" value={editBizName} readonly onChange={() => {}} />
        <BasicForm label="ブランド" value={editBrandName} readonly onChange={() => {}} />
      </Box>
      <Box display="flex" flexDirection="column" gap={5.5}>
        <Text variant="h3" color="blueGray">
          店舗名
        </Text>
        <Box>
          <RhfInput
            name="store_name"
            control={control}
            defaultValue={storeInfo?.store_name || ''}
            fullWidth
            placeholder=""
            rules={{ required: '店舗名を入力してください' }}
          />
          {errors?.store_name && (
            <Box pt={4}>
              <ErrorMessage>{errors?.store_name.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={5.5}>
        <Text variant="h3" color="blueGray">
          郵便番号
        </Text>
        <Box display="flex" flexDirection="row" gap={16} alignItems="center">
          <Box>
            <RhfInput
              name="zipCode1"
              control={control}
              width={120}
              defaultValue={storeInfo?.zipCode1 || ''}
              placeholder=""
              type="number"
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
              }}
            />
          </Box>
          <Divider option="horizontal" length={7} />
          <Box>
            <RhfInput
              name="zipCode2"
              control={control}
              width={120}
              defaultValue={storeInfo?.zipCode2 || ''}
              placeholder=""
              type="number"
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
              }}
            />
          </Box>
        </Box>
        {(errors?.zipCode1 || errors.zipCode2) && (
          <Box pt={4}>
            <ErrorMessage>{errors.zipCode1?.message ?? errors.zipCode2?.message}</ErrorMessage>
          </Box>
        )}
      </Box>
      <Box height={78} display="flex" flexDirection="column" gap={4}>
        <InputLabel fullWidth optional>
          住所
        </InputLabel>
        <Box display="flex" gap={16}>
          <Box>
            <RhfSelect
              options={prefectureOptions}
              control={control}
              defaultValue={storeInfo?.prefecture || ''}
              name="prefecture"
              width={176}
              placeholder="都道府県"
              rules={{ required: '都道府県を選択してください' }}
            />
            {errors?.prefecture && (
              <Box pt={4}>
                <ErrorMessage>{errors?.prefecture.message}</ErrorMessage>
              </Box>
            )}
          </Box>
          <Box>
            <RhfSelect
              options={cityOptions}
              control={control}
              defaultValue={storeInfo?.city || ''}
              name="city"
              width={176}
              placeholder="市区町村"
              rules={{ required: '市区町村を選択してください' }}
            />
            {errors?.city && (
              <Box pt={4}>
                <ErrorMessage>{errors?.city.message}</ErrorMessage>
              </Box>
            )}
          </Box>
          <Box>
            <RhfInput
              control={control}
              name="address1"
              defaultValue={storeInfo?.address1 || ''}
              width={176}
              placeholder="番地等"
              rules={{ required: '番地等を入力してください' }}
            />
            {errors?.address1 && (
              <Box pt={4}>
                <ErrorMessage>{errors?.address1.message}</ErrorMessage>
              </Box>
            )}
          </Box>
          <RhfInput
            control={control}
            name="address2"
            defaultValue={storeInfo?.address2 || ''}
            width={176}
            placeholder="建物名・部屋番号"
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={5.5}>
        <Box display="flex" flexDirection="row" gap={50}>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
          <Text variant="caption11" color="cautionRed">
            バイトラベラーに連絡先として表示されます
          </Text>
        </Box>
        <RhfInput
          name="phone"
          control={control}
          width={328}
          defaultValue={storeInfo?.phone || ''}
          placeholder="電話番号"
          type="number"
          rules={{
            required: '電話番号（数字のみ）を入力してください',
            pattern: {
              value: /^\d*$/,
              message: '数字のみを入力してください',
            },
          }}
        />
        {errors?.phone && <ErrorMessage>{errors?.phone.message}</ErrorMessage>}
      </Box>
      <Box display="flex" flexDirection="row" gap={16}>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <InputLabel optional>緯度</InputLabel>
          <RhfInput
            name="lat"
            control={control}
            width={328}
            defaultValue={storeInfo?.lat || ''}
            placeholder="緯度"
            type="number"
            rules={{
              max: {
                value: 90,
                message: '90以下の数字を入力してください。',
              },
              min: {
                value: -90,
                message: '-90以上の数字を入力してください。',
              },
            }}
          />
          {errors?.lat && <ErrorMessage>{errors?.lat.message}</ErrorMessage>}
        </Box>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <InputLabel optional>経度</InputLabel>
          <RhfInput
            name="lng"
            control={control}
            width={328}
            defaultValue={storeInfo?.lng || ''}
            placeholder="経度"
            type="number"
            rules={{
              max: {
                value: 180,
                message: '180以下の数字を入力してください。',
              },
              min: {
                value: -180,
                message: '-180以上の数字を入力してください。',
              },
            }}
          />
          {errors?.lng && <ErrorMessage>{errors?.lng.message}</ErrorMessage>}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={5.5}>
        <Text variant="h3" color="blueGray">
          ご担当者様氏名
        </Text>
        <Box>
          <RhfInput
            name="managerName"
            control={control}
            defaultValue={storeInfo?.managerName || ''}
            fullWidth
            placeholder=""
            rules={{ required: '氏名を入力してください' }}
          />
          {errors?.store_name && (
            <Box pt={4}>
              <ErrorMessage>{errors?.store_name.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={16}>
        <InputLabel>メールアドレス</InputLabel>
        <RhfInput
          name="mailAddress"
          control={control}
          fullWidth
          defaultValue={storeInfo?.mailAddress || ''}
          placeholder="メールアドレス"
          rules={{
            required: 'メールアドレスを入力してください。',
            pattern: {
              value: emailPattern,
              message: '入力形式がメールアドレスではありません。',
            },
          }}
        />
        {errors.mailAddress?.message && (
          <Text variant="caption12" color="cautionRed">
            {errors.mailAddress.message}
          </Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={8}>
        <InputLabel fullWidth optional>
          コメント
        </InputLabel>
        <Box>
          <RhfTextarea
            name="comment"
            control={control}
            defaultValue={storeInfo?.comment || ''}
            fullWidth
            height={74}
            placeholder=""
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" gap={5}>
        <MainButton variant="secondary" onClick={toBack}>
          戻る
        </MainButton>
        <Box display="grid" justifyContent="center">
          <MainButton
            onClick={() => {
              onSubmit();
            }}
          >
            次へ
          </MainButton>
        </Box>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="新規登録 - 店舗未登録の場合" />
      <Box display="flex" flexDirection="column" justifyContent="center" pt={18} pb={22} gap={16}>
        <Box display='block'>
          <Text display='block' variant="body14" bold color="blueGray" align="center">
            大変申し訳ありません
            <br />
            ご指定の店舗はまだ未登録です
          </Text>
        </Box>
        <Box display='block'>
          <Text display='block' variant="body14" align="center">
            運営にて店舗登録対応を行います。店舗情報を入力して「送信」を押してください。
          </Text>
        </Box>
      </Box>
      <Box display="flex" width="100%" flexDirection='column' pb={2}>
        <Box display='block' width="100%" mb={-2}>
          <BasicForm fullWidth label="業種" value={editBizName} readonly onChange={() => {}} />
        </Box>
        <Box display='block' width="100%">
          <BasicForm fullWidth label="ブランド" value={editBrandName} readonly onChange={() => {}} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display='block'>
          <Text variant="h3" color="blueGray">
            店舗名
          </Text>
        </Box>
        <Box>
          <RhfInput
            name="store_name"
            control={control}
            defaultValue={storeInfo?.store_name || ''}
            fullWidth
            placeholder=""
            rules={{ required: '店舗名を入力してください' }}
          />
          {errors?.store_name && (
            <Box pt={4}>
              <ErrorMessage>{errors?.store_name.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} pt={18}>
        <Box display='block'>
          <Text variant="h3" color="blueGray">
            郵便番号
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent='space-between' gap={8} alignItems="center">
          <Box width="100%">
            <RhfInput
              name="zipCode1"
              control={control}
              fullWidth
              defaultValue={storeInfo?.zipCode1 || ''}
              placeholder=""
              type="number"
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
              }}
            />
          </Box>
          <Divider option="horizontal" length={7} />
          <Box width="100%">
            <RhfInput
              name="zipCode2"
              control={control}
              fullWidth
              defaultValue={storeInfo?.zipCode2 || ''}
              placeholder=""
              type="number"
              rules={{
                required: '郵便番号を入力してください。',
                pattern: {
                  value: /^\d*$/,
                  message: '数字を使用してください。',
                },
                maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
              }}
            />
          </Box>
        </Box>
        {(errors?.zipCode1 || errors.zipCode2) && (
          <Box pt={4}>
            <ErrorMessage>{errors.zipCode1?.message ?? errors.zipCode2?.message}</ErrorMessage>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" pt={16}>
        <Box display='flex' flexDirection="column" gap={2}>
          <InputLabel fullWidth optional>
            住所
          </InputLabel>
          <Box width="100%" display="flex" justifyContent="space-between" gap={24}>
            <Box width="100%">
              <RhfSelect
                options={prefectureOptions}
                control={control}
                defaultValue={storeInfo?.prefecture || ''}
                name="prefecture"
                fullWidth
                height={40}
                placeholder="都道府県"
                rules={{ required: '都道府県を選択してください' }}
              />
              {errors?.prefecture && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.prefecture.message}</ErrorMessage>
                </Box>
              )}
            </Box>
            <Box width="100%">
              <RhfSelect
                options={cityOptions}
                control={control}
                defaultValue={storeInfo?.city || ''}
                name="city"
                fullWidth
                height={40}
                placeholder="市区町村"
                rules={{ required: '市区町村を選択してください' }}
              />
              {errors?.city && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.city.message}</ErrorMessage>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent='space-between' gap={24} pt={16}>
          <Box width="100%">
            <RhfInput
              control={control}
              name="address1"
              defaultValue={storeInfo?.address1 || ''}
              fullWidth
              placeholder="番地等"
              rules={{ required: '番地等を入力してください' }}
            />
            {errors?.address1 && (
              <Box pt={4}>
                <ErrorMessage>{errors?.address1.message}</ErrorMessage>
              </Box>
            )}
          </Box>
          <Box width="100%">
            <RhfInput
              control={control}
              name="address2"
              defaultValue={storeInfo?.address2 || ''}
              fullWidth
              placeholder="建物名・部屋番号"
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} pt={18}>
        <Box display="flex" flexDirection="row" justifyContent='space-between'>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
          <div style={{transition: "translateY(-5px)"}}>
            <Text variant="caption12" color="cautionRed">
              バイトラベラーに連絡先として表示されます
            </Text>
          </div>
        </Box>
        <RhfInput
          name="phone"
          control={control}
          fullWidth
          defaultValue={storeInfo?.phone || ''}
          placeholder="電話番号"
          type="number"
          rules={{
            required: '電話番号（数字のみ）を入力してください',
            pattern: {
              value: /^\d*$/,
              message: '数字のみを入力してください',
            },
          }}
        />
        {errors?.phone && <ErrorMessage>{errors?.phone.message}</ErrorMessage>}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} pt={14}>
        <Box display="flex" flexDirection="column" gap={2}>
          <InputLabel fullWidth optional>緯度</InputLabel>
          <RhfInput
            name="lat"
            control={control}
            fullWidth
            defaultValue={storeInfo?.lat || ''}
            placeholder="緯度"
            type="number"
            rules={{
              max: {
                value: 90,
                message: '90以下の数字を入力してください。',
              },
              min: {
                value: -90,
                message: '-90以上の数字を入力してください。',
              },
            }}
          />
          {errors?.lat && <ErrorMessage>{errors?.lat.message}</ErrorMessage>}
        </Box>
        <Box display="flex" flexDirection="column" pt={14} gap={2}>
          <InputLabel fullWidth optional>経度</InputLabel>
          <RhfInput
            name="lng"
            control={control}
            fullWidth
            defaultValue={storeInfo?.lng || ''}
            placeholder="経度"
            type="number"
            rules={{
              max: {
                value: 180,
                message: '180以下の数字を入力してください。',
              },
              min: {
                value: -180,
                message: '-180以上の数字を入力してください。',
              },
            }}
          />
          {errors?.lng && <ErrorMessage>{errors?.lng.message}</ErrorMessage>}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" pt={16} gap={2}>
        <Box display='block'>
          <Text variant="h3" color="blueGray">
            ご担当者様氏名
          </Text>
        </Box>
        <Box>
          <RhfInput
            name="managerName"
            control={control}
            defaultValue={storeInfo?.managerName || ''}
            fullWidth
            placeholder=""
            rules={{ required: '氏名を入力してください' }}
          />
          {errors?.store_name && (
            <Box pt={4}>
              <ErrorMessage>{errors?.store_name.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" pt={16} gap={2}>
        <InputLabel>メールアドレス</InputLabel>
        <RhfInput
          name="mailAddress"
          control={control}
          fullWidth
          defaultValue={storeInfo?.mailAddress || ''}
          placeholder="メールアドレス"
          rules={{
            required: 'メールアドレスを入力してください。',
            pattern: {
              value: emailPattern,
              message: '入力形式がメールアドレスではありません。',
            },
          }}
        />
        {errors.mailAddress?.message && (
          <Text variant="caption12" color="cautionRed">
            {errors.mailAddress.message}
          </Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" pt={16} gap={2}>
        <InputLabel fullWidth optional>
          コメント
        </InputLabel>
        <Box>
          <RhfTextarea
            name="comment"
            control={control}
            defaultValue={storeInfo?.comment || ''}
            fullWidth
            height={94}
            placeholder=""
          />
        </Box>
      </Box>
      <Box display="flex" pt={20} pb={18} justifyContent="space-between" gap={5}>
        <MainButton fullWidth customHeight={40} variant="secondary" onClick={toBack}>
          戻る
        </MainButton>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            onSubmit();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  );
  // feat screen_01-01-02 additional end
}
