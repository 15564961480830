import React from 'react';
import { TRecvNotify } from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { colorPalette } from 'components/text/text';
import { Icon } from 'components/icon';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';


export type ListProps = {
  children: React.ReactNode;
  item: TRecvNotify;
  onClickRow: () => void;
};

const StyledSpan = styled.span`
  &:hover {
    color: ${colorPalette.blue};
  }
`;

/* feat_common_responsive_list_row_start */
const StyledSpanContent = styled.span`
  display: block;
  width : 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
/* feat_common_responsive_list_row_start */

function ListText(props: {
  children: React.ReactNode;
  onClickRow:() => void;
  // feat_common_responsive_useMobile add props start
  hasAlert?: boolean;
  // feat_common_responsive_useMobile add props end
}) {
  // feat_common_responsive_useMobile add props start
  const { children, onClickRow,hasAlert} = props;
  // feat_common_responsive_useMobile add props end
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (    
    <div role="button" style={{cursor: "pointer"}} tabIndex={0} onClick={() => onClickRow()} onKeyDown={() => {}}>
      <Text variant="body14">
        <StyledSpan>
          {children}
        </StyledSpan>
      </Text>
    </div>
  ): (
    /* feat_common_responsive_add_prop_start */
    <div role="button" style={{cursor: "none", width: "100%"}} tabIndex={0} onClick={() => onClickRow()} onKeyDown={() => {}}>
      {/* feat_common_responsive_add_prop_end */}
      <StyledSpanContent>
        <StyledSpan >
        {/* feat_common_responsive_list_row_start */}
          {hasAlert && 
            <Box display='inline-block' mr={4}>
              <Icon name='indicatorRed' size={8}/> 
            </Box>
          }
          {/* feat_common_responsive_list_row_end */}
          {children}
        </StyledSpan>
      </StyledSpanContent>
    </div>
  );
};

// 通知一覧の各行
export function ListRow(props: ListProps) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { children, item, onClickRow } = props;

  const hasAlert = !item.read ?? false;

  return hasAlert ? (
    <Box display='flex' flexDirection='row' gap={8} alignItems='center'>
      {/* feat_common_responsive_list_row_start */}
      {
        !isMobile && 
        <Icon name='indicatorRed' size={8}/> 
      }
      {/* feat_common_responsive_list_row_end */}
      <ListText 
        onClickRow={onClickRow} 
        // feat_common_responsive_add_props hasAlert start
        hasAlert={hasAlert}
        // feat_common_responsive_add_props hasAlert end
      >
        {children}
      </ListText>
    </Box>
  ): (
    <div>
      <ListText onClickRow={onClickRow}>{children}</ListText>
    </div>
  );
}

