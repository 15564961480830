import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { AddThanksMessageModalForm, ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';

enum PhraseType {
  PREVIOUS = 0,
  FOLLOWING = 1,
}

export function AddThanksMessage(
  control: Control<AddThanksMessageModalForm>,
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const {
    data: thanksMessageData,
    loading: thanksMessageLoading,
    error: thanksMessageError,
  } = gql.useGetMAchieveQuery({
    context: { clientName: 'master' },
  });
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (thanksMessageError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [thanksMessageError, openErrorModal]);

  const previousSentenceList =
    thanksMessageData?.getMAchieve?.filter((item) => item?.kind === PhraseType.PREVIOUS) || [];

  const followingSentenceList =
    thanksMessageData?.getMAchieve?.filter((item) => item?.kind === PhraseType.FOLLOWING) || [];

  const previousSentenceOptions = previousSentenceList.map((item) => ({
    label: item?.phrase || '',
    value: item?.phrase || '',
  }));

  const followingSentenceOptions = followingSentenceList.map((item) => ({
    label: item?.phrase || '',
    value: item?.phrase || '',
  }));

  const previousSentence = useWatch({ control, name: 'previousSentence' });
  const followingSentence = useWatch({ control, name: 'followingSentence' });

  const shouldDisableNextButton =
    previousSentence === '' || !previousSentence || followingSentence === '' || !followingSentence;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        Thanksメッセージ付与
      </Text>
    ),
    content: !isMobile ?(
      <Box display="flex" height="100%" flexDirection="column" flex="1" gap={9.5} pa={8}>
        {thanksMessageLoading && <Loading />}
        <Text variant="body14">Thanksメッセージを選択してください。</Text>
        <Box display="flex" flexDirection="row" gap={16}>
          <Box display="flex" flexDirection="column" gap={5.5} width={160}>
            <Text variant="h3" color="blueGray">
              A
            </Text>
            <RhfSelect
              control={control}
              name="previousSentence"
              options={previousSentenceOptions}
              width={160}
              placeholder="選択"
              rules={{ required: true }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={5.5} width={160}>
            <Text variant="h3" color="blueGray">
              B
            </Text>
            <RhfSelect
              control={control}
              name="followingSentence"
              options={followingSentenceOptions}
              width={160}
              placeholder="選択"
              rules={{ required: true }}
            />
          </Box>
        </Box>
      </Box>
    ) : (
      /* feat_screen_05-01-05-02_start */
      <Box display="flex" height="100%" flexDirection="column" flex="1" gap={9.5}>
        {thanksMessageLoading && <Loading />}
        <Text variant="body14">Thanksメッセージを選択してください。</Text>
        <Box display="flex" flexDirection="row" gap={16}>
          <Box display="flex" flexDirection="column" gap={4}  width="100%" pt={4}>
            <Text variant="body14" color="blueGray" bold>
              A
            </Text>
            <RhfSelect
              control={control}
              name="previousSentence"
              options={previousSentenceOptions}
              fullWidth
              height={40}
              placeholder="選択"
              rules={{ required: true }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={4} width="100%"  pt={4}>
            <Text variant="body14" color="blueGray" bold>
              B
            </Text>
            <RhfSelect
              control={control}
              name="followingSentence"
              options={followingSentenceOptions}
              fullWidth
              height={40}
              placeholder="選択"
              rules={{ required: true }}
            />
          </Box>
        </Box>
      </Box>
      /* feat_screen_05-01-05-02_end */
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen_05-01-05-02_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          /* feat_screen_05-01-05-02_end */
          variant="secondary" 
          onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          /* feat_screen_05-01-05-02_start */
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          /* feat_screen_05-01-05-02_end */
          disabled={shouldDisableNextButton}
          onClick={() => {
            onNext();
          }}
        >
          付与
        </MainButton>
      </Box>
    ),
    /* feat_screen_05-01-05-02_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 275 : "auto",
    /* feat_screen_05-01-05-02_end */
  };

  return modalContent;
}
