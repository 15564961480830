import { Box } from 'components/box';
import { Card } from 'components/card';
import { Text } from 'components/text';
import {
  VtEmployList,
  useAddelTFavoriteBtmemByIdBtmemIdMutation,
  useGetVtEmployListByTpmemIdQuery,
} from 'graphql/graphql-mw';
import React, { useCallback, useMemo, useState } from 'react';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Loading } from 'components/loading/loading';
import { useAuth } from 'hooks/authProvider';
import { List } from 'components/list';
import { styled } from '@linaria/react';
import { orderBy } from 'lodash';
import { Avatar } from 'components/avatar';
import { Icon } from 'components/icon';
import { Chip } from 'components/chip';
import { Favorite } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';

import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { ListHeaderType, ListItemsType, SelectOptionsType } from './type';
import { ImgWrapper } from './components/imgWrapper';
import { BlockFlag, MemberType, StoreType } from './const';

const ALL = '全て';

const StyledText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;

  color: #ffffff;
  display: flex;
  align-items: center;
`;

export function S0502() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { handleChangeLimit, handleChangePage, limit, page, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort();
  const { openErrorModal } = useErrorModal();

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end

  const tpmemId = auth?.tpmemId;
  if (tpmemId === null) navigate('/signin');

  const [filterValue, setFilterValue] = useState<{
    btmem_id?: string;
    favorite?: string;
  }>();
  const [btmemId, setBtmemId] = useState<string>('');

  const {
    data: { getVTEmployListByTpmemId: data } = {
      getVTEmployListByTpmemId: [] as VtEmployList[],
    },
    loading,
    error: getVtEmployListByTpmemIdError,
    refetch,
  } = useGetVtEmployListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const handleFilterValueClear = () => {
    setFilterValue(undefined);
    setBtmemId('');
  };

  const generateChip = (type: number) => {
    switch (type) {
      case StoreType.OTHER_STORE:
        return (
          <Chip width={56} color="okBlue">
            他店/一般
          </Chip>
        );
      case StoreType.GROUP:
        return (
          <Chip width={56} color="darkBlue">
            グループ
          </Chip>
        );
      default:
        return '';
    }
  };

  const favoriteSelectOptions: SelectOptionsType = [
    { value: '全て', label: '全て' },
    { value: 'お気に入り済', label: 'お気に入り済' },
    { value: 'お気に入り未', label: 'お気に入り未' },
  ];

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'eye', width: 24, columnName: '' },
      // feat_screen_05-02-01-01_start
      { key: 'favorite', width: !isMobile ? 24 : 20, columnName: '' },
      { key: 'image', width: !isMobile ? 40 : 45, columnName: '' },
      { key: 'kind', width: !isMobile ? 56 : 68, columnName: '種別' },
      // feat_screen_05-02-01-01_end
      { key: 'btmem_number', width: 112, columnName: '会員番号' },
      { key: 'age', width: 56, columnName: '年齢' },
      // feat_screen_05-02-01-01_start
      { key: 'rank_name', width: !isMobile ? 64 : 68, columnName: 'ランク' },
      { key: 'score', width: !isMobile ? 64 : 68, columnName: 'スコア' },
      { key: 'belong_score', width: !isMobile ? 88 : 92, columnName: '自店スコア' },
      // feat_screen_05-02-01-01_end
      { key: 'attribute', width: 104, columnName: '種類' },
      { key: 'gender', width: 56, columnName: '性別' },
      // feat_screen_05-02-01-01_start
      { key: 'uniform_size', width: !isMobile ? 88 : 92, columnName: '制服サイズ' },
      // feat_screen_05-02-01-01_end
    ],
    [
      // feat_screen_05-02-01-01_start
      isMobile,
      // feat_screen_05-02-01-01_end
    ]
  );

  const [updateFavoriteStatus, { loading: updateFavoriteStatusLoading, error: updateFavoriteStatusError }] =
    useAddelTFavoriteBtmemByIdBtmemIdMutation();

  React.useEffect(() => {
    if (getVtEmployListByTpmemIdError || updateFavoriteStatusError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtEmployListByTpmemIdError, updateFavoriteStatusError, openErrorModal]);

  const toggleFavoriteStatus = useCallback(
    (isFavorite?: number | null, btmemNumber?: number | null) => {
      if (typeof isFavorite !== 'number' || typeof btmemNumber !== 'number' || typeof tpmemId !== 'number') {
        return new Promise(() => {});
      }
      return updateFavoriteStatus({
        variables: {
          btmem_id: btmemNumber,
          id: tpmemId,
          on_off: isFavorite === Favorite.YES ? Favorite.NO : Favorite.YES,
        },
      });
    },
    [tpmemId, updateFavoriteStatus]
  );

  const onClickFavorite = useCallback(
    (isFavorite?: number | null, btmemNumber?: number | null) => {
      if (updateFavoriteStatusLoading) {
        return;
      }

      toggleFavoriteStatus(isFavorite, btmemNumber).then(() => {
        refetch();
      });
    },
    [updateFavoriteStatusLoading, toggleFavoriteStatus, refetch]
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading) return [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let items: VtEmployList[] = data;

    if (filterValue?.btmem_id && filterValue.btmem_id !== ALL) {
      const regexp = new RegExp(filterValue.btmem_id, 'i');
      items = items.filter(({ btmem_number }) => btmem_number?.match(regexp));
    }
    if (filterValue?.favorite && filterValue.favorite !== ALL) {
      items = items.filter(({ favorite_flag }) => {
        if (filterValue.favorite === 'お気に入り済') {
          return favorite_flag === Favorite.YES;
        }
        return favorite_flag === Favorite.NO;
      });
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    // リストの背景判定
    const rowBackgroundColor = (identification: number | undefined | null) => {
      switch (identification) {
        case MemberType.PERMANENTLY_DISABLE:
        case MemberType.TEMPORARILY_DISABLE:
        case MemberType.SUSPENSION:
          return 'red';
        default:
          return 'white';
      }
    };
    // Imageのラベル取得
    const rowLabel = (identification: number | undefined) => {
      switch (identification) {
        case MemberType.PERMANENTLY_DISABLE:
          return '一時停止';
        case MemberType.TEMPORARILY_DISABLE:
          return '停止';
        case MemberType.SUSPENSION:
          return '休止';
        default:
          return '';
      }
    };
    // ImageのColor取得
    const rowImageColor = (identification: number | undefined) => {
      switch (identification) {
        case MemberType.PERMANENTLY_DISABLE:
          return 'rgba(0, 0, 0, 0.7)';
        case MemberType.TEMPORARILY_DISABLE:
          return 'rgba(0, 0, 0, 0.7)';
        case MemberType.SUSPENSION:
          return 'rgba(229, 0, 0, 0.7)';
        default:
          return '';
      }
    };

    return items.map((item, key) => ({
      eye: !isMobile ? (
        <Link to={`${location.pathname}/${item.btmem_number ?? ''}`}>
          <Icon name="eye" />
        </Link>
      ) : (
        // feat_screen_05-02-01-01_start
        <Link to={`${location.pathname}/${item.btmem_number ?? ''}`}>
          <Icon name="eye" size={20} />
        </Link>
        // feat_screen_05-02-01-01_end
      ),
      favorite: !isMobile ? (
        <Icon
          name={item.favorite_flag === Favorite.YES ? 'favoriteBorderO' : 'favoriteBorder'}
          onClick={() => onClickFavorite(item.favorite_flag, parseInt(item.btmem_number ?? '0', 10))}
        />
      ) : (
        // feat_screen_05-02-01-01_start
        <Icon
          size={20}
          name={item.favorite_flag === Favorite.YES ? 'favoriteBorderO' : 'favoriteBorder'}
          onClick={() => onClickFavorite(item.favorite_flag, parseInt(item.btmem_number ?? '0', 10))}
        />
        // feat_screen_05-02-01-01_end
      ),
      image:
        item?.status === MemberType.SUSPENSION ||
        item?.status === MemberType.PERMANENTLY_DISABLE ||
        item?.status === MemberType.TEMPORARILY_DISABLE ? (
          <ImgWrapper
            width={40}
            height={40}
            membertype={item?.status}
            backgroundcolor={rowImageColor(item?.status)}
            isMobile={isMobile}
            text={<StyledText>{rowLabel(item?.status)}</StyledText>}
          >
            <Avatar src={item.image ?? ''} width={40} height={40} />
          </ImgWrapper>
        ) : (
          <Avatar src={item.image ?? ''} width={40} height={40} />
        ),
      kind: generateChip(item.type ?? 0),
      btmem_number: item?.btmem_number,
      age: item?.age,
      rank_name: item?.rank_name,
      score: item?.score,
      belong_score: item.belong_score,
      attribute: item?.attribute,
      gender: item?.gender,
      uniform_size: item?.uniform_size,
      backgroundColor: item.block_flag === BlockFlag.TRUE ? 'red' : rowBackgroundColor(item.status),
      uniqueKey: key,
    }));
  }, [
    loading,
    data,
    filterValue,
    sort,
    location.pathname,
    onClickFavorite,
    // feat_screen_05-02-01-01_add_props_start
    isMobile,
    // feat_screen_05-02-01-01_add_props_end
  ]);

  return (
    <>
      {loading && <Loading />}
      {!isMobile ? (
        <Box display="flex" flexDirection="column" height="100%" gap={26}>
          <Box display="flex" height={30}>
            <Text variant="h1" color="darkBlue">
              他店/一般一覧
            </Text>
          </Box>
          <Box display="flex" flex="auto">
            <Card overflow="hidden">
              <Box display="flex" flexDirection="row" pa={16} gap={16} alignItems="center">
                <Input
                  name="btmem_number"
                  onChange={(e) => setBtmemId(e.target.value)}
                  value={btmemId}
                  width={160}
                  placeholder="会員番号"
                />
                <MainButton
                  icon="search"
                  width={80}
                  onClick={() => {
                    // ページ初期化
                    setPage(1);
                    setFilterValue({ ...filterValue, btmem_id: btmemId });
                  }}
                >
                  検索
                </MainButton>
                <Divider option="vertical" length={24} />
                <Select
                  name="favorite"
                  options={favoriteSelectOptions}
                  onChange={(value) => {
                    // ページ初期化
                    setPage(1);
                    setFilterValue({ ...filterValue, favorite: value });
                  }}
                  value={filterValue?.favorite ?? ''}
                  width={140}
                  placeholder="お気に入り"
                />
                <MainButton
                  variant="clear"
                  width={44}
                  onClick={() => {
                    // ページ初期化
                    setPage(1);
                    handleFilterValueClear();
                  }}
                >
                  クリア
                </MainButton>
              </Box>
              <Divider option="horizontal" />
              <Box flex="auto" width="100%" px={16}>
                <PaginationBox
                  dataSize={listItems.length}
                  limit={limit}
                  page={page}
                  onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                  onChangePage={(value: number) => handleChangePage(value)}
                >
                  <ScrollWrapper bottom={317}>
                    <List
                      header={listHeader}
                      items={listItems.slice(limit * (page - 1), limit * page)}
                      key={String(limit) + String(page) + String(sort?.key || '')}
                      sort={sort}
                      onChangeSort={handleChangeSort}
                      rowWidth="100%"
                      rowHeight={56}
                      width="100%"
                    />
                  </ScrollWrapper>
                </PaginationBox>
              </Box>
            </Card>
          </Box>
        </Box>
      ) : (
        // feat_screen_05-02-01-01_start
        <Box display="flex" flexDirection="column" columnGap={16} width="100vw" height="100%">
          <Box>
            <MultiMenuHeader
              items={[
                {
                  label: 'バイトラベラー',
                },
                {
                  label: '他店/一般一覧',
                },
              ]}
              mainHeader="他店/一般一覧"
            />
          </Box>
          <Box display="flex" flex="auto" px={16} overflow="auto" pt={16} pb={isMobile ? 76 : 16}>
            <Card overflow="auto">
              <Box display={isShowFilter ? 'flex' : 'none'} flexDirection="column" pa={16} pb={31} gap={12}>
                <Box display="flex" gap={12} justifyContent="space-between">
                  <Box flex="1">
                    <Input
                      name="btmem_number"
                      onChange={(e) => setBtmemId(e.target.value)}
                      value={btmemId}
                      fullWidth
                      placeholder="会員番号"
                    />
                  </Box>
                  <MainButton
                    icon="search"
                    width={80}
                    customHeight={40}
                    onClick={() => {
                      // ページ初期化
                      setPage(1);
                      setFilterValue({ ...filterValue, btmem_id: btmemId });
                    }}
                  >
                    検索
                  </MainButton>
                </Box>
                <Box display="flex" justifyContent="space-between" gap={12}>
                  <Select
                    name="favorite"
                    options={favoriteSelectOptions}
                    height={40}
                    onChange={(value) => {
                      // ページ初期化
                      setPage(1);
                      setFilterValue({ ...filterValue, favorite: value });
                    }}
                    value={filterValue?.favorite ?? ''}
                    fullWidth
                    placeholder="お気に入り"
                  />
                  <Box display="flex" alignItems="center">
                    <MainButton
                      variant="clear"
                      width={80}
                      onClick={() => {
                        // ページ初期化
                        setPage(1);
                        handleFilterValueClear();
                      }}
                    >
                      クリア
                    </MainButton>
                  </Box>
                </Box>
              </Box>
              <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
              <Box flex="auto" width="100%">
                <PaginationBox
                  dataSize={listItems.length}
                  limit={limit}
                  page={page}
                  onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                  onChangePage={(value: number) => handleChangePage(value)}
                >
                  <ScrollWrapper bottom={isShowFilter ? 379 : 270}>
                    <List
                      header={listHeader}
                      items={listItems.slice(limit * (page - 1), limit * page)}
                      key={String(limit) + String(page) + String(sort?.key || '')}
                      sort={sort}
                      onChangeSort={handleChangeSort}
                      rowWidth="100%"
                      rowHeight={57}
                      width="100%"
                    />
                  </ScrollWrapper>
                </PaginationBox>
              </Box>
            </Card>
          </Box>
        </Box>
        // feat_screen_05-02-01-01_end
      )}
    </>
  );
}
