import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import { orderBy } from 'lodash';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { Divider } from 'components/newDivider';
import { TextLink } from 'components/textLink';
import { usePagenation, useSort } from 'components/utils/hooks';
import {
  MBrand,
  VtGroupList,
  VSelGroupList,
  useGetMBrandByBizIdQuery,
  useGetVtGroupListByTpmemIdQuery,
  useGetVSelGroupListByMyIdBrandIdLazyQuery,
  useUpdateTGroupMemStatusByIdTpmemIdMutation,
  useGetMStateLazyQuery,
  useGetMCityByStateIdLazyQuery,
  useGetVtMemberBaseByIdLazyQuery,
  useAddTGroupMutation,
  MState,
  MCity,
  VtMemberBase,
} from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { Option, Store } from 'pages/s09/components/types';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import { JoinStatus, MemberStoreStatus } from 'components/const';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { handleMutationResult } from 'error';

import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import LeaveModal from './modals/groupStores/LeaveModal';
import LeaveCompletedModal from './modals/groupStores/LeaveCompletedModal';
import SelectBrandModal from './modals/groupStores/SelectBrandModal';
import FindStoresModal from './modals/groupStores/FindStoresModal';
import StoreDetailsModal, { StoreDetails } from './modals/groupStores/StoreDetailsModal';
import ConfirmInvitationModal from './modals/groupStores/ConfirmInvitationModal';
import InvitationCompletedModal from './modals/groupStores/InvitationCompletedModal';

/**
 * Figma ID
 * 09-04-01
 * グループ店舗タブ
 */

const StyledListWrapper = styled.div<{
  isShowFilter?: boolean;
}>`
  max-height: calc(100vh - 352px);
  min-height: calc(100vh - 352px);
  overflow: scroll;
  /* feat_common_responsive_divider_start */
  @media only screen and (max-width: 768px) {
    max-height: ${({isShowFilter})=> isShowFilter ? "calc(100svh - 428px)" : "calc(100svh - 341px)"};
    min-height: ${({isShowFilter})=> isShowFilter ? "calc(100svh - 428px)" : "calc(100svh - 341px)"};
    overflow: scroll;
  }
  /* feat_common_responsive_modal_end */
`;

const header = [
  {
    columnName: '',
    key: 'picture',
    width: 80,
  },
  {
    columnName: '店舗名',
    key: 'storeName',
    width: 160,
  },
  {
    columnName: '登録日',
    key: 'registeredDate',
    width: 240,
  },
];

interface ListDataType {
  id: number;
  image: string;
  storeName: string;
  registeredDate: string;
}

function generateListRow(
  data: ListDataType[],
  limit: number,
  page: number,
  onClickStoreName: (id: number) => void,
  sort?: { key: string; direction: 'asc' | 'desc' },
  // feat_screen_09-04-01_start
  isMobile?: boolean
  // feat_screen_09-04-01_end
) {
  function convert(d: ListDataType, index: number) {
    return {
      // feat_screen_09-04-01_start
      picture: <img width={!isMobile ? 60 : 40} height={40} src={d.image} alt="" />,
      // feat_screen_09-04-01_end
      storeName: (
        <TextLink to="" onClick={() => onClickStoreName(d.id)}>
          {d.storeName}
        </TextLink>
      ),
      registeredDate: d.registeredDate,
      uniqueKey: `key-${index}`,
    };
  }

  return orderBy(data, sort?.key, sort?.direction)
    .slice(limit * (page - 1), limit * page)
    .map(convert);
}

const enum ModalTypes {
  Leave = 'Leave',
  LeaveCompleted = 'LeaveCompleted',
  SelectBrand = 'SelectBrand',
  FindStores = 'FindStores',
  ConfirmInvitation = 'ConfirmInvitation',
  InvitationCompleted = 'InvitationCompleted',
  StoreDetailsInfo = 'StoreDetailsInfo',
}

function convertBrandDataToOptions(data: (MBrand | null)[]): Option[] {
  return data.map((d) => ({
    value: d?.id?.toString() || '0',
    label: d?.name || '',
  }));
}

function convertStatesDataToOptions(data: (MState | null)[]): Option[] {
  return data.map((d) => ({
    value: d?.id.toString() || '',
    label: d?.name || '',
  }));
}

function convertCitiesDataToOptions(data: (MCity | null)[]): Option[] {
  return data.map((d) => ({
    value: d?.name || '', // 市区町村名をフィルタに使いたいのでnameをvalueにする
    label: d?.name || '',
  }));
}

function convertGroupStoresDataToListData(data: VtGroupList[]): ListDataType[] {
  return data.map((d) => ({
    id: d.tpmem_id,
    image: d.image || '',
    storeName: d.name || '',
    registeredDate: d.accept_date || '',
  }));
}

function convertStoresToList(data: (VSelGroupList | null)[]): Store[] {
  return data.map((d) => ({
    id: d?.tpmem_id || 0,
    name: d?.name || '',
    zipcode: d?.zip_code || '',
    address: `${d?.state || ''}${d?.city || ''}${d?.address || ''}${d?.address2 || ''}` || '',
    corporateNumber: d?.corporate_no || '',
    disabled: d?.joined === 1,
    state: d?.state || '',
    city: d?.city || '',
  }));
}

function convertVtMemberBaseToStoreDetails(data?: VtMemberBase): StoreDetails {
  return {
    storeName: data?.store_name || '',
    zipcode: data?.zip_code || '',
    address: `${data?.state || ''}${data?.city || ''}${data?.address || ''}${data?.address2 || ''}` || '',
    image: data?.image || '',
    phone: data?.phone || '',
    email: data?.email || '',
    managerName: data?.manager || '',
    corporationName: data?.mco_name || '',
    corporationZipcode: data?.mco_zip_code || '',
    corporationAddress:
      `${data?.mco_state || ''}${data?.mco_city || ''}${data?.mco_address || ''}${data?.mco_address2 || ''}` || '',
    corporationNumber: data?.corporate_no || '',
    corporationPhone: data?.mco_phone || '',
    representativeName: data?.mco_m_name || '',
    representativeNameKana: data?.mco_m_kana || '',
    chiefName: data?.mco_t_name || '',
    chiefNameKana: data?.mco_t_kana || '',
    chiefPhone: data?.mco_t_phone || '',
  };
}

function GroupStores(props: { bizId: number }) {
  const { bizId } = props;
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  const [activeModal, setActiveModal] = React.useState<ModalTypes | null>(null);
  const [selectedBrand, setSelectedBrand] = React.useState<Option | null>(null);
  const [selectedState, setSelectedState] = React.useState<Option | null>(null);
  const [selectedGroupStores, setSelectedGroupStores] = React.useState<Store[]>([]);

  const {
    data: brandData,
    loading: brandDataLoading,
    error: brandDataError,
  } = useGetMBrandByBizIdQuery({
    variables: { bid: bizId },
    context: {
      clientName: 'master',
    },
  });

  const { tpmemId: loginId } = useAuth();

  const [findStoresQuery, { data: storesData, loading: storesLoading }] = useGetVSelGroupListByMyIdBrandIdLazyQuery();
  const storesList = convertStoresToList(
    storesData?.getVSelGroupListByMyIdBrandId.filter((store) => store.tpmem_id !== loginId) || []
  );

  const [findVtMemberBaseQuery, { data: vtMemberBaseData, loading: vtMemberBaseLoading }] =
    useGetVtMemberBaseByIdLazyQuery();
  const storeDetails = convertVtMemberBaseToStoreDetails(vtMemberBaseData?.getVTMemberBaseById);

  const [getStatesQuery, { data: statesData, loading: statesLoading }] = useGetMStateLazyQuery();
  const statesOptions = convertStatesDataToOptions(statesData?.getMState || []);

  const [getCitiesQuery, { data: citiesData, loading: citiesLoading }] = useGetMCityByStateIdLazyQuery();
  // 市区町村のオプションは都道府県が選択されている場合のみ取得する
  const citiesOptions = convertCitiesDataToOptions((selectedState && citiesData?.getMCityByStateId) || []);

  const { openErrorModal } = useErrorModal();

  const memberStoreStatus = useMemberStoreStatus();
  // ブランドエラー監視
  useEffect(() => {
    if (brandDataError) {
      openErrorModal({
        message: 'ブランド一覧が取得できませんでした。時間をおいてもう一度お試しください。',
      });
    }
  }, [brandDataError, openErrorModal]);

  useEffect(() => {
    if (selectedBrand?.value && typeof loginId === 'number' && activeModal === ModalTypes.FindStores) {
      findStoresQuery({
        variables: {
          brand_id: Number(selectedBrand.value),
          my_id: loginId,
        },
      }).catch(() => {
        setActiveModal(null);
        openErrorModal({
          message: '店舗一覧が取得できませんでした。時間をおいてもう一度お試しください。',
        });
      });
      getStatesQuery({
        context: {
          clientName: 'master',
        },
      }).catch(() => {
        setActiveModal(null);
        openErrorModal({
          message: '都道府県一覧が取得できませんでした。時間をおいてもう一度お試しください。',
        });
      });
    }
  }, [selectedBrand?.value, loginId, findStoresQuery, getStatesQuery, activeModal, openErrorModal]);

  useEffect(() => {
    if (selectedState?.value) {
      getCitiesQuery({
        variables: {
          sid: Number(selectedState.value),
        },
        context: {
          clientName: 'master',
        },
      }).catch(() => {
        setActiveModal(null);
        openErrorModal({
          message: '市区町村一覧が取得できませんでした。時間をおいてもう一度お試しください。',
        });
      });
    }
  }, [selectedState, getCitiesQuery, openErrorModal]);

  const brandOptions = convertBrandDataToOptions(brandData?.getMBrandByBizId || []);

  const {
    data: groupStoresData,
    loading: groupStoresLoading,
    refetch: refetchGroupStores,
    error: groupStoresError,
  } = useGetVtGroupListByTpmemIdQuery({
    variables: { tpmem_id: loginId || 0 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupStoresError) {
      openErrorModal({
        message: 'グループ店舗一覧が取得できませんでした。時間をおいてもう一度お試しください。',
      });
    }
  }, [groupStoresError, openErrorModal]);

  const groupStoresRowData =
    groupStoresData?.getVTGroupListByTpmemId?.list.filter((d): d is Exclude<typeof d, null> => d !== null) || [];
  const storesListData = convertGroupStoresDataToListData(groupStoresRowData);

  const groupId = groupStoresData?.getVTGroupListByTpmemId.group_id || 0;

  const [apiError, setApiError] = React.useState<RequiringCallbackError>();
  const [inviteGroupMutation, { loading: inviteGroupLoading }] = useAddTGroupMutation({
    variables: {
      id: groupId,
      tpmem_id: loginId || 0,
      invite_ids: selectedGroupStores.map((store) => store.id).join(','),
    },
  });

  function inviteToGroup() {
    function onError() {
      setActiveModal(null);
      openErrorModal({
        message: 'グループ招待に失敗しました。時間をおいてもう一度お試しください。',
      });
    }
    inviteGroupMutation()
      .then((result) => {
        if (result.errors) {
          onError();
        } else {
          if (result.data === undefined || result.data === null) return;
          const r = handleMutationResult(result.data.addTGroup);
          if (r.isSuccessful) {
            setApiError(undefined);
            setActiveModal(ModalTypes.InvitationCompleted);
            refetchGroupStores();
          } else {
            setApiError(r.error);
          }
        }
      })
      .catch(() => {
        onError();
      });
  }

  const [leaveGroupMutation, { loading: leaveGroupLoading }] = useUpdateTGroupMemStatusByIdTpmemIdMutation({
    variables: {
      id: groupId,
      tpmem_id: loginId || 0,
      status: JoinStatus.LEFT,
    },
  });

  function leaveGroup() {
    leaveGroupMutation()
      .then((result) => {
        if (result.data === undefined || result.data === null) return;
        const r = handleMutationResult(result.data.updateTGroupMemStatusByIdTpmemId);
        if (r.isSuccessful) {
          setApiError(undefined);
          setActiveModal(ModalTypes.LeaveCompleted);
          refetchGroupStores();
        } else {
          setApiError(r.error);
        }
      })
      .catch(() => {
        setActiveModal(null);
        openErrorModal({
          message: 'グループ脱退に失敗しました。時間をおいてもう一度お試しください。',
        });
      });
  }

  function showDetailsModal(id: number) {
    function onError() {
      setActiveModal(null);
      openErrorModal({
        message: '店舗詳細が取得できませんでした。時間をおいてもう一度お試しください。',
      });
    }

    findVtMemberBaseQuery({
      variables: {
        id,
      },
    })
      .then((result) => {
        if (result.error) {
          onError();
        } else {
          setActiveModal(ModalTypes.StoreDetailsInfo);
        }
      })
      .catch(() => {
        onError();
      });
  }

  function onModalClose() {
    setSelectedBrand(null);
    setSelectedState(null);
    setActiveModal(null);
  }

  React.useEffect(() => {
    if (apiError) {
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
      setActiveModal(null);
    }
  }, [apiError, openErrorModal, setActiveModal]);

  const loading =
    brandDataLoading ||
    groupStoresLoading ||
    storesLoading ||
    inviteGroupLoading ||
    leaveGroupLoading ||
    statesLoading ||
    citiesLoading ||
    vtMemberBaseLoading;
  
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end

  // feat_screen_09-04-01_start
  const headerMobile = [
    {
      columnName: '',
      key: 'picture',
      width: 54,
    },
    {
      columnName: '店舗名',
      key: 'storeName',
      width: 118,
    },
    {
      columnName: '登録日',
      key: 'registeredDate',
      width: "calc(100vw - 54px - 118px - 50px - 32px)",
    },
  ];
  // feat_screen_09-04-01_end

  return (
    <>
      {loading && <Loading />}
      {
        !isMobile ? (
          <Box px={16} py={24} width="100%">
            <Box mb={16} display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                グループ店舗（{storesListData.length}）
              </Text>
              <Box display="flex" gap={8}>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <>
                    <MainButton
                      icon="wavingHand"
                      iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                      variant="secondary"
                      thin
                      onClick={() => setActiveModal(ModalTypes.Leave)}
                      disabled={!groupId || groupStoresRowData.length === 0}
                    >
                      グループ脱退
                    </MainButton>
                    <MainButton icon="addCircle" thin onClick={() => setActiveModal(ModalTypes.SelectBrand)}>
                      グループ招待
                    </MainButton>
                  </>
                )}
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box width="100%">
              <PaginationBox
                dataSize={storesListData.length}
                limit={limit}
                page={page}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
              >
                <StyledListWrapper>
                  <List
                    header={header}
                    items={generateListRow(storesListData, limit, page, (id) => showDetailsModal(id), sort)}
                    onChangeSort={handleChangeSort}
                    sort={sort}
                    width="100%"
                    rowWidth="100%"
                  />
                </StyledListWrapper>
              </PaginationBox>
            </Box>
          </Box>
        ) : (
          // feat_screen_09-04-01_start
          <Box pa={0} width="100%">
            <Box display={isShowFilter ? "flex" : "none"} flexDirection="column" alignItems='center' gap={14} pb={31} pt={16} height="max-content">
              <Text variant="h2" color="darkBlue">
                グループ店舗（{storesListData.length}）
              </Text>
              <Box display="flex" gap={8} width="100%" px={16} minHeight={32}>
                {memberStoreStatus !== MemberStoreStatus.PAUSE && (
                  <>
                    <MainButton
                      icon="wavingHand"
                      iconSize={20}
                      iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                      variant="secondary"
                      fullWidth
                      onClick={() => setActiveModal(ModalTypes.Leave)}
                      disabled={!groupId || groupStoresRowData.length === 0}
                    >
                      グループ脱退
                    </MainButton>
                    <MainButton 
                      icon="addCircle" 
                      iconSize={20}
                      fullWidth
                      onClick={() => setActiveModal(ModalTypes.SelectBrand)}
                    >
                      グループ招待
                    </MainButton>
                  </>
                )}
              </Box>
            </Box>
            <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
            <Box width="100%">
              <PaginationBox
                dataSize={storesListData.length}
                limit={limit}
                page={page}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
              >
                <StyledListWrapper isShowFilter={isShowFilter}>
                  <List
                    header={headerMobile}
                    items={generateListRow(storesListData, limit, page, (id) => showDetailsModal(id), sort, isMobile)}
                    onChangeSort={handleChangeSort}
                    sort={sort}
                    width="100%"
                    rowWidth="100%"
                  />
                </StyledListWrapper>
              </PaginationBox>
            </Box>
          </Box>
          // feat_screen_09-04-01_end
        )
      }
      <LeaveModal
        isOpen={activeModal === ModalTypes.Leave}
        onModalClose={() => setActiveModal(null)}
        onClickSubmit={() => leaveGroup()}
      />
      <LeaveCompletedModal
        isOpen={activeModal === ModalTypes.LeaveCompleted}
        onModalClose={() => setActiveModal(null)}
      />
      <SelectBrandModal
        isOpen={activeModal === ModalTypes.SelectBrand}
        onModalClose={() => onModalClose()}
        onClickNext={() => setActiveModal(ModalTypes.FindStores)}
        options={brandOptions}
        selectedBrand={selectedBrand}
        onChangeBrand={(option) => setSelectedBrand(option)}
      />
      <FindStoresModal
        isOpen={activeModal === ModalTypes.FindStores}
        onModalClose={() => onModalClose()}
        onBack={() => setActiveModal(ModalTypes.SelectBrand)}
        onClickNext={(checkedStoreIds) => {
          setSelectedGroupStores(storesList.filter((store) => checkedStoreIds.includes(store.id.toString())));
          setActiveModal(ModalTypes.ConfirmInvitation);
        }}
        onChangeState={(option) => setSelectedState(option)}
        selectedBrand={selectedBrand}
        selectedState={selectedState}
        shouldClearForm={activeModal === null}
        storesList={storesList}
        statesOptions={statesOptions}
        citiesOptions={citiesOptions}
      />
      <ConfirmInvitationModal
        isOpen={activeModal === ModalTypes.ConfirmInvitation}
        onModalClose={() => onModalClose()}
        onBack={() => setActiveModal(ModalTypes.FindStores)}
        onClickSubmit={() => inviteToGroup()}
        selectedGroupStores={selectedGroupStores}
      />
      <InvitationCompletedModal
        isOpen={activeModal === ModalTypes.InvitationCompleted}
        onModalClose={() => onModalClose()}
      />
      <StoreDetailsModal
        isOpen={activeModal === ModalTypes.StoreDetailsInfo}
        onModalClose={() => setActiveModal(null)}
        storeDetails={storeDetails}
      />
    </>
  );
}

export default GroupStores;
