import React from 'react';
import { Chip } from 'components/chip';
import { StatusName } from './const/const';

export const generateChip = (identification: number) => {
  switch (identification) {
    case StatusName.APPROVED:
      return <Chip color="okBlue">承認済</Chip>;
    case StatusName.APPLYING:
      return <Chip color="warningYellow">申請中</Chip>;
    case StatusName.DENIAL:
      return <Chip color="cautionRed">否認</Chip>;
    default:
      return <Chip color="okBlue">承認済</Chip>;
  }
};

export enum ModalStep {
  NULL = 0,
  CHECK = 1,
  COMPLETE = 2,
}
