import React from 'react';
import {styled} from '@linaria/react';
import {MemberStatus} from '../const';


const StyledBox = styled.div<{
    height: number,
    widht: number
}>`
    position: relative;
    height: ${({height}) => height}px;
    width: ${({widht}) => widht}px;
`;

const StyledBG = styled.div<{
    backgroundcolor: string;
}>`
    position: absolute;
    background-color: ${({backgroundcolor}) => backgroundcolor};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const StyledTextLine = styled.div<{
    status: number;
    isMobile: boolean;
}>`
    position: absolute;
    top: ${({status, isMobile}) => {
        if (isMobile) {
            return '14px';
        }
        switch (status) {
            case MemberStatus.PERMANENTLY_DISABLE:
            case MemberStatus.TEMPORARILY_DISABLE:
            case MemberStatus.SUSPENSION:
                return '14px';
            default:
                return '0px';
        }
    }};
    left: ${({status, isMobile}) => {
        if (isMobile) {
            return '14px';
        }
        switch (status) {
            case MemberStatus.TEMPORARILY_DISABLE:
            case MemberStatus.SUSPENSION:
                return '10px';
            default:
                return '0px';
        }
    }};
    text-align: center;
`;

export function ImgWrapper(props: {
    children: React.ReactNode;
    height: number;
    width: number;
    memberstatus: number;
    backgroundcolor: string;
    isMobile: boolean;
    text: React.ReactNode;
}) {
    const {children, height, width, memberstatus, backgroundcolor, isMobile, text} = props;
    return (
        <StyledBox height={height} widht={width}>
            <StyledBG backgroundcolor={backgroundcolor}/>
            <StyledTextLine status={memberstatus} isMobile={isMobile}>{text}</StyledTextLine>
            {children}
        </StyledBox>
    );
}
