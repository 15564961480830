import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useState } from 'react';
import * as gql from 'graphql/graphql-mw';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { ErrorModal } from 'components/errorModal';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ResultRows } from 'components/const';

enum ModalStep {
  CONFIRM = 2,
  COMPOLETE = 3,
}

export function RemoveBlockModal(props: { isOpen: boolean; onClose: () => void; refetch: () => void }) {
  const { isOpen, onClose, refetch } = props;

  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CONFIRM);

  const [isError, setIsError] = useState<boolean>(false);

  const handleErrorHappen = () => setIsError(true);
  const onModalClose = () => {
    onClose();
    setModalStep(ModalStep.CONFIRM);
    refetch();
  };

  const [deleteTBtmemBlockMutation] = gql.useDeleteTBtmemBlockMutation({
    variables: { block_id: btmemId, id: tpmemId },
  });

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const confirmContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック解除
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Text variant="body14">このバイトラベラーのブロックを解除しますか？</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen-05-01-01-09_start */
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          /* feat_screen-05-01-01-09_end */
          variant="secondary"
          onClick={() => onModalClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          /* feat_screen-05-01-01-09_start */
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          /* feat_screen-05-01-01-09_end */
          variant="warnSecondary"
          onClick={() => {
            deleteTBtmemBlockMutation()
              .then((result) => {
                if (result.data?.deleteTBtmemBlock.resultRows === ResultRows.UPDATED) {
                  setModalStep(ModalStep.COMPOLETE);
                } else {
                  onModalClose();
                  handleErrorHappen();
                }
              })
              .catch(() => {
                onModalClose();
                handleErrorHappen();
              });
          }}
        >
          解除
        </MainButton>
      </Box>
    ),
    /* feat_screen-05-01-01-09_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : 169,
    /* feat_screen-05-01-01-09_end */
  };

  const completeContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック解除 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Text variant="body14">このバイトラベラーのブロックを解除しました。</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen-05-01-01-10_start */
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          /* feat_screen-05-01-01-10_end */
          variant="secondary"
          onClick={() => {
            onModalClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    /* feat_screen-05-01-01-10_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : 169,
    /* feat_screen-05-01-01-10_end */
  };

  const modalContent = {
    [ModalStep.CONFIRM]: confirmContent,
    [ModalStep.COMPOLETE]: completeContent,
  };

  return isError ? (
    <ErrorModal
      isOpen={isError}
      onClose={() => setIsError(false)}
      title="エラー"
      message="ブロック解除に失敗しました。"
    />
  ) : (
    <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />
  );
}
