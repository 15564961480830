import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import * as gql from 'graphql/graphql-mw';
import { BaseInfo, PenaltyRate } from 'pages/s05-01/[id]/components';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { SubMenuItemsType } from '../type';

export function RestrictedContent(
  props: {
    refetchHeaderData: (variables?: Partial<gql.Exact<{
      id: number;
      tpmem_id: number;
    }>> | undefined) => Promise<ApolloQueryResult<gql.GetVbMemberBaseByIdQuery>>
    /* feat_screen_05-02-01-02_add_props_start */
    blogFlag?: boolean
      /* feat_screen_05-02-01-02_add_props_end */  
  }
){
  const { 
    refetchHeaderData,
    /* feat_screen_05-02-01-02_add_props_start */
    blogFlag
    /* feat_screen_05-02-01-02_add_props_end */  
  } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  /* feat_screen_05-02-01-02_start */
  const [tab, setTab] = React.useState<number>(0);
  /* feat_screen_05-02-01-02_end */

  const subMenuItems: SubMenuItemsType = [
    { label: '基本情報', content: <BaseInfo 
        refetchHeaderData={()=> refetchHeaderData()}
        /* feat_screen_05-01-01-08_add_props_start */
        blogFlag={blogFlag}
        /* feat_screen_05-01-01-08_add_props_end */
    /> },
    { label: 'ペナルティ率', content: <PenaltyRate 
        /* feat_screen_05-01-01-08_add_props_start */
        blogFlag={blogFlag}
        /* feat_screen_05-01-01-08_add_props_end */
    /> },
  ];

  return !isMobile ? (
    <Card overflow="hidden">
      <SubMenu items={subMenuItems} />
    </Card>
  ) : (
    /* feat_screen_05-02-01-02_start */
    <Card>
      <Dropdown items={subMenuItems} tab={tab} setTab={setTab} fullWidth/>
    </Card>
    /* feat_screen_05-02-01-02_end */
  );
};