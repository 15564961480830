/**
 * Figma ID: 04-03-06-01, 04-03-06-02, 04-03-06-03, 04-03-07-01, 04-03-07-02, 04-03-07-03
 * 名称: 募集テンプレート新規作成
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from 'hooks/authProvider';
import * as gql from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Modal, ImageModal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { RadioGroup } from 'components/radioGroup';
import { Box } from 'components/box';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { Info } from 'components/info';
import { FormData } from 'pages/s04-01-01/common/formState';
import { RhfInput } from 'components/rhfInput';
import { RhfTextarea } from 'components/rhfTextarea';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { TextSet } from 'pages/s04-01-01/[id]/components/textSet';
import { TextLink } from 'components/textLink';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import { ResultRows } from 'components/const';
import { useNoticeOfEmploymentPdfPreview } from 'pdfComponent';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useCreateTemplateConnectApi, usePostTemplateConnectApi } from '../../common/hooks';
import { getPdfPreviewProps } from '../../common/utils';

enum ModalStep {
  FORM = 1,
  PREVIEW = 2,
  DONE = 3,
}

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalComponent({ isOpen, onClose }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<FormData>({ reValidateMode: 'onSubmit' });

  /**
   * [API] テンプレート取得
   */
  const {
    data: templateData,
    loading: templateLoading,
    error: templateError,
  } = useCreateTemplateConnectApi(Number(tpmemId));

  const dispData = templateData?.getSTpmemNoticetplByTpmemId as gql.STpmemNoticetpl;
  const hasTpmemId = !!dispData?.tpmem_id;

  /**
   * [API] テンプレート保存
   */
  const { setFixedPostFormData, addupdSTpmemNoticetplByTpmemIdMutation } = usePostTemplateConnectApi(Number(tpmemId));

  const [fixedFormData, setFixedFormData] = useState<FormData>({});
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.FORM);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const [radioAttachment, setAttachment] = useState<string>('0');
  const { openErrorModal } = useErrorModal();

  /**
   * 添付ファイルのパス、ファイル名をセット
   */
  const attachedFilePath = dispData?.attached_file;
  const attachedFileName = dispData?.attached_name || '';

  /**
   * 添付ファイル有無の初期値をセット
   */
  useEffect(() => {
    if (!dispData) return;
    if (dispData?.attached_file_on) {
      setAttachment('1');
    } else {
      setAttachment('0');
    }
  }, [dispData, setAttachment]);

  const onClickConfirm = handleSubmit((data) => {
    /**
     * Preview用のデータをセット
     */
    setFixedFormData(data);
    /**
     * Mutation用のデータをセット
     */
    setFixedPostFormData(data);
    setModalStep(ModalStep.PREVIEW);
  });

  const clearEvent = (isDone: boolean) => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.FORM);

    /**
     * フォームデータをリセット
     */
    reset();
    onClose();

    /**
     * 画面をリロード
     */
    if (isDone) navigate(0);
  };

  const seveForm = () => {
    /**
     * フォーム送信Mutation
     */
    addupdSTpmemNoticetplByTpmemIdMutation()
      .then((result) => {
        if (!result.errors) {
          if (result.data?.addupdSTpmemNoticetplByTpmemId.resultRows === ResultRows.NOT_UPDATED) {
            clearEvent(false);
            openErrorModal({
              message: 'テンプレートの作成に失敗しました',
            });
          } else {
            setModalStep(ModalStep.DONE);
          }
        } else {
          clearEvent(false);
          openErrorModal({
            message: 'テンプレートの作成に失敗しました',
          });
        }
      })
      .catch(() => {
        clearEvent(false);
        openErrorModal({
          message: 'テンプレートの作成に失敗しました',
        });
      });
  };

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf: previewNoticePdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = () => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFパラメータ取得
        const pdfProps = getPdfPreviewProps(fixedFormData);

        // PDFプレビュー
        const result = await previewNoticePdf(Number(auth?.tpmemId), pdfProps);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          onClose();
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        onClose();
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  const displayModal = () => {
    // 先にデータを取得している為ここでエラーチェック
    if (ModalStep.FORM === modalStep && templateError && isOpen) {
      onClose();
      openErrorModal({ message: '募集テンプレートの取得に失敗しました。' });
    }
    switch (modalStep) {
      case ModalStep.FORM:
        return {
          // feat screen 04-03-06-01 start
          width: !isMobile ? 800 : "100%",
          height: !isMobile ? "80%" : "72.5%",
          // feat screen 04-03-06-01 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              {hasTpmemId === false ? (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート新規作成
                </Text>
              ) : (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート編集
                </Text>
              )}
            </Box>
          ),
          // feat screen 04-03-06-01 start
          content: !isMobile ? (
            <Box pr={16}>
              <Box mb={18}>
                {hasTpmemId === false ? (
                  <Text variant="body14">
                    募集情報テンプレートを作成します。項目内容を入力して確認を押してください。
                  </Text>
                ) : (
                  <Text variant="body14">
                    募集情報テンプレートを編集します。項目内容を入力して確認を押してください。
                  </Text>
                )}
              </Box>
              <Box mb={24}>
                <TextSet
                  label="業務詳細"
                  content={
                    dispData?.description ? (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.description || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="働くための条件"
                  content={
                    dispData?.conditions ? (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.conditions || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="注意事項"
                  content={
                    dispData?.caution ? (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.caution || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="持ち物"
                  content={
                    dispData?.belongings ? (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.belongings || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="アクセス"
                  content={
                    dispData?.access ? (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.access || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={156}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="メイン画像"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={8}>
                      {!dispData?.image1 &&
                        !dispData?.image2 &&
                        !dispData?.image3 &&
                        !dispData?.image4 &&
                        !dispData?.image5 &&
                        'なし'}
                      {dispData?.image1 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image1_flag" control={control} defaultValue={dispData?.image1_on} />
                          </div>
                        </div>
                      )}
                      {dispData?.image2 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image2_flag" control={control} defaultValue={dispData?.image2_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image3 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image3_flag" control={control} defaultValue={dispData?.image3_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image4 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image4_flag" control={control} defaultValue={dispData?.image4_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image5 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image5_flag" control={control} defaultValue={dispData?.image5_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image1 && (
                        <RhfInput
                          name="image1_path"
                          control={control}
                          defaultValue={dispData?.image1}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image2 && (
                        <RhfInput
                          name="image2_path"
                          control={control}
                          defaultValue={dispData?.image2}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image3 && (
                        <RhfInput
                          name="image3_path"
                          control={control}
                          defaultValue={dispData?.image3}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image4 && (
                        <RhfInput
                          name="image4_path"
                          control={control}
                          defaultValue={dispData?.image4}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image5 && (
                        <RhfInput
                          name="image5_path"
                          control={control}
                          defaultValue={dispData?.image5}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      <ImageModal
                        src={modalImageSrc}
                        isOpen={isImageModalOpen}
                        onClose={() => setIsImageModalOpen(false)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mb={24}>
                <TextSet
                  label="添付ファイル"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
                      {attachedFilePath ? (
                        <>
                          <div>
                            <TextLink href={attachedFilePath || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          </div>
                          <div>
                            <RadioGroup
                              name="radioAttachment"
                              onChange={(e) => {
                                if (!setValue) return;
                                setAttachment(e.target.value);
                                setValue('attachment_flag', e.target.value);
                              }}
                              options={[
                                {
                                  label: '添付する',
                                  value: '1',
                                },
                                {
                                  label: '添付しない',
                                  value: '0',
                                },
                              ]}
                              value={radioAttachment}
                            />
                          </div>
                        </>
                      ) : (
                        'なし'
                      )}
                    </Box>
                  }
                />
                {attachedFilePath && (
                  <>
                    <RhfInput
                      name="attachment_flag"
                      control={control}
                      defaultValue="1"
                      placeholder=""
                      type="hidden"
                      width={30}
                    />
                    <RhfInput
                      name="attached_file"
                      control={control}
                      defaultValue={attachedFilePath}
                      placeholder=""
                      type="hidden"
                      width={200}
                    />
                  </>
                )}
              </Box>
            </Box>
          ) : (
            // Responsive Component
            <Box>
              <Box mt={2} mb={14}>
                {hasTpmemId === false ? (
                  <Text variant="body14">
                    募集情報テンプレートを作成します。項目内容を入力して確認を押してください。
                  </Text>
                ) : (
                  <Text variant="body14">
                    募集情報テンプレートを編集します。項目内容を入力して確認を押してください。
                  </Text>
                )}
              </Box>
              <Box mb={9}>
                <TextSet
                  label="業務詳細"
                  content={
                    dispData?.description ? (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.description || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="description"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="働くための条件"
                  content={
                    dispData?.conditions ? (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.conditions || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="conditions"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="注意事項"
                  content={
                    dispData?.caution ? (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.caution || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="caution"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="持ち物"
                  content={
                    dispData?.belongings ? (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.belongings || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="belongings"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="アクセス"
                  content={
                    dispData?.access ? (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue={dispData?.access || ''}
                      />
                    ) : (
                      <RhfTextarea
                        name="access"
                        control={control}
                        height={98}
                        fullWidth
                        placeholder=""
                        defaultValue=""
                      />
                    )
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="メイン画像"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={8}>
                      {!dispData?.image1 &&
                        !dispData?.image2 &&
                        !dispData?.image3 &&
                        !dispData?.image4 &&
                        !dispData?.image5 &&
                        'なし'}
                      {dispData?.image1 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image1 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image1 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image1_flag" control={control} defaultValue={dispData?.image1_on} />
                          </div>
                        </div>
                      )}
                      {dispData?.image2 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image2 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image2 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image2_flag" control={control} defaultValue={dispData?.image2_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image3 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image3 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image3 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image3_flag" control={control} defaultValue={dispData?.image3_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image4 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image4 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image4 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image4_flag" control={control} defaultValue={dispData?.image4_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image5 && (
                        <div style={{ position: 'relative' }}>
                          <ImgWrapper
                            onClick={() => {
                              setModalImageSrc(dispData?.image5 || '');
                              setIsImageModalOpen(true);
                            }}
                          >
                            <img
                              style={{ width: '126px', height: '94.5px' }}
                              src={dispData?.image5 || ''}
                              alt="サムネイル"
                            />
                          </ImgWrapper>
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <RhfCheckbox name="image5_flag" control={control} defaultValue={dispData?.image5_on} />
                          </div>
                        </div>
                      )}

                      {dispData?.image1 && (
                        <RhfInput
                          name="image1_path"
                          control={control}
                          defaultValue={dispData?.image1}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image2 && (
                        <RhfInput
                          name="image2_path"
                          control={control}
                          defaultValue={dispData?.image2}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image3 && (
                        <RhfInput
                          name="image3_path"
                          control={control}
                          defaultValue={dispData?.image3}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image4 && (
                        <RhfInput
                          name="image4_path"
                          control={control}
                          defaultValue={dispData?.image4}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      {dispData?.image5 && (
                        <RhfInput
                          name="image5_path"
                          control={control}
                          defaultValue={dispData?.image5}
                          placeholder=""
                          type="hidden"
                          width={200}
                        />
                      )}

                      <ImageModal
                        src={modalImageSrc}
                        isOpen={isImageModalOpen}
                        onClose={() => setIsImageModalOpen(false)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mb={9}>
                <TextSet
                  label="添付ファイル"
                  content={
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={16}>
                      {attachedFilePath ? (
                        <>
                          <div>
                            <TextLink href={attachedFilePath || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          </div>
                          <div>
                            <RadioGroup
                              name="radioAttachment"
                              onChange={(e) => {
                                if (!setValue) return;
                                setAttachment(e.target.value);
                                setValue('attachment_flag', e.target.value);
                              }}
                              options={[
                                {
                                  label: '添付する',
                                  value: '1',
                                },
                                {
                                  label: '添付しない',
                                  value: '0',
                                },
                              ]}
                              value={radioAttachment}
                            />
                          </div>
                        </>
                      ) : (
                        'なし'
                      )}
                    </Box>
                  }
                />
                {attachedFilePath && (
                  <>
                    <RhfInput
                      name="attachment_flag"
                      control={control}
                      defaultValue="1"
                      placeholder=""
                      type="hidden"
                      width={30}
                    />
                    <RhfInput
                      name="attached_file"
                      control={control}
                      defaultValue={attachedFilePath}
                      placeholder=""
                      type="hidden"
                      width={200}
                    />
                  </>
                )}
              </Box>
            </Box>
          ),
          // feat screen 04-03-06-01 end
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-06-01 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent(false)}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickConfirm()}>
                確認
              </MainButton>
              {/* feat screen 04-03-06-01 end */}
            </Box>
          ),
        };
      case ModalStep.PREVIEW:
        return {
          // feat screen 04-03-06-02 start
          width: !isMobile ? 800 : "100%",
          height: !isMobile ? "80%" : "72.5%",
          // feat screen 04-03-06-02 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              {hasTpmemId === false ? (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート新規作成 プレビュー
                </Text>
              ) : (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート編集 プレビュー
                </Text>
              )}
              {/* feat screen 04-03-06-02 start */}
              {
                !isMobile && 
                <MainButton
                  onClick={() => {
                    onClickPreview();
                  }}
                  variant="secondary"
                  width={200}
                >
                  労働条件通知書プレビュー
                </MainButton>
              }
              {/* feat screen 04-03-06-02 end */}
            </Box>
          ),
          // feat screen 04-03-06-02 start
          content: !isMobile ? (
            <Box>
              <Box pr={8} pl={8}>
                <Box mb={18}>
                  <Text variant="body14">募集情報テンプレートを確認して、保存を押してください。</Text>
                </Box>
                <Box mb={16}>
                  <Text color="darkBlue" variant="h2">
                    募集情報
                  </Text>
                </Box>
                <Divider length={0} option="horizontal" />
                <Info
                  items={[
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.description)}
                        </Text>
                      ),
                      label: '業務詳細',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.conditions)}
                        </Text>
                      ),
                      label: '働くための条件',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.caution)}
                        </Text>
                      ),
                      label: '注意事項',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.belongings)}
                        </Text>
                      ),
                      label: '持ち物',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.access)}
                        </Text>
                      ),
                      label: 'アクセス',
                    },
                    {
                      content: (
                        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                          {fixedFormData?.image1_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image1_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image1_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image2_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image2_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image2_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image3_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image3_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image3_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image4_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image4_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image4_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image5_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image5_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image5_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                        </div>
                      ),
                      label: 'メイン画像',
                    },
                    {
                      content: (
                        <Box>
                          {fixedFormData?.attachment_flag === '1' ? (
                            <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          ) : (
                            <Text variant="body14">添付無し</Text>
                          )}
                        </Box>
                      ),
                      label: '添付ファイル',
                    },
                  ]}
                />
              </Box>
            </Box>
          ) : (
            // Responsive Component
            <Box>
              <Box>
                <Box mb={24} display="flex" justifyContent="center">
                  <MainButton
                    onClick={() => {
                      onClickPreview();
                    }}
                    variant="secondary"
                    width={212}
                  >
                    労働条件通知書プレビュー
                  </MainButton>
                </Box>
                <Box pt={2} mb={16}>
                  <Text display="block" variant="body14">募集情報テンプレートを確認して、保存を押してください。</Text>
                </Box>
                <Box mb={13}>
                  <Text display="block" bold color="darkBlue" variant="body14">
                    募集情報
                  </Text>
                </Box>
                <Divider length={0} option="horizontal" />
                <Info
                  items={[
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.description)}
                        </Text>
                      ),
                      label: '業務詳細',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.conditions)}
                        </Text>
                      ),
                      label: '働くための条件',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.caution)}
                        </Text>
                      ),
                      label: '注意事項',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.belongings)}
                        </Text>
                      ),
                      label: '持ち物',
                    },
                    {
                      content: (
                        <Text wordBreak="break-all" variant="body14">
                          {charcterReplaceBr(fixedFormData?.access)}
                        </Text>
                      ),
                      label: 'アクセス',
                    },
                    {
                      content: (
                        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: "flex-end" }}>
                          {fixedFormData?.image1_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image1_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image1_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image2_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image2_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image2_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image3_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image3_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image3_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image4_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image4_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image4_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                          {fixedFormData?.image5_flag && (
                            <ImgWrapper
                              onClick={() => {
                                setModalImageSrc(fixedFormData?.image5_path || '');
                                setIsImageModalOpen(true);
                              }}
                            >
                              <img
                                style={{ width: '126px', height: '94.5px' }}
                                src={fixedFormData?.image5_path || ''}
                                alt="サムネイル"
                              />
                            </ImgWrapper>
                          )}
                        </div>
                      ),
                      label: 'メイン画像',
                    },
                    {
                      content: (
                        <Box>
                          {fixedFormData?.attachment_flag === '1' ? (
                            <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
                              {attachedFileName}
                            </TextLink>
                          ) : (
                            <Text variant="body14">添付無し</Text>
                          )}
                        </Box>
                      ),
                      label: '添付ファイル',
                    },
                  ]}
                />
              </Box>
            </Box>
          ),
          // feat screen 04-03-06-02 end
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-06-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.FORM)}>
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => seveForm()}>
                保存
              </MainButton>
              {/* feat screen 04-03-06-02 end */}
            </Box>
          ),
        };
      case ModalStep.DONE:
        return {
          // feat screen 04-03-06-02 start
          width: !isMobile ? 384 : "100%",
          height: !isMobile ? 220 : "unset",
          // feat screen 04-03-06-02 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              {hasTpmemId === false ? (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート新規作成 完了
                </Text>
              ) : (
                <Text variant="h2" color="darkBlue">
                  募集テンプレート編集 完了
                </Text>
              )}
            </Box>
          ),
          // feat screen 04-03-06-03 start
          content: !isMobile ?(
            <Box>
              {hasTpmemId === false ? (
                <Text variant="body14">
                  テンプレート作成が完了しました。
                  <br />
                  募集テンプレートボタンから編集できます。
                </Text>
              ) : (
                <Text variant="body14">
                  テンプレート作成が完了しました。
                  <br />
                  募集テンプレートボタンから再度編集できます。
                </Text>
              )}
            </Box>
          ) : (
            // Responsive Component
            <Box>
              {hasTpmemId === false ? (
                <Text variant="body14">
                  テンプレート作成が完了しました。
                  募集テンプレートボタンから編集できます。
                </Text>
              ) : (
                <Text variant="body14">
                  テンプレート作成が完了しました。
                  募集テンプレートボタンから再度編集できます。
                </Text>
              )}
            </Box>
          // feat screen 04-03-06-03 end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-06-02 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent(true)}>
                閉じる
              </MainButton>
              {/* feat screen 04-03-06-02 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  };

  const modalContents = displayModal();

  if (templateLoading) {
    return <Loading />;
  }

  return (
    <Modal
      width={modalContents?.width}
      height={modalContents?.height}
      header={modalContents?.header}
      content={modalContents?.content}
      footer={modalContents?.footer}
      isOpen={isOpen}
      onClose={() => clearEvent(false)}
    />
  );
}
