import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { useAuth } from 'hooks/authProvider';
import React, { useEffect, useMemo } from 'react';
import * as gql from 'graphql/graphql-mw';
import { useParams } from 'react-router-dom';
import { HistoryListItemsType } from 'pages/s05-01/type';
import { Loading } from 'components/loading/loading';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { handleMutationResult } from 'error';

import SelectGiftModal from 'pages/s05-01/modal/thanksGiftModal/SelectGiftModal';
import ConfirmGiftModal from 'pages/s05-01/modal/thanksGiftModal/ConfirmGiftModal';
import CompleteGiftModal from 'pages/s05-01/modal/thanksGiftModal/CompleteGiftModal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { PaymentType } from 'pages/s05-01/const';
import useIsMobile from 'hooks/responsive/useIsMobile';

enum ModalTypes {
  SELECT_GIFT = 'selectGift',
  CONFIRM_GIFT = 'confirmGift',
  COMPLETE = 'complete',
}

export function PaymentThanksGift() {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const [activeModalType, setActiveModalType] = React.useState<ModalTypes | null>(null);

  const [selectedGiftCode, setSelectedGiftCode] = React.useState<string>('');
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<string>('');

  const { openErrorModal } = useErrorModal();
  /* feat_common_responsive_useMobile_logic_start */ 
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const {
    data: egiftData,
    loading: egiftInfoLoading,
    error: egiftInfoError,
    refetch: refetchWallet,
  } = gql.useGetSEgiftCodeWalletByIdQuery({
    variables: { id: tpmemId },
  });

  // Thanksギフトの選択OptionをAPIから取得
  const giftCodeOptions = useMemo(() => {
    const egiftList = egiftData?.getSEgiftCodeWalletById?.egift || [];

    return egiftList.map((item) => ({
      label: item?.name || '',
      value: String(item?.egift_code),
      price: item?.price || 0,
      issuance_fee: item?.issuance_fee || 0,
    }));
  }, [egiftData]);

  const selectedGiftName = useMemo(
    () => giftCodeOptions.find(({ value }) => value === selectedGiftCode)?.label ?? '',
    [giftCodeOptions, selectedGiftCode]
  );

  const selectedGiftPrice = useMemo(
    () =>
      egiftData?.getSEgiftCodeWalletById?.egift?.find((item) => Number(selectedGiftCode) === item?.egift_code)?.price ||
      0,
    [selectedGiftCode, egiftData?.getSEgiftCodeWalletById?.egift]
  );

  const selectedGiftIssuanceFee = useMemo(
    () =>
      egiftData?.getSEgiftCodeWalletById?.egift?.find((item) => Number(selectedGiftCode) === item?.egift_code)
        ?.issuance_fee || 0,
    [selectedGiftCode, egiftData?.getSEgiftCodeWalletById?.egift]
  );

  // 現在ウォレット残高
  const walletAmount = useMemo(() => egiftData?.getSEgiftCodeWalletById?.wallet?.price || 0, [egiftData]);

  // EGift追加処理
  const [apiError, setApiError] = React.useState<RequiringCallbackError>();
  const [addEGift, { loading: addEGiftLoading, error: addEGiftError }] = gql.useAddUEgiftMutation({
    onCompleted: (result) => {
      const r = handleMutationResult(result.addUEgift);
      if (r.isSuccessful) {
        setApiError(undefined);
      } else {
        setApiError(r.error);
      }
    },
  });
  React.useEffect(() => {
    if (apiError) {
      setActiveModalType(null);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal, setActiveModalType]);

  useEffect(() => {
    if (addEGiftError) {
      setActiveModalType(null);
      openErrorModal({ message: 'Thanksギフトの支給に失敗しました' });
    }
  }, [addEGiftError, openErrorModal]);

  const {
    data: { getVTBizBtmemInfoByTpmemIdBtmemId: { logging } } = {
      getVTBizBtmemInfoByTpmemIdBtmemId: {
        logging: {} as gql.TLogging[],
      },
    },
    loading,
    error: loggingError,
    refetch: refetchList,
  } = gql.useGetVtBizBtmemInfoByTpmemIdBtmemIdQuery({
    variables: { tpmem_id: tpmemId, btmem_id: btmemId },
  });

  React.useEffect(() => {
    if (egiftInfoError || loggingError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [egiftInfoError, loggingError, openErrorModal]);

  // Eギフト支給処理
  const payEGift = () => {
    (async () => {
      // Eギフト追加
      // use_walletはウォレットの場合はwalletでの支払額を設定、振替の場合は0を設定する
      const result = await addEGift({
        variables: {
          egift_code: Number(selectedGiftCode),
          btmem_id: btmemId,
          issue_id: tpmemId,
          use_wallet: selectedPaymentType === PaymentType.Wallet ? selectedGiftPrice : 0,
        },
      });

      // 失敗したらエラー表示
      if (!result.data?.addUEgift.success) {
        setActiveModalType(null);
        openErrorModal({ message: 'Thanksギフトの支給に失敗しました' });
        return;
      }

      // 一覧更新
      await refetchList();
      // ウォレット残高更新
      await refetchWallet();
      // 支給成功
      setActiveModalType(ModalTypes.COMPLETE);
    })();
  };

  const items: HistoryListItemsType = React.useMemo(
    () =>
      Array.from(logging).map(({ matter, log_date }, key) => ({
        uniqueKey: key,
        text: matter,
        dateTime: log_date || '',
      })),
    [logging]
  );

  return (
    <>
      {(loading || egiftInfoLoading || addEGiftLoading) && <Loading />}
      {
        !isMobile ? (
          <Box px={16} display="flex" flex="auto" flexDirection="column">
            <Box display="flex" flexDirection="row" py={16} justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="h2" color="darkBlue">
                  Thanksギフト支給
                </Text>
                <Text variant="caption12" color="darkBlue">
                  所属のバイトラベラーにプレゼントをあげよう！
                </Text>
              </Box>
              <MainButton
                icon="redeem"
                iconColor="white"
                width={188}
                thin
                onClick={() => setActiveModalType(ModalTypes.SELECT_GIFT)}
              >
                Thanksギフト支給
              </MainButton>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="column" overflow="auto" py={8}>
              <Box height="calc(100vh - 290px)" overflow="scroll">
                {items.map((item) => (
                  <>
                    <Box display="flex" flexDirection="column" gap={4} py={8}>
                      <Text variant="body14">{item.text}</Text>
                      <Text variant="caption11">{item.dateTime}</Text>
                    </Box>
                    <Divider option="horizontal" />
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        ): (
          /* feat_screen_05-01-04-01_start */
          <Box width="calc(100vw - 32px)">
            <Box display="flex" flexDirection="column" px={16} height="calc(100svh - 380px)">
              <Box display="flex" flexDirection="column" gap={15} py={16} alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Text variant="h2" color="darkBlue">
                    Thanksギフト支給
                  </Text>
                  <Text variant="caption12" color="darkBlue">
                    所属のバイトラベラーにプレゼントをあげよう！
                  </Text>
                </Box>
                <MainButton
                  icon="redeem"
                  iconColor="white"
                  width={181}
                  thin={false}
                  onClick={() => setActiveModalType(ModalTypes.SELECT_GIFT)}
                >
                  Thanksギフト支給
                </MainButton>
              </Box>
              <Divider option="horizontal" />
              <Box display="flex" flexDirection="column" overflow="auto">
                <Box height="calc(100svh - 462px)" overflow="scroll">
                  {items.map((item) => (
                    <>
                      <Box display="flex" flexDirection="column" gap={4} pt={14.5} pb={14.5}>
                        <Text variant="body14">{item.text}</Text>
                        <Text variant="caption11">{item.dateTime}</Text>
                      </Box>
                      <Divider option="horizontal" />
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          /* feat_screen_05-01-04-01_end */
        )
      }
      <SelectGiftModal
        isOpen={activeModalType === ModalTypes.SELECT_GIFT}
        onModalClose={() => setActiveModalType(null)}
        onClickNext={(giftCode: string, paymentType: string) => {
          setSelectedGiftCode(giftCode);
          setSelectedPaymentType(paymentType);
          setActiveModalType(ModalTypes.CONFIRM_GIFT);
        }}
        giftCodeOptions={giftCodeOptions}
        walletAmount={walletAmount}
        shouldInitializeForm={activeModalType === null}
      />
      <ConfirmGiftModal
        isOpen={activeModalType === ModalTypes.CONFIRM_GIFT}
        onModalClose={() => setActiveModalType(null)}
        onClickBack={() => setActiveModalType(ModalTypes.SELECT_GIFT)}
        onClickSubmit={() => payEGift()}
        giftTypeName={selectedGiftName}
        giftTypePrice={selectedGiftPrice}
        giftTypeIssuanceFee={selectedGiftIssuanceFee}
        walletAmount={walletAmount}
        paymentType={selectedPaymentType}
      />
      <CompleteGiftModal
        isOpen={activeModalType === ModalTypes.COMPLETE}
        onModalClose={() => setActiveModalType(null)}
      />
    </>
  );
}
