/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import LocalModal from './Modal';

/**
 * Figma ID
 * 06-03-04
 * 否認
 */

interface Props {
  step: number;
  onModalClose: () => void;
  onClickSubmit: () => void;
}

export function RejectionModal({ step, onClickSubmit, onModalClose }: Props) {
  return LocalModal({
    title: '否認',
    contentText: (
      <>
        このユーザーの所属店舗登録申請を否認しますか？
        <br />
        この操作は取り消せません。
      </>
    ),
    submitText: '否認',
    step,
    onClickSubmit,
    onModalClose,
  });
}
