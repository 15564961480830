import { MainMenu } from 'components/mainMenuBar/types';

// TODO: selectedのときなどアイコンの色がデザインとずれている。Iconコンポーネントで色を変えられるようにしたい
export const MemberStoreMainMenu: MainMenu[] = [
  {
    pageNo: 1,
    title: 'HOME',
    icon: { normal: 'top', selected: 'top' },
    link: '/',
    matchedRoutes: ['/'],
  },
  {
    pageNo: 2,
    title: '募集・採用',
    icon: { normal: 'personSearch', selected: 'personSearch' },
    matchedRoutes: ['/s04-01-01', '/s04-01-01/:id', '/s04-02', '/s04-02/:id', '/s04-03'],
    accordionList: [
      {
        accordionTitle: 'バイト募集',
        accordionLink: '/s04-01-01',
        matchedRoutes: ['/s04-01-01', '/s04-01-01/:id'],
      },
      {
        accordionTitle: '成約バイト状況',
        accordionLink: '/s04-02',
        matchedRoutes: ['/s04-02', '/s04-02/:id'],
      },
      {
        accordionTitle: '逆オファー',
        accordionLink: '/s04-03',
        matchedRoutes: ['/s04-03'],
      },
    ],
  },
  {
    pageNo: 3,
    title: 'バイトラベラー',
    icon: { normal: 'hail', selected: 'hail' },
    link: '',
    matchedRoutes: ['/s05-01', '/s05-01/:id', '/s05-02', '/s05-02/:id', '/s06', '/s06/:id', '/s07-02'],
    accordionList: [
      { accordionTitle: '自店・サブ一覧', accordionLink: '/s05-01', matchedRoutes: ['/s05-01', '/s05-01/:id'] },
      { accordionTitle: '所属登録対応', accordionLink: '/s06', matchedRoutes: ['/s06', '/s06/:id'] },
      { accordionTitle: '他店/一般一覧', accordionLink: '/s05-02', matchedRoutes: ['/s05-02', '/s05-02/:id'] },
      { accordionTitle: '利用明細閲覧', accordionLink: '/s07-02', matchedRoutes: ['/s07-02'] },
    ],
  },
  {
    pageNo: 4,
    title: 'ウォレット',
    icon: { normal: 'payment', selected: 'payment' },
    link: '/s07-01',
    matchedRoutes: ['/s07-01'],
  },
  {
    pageNo: 5,
    title: '各種設定',
    icon: { normal: 'settings', selected: 'settings' },
    link: '/s08',
    matchedRoutes: ['/s08'],
  },
  {
    pageNo: 6,
    title: 'マイページ',
    icon: { normal: 'store', selected: 'store' },
    link: '/s09',
    matchedRoutes: ['/s09'],
  },
];
