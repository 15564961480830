import { saveAs } from 'file-saver';

export const downloadImage = (imageName: string, imageUrl: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', imageUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    saveAs(xhr.responseURL, imageName);
  };
  xhr.send();
};
