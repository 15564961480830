import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { DatePicker } from 'components/datePicker';
import { List } from 'components/list';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { useAuth } from 'hooks/authProvider';
import React, { useMemo, useEffect, useState } from 'react';
import * as gql from 'graphql/graphql-mw';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useNavigate } from 'react-router-dom';
import { isAfter, isSameDay, isBefore } from 'date-fns';
import { ListHeaderType, ListItemsType } from '../type';

const StyledText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #969696;
  /* feat screen 07-02-02 start */
  @media only screen and (max-width: 768px) {
    padding: 0 8px;
  }
  /* feat screen 07-02-02 end */
`;

/* feat screen 07-02-02 start */
const StyledFlexWrap = styled.div`
  @media only screen and (max-width: 350px) {
    > div {
      flex-wrap: wrap;
    }
  }
`;
/* feat screen 07-02-02 end */

export function AdmissionFees() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  const navigate = useNavigate();
  const auth = useAuth();
  const tpmemId = auth?.tpmemId;

  if (tpmemId === null) {
    navigate('/signin');
  }

  const [formState, setFormState] = React.useState<{
    minDate: Date | null | undefined;
    maxDate: Date | null | undefined;
  }>();
  const [dateRangePicker, setDateRangePicker] = React.useState<{
    minDate: Date | null | undefined;
    maxDate: Date | null | undefined;
  }>();
  const handleFilterClear = () => {
    setDateRangePicker({
      minDate: null,
      maxDate: null,
    });
    setFormState({
      minDate: null,
      maxDate: null,
    });
  };

  const {
    data: { getVTDepositsListByTpmemId } = {
      getVTDepositsListByTpmemId: [] as gql.VtDepositsList[],
    },
    loading,
    error: getVtDepositsListByTpmemIdError,
  } = gql.useGetVtDepositsListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (getVtDepositsListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtDepositsListByTpmemIdError, openErrorModal]);

  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const listHeader: ListHeaderType = useMemo(
    () => [
      // feat_screen_07-02-01_04 start
      { key: 'work_day', width: !isMobile ? 120 : 107, columnName: '日付' },
      { key: 'education', width: !isMobile ? 98 : 115, columnName: '育成料' },
      // feat_screen_07-02-01_04 end
      { key: 'deposit', width: 136, columnName: 'ウォレット入金額' },
      { key: 'bonus', width: 152, columnName: 'バイトラボーナス金額' },
    ],
    // feat_screen_07-02-01_04 start
    [isMobile]
    // feat_screen_07-02-01_04 end
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading || getVTDepositsListByTpmemId.length === 0) return [];
    let items = getVTDepositsListByTpmemId;

    if (formState?.minDate) {
      items = items.filter(({ work_date }) => {
        if (!work_date || !formState.minDate) return true;
        const regex = /(\d{4})-(\d{2})-(\d{2})/;
        const matches = work_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return (
            isSameDay(new Date(year, month - 1, day), formState.minDate) ||
            isAfter(new Date(year, month - 1, day), formState.minDate)
          );
        }
        return false;
      });
    }
    if (formState?.maxDate) {
      items = items.filter(({ work_date }) => {
        if (!work_date || !formState.maxDate) return true;
        const regex = /(\d{4})-(\d{2})-(\d{2})/;
        const matches = work_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return (
            isSameDay(new Date(year, month - 1, day), formState.maxDate) ||
            isBefore(new Date(year, month - 1, day), formState.maxDate)
          );
        }
        return false;
      });
    }

    if (sort) {
      if (sort.key === 'work_day') {
        items = orderBy(items, sort.key, sort.direction);
      } else if (sort.key === 'education') {
        // 育成料 数値変換
        items = orderBy(items, (item) => parseInt(item.education?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else if (sort.key === 'deposit') {
        // ウォレット入金額 数値変換
        items = orderBy(items, (item) => parseInt(item.deposit?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else if (sort.key === 'bonus') {
        // バイトラボーナス金額 数値変換
        items = orderBy(items, (item) => parseInt(item.bonus?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      work_day: item.work_day,
      education: `¥${item.education ?? 0}`,
      deposit: `¥${item.deposit ?? 0}`,
      bonus: `¥${item.bonus ?? 0}`,
      uniqueKey: key,
    }));
  }, [getVTDepositsListByTpmemId, sort, formState, loading]);

  return (
    <>
      {loading && <Loading />}
      {/* feat_screen_07-02-01_04 start */}
      {!isMobile ? (
        <Box display="flex" flexDirection="column" flex="auto">
          <Box display="flex" flexDirection="row" height={64} alignItems="center" gap={16} px={16}>
            <DatePicker
              onChange={(value) => setDateRangePicker({ minDate: value, maxDate: dateRangePicker?.maxDate })}
              placeholderText="日付を選択"
              selected={dateRangePicker?.minDate}
              width={160}
              maxDate={dateRangePicker?.maxDate}
            />
            <StyledText>~</StyledText>
            <DatePicker
              onChange={(value) => setDateRangePicker({ maxDate: value, minDate: dateRangePicker?.minDate })}
              placeholderText="日付を選択"
              selected={dateRangePicker?.maxDate}
              width={160}
              minDate={dateRangePicker?.minDate}
            />
            <MainButton
              width={60}
              onClick={() => {
                setFormState(dateRangePicker);
                handleChangePage(1);
              }}
            >
              確定
            </MainButton>
            <MainButton width={42} variant="clear" onClick={() => handleFilterClear()}>
              クリア
            </MainButton>
          </Box>
          <Divider option="horizontal" />
          <Box flex="auto" width="100%" px={16}>
            <PaginationBox
              dataSize={listItems.length}
              limit={limit}
              page={page}
              onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
              onChangePage={(value: number) => handleChangePage(value)}
            >
              <ScrollWrapper bottom={385}>
                <List
                  header={listHeader}
                  items={listItems.slice(limit * (page - 1), limit * page)}
                  key={String(limit) + String(page) + String(sort?.key || '')}
                  sort={sort}
                  onChangeSort={(key) => {
                    handleChangeSort(key);
                    handleChangePage(1);
                  }}
                  rowHeight={34}
                  rowWidth="100%"
                  width="100%"
                />
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </Box>
      ) : (
        <>
          <Box display={isShowFilter ? 'flex' : 'none'} width="100%" pb={32} pa={16} flexDirection="column">
            <StyledFlexWrap>
              <Box display="flex" flexDirection="row" alignItems="center" pb={12} justifyContent="space-between">
                <Box width="100%">
                  <DatePicker
                    onChange={(value) => setDateRangePicker({ minDate: value, maxDate: dateRangePicker?.maxDate })}
                    placeholderText="日付を選択"
                    selected={dateRangePicker?.minDate}
                    maxDate={dateRangePicker?.maxDate}
                    fromTo={143}
                  />
                </Box>
                <StyledText>~</StyledText>
                <Box width="100%">
                  <DatePicker
                    onChange={(value) => setDateRangePicker({ maxDate: value, minDate: dateRangePicker?.minDate })}
                    placeholderText="日付を選択"
                    selected={dateRangePicker?.maxDate}
                    minDate={dateRangePicker?.minDate}
                    fromTo={143}
                  />
                </Box>
              </Box>
            </StyledFlexWrap>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" gap={7} alignItems="center">
              <MainButton
                width={88}
                customHeight={40}
                onClick={() => {
                  setFormState(dateRangePicker);
                  handleChangePage(1);
                }}
              >
                確定
              </MainButton>
              <MainButton width={49} variant="clear" onClick={() => handleFilterClear()}>
                クリア
              </MainButton>
            </Box>
          </Box>
          <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
          <Box flex="auto" width="100%">
            <PaginationBox
              dataSize={listItems.length}
              limit={limit}
              page={page}
              onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
              onChangePage={(value: number) => handleChangePage(value)}
            >
              <ScrollWrapper bottom={isShowFilter ? 429 : 319} bottomSE={319}>
                <List
                  header={listHeader}
                  items={listItems.slice(limit * (page - 1), limit * page)}
                  key={String(limit) + String(page) + String(sort?.key || '')}
                  sort={sort}
                  onChangeSort={(key) => {
                    handleChangeSort(key);
                    handleChangePage(1);
                  }}
                  rowHeight={57}
                  rowWidth="100%"
                  width="100%"
                />
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </>
      )}
      {/* feat_screen_07-02-01_04 end */}
    </>
  );
}
