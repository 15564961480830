import React, { useContext, useEffect, useState } from 'react';
import { Box } from 'components/box';
import { AgreementStatus } from 'components/const';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { usePagenation, useSort } from 'components/utils/hooks';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { header, generateListRow, StyledListWrapper } from '../common';
import { ListDataType } from '../type';
import { CurrentFilterContext, Filter, applyFilter } from '../Filter';

function AllList(props: { data: ListDataType[] }) {
  const { publishedNumber, name, arbeitDate, statuses } = useContext(CurrentFilterContext);
  const { data } = props;

  const [filteredData, setFilteredData] = useState<ListDataType[]>([]);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  const [dataSize, setDataSize] = React.useState<number>(0);

  // API Call
  useEffect(() => {
    setFilteredData(data || []);
    setDataSize(data?.length || 0);
  }, [data]);

  useEffect(() => {
    const fData = applyFilter(data || [], {
      publishedNumber,
      name,
      arbeitDate,
      status: statuses?.includes(AgreementStatus.ALL) || statuses === undefined ? [] : statuses,
    });
    setFilteredData(fData);
    setDataSize(fData.length);
    setPage(1);
  }, [data, publishedNumber, name, statuses, arbeitDate, setPage]);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      {/* feat_screen_04_02_add_active_filter_start */}
      <Box display={isShowFilter ? "flex" : "none"}>
        <Filter showStatusFilter />
      </Box>
      {
        isMobile && <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      }
      {/* feat_screen_04_02_add_active_filter_end */}
      {/* feat_screen_04-02-01-01_start */}
      <Box px={!isMobile ? 16 : 0}>
      {/* feat_screen_04-02-01-01_end */}
        <PaginationBox
          dataSize={dataSize}
          limit={limit}
          page={page}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          {/* feat_screen_04-02-02-01_start */}
          <StyledListWrapper showStatusFilter isShowFilter={isShowFilter}>
          {/* feat_screen_04-02-02-01_end */}
            <List
              header={header}
              items={generateListRow(
                filteredData, 
                limit, 
                page, 
                sort,
                /* feat_screen_04-02-01-01_start */
                isMobile
                /* feat_screen_04-02-01-01_end */
              )}
              onChangeSort={handleChangeSort}
              sort={sort}
              /* feat_screen_04-02-03-01_start */
              rowHeight={!isMobile ? 40 : 48}
              /* feat_screen_04-02-03-01_end */
            />
          </StyledListWrapper>
        </PaginationBox>
      </Box>
    </Box>
  );
}

export default AllList;
