import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfTextarea } from 'components/rhfTextarea';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { AddReportModalForm, ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { Control, FieldErrors, UseFormTrigger } from 'react-hook-form';

export function AddReport(
  control: Control<AddReportModalForm>,
  trigger: UseFormTrigger<AddReportModalForm>,
  errors: FieldErrors<AddReportModalForm>,
  onClose: ()=> void,
  onNext: ()=> void
): ModalRet{
  const validate  = (v: string) => {
    if(typeof v === 'undefined' || v === null || v === ''){
      return '通報内容を入力してください';
    }
    return undefined;
  };

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        通報
      </Text>
    ),
    content: !isMobile ? (
      <Box display="flex" flexDirection="column" gap={17.5} pa={8} flex='1'>
        <Text variant="body14">
          このバイトラベラーを通報します。通報内容を入力して、次へを押してください。
        </Text>
        <Box
          display='flex'
          flexDirection='column'
          gap={5.5}
        >
          <Text variant='h3' color='blueGray'>
            通報内容
          </Text>
          <RhfTextarea
            name='reason'
            control={control}
            fullWidth
            height={170}
            rules={{ validate: { message: v => validate(v) } }}
          />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            <Text variant='caption11' color='cautionRed'>{errors.reason?.message}</Text>
          </Box>
        </Box>
      </Box>
    ) : (
      /* feat_screen_05-01-01-11_start */
      <Box display="flex" flexDirection="column" gap={12} flex='1'>
        <Text display="block" variant="body14">
          このバイトラベラーを通報します。通報内容を入力して、次へを押してください。
        </Text>
        <Box
          display='flex'
          flexDirection='column'
          gap={4}
        >
          <Text variant='h3' color='blueGray'>
            通報内容
          </Text>
          <RhfTextarea
            name='reason'
            control={control}
            fullWidth
            height={98}
            rules={{ validate: { message: v => validate(v) } }}
          />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            <Text variant='caption11' color='cautionRed'>{errors.reason?.message}</Text>
          </Box>
        </Box>
      </Box>
      /* feat_screen_05-01-01-11_end */
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen_05-01-01-11_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          /* feat_screen_05-01-01-11_end */
          variant="secondary" 
          onClick={() => onClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          /* feat_screen_05-01-01-11_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          /* feat_screen_05-01-01-11_end */
          onClick={() => {
            trigger('reason')
            .then((isValid) => {
              if(isValid){
                onNext();
              }
            });
          }}
        >
          次へ
        </MainButton>
      </Box>
    ),
    /* feat_screen_05-01-01-11_start */
    width: !isMobile ? 800 : "100%",
    // バリデーションのテキスト分の高さを追加
    height: !isMobile ? 405 + 18 : "unset"
    /* feat_screen_05-01-01-11_end */
  };

  return modalContent;
};