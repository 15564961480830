import { Box } from 'components/box';
import { AgreementStatus } from 'components/const';
import { Divider } from 'components/newDivider/divider';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import axios from 'axios';
import { QrCode, QrCodeType } from 'qrCodeComponent/qrCode';
import { useUpdateTWorkingRecognitionByIdMutation } from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { CheckInInfoContext } from '../common';
import CameraModal from '../modal/faceRecognition/CameraModal';
import ManualModal from '../modal/faceRecognition/ManualModal';
import CompleteModal from '../modal/faceRecognition/CompleteModal';
import FailModal from '../modal/faceRecognition/FailModal';
import { compareFace, existsFaceImage } from '../Rekognition';

function CheckIn({ workId, refetch }: { workId: number; refetch: () => void }) {
  const enum ModalStep {
    CLOSED = 0,
    CAMERA = 1,
    CAMERA_FAIL = 2,
    MANUAL = 3,
    COMPLETED = 4,
  }

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CLOSED);

  const { imageURL, status, brandId, tpmemId, isFaceRecognized } = useContext(CheckInInfoContext);

  const statusesToEnableCameraButton: AgreementStatus[] = [
    AgreementStatus.STAND_BY,
    AgreementStatus.NO_CHECK_IN,
    AgreementStatus.NO_CHECK_IN_OUT,
  ];

  // 顔画像データ
  // 取得失敗したらnullを返す
  const FaceImage = useMemo<Promise<Buffer | null>>(async () => {
    try {
      if (!imageURL) return null;

      const imdData = await axios.get(imageURL, { responseType: 'arraybuffer' });
      if (!imdData?.data) return null;

      return Buffer.from(imdData.data as string, 'binary');
    } catch (e) {
      return null;
    }
  }, [imageURL]);

  // 顔認証
  async function recognizeFace(cameraImg: string | null | undefined): Promise<boolean> {
    // カメラ画像が存在しない場合はfalseを返す
    if (!cameraImg) return false;

    const cameraImgBuffer = Buffer.from(cameraImg.replace('data:image/jpeg;base64,', ''), 'base64');

    // カメラ画像に顔画像が存在するかチェック
    // compareFaceの引数の画像に顔画像がないとエラーになるので、
    // あらかじめ顔画像があるかチェックする
    const isExistsFaceImage = await existsFaceImage(cameraImgBuffer);

    // 顔画像が存在しない場合はfalseを返す
    if (!isExistsFaceImage) return false;

    // 顔画像データが存在しない場合はfalseを返す
    const faceImageBUffer = await FaceImage;
    if (!faceImageBUffer) return false;

    // 顔認証
    const result = await compareFace(faceImageBUffer, cameraImgBuffer);

    return result;
  }

  const [updateFaceRecognitionCompleted, { error: updateFaceRecognitionCompletedError }] =
    useUpdateTWorkingRecognitionByIdMutation({
      variables: { id: workId },
    });

  const { openErrorModal } = useErrorModal();

  const onUpdateFaceRecognitionCompletedError = useCallback(() => {
    setModalStep(ModalStep.CLOSED);
    openErrorModal({
      message: '顔認証を完了できませんでした。時間をおいてもう一度お試しください。',
    });
  }, [openErrorModal, ModalStep.CLOSED]);

  useEffect(() => {
    if (updateFaceRecognitionCompletedError) {
      onUpdateFaceRecognitionCompletedError();
    }
  }, [updateFaceRecognitionCompletedError, onUpdateFaceRecognitionCompletedError]);

  function onFaceRecognitionCompleted() {
    updateFaceRecognitionCompleted()
      .then((result) => {
        if (result.errors || result.data?.updateTWorkingRecognitionById?.resultRows !== 1) {
          onUpdateFaceRecognitionCompletedError();
          return;
        }
        refetch();
        setModalStep(ModalStep.COMPLETED);
      })
      .catch(() => {
        onUpdateFaceRecognitionCompletedError();
      });
  }

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <>
      {
        !isMobile ? (
          <Box pa={18}>
            <Box display="flex" alignItems="center" gap={8} pb={16}>
              <Text variant="h2" color="darkBlue">
                二次元バーコード/顔認証
              </Text>
              <Text variant="caption12" color="darkBlue">
                チェックイン時に使用する顔認証カメラと二次元バーコードです。
              </Text>
            </Box>
            <Divider option="horizontal" />

            <Box display="flex">
              <Box display="flex" flexDirection="column" width="50%" alignItems="center" mt={120}>
                <MainButton
                  width={160}
                  iconColor="white"
                  icon="camera"
                  onClick={() => {
                    setModalStep(ModalStep.CAMERA);
                  }}
                  disabled={isFaceRecognized || !statusesToEnableCameraButton.includes(status)}
                >
                  顔認証
                </MainButton>
                <Box mt={68}>
                  <Text variant="caption12" color="darkBlue">
                    顔認証カメラを起動
                  </Text>
                </Box>
              </Box>
              <Box mt={16}>
                <Divider option="vertical" length={240} />
              </Box>
              <Box display="flex" flexDirection="column" width="50%" alignItems="center" mt={32}>
                <QrCode type={QrCodeType.CHECKIN} brandId={brandId || 0} tpmemId={tpmemId} size={190} />
                <Text variant="caption12" color="darkBlue">
                  チェックイン用二次元バーコード
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          /* feat_screen_04-02-01-08_start */
          <Box display="flex" flexDirection="column">
            <Box pt={10}>
              <Divider option="horizontal" /> 
            </Box>
            <Box display="flex" flexDirection="column" gap={16} pt={16}>
              <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
                <Text variant="h2" color="darkBlue">
                  二次元バーコード/顔認証
                </Text>
                <Text align='center' variant="caption12" color="darkBlue">
                  チェックイン時に使用する顔認証カメラと二次元バーコードです。
                </Text>
              </Box>
              <Divider option="horizontal" />
              <Box display="flex" flexDirection="column" gap={16}>
                <Box display="flex" flexDirection="column" alignItems="center" gap={16}>
                  <MainButton
                    width={124}
                    customHeight={32}
                    iconColor="white"
                    icon="camera"
                    onClick={() => {
                      setModalStep(ModalStep.CAMERA);
                    }}
                    disabled={isFaceRecognized || !statusesToEnableCameraButton.includes(status)}
                  >
                    顔認証
                  </MainButton>
                  <Box mt={-4}>
                    <Text variant="caption12" color="darkBlue">
                      顔認証カメラを起動
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Divider option="horizontal" />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" gap={16}>
                  <QrCode type={QrCodeType.CHECKIN} brandId={brandId || 0} tpmemId={tpmemId} size={124}/>
                  <Text variant="caption12" color="darkBlue">
                    チェックイン用二次元バーコード
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
           /* feat_screen_04-02-01-08_end */
        )
      }
      <CameraModal
        isOpen={modalStep === ModalStep.CAMERA}
        recognizeFace={(cameraImg: string | null | undefined) => recognizeFace(cameraImg)}
        onSucceed={() => onFaceRecognitionCompleted()}
        onFail={() => setModalStep(ModalStep.CAMERA_FAIL)}
        onClickManual={() => setModalStep(ModalStep.MANUAL)}
        onClose={() => setModalStep(ModalStep.CLOSED)}
      />
      <ManualModal
        isOpen={modalStep === ModalStep.MANUAL}
        imageURL={imageURL || ''}
        onComplete={() => onFaceRecognitionCompleted()}
        onClose={() => setModalStep(ModalStep.CLOSED)}
      />
      <CompleteModal isOpen={modalStep === ModalStep.COMPLETED} onClose={() => setModalStep(ModalStep.CLOSED)} />
      <FailModal
        isOpen={modalStep === ModalStep.CAMERA_FAIL}
        onClickManual={() => setModalStep(ModalStep.MANUAL)}
        onClose={() => setModalStep(ModalStep.CLOSED)}
      />
    </>
  );
}

export default CheckIn;
