import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import { Text } from 'components/text';
import { Icon } from 'components/icon';
import { matchPath, useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { PRIMARY_COLOR, GENERIC_COLOR } from 'components/assets/css/style';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { MainMenu } from './types';

export type Props = {
  mainMenuBarData: MainMenu[];
  isOpenMainMenuBar: boolean;
  toggleMainMenubar: (value: boolean) => void;
  currentRoute: string;
  headerContent?: JSX.Element;
};

/* feat_common_responsive_main_menu_bar_start */
const StyledMainMenuBarBG = styled.div<{ minWidth: number }>`
  @media only screen and (max-width: 768px) {
    &.m-d-none {
      display: none;
    }
    &.m-d-visible {
      visibility: hidden;
    }
    background-color: ${PRIMARY_COLOR.OPEN_MENUBAR_BG};
    position: fixed;
    top: 0;
    left: 0;
    height: 100svh;
    width: 100vw;
    z-index:999;
    transition: 0.5s;
  }
  `;
/* feat_common_responsive_main_menu_bar_end */

const StyledMainMenuBarWrapper = styled.div<{ minWidth: number }>`
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    &.m-d-none {
      left: -75vw;
      opacity: 0;
    }
    &.m-d-visible {
      visibility: hidden;
    }
    display: block;
    padding-top: 14px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100svh;
    width: 75vw;
    opacity: 1;
    transition: all .3s ease-in;
    z-index: 900;
    @keyframes liner {
      from {
        width: 30vw;
        opacity: 0;
      }
      to {
        width: 75vw;
        opacity: 1;
      }
    }
    min-width: unset;
    animation: liner 0.2s ease-in;
  }
  /* feat_common_responsive_main_menu_bar_end */
  min-width: ${({ minWidth }) => `${minWidth}px`};
  overflow-y: auto;
  padding: 20px 0px;
  background-color: #f8feff;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.16);
`;

const StyledCloseMenu = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 56px;
  box-sizing: border-box;
`;

const StyledMenuRow = styled.div<{ active: boolean; isDisable?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${({ active, isDisable }) => (active ? '#00a5bf' : isDisable ? '#00a5bf' : 'inherit')};
  color: ${({ isDisable }) => (isDisable ? '#7cc1cc' : 'inherit')};
  cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};
  box-sizing: border-box;
  &:hover {
    background-color: ${({ isDisable }) => (isDisable ? 'inherit' : '#0000001a')};
    color: ${({ isDisable }) => (isDisable ? '#7cc1cc' : '#16495f')};
  }
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    line-height: 10px;
  }
  /* feat_common_responsive_main_menu_bar_end */
`;

const StyledCloseMenuRow = styled(StyledMenuRow)<{ active: boolean }>`
  justify-contents: center;
  padding: 8px 16px;
  color: ${({ active }) => (active ? 'white' : '#008599')};
`;

const StyledOpenMenuWrapper = styled.aside`
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    width: 75vw;
  }
  /* feat_common_responsive_main_menu_bar_end */
  width: 240px;
  box-sizing: border-box;
`;

const StyledOpenMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  box-sizing: border-box;
`;

const StyledOpenMenuRowWrapper = styled.div`
  width: 100%;
`;

const StyledOpenMenuRow = styled(StyledMenuRow)<{ active: boolean }>`
  padding: 0px 16px;
  gap: 8px;
`;

const StyledPositionRelative = styled.div`
  position: relative;
`;

const StyledIconIndicator = styled.div`
  position: absolute;
  left: -4px;
  top: -10px;
`;

const StyledTitle = styled.div`
  flex: auto;
  span {
    vertical-align: super;
  }
`;

const StyledAccordion = styled.div`
  transition: height 0.2s linear, opacity 0.2s ease-in;
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    gap: 0;
  }
  /* feat_common_responsive_main_menu_bar_end */
`;

const StyledAccordionRow = styled.div<{ active: boolean; isDisable?: boolean }>`
  height: 32px;
  padding: 4px 0 4px 48px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? '#00a5bf' : 'inherit')};
  color: ${({ isDisable }) => (isDisable ? '#87a3ae' : '#16495F')};
  cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};
  box-sizing: border-box;

  span {
    color: ${({ active, isDisable }) => (active ? 'white' : isDisable ? '#87a3ae' : 'inherit')} !important;
  }

  &:hover {
    background-color: ${({ isDisable }) => (isDisable ? 'inherit' : '#0000001a')};
    span {
      color: ${({ isDisable }) => (isDisable ? 'inherit' : '#16495f')} !important;
    }
  }
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
  /* feat_common_responsive_main_menu_bar_end */
`;

const StyledAccordionActiveBar = styled.div`
  width: 4px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  background: #008599;
  /* feat_common_responsive_main_menu_bar_start */
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
  /* feat_common_responsive_main_menu_bar_end */
`;

const StyledAccordionIndicator = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 26px;
  top: 50%;
  transform: translateY(-50%);
`;

/* feat_common_responsive_main_menu_bar_start */
const StyledMenuHeader = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
    pading-top: 16px;
  }
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin: 0 16px;
  box-sizing: border-box;
`;

const StyledLogo = styled.img`
  width: 140px;
  height: 24px;
  object-fit: contain;
`;

const StyleTextHeader = styled.div`
  padding: 4px 16px 14px;
`;
/* feat_common_responsive_main_menu_bar_end */


export function MainMenuBar({
  mainMenuBarData,
  isOpenMainMenuBar,
  toggleMainMenubar,
  currentRoute,
  headerContent,
}: Props) {
  const navigate = useNavigate();
  const [openedPageNumbers, setOpenedPageNumbers] = useState<Set<number>>(new Set());
  const [isOpen, setIsOpen] = useState(isOpenMainMenuBar);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  useEffect(() => {
    setIsOpen(isOpenMainMenuBar);
  }, [isOpenMainMenuBar]);

  const isRowActive = (matchedRoutes: string[]) =>
    Boolean(matchedRoutes.find((route) => matchPath(route, currentRoute)));

  // feat_common_height_main_menu_bar_start
  const openPage = (pageNo: number) => {
    if(!isMobile || window.innerHeight > 768) {
      setOpenedPageNumbers((previous) => new Set(previous.add(pageNo)));
    } else {
        setOpenedPageNumbers(() => {
          const setPageNumbers = new Set<number>();
          return setPageNumbers.add(pageNo);
        });
    }
  };
  // feat_common_height_main_menu_bar_end

  const closePage = (pageNo: number) => {
    setOpenedPageNumbers((previous) => {
      previous.delete(pageNo);
      return new Set(previous);
    });
  };

  const handleAccordionMenuClick = (pageNo: number) => {
    if (openedPageNumbers.has(pageNo)) {
      closePage(pageNo);
    } else {
      openPage(pageNo);
    }
  };

  React.useEffect(() => {
    mainMenuBarData.forEach(({ matchedRoutes, pageNo }) => {
      if (matchedRoutes.find((route) => matchPath(route, currentRoute)) && !openedPageNumbers.has(pageNo)) {
        openPage(pageNo);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute, mainMenuBarData]);

  const handleNavigate = (link?: string, isDisable?: boolean) => {
    if (isDisable || !link) {
      // isDisableがfalseの場合、またはリンクが未指定の場合は何もしない
      return;
    }
    if (link) {
      navigate(link);
    }
  };

  const handleClosedMenuClick = (item: MainMenu) => {
    if (item.link) {
      navigate(item.link);
      return;
    }
    openPage(item.pageNo);
    toggleMainMenubar(true);
    setIsOpen(true);
  };

  /* feat_common_responsive_main_menu_bar_start */
  const handleMobileCloseMenu = () => {
    toggleMainMenubar(false);
    setIsOpen(false);
  };

  const handleMobileCloseMenuOverlayChill = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };
  /* feat_common_responsive_main_menu_bar_end */

  if (!isOpen) {
    return (
      /* feat screen 3_home add class m-d-none start */
      <StyledMainMenuBarWrapper minWidth={56} className={cx('m-d-none')}>
      {/* feat screen 3_home add class m-d-none end */}
        <StyledCloseMenu>
          {mainMenuBarData.map((menubarItem) => (
            <StyledCloseMenuRow
              key={menubarItem.pageNo}
              active={isRowActive(menubarItem.matchedRoutes)}
              isDisable={menubarItem.isDisable}
            >
              <StyledPositionRelative>
                {menubarItem.highlighted && (
                  <StyledIconIndicator>
                    <Icon name="indicatorRed" size={8} />
                  </StyledIconIndicator>
                )}
                <Icon
                  name={menubarItem.icon.normal}
                  color={isRowActive(menubarItem.matchedRoutes) ? '#fff' : '#008599'}
                  onClick={() => handleClosedMenuClick(menubarItem)}
                />
              </StyledPositionRelative>
            </StyledCloseMenuRow>
          ))}
        </StyledCloseMenu>
      </StyledMainMenuBarWrapper>
    );
  }
  return !isMobile ? (
    <StyledMainMenuBarWrapper minWidth={240}>
      <StyledOpenMenuWrapper>
        {headerContent}
        <StyledOpenMenu>
          {mainMenuBarData.map((menubarItem) => (
            <StyledOpenMenuRowWrapper key={menubarItem.pageNo}>
              <StyledOpenMenuRow
                key={menubarItem.title}
                role="button"
                tabIndex={0}
                onClick={() =>
                  menubarItem.accordionList
                    ? handleAccordionMenuClick(menubarItem.pageNo)
                    : handleNavigate(menubarItem.link, menubarItem.isDisable)
                }
                active={Boolean(menubarItem.link) && isRowActive(menubarItem.matchedRoutes)}
              >
                <StyledPositionRelative>
                  {menubarItem.highlighted && (
                    <StyledIconIndicator>
                      <Icon name="indicatorRed" size={8} />
                    </StyledIconIndicator>
                  )}
                  <Icon
                    name={menubarItem.icon.normal}
                    color={
                      menubarItem.isDisable
                        ? '#7CC1CC'
                        : Boolean(menubarItem.link) && isRowActive(menubarItem.matchedRoutes)
                        ? '#fff'
                        : '#008599'
                    }
                  />
                </StyledPositionRelative>
                <StyledTitle>
                  <Text
                    variant="body14"
                    color={
                      menubarItem.isDisable
                        ? 'unlinkBlue'
                        : Boolean(menubarItem.link) && isRowActive(menubarItem.matchedRoutes)
                        ? 'white'
                        : 'primaryBlue'
                    }
                    bold
                  >
                    {menubarItem.title}
                  </Text>
                </StyledTitle>
                {menubarItem.accordionList?.length && (
                  <Icon
                    name={openedPageNumbers.has(menubarItem.pageNo) ? 'chevronLess' : 'chevronMore'}
                    color={
                      menubarItem.isDisable
                        ? '#7CC1CC'
                        : Boolean(menubarItem.link) && isRowActive(menubarItem.matchedRoutes)
                        ? '#fff'
                        : '#008599'
                    }
                  />
                )}
              </StyledOpenMenuRow>
              {openedPageNumbers.has(menubarItem.pageNo) && (
                <StyledAccordion>
                  {menubarItem.accordionList &&
                    menubarItem.accordionList.map((accordionItem) => (
                      <StyledAccordionRow
                        key={accordionItem.accordionTitle}
                        role="button"
                        onClick={() => handleNavigate(accordionItem.accordionLink, accordionItem.isDisable)}
                        tabIndex={0}
                        active={isRowActive(accordionItem.matchedRoutes)}
                        isDisable={accordionItem.isDisable}
                      >
                        {isRowActive(accordionItem.matchedRoutes) && <StyledAccordionActiveBar />}
                        {accordionItem.highlighted && (
                          <StyledAccordionIndicator>
                            <Icon name="indicatorRed" size={8} />
                          </StyledAccordionIndicator>
                        )}
                        <Text variant="body14" color={isRowActive(accordionItem.matchedRoutes) ? 'white' : 'darkBlue'}>
                          {accordionItem.accordionTitle}
                        </Text>
                      </StyledAccordionRow>
                    ))}
                </StyledAccordion>
              )}
            </StyledOpenMenuRowWrapper>
          ))}
        </StyledOpenMenu>
      </StyledOpenMenuWrapper>
    </StyledMainMenuBarWrapper>
  ):(
    /* feat_common_responsive_main_menu_bar_start */
    <StyledMainMenuBarBG
      minWidth={240}
      onClick={() => handleMobileCloseMenu()}
      className={!isOpen ? cx('m-d-visible') : cx('')}
    >
      <StyledMainMenuBarWrapper
        minWidth={240}
        onClick={(e)=> handleMobileCloseMenuOverlayChill(e as React.MouseEvent<HTMLInputElement>)}
        className={!isOpen ? cx('m-d-visible') : cx('')}
      >
        <StyledMenuHeader>
          <StyledLogoWrapper>
            <StyledLogo src="/bitravel_logo.png" alt="logo"/>
            <Icon name="cancel" size={30} color={GENERIC_COLOR.BLACK} onClick={() => handleMobileCloseMenu()} />
          </StyledLogoWrapper>
          <StyleTextHeader>
            <Text variant="body14" color="darkBlue" bold>
              加盟店管理
            </Text>
          </StyleTextHeader>
        </StyledMenuHeader>
        <StyledOpenMenuWrapper>
          {headerContent}
        </StyledOpenMenuWrapper>
      </StyledMainMenuBarWrapper>
    </StyledMainMenuBarBG>
     /* feat_common_responsive_main_menu_bar_end */
  );
}
