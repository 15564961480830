import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import React, { useEffect, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import * as gql from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { useLocation } from 'react-router-dom';
import { TransferProcessStatus } from 'components/const';
import { MenuIndexType, SubMenuItemsType } from '../type';
import { BaseInfo, EducationalCost, PaymentThanksGift, PenaltyRate, ThanksMassage } from './components';

export function UnrestrictedContent(props: {
  refetchHeaderData: (
    variables?:
      | Partial<
          gql.Exact<{
            id: number;
            tpmem_id: number;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<gql.GetVbMemberBaseByIdQuery>>;
  /* feat_screen_05-01-01-02_add_props_start */
  blogFlag?: boolean;
  /* feat_screen_05-01-01-02_add_props_end */
}) {
  const { 
    refetchHeaderData,
    /* feat_screen_05-01-01-02_add_props_start */    
    blogFlag
    /* feat_screen_05-01-01-02_add_props_end */ 
  } = props;

  const location = useLocation();
  const state = location?.state as MenuIndexType;
  const menuIndex = state?.menuIndex;

  const auth = useAuth();

  const [getVtNewlyMenuHome] = useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery({
    pollInterval: Number(process.env.REACT_APP_MENU_POLLING_INTERVAL),
  });

  const [transferProcessStatus, setTransferProcessStatus] = useState<number>(0);

  useEffect(() => {
    if (auth.tpmemId === null) return;
    getVtNewlyMenuHome({ variables: { tpmem_id: auth.tpmemId } }).then((result) => {
      const menuData = result.data?.getVTNewlyMenuHomeArbeitByTpmemId.menu;
      const transferProcessStatusData = menuData?.mfk_status;

      if (typeof transferProcessStatusData === 'number') {
        setTransferProcessStatus(transferProcessStatusData);
      }
    });
  }, [auth, getVtNewlyMenuHome]);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  /* feat_screen_05-01-01-02_start */
  const [tab, setTab] = React.useState<number>(menuIndex || 0);
  /* feat_screen_05-01-01-02_end */

  const subMenuItems: SubMenuItemsType = [
    { label: '基本情報', content: <BaseInfo 
        refetchHeaderData={refetchHeaderData} 
        /* feat_common_responsive_useMobile_logic_start */
        blogFlag={blogFlag}
        /* feat_common_responsive_useMobile_logic_end */
      /> 
    },
    { label: '育成料', content: <EducationalCost /> },
    { label: 'Thanksギフト支給',
      content: <PaymentThanksGift /> ,
      isDisable: transferProcessStatus === TransferProcessStatus.UNAPPLIED,
    },
    { label: 'Thanksメッセージ', content: <ThanksMassage /> },
    { label: 'ペナルティ率', content: <PenaltyRate /> },
  ];

  return !isMobile ? (
    <Card overflow="hidden">
      <SubMenu items={subMenuItems} defaultIndex={menuIndex} />
    </Card>
  ) : (
    /* feat_screen_05-01-01-02_start */
    <Card>
      <Dropdown items={subMenuItems} defaultIndex={menuIndex} tab={tab} setTab={setTab} fullWidth/>
    </Card>
    /* feat_screen_05-01-01-02_end */
  );
}
