import React from 'react';
import { Box } from 'components/box';
import { Tab } from 'components/newTab';
import useIsMobile from 'hooks/responsive/useIsMobile';
import CheckIn from './QRCodeFace/Checkin';
import CheckOut from './QRCodeFace/CheckOut';

/**
 * Figma ID: 04-02-01-08,04-02-01-09,04-02-02-02,04-02-02-08
 * 名称: QRコード・顔認証
 */

function QRCodeFaceRecongnition({ workId, refetch }: { workId: number; refetch: () => void }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <Box alignItems="center" display="flex" justifyContent="center" width="100%" pa={16}>
      <Tab
        items={[
          {
            label: 'チェックイン',
            content: (
              <CheckIn
                workId={workId}
                refetch={() => {
                  refetch();
                }}
              />
            ),
          },
          { label: 'チェックアウト', content: <CheckOut /> },
        ]}
      />
    </Box>
  ): (
    // feat_screen_04-02-01-08_start
    <Box alignItems="center" display="flex" justifyContent="center" width="100%" pt={12} pb={16} px={16} height="calc(100% - 64px)" overflow="auto">
      <Tab
        items={[
          {
            label: 'チェックイン',
            content: (
              <CheckIn
                workId={workId}
                refetch={() => {
                  refetch();
                }}
              />
            ),
          },
          { label: 'チェックアウト', content: <CheckOut /> },
        ]}
      />
    </Box>
    // feat_screen_04-02-01-08_end
  );
}

export default QRCodeFaceRecongnition;
