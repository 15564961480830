/**
 * Figma ID: 08-05-02, 08-05-03, 08-05-04
 * 名称: 労働条件通知書
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { Control, FieldErrors } from 'react-hook-form';
import { RequiredIcon } from 'pages/s08/component/Require';
import Divider from 'components/divider/divider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import type { EditContentModel } from '.';

type EditModel = {
  control: Control<EditContentModel>;
  errors: FieldErrors<EditContentModel>;
};
export function EditContent(props: EditModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { control, errors } = props;
  // feat screen 08-05-02 start
  return !isMobile ?(
    <Box>
      <Text variant="body14">労働条件通知書に記載する各項目の文章を記入してください（最大32文字）。</Text>
      <Box pt={18} pb={35}>
        <Box justifyContent="space-between" display="flex" pb={8}>
          <InputLabel>社会保険等</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfInput
          defaultValue=""
          fullWidth
          placeholder="文章を記入"
          control={control}
          name="socialInsurance"
          rules={{
            required: '未記入の場合は保存できません。',
            validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
          }}
        />
        {errors.socialInsurance && (
          <Text variant="caption11" color="cautionRed">
            {errors.socialInsurance.message}
          </Text>
        )}
      </Box>
      <Box pb={35}>
        <Box justifyContent="space-between" display="flex" pb={8}>
          <InputLabel>受動喫煙防止措置</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfInput
          defaultValue=""
          fullWidth
          placeholder="文章を記入"
          control={control}
          name="passiveSmokingPrevention"
          rules={{
            required: '未記入の場合は保存できません。',
            validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
          }}
        />
        {errors.passiveSmokingPrevention && (
          <Text variant="caption11" color="cautionRed">
            {errors.passiveSmokingPrevention.message}
          </Text>
        )}
      </Box>
      <Box>
        <Box display="flex">
          <InputLabel width={160}>喫煙可能エリアでの作業</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfRadioGroup
          defaultValue=""
          name="isWorkingInSmokingArea"
          control={control}
          options={[
            { label: '有', value: 'yes' },
            { label: '無', value: 'no' },
          ]}
          rules={{ required: '未記入の場合は保存できません。' }}
        />
        {errors.isWorkingInSmokingArea && (
          <Text variant="caption11" color="cautionRed">
            {errors.isWorkingInSmokingArea.message}
          </Text>
        )}
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box>
      <Text display="block" variant="body14">労働条件通知書に記載する各項目の文章を記入してください（最大32文字）。</Text>
      <Box width="100%" py={16} pb={14}>
        <Box justifyContent="space-between" display="flex" pb={4}>
          <InputLabel fullWidth>社会保険等</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfInput
          defaultValue=""
          fullWidth
          placeholder="文章を記入"
          control={control}
          name="socialInsurance"
          rules={{
            required: '未記入の場合は保存できません。',
            validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
          }}
        />
        {errors.socialInsurance && (
          <Text variant="caption11" color="cautionRed">
            {errors.socialInsurance.message}
          </Text>
        )}
      </Box>
      <Box width="100%" pb={15}>
        <Box justifyContent="space-between" display="flex" pb={4}>
          <InputLabel fullWidth>受動喫煙防止措置</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfInput
          defaultValue=""
          fullWidth
          placeholder="文章を記入"
          control={control}
          name="passiveSmokingPrevention"
          rules={{
            required: '未記入の場合は保存できません。',
            validate: (val: string) => val.length <= 32 || '32文字以内で入力してください。',
          }}
        />
        {errors.passiveSmokingPrevention && (
          <Text variant="caption11" color="cautionRed">
            {errors.passiveSmokingPrevention.message}
          </Text>
        )}
      </Box>
      <Box>
        <Box display="flex" pb={4}>
          <InputLabel width={163}>喫煙可能エリアでの作業</InputLabel>
          <RequiredIcon />
        </Box>
        <RhfRadioGroup
          defaultValue=""
          name="isWorkingInSmokingArea"
          control={control}
          options={[
            { label: '有', value: 'yes' },
            { label: '無', value: 'no' },
          ]}
          rules={{ required: '未記入の場合は保存できません。' }}
        />
        {errors.isWorkingInSmokingArea && (
          <Text variant="caption11" color="cautionRed">
            {errors.isWorkingInSmokingArea.message}
          </Text>
        )}
      </Box>
    </Box>
    // feat screen 08-05-02 end
  );
}

type ConfirmModel = {
  currentSocialInsurance: string;
  currentPassiveSmokingPrevention: string;
  currentIsWorkingInSmokingArea: string;
};
export function ConfirmContent(props: ConfirmModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { currentSocialInsurance, currentPassiveSmokingPrevention, currentIsWorkingInSmokingArea } = props;
  // feat screen 08-05-03 start
  return !isMobile ? (
    <Box>
      <Text variant="body14">以下の文章を記載します。よろしいですか？</Text>
      <Box pt={8}>
        <Divider option="horizontal" />
      </Box>
      <Box pt={18} pb={10}>
        <InputLabel>社会保険等</InputLabel>
        <Box py={8}>
          <Text variant="body14">{currentSocialInsurance}</Text>
        </Box>
        <Divider option="horizontal" />
      </Box>
      <Box pb={10}>
        <InputLabel>受動喫煙防止措置</InputLabel>
        <Box py={8}>
          <Text variant="body14">{currentPassiveSmokingPrevention}</Text>
        </Box>
        <Divider option="horizontal" />
      </Box>
      <Box>
        <InputLabel width={160}>喫煙可能エリアでの作業</InputLabel>
        <Box py={8}>
          <Text variant="body14">{currentIsWorkingInSmokingArea === 'yes' ? '有' : '無'}</Text>
        </Box>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box>
      <Text display="block" variant="body14">以下の文章を記載します。よろしいですか？</Text>
      <Box py={16}>
        <Divider option="horizontal" />
      </Box>
      <Box width="100%" pb={16}>
        <InputLabel fullWidth>社会保険等</InputLabel>
        <Box pt={6}>
          <Text display="block" variant="body14">{currentSocialInsurance}</Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box py={16}>
        <InputLabel fullWidth>受動喫煙防止措置</InputLabel>
        <Box pt={6}>
          <Text display="block" variant="body14">{currentPassiveSmokingPrevention}</Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box pt={16}>
        <InputLabel fullWidth>喫煙可能エリアでの作業</InputLabel>
        <Box pt={6}>
          <Text display="block" variant="body14">{currentIsWorkingInSmokingArea === 'yes' ? '有' : '無'}</Text>
        </Box>
      </Box>
    </Box>
    // feat screen 08-05-03 end
  );
}

export function CompleteContent() {
  return (
    <Box>
      <Text variant="body14">文章登録が完了しました。</Text>
    </Box>
  );
}
