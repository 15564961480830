import {
  createNoticeOfEmploymentPdf,
  createWithholdingSlipPdf,
  NoticeOfEmploymentPdfProps,
  WsPdfProps,
  download,
  preview,
} from 'components/pdf';
import {
  useGetVbWithholdingSlipDataByGcbIdCnLazyQuery,
  useGetVtNoticeDataByIdLazyQuery,
  useGetVtNoticeTemplateByTpmemIdLazyQuery,
} from 'graphql/graphql-mw';
import { ja } from 'date-fns/locale';
import { format, parse, add } from 'date-fns';

export { preview, download, createNoticeOfEmploymentPdf } from 'components/pdf';

export type { NoticeOfEmploymentPdfProps } from 'components/pdf';

// 労働条件通知書PDFダウンロード用フック
export const useNoticeOfEmploymentPdfDownload = () => {
  // 労働条件通知書PDFデータ取得
  const [getVtNoticeDataById, { loading, error }] = useGetVtNoticeDataByIdLazyQuery();

  // 労働条件通知書PDFダウンロード
  const downloadPdf = async (offerId: number, fileName = '労働条件通知書.pdf') => {
    try {
      // 労働条件通知書データ取得
      const data = await getVtNoticeDataById({
        variables: {
          id: offerId,
        },
      });
      const pdfData = data.data?.getVTNoticeDataById;

      // データが取得できなかった場合はnullを返す
      if (!pdfData || !pdfData.id) return { success: false, error: 'PDFデータの取得に失敗しました。' };

      // 抽出したデータをPDFのプロパティに設定
      const pdfProps: NoticeOfEmploymentPdfProps = {
        userName: pdfData.employer || undefined,
        userAddress: pdfData.employer_address || undefined,
        employmentDate: pdfData.work_day || undefined,
        workingAddress: pdfData.tpmem_address || undefined,
        description: pdfData.description || undefined,
        beginTime: pdfData.begin_time || undefined,
        endTime: pdfData.end_time || undefined,
        breakTime: pdfData.break_time || undefined,
        hourWage: pdfData.base_salary || undefined,
        transFee: pdfData.trans_fee || undefined,
        socialInsurance: pdfData.social_insurance || undefined,
        passiveSmokingMeasure: pdfData.prevent_ets || undefined,
        isWorkSmokingArea: Boolean(pdfData.in_smoking_area),
      };

      // PDF作成
      const buffer = await createNoticeOfEmploymentPdf(pdfProps);
      if (!buffer) return { success: false, error: 'PDFの作成に失敗しました。' };

      // PDFダウンロード
      download(buffer, fileName);

      return { success: true };
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return {
    downloadPdf,
    loading,
    error,
  };
};

// 労働条件通知書PDFテンプレートダウンロード用フック
export const useNoticeOfEmploymentPdfTemplateDownload = () => {
  // 労働条件通知書PDFテンプレートデータ取得
  const [getVtNoticeTemplateByTpmemId, { loading, error }] = useGetVtNoticeTemplateByTpmemIdLazyQuery();

  // 労働条件通知書PDFテンプレートダウンロード
  const downloadPdf = async (tpmemId: number, fileName = '労働条件通知書.pdf') => {
    try {
      // 労働条件通知書テンプレート取得
      const data = await getVtNoticeTemplateByTpmemId({
        variables: {
          tpmem_id: tpmemId,
        },
      });
      const template = data.data?.getVTNoticeTemplateByTpmemId;

      // データが取得できなかった場合は処理を抜ける
      if (!template || !template.tpmem_id) return { success: false, error: 'PDFテンプレートの取得に失敗しました。' };

      // 抽出したデータをPDFのプロパティに設定
      const pdfProps: NoticeOfEmploymentPdfProps = {
        userName: template.employer || '',
        userAddress: template.employer_address || '',
        workingAddress: template.tpmem_address || '',
        socialInsurance: template.social_insurance || '',
        passiveSmokingMeasure: template.prevent_ets || '',
        isWorkSmokingArea: Boolean(template?.in_smoking_area),
      };

      // PDF作成
      const buffer = await createNoticeOfEmploymentPdf(pdfProps);
      if (!buffer) return { success: false, error: 'PDFの作成に失敗しました。' };

      // PDFダウンロード
      download(buffer, fileName);

      return { success: true };
    } catch (e) {
      console.log(e);
      return { success: false, error: 'ダウンロード処理に失敗しました。' };
    }
  };

  return {
    downloadPdf,
    loading,
    error,
  };
};

// 労働条件通知書PDFプレビュー用フック
export const useNoticeOfEmploymentPdfPreview = () => {
  // 労働条件通知書PDFテンプレートデータ取得
  const [getVtNoticeTemplateByTpmemId, { loading, error }] = useGetVtNoticeTemplateByTpmemIdLazyQuery();

  // バイト期間終了日時フォーマット
  const formatEndTime = (beginTime?: string, endTime?: string) => {
    // 開始日時または終了日時がない場合は終了日時を返す
    if (!beginTime || !endTime) return endTime;

    // 開始日時をパース
    const startDate = parse(String(beginTime), 'yyyy年MM月dd日 HH:mm', new Date(), { locale: ja });
    // 終了日時をパース
    const endDate = parse(String(endTime), 'yyyy年MM月dd日 HH:mm', new Date(), { locale: ja });

    // 開始日時が終了日時以前の場合は終了日時を返す
    if (startDate.getTime() <= endDate.getTime()) return endTime;

    // 上記以外の場合、終了日時に1日を足して返す
    return format(add(endDate, { days: 1 }), 'yyyy年MM月dd日 HH:mm', { locale: ja });
  };

  // 労働条件通知書PDFプレビュー処理
  const previewPdf = async (tpmemId: number, pdfProps: NoticeOfEmploymentPdfProps) => {
    try {
      // 労働条件通知書テンプレート取得
      const data = await getVtNoticeTemplateByTpmemId({
        variables: {
          tpmem_id: tpmemId,
        },
      });
      const template = data.data?.getVTNoticeTemplateByTpmemId;

      // データが取得できなかった場合は処理を抜ける
      if (!template || !template.tpmem_id) return { success: false, error: 'PDFテンプレートの取得に失敗しました。' };

      // PDFテンプレート設定
      pdfProps.userName = template?.employer || '';
      pdfProps.userAddress = template?.employer_address || '';
      pdfProps.workingAddress = template?.tpmem_address || '';
      pdfProps.socialInsurance = template?.social_insurance || '';
      pdfProps.passiveSmokingMeasure = template?.prevent_ets || '';
      pdfProps.isWorkSmokingArea = Boolean(template?.in_smoking_area);
      // 終了日時をフォーマット
      pdfProps.endTime = formatEndTime(pdfProps.beginTime, pdfProps.endTime);
      // 金額項目をカンマフォーマット
      if (pdfProps.hourWage) pdfProps.hourWage = Number(pdfProps.hourWage).toLocaleString();
      if (pdfProps.transFee) pdfProps.transFee = Number(pdfProps.transFee).toLocaleString();

      // PDFデータ作成
      const buffer = await createNoticeOfEmploymentPdf(pdfProps);
      if (!buffer) return { success: false, error: 'PDFの作成に失敗しました。' };

      // PDFプレビュー
      preview(buffer);

      return { success: true };
    } catch (e) {
      console.log(e);
      return { success: false, error: 'プレビュー処理に失敗しました。' };
    }
  };

  return {
    previewPdf,
    loading,
    error,
  };
};

// 源泉徴収票PDFデータ取得用フック
export const useWithholdSlipPdfDownload = () => {
  // 源泉徴収票PDFデータ取得
  const [getVbWithholdingSlipDataByGcbIdCn, { loading, error }] = useGetVbWithholdingSlipDataByGcbIdCnLazyQuery();

  // 源泉徴収票PDFデータ取得
  // 例外時やデータが取得できなかった場合はエラーを返す
  const downloadPdf = async (btmemId: number, year: number, corporateNo: string) => {
    try {
      // 源泉徴収票データ取得
      const data = await getVbWithholdingSlipDataByGcbIdCn({
        variables: {
          btmem_id: btmemId,
          gregorian_cal: year,
          corporate_no: corporateNo,
        },
      });
      const pdfData = data.data?.getVBWithholdingSlipDataByGCBIdCN;

      // データが取得できなかった場合はエラーを返す
      if (!pdfData || !pdfData.btmem_id) return { success: false, error: 'PDFデータの取得に失敗しました。' };

      // 抽出したデータをPDFのプロパティに設定
      const pdfProps: WsPdfProps = {
        year: pdfData?.gregorian_cal || undefined,
        address: pdfData?.btmem_address || undefined,
        nameKana: pdfData?.btmem_kana || undefined,
        name: pdfData?.btmem_name || undefined,
        paymentPrice: pdfData?.salary || undefined,
        withholdingPrice: pdfData?.tax_amount || undefined,
        payerAddress: pdfData?.corp_address || undefined,
        payerName: pdfData?.corp_name || undefined,
        payerPhone: pdfData?.corp_phone || undefined,
      };

      // PDF作成
      const pdfBuffer = await createWithholdingSlipPdf(pdfProps);
      // PDF作成に失敗した場合はエラーを返す
      if (!pdfBuffer) return { success: false, error: 'PDFの作成に失敗しました。' };

      // PDFダウンロード
      download(pdfBuffer, `${pdfProps.name || ''}_源泉${year}年分.pdf`);

      return { success: true };
    } catch (e) {
      console.log(e);
      return { success: false, error: 'ダウンロード処理に失敗しました。' };
    }
  };

  return {
    downloadPdf,
    loading,
    error,
  };
};
