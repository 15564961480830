import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { RejectionDataModel } from '../../types';

/**
 * Figma ID
 * 04-02-06-09
 * 否認確認
 */

function RejectionConfirmationModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onClickBack: () => void;
  onClickSubmit: () => void;
  rejectionData: RejectionDataModel;
  totalSalary: number;
  isExceededSalary: boolean;
}) {
  const { isOpen, onModalClose, onClickBack, onClickSubmit, rejectionData, totalSalary, isExceededSalary } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const formattedCheckInTime = `${rejectionData.checkInHour}:${rejectionData.checkInMin}`;
  const formattedCheckOutTime = `${rejectionData.checkOutHour}:${rejectionData.checkOutMin}`;
  const numberFormatter = Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
  let formattedCheckInDate = '';
  if (rejectionData.checkInDate) {
    formattedCheckInDate = `${rejectionData.checkInDate.getFullYear()}年${
      rejectionData.checkInDate.getMonth() + 1
    }月${rejectionData.checkInDate?.getDate()}(${dayOfWeek[rejectionData.checkInDate?.getDay()]})`;
  }

  // 年月日を比べたいだけなので、日付には適当な同じ値を入れる
  const isMultipleDay =
    new Date(
      rejectionData.checkInDate.getFullYear(),
      rejectionData.checkInDate.getMonth(),
      rejectionData.checkInDate.getDate(),
      0,
      0
    ) <
    new Date(
      rejectionData.checkOutDate.getFullYear(),
      rejectionData.checkOutDate.getMonth(),
      rejectionData.checkOutDate.getDate(),
      0,
      0
    );

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        否認 確認
      </Text>
    ),
    content: !isMobile ? (
        isExceededSalary ? (
          <Box display="flex" flexDirection="column" gap={12}>
            <Box>
              <Text variant="body14" color="cautionRed">
                業務報酬合計が1日の報酬最大金額を超えました。戻るを押して勤務時間を修正してください。
              </Text>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  提案修正時間
                </Text>
              </Box>
              <Box>
                <Text variant="body14">
                  {formattedCheckInDate} {formattedCheckInTime} ~ {isMultipleDay && '翌'}
                  {formattedCheckOutTime}&nbsp;&nbsp;休憩
                  {rejectionData.breakTime}
                </Text>
              </Box>
            </Box>
            <Box display="flex">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  業務報酬合計（交通費込み）
                </Text>
              </Box>
              <Box>
                <Text variant="body14" color="cautionRed">
                  1日の報酬最大金額を超えています (交通費を除いた額が\24,001以上です)
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={12}>
            <Box>
              <Text variant="body14" color="black">
                以下の内容で送信します。
              </Text>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  提案修正時間
                </Text>
              </Box>
              <Box>
                <Text variant="body14">
                  {formattedCheckInDate} {formattedCheckInTime} ~ {isMultipleDay && '翌'}
                  {formattedCheckOutTime}&nbsp;&nbsp;休憩
                  {rejectionData.breakTime}
                </Text>
              </Box>
            </Box>
            <Box display="flex">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  業務報酬合計（交通費込み）
                </Text>
              </Box>
              <Box>
                <Text variant="body14">{numberFormatter.format(totalSalary)}</Text>
              </Box>
            </Box>
            <Box display="flex">
              <Box width={184}>
                <Text variant="body14" color="darkGray">
                  修正依頼理由
                </Text>
              </Box>
              <Box>
                <Text variant="body14">{rejectionData.comment}</Text>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box>
              <Text variant="body14" color="black">
                よろしいですか？
              </Text>
            </Box>
          </Box>
        )
    ) : (
      /* feat_screen_04-02-06-09_start */
      isExceededSalary ? (
        <Box display="flex" flexDirection="column" gap={16}>
          <Box display='flex'>
            <Text variant="body14" color="cautionRed">
              業務報酬合計が1日の報酬最大金額を超えました。戻るを押して勤務時間を修正してください。
            </Text>
          </Box>
          <Divider option="horizontal" />
          <Box display="flex" justifyContent='space-between'>
            <Box width={260}>
              <Text variant="body14" color="darkGray">
                提案修正時間
              </Text>
            </Box>
            <Box display='flex'>
              <Text variant="body14" align='right'>
                {formattedCheckInDate} {formattedCheckInTime} ~ {isMultipleDay && '翌'}
                {formattedCheckOutTime}&nbsp;&nbsp;休憩
                {rejectionData.breakTime}
              </Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box width={260} display="flex" flexDirection="column">
              <Text variant="body14" color="darkGray">
                業務報酬合計
              </Text>
              <Text variant="body14" color="darkGray">
                (交通費込み)
              </Text>
            </Box>
            <Box display='flex'>
              <Text variant="body14" color="cautionRed" align='right'>
                1日の報酬最大金額を超えています (交通費を除いた額が\24,001以上です)
              </Text>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={16}>
          <Box display='flex'>
            <Text variant="body14" color="black">
              以下の内容で送信します。
            </Text>
          </Box>
          <Divider option="horizontal" />
          <Box display="flex" justifyContent='space-between'>
            <Box display='flex'>
              <Text variant="body14" color="darkGray">
                提案修正時間
              </Text>
            </Box>
            <Box display='flex' width={160}>
              <Text variant="body14" align='right'>
                {formattedCheckInDate} {formattedCheckInTime} ~ {isMultipleDay && '翌'}
                {formattedCheckOutTime}&nbsp;&nbsp;休憩
                {rejectionData.breakTime}
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Text variant="body14" color="darkGray">
                業務報酬合計
              </Text>
              <Text variant="body14" color="darkGray">
                (交通費込み)
              </Text>
            </Box>
            <Box>
              <Text variant="body14">{numberFormatter.format(totalSalary)}</Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent='space-between'>
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                修正依頼理由
              </Text>
            </Box>
            <Box display='flex'>
              <Text variant="body14" align='right'>{rejectionData.comment}</Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <Box display='flex'>
            <Text variant="body14" color="black">
              よろしいですか？
            </Text>
          </Box>
        </Box>
      )
      /* feat_screen_04-02-06-09 */
    ),
    footer: !isMobile ? (
      isExceededSalary ? (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClickBack()}>
            戻る
          </MainButton>
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClickBack()}>
            戻る
          </MainButton>
          <MainButton width={104} thin onClick={() => onClickSubmit()}>
            送信
          </MainButton>
        </Box>
      )
    ) : (
      /* feat_screen_04-02-06-09_start */
      isExceededSalary ? (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClickBack()}>
            戻る
          </MainButton>
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClickBack()}>
            戻る
          </MainButton>
          <MainButton width={96} customHeight={32} thin onClick={() => onClickSubmit()}>
            送信
          </MainButton>
        </Box>
      )
      /* feat_screen_04-02-06-09_end */
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      /* feat_screen_04-02-06-09_start */
      width={!isMobile ? 800 : "100%"}
      /* feat_screen_04-02-06-09_end */
    />
  );
}

export default RejectionConfirmationModal;
