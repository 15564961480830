import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-05-05
 * 退会できませんモーダル
 */

function CannotCancelModal(props: { isOpen: boolean; onModalClose: () => void }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, onModalClose } = props;

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        退会できません
      </Text>
    ),
    content: (
      <Box>
        {/* feat screen 09-05-05 start */}
        <Text display={!isMobile ? "inline" : "block"} variant="body14" color="black">
          採用中、自店所属の採用、もしくはウォレットに残金があるため退会できません。
        </Text>
        {/* feat screen 09-05-05 end */}
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {/* feat screen 09-05-05 start */}
        <MainButton width={!isMobile ? 104 : 96} variant="secondary" thin={!isMobile} onClick={() => onModalClose()}>
          閉じる
        </MainButton>
        {/* feat screen 09-05-05 end */}
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default CannotCancelModal;
