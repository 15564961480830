/**
 * @param textDate 'YYYY-MM-DD hh:mm'
 * @returns ISO 8601形式の文字列
 * "yyyy-MM-dd H:m" の形式を受け付けないSafari用対応
 */

export const DateFormatForIso8601 = (textDate: string) => {
  if (!textDate) return '';
  // ISO 8601 形式に変換 ("yyyy-MM-ddTHH:mm")
  const [datePart, timePart] = textDate.split(' ');
  // 時間 (H:m) を "HH:mm" 形式に変換
  const [hours, minutes] = timePart.split(':');
  const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  // ISO 8601 形式の日付文字列を作成
  return `${datePart}T${formattedTime}`;
};
