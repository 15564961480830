export enum FavoriteFlag {
  TRUE = 1,
  FALSE = 0,
}

export enum BlockFlag {
  TRUE = 1,
  FALSE = 0,
}

export enum MemberType {
  MEMBER = 1,
  PERMANENTLY_DISABLE = 2,
  TEMPORARILY_DISABLE = 3,
  SUSPENSION = 4,
}

export enum StoreType {
  OTHER_STORE = 0,
  GROUP = 3,
}

export enum BelongType {
  OWN_STORE = 1,
  SUB = 2,
}

export enum EmployeeType {
  OTHER_STORE = 0,
  OWN_STORE = 1,
  SUB = 2,
  GROUP = 3,
}
