import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';

type ContentTitleModel = {
  title: string;
  // feat screen 02-02-05 start
  width?: string | number;
  // feat screen 02-02-05 end
};

export function ContentTitle(props: ContentTitleModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  // feat screen 02-02-05 start
  const { title , width } = props;
  // feat screen 02-02-05 end
    // feat_screen_02-02_01_start
  return !isMobile ? (
    <Box display="flex" flexDirection="column" gap={4}>
      <Text bold variant="body16" color="primaryBlue">
        {title}
      </Text>
      <Divider option="horizontal" length={376} />
    </Box>
  ) : (
    // feat screen 02-02-05 start
    <Box display="flex" flexDirection="column" gap={16} width={width}>
    {/* feat screen 02-02-05 end */}
      <Text bold variant="body16" color="primaryBlue">
        {title}
      </Text>
      <Divider option="horizontal" length={0} />
    </Box>
  );
  // feat_screen_02-02_01_end 
}
