import { TextLink } from 'components/textLink';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import {
  VtArbeitOfferInfo,
  VtBtmemBase,
  VtBtSkillCheck,
  useGetVtBtmemBaseSkillByEntryIdLazyQuery,
} from 'graphql/graphql-mw';
import { convertRecruitTypeToChipColor, convertRecruitTypeToLabel } from 'pages/s04-02/components/common';
import React, { useEffect, useState } from 'react';
import { RecruitType } from 'pages/s04-02/components/const';
import { Chip } from 'components/chip';
import { useNoticeOfEmploymentPdfDownload } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Divider } from 'components/newDivider';
import { AgreementStatus } from 'components/const';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import useIsMobile from 'hooks/responsive/useIsMobile';
import BasicInfoModal from './modal/BasicInfoModal';
import { SkillData } from './modal/SkillCheck';

function convertSkillCheckToSkillData(skillCheckData: VtBtSkillCheck[]): SkillData[] {
  const skillData: SkillData[] = [];

  skillCheckData.forEach((skill) => {
    const existingSkills = skillData.find((s) => s.label === skill.name);
    if (existingSkills) {
      existingSkills.skills.push({
        name: skill.check_item || '',
        checked: skill.checked === 1,
      });
    } else {
      skillData.push({
        label: skill.name || '',
        skills: [
          {
            name: skill.check_item || '',
            checked: skill.checked === 1,
          },
        ],
      });
    }
  });

  return skillData;
}

/**
 * Figma ID: 04-02-01-02,04-02-07-02,04-02-08-02,04-02-09-02
 * 名称: 募集情報
 */

function RecruitInfo(props: {
  data: VtArbeitOfferInfo & {
    entry_id: number;
    type?: number | null;
    btmem_id: number;
    btmem_name: string;
    workingAgreementStatus: AgreementStatus;
  };
}) {
  const { data } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [btMemBaseInfo, setBtMemBaseInfo] = useState<VtBtmemBase>();
  const [skillCheckData, setSkillCheckData] = useState<VtBtSkillCheck[]>([]);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  const [getBtmemDataQuery, { error: baseSkillError }] = useGetVtBtmemBaseSkillByEntryIdLazyQuery({
    variables: {
      entry_id: data.entry_id,
    },
    fetchPolicy: 'no-cache',
  });
  // エラー処理 スキルチェック情報
  React.useEffect(() => {
    if (baseSkillError && data.entry_id > 0) {
      openErrorModal({
        message: 'スキルチェック情報が取得できませんでした。',
      });
    }
  }, [baseSkillError, openErrorModal, data.entry_id]);

  useEffect(() => {
    getBtmemDataQuery().then((res) => {
      const baseInfo = res.data?.getVTBtmemBaseSkillByEntryId.base;
      const skillInfo = res.data?.getVTBtmemBaseSkillByEntryId.skill;
      setBtMemBaseInfo(baseInfo);
      setSkillCheckData(skillInfo || []);
    });
  }, [getBtmemDataQuery, data.entry_id]);

  // [hook] 労働条件通知書PDFダウンロード
  const { downloadPdf, loading: downloadLoading } = useNoticeOfEmploymentPdfDownload();

  // [onClick] 労働条件通知書PDFダウンロード
  const onClickPdfDownload = () => {
    (async () => {
      if (typeof data.id !== 'number') return;

      try {
        // ロード中は処理しない(2重クリック防止)
        if (downloadLoading) return;

        // PDFダウンロード処理
        const result = await downloadPdf(data.id);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        console.error(error);
        openErrorModal({ message: 'ダウンロード処理に失敗しました。' });
      }
    })();
  };

  return !isMobile ? (
    <>
      <Box px={16} pt={0} pb={72} width="100%">
        <Box py={16}>
          <Text variant="h2" color="darkBlue">
            募集情報
          </Text>
        </Box>
        <Divider option="horizontal" />
        <ScrollableArea>
          <Info
            items={[
              {
                content: data.offer_number,
                label: '掲載No.',
              },
              {
                content: data.title,
                label: '掲載タイトル',
              },
              {
                content: data.name,
                label: '募集店',
              },
              {
                content: (
                  <Box display="flex" flexDirection="row" alignItems="center" gap={8}>
                    <Chip color={convertRecruitTypeToChipColor(data.type as RecruitType)}>
                      {convertRecruitTypeToLabel(data.type as RecruitType)}
                    </Chip>
                    <TextLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                      }}
                      variant="body14"
                    >
                      {data?.btmem_id?.toString().padStart(10, '0')}
                    </TextLink>
                  </Box>
                ),
                label: '採用者会員番号',
              },
              {
                content: data.btmem_name,
                label: '採用者',
              },

              {
                content: data.begin_end,
                label: 'バイト期間',
              },
              {
                content: data.post_begin,
                label: '募集開始',
              },
              {
                content: data.post_end,
                label: '募集締切',
              },
              {
                content: `${data.break_time || 0}分`,
                label: '休憩時間',
              },
              {
                content: `¥${data.hour_wage_disp || 0}`,
                label: '時給',
              },
              {
                content: data.chief,
                label: '当日の店舗責任者',
              },
              {
                content: `¥${data.trans_fee || 0}`,
                label: '交通費',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.description)}
                  </Text>
                ),
                label: '業務詳細',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.conditions)}
                  </Text>
                ),
                label: '働くための条件',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.caution)}
                  </Text>
                ),
                label: '注意事項',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.belongings)}
                  </Text>
                ),
                label: '持ち物',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.access)}
                  </Text>
                ),
                label: 'アクセス',
              },
              {
                content: (
                  <TextLink variant="body14" onClick={onClickPdfDownload}>
                    労働条件通知書
                  </TextLink>
                ),
                label: '労働条件通知書',
              },
              {
                content: (
                  <Box alignItems="center" display="flex" gap={8}>
                    {data.image1 && <img alt="sampleImage" height={94} src={data.image1} width={126} />}
                    {data.image2 && <img alt="sampleImage" height={94} src={data.image2} width={126} />}
                    {data.image3 && <img alt="sampleImage" height={94} src={data.image3} width={126} />}
                    {data.image4 && <img alt="sampleImage" height={94} src={data.image4} width={126} />}
                    {data.image5 && <img alt="sampleImage" height={94} src={data.image5} width={126} />}
                  </Box>
                ),
                label: 'メイン画像',
              },
              {
                /** TODO: 添付ファイルURLからファイル名を取得する必要あり。良いライブラリがあるか？ */
                content: data.attached_file ? (
                  <Text variant="body14">
                    <TextLink href={data.attached_file || ''} variant="body14">
                      添付ファイル
                    </TextLink>
                  </Text>
                ) : (
                  <Text variant="body14">なし</Text>
                ),
                label: '添付ファイル',
              },
            ]}
          />
        </ScrollableArea>
      </Box>
      {btMemBaseInfo && (
        <BasicInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          workingAgreementStatus={data.workingAgreementStatus}
          info={btMemBaseInfo}
          skills={convertSkillCheckToSkillData(skillCheckData)}
        />
      )}
    </>
  ) : (
    /* feat_screen_04-02-01-02_start */
    <>
      <Box px={12} pt={0} width="100%" height="calc(100% - 52px)" overflow="auto" >
        <Box py={14} display="flex" justifyContent="center">
          <Text variant="h2" color="darkBlue">
            募集情報
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Info
          items={[
            {
              content: data.offer_number,
              label: '掲載No.',
            },
            {
              content: data.title,
              label: '掲載タイトル',
            },
            {
              content: data.name,
              label: '募集店',
            },
            {
              content: (
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" gap={8} mr={-4}>
                  <Box mr={-8} mt={-6}>
                    <Chip color={convertRecruitTypeToChipColor(data.type as RecruitType)} width={60}>
                      {convertRecruitTypeToLabel(data.type as RecruitType)}
                    </Chip>
                  </Box>
                  <TextLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsModalOpen(true);
                    }}
                    variant="body14"
                  >
                    {data?.btmem_id?.toString().padStart(10, '0')}
                  </TextLink>
                </Box>
              ),
              label: '採用者会員番号',
            },
            {
              content: data.btmem_name,
              label: '採用者',
            },

            {
              content: data.begin_end,
              label: 'バイト期間',
            },
            {
              content: data.post_begin,
              label: '募集開始',
            },
            {
              content: data.post_end,
              label: '募集締切',
            },
            {
              content: `${data.break_time || 0}分`,
              label: '休憩時間',
            },
            {
              content: `¥${data.hour_wage_disp || 0}`,
              label: '時給',
            },
            {
              content: data.chief,
              label: '当日の店舗責任者',
            },
            {
              content: `¥${data.trans_fee || 0}`,
              label: '交通費',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.description)}
                  </Text>
                </Box>
              ),
              label: '業務詳細',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.conditions)}
                  </Text>
                </Box>
              ),
              label: '働くための条件',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.caution)}
                  </Text>
                </Box>
              ),
              label: '注意事項',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.belongings)}
                  </Text>
                </Box>
              ),
              label: '持ち物',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Text wordBreak="break-all" variant="body14">
                    {charcterReplaceBr(data.access)}
                  </Text>
                </Box>
              ),
              label: 'アクセス',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <TextLink variant="body14" onClick={onClickPdfDownload}>
                    労働条件通知書
                  </TextLink>
                </Box>
              ),
              label: '労働条件通知書',
            },
            {
              content: (
                <Box alignItems="flex-end" display="flex" flexDirection="column" gap={8}>
                  {data.image1 && <img alt="sampleImage" height={94} src={data.image1} width={126} />}
                  {data.image2 && <img alt="sampleImage" height={94} src={data.image2} width={126} />}
                  {data.image3 && <img alt="sampleImage" height={94} src={data.image3} width={126} />}
                  {data.image4 && <img alt="sampleImage" height={94} src={data.image4} width={126} />}
                  {data.image5 && <img alt="sampleImage" height={94} src={data.image5} width={126} />}
                </Box>
              ),
              label: 'メイン画像',
            },
            {
              /** TODO: 添付ファイルURLからファイル名を取得する必要あり。良いライブラリがあるか？ */
              content: data.attached_file ? (
                <Text variant="body14">
                  <TextLink href={data.attached_file || ''} variant="body14">
                    添付ファイル
                  </TextLink>
                </Text>
              ) : (
                <Text variant="body14">なし</Text>
              ),
              label: '添付ファイル',
            },
          ]}
        />
      </Box>
      {btMemBaseInfo && (
        <BasicInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          workingAgreementStatus={data.workingAgreementStatus}
          info={btMemBaseInfo}
          skills={convertSkillCheckToSkillData(skillCheckData)}
        />
      )}
    </>
    /* feat_screen_04-02-01-02_end */
  );
}

export default RecruitInfo;
