import axios from 'axios';

export type ReportNewStoreProps = {
  managerName: string;
  mailAddress: string;
  bizName: string;
  brandName: string;
  tpmemName: string;
  zipCode: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  phone: string;
  lat: string;
  lng: string;
  comment: string;
};

export default async function reportNewStore(props: ReportNewStoreProps) {
  const {
    managerName,
    mailAddress,
    bizName,
    brandName,
    tpmemName,
    zipCode,
    prefecture,
    city,
    address1,
    address2,
    phone,
    lat,
    lng,
    comment,
  } = props;

  const headers = {
    'x-api-key': process.env.REACT_APP_STORE_REPORT_API_KEY,
  };

  const data = {
    fieldName: 'sendMailRequestNewStore',
    arguments: {
      from_name: managerName,
      from_email: mailAddress,
      biz_name: bizName,
      brand_name: brandName,
      store_name: tpmemName,
      zip_code: zipCode,
      state: prefecture,
      city,
      address1,
      address2,
      phone_no: phone,
      lat,
      lng,
      comment,
    },
  };
  const response = await axios.post(process.env.REACT_APP_STORE_REPORT_API_URL || '', data, {
    headers,
  });
  return response;
}
