/**
 * Figma ID: 04-03-03-03, 04-03-03-04
 * 名称: 募集削除
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VtBtmemOfferInfo } from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Modal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { Box } from 'components/box';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ModalZindex } from 'components/const';
import { useDeleteOfferInfoConnectApi } from '../../../common/hooks';

enum ModalStep {
  DELETE = 1,
  DONE = 2,
}

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  dispInfoData?: VtBtmemOfferInfo;
}

export function DeleteModalComponent({ isOpen, onClose, dispInfoData }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const [modalStep, setModalStep] = useState<number>(ModalStep.DELETE);
  const { openErrorModal } = useErrorModal();

  const { updateTArbeitOfferStatus6ByIdMutation, setAcceptOfferId } = useDeleteOfferInfoConnectApi();

  useEffect(() => {
    if (!dispInfoData?.accept_offer_id) return;
    setAcceptOfferId(dispInfoData?.accept_offer_id);
  }, [dispInfoData?.accept_offer_id, setAcceptOfferId]);

  const navigate = useNavigate();

  const clearEvent = (isDone: boolean) => {
    setModalStep(ModalStep.DELETE);
    onClose();
    /**
     * 画面をリロード
     */
    if (isDone) navigate(0);
  };

  const submitDelete = () => {
    updateTArbeitOfferStatus6ByIdMutation()
      .then((result) => {
        if (!result.errors) {
          setModalStep(ModalStep.DONE);
        } else {
          openErrorModal({
            message: '募集の削除に失敗しました',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        }
      })
      .catch(() => {
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          zIndex: ModalZindex.ERRMODALZINDEX,
        });
      });
  };

  function displayModal() {
    switch (modalStep) {
      case ModalStep.DELETE:
        return {
          // feat screen 04-03-02-03 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-03-02-03 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集削除
              </Text>
            </Box>
          ),
          content: <Text variant="body14">このバイトラベラーへの逆オファー募集を削除しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-03-03 start */}
              <MainButton width={!isMobile ? 104 :96} variant="secondary" onClick={() => clearEvent(false)}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 :96} onClick={() => submitDelete()}>
                削除
              </MainButton>
              {/* feat screen 04-03-03-03 end */}
            </Box>
          ),
        };
      case ModalStep.DONE:
        return {
          // feat screen 04-03-02-03 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 189 : 'unset',
          // feat screen 04-03-02-03 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集削除 完了
              </Text>
            </Box>
          ),
          content: <Text variant="body14">逆オファーの募集削除が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-03-03-04 start */}
              <MainButton width={!isMobile ? 104 :96} variant="secondary" onClick={() => clearEvent(true)}>
                閉じる
              </MainButton>
              {/* feat screen 04-03-03-04 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 189,
          header: null,
          content: null,
          footer: null,
        };
    }
  }
  const modalContents = displayModal();

  return (
    <Modal
      width={modalContents?.width}
      height={modalContents?.height}
      header={modalContents?.header}
      content={modalContents?.content}
      footer={modalContents?.footer}
      isOpen={isOpen}
      onClose={() => clearEvent(false)}
    />
  );
}
