/**
 * Figma ID: 01-01-02
 * 名称: 店舗選択
 */
import React, { ReactNode, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroller';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { Input } from 'components/input';
import { InputLabel } from 'components/inputLabel';
import { Icon } from 'components/icon';
import { styled } from '@linaria/react';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Select } from 'components/select';
import { Loading } from 'components/loading/loading';
import { RadioGroup } from 'components/radioGroup';
import {
  useGetMCityByStateIdLazyQuery,
  useGetMStateQuery,
  useGetVtSelectStoreListByBrandIdQuery,
} from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ContentTitle } from './component/ContentTitle';

const CustomButton = styled.button<{
  width?: number;
  color?: string;
  border?: string;
  borderRadius?: number;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 30px;
  height: 32px;
  background: ${({ color = PRIMARY_COLOR.PRIMARY_BLUE }) => color};
  border: 1px solid ${({ border = 0 }) => border};
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  /* feat screen_01-01-02_end */
`;

type Props = {
  toNext: (tpmemId: number) => void;
  toBack: () => void;
  toStopRegistration: (tpmemId: number) => void;
  brandId: number;
  defaultTpmemId?: number;
};

export function SelectStore({ toNext, toBack, toStopRegistration, brandId, defaultTpmemId }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const [tpmemListDataLength, setTpmemListDataLength] = React.useState<number>(0);
  const [tpmemOptionsPage, setTpmemOptionsPage] = React.useState<number>(1);
  const [searchTpmemName, setSearchTpmemName] = React.useState<string>('');
  const [searchPhoneNumber, setSearchPhoneNumber] = React.useState<string>('');
  const [prefectureId, setPrefectureId] = React.useState<string>('');
  const [cityId, setCityId] = React.useState<string>('');
  const [searchCondition, setSearchCondition] = React.useState<{
    tpmemName: string;
    phoneNumber: string;
    prefecture: string;
    city: string;
  } | null>(null);

  const handleSetSearchCondition = (
    condition: {
      tpmemName: string;
      phoneNumber: string;
      prefecture: string;
      city: string;
    } | null
  ) => {
    setSearchCondition(condition);
    // ページを初期化
    setTpmemOptionsPage(1);
  };

  const [cityOptions, setCityOptions] = React.useState<{ label: string; value: string }[]>([]);
  const [tpmemId, setTpmemId] = React.useState<string>('');
  const { openErrorModal } = useErrorModal();
  const {
    data: { getVTSelectStoreListByBrandId } = { getTBaseListByBrandId: [] },
    loading: tpmemDataListLoading,
    error: tpmemDataListLoadingError,
  } = useGetVtSelectStoreListByBrandIdQuery({
    variables: { brand_id: brandId },
    context: { clientName: 'master' },
  });
  const { data: { getMState } = { getMState: [] }, error: getMStateError } = useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [fetchMCityByStateId, { error: fetchMCityByStateIdError }] = useGetMCityByStateIdLazyQuery();
  React.useEffect(() => {
    if (tpmemDataListLoadingError || getMStateError || fetchMCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [tpmemDataListLoadingError, getMStateError, fetchMCityByStateIdError, openErrorModal]);

  useEffect(() => {
    if (prefectureId) {
      fetchMCityByStateId({
        context: { clientName: 'master' },
        variables: { sid: Number(prefectureId) },
      }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
        if (getMCityByStateId) {
          setCityOptions(
            getMCityByStateId.map((item) => ({
              label: item?.name ?? '',
              value: item?.id ? String(item.id) : '',
            }))
          );
        }
      });
    }
  }, [prefectureId, fetchMCityByStateId]);

  useEffect(() => {
    if (defaultTpmemId) {
      setTpmemId(String(defaultTpmemId));
    }
  }, [defaultTpmemId]);

  const prefectureOptions = useMemo<{ label: string; value: string }[]>(() => {
    if (!getMState) {
      return [];
    }
    return getMState.map((item) => ({
      label: item?.name ?? '',
      value: item?.id ? String(item.id) : '',
    }));
  }, [getMState]);
  const tpmemOptions = useMemo<{ label: ReactNode; value: string }[]>(() => {
    if (tpmemDataListLoading || getVTSelectStoreListByBrandId?.length === 0) {
      return [];
    }
    const filteredList = getVTSelectStoreListByBrandId?.filter(
      (item) =>
        item.name?.includes(searchCondition?.tpmemName ?? '') &&
        item.phone?.includes(searchCondition?.phoneNumber ?? '') &&
        item.city?.includes(searchCondition?.city ?? '') &&
        item.state?.includes(searchCondition?.prefecture ?? '')
    );

    setTpmemListDataLength(filteredList?.length ?? 0);

    return (
      filteredList
        ?.map((item) => ({
          label: (
            // feat screen_01-01-02_start
            <div>
              {!isMobile ? (
                <Box display="flex" width="100%" gap={16} key={item.id}>
                  <Text variant="caption12">{item.name}</Text>
                  <Text variant="caption12">
                    〒{item.zip_code}
                    {item.state}
                    {item.city}
                    {item.address1}
                    {item.address2}
                  </Text>
                  <Text variant="caption12">{item.phone}</Text>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" gap={2} width="100%" key={item.id}>
                  <Text variant="body14">{item.name}</Text>
                  <Text variant="body14">
                    〒{item.zip_code}
                    {item.state}
                    {item.city}
                    {item.address1}
                    {item.address2}
                  </Text>
                  <Text variant="body14">{item.phone}</Text>
                </Box>
              )}
            </div>
            // feat screen_01-01-02_end
          ),
          value: String(item.id),
        }))
        .slice(0, 10 * tpmemOptionsPage) ?? []
    );
  }, [
    getVTSelectStoreListByBrandId,
    searchCondition?.city,
    searchCondition?.phoneNumber,
    searchCondition?.prefecture,
    searchCondition?.tpmemName,
    tpmemDataListLoading,
    tpmemOptionsPage,
    isMobile,
  ]);

  return (
    // feat_screen_01-01-02_start
    <div>
      {!isMobile ? (
        <Box width={796} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 店舗選択" />
          <Box>
            <Box mb={16}>
              <Text variant="body14">登録する店舗を選択して次へを押して下さい。</Text>
              <br />
              <Text variant="body14" color="cautionRed">
                ※店舗が存在しない場合は「登録を中止」を押してください。
              </Text>
            </Box>
            <Box display="flex" alignItems="flex-end" gap={14} mb={8}>
              <Box width={200} display="flex" flexDirection="column" gap={4}>
                <InputLabel width={200}>キーワードで検索</InputLabel>
                <Box display="flex">
                  <Input
                    value={searchTpmemName}
                    placeholder="店舗名"
                    onChange={(e) => setSearchTpmemName(e.target.value)}
                    width={169}
                  />
                  <CustomButton
                    onClick={() => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', phoneNumber: '', prefecture: '' }),
                        tpmemName: searchTpmemName,
                      });
                    }}
                  >
                    <Icon name="search" />
                  </CustomButton>
                </Box>
              </Box>
              <Box width={200} display="flex" flexDirection="column" gap={4}>
                <InputLabel width={200}>電話番号で検索</InputLabel>
                <Box display="flex">
                  <Input
                    value={searchPhoneNumber}
                    placeholder="電話番号"
                    onChange={(e) => setSearchPhoneNumber(e.target.value)}
                    width={169}
                  />
                  <CustomButton
                    onClick={() => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', tpmemName: '', prefecture: '' }),
                        phoneNumber: searchPhoneNumber,
                      });
                    }}
                  >
                    <Icon name="search" />
                  </CustomButton>
                </Box>
              </Box>
              <Box width={148} display="flex" flexDirection="column" gap={4}>
                <InputLabel width={148}>都道府県</InputLabel>
                <Box display="flex">
                  <Select
                    value={prefectureId}
                    placeholder="都道府県を選択"
                    options={prefectureOptions}
                    onChange={(value, label) => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', phoneNumber: '', tpmemName: '' }),
                        prefecture: label,
                      });
                      setPrefectureId(value);
                    }}
                    width={148}
                  />
                </Box>
              </Box>
              <Box width={148} display="flex" flexDirection="column" gap={4}>
                <InputLabel width={148}>市区町村</InputLabel>
                <Box display="flex">
                  <Select
                    value={cityId}
                    placeholder="市区町村を選択"
                    options={cityOptions}
                    onChange={(value, label) => {
                      setSearchCondition({
                        ...(searchCondition ?? { prefecture: '', phoneNumber: '', tpmemName: '' }),
                        city: label,
                      });
                      setCityId(value);
                    }}
                    width={148}
                  />
                </Box>
              </Box>
              <MainButton
                variant="clear"
                width={42}
                onClick={() => {
                  setSearchCondition(null);
                  setSearchTpmemName('');
                  setSearchPhoneNumber('');
                  setPrefectureId('');
                  setCityId('');
                }}
              >
                クリア
              </MainButton>
            </Box>
            <Box>
              <Text variant="caption11" color="darkGray">
                {tpmemListDataLength}件の検索結果
              </Text>
              <Divider option="horizontal" />
              <Box width="100%" height={500} overflow="auto">
                {tpmemDataListLoading ? (
                  <Loading />
                ) : (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      setTimeout(() => {
                        setTpmemOptionsPage(tpmemOptionsPage + 1);
                      }, 500);
                    }}
                    hasMore={tpmemOptionsPage * 100 < tpmemListDataLength}
                    loader={
                      <Box my={4} display="flex" justifyContent="center">
                        <Text variant="caption12" color="blueGray">
                          Loading...
                        </Text>
                      </Box>
                    }
                    useWindow={false}
                  >
                    <RadioGroup
                      isList
                      name="selectTpmemId"
                      value={tpmemId}
                      options={tpmemOptions}
                      onChange={(event) => setTpmemId(event.target.value)}
                    />
                  </InfiniteScroll>
                )}
              </Box>
            </Box>
          </Box>
          <Box pt={30} display="flex" gap={4} justifyContent="center">
            <MainButton
              variant="warnSecondary"
              onClick={() => {
                toStopRegistration(Number(tpmemId));
              }}
            >
              登録を中止
            </MainButton>
            <MainButton variant="secondary" onClick={toBack}>
              戻る
            </MainButton>
            <MainButton
              onClick={() => {
                toNext(Number(tpmemId));
              }}
              disabled={!tpmemId}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      ) : (
        // Responsive Component
        <Box width="100vw" display="flex" flexDirection="column" px={16}>
          <ContentTitle title="新規登録 - 店舗選択" />
          <Box mt={18}>
            <Box mb={23} display="flex" flexDirection="column">
              <Text variant="body14">登録する店舗を選択して次へを押して下さい。</Text>
              <Text variant="body14" color="cautionRed">
                ※店舗が存在しない場合は「登録を中止」を押してください。
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap={16} mb={10}>
              <Box width="100%" display="flex" flexDirection="column" gap={2}>
                <InputLabel width={200}>キーワードで検索</InputLabel>
                <Box display="flex" width="100%">
                  <Input
                    flexProps={1}
                    value={searchTpmemName}
                    placeholder="店舗名"
                    onChange={(e) => setSearchTpmemName(e.target.value)}
                    fullWidth
                  />
                  <CustomButton
                    onClick={() => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', phoneNumber: '', prefecture: '' }),
                        tpmemName: searchTpmemName,
                      });
                    }}
                  >
                    <Icon name="search" />
                  </CustomButton>
                </Box>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" gap={2}>
                <InputLabel width={200}>電話番号で検索</InputLabel>
                <Box display="flex">
                  <Input
                    flexProps={1}
                    value={searchPhoneNumber}
                    placeholder="電話番号"
                    onChange={(e) => setSearchPhoneNumber(e.target.value)}
                    fullWidth
                  />
                  <CustomButton
                    onClick={() => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', tpmemName: '', prefecture: '' }),
                        phoneNumber: searchPhoneNumber,
                      });
                    }}
                  >
                    <Icon name="search" />
                  </CustomButton>
                </Box>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" gap={2}>
                <InputLabel width={148}>都道府県</InputLabel>
                <Box width="100%">
                  <Select
                    value={prefectureId}
                    height={40}
                    placeholder="都道府県を選択"
                    options={prefectureOptions}
                    onChange={(value, label) => {
                      setSearchCondition({
                        ...(searchCondition ?? { city: '', phoneNumber: '', tpmemName: '' }),
                        prefecture: label,
                      });
                      setPrefectureId(value);
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" gap={2}>
                <InputLabel width={148}>市区町村</InputLabel>
                <Box>
                  <Select
                    value={cityId}
                    placeholder="市区町村を選択"
                    height={40}
                    options={cityOptions}
                    onChange={(value, label) => {
                      setSearchCondition({
                        ...(searchCondition ?? { prefecture: '', phoneNumber: '', tpmemName: '' }),
                        city: label,
                      });
                      setCityId(value);
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" flexDirection="column" width="100%">
                <MainButton
                  variant="clear"
                  width={45}
                  customHeight={28}
                  onClick={() => {
                    setSearchCondition(null);
                    setSearchTpmemName('');
                    setSearchPhoneNumber('');
                    setPrefectureId('');
                    setCityId('');
                  }}
                >
                  クリア
                </MainButton>
              </Box>
            </Box>
            <Box>
              <Box mb={4}>
                <Text variant="body14" color="darkGray">
                  {tpmemListDataLength}件の検索結果
                </Text>
              </Box>
              <Divider option="horizontal" />
              <Box width="100%" height={400} overflow="auto">
                {tpmemDataListLoading ? (
                  <Loading />
                ) : (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      setTimeout(() => {
                        setTpmemOptionsPage(tpmemOptionsPage + 1);
                      }, 500);
                    }}
                    hasMore={tpmemOptionsPage * 100 < tpmemListDataLength}
                    loader={
                      <Box my={4} display="flex" justifyContent="center">
                        <Text variant="caption12" color="blueGray">
                          Loading...
                        </Text>
                      </Box>
                    }
                    useWindow={false}
                  >
                    <RadioGroup
                      isList
                      name="selectTpmemId"
                      value={tpmemId}
                      options={tpmemOptions}
                      onChange={(event) => setTpmemId(event.target.value)}
                    />
                  </InfiniteScroll>
                )}
              </Box>
            </Box>
          </Box>
          <Box py={24} display="flex" gap={4} justifyContent="center">
            <MainButton
              variant="warnSecondary"
              customHeight={40}
              onClick={() => {
                toStopRegistration(Number(tpmemId));
              }}
            >
              登録を中止
            </MainButton>
            <MainButton customHeight={40} variant="secondary" onClick={toBack}>
              戻る
            </MainButton>
            <MainButton
              customHeight={40}
              onClick={() => {
                toNext(Number(tpmemId));
              }}
              disabled={!tpmemId}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      )}
    </div>
    // feat_screen_01-01-02_end
  );
}
