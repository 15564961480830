// feat screen 08-01-01  custom mobile listRow start
import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { Box }  from 'components/box';

export const colorPalette = {
  white: '#FFFFFF',
  gray: '#DBDBDB',
  red: 'rgba(255, 0, 0, 0.1)',
  yellow: 'rgba(255, 184, 0, 0.1)',
} as const;

export type ColorKeyType = keyof typeof colorPalette;
type ColorValueType = typeof colorPalette[ColorKeyType];

export interface Props {
  backgroundColor?: ColorKeyType;
  rowItem: {
    width: number | string;
    content: ReactNode;
    key: string;
    hasDivider?: boolean;
  }[];
  rowHeight?: number;
  rowWidth?: number | string;
}

// feat screen_08-01-01 start
const StyledMobileCellScreen08 = styled.div<{ width: number | string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StyledMobileRowScreen08 = styled.li<{
  backgroundColor: ColorValueType;
  rowHeight?: number;
  rowWidth: number | string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ rowHeight }) => (rowHeight ? `${rowHeight}px` : 'unset')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #dbdbdb;
  width: ${({ rowWidth }) => (typeof rowWidth === 'number' ? `${rowWidth}px` : rowWidth)};
`;
// feat screen_08-01-01 end

export function MobileListRow({ rowItem, backgroundColor = 'white', rowHeight, rowWidth = 'max-content'}: Props) {
  return (
    <StyledMobileRowScreen08 backgroundColor={colorPalette[backgroundColor]} rowHeight={rowHeight} rowWidth={rowWidth}>
      <Box display="flex" width="100%">
        <Box minWidth={140} display="flex" alignItems="center">
          {typeof rowItem[0].content === 'string' || typeof rowItem[0].content === 'number' ? (
            <Text variant="caption12" lineClamp={1}>
              {rowItem[0].content}
            </Text>
          ) : (
            rowItem[0].content
          )}
        </Box>
        <Box display="flex" flexDirection="column" width="100%" gap={8}>
          {rowItem.map((item,index) => (
            index !== 0 ?
              <StyledMobileCellScreen08 key={item.key + index.toString()} width={item.width}>
                {item.hasDivider && (
                  <Box mr={14}>
                    <Divider option="vertical" length={18} />
                  </Box>
                )}
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text variant="caption12" lineClamp={1}>
                    {item.content}
                  </Text>
                ) : (
                  item.content
                )}
              </StyledMobileCellScreen08>
            : ''
          ))}
        </Box>
      </Box>
    </StyledMobileRowScreen08>
  );
}
// feat screen 08-01-01  custom mobile listRow end