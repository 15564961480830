import { Box } from "components/box";
import { MainButton } from "components/mainButton";
import { RhfInput } from "components/rhfInput";
import { RhfSelect } from "components/rhfSelect";
import { Text } from "components/text";
import useIsMobile from "hooks/responsive/useIsMobile";
import { AddBlockModalForm, ModalRet } from "pages/s05-01/type";
import React from "react";
import { Control, UseFormTrigger, useWatch } from "react-hook-form";

export function AddReason(
  control: Control<AddBlockModalForm>,
  trigger: UseFormTrigger<AddBlockModalForm>,
  reasonOptions: { label: string; value: string }[],
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const reason = useWatch({ control, name: "reason" });
  const reasonOther = useWatch({ control, name: "reason_other" });

  const isReasonOthers = reason === "その他";
  const isButtonDisabled = () => {
    if (isReasonOthers) return reasonOther === "" || !reasonOther;
    return !reason;
  };

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = !isMobile ? {
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック
      </Text>
    ),
    content: (
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        flex="1"
        gap={17.5}
        pa={8}
      >
        <Text variant="body14">ブロック理由を選択してください。</Text>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            ブロック理由
          </Text>
          <RhfSelect
            control={control}
            name="reason"
            options={reasonOptions}
            placeholder="ブロック理由を選択"
            width={752}
          />
        </Box>
        {isReasonOthers && (
          <Box display="flex" flexDirection="column" gap={5.5} height={78}>
            <Text variant="h3" color="blueGray">
              ブロック理由
            </Text>
            <RhfInput
              name="reason_other"
              control={control}
              fullWidth
              placeholder="コメントを記入"
              rules={{ required: true }}
            />
          </Box>
        )}
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => onClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          disabled={isButtonDisabled()}
          onClick={() => {
            trigger("reason");
            if (isReasonOthers) {
              trigger("reason_other");
            }
            onNext();
          }}
        >
          確定
        </MainButton>
      </Box>
    ),
    width: 800,
    height: isReasonOthers ? 377 : 283,
  } : {
  /* feat_screen_05-01-01-04_start */
    header: (
      <Text variant="h2" color="darkBlue">
        ブロック
      </Text>
    ),
    content: (
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        flex="1"
        gap={12}
      >
        <Text variant="body14">ブロック理由を選択してください。</Text>
        <Box display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            ブロック理由
          </Text>
          <RhfSelect
            control={control}
            name="reason"
            options={reasonOptions}
            placeholder="ブロック理由を選択"
            fullWidth
            height={40}
          />
        </Box>
        {isReasonOthers && (
        <Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Text variant="h3" color="blueGray">
              ブロック理由
            </Text>
            <RhfInput
              name="reason_other"
              control={control}
              fullWidth
              placeholder="コメントを記入"
              rules={{ required: true }}
            />
          </Box>
        </Box>
        )}
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          width={96}
          customHeight={32}
          variant="secondary"
          thin
          onClick={() => onClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          width={96}
          customHeight={32}
          thin
          disabled={isButtonDisabled()}
          onClick={() => {
            trigger("reason");
            if (isReasonOthers) {
              trigger("reason_other");
            }
            onNext();
          }}
        >
          確定
        </MainButton>
      </Box>
    ),
    width: "100%",
    height: isReasonOthers ? 323 : 251,
    /* feat_screen_05-01-01-04_end */
  };

  return modalContent;
}
