/**
 * Figma ID: 04-01-01-14, 04-01-01-15, 04-01-01-16
 * 名称: 応募
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@linaria/react';
import * as gql from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Tab } from 'components/newTab';
import { Divider } from 'components/newDivider';
import { Avatar } from 'components/avatar';
import { Chip } from 'components/chip';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import NoImageSmall from 'components/assets/images/no-image-small.svg';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { KindName, RecruitmentStatus, ApplicationStatus } from '../../../common/const';
import BasicInfo from './BasicInfo';
import SkillCheck from './SkillCheck';
import { CostDetails } from './CostDetails';
import { useEmployConnectApi, useRejectionConnectApi } from '../../../common/hooks';

enum ModalStep {
  DEFAULT = 0,
  CONFIRM_RECRUITMENT = 1,
  CONFIRM_RECRUITMENT_FOREIGNERS = 2,
  CONFIRM_RECRUITMENT_DONE = 3,
  CONFIRM_REJECTION = 4,
  CONFIRM_REJECTION_DONE = 5,
}

const StyledImgBox = styled.div`
  width: 80px;
  height: 60px;
  background-color: #000;
  text-align: center;
`;

const StyledHeaderBG = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
  gap: 24px;
  /* feat screen_04-01-01-14_start */
  @media only screen and (max-width: 768px) {
    gap: 16px;
    margin-bottom: 15px;
  }
  /* feat screen_04-01-01-14_end */
`;

const StyledBorderBox = styled.div`
  box-sizing: border-box;
  padding: 2px 8px;
  border-radius: 12px;
  border: 1px solid ${PRIMARY_COLOR.PRIMARY_BLUE};
  height: 22px;
  align-items: center;
  display: flex;
  /* feat screen_04-01-01-14_start */
  @media only screen and (max-width: 768px) {
    height: 16px;
  }
  /* feat screen_04-01-01-14_end */
`;

function BasicInfoModal(props: {
  isOpen: boolean;
  onClose: () => void;
  offerList?: gql.VtArbeitEntryList;
  base?: gql.VtBtmemBase;
  skill?: gql.VtBtSkillCheck[];
  cost?: gql.VtBtCostInfo;
  offerStatus?: number;
  refetchBasicInfo?: () => void;
  refetch: () => void;
}) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const navigate = useNavigate();
  const { isOpen, onClose, base, skill, cost, offerList, offerStatus, refetchBasicInfo, refetch } = props;
  const [modalStep, setModalStep] = useState<number>(ModalStep.DEFAULT);
  const moreInfoLinkBasePath = '/s04-02';
  const { openErrorModal } = useErrorModal();
  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(0);
  // feat_common_responsive_tab_end

  function generateHeader(btmem_id: number) {
    // feat screen 04-01-01-19 start
    return !isMobile ? (
      <Box display="flex" justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          {String(btmem_id).padStart(10, '0')}
        </Text>
        {offerStatus === RecruitmentStatus.OFFER_FINALIZED && (
          <MainButton
            width={104}
            variant="secondary"
            onClick={() => {
              navigate({ pathname: `${String(moreInfoLinkBasePath)}/${String(base?.offer_id)}` });
            }}
          >
            もっと見る
          </MainButton>
        )}
      </Box>
    ) : (
    // Responsive Component
      <Box>
        <div style={{position: "relative", width: "100%"}}>
          <Text variant="h2" color="darkBlue">
            {String(btmem_id).padStart(10, '0')}
          </Text>
          {offerStatus === RecruitmentStatus.OFFER_FINALIZED && (
            <div style={{position: "absolute", top: "-4px" , right: 0}}>
              <MainButton
                width={102}
                variant="secondary"
                onClick={() => {
                  navigate({ pathname: `${String(moreInfoLinkBasePath)}/${String(base?.offer_id)}` });
                }}
              >
                もっと見る
              </MainButton>
            </div>
          )}
        </div>
      </Box>
    );
    // feat screen 04-01-01-19 end
  }

  function generateContent(info: gql.VtBtmemBase, skillData?: gql.VtBtSkillCheck[], costData?: gql.VtBtCostInfo) {
    function generateBody() {
      // feat screen 04-01-01-14 start
      return !isMobile ? (
        <Box px={12} mt={8}>
          <Divider option="horizontal" />
          <Box mt={8}>
            <Tab
              items={[
                {
                  label: '基本情報',
                  content: <BasicInfo info={info} offerList={offerList} />,
                },
                {
                  label: 'スキルチェック',
                  content: <SkillCheck rank={info?.rank_name as string} skillData={skillData || []} />,
                },
                { label: '費用明細詳細', content: <CostDetails costData={costData} /> },
              ]}
            />
          </Box>
        </Box>
      ) : (
        // Responsive Component
        <Box>
          <Box>
            <Dropdown
              tab={tab}
              setTab={setTab}
              fullWidth
              items={[
                {
                  label: '基本情報',
                  content: <BasicInfo info={info} offerList={offerList} />,
                },
                {
                  label: 'スキルチェック',
                  content: <SkillCheck rank={info?.rank_name as string} skillData={skillData || []} />,
                },
                { label: '費用明細詳細', content: <CostDetails costData={costData} /> },
              ]}
            />
          </Box>
        </Box>
      );
      // feat screen 04-01-01-14 end
    }

    // feat screen 04-01-01-14 start
    const top = !isMobile ? (
      <StyledHeaderBG>
        <Avatar src={info?.image || NoImageSmall} width={80} height={60} />
        <Box display="flex" flexDirection="column" width="100%" gap={1}>
          <Box display="flex" gap={8} width={56}>
            {offerList?.status === ApplicationStatus.OFFERED && <Chip color="okBlue">採用確定</Chip>}
            {offerList?.status === ApplicationStatus.REJECTED && <Chip color="liteGray">不採用</Chip>}
            <Chip color={offerList?.kind_name === KindName.GENERAL ? 'okBlue' : 'darkBlue'}>
              {offerList?.kind_name}
            </Chip>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="row" gap={16} alignItems="center">
              <Text variant="h1">{String(info.btmem_id).padStart(10, '0')}</Text>
              {base?.favorite === 1 && (
                <StyledBorderBox>
                  <Text variant="caption12" bold color="primaryBlue">
                    お気に入り
                  </Text>
                </StyledBorderBox>
              )}
            </Box>

            {info?.status === 0 && (
              <Box display="flex" gap={8}>
                <MainButton onClick={() => setModalStep(ModalStep.CONFIRM_REJECTION)} variant="warnSecondary">
                  不採用
                </MainButton>
                <MainButton
                  icon="howToReg"
                  iconColor="#fff"
                  /**
                   * base?.period_dateは外国人応募者
                   */
                  onClick={() =>
                    base?.period_date
                      ? setModalStep(ModalStep.CONFIRM_RECRUITMENT_FOREIGNERS)
                      : setModalStep(ModalStep.CONFIRM_RECRUITMENT)
                  }
                  variant="primary"
                >
                  採用
                </MainButton>
              </Box>
            )}
          </Box>
        </Box>
      </StyledHeaderBG>
    ) : (
      // Responsive Component
      <Box display="flex" flexDirection="column">
        <StyledHeaderBG>
          <Avatar src={info?.image || NoImageSmall} width={60} height={60} />
          <Box display="flex" flexDirection="column" width="100%" gap={4}>
            <Box display="flex" gap={8}>
              {offerList?.status === ApplicationStatus.OFFERED && <Chip color="okBlue">採用確定</Chip>}
              {offerList?.status === ApplicationStatus.REJECTED && <Chip color="liteGray">不採用</Chip>}
              <Chip color={offerList?.kind_name === KindName.GENERAL ? 'okBlue' : 'darkBlue'}>
                {offerList?.kind_name}
              </Chip>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="h2">{String(info.btmem_id).padStart(10, '0')}</Text>
                {base?.favorite === 1 && (
                  <StyledBorderBox>
                    <Text variant="chip10" bold color="primaryBlue">
                      お気に入り
                    </Text>
                  </StyledBorderBox>
                )}
              </Box>
            </Box>
          </Box>
        </StyledHeaderBG>
        {info?.status === 0 && (
          <Box display="flex" gap={8} pb={15} pt={0} justifyContent="space-between">
            <MainButton fullWidth onClick={() => setModalStep(ModalStep.CONFIRM_REJECTION)} variant="warnSecondary">
              不採用
            </MainButton>
            <MainButton
              fullWidth
              icon="howToReg"
              iconColor="#fff"
              /**
               * base?.period_dateは外国人応募者
               */
              onClick={() =>
                base?.period_date
                  ? setModalStep(ModalStep.CONFIRM_RECRUITMENT_FOREIGNERS)
                  : setModalStep(ModalStep.CONFIRM_RECRUITMENT)
              }
              variant="primary"
            >
              採用
            </MainButton>
          </Box>
          )}
      </Box>
    );
    // feat screen 04-01-01-14 end

    const body = generateBody();

    return (
      <>
        {top}
        {body}
      </>
    );
  }

  const closeModal = () => {
    setModalStep(ModalStep.DEFAULT);
    onClose();
  };

  function footer() {
    return (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {/* feat screen feat screen 04-01-01-14 start */}
        <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={closeModal}>
          閉じる
        </MainButton>
        {/* feat screen feat screen 04-01-01-14 end */}
      </Box>
    );
  }

  /**
   * [API] 採用をPOST
   */
  const { updateTArbeitOfferStatus3ByIdMutation: callEmployAPI, error: employmentError } = useEmployConnectApi(
    offerList?.offer_id || 0,
    base?.entry_id || 0
  );
  const onClickEmployment = () => {
    callEmployAPI().then((result) => {
      if (!result.errors) {
        setModalStep(ModalStep.CONFIRM_RECRUITMENT_DONE);
        refetchBasicInfo?.();
      } else {
        openErrorModal({
          message: '採用処理に失敗しました',
        });
      }
    });
  };

  /**
   * [API] 不採用をPOST
   */
  const { updateTArbeitEntryStatus4ByIdMutation: callRejectionAPI, error: rejectionError } = useRejectionConnectApi(
    base?.entry_id || 0
  );
  const onClickRejection = () => {
    callRejectionAPI().then((result) => {
      if (!result.errors) {
        setModalStep(ModalStep.CONFIRM_REJECTION_DONE);
        refetchBasicInfo?.();
      } else {
        openErrorModal({
          message: '不採用処理に失敗しました',
        });
      }
    });
  };

  const apiError = employmentError || rejectionError;

  useEffect(() => {
    if (apiError) {
      onClose();
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, onClose, openErrorModal]);

  /**
   * コンテンツ生成
   */
  function displayModal() {
    if (!base) {
      return null;
    }
    switch (modalStep) {
      case ModalStep.DEFAULT:
        return {
          // feat screen 04-01-01-14 start
          width: !isMobile ? 1003 : "100%",
          height: !isMobile ? "80%" : "72.5%",
          // feat screen 04-01-01-14 end
          header: generateHeader(base.btmem_id || 0),
          content: generateContent(base, skill, cost),
          footer: footer(),
        };
      case ModalStep.CONFIRM_RECRUITMENT:
        return {
          with: 384,
          // feat screen 04-01-01-14 start
          height: !isMobile ? 210 : "unset",
          // feat screen 04-01-01-14 end
          header: (
            <Text variant="h2" color="darkBlue">
              採用
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">このバイトラベラーを採用しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat sreen 04-01-01-17 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => closeModal()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickEmployment()}>
                採用
              </MainButton>
              {/* feat sreen 04-01-01-17 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM_RECRUITMENT_DONE:
        return {
          with: 384,
          // feat screen 04-01-01-14 start
          height: !isMobile ? 189 : "unset",
          // feat screen 04-01-01-14 end
          header: (
            <Text variant="h2" color="darkBlue">
              採用 完了
            </Text>
          ),
          content: <Text variant="body14">採用が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
              // feat screen 04-01-01-14 start
                width={!isMobile ? 104 : 96}
                // feat screen 04-01-01-14 end
                variant="secondary"
                onClick={() => {
                  refetch();
                  closeModal();
                }}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.CONFIRM_RECRUITMENT_FOREIGNERS:
        return {
          with: 384,
          // feat screen 04-01-01-14 start
          height: !isMobile ? 240 : "unset",
          // feat screen 04-01-01-14 end
          header: (
            <Text variant="h2" color="darkBlue">
              採用
            </Text>
          ),
          // feat screen 04-01-01-17 start
          content: !isMobile ? (
            <>
              <Text variant="body14">このバイトラベラーを採用しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
              <br />
              <Text color="cautionRed" variant="body14">
                就労時間に制限のある外国人労働者です。ご注意ください。
              </Text>
            </>
          ) : (
            // Responsive Component
            <>
              <Box mb={20}>
                <Text display="block" variant="body14">このバイトラベラーを採用しますか？</Text>
                <Text display="block" variant="body14">この操作は中止できません。</Text>
              </Box>
              <Text display="block" color="cautionRed" variant="body14">
                就労時間に制限のある外国人労働者です。ご注意ください。
              </Text>
            </>
            // feat screen 04-01-01-17 end
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-01-17 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => closeModal()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickEmployment()}>
                採用
              </MainButton>
              {/* feat screen 04-01-01-17 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM_REJECTION:
        return {
          with: 384,
          // feat screen 04-01-01-14 start
          height: !isMobile ? 210 : "unset",
          // feat screen 04-01-01-14 end
          header: (
            <Text variant="h2" color="darkBlue">
              不採用
            </Text>
          ),
          content: (
            <Text variant="body14">
              このバイトラベラーを不採用しますか？
              <br />
              この操作は中止できません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-01-20 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => closeModal()}>
                キャンセル
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickRejection()}>
                不採用
              </MainButton>
              {/* feat screen 04-01-01-20 end */}
            </Box>
          ),
        };
      case ModalStep.CONFIRM_REJECTION_DONE:
        return {
          with: 384,
          // feat screen 04-01-01-14 start
          height: !isMobile ? 189 : "unset",
          // feat screen 04-01-01-14 end
          header: (
            <Text variant="h2" color="darkBlue">
              不採用 完了
            </Text>
          ),
          content: <Text variant="body14">不採用が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                // feat screen 04-01-01-21 start
                width={!isMobile ? 104  : 96}
                // feat screen 04-01-01-21 end
                variant="secondary"
                onClick={() => {
                  refetch();
                  closeModal();
                }}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }

  const modalContents = displayModal();

  /**
   * 親側でJSXを返せと怒られるので null を返してる
   */
  return base ? (
    <Modal
      width={modalContents?.width}
      height={modalContents?.height}
      header={modalContents?.header}
      content={modalContents?.content}
      footer={modalContents?.footer}
      isOpen={isOpen}
      onClose={onClose}
    />
  ) : null;
}

export default BasicInfoModal;
