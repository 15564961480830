import React, { createContext, useContext } from 'react';
import { Box } from 'components/box';
import { AgreementStatus } from 'components/const';
import { DatePicker } from 'components/datePicker';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Select } from 'components/select';
import { format as formatDate } from 'date-fns';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { convertStatusToLabel } from './common';
import { ListDataType } from './type';

type FilterContext = {
  inputPublishedNumber?: string;
  setInputPublishedNumber?: React.Dispatch<React.SetStateAction<string | undefined>>;
  publishedNumber?: string;
  setPublishedNumber?: React.Dispatch<React.SetStateAction<string | undefined>>;
  inputName?: string;
  setInputName?: React.Dispatch<React.SetStateAction<string | undefined>>;
  name?: string;
  setName?: React.Dispatch<React.SetStateAction<string | undefined>>;
  arbeitDate?: Date | null;
  setArbeitDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  statuses?: AgreementStatus[];
  setStatuses?: React.Dispatch<React.SetStateAction<AgreementStatus[]>>;
};

export const CurrentFilterContext = createContext<FilterContext>({});

const statusOptions = [
  { label: convertStatusToLabel(AgreementStatus.ALL), value: AgreementStatus.ALL.toString() },
  { label: convertStatusToLabel(AgreementStatus.BEFORE), value: AgreementStatus.BEFORE.toString() },
  { label: convertStatusToLabel(AgreementStatus.STAND_BY), value: AgreementStatus.STAND_BY.toString() },
  { label: convertStatusToLabel(AgreementStatus.IN_PROGRESS), value: AgreementStatus.IN_PROGRESS.toString() },
  { label: convertStatusToLabel(AgreementStatus.NO_CHECK_IN), value: AgreementStatus.NO_CHECK_IN.toString() },
  { label: convertStatusToLabel(AgreementStatus.NO_CHECK_OUT), value: AgreementStatus.NO_CHECK_OUT.toString() },
  { label: convertStatusToLabel(AgreementStatus.NO_CHECK_IN_OUT), value: AgreementStatus.NO_CHECK_IN_OUT.toString() },
  {
    label: convertStatusToLabel(AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS),
    value: `${AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS.toString()},${AgreementStatus.REQUEST_CORRECTION_REJECTED.toString()}`,
  },
  {
    label: convertStatusToLabel(AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED),
    value: `${AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED.toString()},${AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT.toString()}`,
  },
  { label: convertStatusToLabel(AgreementStatus.COMPLETE), value: AgreementStatus.COMPLETE.toString() },
  { label: convertStatusToLabel(AgreementStatus.CANCEL), value: AgreementStatus.CANCEL.toString() },
  { label: convertStatusToLabel(AgreementStatus.NO_SHOW), value: AgreementStatus.NO_SHOW.toString() },
];

interface FilterProps {
  showStatusFilter?: boolean;
}

export function Filter({ showStatusFilter = false }: FilterProps) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const {
    inputPublishedNumber,
    setInputPublishedNumber,
    setPublishedNumber,
    inputName,
    setInputName,
    setName,
    arbeitDate,
    setArbeitDate,
    statuses,
    setStatuses,
  } = useContext(CurrentFilterContext);

  function clearForm() {
    setInputPublishedNumber?.('');
    setPublishedNumber?.('');
    setInputName?.('');
    setName?.('');
    setArbeitDate?.(null);
    setStatuses?.([AgreementStatus.ALL]);
  }

  return !isMobile ? (
    <Box display="flex" flexWrap="wrap" gap={16} alignItems="center" px={16} pt={16}>
      <Box gap={16} display="flex">
        <Input
          placeholder="掲載No."
          value={inputPublishedNumber || ''}
          onChange={(e) => setInputPublishedNumber?.(e.target.value)}
          width={160}
        />
        <Input
          placeholder="採用者氏名"
          value={inputName || ''}
          onChange={(e) => setInputName?.(e.target.value)}
          width={160}
        />
        <MainButton
          icon="search"
          width={80}
          onClick={() => {
            setPublishedNumber?.(inputPublishedNumber);
            setName?.(inputName);
          }}
        >
          検索
        </MainButton>
      </Box>
      <Divider option="vertical" length={24} />
      <Box display="flex" gap={16}>
        <Box>
          <DatePicker
            onChange={(value) => {
              setArbeitDate?.(value);
            }}
            selected={arbeitDate}
            placeholderText="バイト日"
            width={168}
          />
        </Box>
        {showStatusFilter && (
          <Select
            onChange={(value) => {
              setStatuses?.(value.split(',').map((v) => Number(v)));
            }}
            options={statusOptions}
            value={(statuses !== undefined ? statuses : AgreementStatus.ALL).toString()}
            width={168}
            placeholder="状態"
          />
        )}
        <MainButton variant="clear" width={42} onClick={() => clearForm()}>
          クリア
        </MainButton>
      </Box>
    </Box>
  ) : (
    /* feat_screen_04-02-01-01_start */
    <Box display="flex" flexWrap="wrap" gap={12} alignItems="center" px={16} pt={16} pb={15}>
      <Box gap={12} display="flex">
        <Box display="flex" flex='3' gap={12}>
          <Input
            placeholder="掲載No."
            value={inputPublishedNumber || ''}
            onChange={(e) => setInputPublishedNumber?.(e.target.value)}
            fullWidth
          />
          <Input
            placeholder="採用者氏名"
            value={inputName || ''}
            onChange={(e) => setInputName?.(e.target.value)}
            fullWidth
          />
        </Box>
        <div style={{flex :1}}>
          <MainButton
            icon="search"
            customHeight={40}
            width={80}
            onClick={() => {
              setPublishedNumber?.(inputPublishedNumber);
              setName?.(inputName);
            }}
          >
            検索
          </MainButton>
        </div>
      </Box>
      <Box display="flex" gap={12} justifyContent='space-between' width="100%">
        <Box flex={showStatusFilter ? '1' : '3'}>
          <DatePicker
            onChange={(value) => {
              setArbeitDate?.(value);
            }}
            selected={arbeitDate}
            placeholderText="バイト日"
            width={150}
          />
        </Box>
        <Box flex='1' width="100%">
          {showStatusFilter && (
            <Select
              onChange={(value) => {
                setStatuses?.(value.split(',').map((v) => Number(v)));
              }}
              options={statusOptions}
              value={(statuses !== undefined ? statuses : AgreementStatus.ALL).toString()}
              fullWidth
              placeholder="状態"
              height={40}
            />
          )}
          {!showStatusFilter && (
            <Box flex='1' alignItems="end" py={8} justifyContent="flex-start">
              <MainButton variant="clear" width={80} customHeight={24} onClick={() => clearForm()}>
                クリア
              </MainButton>
            </Box>
          )
          }
        </Box>
      </Box>
      {
        showStatusFilter && (
          <Box width="100%" display='flex' justifyContent='flex-end' mt={-4}>
            <MainButton variant="clear" width={45} customHeight={24} onClick={() => clearForm()}>
              クリア
            </MainButton>
          </Box>
        )
      }
    </Box>
    /* feat_screen_04-02-01-01_end */
  );
}

export function applyFilter(
  data: ListDataType[],
  filter: { publishedNumber?: string; name?: string; arbeitDate?: Date | null; status?: AgreementStatus[] }
) {
  const formattedDate = filter.arbeitDate ? formatDate(filter.arbeitDate, 'yyyy年MM月dd日') : null;

  return data
    .filter(
      (d) =>
        filter.publishedNumber === undefined ||
        filter.publishedNumber.length === 0 ||
        filter.publishedNumber === d.publishedNumber
    )
    .filter((d) => filter.name === undefined || filter.name.length === 0 || filter.name === d.name)
    .filter((d) => formattedDate === null || d.arbeitStartDate?.match(formattedDate))
    .filter(
      (d) =>
        (typeof d?.status === 'number' && filter.status?.includes(d.status)) ||
        filter.status?.length === 0 ||
        filter.status === undefined
    );
}
