import React from 'react';
import { Box } from 'components/box';
import { BitravelLogo } from 'components/assets/css/pages/s01/screen01Style';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
// feat screen 02-02-05 start
type ContentTitle = {
  titlePadding?: number;
};
// feat screen 02-02-05 end
export function TitleHeader(props: ContentTitle) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  // feat screen 02-02-05 start
  const { titlePadding } = props;
  // feat screen 02-02-05 end
  /* feat_common_responsive_useMobile_logic_start */
  // feat screen 02-01-01 start
  return !isMobile ? (
    <Box width="100%" justifyContent="center" display="flex">
      <BitravelLogo width={104} height={18} src="/bitravel_logo.png" alt="bitravel_logo" />
      <Box justifyContent="center" alignItems="center" display="grid" pl={8}>
        <Text color="darkBlue" variant="body14" bold>
          加盟店管理
        </Text>
      </Box>
    </Box>
  ) : (
    // feat screen 02-02-05 start
    <Box pt={titlePadding !== undefined ? titlePadding : 81}>
      {/* feat screen 02-02-05 start */}
      <Box height="100%" width="100%" alignItems="center" justifyContent="center" display="grid">
        <BitravelLogo width={200} height={35} src="/bitravel_logo.png" alt="bitravel_logo" />
        <Box justifyContent="center" alignItems="center" display="grid" py={24}>
          <Text color="darkBlue" variant="body16" bold>
            加盟店管理
          </Text>
        </Box>
      </Box>
    </Box>
  );
  // feat screen 02-01-01 end
}
