import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { parse, format } from 'date-fns';
import { FormData } from 'pages/s04-01-01/common/formState';

/**
 * Figma ID: 04-03-01
 * 名称: 逆オファー一覧取得
 */

export function useGetBtmemOfferListConnectApi(initTpmemId: number) {
  const { data, loading, error } = gql.useGetVtBtmemOfferListByTpmemIdQuery({
    variables: {
      tpmem_id: initTpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    error,
  };
}

/**
 * スキルデータの取得
 */

export function useGetInfoSkillDataConnectApi(initId: number, initTpmemId: number) {
  const [getVtBtmemOfferInfoSkillByIdMutation, { data, loading, error }] = gql.useGetVtBtmemOfferInfoSkillByIdMutation({
    variables: {
      id: initId,
      tpmem_id: initTpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    getVtBtmemOfferInfoSkillByIdMutation,
    data,
    loading,
    error,
  };
}

/**
 * Figma ID: 04-03-02-01, 04-03-02-02, 04-03-02-03, 04-03-02-04, 04-03-02-05
 *           04-03-03-01, 04-03-03-02, 04-03-03-03, 04-03-03-04,
 * 名称: 逆オファー詳細
 */
export function useBtmemOfferInfoConnectApi(initId: number, initTpmemId: number) {
  const { data, loading, error } = gql.useGetVtBtmemOfferInfoByIdQuery({
    variables: {
      id: initId,
      tpmem_id: initTpmemId,
    },
  });
  return {
    data,
    loading,
    error,
  };
}

/**
 * Figma ID: 04-03-03-03, 04-03-03-04
 * 名称: 募集削除
 */
export function useDeleteOfferInfoConnectApi() {
  const [acceptOfferId, setAcceptOfferId] = React.useState<number>(0);
  const [updateTArbeitOfferStatus6ByIdMutation] = gql.useUpdateTArbeitOfferStatus6ByIdMutation({
    variables: {
      id: acceptOfferId,
    },
  });
  return {
    updateTArbeitOfferStatus6ByIdMutation,
    setAcceptOfferId,
  };
}

/**
 * Figma ID: 04-03-03-02
 * 名称: 逆オファー受入 募集内容
 */
export function useVerifyInfoConnectApi(tpmemId: number, offerId: number) {
  const { data, error } = gql.useGetTArbeitOfferByTpmemIdBOfferIdQuery({
    variables: {
      tpmem_id: tpmemId,
      b_offer_id: offerId,
    },
  });
  return {
    data,
    error,
  };
}

/**
 * Figma ID: 04-03-06-02, 04-03-07-02
 * 名称: 募集テンプレート新規作成（テンプレート保存）
 * （外）
 */
export function usePostTemplateConnectApi(tpmemId: number) {
  const [fixedPostFormData, setFixedPostFormData] = React.useState<FormData>({});
  const [addupdSTpmemNoticetplByTpmemIdMutation, { loading }] = gql.useAddupdSTpmemNoticetplByTpmemIdMutation({
    variables: {
      tpmem_id: tpmemId,
      description: fixedPostFormData?.description || 'a',
      conditions: fixedPostFormData?.conditions || '',
      caution: fixedPostFormData?.caution || '',
      belongings: fixedPostFormData?.belongings || '',
      access: fixedPostFormData?.access || '',
      attached_file: fixedPostFormData?.attachment_flag === '1' ? String(fixedPostFormData?.attached_file) : '',
      image1: fixedPostFormData?.image1_flag ? String(fixedPostFormData?.image1_path) : '',
      image2: fixedPostFormData?.image2_flag ? String(fixedPostFormData?.image2_path) : '',
      image3: fixedPostFormData?.image3_flag ? String(fixedPostFormData?.image3_path) : '',
      image4: fixedPostFormData?.image4_flag ? String(fixedPostFormData?.image4_path) : '',
      image5: fixedPostFormData?.image5_flag ? String(fixedPostFormData?.image5_path) : '',
    },
  });
  return {
    setFixedPostFormData,
    addupdSTpmemNoticetplByTpmemIdMutation,
  };
}

/**
 * Figma ID: 04-03-02-04
 * 名称: 募集新規作成 確認
 * （中）
 */

export function usePostTArbeitOfferConnectApi(tpmemId: number, id: number) {
  const [fixedPostFormData, setFixedPostFormData] = React.useState<FormData>({});

  const generateBeginDate = (shift_date?: string, hour?: string, minute?: string) => {
    if (!shift_date || !hour || !minute) {
      return '';
    }
    const dateStr = `${shift_date} ${hour}:${minute}`;
    const parsedDate = parse(dateStr, 'yyyy-MM-dd H:mm', new Date());
    return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
  };
  // 日またぎチェック ＋1日
  let endDate = fixedPostFormData.shift_date;
  if (
    fixedPostFormData.daily_start_hour &&
    fixedPostFormData.daily_end_hour &&
    parseInt(fixedPostFormData.daily_end_hour, 10) < parseInt(fixedPostFormData.daily_start_hour, 10) &&
    fixedPostFormData.shift_date
  ) {
    const shiftBeginDate = new Date(fixedPostFormData.shift_date);
    shiftBeginDate.setDate(shiftBeginDate.getDate() + 1);
    endDate = format(shiftBeginDate, 'yyyy-MM-dd');
  }
  const [addTArbeitOfferAd2S2Mutation] = gql.useAddTArbeitOfferAd2S2Mutation({
    variables: {
      tpmem_id: tpmemId,
      begin_date: generateBeginDate(
        fixedPostFormData?.shift_date,
        fixedPostFormData?.daily_start_hour,
        fixedPostFormData?.daily_start_minute
      ),
      end_date: generateBeginDate(endDate, fixedPostFormData?.daily_end_hour, fixedPostFormData?.daily_end_minute),
      hour_wage: Number(fixedPostFormData?.hour_wage_display || '0'),
      trans_fee: Number(fixedPostFormData?.trans_fee || '0'),
      break_time: Number(fixedPostFormData?.break_time || '0'),
      description: fixedPostFormData?.description || '',
      conditions: fixedPostFormData?.conditions || '',
      caution: fixedPostFormData?.caution || '',
      belongings: fixedPostFormData?.belongings || '',
      access: fixedPostFormData?.access || '',
      chief: fixedPostFormData?.chief || '',
      attached_file: fixedPostFormData?.attachment_flag === '1' ? fixedPostFormData?.attached_file || '' : '',
      image1: fixedPostFormData?.image1_flag ? fixedPostFormData?.image1_path || '' : '',
      image2: fixedPostFormData?.image2_flag ? fixedPostFormData?.image2_path || '' : '',
      image3: fixedPostFormData?.image3_flag ? fixedPostFormData?.image3_path || '' : '',
      image4: fixedPostFormData?.image4_flag ? fixedPostFormData?.image4_path || '' : '',
      image5: fixedPostFormData?.image5_flag ? fixedPostFormData?.image5_path || '' : '',
      b_offer_id: id,
    },
  });
  return {
    setFixedPostFormData,
    addTArbeitOfferAd2S2Mutation,
  };
}

/**
 * Figma ID: 04-03-06-01
 * 名称: 募集新規作成（テンプレート取得）
 * （外）
 */
export function useCreateTemplateConnectApi(tpmemId: number) {
  const { data, loading, error } = gql.useGetSTpmemNoticetplByTpmemIdQuery({
    variables: {
      tpmem_id: tpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    error,
  };
}

/**
 * 募集テンプレート情報を取得するAPI
 * 04-03-02-02（中）
 */
export function useGetTemplateDataConnenctApi(tpmemId: number) {
  const { data, loading } = gql.useGetSTpmemNoticetplMakeInfoByTpmemIdQuery({
    variables: {
      tpmem_id: tpmemId,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
  };
}
