import React from 'react';
import { BitravelError } from './BitravelError';

export class RequiringCallbackError extends BitravelError {
  readonly requiredCallbackNotDefinedMessage = 'requiredCallback が設定されていません';

  readonly requiredCallback: () => void = () => {
    throw Error(this.requiredCallbackNotDefinedMessage);
  };

  constructor(props: { message: string | React.ReactNode; title?: string; requiredCallback: () => void }) {
    super(props);
    this.requiredCallback = props.requiredCallback;
  }
}
