/**
 * Figma ID: 04-01-01-15
 * 名称: 応募（タブ）
 */
import { Box } from 'components/box';
import { Text } from 'components/text';
import { CategorizedInfo } from 'components/categorizedInfo';
import React, { useEffect, useMemo, useState } from 'react';
import * as gql from 'graphql/graphql-mw';
import { Icon } from 'components/icon';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';

export interface SkillData {
  skillData?: gql.VtBtSkillCheck[];
  rank?: string;
}

function SkillCheck(props: SkillData) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { skillData, rank } = props;
  const [keyLen, setKeyLen] = useState<number>(0);

  const groupedByName = useMemo(
    () =>
      skillData?.reduce((acc: { [no: number]: gql.VtBtSkillCheck[] }, item: gql.VtBtSkillCheck) => {
        if (item.no) {
          if (!acc[item.no]) {
            acc[item.no] = [];
          }
          acc[item.no].push(item);
        }
        return acc;
      }, {}),
    [skillData]
  );

  useEffect(() => {
    if (!groupedByName) return;
    setKeyLen(Object.keys(groupedByName).length);
  }, [groupedByName, setKeyLen]);

  const convertedData = Object.keys(groupedByName as gql.VtBtSkillCheck).map((key) => {
    if (!groupedByName) return null;
    const value = groupedByName[Number(key)];
    return {
      label: value?.[0].name || '',
      skills: value?.map((v) => ({
        name: v.check_item,
        checked: v.checked,
      })),
    };
  });

  return (
    <Box>
      <Box my={16}>
        <Text variant="body14" color="darkBlue">
          ランク&nbsp;
        </Text>
        <Text variant="body16" color="darkBlue" bold>
          {rank}
        </Text>
      </Box>
      <Divider option="horizontal" />
      <CategorizedInfo
        items={convertedData?.map((d) => ({
          label: d?.label || '',
          contents: d?.skills.map((s) => (
            // feat screen 04-01-01-13 start
            <Box display="flex" alignItems={!isMobile ? "center" : "flex-start"} gap={8}>
              <Box>
                {s.checked ? <Icon color="#008599" name="check" /> : <Box width={24}>&nbsp;</Box>}
              </Box>
              {/*  feat screen 04-01-01-13 end */}
              <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                {s.name}
              </Text>
            </Box>
          )) as React.ReactNode[],
        }))}
      />
    </Box>
  );
}

export default SkillCheck;
