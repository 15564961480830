/**
 * Figma ID: 02-02-14
 * 名称: メールアドレス変更依頼 確認
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { reportChangEmail } from 'components/api';
import { ResponseErrorCode } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  tpCode: string;
  firstName: string;
  lastName: string;
  firstKana: string;
  lastKana: string;
  managerBirthday: Date;
  businessName: string;
  zipCode1: string;
  zipCode2: string;
  phone: string;
  email: string;
  toNext: () => void;
  toBack: () => void;
};

export default function ConfirmEmailContent({
  tpCode,
  firstName,
  lastName,
  firstKana,
  lastKana,
  managerBirthday,
  businessName,
  zipCode1,
  zipCode2,
  phone,
  email,
  toNext,
  toBack,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const section1 = [
    { label: '責任者氏名', value: `${lastName} ${firstName}` },
    { label: '責任者氏名（フリガナ）', value: `${lastKana}${firstKana}` },
    {
      label: '責任者生年月日',
      value: `${String(managerBirthday?.getFullYear())}年${String((managerBirthday?.getMonth() ?? 0) + 1)}月${String(
        managerBirthday?.getDate()
      )}日`,
    },
    { label: '事業者名または事業者氏名/屋号', value: businessName },
    {
      label: '事業者名または事業者氏名/屋号の所在地郵便番号',
      value: `〒${zipCode1}-${zipCode2}`,
    },
    { label: '事業者名または事業者氏名/屋号の電話番号', value: phone },
    { label: '正しいメールアドレス', value: email },
  ];
  const [isLoading, setIsLoading] = React.useState(false);
  const { openErrorModal } = useErrorModal();

  // feat screen No_06 start
  return !isMobile ? (
    <>
      {isLoading && <Loading />}
      <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
        <ContentTitle title="メールアドレス変更依頼 確認" />

        <Box height={50} display="flex" alignItems="center">
          <Text variant="body14">下記内容で送信します。よろしければ送信ボタンを押してください</Text>
        </Box>

        <Box>
          <Box py={11}>
            <Divider option="horizontal" />
          </Box>
          {section1.map((item, index) => (
            <Box key={Number(index)}>
              <Box display="flex" alignItems="center">
                <Box width={160}>
                  <Text variant="body14" color="darkGray">
                    {item.label}
                  </Text>
                </Box>
                <Text variant="body14">{item.value}</Text>
              </Box>
              <Box py={11}>
                <Divider option="horizontal" />
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" gap={5} justifyContent="center">
          <MainButton variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            onClick={() => {
              setIsLoading(true);
              reportChangEmail({
                tpcode: tpCode,
                firstname: firstName,
                lastname: lastName,
                firstkana: firstKana,
                lastkana: lastKana,
                dob: `${String(managerBirthday?.getFullYear())}-${String(
                  (managerBirthday?.getMonth() ?? 0) + 1
                )}-${String(managerBirthday?.getDate())}`,
                mconame: businessName,
                zipcode: `${zipCode1}${zipCode2}`,
                phoneno: phone,
                newemail: email,
              })
                .then((result) => {
                  switch (result.data.ErrorCode) {
                    case ResponseErrorCode.SUCCESS:
                      toNext();
                      break;
                    case ResponseErrorCode.ER_DUP_ENTRY:
                      openErrorModal({ message: 'メールアドレス重複エラー' });
                      break;
                    default:
                      openErrorModal({ message: '送信できませんでした。' });
                      break;
                  }
                })
                .catch((e) => {
                  openErrorModal({ message: '送信できませんでした。' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            送信
          </MainButton>
        </Box>
      </Box>
    </>
  ) : (
    // Responsive Component
    <>
      {isLoading && <Loading />}
      <Box width="100vw" display="flex" flexDirection="column" px={16}>
        <ContentTitle title="メールアドレス変更依頼 確認" />

        <Box pt={18} display="flex" alignItems="center">
          <Text variant="body14">下記内容で送信します。よろしければ送信ボタンを押してください</Text>
        </Box>

        <Box>
          <Box pt={14} pb={16}>
            <Divider option="horizontal" />
          </Box>
          {section1.map((item, index) => (
            <Box key={Number(index)}>
              <Box display="flex" alignItems="center" justifyContent='space-between'>
                <Box width={155}>
                  <Text display='block' variant="body14" color="darkGray">
                    {item.label}
                  </Text>
                </Box>
                <Text display='block' variant="body14">{item.value}</Text>
              </Box>
              {section1.length - 1 !== index && (
                <Box pt={15} pb={16}>
                  <Divider option="horizontal" />
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" pb={24} pt={22} justifyContent="space-between" gap={5}>
          <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            fullWidth 
            customHeight={40} 
            onClick={() => {
              setIsLoading(true);
              reportChangEmail({
                tpcode: tpCode,
                firstname: firstName,
                lastname: lastName,
                firstkana: firstKana,
                lastkana: lastKana,
                dob: `${String(managerBirthday?.getFullYear())}-${String(
                  (managerBirthday?.getMonth() ?? 0) + 1
                )}-${String(managerBirthday?.getDate())}`,
                mconame: businessName,
                zipcode: `${zipCode1}${zipCode2}`,
                phoneno: phone,
                newemail: email,
              })
                .then((result) => {
                  switch (result.data.ErrorCode) {
                    case ResponseErrorCode.SUCCESS:
                      toNext();
                      break;
                    case ResponseErrorCode.ER_DUP_ENTRY:
                      openErrorModal({ message: 'メールアドレス重複エラー' });
                      break;
                    default:
                      openErrorModal({ message: '送信できませんでした。' });
                      break;
                  }
                })
                .catch((e) => {
                  openErrorModal({ message: '送信できませんでした。' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            送信
          </MainButton>
        </Box>
      </Box>
    </>
  );
  // feat screen No_06 end
}
