import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { passwordPattern, passwordLength } from 'components/const';
import React from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormType) => void;
  errorMessages?: string;
}

interface FormType {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const passwordRegExp = /^(?=.*[A-Z])(?=.*[^$*.\\[\]{}()?&quot;!@#%&/\\,&gt;&lt;&prime;:;|_~`+=])[a-zA-Z0-9.?/-]/;

function EditPasswordModal(props: Props) {
  const { isOpen, onClose, onSubmit, errorMessages } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<FormType>();

  const passwordWatch = watch('newPassword');

  function clearForm() {
    setValue('currentPassword', '');
    setValue('newPassword', '');
    setValue('newPasswordConfirm', '');
  }

  function onModalClose() {
    clearForm();
    clearErrors();
    onClose();
  }

  function submit() {
    handleSubmit((data) => {
      onSubmit(data);
      clearForm();
    })();
  }

  const header = (
    <Text variant="h2" color="darkBlue">
      パスワード編集
    </Text>
  );

  const content = !isMobile ? (
    <Box pt={12} pb={20} pl={12}>
      <Box>
        <Box>
          <Text variant="h3" color="blueGray">
            現在のパスワード
          </Text>
        </Box>
        <Box mt={8}>
          <RhfInput
            name="currentPassword"
            control={control}
            width={752}
            type="password"
            rules={{
              required: '現在のパスワードを入力してください。',
            }}
          />
          {errorMessages && (
            <Text variant="caption12" color="cautionRed">
              {errorMessages}
            </Text>
          )}
          <Box>
            <Text variant="caption12" color="cautionRed">
              {errors?.currentPassword?.message}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box mt={24}>
        <Box>
          <Text variant="h3" color="blueGray">
            新しいパスワード
          </Text>
        </Box>
        <Box width="100%" mt={8}>
          <RhfInput
            name="newPassword"
            control={control}
            width={752}
            type="password"
            rules={{
              required: '新しいパスワードを入力してください。',
              validate: (value) => passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
              minLength: { value: passwordLength, message: '8文字以上で入力してください' },
            }}
          />
        </Box>
        <Box>
          <Text variant="caption12" color="cautionRed">
            {errors?.newPassword?.message}
          </Text>
        </Box>
      </Box>
      <Box mt={24}>
        <Box>
          <Text variant="h3" color="blueGray">
            新しいパスワード（確認）
          </Text>
        </Box>
        <Box width="100%" mt={8}>
          <RhfInput
            name="newPasswordConfirm"
            control={control}
            width={752}
            type="password"
            rules={{
              required: '新しいパスワード（確認）を入力してください。',
              validate: (value) => value === passwordWatch || 'パスワードが一致しません。',
            }}
          />
          <Box>
            <Text variant="caption12" color="cautionRed">
              {errors?.newPasswordConfirm?.message}
            </Text>
          </Box>
        </Box>
      </Box>
        <Box width={752} mt={8}>
            <Text color="cautionRed" variant="caption12">
                パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
                &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
            </Text>
        </Box>
    </Box>
  ) : (
    // feat_screen_09-03-05_start
    <Box display="flex" flexDirection="column" gap={16}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Text display="block" variant="body14" color="blueGray" bold>
          現在のパスワード
        </Text>
        <Box mt={-4}>
          <RhfInput
            name="currentPassword"
            control={control}
            fullWidth
            type="password"
            rules={{
              required: '現在のパスワードを入力してください。',
            }}
            />
        </Box>
        {errorMessages && (
          <Text variant="caption12" color="cautionRed">
            {errorMessages}
          </Text>
        )}
        <Text variant="caption12" color="cautionRed">
          {errors?.currentPassword?.message}
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <Text display="block" variant="body14" color="blueGray" bold>
          新しいパスワード
        </Text>
        <RhfInput
          name="newPassword"
          control={control}
          fullWidth
          type="password"
          rules={{
            required: '新しいパスワードを入力してください。',
            validate: (value) => passwordRegExp.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
            minLength: { value: passwordLength, message: '8文字以上で入力してください' },
          }}
        />
        <Text variant="caption12" color="cautionRed">
          {errors?.newPassword?.message}
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <Text display="block" variant="body14" color="blueGray" bold>
          新しいパスワード（確認）
        </Text>
        <RhfInput
          name="newPasswordConfirm"
          control={control}
          fullWidth
          type="password"
          rules={{
            required: '新しいパスワード（確認）を入力してください。',
            validate: (value) => value === passwordWatch || 'パスワードが一致しません。',
          }}
        />
        <Text variant="caption12" color="cautionRed">
          {errors?.newPasswordConfirm?.message}
        </Text>
      </Box>
        <Box width="100%">
            <Text color="cautionRed" variant="caption12">
                パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
                &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
            </Text>
        </Box>
    </Box>
    // feat_screen_09-03-05_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-03-05_start
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        // feat_screen_09-03-05_end
        variant="secondary"
        onClick={() => onModalClose()}>
        キャンセル
      </MainButton>
      <MainButton
        // feat_screen_09-03-05_start
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        // feat_screen_09-03-05_end
        onClick={() => submit()}>
        変更
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      height="auto"
      // feat_screen_09-03-05_start
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-03-05_end
    />
  );
}

export default EditPasswordModal;
