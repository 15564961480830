import axios from 'axios';

export type ReportMemberProps = {
  baitravelerId: string;
  storeId: string;
  body: string;
};

export default async function reportMember(props: ReportMemberProps) {
  const { baitravelerId, storeId, body } = props;

  const headers = {
    'x-api-key': process.env.REACT_APP_MEMBER_REPORT_API_KEY,
  };

  const data = {
    fieldName: 'sendMailReportMember',
    arguments: {
      b_member_id: baitravelerId,
      t_member_id: storeId,
      body,
    },
  };

  const response = await axios.post(process.env.REACT_APP_MEMBER_REPORT_API_URL || '', data, {
    headers,
  });

  return response;
}
