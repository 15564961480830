import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { DatePicker } from 'components/datePicker';
import { List } from 'components/list';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { useAuth } from 'hooks/authProvider';
import React, { useMemo, useEffect, useState } from 'react';
import * as gql from 'graphql/graphql-mw';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { isAfter, isSameDay, isBefore } from 'date-fns';

import { Link, useNavigate } from 'react-router-dom';
import { ListHeaderType, ListItemsType } from '../type';

const StyledText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #969696;
  /* feat screen 07-02-01 start */
  @media only screen and (max-width: 768px) {
    padding: 0 8px;
  }
  /* feat screen 07-02-01 end */
`;
/* feat screen 07-02-01 start */
const StyledFlexWrap = styled.div`
  @media only screen and (max-width: 350px) {
    > div {
      flex-wrap: wrap;
    }
  }
`;
/* feat screen 07-02-01 end */

const StyledTextDecoration = styled.div`
  a {
    -webkit-text-decoration: underline #008599;
    text-decoration: underline #008599;
  }
`;

export function TuitionFees() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  const navigate = useNavigate();
  const auth = useAuth();
  const tpmemId = auth?.tpmemId;

  if (tpmemId === null) {
    navigate('/signin');
  }

  const [formState, setFormState] = React.useState<{
    minDate: Date | null | undefined;
    maxDate: Date | null | undefined;
  }>();
  const [dateRangePicker, setDateRangePicker] = React.useState<{
    minDate: Date | null | undefined;
    maxDate: Date | null | undefined;
  }>();
  const handleFilterClear = () => {
    setDateRangePicker({
      minDate: null,
      maxDate: null,
    });
    setFormState({
      minDate: null,
      maxDate: null,
    });
  };

  const {
    data: { getVTWithdrawalsListByTpmemId } = {
      getVTWithdrawalsListByTpmemId: [] as gql.VtWithdrawalsList[],
    },
    loading,
    error: getVtWithdrawalsListByTpmemIdError,
  } = gql.useGetVtWithdrawalsListByTpmemIdQuery({
    variables: { tpmem_id: tpmemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (getVtWithdrawalsListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtWithdrawalsListByTpmemIdError, openErrorModal]);

  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const listHeader: ListHeaderType = useMemo(
    () => [
      // feat_screen_07-02-01_04 start
      { key: 'offer_number', width: !isMobile ? 136 : 108, columnName: '掲載No.' },
      { key: 'work_day', width: !isMobile ? 120 : 115, columnName: '日付' },
      // feat_screen_07-02-01_04 end
      { key: 'cost', width: 98, columnName: '費用' },
      { key: 'month', width: 96, columnName: '引落とし月' },
    ],
    // feat_screen_07-02-01_04 start
    [isMobile]
    // feat_screen_07-02-01_04 end
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading || getVTWithdrawalsListByTpmemId.length === 0) return [];
    let items = getVTWithdrawalsListByTpmemId;

    if (formState?.minDate) {
      items = items.filter(({ work_date }) => {
        if (!work_date || !formState.minDate) return true;
        const regex = /(\d{4})-(\d{2})-(\d{2})/;
        const matches = work_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return (
            isSameDay(new Date(year, month - 1, day), formState.minDate) ||
            isAfter(new Date(year, month - 1, day), formState.minDate)
          );
        }
        return false;
      });
    }
    if (formState?.maxDate) {
      items = items.filter(({ work_date }) => {
        if (!work_date || !formState.maxDate) return true;
        const regex = /(\d{4})-(\d{2})-(\d{2})/;
        const matches = work_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return (
            isSameDay(new Date(year, month - 1, day), formState.maxDate) ||
            isBefore(new Date(year, month - 1, day), formState.maxDate)
          );
        }
        return false;
      });
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else if (sort.key === 'cost') {
        // 数値変換
        items = orderBy(items, (item) => parseInt(item.cost?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      offer_number: (
        // feat_screen_07-02-01_04 start
        <StyledTextDecoration>
          <Link to={`/s04-02/${item.offer_number?.slice(2) ?? ''}`}>
            <Text variant="caption12" color="primaryBlue">
              {item.offer_number}
            </Text>
          </Link>
        </StyledTextDecoration>
        // feat_screen_07-02-01_04 end
      ),
      work_day: item.work_day,
      cost: `¥${item.cost ?? 0}`,
      month: item.month,
      uniqueKey: key,
    }));
  }, [getVTWithdrawalsListByTpmemId, sort, formState, loading]);

  // feat_screen_07-02-01_04 start
  return !isMobile ? (
    // feat_screen_07-02-01_04 end
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" flex="auto">
        <Box display="flex" flexDirection="row" height={64} alignItems="center" gap={16} px={16}>
          <DatePicker
            onChange={(value) => setDateRangePicker({ minDate: value, maxDate: dateRangePicker?.maxDate })}
            placeholderText="日付を選択"
            selected={dateRangePicker?.minDate}
            width={160}
            maxDate={dateRangePicker?.maxDate}
          />
          <StyledText>~</StyledText>
          <DatePicker
            onChange={(value) => setDateRangePicker({ maxDate: value, minDate: dateRangePicker?.minDate })}
            placeholderText="日付を選択"
            selected={dateRangePicker?.maxDate}
            width={160}
            minDate={dateRangePicker?.minDate}
          />
          <MainButton
            width={60}
            onClick={() => {
              setFormState(dateRangePicker);
              handleChangePage(1);
            }}
          >
            確定
          </MainButton>
          <MainButton width={42} variant="clear" onClick={() => handleFilterClear()}>
            クリア
          </MainButton>
        </Box>
        <Divider option="horizontal" />
        <Box flex="auto" width="100%" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={385}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={(key) => {
                  handleChangeSort(key);
                  handleChangePage(1);
                }}
                rowHeight={34}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
    </>
  ) : (
    // feat_screen_07-02-01_04 start
    <>
      {loading && <Loading />}
      <Box display={isShowFilter ? 'flex' : 'none'} width="100%" pb={32} pa={16} flexDirection="column">
        <StyledFlexWrap>
          <Box display="flex" flexDirection="row" alignItems="center" pb={12} justifyContent="space-between">
            <Box width="100%">
              <DatePicker
                onChange={(value) => setDateRangePicker({ minDate: value, maxDate: dateRangePicker?.maxDate })}
                placeholderText="日付を選択"
                selected={dateRangePicker?.minDate}
                fromTo={143}
                maxDate={dateRangePicker?.maxDate}
              />
            </Box>
            <StyledText>~</StyledText>
            <Box width="100%">
              <DatePicker
                onChange={(value) => setDateRangePicker({ maxDate: value, minDate: dateRangePicker?.minDate })}
                placeholderText="日付を選択"
                selected={dateRangePicker?.maxDate}
                fromTo={143}
                minDate={dateRangePicker?.minDate}
              />
            </Box>
          </Box>
        </StyledFlexWrap>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" gap={7} alignItems="center">
          <MainButton
            width={88}
            customHeight={40}
            onClick={() => {
              setFormState(dateRangePicker);
              handleChangePage(1);
            }}
          >
            確定
          </MainButton>
          <MainButton width={49} variant="clear" onClick={() => handleFilterClear()}>
            クリア
          </MainButton>
        </Box>
      </Box>
      <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      <Box flex="auto" py={0}>
        <PaginationBox
          dataSize={listItems.length}
          limit={limit}
          page={page}
          onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
          onChangePage={(value: number) => handleChangePage(value)}
        >
          <ScrollWrapper bottom={isShowFilter ? 429 : 319} bottomSE={319}>
            <List
              header={listHeader}
              items={listItems.slice(limit * (page - 1), limit * page)}
              key={String(limit) + String(page) + String(sort?.key || '')}
              sort={sort}
              onChangeSort={(key) => {
                handleChangeSort(key);
                handleChangePage(1);
              }}
              rowHeight={57}
              rowWidth="100%"
              width="100%"
            />
          </ScrollWrapper>
        </PaginationBox>
      </Box>
    </>
    // feat_screen_07-02-01_04 end
  );
}
