/**
 * Figma ID: 01-01-04
 * 名称: 事業者情報
 */
import React, { useMemo } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { MainButton } from 'components/mainButton';
import { RadioGroup } from 'components/radioGroup';
import * as gql from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  toNext: (mcoInfo: {
    corporateNo: string;
    mcoName: string;
    mcoZipCode: string;
    mcoState: string;
    mcoCity: string;
    mcoAddress1: string;
    mcoAddress2: string;
    mcoPhone: string;
    mcoMFirstName: string;
    mcoMLastName: string;
    mcoMFirstKana: string;
    mcoMLastKana: string;
    mcoTFirstName: string;
    mcoTLastName: string;
    mcoTFirstKana: string;
    mcoTLastKana: string;
    mcoTPhone: string;
  }) => void;
  toEmployerInfo: () => void;
  toBack: () => void;
};
export function CorporationInfo({ toNext, toBack, toEmployerInfo }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { openErrorModal } = useErrorModal();
  const [isCorporation, setIsCorporation] = React.useState<boolean>(true);
  const { data: { getMState } = { getMState: [] }, error: getMStateError } = gql.useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [fetchMCityByStateId, { error: fetchMCityByStateIdError }] = gql.useGetMCityByStateIdLazyQuery();
  React.useEffect(() => {
    if (getMStateError || fetchMCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getMStateError, fetchMCityByStateIdError, openErrorModal]);

  const [cityOptions, setCityOptions] = React.useState<{ label: string; value: string }[]>([]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    businessName: string;
    zipCode1: string;
    zipCode2: string;
    prefecture: string;
    city: string;
    address1: string;
    address2: string;
    corporateNumber: string;
    phone: string;
    mcoFamilyName: string;
    mcoFirstName: string;
    mcoFamilyNameKana: string;
    mcoFirstNameKana: string;
    managerFamilyName: string;
    managerFirstName: string;
    managerFamilyNameKana: string;
    managerFirstNameKana: string;
    managerPhone: string;
    managerBirthday: Date | null;
  }>({
    reValidateMode: 'onChange',
  });

  const prefectureOptions = useMemo<{ label: string; value: string }[]>(() => {
    if (!getMState) {
      return [];
    }
    return getMState.map((item) => ({
      label: item?.name ?? '',
      value: item?.name ?? '',
    }));
  }, [getMState]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type, ...rest }) => {
      if (name === 'prefecture' && type === 'change') {
        const selectedPrefecture = getMState?.find((item) => item?.name === value.prefecture);
        if (selectedPrefecture) {
          fetchMCityByStateId({
            context: { clientName: 'master' },
            variables: { sid: Number(selectedPrefecture.id) }
          }).then(({ data: { getMCityByStateId } = { getMCityByStateId: [] } }) => {
            if (getMCityByStateId) {
              setCityOptions(
                getMCityByStateId.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                }))
              );
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMCityByStateId, getMState]);

  const onSubmit = handleSubmit((data) => {
    if (!isCorporation) {
      toEmployerInfo();
      return;
    }
    toNext({
      corporateNo: data.corporateNumber,
      mcoName: data.businessName,
      mcoZipCode: `${data.zipCode1}${data.zipCode2}`,
      mcoState: data.prefecture,
      mcoCity: data.city,
      mcoAddress1: data.address1,
      mcoAddress2: data.address2,
      mcoPhone: data.phone,
      mcoMFirstName: data.mcoFirstName,
      mcoMLastName: data.mcoFamilyName,
      mcoMFirstKana: data.mcoFirstNameKana,
      mcoMLastKana: data.mcoFamilyNameKana,
      mcoTFirstName: data.managerFirstName,
      mcoTLastName: data.managerFamilyName,
      mcoTFirstKana: data.managerFirstNameKana,
      mcoTLastKana: data.managerFamilyNameKana,
      mcoTPhone: data.managerPhone,
    });
  });

  return (
    // feat screen 01-01-04 start
    <div>
      {!isMobile ? (
        <Box width={752} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 事業者情報" />
          <Box display="grid">
            <Text variant="body14">事業者情報を入力して次へを押してください。項目は全て入力してください。</Text>
            <br />
            <Text variant="body14">法人でない場合は無しを選択して、次へを押して雇用主情報を入力してください。</Text>
          </Box>
          <Box>
            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>法人</InputLabel>
              <RadioGroup
                name="isCorporation"
                value={isCorporation ? 'yes' : 'no'}
                options={[
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        有
                      </Text>
                    ),
                    value: 'yes',
                  },
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        無
                      </Text>
                    ),
                    value: 'no',
                  },
                ]}
                onChange={(e) => {
                  setIsCorporation(e.target.value === 'yes');
                }}
              />
            </Box>
            {isCorporation && (
              <>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>事業者名</InputLabel>
                  <RhfInput
                    fullWidth
                    control={control}
                    name="businessName"
                    defaultValue=""
                    placeholder="事業者名を入力"
                    rules={{ required: '事業者名を入力してください。' }}
                  />
                  {errors.businessName?.message && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.businessName.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>郵便番号</InputLabel>
                  <Box display="flex" alignItems="center">
                    <RhfInput
                      control={control}
                      rules={{
                        required: '郵便番号を入力してください。',
                        pattern: {
                          value: /^\d*$/,
                          message: '数字を使用してください。',
                        },
                        maxLength: { value: 3, message: `郵便番号の形式で入力してください` },
                        minLength: { value: 3, message: `郵便番号の形式で入力してください` },
                      }}
                      name="zipCode1"
                      defaultValue=""
                      width={104}
                    />
                    <Box px={14}>
                      <Text color="liteGray" variant="body14">
                        -
                      </Text>
                    </Box>
                    <RhfInput
                      control={control}
                      rules={{
                        required: '郵便番号を入力してください。',
                        pattern: {
                          value: /^\d*$/,
                          message: '数字を使用してください。',
                        },
                        maxLength: { value: 4, message: `郵便番号の形式で入力してください` },
                        minLength: { value: 4, message: `郵便番号の形式で入力してください` },
                      }}
                      name="zipCode2"
                      defaultValue=""
                      width={104}
                    />
                  </Box>
                  {(errors.zipCode1 || errors.zipCode2) && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.zipCode1?.message || errors.zipCode2?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel fullWidth optional>
                    住所
                  </InputLabel>
                  <Box display="flex" gap={10}>
                    <RhfSelect
                      options={prefectureOptions}
                      control={control}
                      defaultValue=""
                      name="prefecture"
                      width={176}
                      placeholder="都道府県"
                    />
                    <RhfSelect
                      options={cityOptions}
                      control={control}
                      defaultValue=""
                      name="city"
                      width={176}
                      placeholder="市区町村"
                    />
                    <RhfInput control={control} name="address1" defaultValue="" width={176} placeholder="番地等" />
                    <RhfInput
                      control={control}
                      name="address2"
                      defaultValue=""
                      width={176}
                      placeholder="建物名・部屋番号"
                    />
                  </Box>
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel subLabel="WEBから検索" subLabelHref="https://www.houjin-bangou.nta.go.jp/">
                    法人番号
                  </InputLabel>
                  <RhfInput
                    control={control}
                    name="corporateNumber"
                    defaultValue=""
                    placeholder="法人番号を記入"
                    rules={{
                      required: '法人番号を入力してください。',
                      pattern: {
                        value: /^\d*$/,
                        message: '13桁の数値で入力してください',
                      },
                      maxLength: { value: 13, message: `13桁の数値で入力してください` },
                      minLength: { value: 13, message: `13桁の数値で入力してください` },
                    }}
                  />
                  {errors.corporateNumber?.message && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.corporateNumber?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>電話番号</InputLabel>
                  <RhfInput
                    control={control}
                    name="phone"
                    defaultValue=""
                    placeholder="電話番号を入力"
                    rules={{
                      required: '電話番号を入力してください。',
                      pattern: {
                        value: /^\d*$/,
                        message: '数字を使用してください。',
                      },
                    }}
                  />
                  {errors.phone?.message && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.phone?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>代表者氏名</InputLabel>
                  <Box display="flex" gap={5}>
                    <RhfInput
                      control={control}
                      name="mcoFamilyName"
                      defaultValue=""
                      width={120}
                      placeholder="姓"
                      rules={{ required: '代表者氏名を入力してください。' }}
                    />
                    <RhfInput
                      control={control}
                      name="mcoFirstName"
                      defaultValue=""
                      width={120}
                      placeholder="名"
                      rules={{ required: '代表者氏名を入力してください。' }}
                    />
                  </Box>
                  {(errors.mcoFamilyName || errors.mcoFirstName) && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.mcoFamilyName?.message || errors.mcoFirstName?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>代表者氏名（フリガナ）</InputLabel>
                  <Box display="flex" gap={5}>
                    <RhfInput
                      control={control}
                      name="mcoFamilyNameKana"
                      defaultValue=""
                      width={120}
                      placeholder="セイ"
                      rules={{
                        required: '氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        }
                      }}
                    />
                    <RhfInput
                      control={control}
                      name="mcoFirstNameKana"
                      defaultValue=""
                      width={120}
                      placeholder="メイ"
                      rules={{
                        required: '氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        }
                      }}
                    />
                  </Box>
                  {(errors.mcoFamilyNameKana || errors.mcoFirstNameKana) && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.mcoFamilyNameKana?.message || errors.mcoFirstNameKana?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>担当者氏名</InputLabel>
                  <Box display="flex" gap={5}>
                    <RhfInput
                      control={control}
                      name="managerFamilyName"
                      defaultValue=""
                      width={120}
                      placeholder="姓"
                      rules={{ required: '担当者氏名を入力してください。' }}
                    />
                    <RhfInput
                      control={control}
                      name="managerFirstName"
                      defaultValue=""
                      width={120}
                      placeholder="名"
                      rules={{ required: '担当者氏名を入力してください。' }}
                    />
                  </Box>
                  {(errors.managerFamilyName || errors.managerFirstName) && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerFamilyName?.message || errors.managerFirstName?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel>担当者氏名（フリガナ）</InputLabel>
                  <Box display="flex" gap={5}>
                    <RhfInput
                      control={control}
                      name="managerFamilyNameKana"
                      defaultValue=""
                      width={120}
                      placeholder="セイ"
                      rules={{
                        required: '担当者氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        }
                      }}
                    />
                    <RhfInput
                      control={control}
                      name="managerFirstNameKana"
                      defaultValue=""
                      width={120}
                      placeholder="メイ"
                      rules={{
                        required: '担当者氏名（フリガナ）を入力してください。',
                        pattern: {
                          value: /^[ァ-ンヴー]*$/i,
                          message: 'カナ文字を入力してください',
                        },
                      }}
                    />
                  </Box>
                  {(errors.managerFamilyNameKana || errors.managerFirstNameKana) && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
                    </Text>
                  )}
                </Box>
                <Box height={78} display="flex" flexDirection="column" gap={4}>
                  <InputLabel optional>担当者電話番号</InputLabel>
                  <RhfInput
                    control={control}
                    name="managerPhone"
                    defaultValue=""
                    placeholder="電話番号を入力"
                    rules={{
                      pattern: {
                        value: /^\d*$/,
                        message: '数字を使用してください。',
                      }
                    }}
                  />
                  {errors.managerPhone && (
                    <Text variant="caption12" color="cautionRed">
                      {errors.managerPhone?.message}
                    </Text>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box display="flex" gap={5} justifyContent="center">
            <MainButton variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              onClick={() => {
                onSubmit();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      ) : (
        // Responsive Components
        <Box width="100vw" display="flex" flexDirection="column" px={16}>
          <ContentTitle title="新規登録 - 事業者情報" />
          <Box display="flex" flexDirection="column" mt={18}>
            <Text variant="body14">事業者情報を入力して次へを押してください。項目は全て入力してください。</Text>
            <Text variant="body14">法人でない場合は無しを選択して、次へを押して雇用主情報を入力してください。</Text>
          </Box>
          <Box mt={22}>
            <Box display="flex" flexDirection="column" gap={6} pb={15}>
              <InputLabel>法人</InputLabel>
              <RadioGroup
                name="isCorporation"
                value={isCorporation ? 'yes' : 'no'}
                options={[
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        有
                      </Text>
                    ),
                    value: 'yes'
                  },
                  {
                    label: (
                      <Text color="blueGray" variant="body14">
                        無
                      </Text>
                    ),
                    value: 'no'
                  }
                ]}
                onChange={(e) => {
                  setIsCorporation(e.target.value === 'yes');
                }}
              />
            </Box>
            {isCorporation && (
              <>
                <Box display="flex" flexDirection="column" gap={2} pb={16}>
                  <InputLabel>事業者名</InputLabel>
                  <RhfInput
                    fullWidth
                    control={control}
                    name="businessName"
                    defaultValue=""
                    placeholder="事業者名を入力"
                    rules={{ required: '事業者名を入力してください。' }}
                  />
                  {errors.businessName?.message && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.businessName.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={18}>
                  <InputLabel>郵便番号</InputLabel>
                  <Box display="flex" alignItems="center" justifyContent='space-between'>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        control={control}
                        rules={{
                          required: '郵便番号を入力してください。',
                          pattern: {
                            value: /^\d*$/,
                            message: '数字を使用してください。'
                          },
                          maxLength: { value: 3, message: `郵便番号の形式で入力してください` },
                          minLength: { value: 3, message: `郵便番号の形式で入力してください` }
                        }}
                        name="zipCode1"
                        defaultValue=""
                        fullWidth
                      />
                    </div>
                    <Box px={14}>
                      <Text color="liteGray" variant="body14">
                        -
                      </Text>
                    </Box>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        control={control}
                        rules={{
                          required: '郵便番号を入力してください。',
                          pattern: {
                            value: /^\d*$/,
                            message: '数字を使用してください。'
                          },
                          maxLength: { value: 4, message: `郵便番号の形式で入力してください` },
                          minLength: { value: 4, message: `郵便番号の形式で入力してください` }
                        }}
                        name="zipCode2"
                        defaultValue=""
                        fullWidth
                      />
                    </div>
                  </Box>
                  {(errors.zipCode1 || errors.zipCode2) && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.zipCode1?.message || errors.zipCode2?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <InputLabel fullWidth optional>
                    住所
                  </InputLabel>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <div style={{width : "100%"}}>
                      <RhfSelect
                        height={40}
                        options={prefectureOptions}
                        control={control}
                        defaultValue=""
                        name="prefecture"
                        placeholder="都道府県"
                        fullWidth
                      />
                    </div>
                    <Box px={14}>
                      <Text color="liteGray" variant="body14">
                        -
                      </Text>
                    </Box>
                    <div style={{width : "100%"}}>
                      <RhfSelect
                        height={40}
                        options={cityOptions}
                        control={control}
                        defaultValue=""
                        name="city"
                        placeholder="市区町村"
                        fullWidth
                      />
                    </div>
                  </Box>
                  <Box display="flex" mt={16} alignItems="center" justifyContent='space-between'>
                  <div style={{width : "100%"}}>
                    <RhfInput control={control} name="address1" defaultValue="" fullWidth placeholder="番地等" />
                  </div>
                    <Box px={14}>
                      <Text color="liteGray" variant="body14">
                        -
                      </Text>
                    </Box>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        control={control}
                        name="address2"
                        defaultValue=""
                        fullWidth
                        placeholder="建物名・部屋番号"
                      />
                    </div>
                  </Box>
                </Box>
                <Box mt={16} display="flex" flexDirection="column" gap={2} pb={16}>
                  <InputLabel fullWidth subLabel="WEBから検索" subLabelHref="https://www.houjin-bangou.nta.go.jp/">
                    法人番号
                  </InputLabel>
                  <RhfInput
                    fullWidth
                    control={control}
                    name="corporateNumber"
                    defaultValue=""
                    placeholder="法人番号を記入"
                    rules={{
                      required: '法人番号を入力してください。',
                      pattern: {
                        value: /^\d*$/,
                        message: '13桁の数値で入力してください'
                      },
                      maxLength: { value: 13, message: `13桁の数値で入力してください` },
                      minLength: { value: 13, message: `13桁の数値で入力してください` }
                    }}
                  />
                  {errors.corporateNumber?.message && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.corporateNumber?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={16}>
                  <InputLabel>電話番号</InputLabel>
                  <RhfInput
                    fullWidth
                    control={control}
                    name="phone"
                    defaultValue=""
                    placeholder="電話番号を入力"
                    rules={{
                      required: '電話番号を入力してください。',
                      pattern: {
                        value: /^\d*$/,
                        message: '数字を使用してください。'
                      }
                    }}
                  />
                  {errors.phone?.message && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.phone?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={18}>
                  <InputLabel>代表者氏名</InputLabel>
                  <Box display="flex" justifyContent="space-between" gap={20}>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="mcoFamilyName"
                        defaultValue=""
                        width={120}
                        placeholder="姓"
                        rules={{ required: '代表者氏名を入力してください。' }}
                      />
                    </div>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="mcoFirstName"
                        defaultValue=""
                        width={120}
                        placeholder="名"
                        rules={{ required: '代表者氏名を入力してください。' }}
                      />
                    </div>
                  </Box>
                  {(errors.mcoFamilyName || errors.mcoFirstName) && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.mcoFamilyName?.message || errors.mcoFirstName?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={16}>
                  <InputLabel>代表者氏名（フリガナ）</InputLabel>
                  <Box display="flex" justifyContent="space-between" gap={20}>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="mcoFamilyNameKana"
                        defaultValue=""
                        width={120}
                        placeholder="セイ"
                        rules={{
                          required: '氏名（フリガナ）を入力してください。',
                          pattern: {
                            value: /^[ァ-ンヴー]*$/i,
                            message: 'カナ文字を入力してください'
                          }
                        }}
                      />
                    </div>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="mcoFirstNameKana"
                        defaultValue=""
                        width={120}
                        placeholder="メイ"
                        rules={{
                          required: '氏名（フリガナ）を入力してください。',
                          pattern: {
                            value: /^[ァ-ンヴー]*$/i,
                            message: 'カナ文字を入力してください'
                          }
                        }}
                      />
                    </div>
                  </Box>
                  {(errors.mcoFamilyNameKana || errors.mcoFirstNameKana) && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.mcoFamilyNameKana?.message || errors.mcoFirstNameKana?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={18}>
                  <InputLabel>担当者氏名</InputLabel>
                  <Box display="flex" justifyContent="space-between" gap={20}>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="managerFamilyName"
                        defaultValue=""
                        width={120}
                        placeholder="姓"
                        rules={{ required: '担当者氏名を入力してください。' }}
                      />
                    </div>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="managerFirstName"
                        defaultValue=""
                        width={120}
                        placeholder="名"
                        rules={{ required: '担当者氏名を入力してください。' }}
                      />
                    </div>
                  </Box>
                  {(errors.managerFamilyName || errors.managerFirstName) && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.managerFamilyName?.message || errors.managerFirstName?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pb={18}>
                  <InputLabel>担当者氏名（フリガナ）</InputLabel>
                  <Box display="flex" justifyContent="space-between" gap={20}>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="managerFamilyNameKana"
                        defaultValue=""
                        width={120}
                        placeholder="セイ"
                        rules={{
                          required: '担当者氏名（フリガナ）を入力してください。',
                          pattern: {
                            value: /^[ァ-ンヴー]*$/i,
                            message: 'カナ文字を入力してください'
                          }
                        }}
                      />
                    </div>
                    <div style={{width : "100%"}}>
                      <RhfInput
                        fullWidth
                        control={control}
                        name="managerFirstNameKana"
                        defaultValue=""
                        width={120}
                        placeholder="メイ"
                        rules={{
                          required: '担当者氏名（フリガナ）を入力してください。',
                          pattern: {
                            value: /^[ァ-ンヴー]*$/i,
                            message: 'カナ文字を入力してください'
                          }
                        }}
                      />
                    </div>
                  </Box>
                  {(errors.managerFamilyNameKana || errors.managerFirstNameKana) && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" gap={4} pb={16}>
                  <InputLabel fullWidth optional>担当者電話番号</InputLabel>
                  <RhfInput
                    fullWidth
                    control={control}
                    name="managerPhone"
                    defaultValue=""
                    placeholder="電話番号を入力"
                    rules={{
                      pattern: {
                        value: /^\d*$/,
                        message: '数字を使用してください。'
                      }
                    }}
                  />
                  {errors.managerPhone && (
                    <Box mt={-4}>
                      <Text variant="caption12" color="cautionRed">
                        {errors.managerPhone?.message}
                      </Text>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box display="flex" gap={12} pb={24} mt={8} justifyContent="center">
            <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
              戻る
            </MainButton>
            <MainButton
              fullWidth
              customHeight={40}
              onClick={() => {
                onSubmit();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      )}
    </div>
    // feat screen 01-01-04 end
  );
}
