/**
 * Figma ID: 02-02-04
 * 名称: パスワード再設定完了
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from '../component/ContentTitle';

export default function CompleteUpdatePassword() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  // feat_screen_02-02_04_start
  return !isMobile ? (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定 完了" />
      <Box display="grid" justifyContent="center" pt={21}>
        <Box width={300}>
          <Text variant="body14">パスワード再設定が完了しました。</Text>
        </Box>
        <Box width={300}>
          <Text variant="body14">ログイン画面から再度ログインしてください。</Text>
        </Box>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
    </Box>
  ) : (
    <Box px={16}>
      <ContentTitle title="パスワード再設定 完了" />
      <Box pt={16}>
        <Box>
          <Text variant="body14">パスワード再設定が完了しました。</Text>
        </Box>
        <Box>
          <Text variant="body14">ログイン画面から再度ログインしてください。</Text>
        </Box>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink variant='body14' to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
    </Box>
  );
  // feat_screen_02-02_04_end
}
