import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s05-01/type';
import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { ApolloQueryResult } from '@apollo/client';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function Complete(
  onClose: () => void,
  refetch: (
    variables?: Partial<gql.Exact<{ btmem_id: number; tpmem_id: number }>> | undefined
  ) => Promise<ApolloQueryResult<gql.GetVtBtmemEducInfoByTpmemIdBtmemIdQuery>>
): ModalRet {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        料率変更 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Text variant="body14">育成料料率の変更が完了しました。</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_05-01-03-04_start  
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-03-04_end
          variant="secondary" 
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    // feat_screen_05-01-03-04_start
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 189 : "unset"
    // feat_screen_05-01-03-04_end
  };

  return modalContent;
}
