/**
 * Figma ID: 01-01-01
 * 名称: 新規登録
 */
import React, { useEffect } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { Loading } from 'components/loading/loading';
import { useForm } from 'react-hook-form';
import { useGetMBrandByBizIdLazyQuery, useGetMBusinessQuery } from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  toNext: (args: { bizId: string; brandId: string; bizName: string; brandName: string }) => void;
};

export function SelectBusinessAndBrand({ toNext }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { openErrorModal } = useErrorModal();
  const {
    data: { getMBusiness } = { getMBusiness: [] },
    loading: bizDataLoading,
    error: bizDataError,
  } = useGetMBusinessQuery({
    context: { clientName: 'master' },
  });
  const [fetchBrandList, { error: fetchBrandListError }] = useGetMBrandByBizIdLazyQuery({
    context: { clientName: 'master' },
  });
  useEffect(() => {
    if (bizDataError || fetchBrandListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [bizDataError, fetchBrandListError, openErrorModal]);

  const [brandList, setBrandList] = React.useState<{ label: string; value: string }[]>([]);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    bizId: string;
    brandId: string;
  }>({
    mode: 'onChange',
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'bizId' && type === 'change') {
        fetchBrandList({ variables: { bid: Number(value.bizId) } })
          .then(({ data: { getMBrandByBizId } = { getMBrandByBizId: [] } }) => {
            setBrandList((getMBrandByBizId ?? []).map((val) => ({ label: val!.name, value: String(val!.id) })));
          })
          .catch((e) => {
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchBrandList, openErrorModal]);

  const onSubmit = handleSubmit((data) => {
    // 店舗未登録用
    const businessItem = getMBusiness?.find((item) => item?.id === Number(data.bizId));
    const brandItem = brandList?.find((item) => item?.value === data.brandId);
    toNext({
      bizId: data.bizId,
      brandId: data.brandId,
      bizName: businessItem?.name ?? '',
      brandName: brandItem?.label ?? '',
    });
  });

  return (
    <>
      {/*  feat_screen_01_01_01_start */}
      {!isMobile ? (
        <Box width={752} display="flex" flexDirection="column" gap={24}>
          <ContentTitle title="新規登録 - 業種とブランド選択" />
          <Box>
            <Text variant="body14">業種とブランドを選択して次へを押してください。</Text>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column" gap={4} justifyContent="flex-start" height={78}>
              <InputLabel>業種</InputLabel>
              {bizDataLoading && <Loading />}
              <RhfSelect
                placeholder="業種を選択"
                control={control}
                options={(getMBusiness ?? [])
                  .filter((val) => val !== null)
                  .map((val) => ({ label: val!.name, value: String(val!.id) }))}
                name="bizId"
                rules={{ required: '選択してください。' }}
              />
              {errors.bizId?.message && (
                <Text color="cautionRed" variant="caption12">{`※${errors.bizId.message}`}</Text>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={4} justifyContent="flex-start" height={78} mb={24}>
              <InputLabel>ブランド</InputLabel>
              <RhfSelect
                placeholder="ブランドを選択"
                control={control}
                options={brandList}
                name="brandId"
                rules={{ required: '選択してください。' }}
              />
              {errors.brandId?.message && (
                <Text color="cautionRed" variant="caption12">{`※${errors.brandId.message}`}</Text>
              )}
            </Box>
            <MainButton
              onClick={() => {
                onSubmit();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      ) : (
        // Mobile Components
        <Box width="100vw" display="flex" flexDirection="column" px={16}>
          <ContentTitle title="新規登録 - 業種とブランド選択" />
          <Box pb={22} pt={16}>
            <Text variant="body14">業種とブランドを選択して次へを押してください。</Text>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box width="100%" display="flex" flexDirection="column" gap={2} justifyContent="flex-start" pb={16}>
              <InputLabel fullWidth>業界</InputLabel>
              {bizDataLoading && <Loading />}
              <RhfSelect
                placeholder="業種を選択"
                control={control}
                height={40}
                options={(getMBusiness ?? [])
                  .filter((val) => val !== null)
                  .map((val) => ({ label: val!.name, value: String(val!.id) }))}
                name="bizId"
                rules={{ required: '選択してください。' }}
                fullWidth
              />
              {errors.bizId?.message && (
                <Box mt={-4}>
                  <Text color="cautionRed" variant="caption12">{`※${errors.bizId.message}`}</Text>
                </Box>
              )}
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap={2}
              justifyContent="flex-start"
              pb={16}
              mb={36}
            >
              <InputLabel>ブランド</InputLabel>
              <RhfSelect
                placeholder="ブランドを選択"
                control={control}
                height={40}
                options={brandList}
                name="brandId"
                rules={{ required: '選択してください。' }}
                fullWidth
              />
              {errors.brandId?.message && (
                <Box mt={-4}>
                  <Text color="cautionRed" variant="caption12">{`※${errors.brandId.message}`}</Text>
                </Box>
              )}
            </Box>
            <MainButton
              fullWidth
              customHeight={40}
              onClick={() => {
                onSubmit();
              }}
            >
              次へ
            </MainButton>
          </Box>
        </Box>
      )}
      {/*  feat_screen_01_01_01_end */}
    </>
  );
}
