import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 04-02-06-09
 * 出勤実態無し再確認
 */

function DenialModal(props: {
  isOpen: boolean;
  onModalClose: () => void;
  onClickBack: () => void;
  onClickSubmit: () => void;
}) {
  const { isOpen, onModalClose, onClickBack, onClickSubmit } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="cautionRed">
        出勤実態無し
      </Text>
    ),
    content: !isMobile ?(
      <Box>
        <Text variant="body14" color="black">
          出勤実態無しを送信します。
          <br />
          送信すると対象バイトラベラーはバックレ認定され無期限利用停止となります。
          <br />
          よろしいですか？
          <br />
          <br />
          この作業は取り消せません。
        </Text>
      </Box>
    ) : (
      /* feat_screen_04-02-06-09_start */
      <Box>
        <Text variant="body14" color="black">
          出勤実態無しを送信します。
          <br />
          送信すると対象バイトラベラーはバックレ認定され無期限利用停止となります。
          <Box mt={-2}>
            <Text variant="body14" color="black">よろしいですか？</Text>
          </Box>
          <Box pt={10}>
            <Text variant="body14" color="black">この作業は取り消せません。</Text>
          </Box>
        </Text>
      </Box>
      /* feat_screen_04-02-06-09_end */
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClickBack()}>
          戻る
        </MainButton>
        <MainButton width={104} thin onClick={() => onClickSubmit()}>
          送信
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_04-02-06-09_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => onClickBack()}>
          戻る
        </MainButton>
        <MainButton customHeight={32} width={96} thin onClick={() => onClickSubmit()}>
          送信
        </MainButton>
      </Box>
      /* feat_screen_04-02-06-09_end */
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      /* feat_screen_04-02-06-09_start */
      height={!isMobile ? "auto" : 267} 
      width={!isMobile ? 384 : "100%"}
      /* feat_screen_04-02-06-09_end */
    />
  );
}

export default DenialModal;
