import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { MainButton } from 'components/mainButton';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { usePagenation } from 'components/utils';
import {
  TRecvNotify,
  VtInvitedGroupInfo,
  useGetVtInvitedGroupInfoByTpmemIdIdLazyQuery,
  useUpdateTGroupMemStatusByIdTpmemIdMutation,
  useUpdateTRecvNotifyReadByTpmemIdKindMutation,
  useGetupdTRecvNotifyReadByIdTpmemIdSysIdKIndMutation,
  useGetupdVtBizBtmemInfoByTpmemIdBtmemIdMutation,
  GetVtNewlyMenuHomeArbeitByTpmemIdDocument,
  VtRewlyHome
} from 'graphql/graphql-mw';
import { format } from 'date-fns';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { MessageStatus, ResultRows } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { DateFormatForIso8601 } from 'components/utils/dateFormatForIso8601';
import { handleMutationResult } from 'error';
import { useNavigate } from 'react-router-dom';
import { MYPAGE_MENU_INDEX } from 'pages/s09/const';
import { TabIndex, MenuIndex } from 'pages/s04-02/components/const';
import { MenuIndex as OfferMenuIndex } from 'pages/s04-01-01/common/const';
import { MenuIndex as ThankMenuIndex } from 'pages/s05-01/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { styled } from '@linaria/react';
import { useHomeListApi } from '../common/hooks';
import { ListItemsType } from '../common/types';
import { useNotify } from '../provider/notifyProvider';
import { ModalType, RowAction } from '../common/const';
import { List } from './list';
import { ListRow } from './listRow';
import NoticeModal from './modal/noticeModal';
import GroupInviteModal from './modal/groupInviteModal';

type PropType = {
  hasAlert?: boolean;
  icon: ReactNode;
  label: string;
  clearButton?: boolean;
  messageStatus: number;
  sysId: number;
  column: string;
};

// タブ毎のページタイプ
type PageTabType = {
  employment: number;
  worker: number;
  work_time: number;
  review: number;
  b_offer: number;
  belong: number;
  group: number;
  u_notice: number;
};

export function Content({
  hasAlert = false,
  icon,
  label,
  clearButton = false,
  messageStatus,
  sysId,
  column,
}: PropType) {
  // ページネーション
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();

  // 通知情報
  const notifyContext = useNotify();
  const { setNotification, notification } = notifyContext;

  // 認証情報
  const auth = useAuth();

  // HOME情報を取得する
  const {
    data: homeData,
    loading: homeRenderLoading,
    refetch: refetchHomeApi,
    error: homeRenderError,
  } = useHomeListApi(auth.tpmemId || 0, sysId, messageStatus);

  // タブ毎のページ情報
  // ※Paginationが別タブでも共有されてしまうのでタブ毎にページ情報を保持する
  const [tabPage, setTabPage] = React.useState<PageTabType>({
    employment: 1,
    worker: 1,
    work_time: 1,
    review: 1,
    b_offer: 1,
    belong: 1,
    group: 1,
    u_notice: 1,
  });

  // 初回描画制御
  const [isInitialTabRender, setIsInitialTabRender] = React.useState<boolean>(true);

  // タブが切り替わったらタブ毎のページ情報に更新する
  React.useEffect(() => {
    setPage(tabPage[column as keyof PageTabType]);
  }, [tabPage, column, setPage]);

  // タブ通知情報を設定する
  const setTabNotify = useCallback(
    (tabList: VtRewlyHome[]) => {
      if (!tabList.length) return;

      setNotification({
        employment: tabList[0].employment || 0,
        worker: tabList[0].worker || 0,
        work_time: tabList[0].work_time || 0,
        review: tabList[0].review || 0,
        b_offer: tabList[0].b_offer || 0,
        belong: tabList[0].belong || 0,
        group: tabList[0].group || 0,
        u_notice: tabList[0].u_notice || 0,
      });
    },
    [setNotification]
  );

  // HOME画面更新
  const refetchHome = useCallback(async () => {
    // HOME画面更新
    const result = await refetchHomeApi();

    const tabList = result?.home ?? [];
    if (tabList.length) {
      setTabNotify(tabList);
    }
  }, [refetchHomeApi, setTabNotify]);

  React.useEffect(() => {
    // 初期表示時は採用タブを表示した再に強制的にHOME画面を更新する
    // 別画面から戻ってきたタイミングでタブの通知情報が更新されない為
    // feat refetch notifycation from /s00 to /s01 start
    if ((messageStatus === MessageStatus.ADOPTION || messageStatus === MessageStatus.NOTICE || messageStatus === MessageStatus.REVIEW) && isInitialTabRender) {
    // feat refetch notifycation from /s00 to /s01 end  
      (async () => {
        await refetchHome();
        setIsInitialTabRender(false);
      })();
    }
  }, [messageStatus, isInitialTabRender, refetchHome]);

  // HOME画面の一覧情報
  const HomeList = useMemo(() => homeData?.notify ?? [], [homeData]);

  const list = useMemo(() => [...HomeList], [HomeList]);

  const [dataSize, setDataSize] = React.useState<number>(0);

  const { openErrorModal } = useErrorModal();

  // モーダル表示
  const [activeModal, setActiveModal] = React.useState<ModalType | null>(null);
  // 現在選択中の行のデータ
  const [activeRowItem, setActiveRowItem] = React.useState<TRecvNotify | null>(null);
  // グループ招待データ
  const [invitedGroupInfo, setInvitedGroupInfo] = React.useState<VtInvitedGroupInfo | null>(null);

  // グループ招待データ取得
  // ステータスを更新するため、キャッシュを使用せず強制的にデータを再取得する
  const [getVtInvitedGroupInfoByTpmemId, { error: getVtInvitedGroupInfoByTpmemIdError }] =
    useGetVtInvitedGroupInfoByTpmemIdIdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const getInvitedGroupInfo = useCallback(
    async (tpmemId: number, refNo: number) => {
      const data = await getVtInvitedGroupInfoByTpmemId({ variables: { tpmem_id: tpmemId, id: refNo } });

      return data?.data?.getVTInvitedGroupInfoByTpmemIdId;
    },
    [getVtInvitedGroupInfoByTpmemId]
  );
  const [apiError, setApiError] = React.useState<RequiringCallbackError>();

  useEffect(() => {
    if (homeRenderError || getVtInvitedGroupInfoByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [homeRenderError, getVtInvitedGroupInfoByTpmemIdError, openErrorModal]);

  React.useEffect(() => {
    if (apiError) {
      setActiveModal(null);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  // 店舗グループメンバー情報更新
  const [updateTGroupMemStatusByIdTpmemId, { loading: updateTGroupMemStatusLoading }] =
    useUpdateTGroupMemStatusByIdTpmemIdMutation({
      onCompleted: (result) => {
        const r = handleMutationResult(result.updateTGroupMemStatusByIdTpmemId);
        if (r.isSuccessful) {
          // ResultRowsが0でもtrueなので
          if (result.updateTGroupMemStatusByIdTpmemId.resultRows === ResultRows.UPDATED) {
            setApiError(undefined);
          } else {
            setActiveModal(null);
            openErrorModal({
              message: '更新できませんでした。',
            });
          }
        } else {
          setApiError(r.error);
        }
      },
    });

  const updateInvitedGroupInfo = useCallback(
    async (status: number) => {
      await updateTGroupMemStatusByIdTpmemId({
        variables: { tpmem_id: auth.tpmemId || 0, id: invitedGroupInfo?.id || 0, status },
      });
    },
    [auth.tpmemId, invitedGroupInfo?.id, updateTGroupMemStatusByIdTpmemId]
  );

  // 行クリック時の既読更新
  const [getupdTRecvNotifyReadByIdTpmemIdSysIdKInd, { loading: updateRowReadLoading }] =
    useGetupdTRecvNotifyReadByIdTpmemIdSysIdKIndMutation();

  const updateRowRead = useCallback(
    async (id: number) => {
      await getupdTRecvNotifyReadByIdTpmemIdSysIdKInd({
        variables: { id, tpmem_id: auth.tpmemId || 0, sys_id: sysId, kind: messageStatus },
        // feat refetch query to refetcg get notifications start
        refetchQueries: [{
          query: GetVtNewlyMenuHomeArbeitByTpmemIdDocument,
          variables: {
            tpmem_id: auth.tpmemId
          }
        }]
        // feat refetch query to refetcg get notifications end
      })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            openErrorModal({ message: '更新に失敗しました' });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          });
        });

      // HOME画面更新
      await refetchHome();
    },
    [auth.tpmemId, sysId, messageStatus, getupdTRecvNotifyReadByIdTpmemIdSysIdKInd, refetchHome, openErrorModal]
  );

  // 既読更新
  const [updateTRecvNotifyReadByTpmemIdKind, { loading: updateTRecvNotifyLoading }] =
    useUpdateTRecvNotifyReadByTpmemIdKindMutation();

  const updateRead = useCallback(async () => {
    // メッセージ種別
    let kinds = String(messageStatus);
    // レビューの場合はレビュー、お気に入り、応援メッセージを既読にする
    if (messageStatus === MessageStatus.REVIEW) {
      kinds = `${MessageStatus.REVIEW},${MessageStatus.FAVORITE},${MessageStatus.CHEERING}`;
    }

    await updateTRecvNotifyReadByTpmemIdKind({
      variables: { tpmem_id: auth.tpmemId || 0, kinds },
      // feat refetch query to refetcg get notifications start
      refetchQueries: [{
        query: GetVtNewlyMenuHomeArbeitByTpmemIdDocument,
        variables: {
          tpmem_id: auth.tpmemId
        }
      }]
      // feat refetch query to refetcg get notifications end
    })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          openErrorModal({ message: '更新に失敗しました' });
        } else {
          const errorCode = result.data?.updateTRecvNotifyReadByTpmemIdKind.resultRows;
          // 必要であれば
        }
      })
      .catch(() => {
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  }, [auth.tpmemId, messageStatus, updateTRecvNotifyReadByTpmemIdKind, openErrorModal]);

  // Thanksメッセージデータチェック用 ※通知受信後に所属解除された場合は、飛び先がない
  const [getThanksMessageHistory] = useGetupdVtBizBtmemInfoByTpmemIdBtmemIdMutation();

  // データサイズ設定
  useEffect(() => {
    setDataSize(list?.length || 0);
  }, [list]);

  const navigate = useNavigate();

  // 一覧クリック時のコールバック
  const onClickRow = useCallback(
    (item: TRecvNotify | null) => {
      if (!item) return;
      const screenNo = item.screen_no;
      // 既読フラグ
      const read = Boolean(item.read);
      switch (screenNo) {
        case RowAction.NOTICE_MODAL:
          (async () => {
            // 既読更新
            if (!read) await updateRowRead(Number(item.id));
            // 通知情報モーダルを表示する
            setActiveRowItem(item);
            setActiveModal(ModalType.NOTICE);
          })();
          break;
        case RowAction.GROUP_INVITE_MODAL:
          (async () => {
            // 既読更新
            if (!read) await updateRowRead(Number(item.id));
            // グループ招待情報を取得する
            const igInfo = await getInvitedGroupInfo(auth.tpmemId || 0, Number(item.ref_no));
            if (!igInfo) return;
            setInvitedGroupInfo(igInfo);
            // グループ招待情報モーダルを表示する
            setActiveRowItem(item);
            setActiveModal(ModalType.GROUP_INVITE);
          })();
          break;
        case RowAction.TRANSIT_MYPAGE_GROUP_INVITE:
          (async () => {
            // 既読更新
            if (!read) await updateRowRead(Number(item.id));
          })();

          // マイページ グループ店舗一覧画面へ遷移する
          navigate('/s09', { state: { menuIndex: MYPAGE_MENU_INDEX.Group } });
          break;
        case RowAction.TRANSIT_MYPAGE_BASE_INFO:
          // マイページ 基本情報画面に遷移する
          navigate('/s09');
          break;
        case RowAction.TRANSIT_B_OFFER:
        case RowAction.TRANSIT_B_OFFER_CONTRACT:
          // 逆オファーページに遷移する
          // s04-03側で逆オファーページを表示する為に何かしらlocation.stateを設定する必要がある。
          // item.ref_noには逆オファーIDが設定されている
          navigate(`/s04-03/${String(item.ref_no)}`, { state: { id: item.ref_no } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_STAND_BY:
          // 成約バイト状況一覧画面に遷移する スタンバイタブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.STAND_BY } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_IN_PROGRESS:
          // 成約バイト状況一覧画面に遷移する バイト中タブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.IN_PROGRESS } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_WAITING_COMPLETE:
          // 成約バイト状況一覧画面に遷移する 完了待ちタブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.WAITING_COMPLETE } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_CHECK_IN_OUT:
          // 成約バイト状況一覧画面に遷移する チェックイン/アウトタブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.CHECK_IN_OUT } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_REQUEST_CORRECTION:
          // 成約バイト状況一覧画面に遷移する 時間修正タブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.REQUEST_CORRECTION } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_COMPLETE:
          // 成約バイト状況一覧画面に遷移する 完了タブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.COMPLETE } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_CANCEL:
          // 成約バイト状況一覧画面に遷移する キャンセルタブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.CANCEL } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_NO_SHOW:
          // 成約バイト状況一覧画面に遷移する バックレタブを表示する
          navigate(`/s04-02/`, { state: { tabIndex: TabIndex.NO_SHOW } });
          break;
        case RowAction.TRANSIT_NEW_ARBEIT_REVIEW:
          // 成約バイト状況画面に遷移する レビューのメニューを表示する
          // item.ref_noにはバイト募集IDが設定されている
          navigate(`/s04-02/${String(item.ref_no)}`, { state: { menuIndex: MenuIndex.REVIEW } });
          break;
        case RowAction.TRANSIT_ARBEIT_OFFER_APPLY:
          // バイト募集情報画面に遷移する 応募のメニューを表示する
          // item.ref_noにはバイト募集IDが設定されている
          navigate(`/s04-01-01/${String(item.ref_no)}`, { state: { menuIndex: OfferMenuIndex.OFFER } });
          break;
        case RowAction.TRANSIT_BT_BASE_INFO:
          // バイトラベラーの所属・登録対応の基本情報
          // item.ref_noにはバイトラベル会員IDが設定されている
          navigate(`/s06/${String(item.ref_no)}`);
          break;
        case RowAction.TRANSIT_BT_THANK_MESSAGE:
          // バイトラベラーの自社・サブ一覧のThankメッセージ
          // item.ref_noにはバイト募集IDが設定されている
          // Thankメッセージデータチェック ※データがある時、画面遷移
          (async () => {
            // Thankメッセージ取得
            await getThanksMessageHistory({
              variables: {
                tpmem_id: auth.tpmemId || 0,
                btmem_id: Number(item.ref_no),
              },
            })
              .then((data) => {
                // データチェック
                if (!data.data?.getupdVTBizBtmemInfoByTpmemIdBtmemId.logging) {
                  openErrorModal({ message: '所属登録は解除されています。' });
                } else {
                  navigate(`/s05-01/${String(item.ref_no)}`, { state: { menuIndex: ThankMenuIndex.THANK_MESSAGE } });
                }
              })
              .catch(() => {
                openErrorModal({ message: '所属登録は解除されています。' });
              });
          })();
          break;
        default:
          break;
      }
    },
    [auth.tpmemId, getInvitedGroupInfo, getThanksMessageHistory, navigate, updateRowRead, openErrorModal]
  );

  const listItems: ListItemsType[] = [];

  list.sort((data1, data2) => {
    const date1 = new Date(DateFormatForIso8601(data1?.recv_date ?? '') || 0);
    const date2 = new Date(DateFormatForIso8601(data2?.recv_date ?? '') || 0);
    return date2.getTime() - date1.getTime();
  });

  list.forEach((item, key) => {
    // 一覧部に表示するテキスト
    // メッセージ種別(kind)によって参照先が変わる
    const text = item.title || '';

    let recvDate = item?.recv_date;
    if (recvDate) {
      recvDate = format(new Date(DateFormatForIso8601(recvDate) || 0), 'yyyy年MM月dd日 HH:mm');
    }

    listItems.push({
      uniqueKey: key,
      text: (
        <ListRow item={item} onClickRow={() => onClickRow(item)}>
          {text}
        </ListRow>
      ),
      dateTime: recvDate ?? '',
      hasAlert: item.read ?? false,
    });
  });

  const tabHeight = 40;
  const headerHeight = 60;
  const dividerHeight = 1;
  const totalHeaderHeight = headerHeight + dividerHeight;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  /* feat_screen_03_01_start */
  const StyledPositionMainBtn = styled.div`
    position: absolute;
    /* feat_bottom_tab_responsive_mobile_start */
    top: -45px;
    right: 4px;
    /* feat_bottom_tab_responsive_mobile_end */
  `;
  /* feat_screen_03_01_end */

  return !isMobile ?(
    <>
      {(homeRenderLoading || updateTGroupMemStatusLoading || updateTRecvNotifyLoading || updateRowReadLoading) && (
        <Loading />
      )}
      <Box display="flex" flexDirection="column" width="100%" height={`calc(100% - ${tabHeight}px)`}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          justifyContent="space-between"
          alignItems="center"
          pa={16}
          minHeight={headerHeight}
          maxHeight={headerHeight}
        >
          <Box display="flex" flex="row" alignItems="center" gap={8}>
            {icon}
            <Text color="darkBlue" variant="h2">
              {label}
            </Text>
          </Box>
          {clearButton && label !== '勤務時間修正' && (
            <MainButton
              variant="secondary"
              onClick={() => {
                (async () => {
                  // 既読更新
                  await updateRead();
                  // HOME画面更新
                  await refetchHome();
                })();
              }}
              disabled={!hasAlert}
              width={102}
              thin
            >
              既読にする
            </MainButton>
          )}
        </Box>
        <Divider option="horizontal" />
        <Box
          display="flex"
          flexDirection="column"
          flex="auto"
          px={24}
          height={`calc(100% - ${totalHeaderHeight}px)`}
        >
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={(resultPage) => {
              handleChangePage(resultPage);
              tabPage[column as keyof PageTabType] = resultPage;
              setTabPage(tabPage);
            }}
          >
            <List listItems={listItems} page={page} limit={limit} />
          </PaginationBox>
        </Box>
      </Box>
      <NoticeModal
        isOpen={activeModal === ModalType.NOTICE}
        onModalClose={() => setActiveModal(null)}
        item={activeRowItem}
      />
      {invitedGroupInfo && (
        <GroupInviteModal
          isOpen={activeModal === ModalType.GROUP_INVITE}
          onModalClose={() => setActiveModal(null)}
          item={invitedGroupInfo}
          updateInvitedGroupInfo={(status: number) => updateInvitedGroupInfo(status)}
        />
      )}
    </>
  ) : (
    /* feat_screen_03-01_start */
    <>
      {(homeRenderLoading || updateTGroupMemStatusLoading || updateTRecvNotifyLoading || updateRowReadLoading) && (
        <Loading />
      )}
      <Box display="flex" flexDirection="column"  height={`calc(100% - ${tabHeight}px)`}>
        {clearButton && label !== '勤務時間修正' && (
          /* feat_bottom_tab_responsive_mobile_start */
          <div style={{ position: 'relative' }}>
            <StyledPositionMainBtn>
              <MainButton
                variant="secondary"
                onClick={() => {
                  (async () => {
                    // 既読更新
                    await updateRead();
                    // HOME画面更新
                    await refetchHome();
                  })();
                }}
                disabled={!hasAlert}
                width={108}
                customHeight={40}
                thin
              >
                既読にする
              </MainButton>
            </StyledPositionMainBtn>
          </div>
          /* feat_bottom_tab_responsive_mobile_end */
        )}
        <Divider option="horizontal" />
        <Box
          display="flex"
          flexDirection="column"
          flex="auto"
          height={`calc(100% - ${totalHeaderHeight}px)`}
        >
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={(resultPage) => {
              handleChangePage(resultPage);
              tabPage[column as keyof PageTabType] = resultPage;
              setTabPage(tabPage);
            }}
          >
            <Box px={12} mt={4}>
              <List listItems={listItems} page={page} limit={limit} />
            </Box>
          </PaginationBox>
        </Box>
      </Box>
      <NoticeModal
        isOpen={activeModal === ModalType.NOTICE}
        onModalClose={() => setActiveModal(null)}
        item={activeRowItem}
      />
      {invitedGroupInfo && (
        <GroupInviteModal
          isOpen={activeModal === ModalType.GROUP_INVITE}
          onModalClose={() => setActiveModal(null)}
          item={invitedGroupInfo}
          updateInvitedGroupInfo={(status: number) => updateInvitedGroupInfo(status)}
        />
      )}
    </>
    /* feat_screen_03-01_end */
  );
}
