import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { emailPattern } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormType) => void;
  currentEmail: string;
}

interface FormType {
  newEmail: string;
  newEmailConfirm: string;
}

function EditEmailModal(props: Props) {
  const { isOpen, onClose, onSubmit, currentEmail } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<FormType>();

  const emailWatch = watch('newEmail');

  function clearForm() {
    setValue('newEmail', '');
    setValue('newEmailConfirm', '');
  }

  function onModalClose() {
    clearForm();
    clearErrors();
    onClose();
  }

  function submit() {
    handleSubmit((data) => {
      onSubmit(data);
      clearForm();
    })();
  }

  const header = (
    <Text variant="h2" color="darkBlue">
      編集
    </Text>
  );

  const content = !isMobile ? (
    <Box pt={12} pb={20} pl={12}>
      <Box>
        <Box>
          <Text variant="h3" color="blueGray">
            現在のメールアドレス
          </Text>
        </Box>
        <Box mt={8}>
          <Text variant="body14">{currentEmail}</Text>
        </Box>
      </Box>
      <Box mt={24}>
        <Box>
          <Text variant="h3" color="blueGray">
            新しいメールアドレス
          </Text>
        </Box>
        <Box width="100%" mt={8}>
          <RhfInput
            name="newEmail"
            control={control}
            width={752}
            rules={{
              required: '新しいメールアドレスを入力してください。',
              pattern: {
                value: emailPattern,
                message: 'メールアドレスの形式が正しくありません。',
              },
            }}
          />
          <Box>
            <Text variant="caption12" color="cautionRed">
              {errors?.newEmail?.message}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box mt={24}>
        <Box>
          <Text variant="h3" color="blueGray">
            新しいメールアドレス（確認）
          </Text>
        </Box>
        <Box width="100%" mt={8}>
          <RhfInput
            name="newEmailConfirm"
            control={control}
            width={752}
            rules={{
              required: '新しいメールアドレス（確認）を入力してください。',
              validate: (value) => value === emailWatch || 'メールアドレスが一致しません。',
            }}
          />
          <Box>
            <Text variant="caption12" color="cautionRed">
              {errors?.newEmailConfirm?.message}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    // feat_screen_09-03-02_start
    <Box display="flex" flexDirection="column" gap={12}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Text display="block" variant="body14" color="blueGray" bold>
          現在のメールアドレス
        </Text>
        <Text display="block" variant="body14">{currentEmail}</Text>
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <Text display="block" variant="body14" color="blueGray" bold>
          新しいメールアドレス
        </Text>
        <RhfInput
          name="newEmail"
          control={control}
          fullWidth
          rules={{
            required: '新しいメールアドレスを入力してください。',
            pattern: {
              value: emailPattern,
              message: 'メールアドレスの形式が正しくありません。',
            },
          }}
        />
        {
          errors?.newEmail?.message && 
          <Text variant="caption12" color="cautionRed">
            {errors?.newEmail?.message}
          </Text>
        }
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Text variant="h3" color="blueGray">
            新しいメールアドレス（確認）
          </Text>
        </Box>
        <Box width="100%">
          <RhfInput
            name="newEmailConfirm"
            control={control}
            fullWidth
            rules={{
              required: '新しいメールアドレス（確認）を入力してください。',
              validate: (value) => value === emailWatch || 'メールアドレスが一致しません。',
            }}
          />
          <Box>
            <Text variant="caption12" color="cautionRed">
              {errors?.newEmailConfirm?.message}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
    // feat_screen_09-03-02_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-03-02_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-03-02_end
        variant="secondary" 
        onClick={() => onModalClose()}
      >
        キャンセル
      </MainButton>
      <MainButton
        // feat_screen_09-03-02_start 
        width={!isMobile ? 104 : 96} 
        thin={!isMobile} 
        // feat_screen_09-03-02_end
        onClick={() => submit()}>
        送信
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      height="auto"
      // feat_screen_09-03-02_start
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-03-02_end
    />
  );
}

export default EditEmailModal;
