import React from 'react';
import { styled } from '@linaria/react';

type ObjectFitType = 'contain' | 'cover';

interface Overlap {
  color: string;
  content: React.ReactNode;
}

export interface Props {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  rounded?: boolean;
  objectFit?: ObjectFitType;
  overlap?: Overlap;
}

const StyledDiv = styled.div<{ width: number; height: number; rounded: boolean }>`
  box-sizing: border-box;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ rounded }) => (rounded ? '50%' : 'none')};
  background-color: '#000000';
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOverlapper = styled.div<{ width: number; height: number; rounded: boolean; color: string }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ rounded }) => (rounded ? '50%' : 'none')};
  background-color: ${({ color }) => color};
  position: absolute;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledAvatar = styled.img<{ width: number; height: number; rounded: boolean; objectFit: ObjectFitType }>`
  box-sizing: border-box;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ rounded }) => (rounded ? '50%' : 'none')};
  object-fit: ${({ objectFit }) => objectFit};
  // feat_common_avatar_rotate_start
  @media only screen and (max-width: 768px) {
      image-orientation: none;
  }
  // feat_common_avatar_rotate_end
`;

export function Avatar({
  src,
  alt = '',
  width = 32,
  height = 32,
  rounded = false,
  objectFit = 'contain',
  overlap,
}: Props) {
  return (
    <StyledDiv width={width} height={height} rounded={rounded}>
      {/* feat_common_test_case_avatar_rotate_start */}
      <StyledAvatar src={src} alt={alt} width={width} height={height} rounded={rounded} objectFit={objectFit} />
        {/* feat_common_test_case_avatar_rotate_end */}
      {overlap && (
        <StyledOverlapper width={width} height={height} rounded={rounded} color={overlap?.color}>
          {overlap.content}
        </StyledOverlapper>
      )}
    </StyledDiv>
  );
}
