import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { useUpdateSTpmemRecruitImageClByTIdMutation } from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  tpmemId: number;
  selectedImageName: string;
  refetch: () => void;
}

type Phase = 'confirm' | 'complete';
export function DeleteModal(props: DeleteModalProps) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen = false, onClose, tpmemId, selectedImageName, refetch } = props;
  const [deletePhase, setDeletePhase] = React.useState<Phase>('confirm');
  const [deleteImageMutation] = useUpdateSTpmemRecruitImageClByTIdMutation();
  const { openErrorModal } = useErrorModal();

  const updateImage = async () => {
    switch (selectedImageName) {
      case 'image1':
        await deleteImageMutation({
          variables: { tpmem_id: tpmemId ?? 0, is_image1: true, is_image1_date: true },
        })
          .then((result) => {
            if (result.data?.updateSTpmemRecruitImageClByTId.resultRows === ResultRows.UPDATED) {
              refetch();
            } else {
              setDeletePhase('confirm');
              onClose();
              openErrorModal({
                message: '画像の削除に失敗しました。時間を空けて再度お試しください。',
              });
            }
          })
          .catch(() => {
            setDeletePhase('confirm');
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
        break;
      case 'image2':
        await deleteImageMutation({
          variables: { tpmem_id: tpmemId ?? 0, is_image2: true, is_image2_date: true },
        })
          .then((result) => {
            if (result.data?.updateSTpmemRecruitImageClByTId.resultRows === ResultRows.UPDATED) {
              refetch();
            } else {
              setDeletePhase('confirm');
              onClose();
              openErrorModal({
                message: '画像の削除に失敗しました。時間を空けて再度お試しください。',
              });
            }
          })
          .catch(() => {
            setDeletePhase('confirm');
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
        break;
      case 'image3':
        await deleteImageMutation({
          variables: { tpmem_id: tpmemId ?? 0, is_image3: true, is_image3_date: true },
        })
          .then((result) => {
            if (result.data?.updateSTpmemRecruitImageClByTId.resultRows === ResultRows.UPDATED) {
              refetch();
            } else {
              setDeletePhase('confirm');
              onClose();
              openErrorModal({
                message: '画像の削除に失敗しました。時間を空けて再度お試しください。',
              });
            }
          })
          .catch(() => {
            setDeletePhase('confirm');
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
        break;
      case 'image4':
        await deleteImageMutation({
          variables: { tpmem_id: tpmemId ?? 0, is_image4: true, is_image4_date: true },
        })
          .then((result) => {
            if (result.data?.updateSTpmemRecruitImageClByTId.resultRows === ResultRows.UPDATED) {
              refetch();
            } else {
              setDeletePhase('confirm');
              onClose();
              openErrorModal({
                message: '画像の削除に失敗しました。時間を空けて再度お試しください。',
              });
            }
          })
          .catch(() => {
            setDeletePhase('confirm');
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
        break;
      case 'image5':
        await deleteImageMutation({
          variables: { tpmem_id: tpmemId ?? 0, is_image5: true, is_image5_date: true },
        })
          .then((result) => {
            if (result.data?.updateSTpmemRecruitImageClByTId.resultRows === ResultRows.UPDATED) {
              refetch();
            } else {
              setDeletePhase('confirm');
              onClose();
              openErrorModal({
                message: '画像の削除に失敗しました。時間を空けて再度お試しください。',
              });
            }
          })
          .catch(() => {
            setDeletePhase('confirm');
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          });
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      header={
        <Text variant="h2" color="darkBlue">
          {deletePhase === 'confirm' ? '削除' : '削除 完了'}
        </Text>
      }
      content={
        <Box display="grid" alignItems="center" height="100%">
          {/* feat screen 08-08-03 start */}
          {deletePhase === 'confirm' ? (
            <Text display={!isMobile ? "inline" : "block"} variant="body14">画像を削除しますか？</Text>
          ) : (
            <Text display={!isMobile ? "inline" : "block"} variant="body14">画像の削除が完了しました。</Text>
          )}
          {/* feat screen 08-08-03 end */}
        </Box>
      }
      footer={
        deletePhase === 'confirm' ? (
          <Box display="flex" justifyContent="flex-end" gap={8}>
            <MainButton
              // feat screen 08-08-03 start
              width={!isMobile ? 104 : 96}
              // feat screen 08-08-03 end
              variant="secondary"
              onClick={() => {
                onClose();
              }}
            >
              キャンセル
            </MainButton>
            <MainButton
              // feat screen 08-08-03 start
              width={!isMobile ? 104 : 96}
              // feat screen 08-08-03 end
              onClick={() => {
                updateImage();
                setDeletePhase('complete');
              }}
            >
              削除
            </MainButton>
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end" gap={3}>
            <MainButton
              // feat screen 08-08-03 start
              width={!isMobile ? 104 : 96}
              // feat screen 08-08-03 end
              variant="secondary"
              onClick={() => {
                onClose();
                setDeletePhase('confirm');
              }}
            >
              閉じる
            </MainButton>
          </Box>
        )
      }
      onClose={onClose}
      // feat screen 08-08-03 start
      height={!isMobile ? 200 : "unset"}
      // feat screen 08-08-03 end
    />
  );
}
