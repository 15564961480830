/**
 * Figma ID: 01-01-12
 * 名称: 登録完了
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  tpmemId: string;
};

export function Complete({ tpmemId }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const navigate = useNavigate();

  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="新規登録 完了" />
      <Box>
        <Text variant="body14">新規登録が完了しました。 </Text>
        <br />
        <Text variant="body14">下記のログインIDを使用して、ログイン画面から再度ログインしてください。</Text>
        <br />
        <Text variant="body14">ログインIDは今後も使用しますので、必ずメモをしてください。</Text>
      </Box>

      <Box
        display="grid"
        justifyContent="center"
        backgroundColor="liteBlue"
        border={`2px solid ${PRIMARY_COLOR.BLUE}`}
        py={9}
      >
        <Text variant="h3" color="blueGray">
          ログインID
        </Text>
        <Box justifyContent="center" display="grid" pt={10}>
          <Text variant="body14">{tpmemId}</Text>
        </Box>
      </Box>

      <Box display="grid" justifyContent="center">
        <TextLink onClick={() => navigate({ pathname: '/signIn' })}>ログイン画面へ</TextLink>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="新規登録 完了" />
      <Box mt={18} mb={-2} display="flex" flexDirection="column">
        <Text display="block" variant="body14">新規登録が完了しました。</Text>
        <Box>
          <Text display="block" variant="body14">
            下記のログインIDを使用して、ログイン画面から再度ログインしてください。
            ログインIDは今後も使用しますので、必ずメモをしてください。
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="liteBlue"
        border={`2px solid ${PRIMARY_COLOR.BLUE}`}
        py={24}
        my={24}
      >
        <Text variant="h3" color="blueGray">
          ログインID
        </Text>
        <Box justifyContent="center" display="grid" pt={6}>
          <Text variant="body14">{tpmemId}</Text>
        </Box>
      </Box>

      <Box display="grid" justifyContent="center">
        <TextLink variant='body14' onClick={() => navigate({ pathname: '/signIn' })}>ログイン画面へ</TextLink>
      </Box>
    </Box>
  );
}
