/**
 * Figma ID: 04-01-01-23, 04-01-01-34, 04-01-01-37
 * 名称: バイト募集（応募/採用履歴）
 */
import React, { ReactNode, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { ScrollWrapper, HideWrapper } from 'components/assets/css/pages/pageStyle';
import { Text } from 'components/text';
import { PaginationBox } from 'components/pagination/paginationBox';
import { Box } from 'components/box';
import { Loading } from 'components/loading/loading';
import { HistoryList } from 'components/historyList';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useLoggingListConnectApi } from '../../common/hooks';

export function History() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;

  const { id } = useParams();

  /**
   * Connect API
   */

  const { data, loading, error: LoggingError } = useLoggingListConnectApi(Number(tpmemId), Number(id));
  const { openErrorModal } = useErrorModal();
  /**
   * エラー処理
   */
  useEffect(() => {
    if (LoggingError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [LoggingError, openErrorModal]);

  /**
   * State
   */
  const [dataSize, setDataSize] = useState<number>(0);
  const [limit, setLimit] = useState<10 | 20 | 30>(10);
  const [page, setPage] = useState<number>(1);
  const [dataList, setDataList] = useState<{ uniqueKey: string | number; text: ReactNode; dateTime: string }[]>([]);

  /**
   * Mapping
   */
  const mappedList = useMemo(
    () =>
      (data?.getTLoggingListByTpmemId as gql.TLogging[])?.map((item) => ({
        uniqueKey: item.id as number | string,
        dateTime: item.log_date as string,
        text: item.matter as ReactNode,
      })) || [],
    [data]
  );

  /**
   * Pagination
   */
  useEffect(() => {
    setDataList(mappedList.slice((page - 1) * limit, page * limit));
    setDataSize(mappedList.length);
  }, [mappedList, limit, page]);

  const handleLimitChange = (value: 10 | 20 | 30) => {
    setPage(1);
    setLimit(value);
  };
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  /**
   * Render
   */
  // feat screen 04-01-01-23 start
  return !isMobile ? (
    <HideWrapper>
      <Box px={16} pt={0} pb={24}>
        <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
          <div>
            <Text color="darkBlue" variant="h2">
              応募/採用履歴
            </Text>
          </div>
        </Box>
        <Divider option="horizontal" />
        <PaginationBox
          dataSize={dataSize}
          limit={limit}
          page={page}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
        >
          <ScrollWrapper bottom={325}>{!loading ? <HistoryList items={dataList} /> : <Loading />}</ScrollWrapper>
        </PaginationBox>
      </Box>
    </HideWrapper>
  ) : (
    // Responsive Component
    <HideWrapper>
      <Box height="100%">
        <Box display="flex" width="100%" alignItems="center" justifyContent="center" pt={10} pb={12}>
          <div>
            <Text color="darkBlue" variant="h2">
              応募/採用履歴
            </Text>
          </div>
        </Box>
        <Divider option="horizontal" />
        <Box height="calc(100% - 46px)">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleLimitChange}
            onChangePage={handlePageChange}
          >
            <div style={{height: "100%", overflow: "auto", padding: "0 12px"}}>
              {!loading ? <HistoryList items={dataList} /> : <Loading />}
            </div>
          </PaginationBox>
        </Box>
      </Box>
    </HideWrapper>
    // feat screen 04-01-01-23 end
  );
}
