import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text';
import { Icon } from '../icon/icon';
import { PRIMARY_COLOR, GENERIC_COLOR, LABEL_COLOR } from '../assets/css/style';

export interface Props {
  value: string;
  options: {
    label: string;
    value: string;
    icon?: React.ReactNode;
  }[];
  onChange?: (value: string, label: string) => void;
  placeholder?: string;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  error?: boolean;
  name?: string;
  disabled?: boolean;
}

const StyledWrapper = styled.div`
  /* feat_common_responsive_style_start*/
  @media only screen and (max-width: 768px) {
    flex: 1;
  }
  /* feat_common_responsive_style_end*/
  position: relative;
`;

const StyledButton = styled.button<{
  width: number;
  height: number;
  fullWidth?: boolean;
  error: boolean;
  disabled: boolean;
}>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  height: ${({ height }) => height}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5.5px 8px;
  border: 1px solid ${({ error }) => (error ? GENERIC_COLOR.CAUTION_RED : GENERIC_COLOR.SUPERLITE_GRAY)};
  border-radius: 2px;
  background-color: ${({ disabled }) => (disabled ? GENERIC_COLOR.SUPERLITE_GRAY : GENERIC_COLOR.WHITE)};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:focus {
    border: 1px solid ${LABEL_COLOR.BLUE};
  }
  /* feat_common_reponsive_select_start */
  @media only screen and (max-width: 768px) {
    border-radius: 4px;
  }  
  /* feat_common_reponsive_select_end */
`;

const StyledDropDownMenu = styled.ul<{
  width: number;
  height: number;
  fullWidth?: boolean;
}>`
  position: absolute;
  top: ${({ height }) => height}px;
  /* feat_common_responsive_style_start*/
  @media only screen and (max-width: 768px) {
    top: ${({ height }) => height + 2}px;
    max-height: 160px;
  }
  /* feat_common_responsive_style_end*/
  left: 0;
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  max-height: 306px;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${GENERIC_COLOR.WHITE};
  border: 1px solid ${PRIMARY_COLOR.PRIMARY_BLUE};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  list-style: none;
  /* 現状の最大値のz-indexです。モーダルよりも上に来るようにしています */
  z-index: 101;
`;

const StyledMenuItem = styled.li<{ active: boolean }>`
  font-family: Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background-color: ${({ active }) => (active ? PRIMARY_COLOR.BLUE : GENERIC_COLOR.WHITE)};
  color: ${({ active }) => (active ? GENERIC_COLOR.WHITE : PRIMARY_COLOR.DARK_BLUE)};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* feat_common_responsive_style_start*/
  @media only screen and (max-width: 768px) {
    padding: 11px;
  }
  /* feat_common_responsive_style_end */
  padding: 8px;
  width: 100%;
  cursor: pointer;
  :hover {
    background: ${GENERIC_COLOR.SUPERLITE_GRAY};
  }
  :first-child {
    border-radius: 4px 4px 0 0;
  }
  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export function Select({
  value: currentValue,
  options,
  width = 328,
  height = 32,
  placeholder,
  fullWidth,
  onChange,
  error = false,
  name,
  disabled = false,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    // dropdownが非表示の場合何もしない
    if (!dropdownRef.current) {
      return;
    }
    // ボタンかドロップダウンメニュー以外が押された場合はドロップダウンメニューを閉じる
    if (!buttonRef.current?.contains(e.target as Node) && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledWrapper>
      <StyledButton
        ref={buttonRef}
        width={width}
        fullWidth={fullWidth}
        height={height}
        onClick={() => setIsOpen(!isOpen)}
        error={error}
        disabled={disabled}
      >
        {/* feat_screen_01-01-04_start */}
        {!isMobile ? (
          <Box width={width - 40} maxWidth={width - 40}>
            <Text variant="body14" color={currentValue ? 'black' : 'liteGray'} lineClamp={1}>
              {options.find(({ value }) => value === currentValue)?.label || placeholder}
            </Text>
          </Box>
        ) : (
          <Box width={fullWidth ? "100%" : width - 40}>
            <Text variant="body14" color={currentValue ? 'black' : 'liteGray'} lineClamp={1}>
              {options.find(({ value }) => value === currentValue)?.label || placeholder}
            </Text>
          </Box>
        )}
        {/* feat_screen_01-01-04_end */}
        {options.find(({ value }) => value === currentValue)?.icon || ''}

        {isOpen ? <Icon name="chevronLess" /> : <Icon name="chevronMore" />}
      </StyledButton>
      {isOpen && (
        <StyledDropDownMenu width={width} fullWidth={fullWidth} height={height} ref={dropdownRef}>
          {options.map(({ label, value }) => (
            <StyledMenuItem
              key={value}
              active={value === currentValue}
              onClick={() => {
                if (onChange) {
                  onChange(value, label);
                }
                setIsOpen(false);
              }}
            >
              {label}
            </StyledMenuItem>
          ))}
        </StyledDropDownMenu>
      )}
      <input type="hidden" value={currentValue} name={name} />
    </StyledWrapper>
  );
}
