import React, { useCallback, useEffect } from 'react';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { SubMenu } from 'components/subMenu';
import { useLocation, useParams } from 'react-router-dom';
import { ContentsCard, PageBody } from 'components/assets/css/pages/pageStyle';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import { Dropdown } from 'components/dropdown';
import { Card } from 'components/card';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Avatar } from 'components/avatar';
import { MainButton } from 'components/mainButton';
import { Loading } from 'components/loading/loading';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import { handleMutationResult } from 'error';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import { MenuIndexType } from 'pages/s05-01/type';
import { BasicInfo } from './components/basic_info';
import { ModalStep, generateChip } from '../s06-utils';
import { StatusName } from '../const/const';
import { SkillCheckSheet } from './components/skill-check-sheet';
import { ApprovalModal } from '../modal/ApprovalModal';
import { ApprovalCompletedModal } from '../modal/ApprovalCompletedModal';
import { RejectionModal } from '../modal/RejectionModal';
import { RejectionCompletedModal } from '../modal/RejectionCompletedModal';

/**
 * Figma ID: 04-02-01,06-02-02
 * 名称: 基本情報,スキルチェックシートのフレーム
 */

const StyledHeaderBG = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
  gap: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

// feat screen 06-02-01 start
const StyledHeaderSub = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 10px 0 0px 15px;
`;
// feat screen 06-02-01 end

const headerBackgroundColor = (identification: number) => {
  switch (identification) {
    case StatusName.APPROVED:
      return 'rgba(49, 112, 200, 0.1)';
    case StatusName.APPLYING:
      return 'rgba(232, 167, 0, 0.1)';
    case StatusName.DENIAL:
      return 'rgba(229, 0, 0, 0.1)';
    default:
      return 'rgba(232, 167, 0, 0.1)';
  }
};

export function S06Details() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat_common_responsive_tab_start
  const location = useLocation();
  const state = location?.state as MenuIndexType;
  const menuIndex = state?.menuIndex;
  const [tab, setTab] = React.useState<number>(menuIndex || 0);
  // feat_common_responsive_tab_end
  const { id } = useParams();
  const auth = useAuth();
  const { tpmemId } = auth;
  const [update, { data, loading, error: errorBelongInfo }] = gql.useGetupdVtRegBelongInfoByTdBIdMutation({
    variables: { tpmem_id: tpmemId || 0, btmem_id: Number(id) },
  });
  const [approvalModalStep, setApprovalModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [approvalCompletedModalStep, setApprovalCompletedModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [rejectionModalStep, setRejectionModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [rejectionCompletedModalStep, setRejectionCompletedModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  useEffect(() => {
    update();
  }, [update]);
  const { openErrorModal } = useErrorModal();
  const [apiError, setApiError] = React.useState<RequiringCallbackError>();
  const [approveMutation, { loading: loadingApptove, error: errorApprove }] =
    gql.useUpdateTBelongBtmemRnByTIdBIdMutation({
      variables: {
        tpmem_id: tpmemId || 0,
        btmem_id: Number(id),
      },
    });

  const [rejectMutation, { loading: loadingReject, error: errorReject }] = gql.useUpdateTBelongBtmemByIdBtmemIdMutation(
    {
      variables: {
        id: tpmemId || 0,
        btmem_id: Number(id),
      },
    }
  );

  useEffect(() => {
    if (errorBelongInfo) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [errorBelongInfo, openErrorModal]);

  const onClickSubmit = useCallback(() => {
    approveMutation()
      .then((result) => {
        if (result.data === undefined || result.data === null) return;
        const r = handleMutationResult(result.data.updateTBelongBtmemRNByTIdBId);
        if (r.isSuccessful) {
          // ResultRowsをみないと更新できたかわからないため
          if (result.data.updateTBelongBtmemRNByTIdBId.resultRows === ResultRows.NOT_UPDATED) {
            setApiError(undefined);
            setApprovalModalStep(ModalStep.NULL);
            openErrorModal({
              message: '変更できませんでした。',
            });
          } else {
            setApiError(undefined);
            if (!errorApprove) {
              setApprovalModalStep(ModalStep.NULL);
              setApprovalCompletedModalStep(ModalStep.COMPLETE);
              update();
            }
          }
        } else {
          setApiError(r.error);
        }
      })
      .catch(() => {
        setApiError(undefined);
        setApprovalModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  }, [approveMutation, errorApprove, update, openErrorModal]);

  const onRejectionClickSubmit = useCallback(() => {
    rejectMutation()
      .then((result) => {
        if (result.data === undefined || result.data === null) return;
        const r = handleMutationResult(result.data.updateTBelongBtmemByIdBtmemId);
        if (r.isSuccessful) {
          // ResultRowsをみないと更新できたかわからないため
          if (result.data.updateTBelongBtmemByIdBtmemId.resultRows === ResultRows.NOT_UPDATED) {
            setApiError(undefined);
            setRejectionModalStep(ModalStep.NULL);
            openErrorModal({
              message: '変更できませんでした。',
            });
          } else {
            setApiError(undefined);
            if (!errorReject) {
              setRejectionModalStep(ModalStep.NULL);
              setRejectionCompletedModalStep(ModalStep.COMPLETE);
              update();
            }
          }
        } else {
          setApiError(r.error);
        }
      })
      .catch(() => {
        setApiError(undefined);
        setRejectionModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  }, [errorReject, rejectMutation, update, openErrorModal]);

  React.useEffect(() => {
    if (apiError) {
      setApprovalModalStep(ModalStep.NULL);
      setRejectionModalStep(ModalStep.NULL);
      setApprovalCompletedModalStep(ModalStep.NULL);
      setRejectionCompletedModalStep(ModalStep.NULL);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [
    apiError,
    openErrorModal,
    setApprovalModalStep,
    setRejectionModalStep,
    setApprovalCompletedModalStep,
    setRejectionCompletedModalStep,
  ]);

  if (loading || loadingApptove || loadingReject) {
    return Loading();
  }

  return !isMobile ? (
    <>
      <Box mb={24}>
        <StyledHeaderBG backgroundColor={headerBackgroundColor(data?.getupdVTRegBelongInfoByTdBId.status || 0)}>
          <Avatar src={data?.getupdVTRegBelongInfoByTdBId?.image ?? NoImageLarge} width={80} height={60} />
          <Box display="flex" flexDirection="column" width="100%" gap={1} pt={8}>
            <Box width={56} alignItems="center">
              {generateChip(data?.getupdVTRegBelongInfoByTdBId.status || 0)}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                <Text variant="h1">{data?.getupdVTRegBelongInfoByTdBId.name}</Text>
              </Box>
              <Box display="flex" mb={6}>
                <Box mr={8}>
                  <MainButton
                    variant="warnSecondary"
                    width={160}
                    onClick={() => {
                      setRejectionModalStep(ModalStep.CHECK);
                    }}
                    disabled={data?.getupdVTRegBelongInfoByTdBId.status !== StatusName.APPLYING}
                  >
                    否認
                  </MainButton>
                </Box>
                <Box gap={8}>
                  <MainButton
                    variant="primary"
                    icon="adminPanelSettings"
                    width={160}
                    onClick={() => {
                      setApprovalModalStep(ModalStep.CHECK);
                    }}
                    disabled={data?.getupdVTRegBelongInfoByTdBId.status !== StatusName.APPLYING}
                  >
                    承認
                  </MainButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledHeaderBG>
      </Box>
      <PageBody bottom={88}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                content: <BasicInfo userId={id || ''} />,
                label: '基本情報',
              },
              {
                content: <SkillCheckSheet userId={id || ''} />,
                label: 'スキルチェックシート',
              },
            ]}
          />
        </ContentsCard>
      </PageBody>
      <ApprovalModal
        step={approvalModalStep}
        onModalClose={() => {
          setApprovalModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {
          onClickSubmit();
        }}
      />
      <ApprovalCompletedModal
        step={approvalCompletedModalStep}
        onModalClose={() => {
          setApprovalCompletedModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {}}
      />
      <RejectionModal
        step={rejectionModalStep}
        onModalClose={() => {
          setRejectionModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {
          onRejectionClickSubmit();
        }}
      />
      <RejectionCompletedModal
        step={rejectionCompletedModalStep}
        onModalClose={() => {
          setRejectionCompletedModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {}}
      />
    </>
  ) : (
    // feat screen 06-02-01 start
    <>
      <MultiMenuHeader
        items={[
          {
            label: 'バイトラベラー',
          },
          {
            label: '所属登録対応',
          },
        ]}
        mainHeader="所属登録対応"
      />
      <Box mb={16} mt={6}>
        <StyledHeaderSub backgroundColor={headerBackgroundColor(data?.getupdVTRegBelongInfoByTdBId.status || 0)}>
          <Box display="flex" flexDirection="row" mb={18}>
            <Box backgroundColor="black">
              <Avatar src={data?.getupdVTRegBelongInfoByTdBId?.image ?? NoImageLarge} width={80} height={60} />
            </Box>
            <Box flexDirection="column" ml={18} pt={5}>
              <Box width={50} alignItems="center">
                {generateChip(data?.getupdVTRegBelongInfoByTdBId.status || 0)}
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" pt={5}>
                <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                  <Text variant="h1">{data?.getupdVTRegBelongInfoByTdBId.name}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={6} justifyContent="space-between" mr={15}>
            <Box>
              <MainButton
                variant="warnSecondary"
                width={164}
                onClick={() => {
                  setRejectionModalStep(ModalStep.CHECK);
                }}
                disabled={data?.getupdVTRegBelongInfoByTdBId.status !== StatusName.APPLYING}
              >
                否認
              </MainButton>
            </Box>
            <Box>
              <MainButton
                variant="primary"
                icon="adminPanelSettings"
                width={164}
                onClick={() => {
                  setApprovalModalStep(ModalStep.CHECK);
                }}
                disabled={data?.getupdVTRegBelongInfoByTdBId.status !== StatusName.APPLYING}
              >
                承認
              </MainButton>
            </Box>
          </Box>
        </StyledHeaderSub>
      </Box>
      <Box px={16}>
        <Card overflow="hidden">
          <Box height="calc(100svh - 339px)">
            <Dropdown
              fullWidth
              items={[
                {
                  content: (
                    <Box width="100%" height="90%" overflow="auto">
                      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                        <BasicInfo userId={id || ''} />
                      </Box>
                    </Box>
                  ),
                  label: '基本情報',
                },
                {
                  content: (
                    <Box width="100%" height="90%" overflow="auto">
                      <Box pb={15}>
                        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                          <SkillCheckSheet userId={id || ''} />
                        </Box>
                      </Box>
                    </Box>
                  ),
                  label: 'スキルチェックシート',
                },
              ]}
              tab={tab}
              setTab={setTab}
            />
          </Box>
        </Card>
      </Box>
      <ApprovalModal
        step={approvalModalStep}
        onModalClose={() => {
          setApprovalModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {
          onClickSubmit();
        }}
      />
      <ApprovalCompletedModal
        step={approvalCompletedModalStep}
        onModalClose={() => {
          setApprovalCompletedModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {}}
      />
      <RejectionModal
        step={rejectionModalStep}
        onModalClose={() => {
          setRejectionModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {
          onRejectionClickSubmit();
        }}
      />
      <RejectionCompletedModal
        step={rejectionCompletedModalStep}
        onModalClose={() => {
          setRejectionCompletedModalStep(ModalStep.NULL);
        }}
        onClickSubmit={() => {}}
      />
    </>
    // feat screen 06-02-01 end
  );
}
