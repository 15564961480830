import { styled } from '@linaria/react';

const PRIMARY_COLOR = {
  PRIMARY_BLUE: '#008599',
  BLUE: '#00A5BF',
  DARK_BLUE: '#16495F',
  BLUE_GRAY: '#5F797D',
  LITE_BLUE: '#F8FEFF',
  /* feat_common_styles_color_start */
  OPEN_MENUBAR_BG: 'rgba(0,0,0,0.4)',
  /* feat_common_styles_color_end */
};
const GENERIC_COLOR = {
  DARK_GRAY: '#484848',
  LITE_GRAY: '#969696',
  SUPERLITE_GRAY: '#DBDBDB',
  BG_GRAY: '#FBFBFB',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  MORDAL_bg: 'rgba(0, 0, 0, 0.5)',
  RED_LITE: '#F35555',
  CAUTION_RED: '#E50000',
  OK_BLUE: '#3170C8',
  WARNING_YELLOW: '#E8A700',
};

const LABEL_COLOR = {
  BLUE: '#00A5BF',
  OK_BLUE: '#3170C8',
  DARK_BLUE: '#16495F',
  RED_LITE: '#F35555',
  CAUTION_RED: '#E50000',
  WARNING_YELLOW: '#E8A700',
  GREEN: '#35A554',
  LITE_GRAY: '#969696',
};

// feat screen 00 start
const QUICK_ACCESS_COLOR = {
  SUCCESS: '#92D050',
  APPLY_SUCCESS: '#07CF84',
  WARNING: '#FEB72B',
  INFO: '#1480FF',
  WHITE: '#EF873B',
  SEMI_BLUE: '"#F8F8F8"',
  DISABLE: '#F0F6FF'
};
// feat screen 00 end

const Text = styled.div<{
  color: string;
}>`
  color: ${({ color }) => color};
  font-family: 'Meiryo';
  font-style: normal;

  flex: none;
  order: 1;
  flex-grow: 1;
`;

const FONT = {
  BIG_TITLE: styled(Text)`
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  `,
  H1: styled(Text)`
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  `,
  H2: styled(Text)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  H3: styled(Text)`
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  `,
  BODY18: styled(Text)`
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  `,
  BODY18B: styled(Text)`
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  `,
  BODY16: styled(Text)`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  BODY16B: styled(Text)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  BODY14: styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  `,
  BODY14B: styled(Text)`
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  `,
  BODY14U: styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    text-decoration-line: underline;
  `,
  CAPTION12: styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  `,
  CAPTION12B: styled(Text)`
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  `,
  CAPTION12U: styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    text-decoration-line: underline;
  `,
  CAPTION11: styled(Text)`
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  `,
  CAPTION10: styled(Text)`
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
  `,
};

// /////////////////////////////

const FullScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Drop = {
  Header: styled.div`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 0px 0px;
  `,
  Footer: styled.div`
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 8px 8px;
  `,
};

const TextBox = styled.input<{
  width: number;
  height: number;
}>`
  /* form/input */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* バイトラベル共通/white */
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const TextArea = styled.textarea<{
  width: number;
  height: number;
}>`
  resize: none;
  box-sizing: border-box;

  width: 752px;
  height: 156px;

  /* バイトラベル共通/white */
  background: ${GENERIC_COLOR.WHITE};

  /* バイトラベル共通/superlite_grey */
  border: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  border-radius: 2px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
`;

const Blank = styled.div<{
  width: number;
  height: number;
}>`
  /* form/warning_message */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 8px;
  gap: 8px;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const Divider = styled.div<{
  width: number;
  order: number;
}>`
  width: ${({ width }) => width}px;
  height: 1px;

  /* Inside auto layout */
  flex: none;
  order: ${({ order }) => order};
  align-self: stretch;
  flex-grow: 0;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* バイトラベル共通/superlite_grey */
  background: ${GENERIC_COLOR.SUPERLITE_GRAY};
`;

export { FullScreen, GENERIC_COLOR, PRIMARY_COLOR, LABEL_COLOR, FONT, Drop, TextBox, TextArea, Blank, Divider, 
  // feat screen 00 start
  QUICK_ACCESS_COLOR
  // feat screen 00 end
  };
