import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';

import { MultiMenuHeader } from 'components/multiMenuHeader';
import { Box } from 'components/box';
import { Card } from 'components/card';
import { Tab } from 'components/newTab';
import { Text } from 'components/text';
import { AdmissionFees, TuitionFees, ImmediateWithouthold, MonthlyWithouthold } from './components';

export function S0702() {
  /* feat_common_responsive_useMobile_logic_start */
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  /* feat_screen 11 handle set tab select start */
  const { id } = useParams();
  const [tab, setTab] = React.useState<number>(Number(id) || 0);
  const arrayTab = ['受入費用', '育成料明細', '源泉徴収(即日)', '源泉徴収(月次)'];

  useEffect(() => {
    const iId = Number(id);
    const correctTab = [0, 1, 2, 3];
    if (iId || iId === 0) {
      if (correctTab.includes(iId)) {
        setTab(Number(iId));
      } else {
        navigate('/*');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isMobile) {
      if (location.pathname !== `/s07-02/${tab}`) {
        navigate(`/s07-02/${tab}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  /* feat_screen 11 handle set tab select end */

  // feat_screen_07-02-01_04 start
  return !isMobile ? (
    // feat_screen_07-02-01_04 start
    <Box display="flex" flexDirection="column" height="100%" gap={26}>
      <Box display="flex" height={30}>
        <Text variant="h1" color="darkBlue">
          利用明細閲覧
        </Text>
      </Box>
      <Box display="flex" flex="auto">
        <Card overflow="hidden">
          <Box display="flex" pt={16}>
            <Tab
              items={[
                { label: '受入費用', content: <TuitionFees /> },
                { label: '育成料', content: <AdmissionFees /> },
                { label: '源泉徴収(即日)', content: <ImmediateWithouthold /> },
                { label: '源泉徴収(月次)', content: <MonthlyWithouthold /> },
              ]}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  ) : (
    // feat_screen_07-02-01_04 start
    <Box display="flex" flexDirection="column" columnGap={16} width="100vw" height="100%">
      <MultiMenuHeader
        items={[
          {
            label: 'バイトラベラー',
          },
          {
            label: arrayTab[tab],
          },
        ]}
        mainHeader="利用明細閲覧"
      />
      <Box overflow="auto" height="100%" display="flex" px={16} pt={16} pb={isMobile ? 76 : 16}>
        <Card overflow="auto">
          <Box display="flex" width="100%" flexDirection="column">
            <Box display="flex" width="100%" alignItems="center">
              <Box display="flex" width="100%" flex="1">
                <Dropdown
                  fullWidth
                  items={[
                    {
                      content: <TuitionFees />,
                      label: '受入費用',
                    },
                    {
                      content: <AdmissionFees />,
                      label: '育成料明細',
                    },
                    {
                      content: <ImmediateWithouthold />,
                      label: '源泉徴収(即日)',
                    },
                    {
                      content: <MonthlyWithouthold />,
                      label: '源泉徴収(月次)',
                    },
                  ]}
                  tab={tab}
                  setTab={setTab}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
    // feat_screen_07-02-01_04 end
  );
}
