/* feat_common_responsive_dropdown_start */
import React, { ReactNode, useEffect, useRef, useCallback } from 'react';
import { styled } from '@linaria/react';
import { Name } from 'components/icon/icon';
import { cx } from '@linaria/core';
import { Divider } from 'components/newDivider';
import { Text } from '../text';
import { Icon } from '../icon';
import { Box } from '../box';

export interface Props {
  items: {
    label: string;
    labelLength?: number;
    content: ReactNode;
    hasNotification?: boolean;
    callBack?: (index: number) => void;
  }[];
  defaultIndex?: number;
  width?: number | string;
  fullWidth?: boolean;
  fullWidthItem?: Array<string>;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}

const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 48px;
`;

const StyledDropdown = styled.div<{
  width: number | string;
  fullWidth: boolean;
  isFullWidth: boolean;
}>`
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #008599;
    color: white;
    padding: 10.5px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    cursor: default;
    border-radius: ${({ fullWidth,isFullWidth }) => (fullWidth || isFullWidth ? '10px 10px 0px 0px' : '10px 0px 0px 0px')} ;
    width: ${({ width, fullWidth,isFullWidth }) => (fullWidth || isFullWidth ? '100%' : (typeof width === 'number' ? `${width}px` : width))};
`;

const StyledItemSelected = styled.div`
    display: flex;
    gap: 6px;
    align-items: center
`;

const StyledDropdownWrapper = styled.div<{
    width: number | string;
    fullWidth: boolean;
    isFullWidth: boolean;
}>`
    position: absolute;
    top: 49px;
    left: 0;
    background-color: #FFF;
    width: calc(100% - 2px);
    border-radius: 4px;
    padding: 7px 0;
    border: 1px solid var(--Primary-Color-2, #008599);
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    z-index: 5;
    max-height: 55vh;
    overflow: auto;
    &.d-block-class {
        display: block;
    }

    &.d-none-class {
        display: none;
    }
`;

const StyledDropdownItem = styled.a<{
  active: boolean;
  labelLength: number;
}>`
    position: relative;
    color: black;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    background-color: ${({ active }) => (active ? '#008599' : '#FFFFFF')};
`;

const StyledIndicatorWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 6px;
    left: 8px;
`;

const StyledRedPointRight = styled.div`
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
`;

type PropsOpacity = {
    label: string;
    content: React.ReactNode;
    hasNotification?: boolean | undefined;
    iconName?: string;
};

export function Dropdown({tab, setTab, items, defaultIndex , width = 189, fullWidth = false, fullWidthItem = []}: Props) {
    // const [tab, setTab] = React.useState<number>(defaultIndex || 0);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [itemSelect, setItemSelect] = React.useState<PropsOpacity>(items[tab || 0]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleOpenDropdown = () => {
        setIsOpen(!isOpen);
    };

    useCallback(() => {
        if (defaultIndex !== undefined) setTab(defaultIndex);
    }, [defaultIndex, setTab]);
    
    const handleClickOutside = (e: MouseEvent) => {
        // dropdownが非表示の場合何もしない
        if (!dropdownRef.current) {
            return;
        }
        // ボタンかドロップダウンメニュー以外が押された場合はドロップダウンメニューを閉じる
        if (!buttonRef.current?.contains(e.target as Node) && !dropdownRef.current.contains(e.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    return (
        <StyledWrapper>
            <StyledDropdown width={width} fullWidth={fullWidth} 
                ref={buttonRef}
                isFullWidth={fullWidthItem.some((item) => itemSelect.label.includes(item))} 
                onClick={() => { setIsOpen(!isOpen); }}
            >
                <Box px={12} display='flex' justifyContent='space-between' width="100%">
                    <StyledItemSelected>
                        {
                            itemSelect?.iconName && (
                                <Icon name={itemSelect?.iconName as Name} color='#FFFFFF'/>
                            )
                        }
                        <Text lineClamp={1} variant='body18' color='white' bold>{items[tab]?.label}</Text>
                    </StyledItemSelected>
                    <Icon
                        onClick={() => handleOpenDropdown()}
                        size={24}
                        name={isOpen ? 'chevronLess' : 'chevronMore'}
                        color='#FFFFFF'
                    />
                    {items[tab]?.hasNotification && (
                        <StyledRedPointRight>
                            <Icon name="indicatorRed" size={8} />
                        </StyledRedPointRight>
                    )}
                </Box>
                <StyledDropdownWrapper width={width} fullWidth={fullWidth} 
                    ref={dropdownRef}
                    isFullWidth={fullWidthItem.some((item) => itemSelect.label.includes(item))} 
                    className={cx(isOpen ? 'd-block-class' : 'd-none-class')}
                >
                    {items.map((item, index) => (
                        <StyledDropdownItem
                            type="button"
                            role="tab"
                            active={tab === index}
                            onClick={() => {
                                setTab(index);
                                setItemSelect(item);
                                setIsOpen(!isOpen);
                                if (item.callBack) item.callBack(index);
                            }}
                            labelLength={item.labelLength || 120}
                            key={item.label}
                        >
                            {item.hasNotification && (
                            <StyledIndicatorWrapper>
                                <Icon name="indicatorRed" size={8} />
                            </StyledIndicatorWrapper>
                            )}
                            <Text variant="body16p5" color={tab === index ? 'white' : 'black'}>
                                {item.label}
                            </Text>
                        </StyledDropdownItem>
                    ))}
                </StyledDropdownWrapper>
            </StyledDropdown>
            {/*  feat screen_04-01-01-01_start */}
            <Divider option="horizontal" />
            {/*  feat screen_04-01-01-01_end */}
            {items[tab]?.content}
        </StyledWrapper>
    );
}
/* feat_common_responsive_dropdown_start */