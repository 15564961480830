import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React from 'react';
import { VtBtmemBase } from 'graphql/graphql-mw';
import { Favorite } from 'pages/s04-02/components/const';
import { Tab } from 'components/newTab';
import { Chip } from 'components/chip';
import { Divider } from 'components/newDivider';
import { RecruitType, AgreementStatus } from 'components/const';
import { convertRecruitTypeToChipColor, convertRecruitTypeToLabel } from 'pages/s04-02/components/common';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import BasicInfo from './BasicInfo';
import SkillCheck, { SkillData } from './SkillCheck';

/**
 * Figma ID: 04-02-01-03, 04-02-01-04
 * 名称: 基本情報モーダル
 */

function generateHeader(btmem_id: number, 
  navigate: NavigateFunction, 
  type?: number | null, 
  /* feat_screen_04-02-01-03_add_props_start */
  isMobile?: boolean
  /* feat_screen_04-02-01-03_add_props_end */
  ) {
  return !isMobile ? (
    <Box display="flex" justifyContent="space-between">
      <Text variant="h2" color="darkBlue">
        {btmem_id.toString().padStart(10, '0')}
      </Text>
      <MainButton
        width={104}
        variant="secondary"
        onClick={() => {
          if (type === RecruitType.BELONGED || type === RecruitType.SUB) {
            navigate(`/s05-01/${btmem_id}`);
          } else if (type === RecruitType.HIRED || type === RecruitType.GROUP) {
            navigate(`/s05-02/${btmem_id}`);
          }
        }}
      >
        もっと見る
      </MainButton>
    </Box>
  ): (
    /* feat_screen_04-02-01-03_add_props_start */
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Text variant="h2" color="darkBlue">
          {btmem_id.toString().padStart(10, '0')}
        </Text>
      </Box>
      <MainButton
        width={104}
        variant="secondary"
        onClick={() => {
          if (type === RecruitType.BELONGED || type === RecruitType.SUB) {
            navigate(`/s05-01/${btmem_id}`);
          } else if (type === RecruitType.HIRED || type === RecruitType.GROUP) {
            navigate(`/s05-02/${btmem_id}`);
          }
        }}
      >
        もっと見る
      </MainButton>
    </Box>
    /* feat_screen_04-02-01-03_add_props_end */
  );
}

function generateContent(
  /* feat_screen_04-02-01-03_start */
  isMobile: boolean,
  tab: number,
  setTab: React.Dispatch<React.SetStateAction<number>>,
  /* feat_screen_04-02-01-03_end */
  workingAgreementStatus: AgreementStatus, 
  info: VtBtmemBase, 
  skillData?: SkillData[]
) {
  /* feat_screen_04-02-01-03_start */
  const isMobileProp = isMobile;
  /* feat_screen_04-02-01-03_end */
  function generateBody(
    /* feat_screen_04-02-01-03_start */
    isMobi?: boolean,
    tabMobile?: number,
    setTabMobile?: React.Dispatch<React.SetStateAction<number>>,
    /* feat_screen_04-02-01-03_end */
    type?: number | null) {
    switch (type) {
      case RecruitType.HIRED:
        return !isMobi ? (
          <Box px={12} mt={8}>
            <Divider option="horizontal" />
            <Box mt={8}>
              <Tab
                items={[
                  {
                    label: '基本情報',
                    content: <BasicInfo 
                      workingAgreementStatus={workingAgreementStatus} 
                      info={info}
                      /* feat_screen_04-02-01-03_start */
                      isHasDivider={false}
                      /* feat_screen_04-02-01-03_end */
                    />,
                  },
                  {
                    label: 'スキルチェック',
                    content: <SkillCheck rank={info.rank_name || ''} data={skillData || []} />,
                  },
                ]}
              />
            </Box>
          </Box>
        ): (
          /* feat_screen_04-02-01-03_start */
            <Box mt={8}>
              <Box mt={16}>
                <Dropdown
                  tab={tab}
                  setTab={setTab}
                  fullWidth
                  items={[
                    {
                      label: '基本情報',
                      content: <BasicInfo 
                        workingAgreementStatus={workingAgreementStatus} 
                        info={info} 
                        isHasDivider={false}
                      />,
                    },
                    {
                      label: 'スキルチェック',
                      content: <SkillCheck rank={info.rank_name || ''} data={skillData || []} />,
                    },
                  ]}
                />
              </Box>
            </Box>
          /* feat_screen_04-02-01-03_end */
        );
      default:
        return <BasicInfo 
          workingAgreementStatus={workingAgreementStatus} 
          info={info}
          /* feat_screen_04-02-01-03_start */
          isHasDivider
          /* feat_screen_04-02-01-03_end */
        />;
    }
  }

  const top = !isMobileProp ? (
    <Box display="flex" gap={24} px={12}>
      <img src={info.image || ''} alt="" width={60} height={60} />
      <Box display="flex" flexDirection="column" mt={6}>
        <Box display="flex" gap={8} width={56}>
          <Chip color={convertRecruitTypeToChipColor(info.type)}>{convertRecruitTypeToLabel(info.type)}</Chip>
        </Box>
        <Box display="flex" alignItems="center">
          <Text variant="h1">{info.btmem_id.toString().padStart(10, '0')}</Text>
          {info.favorite === Favorite.YES && (
            <Box border={`solid 1px ${PRIMARY_COLOR.PRIMARY_BLUE}`} borderRadius={20} px={8} ml={24}>
              <Text color="primaryBlue" variant="caption12">
                お気に入り
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    /* feat_screen_04-02-01-03_start */
    <Box display="flex" gap={16}>
      <img src={info.image || ''} alt="" width={60} height={60} />
      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Chip color={convertRecruitTypeToChipColor(info.type)}>{convertRecruitTypeToLabel(info.type)}</Chip>
          <Box mt={5}>
            <Text variant="body16" bold>{info.btmem_id.toString().padStart(10, '0')}</Text>
          </Box>
        </Box>
        <Box display="flex" alignItems="end" justifyContent="flex-start" mt={18} ml={20}>
          {info.favorite === Favorite.YES && (
            <Box border={`solid 1px ${PRIMARY_COLOR.PRIMARY_BLUE}`} borderRadius={24} py={3} px={12}>
              <Text display='block' bold color="primaryBlue" variant="chip10">お気に入り</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    /* feat_screen_04-02-01-03_end */
  );

  /* feat_screen_04-02-01-03_start */          
  const body = generateBody(isMobileProp, tab, setTab, info.type);
  /* feat_screen_04-02-01-03_end */

  return (
    <>
      {top}
      {body}
    </>
  );
}

function footer(
  onClose: () => void, 
  /* feat_screen_04-02-01-03_start */  
  isMobile?: boolean
  /* feat_screen_04-02-01-03_end */ 
) {
  return (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        /* feat_screen_04-02-01-03_start */
        width={!isMobile ? 104 : 96}
        /* feat_screen_04-02-01-03_end */
        variant="secondary" 
        onClick={onClose}
      >
        閉じる
      </MainButton>
    </Box>
  );
}

function BasicInfoModal(props: {
  isOpen: boolean;
  onClose: () => void;
  workingAgreementStatus: AgreementStatus;
  info: VtBtmemBase;
  skills?: SkillData[];
}) {
  const { isOpen, onClose, workingAgreementStatus, info, skills } = props;

  const navigate = useNavigate();
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(0);
  // feat_common_responsive_tab_end
  
  return (
    <Modal
      /* feat_screen_04-02-01-03_start */
      width={!isMobile ? 1003 : "100%"}
      height={!isMobile ? "90vh" : 589}
      /* feat_screen_04-02-01-03_end */
      header={generateHeader(info.btmem_id, navigate, info.type, isMobile)}
      content={generateContent(
        /* feat_screen_04-02-01-03_start */
        isMobile,
        tab,
        setTab,
        /* feat_screen_04-02-01-03_end */
        workingAgreementStatus, 
        info, 
        skills
      )}
      footer={footer(
        onClose,
        /* feat_screen_04-02-01-03_start */  
        isMobile
        /* feat_screen_04-02-01-03_end */  
      )}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

export default BasicInfoModal;
