/**
 * Figma ID: 04-03-01
 * 名称: 逆オファー
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { styled } from '@linaria/react';
import * as gql from 'graphql/graphql-mw';
import { format, isSameDay } from 'date-fns';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination/paginationBox';
import { Input } from 'components/input';
import { Loading } from 'components/loading/loading';
import { Select } from 'components/select';
import { Icon } from 'components/icon';
import { Chip } from 'components/chip';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { DatePicker } from 'components/datePicker';
import { List } from 'components/list';
import { Divider } from 'components/newDivider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { orderBy } from 'lodash';
import { ScrollWrapper, ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { useSort, usePagenation } from 'components/utils/hooks';
// feat screen 04-03-01-01 start
import useIsMobile from 'hooks/responsive/useIsMobile';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import MobileFilterButton from 'components/button/mobileFilterButton';
// feat screen 04-03-01-01 end
import { ModalComponent } from './components/modal/modal';
import { useGetBtmemOfferListConnectApi } from './common/hooks';

const Indicator = styled.div`
  position: absolute;
  top: -8px;
  right: 0;
`;

/* feat_screen_04-03-01-01 start */
const StyledRelativeWrap = styled.div`
  display: block;
  position: relative;
`;
const StyledAbsoluteButton = styled.div`
  display: block;
  position: absolute;
  top: 70px;
  right: 20px;
`;

interface PropState {
  dateValue: Date;
}
/* feat_screen_04-03-01-01 end */
export function S0403() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end

  const auth = useAuth();
  const { tpmemId } = auth;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  /* feat_screen_04-03-01-01 start */
  const state = (location?.state || {}) as PropState;
  const { dateValue } = state;
  /* feat_screen_04-03-01-01 end */
  const [dataSize, setDataSize] = useState<number>(0);

  /**
   * [API] 逆オファー一覧取得
   */
  const {
    data: VtBtmemOfferList,
    loading,
    error: vtBtmemOfferListError,
  } = useGetBtmemOfferListConnectApi(Number(tpmemId));
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (vtBtmemOfferListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vtBtmemOfferListError, openErrorModal]);

  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  const [searchName, setSearchName] = useState<string>('');
  const [workingDay, setWorkingDay] = React.useState<Date | null>(dateValue || null);
  const [statusSelect, setStatusSelect] = React.useState<string>('');
  const [searchConditions, setSearchConditions] = useState<{ name: string } | null>(null);

  /**
   * チップを生成
   * 0:逆オファー中 1:募集送信済 3:成約 4:他店成約 5:期限終了 6:削除 7:強制停止
   */
  const generateChip = (status?: number | null) => {
    switch (status) {
      case 0:
        return <Chip color="darkBlue">逆オファー</Chip>;
      case 1:
        return <Chip color="okBlue">募集送信済</Chip>;
      case 3:
        return <Chip color="okBlue">成約</Chip>;
      case 4:
        return <Chip color="liteGray">他店成約</Chip>;
      case 5:
        return <Chip color="liteGray">期限終了</Chip>;
      case 6:
        return <Chip color="liteGray">削除</Chip>;
      case 7:
        return <Chip color="cautionRed">強制停止</Chip>;
      default:
        return <Chip color="liteGray">---</Chip>;
    }
  };

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    // feat_screen_04-03-01-01 start
    { columnName: '', key: 'eye', width: !isMobile ? 24 : 20 },
    // feat_screen_04-03-01-01 end
    { columnName: '', key: 'image', width: 40 },
    { columnName: '状態', key: 'status', width: 56 },
    { columnName: 'オファーNo.', key: 'offer_no', width: 136 },
    { columnName: 'オファーした人', key: 'btmem_kana', width: 120 },
    { columnName: '登録日時', key: 'created_date', width: 140 },
    { columnName: 'バイト期間', key: 'begin_end', width: 140 },
    { columnName: '希望時間帯', key: 'work_time', width: 104 },
    { columnName: '希望地域', key: 'state_city', width: 104 },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    if (loading) {
      return [];
    }
    let items = VtBtmemOfferList?.getVTBtmemOfferListByTpmemId || [];

    /**
     * Statusでフィルター
     */
    if (statusSelect) {
      items = items.filter(({ status }) => {
        switch (statusSelect) {
          case '0':
            return status === 0;
          case '1':
            return status === 1;
          case '3':
            return status === 3;
          case '4':
            return status === 4;
          case '5':
            return status === 5;
          case '6':
            return status === 6;
          case '7':
            return status === 7;
          case '':
          default:
            return true;
        }
      });
    }

    /**
     * キーワードでフィルター
     */
    if (searchConditions) {
      items = items.filter(({ btmem_kana }) => {
        if (!searchConditions.name) {
          return true;
        }
        const reg = new RegExp(searchConditions.name);
        return reg.test(String(btmem_kana));
      });
    }

    /**
     * 募集したい日でフィルター
     */
    if (workingDay) {
      items = items.filter(({ begin_day }) => {
        if (!begin_day) {
          return false;
        }
        const regex = /(\d{4})年(\d{2})月(\d{2})日/;
        const matches = format(new Date(begin_day), 'yyyy年MM月dd日').match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return isSameDay(new Date(year, month - 1, day), workingDay);
        }
        return false;
      });
    }

    setDataSize(items.length);

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    const handleOnClick = (link: string, item?: gql.VtBtmemOfferList) => {
      navigate(link, { state: { parentData: item } });
    };

    /**
     * リスト生成
     */
    return items
      .map((item) => ({
        uniqueKey: `${String(item?.offer_id)}_${Math.random()}`,
        backgroundColor: 'white',
        eye: (
          <div style={{ position: 'relative' }}>
            {/* feat_screen_04-03-01-01 add size start */}
            <Icon
              name="eye"
              size={!isMobile ? 24 : 20}
              onClick={() => handleOnClick(`${location.pathname}/${String(item?.offer_id)}`, item)}
            />
            {/* feat_screen_04-03-01-01 add size end */}
            {item?.newly === 1 && (
              <Indicator>
                <Icon name="indicatorRed" onClick={() => {}} size={8} />
              </Indicator>
            )}
          </div>
        ),
        image: (
          <ImgWrapper>
            {/* feat_screen_04-03-01-01 add display block start */}
            <img style={{ width: '40px', height: '40px', display: 'block' }} src={item?.btmem_image || ''} alt="" />
            {/* feat_screen_04-03-01-01 add display block end */}
          </ImgWrapper>
        ),
        status: generateChip(item?.status),
        offer_no: item?.offer_no,
        btmem_kana: item?.btmem_kana,
        created_date: item?.created_date,
        begin_end: format(new Date(item?.begin_day || 0), 'yyyy年MM月dd日'),
        work_time: item?.work_time,
        state_city: item?.state_city,
      }))
      .slice(limit * (page - 1), limit * page);
  }, [
    sort,
    page,
    limit,
    loading,
    VtBtmemOfferList,
    searchConditions,
    statusSelect,
    workingDay,
    location.pathname,
    navigate,
    // feat_screen_04-03-01-01 add dept start
    isMobile,
    // feat_screen_04-03-01-01 add dept end
  ]);

  // feat screen 04-03-01-01 start
  useEffect(() => {
    navigate('/s04-03', {
      state: {
        dateValue: workingDay,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingDay]);
  // feat screen 04-03-01-01 end

  /**
   * Render
   */
  // feat screen 04-03-01-01 start
  return !isMobile ? (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box height={30} mb={26} display="flex" alignItems="center" justifyContent="space-between">
        <Text color="darkBlue" variant="h1">
          逆オファー
        </Text>

        <MainButton icon="edit" onClick={() => setIsModalOpen(true)}>
          募集テンプレート
        </MainButton>
      </Box>
      <Box flex="auto">
        <Card overflow="hidden">
          <Box display="flex" flexWrap="wrap" alignItems="center" pa={16} gap={16}>
            <div>
              <Input
                onChange={(event) => setSearchName(event?.target.value || '')}
                placeholder="氏名"
                value={searchName}
                width={160}
              />
            </div>
            <div>
              <MainButton
                variant="primary"
                width={80}
                icon="search"
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  setSearchConditions({
                    name: searchName,
                  });
                }}
              >
                検索
              </MainButton>
            </div>
            <Divider option="vertical" length={24} />
            <div>
              <DatePicker
                selected={workingDay}
                onChange={(value) => {
                  // ページ初期化
                  setPage(1);
                  setWorkingDay(value);
                }}
                placeholderText="募集したい日"
                width={160}
              />
            </div>
            <div>
              <Select
                onChange={(value) => {
                  setPage(1);
                  setStatusSelect(value);
                }}
                options={[
                  {
                    label: '全て',
                    value: '',
                  },
                  {
                    label: '逆オファー',
                    value: '0',
                  },
                  {
                    label: '募集送信済',
                    value: '1',
                  },
                  {
                    label: '成約',
                    value: '3',
                  },
                  {
                    label: '他店成約',
                    value: '4',
                  },
                  {
                    label: '期限終了',
                    value: '5',
                  },
                  {
                    label: '削除',
                    value: '6',
                  },
                  {
                    label: '強制停止',
                    value: '7',
                  },
                ]}
                placeholder="状態"
                value={statusSelect}
                width={120}
              />
            </div>
            <div>
              <MainButton
                variant="clear"
                width={44}
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  setSearchName('');
                  setStatusSelect('');
                  setWorkingDay(null);
                  setSearchConditions(null);
                }}
              >
                クリア
              </MainButton>
            </div>
          </Box>
          <Divider option="horizontal" />
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" pt={24}>
              <Box flex="auto" width="100%" py={0} px={24}>
                <PaginationBox
                  dataSize={dataSize}
                  limit={limit}
                  page={page}
                  onChangeLimit={handleChangeLimit}
                  onChangePage={handleChangePage}
                >
                  <ScrollWrapper bottom={336}>
                    {loading ? (
                      <Loading />
                    ) : (
                      <List
                        key={String(limit) + String(page) + (sort?.key || '')}
                        header={header}
                        items={displayItems}
                        sort={sort}
                        onChangeSort={handleChangeSort}
                        width="100%"
                        rowWidth="100%"
                      />
                    )}
                  </ScrollWrapper>
                </PaginationBox>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <ModalComponent isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
  ) : (
    // Responsive Component
    <Box display="flex" flexDirection="column" width="100vw" height="100%">
      <StyledRelativeWrap>
        <Box>
          <MultiMenuHeader
            items={[
              {
                label: '募集・採用',
              },
              {
                label: '逆オファー',
              },
            ]}
            mainHeader="逆オファー"
          />
          <StyledAbsoluteButton>
            <MainButton customHeight={40} width={180} icon="edit" onClick={() => setIsModalOpen(true)}>
              募集テンプレート
            </MainButton>
          </StyledAbsoluteButton>
        </Box>
      </StyledRelativeWrap>
      <Box width="100%" px={16} mt={16} pb={isMobile ? 76 : 16}>
        <Card>
          <Box pa={12} height={48} width="100%">
            <Text color="darkBlue" variant="body18" bold>
              逆オファー
            </Text>
          </Box>
          <Divider option="horizontal" />
          <Box display={isShowFilter ? 'flex' : 'none'} flexWrap="wrap" alignItems="center" pa={16} pb={8}>
            <Box display="flex" width="100%" gap={12} mb={12}>
              <div style={{ width: '100%' }}>
                <Input
                  onChange={(event) => setSearchName(event?.target.value || '')}
                  placeholder="氏名"
                  value={searchName}
                  fullWidth
                />
              </div>
              <div>
                <MainButton
                  variant="primary"
                  width={80}
                  customHeight={40}
                  icon="search"
                  onClick={() => {
                    // ページ初期化
                    setPage(1);
                    setSearchConditions({
                      name: searchName,
                    });
                  }}
                >
                  検索
                </MainButton>
              </div>
            </Box>
            <Box display="flex" justifyContent="space-between" gap={12} mb={6} width="100%">
              <Box width="100%">
                <DatePicker
                  selected={workingDay}
                  onChange={(value) => {
                    // ページ初期化
                    setPage(1);
                    setWorkingDay(value);
                  }}
                  placeholderText="募集したい日"
                />
              </Box>
              <Box width="100%">
                <Select
                  onChange={(value) => {
                    setPage(1);
                    setStatusSelect(value);
                  }}
                  options={[
                    {
                      label: '全て',
                      value: '',
                    },
                    {
                      label: '逆オファー',
                      value: '0',
                    },
                    {
                      label: '募集送信済',
                      value: '1',
                    },
                    {
                      label: '成約',
                      value: '3',
                    },
                    {
                      label: '他店成約',
                      value: '4',
                    },
                    {
                      label: '期限終了',
                      value: '5',
                    },
                    {
                      label: '削除',
                      value: '6',
                    },
                    {
                      label: '強制停止',
                      value: '7',
                    },
                  ]}
                  placeholder="状態"
                  value={statusSelect}
                  fullWidth
                  height={40}
                />
              </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <MainButton
                variant="clear"
                width={44}
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  setSearchName('');
                  setStatusSelect('');
                  setWorkingDay(null);
                  setSearchConditions(null);
                }}
              >
                クリア
              </MainButton>
            </Box>
          </Box>
          <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
          <Box flex="auto" width="100%">
            <PaginationBox
              dataSize={dataSize}
              limit={limit}
              page={page}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <ScrollWrapper bottom={isShowFilter ? 443 : 319}>
                {loading ? (
                  <Loading />
                ) : (
                  <List
                    key={String(limit) + String(page) + (sort?.key || '')}
                    header={header}
                    items={displayItems}
                    sort={sort}
                    onChangeSort={handleChangeSort}
                    width="100%"
                    rowWidth="100%"
                    rowHeight={58}
                  />
                )}
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </Card>
      </Box>
      <ModalComponent isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
    // feat screen 04-03-01-01 end
  );
}
