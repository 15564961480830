/**
 * Figma ID: 02-02-04
 * 名称: パスワード再設定完了
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from '../component/ContentTitle';

type CompleteSendMailModel = {
  nextPhase: () => void;
};
export default function CompleteSendMail(props: CompleteSendMailModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { nextPhase } = props;
  // feat_screen_02-02_01_start
  return !isMobile ? (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定 送信完了" />
      <Box display="grid" justifyContent="center" pt={21}>
        <Box width={300}>
          <Text variant="body14">お使いのメールアドレスにパスワード再設定用の確認コードが送信されました。</Text>
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            nextPhase();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  ) : (
    <Box px={16}>
      <ContentTitle title="パスワード再設定 送信完了" />
      <Box pt={16}>
        <Box>
          <Text display="block" variant="body14">お使いのメールアドレスにパスワード再設定用の確認コードが送信されました。</Text>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt={24}>
        <MainButton
          customHeight={40}
          fullWidth
          onClick={() => {
            nextPhase();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  );
  // feat_screen_02-02_01_end
}
