/**
 * Figma ID: 08-07-01
 * 名称: 店舗ブロック
 */

import React, {useState} from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { usePagenation, useSort } from 'components/utils';
import { Modal } from 'components/newModal';
import { useForm } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { orderBy } from 'lodash';
import { Loading } from 'components/loading/loading';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import useIsMobile from 'hooks/responsive/useIsMobile';
import MobileFilterButton from 'components/button/mobileFilterButton';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows, ModalZindex } from 'components/const';
import { useAuth } from 'hooks/authProvider';
import { SelectBrandContent, SelectBrandFooter } from './SelectBrandModal';
import { SelectTpmemContent, SelectTpmemFooter } from './SelectTpmemModal';
import { InputReasonContent, InputReasonFooter } from './InputReason';
import { ConfirmContent, ConfirmFooter } from './Confirm';
import { CompleteContent, CompleteFooter } from './Complete';

type AddBlockPhase = 'SelectBrand' | 'SelectTpmem' | 'InputReason' | 'Confirm' | 'Complete';
type DeleteBlockPhase = 'Confirm' | 'Complete';
export type AddBlockInfoModel = {
  brandId?: number;
  tpmemData: { [key: string]: boolean };
  reason?: string;
  comment?: string;
};

export function TpBlock() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // feat feeback 01 toogle show filter start
  const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
  // feat feeback 01 toogle show filter end
  
  const auth = useAuth();
  const { tpmemId } = auth;
  const myId = Number(tpmemId);
  const { openErrorModal } = useErrorModal();
  const { data: tpInfo, error: tpInfoError } = gql.useGetVtMemberBaseByIdQuery({ variables: { id: myId } });
  const {
    data: blockList,
    error: blockListError,
    refetch: refetchBlockList,
  } = gql.useGetVtBlockListByIdQuery({ variables: { id: myId } });
  const deleteValue = 'deleteTpmem';

  const {
    data: brandList,
    loading: brandLoading,
    error: brandError,
  } = gql.useGetMBrandListByBizIdQuery({
    variables: { biz_id: tpInfo?.getVTMemberBaseById.biz_id ?? 1 },
  });

  const [dataSize, setDataSize] = React.useState<number>(0);
  const [isOpenRegisterBlockModal, setIsOpenRegisterBlockModal] = React.useState<boolean>(false);
  const [isOpenDeleteBlockModal, setIsOpenDeleteBlockModal] = React.useState<boolean>(false);
  const [phase, setPhase] = React.useState<AddBlockPhase>('SelectBrand');
  const [deletePhase, setDeletePhase] = React.useState<DeleteBlockPhase>('Confirm');
  const [selectTpmemId, setSelectTpmemId] = React.useState<number>(0);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddBlockInfoModel>({ reValidateMode: 'onChange' });
  const [brandName, setBrandName] = React.useState('');
  const { limit, page, handleChangeLimit, handleChangePage } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const currentBrandId = watch('brandId');
  const currentReason = watch('reason');
  const currentComment = watch('comment');
  const currentTpmemData = watch('tpmemData');
  const [addBlockListTpmem] = gql.useAddTTpmemBlockMutation();
  const [deleteBlockListTpmem] = gql.useDeleteTTpmemBlockByIdBlockIdMutation();
  const toNextPhase = handleSubmit((data) => {
    setPhase('Confirm');
  });

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    // feat screen 08-07-01 start
    { width: !isMobile ? 40 : 24, key: 'threeDotLeader' },
    { width: !isMobile ? 60 : 40, key: 'image' },
    { width: !isMobile ? 156 : 146, key: 'name', columnName: '店舗名' },
    // feat screen 08-07-01 end
    { width: 150, key: 'reg_date', columnName: 'ブロック日' },
    { width: 200, key: 'reason', columnName: '理由' },
  ];

  const displayItems = React.useMemo(() => {
    let items = blockList?.getVTBlockListById;
    if (sort) {
      items = orderBy(items, sort.key, sort.direction);
    }
    const result = items
      ?.map((val, index) => ({
        uniqueKey: index,
        threeDotLeader: (
          <ThreeDotLeader
            menu={[{ label: '解除', value: deleteValue }]}
            onClick={() => {
              setSelectTpmemId(val.block_id ?? 1);
              setIsOpenDeleteBlockModal(true);
            }}
          />
        ),
        // feat screen 08-07-01 start
        image: <img src={val.image ?? ''} alt="tpImg" width={!isMobile ? 60 : 40} height={!isMobile ? "auto" : 40}/>,
        // feat screen 08-07-01 end
        name: (
          <Text variant="caption12" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {val.name}
          </Text>
        ),
        reg_date: (
          <Text variant="caption12" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {val.reg_date}
          </Text>
        ),
        reason: (
          <Text variant="caption12" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {val.reason}
          </Text>
        ),
      }))
      .slice(limit * (page - 1), limit * page);
    setDataSize(items?.length ?? 0);
    return result ?? [];
  }, [  
      blockList?.getVTBlockListById, 
      limit, 
      page, 
      sort, 
      // feat screen 08-07-01 add dept start
      isMobile
      // feat screen 08-07-01 add dept end
  ]);


  const addBlockList = () => {
    const result: string[] = [];
    Object.keys(currentTpmemData).map((val) => currentTpmemData[val] && result.push(val));
    addBlockListTpmem({
      variables: {
        block_ids: result.join(','),
        id: myId,
        reason: currentReason === 'その他' ? currentComment ?? '' : currentReason ?? '',
      },
    })
      .then((ret) => {
        if (ret.data?.addTTpmemBlock.resultRows === ResultRows.UPDATED) {
          setPhase('Complete');
        } else {
          openErrorModal({
            message: '店舗ブロック登録に失敗しました。時間を空けて再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        }
      })
      .catch(() => {
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          zIndex: ModalZindex.ERRMODALZINDEX,
        });
      });
  };

  const nextPhase = () => {
    switch (phase) {
      case 'SelectTpmem':
        setPhase('InputReason');
        break;
      case 'InputReason':
        toNextPhase();
        break;
      case 'Confirm':
        addBlockList();
        break;
      default:
        setPhase('SelectTpmem');
        break;
    }
  };
  const backPhase = () => {
    switch (phase) {
      case 'InputReason':
        setPhase('SelectTpmem');
        break;
      case 'Confirm':
        setPhase('InputReason');
        break;
      default:
        setPhase('SelectBrand');
        break;
    }
  };
  const closeModal = () => {
    setIsOpenRegisterBlockModal(false);
    setPhase('SelectBrand');
    refetchBlockList();
    reset();
  };

  const addBlockModalHeader = () => {
    switch (phase) {
      case 'SelectTpmem':
        return '店舗ブロック登録 店舗選択';
      case 'InputReason':
        return '店舗ブロック登録 理由';
      case 'Confirm':
        return '店舗ブロック登録 確認';
      case 'Complete':
        return '店舗ブロック登録 完了';
      default:
        return '店舗ブロック登録 ブランド選択';
    }
  };
  const addBlockModalContent = () => {
    switch (phase) {
      case 'SelectTpmem':
        return <SelectTpmemContent id={myId} brandId={currentBrandId ?? 1} brandName={brandName} control={control} />;
      case 'InputReason':
        return <InputReasonContent control={control} selectedReason={currentReason} errors={errors} />;
      case 'Confirm':
        return (
          <ConfirmContent selectedBlockTpmemList={currentTpmemData} reason={currentReason} comment={currentComment} />
        );
      case 'Complete':
        return <CompleteContent />;
      default:
        return (
          <SelectBrandContent
            control={control}
            watch={watch}
            brandList={brandList}
            setBrandName={(val: string) => {
              setBrandName(val);
            }}
          />
        );
    }
  };

  const addBlockModalFooter = () => {
    switch (phase) {
      case 'SelectTpmem':
        return (
          <SelectTpmemFooter nextPhase={nextPhase} close={closeModal} backPhase={backPhase} disabledNextPhase={false} />
        );
      case 'InputReason':
        return (
          <InputReasonFooter
            nextPhase={nextPhase}
            close={closeModal}
            backPhase={backPhase}
            disabledNextPhase={currentReason === undefined}
          />
        );
      case 'Confirm':
        return <ConfirmFooter nextPhase={nextPhase} close={closeModal} backPhase={backPhase} />;
      case 'Complete':
        return <CompleteFooter close={closeModal} />;
      default:
        return (
          <SelectBrandFooter
            nextPhase={nextPhase}
            close={closeModal}
            disabledNextPhase={currentBrandId === undefined}
          />
        );
    }
  };
  const modalHeight = () => {
    switch (phase) {
      case 'SelectTpmem':
        // feat screen 08-07-03 start
        return !isMobile ? 600 : "91%";
        // feat screen 08-07-03 end
      case 'InputReason':
        if (currentReason === 'その他') {
          // feat screen 08-07-04 start
          return !isMobile ? 377 : "unset";
        }
        return !isMobile ? 283 : "unset";
        // feat screen 08-07-04 end
      case 'Confirm':
        // feat screen 08-07-06 start
        return !isMobile ? 400 : 470;
        // feat screen 08-07-06 start
      case 'Complete':
        // feat screen 08-07-07 start
        return !isMobile ? 200 : "unset";
        // feat screen 08-07-07 start
      default:
      // feat screen 08-07-02 start
        return !isMobile ? 283 : "unset";
      // feat screen 08-07-02 end
    }
  };
  const modalWidth = () => {
    switch (phase) {
      case 'SelectTpmem':
        return 1100;
      case 'Complete':
        return 350;
      default:
        return 800;
    }
  };

  React.useEffect(() => {
    if (tpInfoError || blockListError || brandError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [tpInfoError, blockListError, brandError, openErrorModal]);

  // feat screen 08-07-01 start
  return !isMobile ? (
    <Box px={24} width="100%">
      {brandLoading && <Loading />}
      <Box display="flex" justifyContent="space-between" gap={8} py={24}>
        <Box display="flex" alignItems="center">
          <Text variant="h2" color="darkBlue">
            店舗ブロック ({dataSize})
          </Text>
          <Box px={5}>
            <Text variant="caption12" color="darkBlue">
              ブロックすると所属バイトラベラーのアプリに選択した店舗の募集情報が表示されません。
            </Text>
          </Box>
        </Box>
        <MainButton
          icon="doNotDisturb"
          iconColor="#fff"
          width={180}
          thin
          onClick={() => setIsOpenRegisterBlockModal(true)}
        >
          新規ブロック登録
        </MainButton>
      </Box>
      <Box>
        <Divider option="horizontal" />
      </Box>

      <Box flex="auto" width="100%">
        <PaginationBox
          dataSize={dataSize}
          limit={limit}
          page={page}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <ScrollWrapper bottom={376}>
            <List
              key="list"
              width="100%"
              rowWidth="100%"
              header={header}
              items={displayItems}
              sort={sort}
              onChangeSort={handleChangeSort}
            />
          </ScrollWrapper>
        </PaginationBox>
      </Box>
      <Modal
        onClose={closeModal}
        overflow={phase === 'SelectTpmem' || phase === 'Confirm' ? undefined : 'visible'}
        isOpen={isOpenRegisterBlockModal}
        header={
          <ModalHeaderContentWrapper>
            <Text variant="h2" color="darkBlue">
              {addBlockModalHeader()}
            </Text>
          </ModalHeaderContentWrapper>
        }
        content={addBlockModalContent()}
        footer={addBlockModalFooter()}
        height={modalHeight()}
        width={modalWidth()}
      />
      <Modal
        onClose={closeModal}
        overflow="visible"
        isOpen={isOpenDeleteBlockModal}
        height={200}
        header={
          <ModalHeaderContentWrapper>
            <Text variant="h2" color="darkBlue">
              {deletePhase === 'Confirm' ? '店舗ブロック解除' : '店舗ブロック解除 完了'}
            </Text>
          </ModalHeaderContentWrapper>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Text variant="body14">
              {deletePhase === 'Confirm' ? '店舗ブロックを解除しますか？' : '店舗ブロック解除が完了しました。'}
            </Text>
          </Box>
        }
        footer={
          deletePhase === 'Confirm' ? (
            <Box display="flex" justifyContent="flex-end" gap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setIsOpenDeleteBlockModal(false)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  deleteBlockListTpmem({ variables: { id: myId, block_id: selectTpmemId } })
                    .then((ret) => {
                      if (ret.data?.deleteTTpmemBlockByIdBlockId.resultRows === ResultRows.UPDATED) {
                        setDeletePhase('Complete');
                      } else {
                        openErrorModal({
                          message: '店舗ブロック解除に失敗しました。時間を空けて再度お試しください。',
                          zIndex: ModalZindex.ERRMODALZINDEX,
                        });
                      }
                    })
                    .catch(() => {
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                        zIndex: ModalZindex.ERRMODALZINDEX,
                      });
                    });
                }}
              >
                解除
              </MainButton>
            </Box>
          ) : (
            <Box display="grid" justifyContent="flex-end" gap={5}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setDeletePhase('Confirm');
                  setIsOpenDeleteBlockModal(false);
                  refetchBlockList();
                }}
              >
                閉じる
              </MainButton>
            </Box>
          )
        }
      />
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%">
      {brandLoading && <Loading />}
      <Box display={isShowFilter ? "block" : "none"}>
        <Box px={16} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" alignItems="center" pt={17} pb={14}>
            <Text variant="h2" color="darkBlue">
              店舗ブロック ({dataSize})
            </Text>
            <Box>
              <Text display="block" align="center" variant="body14" color="darkBlue">
                ブロックすると所属バイトラベラーのアプリに選択した店舗の募集情報が表示されません。
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" mb={16}>
            <MainButton
              icon="doNotDisturb"
              iconColor="#fff"
              width={180}
              onClick={() => setIsOpenRegisterBlockModal(true)}
            >
              新規ブロック登録
            </MainButton>
          </Box>
        </Box>
        <Box px={16} pb={32}>
          <Divider option="horizontal" />
        </Box>
      </Box>
      <MobileFilterButton state={isShowFilter} setState={setIsShowFilter} />
      <Box flex="auto" width="100%">
        <PaginationBox
          dataSize={dataSize}
          limit={limit}
          page={page}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <ScrollWrapper bottom={isShowFilter ? 465 : 317}>
            <List
              key="list"
              width="100%"
              rowWidth="100%"
              header={header}
              items={displayItems}
              sort={sort}
              onChangeSort={handleChangeSort}
              rowHeight={57}
            />
          </ScrollWrapper>
        </PaginationBox>
      </Box>
      <Modal
        onClose={closeModal}
        overflow={phase === 'SelectTpmem' || phase === 'Confirm' ? undefined : 'visible'}
        isOpen={isOpenRegisterBlockModal}
        header={
          <Text variant="h2" color="darkBlue">
            {addBlockModalHeader()}
          </Text>
        }
        content={addBlockModalContent()}
        footer={addBlockModalFooter()}
        height={modalHeight()}
        width="100%"
      />
      <Modal
        onClose={closeModal}
        overflow="visible"
        isOpen={isOpenDeleteBlockModal}
        height="unset"
        header={
          <Text variant="h2" color="darkBlue">
            {deletePhase === 'Confirm' ? '店舗ブロック解除' : '店舗ブロック解除 完了'}
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Text variant="body14">
              {deletePhase === 'Confirm' ? '店舗ブロックを解除しますか？' : '店舗ブロック解除が完了しました。'}
            </Text>
          </Box>
        }
        footer={
          deletePhase === 'Confirm' ? (
            <Box display="flex" justifyContent="flex-end" gap={8}>
              <MainButton width={96} variant="secondary" onClick={() => setIsOpenDeleteBlockModal(false)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  deleteBlockListTpmem({ variables: { id: myId, block_id: selectTpmemId } })
                    .then((ret) => {
                      if (ret.data?.deleteTTpmemBlockByIdBlockId.resultRows === ResultRows.UPDATED) {
                        setDeletePhase('Complete');
                      } else {
                        openErrorModal({
                          message: '店舗ブロック解除に失敗しました。時間を空けて再度お試しください。',
                          zIndex: ModalZindex.ERRMODALZINDEX,
                        });
                      }
                    })
                    .catch(() => {
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                        zIndex: ModalZindex.ERRMODALZINDEX,
                      });
                    });
                }}
              >
                解除
              </MainButton>
            </Box>
          ) : (
            <Box display="grid" justifyContent="flex-end" gap={5}>
              <MainButton
                width={96}
                variant="secondary"
                onClick={() => {
                  setDeletePhase('Confirm');
                  setIsOpenDeleteBlockModal(false);
                  refetchBlockList();
                }}
              >
                閉じる
              </MainButton>
            </Box>
          )
        }
      />
    </Box>
  // feat screen 08-07-01 end
  );
}
