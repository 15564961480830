/**
 * Figma ID: 02-02-03
 * 名称: パスワード再設定
 */
import React from 'react';
import { passwordPattern, passwordLength } from 'components/const';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { styled } from '@linaria/react';
import { TextLink } from 'components/textLink';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  onResetPassword: (args: { confirmationCode: string; password: string }) => Promise<void>;
  onEmailUpdate: () => void;
  email?: string | null | undefined;
};

// feat screen 02-01-01 fix bug B-Travel 168 start
const StyledInputPassWord = styled.div`
  input {
    padding-right: 40px;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
  }
`;
// feat screen 02-01-01 fix bug B-Travel 168 end

export default function ResetPassword({ onResetPassword, onEmailUpdate, email }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const codeLength = 6;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ inputCode: string; inputPassword: string; confirmPassword: string }>({ reValidateMode: 'onChange' });
  const passwordWatch = watch('inputPassword');
  const regExp = /^(?=.*[A-Z])(?=.*[^$*.\\[\]{}()?&quot;!@#%&/\\,&gt;&lt;&prime;:;|_~`+=])[a-zA-Z0-9.?/-]/;

  const onSubmit = handleSubmit(async (data) => {
    await onResetPassword({ confirmationCode: data.inputCode, password: data.inputPassword });
  });
  // feat_screen_02-02_03_start
  return !isMobile ? (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定" />
      <Divider option="horizontal" length={356} />
      <Box display="grid" justifyContent="center" pt={21}>
        <Box width={300}>
          <Text variant="body14" color="primaryBlue">
            {email} に送信しました
          </Text>
        </Box>
        <Box width={300} pt={8}>
          <Text variant="body14">メールに記載された6桁の確認コードと新しいパスワードを入力してください。</Text>
        </Box>
        <Box width={300} pt={8}>
          <Text color="cautionRed" variant="caption12">
            パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
            &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
          </Text>
        </Box>
      </Box>
      <Box justifyContent="center" display="grid" pt={24}>
        <InputLabel>確認コード</InputLabel>
        <RhfInput
          placeholder="6桁のコードを入力"
          name="inputCode"
          control={control}
          rules={{ validate: (value = '') => value.length === codeLength || '6桁のコードを入力してください' }}
        />
        {errors.inputCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>パスワード</InputLabel>
        <RhfInput
          placeholder="パスワード"
          type="password"
          name="inputPassword"
          control={control}
          rules={{
            validate: (value) => passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
            minLength: { value: passwordLength, message: '8文字以上で入力してください' },
          }}
        />
        {errors.inputPassword?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.inputPassword.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>パスワード再入力</InputLabel>
        <RhfInput
          type="password"
          placeholder="パスワード再入力"
          name="confirmPassword"
          control={control}
          rules={{ validate: (value) => value === passwordWatch || 'パスワードが一致しません。' }}
        />
        {errors.confirmPassword?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.confirmPassword.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            onSubmit();
          }}
        >
          設定
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
      <Box pt={20} display="grid" justifyContent="center">
        <TextLink to="" onClick={() => onEmailUpdate()}>
          メールの送信先が間違っている時
        </TextLink>
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box px={16}>
      <ContentTitle title="パスワード再設定" />
      <Box display='flex' flexDirection='column' mt={16} gap={16}>
        <Box width={300}>
          <Text variant="body14" color="primaryBlue">
            {email} に送信しました
          </Text>
        </Box>
        <Box mb={4}>
          <Text display="block" variant="body14">メールに記載された6桁の確認コードと新しいパスワードを入力してください。</Text>
          <Text display="block" color="cautionRed" variant="body14">
            パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
            &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
          </Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" py={12} gap={2}>
        <InputLabel>確認コード</InputLabel>
        <RhfInput
          fullWidth
          placeholder="6桁のコードを入力"
          name="inputCode"
          control={control}
          rules={{ validate: (value = '') => value.length === codeLength || '6桁のコードを入力してください' }}
        />
        {errors.inputCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" pb={16} gap={2}>
        <InputLabel>パスワード</InputLabel>
        <StyledInputPassWord>
          <RhfInput
            fullWidth
            placeholder="パスワード"
            type="password"
            name="inputPassword"
            control={control}
            rules={{
              validate: (value) => regExp.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
              minLength: { value: passwordLength, message: '8文字以上で入力してください' },
            }}
          />
          {errors.inputPassword?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.inputPassword.message}`}</Text>
          )}
        </StyledInputPassWord>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <InputLabel>パスワード再入力</InputLabel>
        <StyledInputPassWord>
          <RhfInput
            fullWidth
            type="password"
            placeholder="パスワード再入力"
            name="confirmPassword"
            control={control}
            rules={{ validate: (value) => value === passwordWatch || 'パスワードが一致しません。' }}
          />
          {errors.confirmPassword?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.confirmPassword.message}`}</Text>
          )}
        </StyledInputPassWord>
      </Box>
      <Box  display="flex" justifyContent="center" alignItems="center" pt={24} >
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            onSubmit();
          }}
        >
          設定
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
      <Box pt={20} pb={16} display="grid" justifyContent="center">
        <TextLink to="" onClick={() => onEmailUpdate()}>
          メールの送信先が間違っている時
        </TextLink>
      </Box>
    </Box>
  );
  // feat_screen_02-02_04_end
}
