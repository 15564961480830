import { createContext, Dispatch } from 'react';

export type State = {
  offerNumber: number;
};

export type Action = {
  type: 'SET_OFFER_NUMBER';
  content: number;
};

type WeeklyOfferNumberContextType = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const WeeklyOfferNumberContext = createContext<WeeklyOfferNumberContextType | undefined>(undefined);
