import React, { useState } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { VtInvitedGroupInfo } from 'graphql/graphql-mw';
import Divider from 'components/divider/divider';
import { TextLink } from 'components/textLink';
import { JoinStatus } from 'components/const';
import { useNavigate } from 'react-router-dom';
import { MYPAGE_MENU_INDEX } from 'pages/s09/const';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import useIsMobile from 'hooks/responsive/useIsMobile';

enum ModalStep {
  DEFAULT = 0, // デフォルト（グループ招待モーダル）
  CONFIRM_ENTRY = 1, // 加入確認モーダル
  COMPLETE_ENTRY = 2, // 加入完了モーダル
  CONFIRM_REJECTION = 3, // 拒否確認モーダル
  COMPLETE_REJECTION = 4, // 拒否完了モーダル
}

// 事業者用アイテムコンテンツ
function McoItemContents(props: { label?: string; content: string }) {
  const { label, content } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box display="flex" mt={7}>
      <Box width={120}>
        <Text variant="body14" color="darkGray">
          {label}
        </Text>
      </Box>
      <Box>
        <Text variant="body14">{content}</Text>
      </Box>
    </Box>
  ): (
    /* feat_screen_03-01-09_start */
    <Box display="flex" justifyContent='space-between' mt={7}>
      <Box display='flex' justifyContent='flex-start' width={120}>
        <Text variant="body14" color="darkGray">
          {label}
        </Text>
      </Box>
      <Box>
        <Text variant="body14">{content}</Text>
      </Box>
    </Box>
    /* feat_screen_03-01-09_end */
  );
}

// 事業者用コンテンツ
function McoContents(props: { item: VtInvitedGroupInfo }) {
  const { item } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return (
    <Box>
      <Box>
        <Text variant="body14">{item.mco_name}</Text>
      </Box>
      { /* feat_screen_03-01-09_start */}
      <Box mt={!isMobile ? 7 : 0}>
      { /* feat_screen_03-01-09_end */}
        <Text variant="body14">
          {`〒${item?.mco_zip_code || ''} ${item.mco_state || ''}${item.mco_city || ''}${item.mco_address || ''}${
            item.mco_address2 || ''
          }`}
        </Text>
      </Box>
      <McoItemContents label="法人番号" content={item.corporate_no || ''} />
      <McoItemContents label="電話番号" content={item.mco_phone || ''} />
      <McoItemContents label="代表者" content={item.mco_m_name || ''} />
      <McoItemContents content={item.mco_m_kana || ''} />
      <McoItemContents label="担当者" content={item.mco_t_name || ''} />
      <McoItemContents content={item.mco_t_kana || ''} />
      <McoItemContents label="担当者電話番号" content={item.mco_t_phone || ''} />
    </Box>
  );
}

// 加入確認モーダル
function getConfirmEntryModal(
  setModalStep: (step: number) => void,
  updateInvitedGroupInfo: (status: number) => Promise<void>,
  /* feat_screen_03-01-10_start */
  isMobile: boolean
  /* feat_screen_03-01-10_end */
) {
  return !isMobile ? {
    with: 384,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          加入
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Text variant="body14">
        このグループに加入しますか？
        <br />
        この操作は中止できません。
      </Text>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.DEFAULT)}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          onClick={() => {
            (async () => {
              await updateInvitedGroupInfo(JoinStatus.JOIN);
              setModalStep(ModalStep.COMPLETE_ENTRY);
            })();
          }}
        >
          承認
        </MainButton>
      </Box>
    ),
  }: {
    /* feat_screen_03-01-10_start */
    height: 198,
    with: "100%",
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          加入
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Text variant="body14">
        このグループに加入しますか？
        <br />
        この操作は中止できません。
      </Text>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} variant="secondary" onClick={() => setModalStep(ModalStep.DEFAULT)}>
          キャンセル
        </MainButton>
        <MainButton
          width={96}
          onClick={() => {
            (async () => {
              await updateInvitedGroupInfo(JoinStatus.JOIN);
              setModalStep(ModalStep.COMPLETE_ENTRY);
            })();
          }}
        >
          承認
        </MainButton>
      </Box>
    )
    /* feat_screen_03-01-10_end */
  };
}

// 加入完了モーダル
function getCompleteEntryModal(
  item: VtInvitedGroupInfo,
  closeModal: () => void,
  navigate: (path: string, state?: any) => void,
  /* feat_screen_03-01-11_start */
  isMobile: boolean
  /* feat_screen_03-01-11_end */
) {
  return !isMobile ? {
    with: 384,
    height: 298,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          加入 完了
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <>
        <Box>
          <Text variant="body14">
            グループに加入しました。
            <br />
            {item.name}とグループになりました。詳細はマイページのグループ店舗からご確認ください。
          </Text>
        </Box>
        <Box mt={16}>
          <TextLink
            variant="body14"
            onClick={() => {
              navigate('/s09', { state: { menuIndex: MYPAGE_MENU_INDEX.Group } });
            }}
          >
            グループ店舗
          </TextLink>
        </Box>
      </>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton variant="secondary" width={104} onClick={() => closeModal()}>
          閉じる
        </MainButton>
      </Box>
    ),
  } : {
    /* feat_screen_03-01-11_start */
    with: "100%",
    height: 253,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          加入 完了
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <>
        <Box>
          <Text display='block' variant="body14">
            グループに加入しました。
          </Text>
          <Text display='block' variant="body14">
            {item.name}とグループになりました。詳細はマイページのグループ店舗からご確認ください。
          </Text>
        </Box>
        <Box ml={-6} mt={-2}>
          <TextLink
            variant="body14"
            onClick={() => {
              navigate('/s09', { state: { menuIndex: MYPAGE_MENU_INDEX.Group } });
            }}
          >
          グループ店舗
          </TextLink>
        </Box>
      </>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton variant="secondary" width={96} onClick={() => closeModal()}>
          閉じる
        </MainButton>
      </Box>
    ),
    /* feat_screen_03-01-11_end */
  };
}

// 拒否確認モーダル
function getConfirmRejectionModal(
  setModalStep: (step: number) => void,
  updateInvitedGroupInfo: (status: number) => Promise<void>,
  /* feat_screen_03-01-12_start */
  isMobile: boolean
  /* feat_screen_03-01-12_end */
) {
  return !isMobile ? {
    with: 384,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          拒否
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Text variant="body14">
        この店舗のグループ招待を拒否しますか？
        <br />
        この操作は中止できません。
      </Text>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.DEFAULT)}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          onClick={() => {
            (async () => {
              await updateInvitedGroupInfo(JoinStatus.REJECT);
              setModalStep(ModalStep.COMPLETE_REJECTION);
            })();
          }}
        >
          否認
        </MainButton>
      </Box>
    ),
  }: {
    /* feat_screen_03-01-12_start */
    height: 198,
    with: "100%",
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          拒否
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Text variant="body14">
        この店舗のグループ招待を拒否しますか？
        <br />
        この操作は中止できません。
      </Text>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} variant="secondary" onClick={() => setModalStep(ModalStep.DEFAULT)}>
          キャンセル
        </MainButton>
        <MainButton
          width={96}
          onClick={() => {
            (async () => {
              await updateInvitedGroupInfo(JoinStatus.REJECT);
              setModalStep(ModalStep.COMPLETE_REJECTION);
            })();
          }}
        >
          否認
        </MainButton>
      </Box>
    ),
    /* feat_screen_03-01-12_end */
  };
}

// 拒否完了モーダル
function getCompleteRejectionModal(
  closeModal: () => void,
  /* feat_screen_03-01-13_start */
  isMobile: boolean
  /* feat_screen_03-01-13_end */
  ) {
  return !isMobile ? {
    with: 384,
    height: 192,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          拒否 完了
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: <Text variant="body14">グループ招待を拒否しました。</Text>,
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton variant="secondary" width={104} onClick={() => closeModal()}>
          閉じる
        </MainButton>
      </Box>
    ),
  }: {
    /* feat_screen_03-01-13_start */
    with: 384,
    height: 172,
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          拒否 完了
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: <Text variant="body14">グループ招待を拒否しました。</Text>,
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton variant="secondary" width={104} onClick={() => closeModal()}>
          閉じる
        </MainButton>
      </Box>
    ),
    /* feat_screen_03-01-13_end */
  };
}

// グループ招待モーダル
function getGroupInviteModal(
  item: VtInvitedGroupInfo,
  onModalClose: () => void,
  setModalStep: (modalStep: number) => void,
  /* feat_screen_03-01-09_start */
  isMobile: boolean
    /* feat_screen_03-01-09_end */
) {
  const infoItems = [
    { label: '店舗名', content: item.name },
    {
      label: '住所',
      content: `〒${item?.zip_code || ''} ${item.state || ''}${item.city || ''}${item.address || ''}${
        item.address2 || ''
      }`,
    },
    { label: '電話番号', content: item.phone },
    { label: '責任者氏名', content: item.mgr_name },
    { label: '事業者', content: <McoContents item={item} />, align: 'center' },
  ];

  return {
    /* feat_screen_03-01-09_start */
    height: !isMobile ? 687 : 589,
    width: !isMobile ? 1040 : "100%",
    /* feat_screen_03-01-09_end */
    header: (
      <Text variant="h2" color="darkBlue">
        以下の店舗からグループ招待が届きました
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box display="flex" alignItems="center" mt={24} mb={8}>
          <div style={{ flexGrow: 2 }}>
            <Box columnGap={23} display="flex" alignItems="center">
              <img src={item?.image || ''} alt="" width={82} height={60} />
              <Text variant="h2" color="darkBlue">
                {item?.name}
              </Text>
            </Box>
          </div>
          {item?.status === JoinStatus.INVITATION && (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton onClick={() => setModalStep(ModalStep.CONFIRM_REJECTION)} variant="warnSecondary">
                拒否
              </MainButton>
              <MainButton
                icon="adminPanelSettings"
                onClick={() => setModalStep(ModalStep.CONFIRM_ENTRY)}
                variant="primary"
              >
                加入
              </MainButton>
            </Box>
          )}
        </Box>
        <Divider option="horizontal" />
        <Info items={infoItems} />
        <Divider option="horizontal" />
      </Box>
    ): (
      /* feat_screen_03-01-09_start */
      <Box mb={-30}>
        <Box display="flex" flexDirection="column" gap={8} alignItems="center" mb={12}>
          <Box gap={8} display="flex" justifyContent='flex-start' alignItems="center" width="100%">
            <Box width={80}>
              <img src={item?.image || ''} alt="" width={80} height={60} />
            </Box>
            <Box>
              <Text variant="h3" color="darkBlue">
                セブンイレブン つくば国際会議場前
              </Text>
            </Box>
          </Box>
          {item?.status === JoinStatus.INVITATION && (
            <Box width="100%" display="flex" justifyContent="space-between" columnGap={8}>
              <MainButton fullWidth customHeight={32} onClick={() => setModalStep(ModalStep.CONFIRM_REJECTION)} variant="warnSecondary">
                拒否
              </MainButton>
              <MainButton
                fullWidth
                customHeight={32}
                icon="adminPanelSettings"
                onClick={() => setModalStep(ModalStep.CONFIRM_ENTRY)}
                variant="primary"
              >
                加入
              </MainButton>
            </Box>
          )}
        </Box>
        <Divider option="horizontal"/>
        <Info items={infoItems} />
      </Box>
      /* feat_screen_03-01-09_end */
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ) : (
     /* feat_screen_03-01-09_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()} customHeight={32}>
          閉じる
        </MainButton>
      </Box>
    ),
    /* feat_screen_03-01-09_end */
  };
}

// モーダルダイアログタイプ
type ModalPropType = {
  /* feat_screen_03-01-09_start */
  height?: number | string;
  width?: number | string;
  /* feat_screen_03-01-09_end */
  header?: JSX.Element;
  content?: JSX.Element;
  footer?: JSX.Element;
};

/**
 * Figma ID
 * 03-01-09
 * グループ招待モーダル
 */
function GroupInviteModal(props: {
  item: VtInvitedGroupInfo | null;
  isOpen: boolean;
  onModalClose: () => void;
  updateInvitedGroupInfo: (status: number) => Promise<void>;
}) {
  const { isOpen, onModalClose, item, updateInvitedGroupInfo } = props;

  const [modalStep, setModalStep] = useState<number>(ModalStep.DEFAULT);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const closeModal = () => {
    onModalClose();
    setModalStep(ModalStep.DEFAULT);
  };

  let modalContent: ModalPropType = {
    header: <>エラー</>,
    content: <>データなし</>,
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton variant="secondary" width={104} onClick={() => closeModal()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 192,
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isOpen) {
      // エラーが発生した場合に前回のModalStepが残るためクリア
      setModalStep(ModalStep.DEFAULT);
    }
    return () => {
      if (isOpen) {
        // Closeの処理
      }
    };
  }, [isOpen]);

  switch (modalStep) {
    case ModalStep.CONFIRM_ENTRY:
      // 加入確認モーダル
      /* feat_screen_03-01-10_start */
      modalContent = getConfirmEntryModal(setModalStep, updateInvitedGroupInfo, isMobile);
      /* feat_screen_03-01-10_end */
      break;
    case ModalStep.COMPLETE_ENTRY:
      // 加入完了モーダル
      /* feat_screen_03-01-11_start */
      modalContent = item ? getCompleteEntryModal(item, closeModal, navigate, isMobile) : modalContent;
      /* feat_screen_03-01-11_end */
      break;
    case ModalStep.CONFIRM_REJECTION:
      // 拒否確認モーダル
      /* feat_screen_03-01-12_start */
      modalContent = getConfirmRejectionModal(setModalStep, updateInvitedGroupInfo, isMobile);
      /* feat_screen_03-01-12_end */
      break;
    case ModalStep.COMPLETE_REJECTION:
      // 拒否完了モーダル
      /* feat_screen_03-01-13_start */
      modalContent = getCompleteRejectionModal(closeModal, isMobile);
      /* feat_screen_03-01-13_end */
      break;
    default:
      // グループ招待モーダル
      /* feat_screen_03-01-09_start */
      modalContent = item ? getGroupInviteModal(item, onModalClose, setModalStep, isMobile) : modalContent;
      /* feat_screen_03-01-09_end */
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => closeModal()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={modalContent.height}
      width={modalContent.width}
    />
  );
}

export default GroupInviteModal;
