import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';
import { BankFormType } from '../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  form: BankFormType;
  onComplete: () => void;
  toBack: () => void;
}

function ConfirmBankModal(props: Props) {
  const { isOpen, onClose, form, onComplete, toBack } = props;
  /* feat_screen_09-02-01_start */
  const isMobile = useIsMobile();
  /* feat_screen_09-02-01_end */
  function onModalClose() {
    onClose();
  }

  const header = (
    <Text variant="h2" color="darkBlue">
      銀行口座編集 確認
    </Text>
  );
  /* feat_screen_09-02-01_start */
  const content = !isMobile ? (
    /* feat_screen_09-02-01_end */
    <Box>
      <Box>
        <Box>
          <Text variant="body14">下記内容で登録します。よろしければ完了を押してください。</Text>
        </Box>
        <Box mt={24}>
          <Divider option="horizontal" />
          <Box display="flex" gap={16} flexDirection="row" alignItems="center" height={48}>
            <Box width={160}>
              <Text variant="body14">銀行口座</Text>
            </Box>
            <Box width="calc(100% - 320px)">
              <Text variant="body14">
                {`${form.bank} ${form.branch_name} ${form.account_type} 
            ${form.account_no} ${form.personal_bank}`}
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <Box display="flex" gap={16} flexDirection="row" alignItems="center" height={48}>
            <Box width={160}>
              <Text variant="body14">口座名義</Text>
            </Box>
            <Box width="calc(100% - 320px)">
              <Text variant="body14">{form?.account_name}</Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
        </Box>
      </Box>
    </Box>
  ) : (
    /* feat_screen_09-02-01_start */
    <Box>
      <Box mb={16}>
        <Text variant="body14">下記内容で登録します。よろしければ完了を押してください。</Text>
      </Box>
      <Box>
        <Divider option="horizontal" />
        <Box display="flex" gap={8} flexDirection="column" alignItems="center" mt={12} mb={16}>
          <Box width="100%">
            <Text variant="body14">銀行口座</Text>
          </Box>
          <Box width="100%">
            <Text variant="body14">
              {`${form.bank} ${form.branch_name} ${form.account_type} 
              ${form.account_no} ${form.personal_bank}`}
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" gap={5} flexDirection="column" alignItems="center" mt={10}>
          <Box width="100%">
            <Text variant="body14">口座名義</Text>
          </Box>
          <Box width="100%">
            <Text variant="body14">{form?.account_name}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
    /* feat_screen_09-02-01_end */
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        /* feat_screen_09-02-01_start */
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        /* feat_screen_09-02-01_end */
        variant="secondary"
        onClick={() => onClose()}
      >
        キャンセル
      </MainButton>
      <MainButton
        /* feat_screen_09-02-01_start */
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        /* feat_screen_09-02-01_end */
        variant="secondary"
        onClick={() => toBack()}
      >
        戻る
      </MainButton>
      <MainButton
        /* feat_screen_09-02-01_start */
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        /* feat_screen_09-02-01_end */
        variant="primary"
        onClick={() => onComplete()}
      >
        完了
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      height="auto"
      /* feat_screen_09-02-01_start */
      width={!isMobile ? 800 : '100%'}
      /* feat_screen_09-02-01_end */
    />
  );
}

export default ConfirmBankModal;
