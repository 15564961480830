import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-04-07
 * グループ脱退モーダル
 */

function LeaveModal(props: { isOpen: boolean; onModalClose: () => void; onClickSubmit: () => void }) {
  const { isOpen, onModalClose, onClickSubmit } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        グループ脱退
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Text variant="body14" color="black">
          現在のグループから脱退を行いますか？
          <br />
          この操作は取り消せません。
        </Text>
      </Box>
    ) : (
      // feat_screen_09-04-07_start
      <Box>
        <Text display="block" variant="body14" color="black">
          現在のグループから脱退を行いますか？
          <br />
          この操作は取り消せません。
        </Text>
      </Box>
      // feat_screen_09-04-07_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_09-04-07_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-07_end
          variant="secondary" 
          onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton 
          // feat_screen_09-04-07_start          
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-04-07_end    
          onClick={() => onClickSubmit()}>
          脱退
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      // feat_screen_09-04-07_start   
      width={!isMobile ? 384 : "100%"}
      // feat_screen_09-04-07_end
    />
  );
}

export default LeaveModal;
