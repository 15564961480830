export enum RecruitType {
  HIRED = 0,
  BELONGED = 1,
  SUB = 2,
  GROUP = 3,
}

export enum RecruitTypeLabel {
  HIRED = '採用',
  BELONGED = '所属',
  SUB = 'サブ',
  GROUP = 'グループ',
}

export enum Favorite {
  YES = 1,
  NO = 0,
}

export enum BelongTo {
  YES = 1,
  NO = 0,
}

export enum Sub {
  YES = 1,
  NO = 0,
}

export enum Identification {
  NOT_SUBMITTED = 0, // 未提出
  IN_PROGRESS = 1, // 確認中
  APPROVED = 2, // 承認済
}

export enum CorrectionStatus {
  REQUEST_SUBMITTED = 0, // 修正依頼済,
  APPROVED = 1, // 承認済,
  REJECTED = 2, // 否認済,
}

export enum TabIndex {
  ALL_LIST = 0, // 一覧
  STAND_BY = 1, // スタンバイ
  IN_PROGRESS = 2, // バイト中
  WAITING_COMPLETE = 3, // 完了待ち
  CHECK_IN_OUT = 4, // チェックイン/アウト
  REQUEST_CORRECTION = 5, // 時間修正
  COMPLETE = 6, // 完了
  CANCEL = 7, // キャンセル
  NO_SHOW = 8, // バックレ
}

export enum MenuIndex {
  REVIEW = 3, // レビュー
}
