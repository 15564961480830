/* eslint-disable no-nested-ternary */

import React from 'react';
import Select, {
  Props as ReactSelectProps,
  StylesConfig,
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';
import { Box } from 'components/box';
import CreatableSelect from 'react-select/creatable';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Icon } from '../icon';

export type Props = ReactSelectProps & {
  width?: string | number;
  creatable?: boolean;
};

function DropdownIndicator(props: DropdownIndicatorProps) {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      <Icon name={menuIsOpen ? 'chevronLess' : 'chevronMore'} color="#fff" />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props: ClearIndicatorProps) {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="cancel" />
    </components.ClearIndicator>
  );
}

const customStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: '34px',
    height: '34px',
    background: '#FFFFFF',
    border: '1px solid #DBDBDB',
    boxShadow: 'none',
    borderRadius: '2px 0px 0px 2px',
    ':hover': {
      ...styles[':active'],
      borderColor: '#DBDBDB',
    },
    // feat screen 01-01-06 start
    '@media only screen and (max-width: 768px)': {
      minHeight: '40px',
      height: '40px'
    },
    // feat screen 01-01-06 end
  }),

  menu: (styles) => ({
    ...styles,
    margin: 0,
    background: '#FFFFFF',
    border: '1px solid #008599',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    // feat screen 01-01-06 start
    maxHeight: '236px',
    // feat screen 01-01-06 start
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    padding: '8px',
    height: '34px',
    fontFamily: 'Meiryo',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: isSelected ? '#FFFFFF' : '#16495F',
    backgroundColor: isSelected ? '#00A5BF' : 'inherit',
    ':hover': {
      ...styles[':active'],
      color: '#16495F',
      backgroundColor: '#DBDBDB',
    },
    // feat screen 01-01-06 start
    '@media only screen and (max-width: 768px)': {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    // feat screen 01-01-06 end
  }),
  input: (styles) => ({ ...styles, padding: 0, margin: 0 }),
  placeholder: (styles) => ({
    ...styles,
    margin: 0,
    height: '21px',
    fontFamily: 'Meiryo',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#DBDBDB',
  }),
  singleValue: (styles) => ({ ...styles }),
  dropdownIndicator: (styles) => ({
    ...styles,
    background: '#008599',
    borderRadius: '0px 2px 2px 0px',
    padding: '4px',
    // feat screen 01-01-06 start
    '@media only screen and (max-width: 768px)': {
      padding: '8px',
      transform: 'translateY(-1px)',
      pointerEvents: 'none',
    },
    // feat screen 01-01-06 end
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '4px',
    marginRight: '8px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '4px 8px',
    fontFamily: 'Meiryo',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#DBDBDB',
  }),
};

export function ComboBox({ width, creatable = false, ...rest }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  if (creatable) {
    return (
      <Box width={width}>
        <CreatableSelect
          components={{ DropdownIndicator, ClearIndicator }}
          isClearable
          styles={customStyles}
          formatCreateLabel={(inputValue) => `"${inputValue}" を追加する`}
          {...rest}
        />
      </Box>
    );
  }
  return (
    // feat screen 01-01-06 start
    <div>
      {!isMobile ? (
        <Box width={width}>
          <Select components={{ DropdownIndicator, ClearIndicator }} isClearable styles={customStyles} {...rest} />
        </Box>
      ) : (
        <Box width={width} height={40}>
          <Select components={{ DropdownIndicator, ClearIndicator }} isClearable styles={customStyles} {...rest} />
        </Box>
      )}
    </div>
    // feat screen 01-01-06 end
  );
}
