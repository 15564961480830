import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-05-02
 * 退会確認モーダル
 */

function ConfirmationModal(props: { isOpen: boolean; onModalClose: () => void; onClickNext: () => void }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, onModalClose, onClickNext } = props;

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        退会手続き
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          本当に退会手続きを行いますか？
          <br />
          この操作は取り消せません。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {/* feat screen 09-05-02 start */}
        <MainButton width={!isMobile ? 104 : 96} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton width={!isMobile ? 104 : 96} thin onClick={() => onClickNext()}>
          確認
        </MainButton>
        {/* feat screen 09-05-02 end */}
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default ConfirmationModal;
