import { format } from 'date-fns';
import { NoticeOfEmploymentPdfProps } from 'pdfComponent';
import { FormData } from 'pages/s04-01-01/common/formState';

// PDFプレビュー用プロパティ日時フォーマット 
const formatPdfPropDateTime = (shiftDate?: string, hour?: string, minute?: string) => {
  if (!shiftDate || !hour || !minute) return '';
  return `${format(new Date(shiftDate || 0), 'yyyy年MM月dd日')} ${hour?.padStart(2, '0') || ''}:${minute?.padStart(2, '0') || ''}`;
};

// PDFプレビュー用プロパティ取得
export function getPdfPreviewProps(fixedFormData: FormData) {  

  // PDFプロパティ設定
  const pdfProps: NoticeOfEmploymentPdfProps = {
    employmentDate: fixedFormData.shift_date && format(new Date(fixedFormData.shift_date || 0), 'yyyy年MM月dd日'),
    description: fixedFormData?.description,
    beginTime: formatPdfPropDateTime(fixedFormData.shift_date, fixedFormData.daily_start_hour, fixedFormData.daily_start_minute),
    endTime: formatPdfPropDateTime(fixedFormData.shift_date, fixedFormData.daily_end_hour, fixedFormData.daily_end_minute),
    breakTime: fixedFormData.break_time ? Number(fixedFormData.break_time) : undefined,
    hourWage: fixedFormData?.hour_wage_display,
    transFee: fixedFormData?.trans_fee
  };

  // 設定したPDFのプロパティを返す
  return pdfProps;
};
