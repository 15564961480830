import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { UseFormReturn } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { GetVtMemberBaseByIdQuery } from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import Divider from 'components/divider/divider';
import { BusinessFormType } from '../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onNext: (data: BusinessFormType) => void;
  form: UseFormReturn<BusinessFormType>;
  data?: GetVtMemberBaseByIdQuery['getVTMemberBaseById'] | null;
}

function EditBusinessInfoModal(props: Props) {
  const { isOpen, onClose, onBack, onNext, form, data } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  function onClickNext() {
    handleSubmit((d) => {
      onNext(d);
    })();
  }

  const header = (
    <Text variant="h2" color="darkBlue">
      基本情報編集 事業者情報
    </Text>
  );

  const content = !isMobile ? (
    <Box>
      <Box>
        <Text variant="body14">事業者情報を入力して次へを押してください。</Text>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            事業者名
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.mco_name}</Text>
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            郵便番号
          </Text>
        </Box>
        <Box>
          <Text variant="body14">{data?.zip_code}</Text>
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              住所
            </Text>
          </Box>
          <Box>
            <Text variant="body14">{`${data?.state ?? ''} ${data?.city ?? ''} ${data?.address ?? ''} ${
              data?.address2 ?? ''
            }`}</Text>
          </Box>
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              電話番号
            </Text>
          </Box>
          <Box>
            <Text variant="body14">{data?.phone}</Text>
          </Box>
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              法人番号
            </Text>
          </Box>
          <Box>
            <Text variant="body14">{data?.corporate_no}</Text>
          </Box>
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              代表者氏名
            </Text>
          </Box>
          <Box display="flex" gap={16}>
            <Box>
              <RhfInput
                type="text"
                name="representativeFamilyName"
                defaultValue={`${data?.mco_m_last_name ?? ''}`}
                control={control}
                width={120}
                rules={{ required: '代表者氏名（名字）を入力してください' }}
              />
            </Box>
            <Box>
              <RhfInput
                type="text"
                name="representativeFirstName"
                defaultValue={`${data?.mco_m_first_name ?? ''}`}
                control={control}
                width={120}
                rules={{ required: '代表者氏名（名前）を入力してください' }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          {errors.representativeFamilyName && (
            <Text variant="caption11" color="cautionRed">
              {errors.representativeFamilyName.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.representativeFirstName && (
            <Text variant="caption11" color="cautionRed">
              {errors.representativeFirstName.message}
            </Text>
          )}
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              代表者氏名（フリガナ）
            </Text>
          </Box>
          <Box display="flex" gap={16}>
            <Box>
              <RhfInput
                type="text"
                name="representativeFamilyNameKana"
                defaultValue={data?.mco_m_last_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '代表者氏名（フリガナ）（名字）を入力してください' }}
              />
            </Box>
            <Box>
              <RhfInput
                type="text"
                name="representativeFirstNameKana"
                defaultValue={data?.mco_m_first_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '代表者氏名（フリガナ）（名前）を入力してください' }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          {errors.representativeFamilyNameKana && (
            <Text variant="caption11" color="cautionRed">
              {errors.representativeFamilyNameKana.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.representativeFirstNameKana && (
            <Text variant="caption11" color="cautionRed">
              {errors.representativeFirstNameKana.message}
            </Text>
          )}
        </Box>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="h3" color="blueGray">
            担当者氏名
          </Text>
        </Box>
        <Box display="flex" gap={16}>
          <Box>
            <RhfInput
              type="text"
              name="businessManagerFamilyName"
              defaultValue={data?.mco_t_last_name ?? ''}
              control={control}
              width={120}
              rules={{ required: '担当者氏名（名字）を入力してください' }}
            />
          </Box>
          <Box>
            <RhfInput
              type="text"
              name="businessManagerFirstName"
              defaultValue={data?.mco_t_first_name ?? ''}
              control={control}
              width={120}
              rules={{ required: '担当者氏名（名前）を入力してください' }}
            />
          </Box>
        </Box>
        <Box>
          {errors.businessManagerFamilyName && (
            <Text variant="caption11" color="cautionRed">
              {errors.businessManagerFamilyName.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.businessManagerFirstName && (
            <Text variant="caption11" color="cautionRed">
              {errors.businessManagerFirstName.message}
            </Text>
          )}
        </Box>
        <Box mt={20}>
          <Box>
            <Text variant="h3" color="blueGray">
              担当者氏名（フリガナ）
            </Text>
          </Box>
          <Box display="flex" gap={16}>
            <Box>
              <RhfInput
                type="text"
                name="businessManagerFamilyNameKana"
                defaultValue={data?.mco_t_last_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '担当者氏名（フリガナ）（名字）を入力してください' }}
              />
            </Box>
            <Box>
              <RhfInput
                type="text"
                name="businessManagerFirstNameKana"
                defaultValue={data?.mco_t_first_kana ?? ''}
                control={control}
                width={120}
                rules={{ required: '担当者氏名（フリガナ）（名前）を入力してください' }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          {errors.businessManagerFamilyNameKana && (
            <Text variant="caption11" color="cautionRed">
              {errors.businessManagerFamilyNameKana.message}
            </Text>
          )}
        </Box>
        <Box>
          {errors.businessManagerFirstNameKana && (
            <Text variant="caption11" color="cautionRed">
              {errors.businessManagerFirstNameKana.message}
            </Text>
          )}
        </Box>
        <Box mt={20}>
          <Box display="flex" alignItems="center">
            <Box width={300}>
              <Text variant="h3" color="blueGray">
                担当者電話番号
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${GENERIC_COLOR.OK_BLUE}`}
              borderRadius={2}
              height={16}
              width={28}
            >
              <Text variant="caption11" color="okBlue">
                任意
              </Text>
            </Box>
          </Box>
          <Box>
            <RhfInput
              type="text"
              name="businessManagerPhone"
              defaultValue={data?.mco_t_phone ?? ''}
              control={control}
              width={328}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    // feat_screen_09-01-03_start
    <Box display="flex" flexDirection="column" gap={15}>
      <Box>
        <Text variant="body14">事業者情報を入力して次へを押してください。</Text>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Text variant="h3" color="blueGray">
            事業者名
          </Text>
        </Box>
        <Box display="flex" >
          <Text align="right" variant="body14">{data?.mco_name}</Text>
        </Box>
      </Box>
      
      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box >
            <Text variant="h3" color="blueGray">
              郵便番号
            </Text>
          </Box>
          <Box display="flex">
            <Text align="right" variant="body14">{data?.zip_code}</Text>
          </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Text variant="h3" color="blueGray">
            住所
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{`${data?.state ?? ''} ${data?.city ?? ''} ${data?.address ?? ''} ${
            data?.address2 ?? ''
          }`}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{data?.phone}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Text variant="h3" color="blueGray">
            法人番号
          </Text>
        </Box>
        <Box display="flex">
          <Text align="right" variant="body14">{data?.corporate_no}</Text>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Text variant="h3" color="blueGray">
            代表者氏名
          </Text>
        </Box>
        <Box display="flex" gap={15}>
            <div style={{width: "100%"}}>
              <Box display="flex" flexDirection="column">
                <RhfInput
                  type="text"
                  name="representativeFamilyName"
                  defaultValue={`${data?.mco_m_last_name ?? ''}`}
                  control={control}
                  fullWidth
                  rules={{ required: '代表者氏名（名字）を入力してください' }}
                />
                {errors.representativeFamilyName && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.representativeFamilyName.message}
                  </Text>
                )}
              </Box>
            </div>
            <div style={{width: "100%"}}>
              <Box display="flex" flexDirection="column">
                <RhfInput
                  type="text"
                  name="representativeFirstName"
                  defaultValue={`${data?.mco_m_first_name ?? ''}`}
                  control={control}
                  fullWidth
                  rules={{ required: '代表者氏名（名前）を入力してください' }}
                />
                {errors.representativeFirstName && (
                  <Text variant="caption11" color="cautionRed">
                    {errors.representativeFirstName.message}
                  </Text>
                )}
              </Box>
            </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Text variant="h3" color="blueGray">
            代表者氏名（フリガナ）
          </Text>
        </Box>
        <Box display="flex" gap={16}>
          <div style={{width: "100%"}}>
              <RhfInput
                type="text"
                name="representativeFamilyNameKana"
                defaultValue={data?.mco_m_last_kana ?? ''}
                control={control}
                fullWidth
                rules={{ required: '代表者氏名（フリガナ）（名字）を入力してください' }}
              />
              {errors.representativeFamilyNameKana && (
                <Text variant="caption11" color="cautionRed">
                  {errors.representativeFamilyNameKana.message}
                </Text>
              )}
          </div>
          <div style={{width: "100%"}}>
            <RhfInput
              type="text"
              name="representativeFirstNameKana"
              defaultValue={data?.mco_m_first_kana ?? ''}
              control={control}
              fullWidth
              rules={{ required: '代表者氏名（フリガナ）（名前）を入力してください' }}
            />
            {errors.representativeFirstNameKana && (
              <Text variant="caption11" color="cautionRed">
                {errors.representativeFirstNameKana.message}
              </Text>
            )}
          </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}> 
        <Box>
          <Text variant="h3" color="blueGray">
            担当者氏名
          </Text>
        </Box>
        <Box display="flex" gap={15}>
          <div style={{width: "100%"}}>
            <RhfInput
              type="text"
              name="businessManagerFamilyName"
              defaultValue={data?.mco_t_last_name ?? ''}
              control={control}
              fullWidth
              rules={{ required: '担当者氏名（名字）を入力してください' }}
            />
            {errors.businessManagerFamilyName && (
              <Text variant="caption11" color="cautionRed">
                {errors.businessManagerFamilyName.message}
              </Text>
            )}
          </div>
          <div style={{width: "100%"}}>
            <RhfInput
              type="text"
              name="businessManagerFirstName"
              defaultValue={data?.mco_t_first_name ?? ''}
              control={control}
              fullWidth
              rules={{ required: '担当者氏名（名前）を入力してください' }}
            />
            {errors.businessManagerFirstName && (
            <Text variant="caption11" color="cautionRed">
              {errors.businessManagerFirstName.message}
            </Text>
            )}

          </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Text variant="h3" color="blueGray">
            担当者氏名（フリガナ）
          </Text>
        </Box>
        <Box display="flex" gap={16}>
          <div style={{width: "100%"}}>
            <RhfInput
              type="text"
              name="businessManagerFamilyNameKana"
              defaultValue={data?.mco_t_last_kana ?? ''}
              control={control}
              fullWidth
              rules={{ required: '担当者氏名（フリガナ）（名字）を入力してください' }}
            />
            {errors.businessManagerFamilyNameKana && (
              <Text variant="caption11" color="cautionRed">
                {errors.businessManagerFamilyNameKana.message}
              </Text>
            )}
          </div>
          <div style={{width: "100%"}}>
            <RhfInput
              type="text"
              name="businessManagerFirstNameKana"
              defaultValue={data?.mco_t_first_kana ?? ''}
              control={control}
              fullWidth
              rules={{ required: '担当者氏名（フリガナ）（名前）を入力してください' }}
            />
            {errors.businessManagerFirstNameKana && (
              <Text variant="caption11" color="cautionRed">
                {errors.businessManagerFirstNameKana.message}
              </Text>
            )}
          </div>
        </Box>
      </Box>

      <Divider option="horizontal"/>

      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={160}>
            <Text variant="h3" color="blueGray">
              担当者電話番号
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            border={`1px solid ${GENERIC_COLOR.OK_BLUE}`}
            borderRadius={2}
            height={16}
            width={28}
          >
            <Text variant="caption11" color="okBlue">
              任意
            </Text>
          </Box>
        </Box>
        <Box>
          <RhfInput
            type="text"
            name="businessManagerPhone"
            defaultValue={data?.mco_t_phone ?? ''}
            control={control}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
    // feat_screen_09-01-03_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-01-03_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-03_end
        variant="secondary" 
        onClick={() => onClose()}
      >
        キャンセル
      </MainButton>
      <MainButton
        // feat_screen_09-01-03_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile} 
        // feat_screen_09-01-03_end
        variant="secondary" 
        onClick={() => onBack()}
      >
        戻る
      </MainButton>
      <MainButton
        // feat_screen_09-01-03_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-03_end
        variant="primary" 
        onClick={() => onClickNext()}
      >
        次へ
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}
      content={content}
      footer={footer}
      // feat_screen_09-01-03_start
      height={!isMobile ? "90vh" : 589}
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-01-03_end
    />
  );
}

export default EditBusinessInfoModal;
