import React, { useMemo } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { ScrollableArea } from 'components/scrollableArea';
import { Info } from 'components/info';
import { MainButton } from 'components/mainButton';
import { AgreementStatus } from 'components/const';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import {
  useUpdateTWorkingUseWalletByIdMutation,
  useGetTWalletSumByIdQuery,
  useGetVtTicketListSumByTpmemIdQuery,
  useUpdateTWorkingUseTicketByIdMutation,
  useGetSTpmemTicketPriceQuery,
} from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useAuth } from 'hooks/authProvider';
import { useNavigate } from 'react-router-dom';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { CostDetailsModel } from './types';
import UseWalletModal from './modal/UseWalletModal';
import CancelWalletModal from './modal/CancelWalletModal';
import CancelTicketModal from './modal/CancelTicketModal';
import UseTicketModal from './modal/UseTicketModal';

/**
 * Figma ID: 04-02-01-05,04-02-01-07
 * 名称: 費用明細詳細
 */

function CostDetails(props: { data?: CostDetailsModel; refetch: () => void }) {
  const { data, refetch } = props;
  const defaultVal = '0';

  const enum ModalType {
    None = 'none',
    UseWallet = 'useWallet',
    CancelWallet = 'cancelWallet',
    UseTicket = 'useTicket',
    CancelTicket = 'cancelTicket',
  }

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.None);

  const [updateWalletUsage, { loading: updateWalletUsageLoading, error: updateWalletUsageError }] =
    useUpdateTWorkingUseWalletByIdMutation();

  const { tpmemId: loginTpMemId } = useAuth();
  const navigate = useNavigate();

  // ログインしていなければ、ログイン画面に遷移
  if (loginTpMemId === null) {
    navigate('/signin');
  }

  const {
    data: walletSumData,
    loading: walletSumLoading,
    refetch: refetchWalletSum,
    error: walletSumError,
  } = useGetTWalletSumByIdQuery({
    variables: { id: loginTpMemId || 0 },
    fetchPolicy: 'no-cache',
  });

  const {
    data: maxTicketAmountData,
    loading: loadingMaxTicketAmountData,
    refetch: refetchMaxTicketAmountData,
    error: errorMaxTicketAmountData,
  } = useGetVtTicketListSumByTpmemIdQuery({
    variables: {
      tpmem_id: loginTpMemId || 0,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: ticketPriceData,
    loading: ticketPriceLoading,
    error: ticketPriceError,
  } = useGetSTpmemTicketPriceQuery({
    fetchPolicy: 'no-cache',
  });

  const [updateTicketUsage, { loading: loadingUpdateTicketUsage, error: errorUpdateTicketUsage }] =
    useUpdateTWorkingUseTicketByIdMutation();

  const buttonsActiveStatus: { useWallet: boolean; cancelWallet: boolean; useTicket: boolean; cancelTicket: boolean } =
    useMemo(() => {
      if (!data) {
        // データが無ければすべて非活性
        return { useWallet: false, cancelWallet: false, useTicket: false, cancelTicket: false };
      }

      const { status, usedWalletAmount, usedTicketAmount, totalHireFee } = data;

      // ステータスがないまたは「完了」以降、もしくは育成料が0ならすべて非活性
      if (status === undefined || status >= AgreementStatus.COMPLETE || Number(totalHireFee.replace(/,/g, '')) === 0) {
        return { useWallet: false, cancelWallet: false, useTicket: false, cancelTicket: false };
      }

      // ウォレット使用額はマイナスで渡ってくるので、マイナスの場合は「ウォレット解除」のみ活性
      if (usedWalletAmount < 0) {
        return { useWallet: false, cancelWallet: true, useTicket: false, cancelTicket: false };
      }

      // チケットを使用していたら「チケット解除」のみ活性
      if (usedTicketAmount > 0) {
        return { useWallet: false, cancelWallet: false, useTicket: false, cancelTicket: true };
      }

      // いずれにも当てはまらなければ「ウォレット使用」「チケット使用」のみ活性
      return { useWallet: true, cancelWallet: false, useTicket: true, cancelTicket: false };
    }, [data]);

  function refetchAll() {
    refetch();
    refetchMaxTicketAmountData();
    refetchWalletSum();
  }

  const numberFormatter = new Intl.NumberFormat('ja-JP');

  const { openErrorModal } = useErrorModal();

  // エラー処理 ウォレットの使用更新
  React.useEffect(() => {
    if (updateWalletUsageError) {
      setActiveModalType(ModalType.None);
      openErrorModal({
        message: 'ウォレットの更新ができませんでした。',
      });
    }
  }, [updateWalletUsageError, openErrorModal, setActiveModalType, ModalType.None]);
  // エラー処理 ウォレット情報の金額合計
  React.useEffect(() => {
    if (walletSumError) {
      openErrorModal({
        message: 'ウォレット情報の合計が取得できませんでした。',
      });
    }
  }, [walletSumError, openErrorModal]);
  // エラー処理 チケットの集計
  React.useEffect(() => {
    if (errorMaxTicketAmountData) {
      openErrorModal({
        message: 'チケットの集計が取得できませんでした。',
      });
    }
  }, [errorMaxTicketAmountData, openErrorModal]);
  // エラー処理 チケットの使用更新
  React.useEffect(() => {
    if (errorUpdateTicketUsage) {
      setActiveModalType(ModalType.None);
      openErrorModal({
        message: 'チケットの更新ができませんでした。',
      });
    }
  }, [errorUpdateTicketUsage, openErrorModal, setActiveModalType, ModalType.None]);
  // エラー処理 チケットの金額
  React.useEffect(() => {
    if (ticketPriceError) {
      openErrorModal({
        message: 'チケットの金額が取得できませんでした。',
      });
    }
  }, [ticketPriceError, openErrorModal]);

  const loading =
    updateWalletUsageLoading ||
    walletSumLoading ||
    loadingMaxTicketAmountData ||
    loadingUpdateTicketUsage ||
    ticketPriceLoading;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <>
      {loading && <Loading />}
      <Box px={16} pt={0} pb={24} width="100%">
        <Box display="flex" alignItems="center" gap={8} py={16}>
          <Text variant="h2" color="darkBlue">
            費用明細詳細
          </Text>
          <Text variant="caption12" color="darkBlue">
            手数料支払にウォレットもしくはチケットが利用可能です（併用不可）
          </Text>
        </Box>
        <Divider option="horizontal" />
        <ScrollableArea>
          <Info
            items={[
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.totalHireFee || defaultVal}`}</Text>
                  </Box>
                ),
                label: '採用手数料',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.transFee || defaultVal}`}</Text>
                  </Box>
                ),
                label: '交通費',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.basicSalary || defaultVal}（時給${
                      data?.hourWage || defaultVal
                    } × ${data?.workTime || defaultVal}）`}</Text>
                  </Box>
                ),
                label: '基本給',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.latenightPay || defaultVal}（深夜割増時給¥${
                      data?.baseWage || defaultVal
                    } × ${data?.latenightTime || defaultVal}）`}</Text>
                  </Box>
                ),
                label: '深夜手当',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.overtimePay || defaultVal}（残業割増時給¥${
                      data?.baseWage || defaultVal
                    } × ${data?.overtimeTime || defaultVal}）`}</Text>
                  </Box>
                ),
                label: '残業手当',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.tax || defaultVal}（採用手数料）`}</Text>
                  </Box>
                ),
                label: '各消費税（目安）',
              },
              {
                content: (
                  <Box height={28}>
                    <Text variant="body14">{`¥${data?.withholdingTax || defaultVal}`}</Text>
                  </Box>
                ),
                label: '源泉徴収',
              },
              {
                content: (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text variant="body14">
                      {typeof data?.walletFee === 'number' && data.walletFee < 0 && '-'}¥
                      {String(numberFormatter.format(data?.walletFee || 0)).replace('-', '')}
                    </Text>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <MainButton
                        thin
                        variant="secondary"
                        icon="currencyYen"
                        width={165}
                        onClick={() => setActiveModalType(ModalType.UseWallet)}
                        disabled={!buttonsActiveStatus.useWallet}
                      >
                        ウォレットを使用
                      </MainButton>
                      <div>
                        <MainButton
                          thin
                          variant="warnSecondary"
                          onClick={() => setActiveModalType(ModalType.CancelWallet)}
                          disabled={!buttonsActiveStatus.cancelWallet}
                        >
                          解除
                        </MainButton>
                      </div>
                    </div>
                  </div>
                ),
                label: 'ウォレット利用',
              },
              {
                content: (
                  <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    <Text variant="body14">
                      {typeof data?.usedTicketAmount === 'number' && data.usedTicketAmount > 0 && '-'}¥
                      {String(numberFormatter.format(Number(data?.ticketFee.replace(/,/g, '') || 0))).replace(
                        '-',
                        ''
                      ) || defaultVal}
                    </Text>
                    <Box display="flex" alignItems="center" gap={8}>
                      <MainButton
                        thin
                        variant="secondary"
                        icon="confirmationNumber"
                        width={165}
                        onClick={() => setActiveModalType(ModalType.UseTicket)}
                        disabled={!buttonsActiveStatus.useTicket}
                      >
                        チケットを使用
                      </MainButton>
                      <MainButton
                        thin
                        variant="warnSecondary"
                        onClick={() => setActiveModalType(ModalType.CancelTicket)}
                        disabled={!buttonsActiveStatus.cancelTicket}
                      >
                        解除
                      </MainButton>
                    </Box>
                  </Box>
                ),
                label: 'チケット利用',
              },
              {
                content: (
                  <Text variant="body14" bold>
                    ¥{data?.totalAmount || defaultVal}
                  </Text>
                ),
                label: '合計',
                labelBold: true,
              },
            ]}
          />
          <Divider option="horizontal" />
        </ScrollableArea>
      </Box>
      <UseWalletModal
        isOpen={activeModalType === ModalType.UseWallet}
        onModalClose={() => setActiveModalType(ModalType.None)}
        submit={(amount) =>
          updateWalletUsage({
            variables: {
              id: data?.workId || 0,
              use_wallet: amount,
            },
          })
            .then((result) => {
              const resultRows = result.data?.updateTWorkingUseWalletById.resultRows;
              if (typeof resultRows === 'number' && resultRows > 0) {
                return true;
              }
              return false;
            })
            .finally(() => refetchAll())
        }
        amount={walletSumData?.getTWalletSUMById.price || 0}
        // ウォレットの場合は消費税を考慮する
        maxToUse={
          Math.floor(
            (Number(data?.totalHireFee.replace(/,/g, '') || 0) + Number(data?.tax.replace(/,/g, '') || 0)) / 100
          ) * 100
        }
      />
      <CancelWalletModal
        isOpen={activeModalType === ModalType.CancelWallet}
        onModalClose={() => setActiveModalType(ModalType.None)}
        submit={() =>
          updateWalletUsage({
            variables: {
              id: data?.workId || 0,
              use_wallet: 0,
            },
          })
            .then((result) => {
              const resultRows = result.data?.updateTWorkingUseWalletById.resultRows;
              if (typeof resultRows === 'number' && resultRows > 0) {
                return true;
              }
              return false;
            })
            .finally(() => refetchAll())
        }
      />
      <UseTicketModal
        isOpen={activeModalType === ModalType.UseTicket}
        onModalClose={() => setActiveModalType(ModalType.None)}
        amount={maxTicketAmountData?.getVTTicketListSUMByTpmemId.enabled_count || 0}
        onSubmit={(amountToUse) => {
          updateTicketUsage({
            variables: {
              id: data?.workId || 0,
              use_ticket: amountToUse,
            },
          })
            .then((result) => {
              if (result.errors) {
                openErrorModal({ message: 'チケットの使用に失敗しました。もう一度やり直してください。' });
              }
            })
            .finally(() => {
              refetchAll();
            });
        }}
        maxToUse={Number(data?.totalHireFee.replace(/,/g, '') || 0)}
        pricePerTicket={ticketPriceData?.getSTpmemTicketPrice.ticket_price || 1}
      />
      <CancelTicketModal
        isOpen={activeModalType === ModalType.CancelTicket}
        onModalClose={() => setActiveModalType(ModalType.None)}
        onSubmit={() =>
          updateTicketUsage({
            variables: {
              id: data?.workId || 0,
              use_ticket: 0,
            },
          })
            .then((result) => {
              if (!result.errors) {
                return true;
              }
              return false;
            })
            .finally(() => {
              refetchAll();
            })
        }
      />
    </>
  ): (
    // feat_screen_04-02-01-05_start
    <>
      {loading && <Loading />}
      <Box px={12} pt={0} width="100%" height="calc(100% - 52px)" overflow="auto">
        <Box display="flex" flexDirection="column" alignItems="center" pt={14} pb={12}>
          <Text variant="h3" color="darkBlue">
            費用明細詳細
          </Text>
          <Box display="flex" px={32}>
            <Text variant="caption12" color="darkBlue" align="center">
              手数料支払にウォレットもしくはチケットが利用可能です（併用不可）
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Info
          items={[
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text variant="body14">{`¥${data?.totalHireFee || defaultVal}`}</Text>
                  </Box>
                </Box>
              ),
              label: '採用手数料',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text variant="body14">{`¥${data?.transFee || defaultVal}`}</Text>
                  </Box>
                </Box>
              ),
              label: '交通費',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text display="block" align="right" variant="body14">{`¥${data?.basicSalary || defaultVal}（時給${
                      data?.hourWage || defaultVal
                    }`}{`× ${data?.workTime || defaultVal}）`}</Text>
                  </Box>
                </Box>
              ),
              label: '基本給',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text display="block" align="right" variant="body14">{`¥${data?.latenightPay || defaultVal}（深夜割増時給¥${
                      data?.baseWage || defaultVal
                    }`}{`× ${data?.latenightTime || defaultVal}）`}</Text>
                  </Box>
                </Box>
              ),
              label: '深夜手当',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text display="block" align="right" variant="body14">{`¥${data?.overtimePay || defaultVal}（残業割増時給¥${
                      data?.baseWage || defaultVal
                    }`}{`× ${data?.overtimeTime || defaultVal}）`}</Text>
                  </Box>
                </Box>
              ),
              label: '残業手当',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                    <Text display="block" align="right" variant="body14">{`¥${data?.tax || defaultVal}（採用手数料）`}</Text>
                  </Box>
                </Box>
              ),
              label: '各消費税（目安）',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box width={210}>
                    <Text display="block" align="right" variant="body14">{`¥${data?.withholdingTax || defaultVal}`}</Text>
                  </Box>
                </Box>
              ),
              label: '源泉徴収',
            },
            {
              content: (
                <Box
                    width='100%'
                    display='flex'
                    flexDirection= 'column'
                    alignItems= 'flex-end'
                    gap={16}
                >
                  <Text display="block" align="right" variant="body14">
                    {typeof data?.walletFee === 'number' && data.walletFee < 0 && '-'}¥
                    {String(numberFormatter.format(data?.walletFee || 0)).replace('-', '')}
                  </Text>
                  <MainButton
                    thin
                    variant="secondary"
                    icon="currencyYen"
                    width={172}
                    onClick={() => setActiveModalType(ModalType.UseWallet)}
                    disabled={!buttonsActiveStatus.useWallet}
                  >
                    ウォレットを使用
                  </MainButton>
                  <MainButton
                    thin
                    variant="warnSecondary"
                    width={172}
                    onClick={() => setActiveModalType(ModalType.CancelWallet)}
                    disabled={!buttonsActiveStatus.cancelWallet}
                  >
                    解除
                  </MainButton>
                </Box>
              ),
              label: 'ウォレット利用',
            },
            {
              content: (
                <Box
                  width='100%'
                  display='flex'
                  flexDirection= 'column'
                  alignItems= 'flex-end'
                  gap={16}
                >
                  <Text variant="body14">
                    {typeof data?.usedTicketAmount === 'number' && data.usedTicketAmount > 0 && '-'}¥
                    {String(numberFormatter.format(Number(data?.ticketFee.replace(/,/g, '') || 0))).replace(
                      '-',
                      ''
                    ) || defaultVal}
                  </Text>
                  <MainButton
                    thin
                    variant="secondary"
                    icon="confirmationNumber"
                    width={172}
                    onClick={() => setActiveModalType(ModalType.UseTicket)}
                    disabled={!buttonsActiveStatus.useTicket}
                  >
                    チケットを使用
                  </MainButton>
                  <MainButton
                    thin
                    variant="warnSecondary"
                    width={172}
                    onClick={() => setActiveModalType(ModalType.CancelTicket)}
                    disabled={!buttonsActiveStatus.cancelTicket}
                  >
                    解除
                  </MainButton>
                </Box>
              ),
              label: 'チケット利用',
            },
            {
              content: (
                <Box display="flex" justifyContent="flex-end">
                  <Box maxWidth={210}>
                      <Text bold align="right" variant="body14">¥{data?.totalAmount || defaultVal}</Text>
                  </Box>
                </Box>
              ),
              label: '合計',
              labelBold: true,
            },
          ]}
        />
      </Box>
      <UseWalletModal
        isOpen={activeModalType === ModalType.UseWallet}
        onModalClose={() => setActiveModalType(ModalType.None)}
        submit={(amount) =>
          updateWalletUsage({
            variables: {
              id: data?.workId || 0,
              use_wallet: amount,
            },
          })
            .then((result) => {
              const resultRows = result.data?.updateTWorkingUseWalletById.resultRows;
              if (typeof resultRows === 'number' && resultRows > 0) {
                return true;
              }
              return false;
            })
            .finally(() => refetchAll())
        }
        amount={walletSumData?.getTWalletSUMById.price || 0}
        // ウォレットの場合は消費税を考慮する
        maxToUse={
          Math.floor(
            (Number(data?.totalHireFee.replace(/,/g, '') || 0) + Number(data?.tax.replace(/,/g, '') || 0)) / 100
          ) * 100
        }
      />
      <CancelWalletModal
        isOpen={activeModalType === ModalType.CancelWallet}
        onModalClose={() => setActiveModalType(ModalType.None)}
        submit={() =>
          updateWalletUsage({
            variables: {
              id: data?.workId || 0,
              use_wallet: 0,
            },
          })
            .then((result) => {
              const resultRows = result.data?.updateTWorkingUseWalletById.resultRows;
              if (typeof resultRows === 'number' && resultRows > 0) {
                return true;
              }
              return false;
            })
            .finally(() => refetchAll())
        }
      />
      <UseTicketModal
        isOpen={activeModalType === ModalType.UseTicket}
        onModalClose={() => setActiveModalType(ModalType.None)}
        amount={maxTicketAmountData?.getVTTicketListSUMByTpmemId.enabled_count || 0}
        onSubmit={(amountToUse) => {
          updateTicketUsage({
            variables: {
              id: data?.workId || 0,
              use_ticket: amountToUse,
            },
          })
            .then((result) => {
              if (result.errors) {
                openErrorModal({ message: 'チケットの使用に失敗しました。もう一度やり直してください。' });
              }
            })
            .finally(() => {
              refetchAll();
            });
        }}
        maxToUse={Number(data?.totalHireFee.replace(/,/g, '') || 0)}
        pricePerTicket={ticketPriceData?.getSTpmemTicketPrice.ticket_price || 1}
      />
      <CancelTicketModal
        isOpen={activeModalType === ModalType.CancelTicket}
        onModalClose={() => setActiveModalType(ModalType.None)}
        onSubmit={() =>
          updateTicketUsage({
            variables: {
              id: data?.workId || 0,
              use_ticket: 0,
            },
          })
            .then((result) => {
              if (!result.errors) {
                return true;
              }
              return false;
            })
            .finally(() => {
              refetchAll();
            })
        }
      />
    </>
    // feat_screen_04-02-01-05_end
  );
}

export default CostDetails;
