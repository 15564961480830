import { useGetMBtmemRankQuery } from 'graphql/graphql-mw';

export function useRank() {
  const { data } = useGetMBtmemRankQuery({
    context: { clientName: 'master' },
  });

  const rankMasterData = (data?.getMBtmemRank || []).filter((d): d is Exclude<typeof d, null> => d !== null);

  const rankSelectOptions = rankMasterData.map((item) => ({
    label: item.name,
    value: item.id.toString(),
  }));

  function getRankLabel(rankId: number) {
    const rank = rankMasterData.find((item) => item?.id === rankId);
    return rank?.name || '';
  }

  return { rankMasterData, rankSelectOptions, getRankLabel };
}
