/**
 * Figma ID: 01-01-12
 * 名称: 確認コード
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { TextLink } from 'components/textLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type AuthCodeModel = {
  dspEmail: string;
  toNext: (confirmationCode: string) => void;
  reSendConfirmationCode: () => Promise<void>;
  toEmail: () => void;
};

export function InputConfirmationCode(props: AuthCodeModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const { dspEmail, toNext, reSendConfirmationCode, toEmail } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    confirmationCode: string;
  }>({
    reValidateMode: 'onChange',
  });

  const codeLength = 6;
  const toNextPhase = handleSubmit((data) => {
    toNext(data.confirmationCode);
  });

  // feat screen 01-01-11 start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="確認コードを入力" />

      <Box display="flex" flexDirection="column" gap={8}>
        <Text variant="body14" color="primaryBlue">
          {dspEmail} メールに送信しました
        </Text>
        {/* <br /> */}
        <Text variant="body14">メールに記載された確認コードを入力してください。</Text>
      </Box>

      <Box display="flex" width="100%" justifyContent="center">
        {/* <Box display="flex" flexDirection="column" gap={24} width="100%" justifyContent="center"> */}
        <Box width={328}>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <RhfInput
              name="confirmationCode"
              control={control}
              width={328}
              placeholder="6桁のコードを入力"
              defaultValue=""
              rules={{
                required: '6桁のコードを入力を入力してください。',
                minLength: { value: codeLength, message: '6桁のコードを入力してください' },
                maxLength: { value: codeLength, message: '6桁のコードを入力してください' },
                validate: (value) => /^\d*$/.test(String(value)) || '数字を使用してください。',
              }}
            />
            {errors?.confirmationCode?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.confirmationCode.message}
              </Text>
            )}
          </Box>
          <Box display="grid" justifyContent="center">
            <MainButton
              onClick={() => {
                toNextPhase();
              }}
            >
              認証
            </MainButton>
          </Box>
          <Box display="grid" justifyContent="center" pt={24} gap={8}>
            <TextLink
              onClick={() => {
                reSendConfirmationCode();
              }}
            >
              確認コードを再送
            </TextLink>
            {/* </Box> */}

            {/* <Box display="grid" justifyContent="center"> */}
            <TextLink
              onClick={() => {
                toEmail();
              }}
            >
              メールアドレスを間違えたとき
            </TextLink>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="確認コードを入力" />

      <Box display="flex" flexDirection="column" gap={13} pt={21}>
        <Box display='block'>
          <Text variant="body14" color="primaryBlue">
            {dspEmail} メールに送信しました
          </Text>
        </Box>
        {/* <br /> */}
        <Box display='block'>
          <Text display='block' variant="body14">メールに記載された確認コードを入力してください。</Text>  
        </Box>
      </Box>

      <Box py={24}>
        <Box display="flex" flexDirection="column" gap={4}>
          <RhfInput
            fullWidth
            name="confirmationCode"
            control={control}
            width={328}
            placeholder="6桁のコードを入力"
            defaultValue=""
            rules={{
              required: '6桁のコードを入力を入力してください。',
              minLength: { value: codeLength, message: '6桁のコードを入力してください' },
              maxLength: { value: codeLength, message: '6桁のコードを入力してください' },
              validate: (value) => /^\d*$/.test(String(value)) || '数字を使用してください。',
            }}
          />
          {errors?.confirmationCode?.message && (
            <Box mt={-4}>
              <Text variant="caption12" color="cautionRed">
                {errors.confirmationCode.message}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            toNextPhase();
          }}
        >
          認証
        </MainButton>
      </Box>

      <Box display="grid" justifyContent="center" mt={24} gap={16}>
        <TextLink
          variant='body14'
          onClick={() => {
            reSendConfirmationCode();
          }}
        >
          確認コードを再送
        </TextLink>
        <TextLink
          onClick={() => {
            toEmail();
          }}
        >
          メールアドレスを間違えたとき
        </TextLink>
      </Box>
    </Box>
  );
  // feat screen 01-01-11 end
}
