import React from 'react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Modal } from 'components/newModal';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from '../text';
import { ModalZindex } from '../const';

export interface Props {
  isOpen: boolean;
  title?: string;
  message: string | React.ReactNode;
  onClose: () => void;
  zIndex?: number;
}

export function ErrorModal({ isOpen, title = 'エラー', message, onClose, zIndex = ModalZindex.OVERLAYZINDEX }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return (
    <Modal
      isOpen={isOpen}
      height="auto"
      header={
        <Text variant="h2" color="cautionRed">
          {title}
        </Text>
      }
      content={<Box>{typeof message === 'string' ? <Text variant="body14">{message}</Text> : message}</Box>}
      footer={
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <MainButton onClick={onClose} width={!isMobile ? 104 : 96} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClose}
      zindex={zIndex}
    />
  );
}
