/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Icon } from 'components/icon';
import { Divider } from 'components/newDivider';
import { RhfTextarea } from 'components/rhfTextarea';
import { ErrorMessage } from 'components/errorMessage';

import { useErrorModal } from 'components/error/errorModalProvider';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { scoreToStars } from '../common';

const enum ModalStep {
  EDIT = 1,
  CONFIRM = 2,
  SEND = 3,
}

interface ReviewData {
  score?: number;
  comment: string;
}

function ReviewModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ReviewData) => Promise<boolean>;
  refetch: () => void;
}) {
  const { isOpen, onClose, onSubmit, refetch } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.EDIT);
  const { openErrorModal } = useErrorModal();
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */   

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm<ReviewData>({ reValidateMode: 'onSubmit' });

  const currentScore = watch('score');
  const currentComment = watch('comment');

  function onModalClose() {
    setModalStep(ModalStep.EDIT); // 次に開くときのために状態を初期化
    setValue('score', undefined);
    setValue('comment', '');
    clearErrors();
    onClose();
  }

  const submit = handleSubmit((data) => {
    onSubmit(data).then((result) => {
      if (result) {
        setModalStep(ModalStep.SEND);
      } else {
        onModalClose();
        openErrorModal({
          message: 'レビューの送信に失敗しました。',
        });
      }
    });
  });

  useEffect(() => {
    setModalStep(ModalStep.EDIT);
    return () => setModalStep(ModalStep.EDIT);
  }, []);

  function generateStarButton(s: number) {
    return (
      <Icon
        name={typeof currentScore === 'number' && currentScore >= s ? 'star' : 'starBorder'}
        size={32}
        onClick={() => {
          setValue('score', s);
        }}
      />
    );
  }

  const EditModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        レビューを書く
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Box py={12}>
          <Text variant="body14">バイトラベラーへのレビューと、コメント入力を行い、次へを押してください。</Text>
        </Box>
        <form>
          <Box>
            <Box>
              <Text variant="body14" color="blueGray" bold>
                スコア
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gap={4}>
              {generateStarButton(1)}
              {generateStarButton(2)}
              {generateStarButton(3)}
              {generateStarButton(4)}
              {generateStarButton(5)}
              <Box ml={8}>
                <Text variant="bigTitle">
                  {typeof currentScore === 'number' && currentScore > 0 ? currentScore : '-'}
                </Text>
              </Box>
              <input type="hidden" {...register('score', { required: 'スコアを入力してください' })} />
            </Box>
            {errors.score && <ErrorMessage>{errors.score.message}</ErrorMessage>}
          </Box>
          <Box mt={40}>
            <Box>
              <Text variant="body14" color="blueGray" bold>
                コメント
              </Text>
            </Box>
            <RhfTextarea
              width={752}
              name="comment"
              control={control}
              rules={{ required: 'コメントを入力してください' }}
              defaultValue=""
            />
            {errors.comment && <ErrorMessage>{errors.comment.message}</ErrorMessage>}
          </Box>
        </form>
      </Box>
    ): (
      // feat_screen_04-02-07-05_start
      <Box display="flex" flexDirection="column" gap={16}>
        <Box>
          <Text display="block" variant="body14">バイトラベラーへのレビューと、コメント入力を行い、次へを押してください。</Text>
        </Box>
        <form>
          <Box display="flex" flexDirection="column" gap={16}>
            <Box>
              <Box>
                <Text variant="body14" color="blueGray" bold>
                  スコア
                </Text>
              </Box>
              <Box display="flex" alignItems="center" gap={8}>
                {generateStarButton(1)}
                {generateStarButton(2)}
                {generateStarButton(3)}
                {generateStarButton(4)}
                {generateStarButton(5)}
                <Box mt={1}>
                  <Text variant="bigTitle">
                    {typeof currentScore === 'number' && currentScore > 0 ? currentScore : '-'}
                  </Text>
                </Box>
                <input type="hidden" {...register('score', { required: 'スコアを入力してください' })} />
              </Box>
              {errors.score && <ErrorMessage>{errors.score.message}</ErrorMessage>}
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <Text variant="body14" color="blueGray" bold>
                  コメント
                </Text>
              </Box>
              <RhfTextarea
                fullWidth
                height={98}
                name="comment"
                control={control}
                rules={{ required: 'コメントを入力してください' }}
                defaultValue=""
              />
              {errors.comment && <ErrorMessage>{errors.comment.message}</ErrorMessage>}
            </Box>
          </Box>
        </form>
      </Box>
      // feat_screen_04-02-07-05_end
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={async () => {
            const result = await trigger();
            if (result) {
              setModalStep(ModalStep.CONFIRM);
            }
          }}
          disabled={currentScore === undefined || currentScore < 1 || currentComment.length < 1}
        >
          次へ
        </MainButton>
      </Box>
    ) : (
      // feat_screen_04-02-07-05_start
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          customHeight={32}
          width={96}
          thin
          onClick={async () => {
            const result = await trigger();
            if (result) {
              setModalStep(ModalStep.CONFIRM);
            }
          }}
          disabled={currentScore === undefined || currentScore < 1 || currentComment.length < 1}
        >
          次へ
        </MainButton>
      </Box>
      // feat_screen_04-02-07-05_end
    ),
    // feat_screen_04-02-07-05_start
    width: !isMobile ? 800 : "100%",
    height: !isMobile ? 507 : "auto",
    // feat_screen_04-02-07-05_end
  };

  const ConfirmModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        レビュー確認
      </Text>
    ),
    content: !isMobile ? (
      // <Box ma={12}>
      <Box>
        <Box pb={12}>
          <Text variant="body14">下記内容で送信します。よろしければ送信を押してください</Text>
        </Box>
        <Divider option="horizontal" />
        <Box my={12} display="flex" alignItems="center">
          <Box width={160}>
            <Text variant="body14">スコア</Text>
          </Box>
          {scoreToStars(currentScore || 0)}
        </Box>
        <Divider option="horizontal" />
        <Box my={12} display="flex" alignItems="center">
          <Box width={160}>
            <Text variant="body14">コメント</Text>
          </Box>
          <Text variant="body14">{currentComment}</Text>
        </Box>
        <Divider option="horizontal" />
      </Box>
    ) : (
      // feat_screen_04-02-07-06_start
      <Box display="flex" flexDirection="column" gap={16}>
        <Box >
          <Text display="block" variant="body14">下記内容で送信します。よろしければ送信を押してください</Text>
        </Box>
        <Divider option="horizontal"/>
        <Box display="flex" alignItems="center"  justifyContent="space-between">
          <Box>
            <Text variant="body14">スコア</Text>
          </Box>
          <Box display="flex" gap={4}>
            {scoreToStars(currentScore || 0, isMobile)}
          </Box>
        </Box>
        <Divider option="horizontal"/>
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Box minWidth={120}>
            <Text variant="body14">コメント</Text>
          </Box>
          <Box>
            <Text variant="body14">{currentComment}</Text>
          </Box>
        </Box>
      </Box>
      // feat_screen_04-02-07-06_end
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => setModalStep(ModalStep.EDIT)}>
          戻る
        </MainButton>
        <MainButton width={104} thin onClick={() => submit()}>
          送信
        </MainButton>
      </Box>
    ) : (
      // feat_screen_04-02-07-06_start
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => setModalStep(ModalStep.EDIT)}>
          戻る
        </MainButton>
        <MainButton customHeight={32} width={96} thin onClick={() => submit()}>
          送信
        </MainButton>
      </Box>
      // feat_screen_04-02-07-06_end
    ),
    /* feat_screen_04-02-07-07_start */
    width: !isMobile ? 800 : "100%",
    height: !isMobile ? 'auto' : 307,
    /* feat_screen_04-02-07-07_end */
  };

  const SendModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        送信 完了
      </Text>
    ),
    content: <Box>レビューを送信しました。</Box>,
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => {
            refetch();
            onModalClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_04-02-07-07_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          customHeight={32}
          width={96}
          variant="secondary"
          thin
          onClick={() => {
            refetch();
            onModalClose();
          }}
        >
          閉じる
        </MainButton>
      </Box>
      /* feat_screen_04-02-07-07_end */
    ),
    /* feat_screen_04-02-07-07_start */
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 190 : "auto",
    /* feat_screen_04-02-07-07_end */
  };

  function switchModalContent() {
    switch (modalStep) {
      case ModalStep.EDIT:
        return EditModal;
      case ModalStep.CONFIRM:
        return ConfirmModal;
      case ModalStep.SEND:
        return SendModal;
      default:
        return undefined;
    }
  }

  const modalContent = switchModalContent();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={modalContent?.height}
      width={modalContent?.width}
    />
  );
}

export default ReviewModal;
