/**
 * Figma ID: 08-07-02
 * 名称: 店舗ブロック
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { Control, UseFormWatch } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { MainButton } from 'components/mainButton';
import * as gql from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import type { AddBlockInfoModel } from '.';

type SelectBrandContentModel = {
  control: Control<AddBlockInfoModel>;
  watch: UseFormWatch<AddBlockInfoModel>;
  brandList: gql.GetMBrandListByBizIdQuery | undefined;
  setBrandName: (val: string) => void;
};
export function SelectBrandContent(props: SelectBrandContentModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { control, watch, brandList, setBrandName } = props;
  const currentBrandId = watch('brandId');
  React.useEffect(() => {
    brandList?.getMBrandListByBizId.map((val) => val.id === Number(currentBrandId) && setBrandName(val.name));
  }, [brandList?.getMBrandListByBizId, currentBrandId, setBrandName]);
  // feat screen 08-07-02 start
  return !isMobile ? (
    <Box width="100%">
      <Text variant="body14">ブランドを選択して「次へ」を押してください。</Text>
      <Box pt={17}>
        <InputLabel>ブランド</InputLabel>
        <RhfSelect
          placeholder="ブランドを選択"
          control={control}
          options={
            brandList?.getMBrandListByBizId.map((value) => ({ label: value.name, value: String(value.id) })) ?? []
          }
          name="brandId"
        />
      </Box>
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%">
      <Text display="block" variant="body14">ブランドを選択して「次へ」を押してください。</Text>
      <Box pt={16}>
        <InputLabel fullWidth>ブランド</InputLabel>
        <RhfSelect
          fullWidth
          height={40}
          placeholder="ブランドを選択"
          control={control}
          options={
            brandList?.getMBrandListByBizId.map((value) => ({ label: value.name, value: String(value.id) })) ?? []
          }
          name="brandId"
        />
      </Box>
    </Box>
    // feat screen 08-07-02 end
  );
}

type SelectBrandFooterModel = {
  nextPhase: () => void;
  close: () => void;
  disabledNextPhase: boolean;
};
export function SelectBrandFooter(props: SelectBrandFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { nextPhase, close, disabledNextPhase } = props;
  return (
    <Box display="flex" gap={8} justifyContent="flex-end">
      {/* feat screen 08-07-02 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} onClick={nextPhase} disabled={disabledNextPhase}>
        次へ
      </MainButton>
      {/* feat screen 08-07-02 end */}
    </Box>
  );
}
