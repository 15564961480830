import React, { useEffect, useState } from 'react';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import * as gql from 'graphql/graphql-mw';
import useIsMobile from 'hooks/responsive/useIsMobile';
import Divider from 'components/divider/divider';
import { BusinessFormType, StoreFormType } from '../../types';

interface Props {
  isOpen: boolean;
  storeData?: gql.GetVtMemberBaseByIdQuery['getVTMemberBaseById'] | null;
  formData?: StoreFormType & BusinessFormType;
  onClose: () => void;
  onBack: () => void;
  onComplete: () => void;
}

function ConfirmModal(props: Props) {
  const { isOpen, storeData, formData, onClose, onBack, onComplete } = props;
  const [photoSrc, setPhotoSrc] = useState<string>();
  
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  useEffect(() => {
    if (formData?.photo) {
      const file = formData.photo[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (result) {
          setPhotoSrc(result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setPhotoSrc(storeData?.image || '');
    }
  }, [formData?.photo, storeData?.image]);

  if (!formData) return null;

  const header = (
    <Text variant="h2" color="darkBlue">
      基本情報編集 確認
    </Text>
  );

  const content = !isMobile ? (
    <Box>
      <Box>
        <Text variant="body14">下記内容で登録します。よろしければ完了を押してください。</Text>
      </Box>
      <Box mt={20}>
        <Box>
          <Text variant="body14" color="blueGray">
            店舗画像
          </Text>
        </Box>
        <img src={photoSrc} alt="" width={160} height={120} />
      </Box>

      <Info
        items={[
          {
            label: 'ID',
            content: storeData?.id,
          },
          {
            label: '業種',
            content: storeData?.biz_name,
          },
          {
            label: 'ブランド',
            content: storeData?.brand_name,
          },
          {
            label: '住所',
            content: `〒${storeData?.zip_code ?? ''} ${storeData?.state ?? ''} ${storeData?.city ?? ''} ${
              storeData?.address ?? ''
            } ${storeData?.address2 ?? ''}`,
          },
          { label: '電話番号', content: storeData?.phone },
          { label: 'メールアドレス(ID)', content: storeData?.email },
          {
            label: '営業時間',
            content: formData.is24Hour
              ? '24時間営業'
              : ` ${formData.fromHour}:${formData.fromMin} ~ ${formData.toHour}:${formData.toMin}`,
          },
          { label: '従業員駐車場', content: storeData?.parking === 'yes' ? '有' : '無' },
          {
            label: '責任者氏名',
            content: `${formData.managerFamilyName ?? ''} ${formData.managerFirstName ?? ''}`,
          },
          {
            label: '責任者生年月日',
            content: `${formData.managerBirthDate.getFullYear()}年${
              formData.managerBirthDate.getMonth() + 1
            }月${formData.managerBirthDate.getDate()}日`,
          },
          { label: '責任者電話番号', content: formData?.managerPhone },
          {
            label: '事業者',
            content: (
              <Box display="flex" flexDirection="column" gap={8}>
                <Box>
                  <Text variant="body14">{storeData?.mco_name}</Text>
                </Box>
                <Box>
                  <Text variant="body14">
                    〒{storeData?.mco_zip_code} {storeData?.mco_state} {storeData?.mco_city} {storeData?.mco_address}{' '}
                    {storeData?.mco_address2}
                  </Text>
                </Box>
                <Box display="flex">
                  <Box width={112}>
                    <Text variant="body14">法人番号</Text>
                  </Box>
                  <Box>
                    <Text variant="body14">{storeData?.corporate_no}</Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={112}>
                    <Text variant="body14">代表者</Text>
                  </Box>
                  <Box>
                    <Text variant="body14">
                      {formData?.representativeFamilyName} {formData?.representativeFirstName}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={112}>&nbsp;</Box>
                  <Box>
                    <Text variant="body14">
                      {formData.representativeFamilyNameKana} {formData.representativeFirstNameKana}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={112}>
                    <Text variant="body14">担当者</Text>
                  </Box>
                  <Box>
                    <Text variant="body14">
                      {formData.businessManagerFamilyName} {formData.businessManagerFirstName}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={112}>&nbsp;</Box>
                  <Box>
                    <Text variant="body14">
                      {formData?.businessManagerFamilyNameKana} {formData?.businessManagerFirstNameKana}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width={112}>
                    <Text variant="body14">担当者電話番号</Text>
                  </Box>
                  <Box>
                    <Text variant="body14">{formData?.businessManagerPhone}</Text>
                  </Box>
                </Box>
              </Box>
            ),
          },
        ]}
      />
    </Box>
  ) : (
    // feat_screen_09-01-04_start
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" gap={14}>
        <Box>
          <Text variant="body14">下記内容で登録します。よろしければ完了を押してください。</Text>
        </Box>
        <Divider option='horizontal'/>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text variant="body14" color="blueGray">
              店舗画像
            </Text>
          </Box>
          <img src={photoSrc} alt="" width={126} height={95} />
        </Box>
        <Divider option='horizontal'/>
      </Box>

      <Info
        items={[
          {
            label: 'ID',
            content: storeData?.id,
          },
          {
            label: '業種',
            content: storeData?.biz_name,
          },
          {
            label: 'ブランド',
            content: storeData?.brand_name,
          },
          {
            label: '住所',
            content: `〒${storeData?.zip_code ?? ''} ${storeData?.state ?? ''} ${storeData?.city ?? ''} ${
              storeData?.address ?? ''
            } ${storeData?.address2 ?? ''}`,
          },
          { label: '電話番号', content: storeData?.phone },
          { label: 'メールアドレス(ID)', content: storeData?.email },
          {
            label: '営業時間',
            content: formData.is24Hour
              ? '24時間営業'
              : ` ${formData.fromHour}:${formData.fromMin} ~ ${formData.toHour}:${formData.toMin}`,
          },
          { label: '従業員駐車場', content: storeData?.parking === 'yes' ? '有' : '無' },
          {
            label: '責任者氏名',
            content: `${formData.managerFamilyName ?? ''} ${formData.managerFirstName ?? ''}`,
          },
          {
            label: '責任者生年月日',
            content: `${formData.managerBirthDate.getFullYear()}年${
              formData.managerBirthDate.getMonth() + 1
            }月${formData.managerBirthDate.getDate()}日`,
          },
          { label: '責任者電話番号', content: formData?.managerPhone },
          {
            label: '事業者',
            content: (
              <Box display="flex" flexDirection="column" gap={8}>
                <Box display="flex">
                  <Text align="left" variant="body14">{storeData?.mco_name}</Text>
                </Box>

                <Divider option="horizontal"/>

                <Box display="flex">
                  <Text align="left" variant="body14">
                    〒{storeData?.mco_zip_code} {storeData?.mco_state} {storeData?.mco_city} {storeData?.mco_address}{' '}
                    {storeData?.mco_address2}
                  </Text>
                </Box>

                <Divider option="horizontal"/>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text variant="body14">法人番号</Text>
                  </Box>
                  <Box display="flex">
                    <Text align='right' variant="body14">{storeData?.corporate_no}</Text>
                  </Box>
                </Box>

                <Divider option="horizontal"/>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text variant="body14">代表者</Text>
                  </Box>
                  <Box display="flex">
                    <Text align="right" variant="body14">
                      {formData?.representativeFamilyName} {formData?.representativeFirstName}
                    </Text>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Box>&nbsp;</Box>
                  <Box display="flex">
                    <Text align='right' variant="body14">
                      {formData.representativeFamilyNameKana} {formData.representativeFirstNameKana}
                    </Text>
                  </Box>
                </Box>

                <Divider option="horizontal"/>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text variant="body14">担当者</Text>
                  </Box>
                  <Box display="flex">
                    <Text align='right' variant="body14">
                      {formData.businessManagerFamilyName} {formData.businessManagerFirstName}
                    </Text>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Box>&nbsp;</Box>
                  <Box display="flex">
                    <Text align="right" variant="body14">
                      {formData?.businessManagerFamilyNameKana} {formData?.businessManagerFirstNameKana}
                    </Text>
                  </Box>
                </Box>
                  
                <Divider option="horizontal"/>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text variant="body14">担当者電話番号</Text>
                  </Box>
                  <Box display='flex'>
                    <Text align='right' variant="body14">{formData?.businessManagerPhone}</Text>
                  </Box>
                </Box>
              </Box>
            ),
          },
        ]}
      />
    </Box>
    // feat_screen_09-01-04_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton 
        // feat_screen_09-01-04_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-04_end
        variant="secondary" 
        onClick={() => onClose()}
      >
        キャンセル
      </MainButton>
      <MainButton
        // feat_screen_09-01-04_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-04_end
        variant="secondary" 
        onClick={() => onBack()}
      >
        戻る
      </MainButton>
      <MainButton 
        // feat_screen_09-01-04_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-01-04_end
        variant="primary" 
        onClick={() => onComplete()}
      >
        完了
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}
      content={content}
      footer={footer}
      // feat_screen_09-01-04_start
      height={!isMobile ? "90vh" : 589} 
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-01-04_end
    />
  );
}

export default ConfirmModal;
