import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { ResultRows } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { VtMemberBase, useUpdateTMemberBankInfoByIdMutation } from 'graphql/graphql-mw';
import { useForm } from 'react-hook-form';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';
import EditBankModal from './modals/BankAccount/EditBankModal';
import ConfirmBankModal from './modals/BankAccount/ConfirmBankModal';
import BankChangedModal from './modals/BankAccount/BankChangedModal';
import { BankFormType } from './types';
import { PersonalBank, AccountType } from '../const';

enum ModalTypes {
  EDIT_BANK = 'EDIT_BANK',
  CONFIRM_BANK = 'CONFIRM_BANK',
  BANK_CHANGED = 'BANK_CHANGED',
}

type BankAccountProps = {
  bankData?: VtMemberBase;
  refetch: () => void;
};
function BankAccount(props: BankAccountProps) {
  const { bankData, refetch } = props;
  const [activeModal, setActiveModal] = React.useState<ModalTypes | null>(null);
  const bankForm = useForm<BankFormType>();
  const [bankEditInfo, setBankEditInfo] = React.useState<BankFormType | null>(null);
  const [updateBankInfo] = useUpdateTMemberBankInfoByIdMutation();
  const { openErrorModal } = useErrorModal();

  // 支店ドロップ初期表示用
  const [isBankInit, setIsBankInit] = React.useState<boolean>(false);

  function onCloseModal() {
    bankForm.reset();
    setBankEditInfo(null);
    setActiveModal(null);
    setIsBankInit(false);
  }

  // エラー対応
  const defaultBankEditInfo: BankFormType = {
    personal_bank: '',
    bank: '',
    branch_no: '',
    branch_name: '',
    account_type: '',
    account_no: '',
    account_name: '',
    bankCode: '',
  };

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box px={16} py={24} width="100%">
      <Box display="flex" alignItems="center" gap={8} py={18}>
        <Text variant="h2" color="darkBlue">
          銀行口座
        </Text>
        <Text variant="caption12" color="darkBlue">
          運営から振り込みがある場合に利用する口座です。使用料金のお支払いについては「決済会社情報」をご確認ください。
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={20}>
        <Box display="flex" gap={16}>
          <Text variant="caption12" color="blueGray">
            {bankData?.bank}
          </Text>
          <Text variant="caption12" color="blueGray">
            {bankData?.branch_name}
          </Text>
          <Text variant="caption12" color="blueGray">
            {bankData?.account_type}
          </Text>
          <Text variant="caption12" color="blueGray">
            {bankData?.account_no}
          </Text>
          <Divider option="vertical" length={16} />
          <Text variant="caption12" color="blueGray">
            {bankData?.personal_bank}
          </Text>
          <Divider option="vertical" length={16} />
          <Text variant="caption12" color="blueGray">
            {bankData?.account_name}
          </Text>
        </Box>
        <Box width={160}>
          <MainButton
            icon="edit"
            iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
            variant="secondary"
            thin
            onClick={() => {
              setIsBankInit(true); // 初期支店選択用
              setActiveModal(ModalTypes.EDIT_BANK);
            }}
          >
            編集
          </MainButton>
        </Box>
      </Box>
      <EditBankModal
        isOpen={activeModal === ModalTypes.EDIT_BANK}
        onClose={() => onCloseModal()}
        onNext={(data) => {
          setBankEditInfo(data);
          setActiveModal(ModalTypes.CONFIRM_BANK);
        }}
        data={bankData}
        form={bankForm}
        isBank={isBankInit}
      />
      <ConfirmBankModal
        isOpen={activeModal === ModalTypes.CONFIRM_BANK}
        form={bankEditInfo || defaultBankEditInfo}
        onClose={() => onCloseModal()}
        onComplete={() => {
          updateBankInfo({
            variables: {
              id: bankData?.id ?? 0,
              account_kind: bankEditInfo?.personal_bank === PersonalBank.Person ? 1 : 2,
              bank_code: bankEditInfo?.bankCode ?? '',
              branch_no: bankEditInfo?.branch_no ?? '',
              branch_name: bankEditInfo?.branch_name ?? '',
              account_type: bankEditInfo?.account_type === AccountType.SavingsAccount ? 1 : 2,
              account_no: bankEditInfo?.account_no ?? '',
              account_name: bankEditInfo?.account_name ?? '',
            },
          })
            .then((result) => {
              if (!result.errors) {
                if (result.data?.updateTMemberBankInfoById.resultRows === ResultRows.NOT_UPDATED) {
                  onCloseModal();
                  openErrorModal({ message: '編集が完了できませんでした。' });
                } else {
                  setActiveModal(ModalTypes.BANK_CHANGED);
                }
              } else {
                onCloseModal();
                openErrorModal({ message: '編集が完了できませんでした。' });
              }
            })
            .catch(() => {
              onCloseModal();
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }}
        toBack={() => {
          setIsBankInit(false);
          setActiveModal(ModalTypes.EDIT_BANK);
        }}
      />
      <BankChangedModal
        isOpen={activeModal === ModalTypes.BANK_CHANGED}
        onClose={() => {
          refetch();
          setActiveModal(null);
        }}
      />
    </Box>
  ) : (
    // feat_screen_09-02-01_start
    <Box px={16} py={16} width="100%" height="calc(100svh - 338px)" overflow="auto">
      <Box pb={12} display="flex" flexDirection='column' alignItems='center' justifyContent="center">
        <Text variant="h2" color="darkBlue">
          銀行口座
        </Text>
        <Text variant="caption12" align='center' color="darkBlue">
          運営から振り込みがある場合に利用する口座です。使用料金のお支払いについては「決済会社情報」をご確認ください。
        </Text>
      </Box>
      <Box alignItems="center">
        <Divider option="horizontal" />
        <Box display="flex" gap={8} pt={16} flexDirection="column">
          <Box display="flex" gap={16}>
            <Box display="flex" gap={12}>
              <Text variant="caption12" color="blueGray">
                {bankData?.bank}
              </Text>
              <Text variant="caption12" color="blueGray">
                {bankData?.branch_name}
              </Text>
            </Box>

            <Box display="flex" gap={12}>
              <Text variant="caption12" color="blueGray">
                {bankData?.account_type}
              </Text>
              <Text variant="caption12" color="blueGray">
                {bankData?.account_no}
              </Text>
            </Box>
          </Box>

          <Divider option="horizontal"/>

          <Text variant="caption12" color="blueGray">
            {bankData?.personal_bank}
          </Text>

          <Divider option="horizontal"/>
          <Text variant="caption12" color="blueGray">
            {bankData?.account_name}
          </Text>
        </Box>
        <Box pt={14}>
          <MainButton
            icon="edit"
            fullWidth
            customHeight={32}
            iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
            variant="secondary"
            thin
            onClick={() => {
              setIsBankInit(true); // 初期支店選択用
              setActiveModal(ModalTypes.EDIT_BANK);
            }}
          >
            編集
          </MainButton>
        </Box>
      </Box>
      <EditBankModal
        isOpen={activeModal === ModalTypes.EDIT_BANK}
        onClose={() => onCloseModal()}
        onNext={(data) => {
          setBankEditInfo(data);
          setActiveModal(ModalTypes.CONFIRM_BANK);
        }}
        data={bankData}
        form={bankForm}
        isBank={isBankInit}
      />
      <ConfirmBankModal
        isOpen={activeModal === ModalTypes.CONFIRM_BANK}
        form={bankEditInfo || defaultBankEditInfo}
        onClose={() => onCloseModal()}
        onComplete={() => {
          updateBankInfo({
            variables: {
              id: bankData?.id ?? 0,
              account_kind: bankEditInfo?.personal_bank === PersonalBank.Person ? 1 : 2,
              bank_code: bankEditInfo?.bankCode ?? '',
              branch_no: bankEditInfo?.branch_no ?? '',
              branch_name: bankEditInfo?.branch_name ?? '',
              account_type: bankEditInfo?.account_type === AccountType.SavingsAccount ? 1 : 2,
              account_no: bankEditInfo?.account_no ?? '',
              account_name: bankEditInfo?.account_name ?? '',
            },
          })
            .then((result) => {
              if (!result.errors) {
                if (result.data?.updateTMemberBankInfoById.resultRows === ResultRows.NOT_UPDATED) {
                  onCloseModal();
                  openErrorModal({ message: '編集が完了できませんでした。' });
                } else {
                  setActiveModal(ModalTypes.BANK_CHANGED);
                }
              } else {
                onCloseModal();
                openErrorModal({ message: '編集が完了できませんでした。' });
              }
            })
            .catch(() => {
              onCloseModal();
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }}
        toBack={() => {
          setIsBankInit(false);
          setActiveModal(ModalTypes.EDIT_BANK);
        }}
      />
      <BankChangedModal
        isOpen={activeModal === ModalTypes.BANK_CHANGED}
        onClose={() => {
          refetch();
          setActiveModal(null);
        }}
      />
    </Box>
    // feat_screen_09-02-01_end
  );
}

export default BankAccount;
