import React, { useMemo, useEffect, useLayoutEffect, useState } from 'react';
import * as gql from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { usePagenation } from 'components/utils';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { styled } from '@linaria/react';
import { useAuth } from 'hooks/authProvider';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import debounce from 'lodash/debounce';
import { MYPAGE_MENU_INDEX } from 'pages/s09/const';
import { RefundRequestModal } from './components/modal/refundRequestModal';

enum ModalType {
  NONE = 'none',
  REFUND_REQUEST = 'refundRequest',
}

/* feat_common_responsive_list_row_start */
const StyledSpanContent = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`;
/* feat_common_responsive_list_row_start */

export function S0701() {
  const navigate = useNavigate();
  const auth = useAuth();
  const tpmemId = auth?.tpmemId;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  if (tpmemId === null) navigate('/signin');
  /* feat_bottom_tab_responsive_mobile_start */
  const [isMobileSmall, setIsMobileSmall] = useState<boolean>(false);
  /* feat_bottom_tab_responsive_mobile_end */
  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.NONE);
  /* feat_bottom_tab_responsive_mobile_start */
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobileSmall(window.innerWidth < 546);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    updateSize();
    return window.removeEventListener('resize', updateSize);
  }, []);
  /* feat_bottom_tab_responsive_mobile_end */
  const {
    data: { getTWalletSUMLoggingById: { wallet, logging } } = {
      getTWalletSUMLoggingById: {
        wallet: {} as gql.TWalletSum,
        logging: [] as gql.TLogging[],
      },
    },
    loading,
    error: getTWalletSUMLoggingByIdError,
    refetch: refetchWalletSumLog,
  } = gql.useGetTWalletSumLoggingByIdQuery({
    variables: { id: tpmemId ?? 0 },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();
  useEffect(() => {
    if (getTWalletSUMLoggingByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getTWalletSUMLoggingByIdError, openErrorModal]);

  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const items = useMemo(() => {
    if (loading || logging?.length === 0) return [];

    const data = logging?.slice((page - 1) * limit, page * limit);
    return data;
  }, [limit, loading, logging, page]);

  const formatMatter = (matter: number): string => {
    if (matter < 0) {
      return String(matter.toLocaleString());
    }
    return `+${matter.toLocaleString()}`;
  };

  // feat screen 07-01-01 start
  const StyleHeader = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
    background: #f8feff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    align-item: center;
  `;
  // feat screen 07-01-01 end

  return !isMobile ? (
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" height="100%" gap={26}>
        <Box display="flex" height={30} justifyContent="space-between">
          <Text variant="h1" color="darkBlue">
            ウォレット
          </Text>
          <MainButton
            variant="secondary"
            onClick={() => {
              navigate('/s09', { state: { menuIndex: MYPAGE_MENU_INDEX.BankAccount } });
            }}
          >
            払戻口座確認
          </MainButton>
        </Box>
        <Box display="flex" flex="auto">
          <Card overflow="hidden">
            <Box display="flex" flexDirection="row" pa={16} gap={16} alignItems="center" justifyContent="space-between">
              <Text variant="caption12" color="darkBlue">
                {wallet?.refund_charge !== 0 ? (
                  <>
                    貯まった育成料を払戻請求します。払戻手数料{wallet?.refund_charge}円分を引いた金額が振り込まれます。
                  </>
                ) : (
                  <>貯まった育成料を払戻請求します。</>
                )}
              </Text>
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="body16" color="darkBlue" bold>
                  残高
                </Text>
                <Text variant="body16" color="darkBlue" bold>
                  ¥{wallet?.price?.toLocaleString()}
                </Text>
              </Box>
            </Box>
            <Box display="flex" px={16}>
              <Divider option="horizontal" />
            </Box>
            <Box
              display="flex"
              px={16}
              pt={11.5}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="h3" color="blueGray">
                入出金履歴
              </Text>
              <MainButton
                icon="currencyYen"
                variant="secondary"
                width={120}
                thin
                onClick={() => setActiveModalType(ModalType.REFUND_REQUEST)}
              >
                払戻請求
              </MainButton>
            </Box>
            <Box flex="auto" width="100%" px={16}>
              <PaginationBox
                dataSize={logging?.length ?? 0}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={350}>
                  <Box display="flex" flexDirection="column" width="100%" gap={8}>
                    <Divider option="horizontal" />
                    {items?.map((item) => (
                      <div key={item?.id}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                          <Box display="flex" flexDirection="column" py={8} gap={4}>
                            <Text variant="body14">{item?.matter}</Text>
                            <Text variant="caption11" color="darkGray">
                              {item?.log_date}
                            </Text>
                          </Box>
                          <Text variant="body14">¥{formatMatter(item?.ref_no ?? 0)}</Text>
                        </Box>
                        <Divider option="horizontal" />
                      </div>
                    ))}
                  </Box>
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Card>
        </Box>
      </Box>
      <RefundRequestModal
        isOpen={activeModalType === ModalType.REFUND_REQUEST}
        onClose={() => setActiveModalType(ModalType.NONE)}
        price={wallet?.price ?? 0}
        refundCharge={wallet?.refund_charge ?? 0}
        refetchWalletSumLog={() => {
          refetchWalletSumLog();
        }}
      />
    </>
  ) : (
    // feat screen 07-01-01 start
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" columnGap={16} width="100vw" height="100%">
        <StyleHeader>
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between" px={16}>
            <Text variant="h2" color="darkBlue">
              ウォレット
            </Text>
            <MainButton
              variant="secondary"
              onClick={() => {
                navigate('/s09', { state: { menuIndex: MYPAGE_MENU_INDEX.BankAccount } });
              }}
            >
              払戻口座確認
            </MainButton>
          </Box>
        </StyleHeader>
        <Box overflow="auto" height="100%" display="flex" px={16} pt={16} pb={isMobile ? 76 : 16}>
          <Card overflow="hidden">
            <Box
              display="flex"
              flexDirection="column"
              pa={16}
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text variant="caption12" color="darkBlue" align="center">
                {wallet?.refund_charge !== 0 ? (
                  <>
                    貯まった育成料を払戻請求します。払戻手数料{wallet?.refund_charge}円分を引いた金額が振り込まれます。
                  </>
                ) : (
                  <>貯まった育成料を払戻請求します。</>
                )}
              </Text>
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <Text variant="body16" color="darkBlue" bold>
                  残高
                </Text>
                <Text variant="body16" color="darkBlue" bold>
                  ¥{wallet?.price?.toLocaleString()}
                </Text>
              </Box>
            </Box>
            <Box display="flex" px={16}>
              <Divider option="horizontal" />
            </Box>
            <Box
              display="flex"
              px={16}
              pt={16}
              pb={14}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="h3" color="blueGray">
                入出金履歴
              </Text>
              <MainButton
                icon="currencyYen"
                variant="secondary"
                width={120}
                thin
                onClick={() => setActiveModalType(ModalType.REFUND_REQUEST)}
              >
                払戻請求
              </MainButton>
            </Box>
            <Box flex="auto" width="100%">
              <PaginationBox
                dataSize={logging?.length ?? 0}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <Box>
                  <ScrollWrapper bottom={!isMobileSmall ? 375 : 393} bottomSE={393}>
                    <Box flex="auto" width="100%" px={16}>
                      <Box display="flex" flexDirection="column" width="100%" gap={6}>
                        <Divider option="horizontal" />
                        {items?.map((item) => (
                          <div key={item?.id}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                              <Box display="flex" flexDirection="column" pt={14} pb={11} gap={5} maxWidth={210}>
                                <StyledSpanContent>{item?.matter}</StyledSpanContent>
                                <Text variant="caption11" color="darkGray">
                                  {item?.log_date}
                                </Text>
                              </Box>
                              <Text variant="body14">¥{formatMatter(item?.ref_no ?? 0)}</Text>
                            </Box>
                            <Divider option="horizontal" />
                          </div>
                        ))}
                      </Box>
                    </Box>
                  </ScrollWrapper>
                </Box>
              </PaginationBox>
            </Box>
          </Card>
        </Box>
      </Box>
      <RefundRequestModal
        isOpen={activeModalType === ModalType.REFUND_REQUEST}
        onClose={() => setActiveModalType(ModalType.NONE)}
        price={wallet?.price ?? 0}
        refundCharge={wallet?.refund_charge ?? 0}
        refetchWalletSumLog={() => {
          refetchWalletSumLog();
        }}
      />
    </>
    // feat screen 07-01-01 end
  );
}
