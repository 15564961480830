import React, { useState } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

const enum ModalStep {
  CONFIRM = 1,
  COMPLETE = 2,
}

/**
 * Figma ID
 * 04-02-01-07
 * チケット解除、チケット解除完了
 */

function CancelTicketModal(props: { isOpen: boolean; onModalClose: () => void; onSubmit: () => Promise<boolean> }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, onModalClose, onSubmit } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CONFIRM);

  const confirmModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        解除
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          トライアルチケットの使用を解除しますか？
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat screen 04-02-01-07 start
          width={!isMobile ? 104 : 96}
          // feat screen 04-02-01-07 end
          variant="secondary"
          thin
          onClick={() => onModalClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          // feat screen 04-02-01-07 start
          width={!isMobile ? 104 : 96}
          // feat screen 04-02-01-07 end
          thin
          onClick={() => {
            onSubmit().then((isSuccess) => {
              if (isSuccess) {
                setModalStep(ModalStep.COMPLETE);
              }
            });
          }}
        >
          解除
        </MainButton>
      </Box>
    ),
  };

  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        解除 完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          トライアルチケットの使用を解除しました。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => {
            onModalClose();
            setModalStep(ModalStep.CONFIRM); // reset modalStep
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
  };

  const modalContent = modalStep === ModalStep.CONFIRM ? confirmModal : completeModal;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default CancelTicketModal;
