/**
 * Figma ID: 04-01-03-10
 * 名称: 週定期日程作成 / プレビュー確認 募集情報（モーダル）
 */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useAuth } from 'hooks/authProvider';
import { Info } from 'components/info';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { ImageModal } from 'components/newModal';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { FormData } from '../../common/formState';
import { ScheduleTypeLabel } from '../../common/scheduleTypeContext';
import { useAutoRecruitPreviewItems } from '../preview/useAutoRecruitPreviewItems';

type Props = {
  fixedFormData?: FormData;
};

export enum AutoDecide {
  MANUAL = 0,
  AUTO = 1,
  COUNTEROFFER = 2,
}

export function WeeklyPreviewInfo({ fixedFormData }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  // 店舗情報
  const { storeInfo } = useAuth();
  const generateSelectedWeekdays = (
    monday?: boolean,
    tuesday?: boolean,
    wednesday?: boolean,
    thursday?: boolean,
    friday?: boolean,
    saturday?: boolean,
    sunday?: boolean
  ): string => {
    const out: string[] = [];
    if (monday) out.push('月');
    if (tuesday) out.push('火');
    if (wednesday) out.push('水');
    if (thursday) out.push('木');
    if (friday) out.push('金');
    if (saturday) out.push('土');
    if (sunday) out.push('日');
    return out.join('・');
  };

  const infoItems: { content: React.ReactNode; label: string }[] = [
    {
      content: fixedFormData?.check_schedule_type === 1 ? ScheduleTypeLabel.DAILY : ScheduleTypeLabel.WEEKLY,
      label: '日程種別',
    },
    {
      content: Number(fixedFormData?.auto_decide) === AutoDecide.MANUAL ? '手動' : '自動',
      label: '採用方式',
    },
    {
      content: fixedFormData?.title || '',
      label: '掲載タイトル',
    },
    {
      content: storeInfo?.name || '',
      label: '募集店',
    },
    {
      content: `${generateSelectedWeekdays(
        fixedFormData?.monday_flag,
        fixedFormData?.tuesday_flag,
        fixedFormData?.wednesday_flag,
        fixedFormData?.thursday_flag,
        fixedFormData?.friday_flag,
        fixedFormData?.saturday_flag,
        fixedFormData?.sunday_flag
      )}曜日`,
      label: '曜日',
    },
    {
      content:
        fixedFormData &&
        `${String(format(new Date(fixedFormData?.repeat_start_date || 0), 'yyyy年MM月dd日'))} ~ ${String(
          format(new Date(fixedFormData?.repeat_end_date || 0), 'yyyy年MM月dd日')
        )}`,
      label: '繰り返し期間',
    },
    {
      content:
        fixedFormData &&
        `作成日時 ~ シフト出勤の${fixedFormData?.shift_hour_to || ''}時間前`,
      label: '各募集掲載期間',
    },
    {
      content:
        fixedFormData &&
        `${String(fixedFormData?.weekly_shift_hour) || ''}:${String(fixedFormData?.weekly_shift_minute) || ''} ~ ${
          String(fixedFormData?.weekly_end_hour) || ''
        }:${String(fixedFormData?.weekly_end_minute) || ''}`,
      label: 'バイト時間',
    },
    {
      content: fixedFormData?.check_break_time !== '0' ? `${String(fixedFormData?.break_time)}分` : '無',
      label: '休憩時間',
    },
    {
      content: fixedFormData?.hour_wage_display ? `¥${String(fixedFormData?.hour_wage_display)}` : '無',
      label: '時給',
    },
  ];

  const { createAutoRecruitePreviewItems } = useAutoRecruitPreviewItems();

  if (fixedFormData?.auto_decide === AutoDecide.AUTO.toString()) {
    infoItems.push(
      ...createAutoRecruitePreviewItems({
        rank: fixedFormData?.rank,
        score: fixedFormData?.score,
        hireFee: fixedFormData?.hire_fee,
        cancelPer: fixedFormData?.cancel_per,
        imminentPer: fixedFormData?.imminent_per,
      })
    );
  }

  infoItems.push(
    {
      content: fixedFormData?.chief || '',
      label: '当日の店舗責任者',
    },
    {
      content: fixedFormData?.check_trans_fee !== '0' ? `¥${String(fixedFormData?.trans_fee)}` : '無',
      label: '交通費',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding align start
        <Text wordBreak="break-all" align={!isMobile ? "left" : "right"} variant="body14">
          {charcterReplaceBr(fixedFormData?.description || '')}
        </Text>
        // feat screen 04-01-03-10 adding align end
      ),
      label: '業務詳細',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding align start
        <Text wordBreak="break-all" align={!isMobile ? "left" : "right"} variant="body14">
          {charcterReplaceBr(fixedFormData?.conditions || '')}
        </Text>
        // feat screen 04-01-03-10 adding align end
      ),
      label: '働くための条件',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding align start
        <Text wordBreak="break-all" align={!isMobile ? "left" : "right"} variant="body14">
          {charcterReplaceBr(fixedFormData?.caution || '')}
        </Text>
        // feat screen 04-01-03-10 adding align end
      ),
      label: '注意事項',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding align start
        <Text wordBreak="break-all" align={!isMobile ? "left" : "right"} variant="body14">
          {charcterReplaceBr(fixedFormData?.belongings || '')}
        </Text>
        // feat screen 04-01-03-10 adding align end
      ),
      label: '持ち物',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding align start
        <Text wordBreak="break-all" align={!isMobile ? "left" : "right"} variant="body14">
          {charcterReplaceBr(fixedFormData?.access || '')}
        </Text>
        // feat screen 04-01-03-10 adding align end
      ),
      label: 'アクセス',
    },
    {
      content: (
        // feat screen 04-01-03-10 adding justifyContent start
        <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' , justifyContent: !isMobile ? 'flex-start' : 'flex-end' }}>
          {/* feat screen 04-01-03-10 adding justifyContent end */}
          {fixedFormData?.image1_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image1_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image1_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image2_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image2_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image2_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image3_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image3_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image3_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image4_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image4_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image4_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
          {fixedFormData?.image5_flag && (
            <ImgWrapper
              onClick={() => {
                setModalImageSrc(fixedFormData.image5_path || '');
                setIsImageModalOpen(true);
              }}
            >
              <img
                style={{ width: '126px', height: '94.5px' }}
                src={fixedFormData.image5_path || ''}
                alt="サムネイル"
              />
            </ImgWrapper>
          )}
        </div>
      ),
      label: 'メイン画像',
    },
    {
      content: (
        <TextLink href={fixedFormData?.attached_file || '#'} variant="body14">
          {fixedFormData?.attached_name || ''}
        </TextLink>
      ),
      label: '添付ファイル',
    }
  );

  return (
    <>
      <Info items={infoItems} />
      <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
    </>
  );
}
