import React from 'react';
import LocalModal from './Modal';

/**
 * Figma ID
 * 06-03-01
 * 承認
 */

interface Props {
  step: number;
  onModalClose: () => void;
  onClickSubmit: () => void;
}

export function ApprovalModal({ step, onClickSubmit, onModalClose }: Props) {
  return LocalModal({
    title: '承認',
    contentText: (
      <>
        このユーザーの所属店舗登録申請を承認しますか？
        <br />
        この操作は取り消せません。
      </>
    ),
    submitText: '承認',
    step,
    onClickSubmit,
    onModalClose,
  });
}
