/**
 * Figma ID: 01
 * 名称: 新規登録
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from 'components/box';
import { Text } from 'components/text';
import { GraphQLErrorCode } from 'components/const';
import { useAddupdTMemberCognitoMutation, useUpdateTMemberActiveByIdMutation } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { format } from 'date-fns';
import { Loading } from 'components/loading/loading';
import { uploadFile } from 'components/api';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { SelectStore } from './selectStore';
import { DetailTpmem } from './detailTpmem';
import { CorporationInfo } from './corporationInfo';
import { EmployerInfo } from './employerInfo';
import { RequiredRecruitment } from './requiredRecruitmentContent';
import { BankInfo } from './bankInfo';
import { InputMailAndPassword } from './inputMailAndPassword';
import { InputConfirmationCode } from './inputConfirmationCode';
import { Complete } from './complete';
import { CompleteEmail } from './completeEmail';
import { SelectBusinessAndBrand } from './selectBusinessAndBrand';
import { ConfirmSubmitContent } from './confirmSubmitContent';
import { ConfirmEmailContent } from './confirmEmailContent';
import { StopRegistration } from './stopRegistration';
import { StopRegistrationContent } from './stopRegistrationContent';
import { TermsOfUsePrivacyPolicy } from './termsOfUsePrivacyPolicy';
import { SendComplete } from './sendcomplete';
import { EmailUpdate } from './emailUpdate';

type PhaseModel =
  | 'termsOfUsePrivacyPolicy'
  | 'selectBusinessAndBrand'
  | 'selectTpmem'
  | 'stopRegistration'
  | 'stopRegistrationContent'
  | 'detailTpmem'
  | 'corporationInfo'
  | 'employerInfo'
  | 'bankInfo'
  | 'requiredRecruitment'
  | 'confirmSubmitContent'
  | 'confirmEmailContent'
  | 'inputMailAndPassword'
  | 'inputConfirmationCode'
  | 'complete'
  | 'completeEmail'
  | 'sendcomplete'
  | 'emailUpdate';

type InitState = {
  initPhase?: PhaseModel;
  email?: string;
  storeCode?: string;
};

export function SignUp() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const location = useLocation();
  const state = location.state as InitState;
  const initPhase = state?.initPhase || 'termsOfUsePrivacyPolicy';
  const initEmail = state?.email || '';
  const initStoreCode = state?.storeCode || '';

  const [phase, setPhase] = React.useState<PhaseModel>(initPhase);
  const [brandId, setBrandId] = React.useState<number>();
  const [tpmemId, setTpmemId] = React.useState<number>();
  const [isCorporation, setIsCorporation] = React.useState<boolean>(true);
  // 店舗新規登録用
  const [bizName, setBizName] = React.useState<string>('');
  const [brandName, setBrandName] = React.useState<string>('');
  // 確認コードを入力画面 email
  const [email, setEmail] = React.useState<string>(initEmail);
  // 店舗コード メールアドレス変更用
  const [tpCode, setTpCode] = React.useState<string>('');
  // 確認コード再送用
  const [storeCode, setStoreCode] = React.useState<string>(initStoreCode);

  const [tpmemInfo, setTpmemInfo] = React.useState<{
    bizName: string;
    brandName: string;
    tpmemName: string;
    tpmemAddress: string;
    tpmemPhone: string;
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    dob: Date;
    phone: string;
    loginId: string;
    openTime: string;
    closeTime: string;
    parking: boolean;
    image: File;
    tpmemId: number;
    invitedInput: string;
  } | null>(null);
  const [mcoInfo, setMcoInfo] = React.useState<{
    corporateNo: string;
    mcoName: string;
    mcoZipCode: string;
    mcoState: string;
    mcoCity: string;
    mcoAddress1: string;
    mcoAddress2: string;
    mcoPhone: string;
    mcoMFirstName: string;
    mcoMLastName: string;
    mcoMFirstKana: string;
    mcoMLastKana: string;
    mcoTFirstName: string;
    mcoTLastName: string;
    mcoTFirstKana: string;
    mcoTLastKana: string;
    mcoTPhone: string;
  } | null>(null);
  const [bankInfo, setBankInfo] = React.useState<{
    bankName: string;
    bankCode: string;
    branchNo: string;
    branchName: string;
    accountType: string;
    accountNo: string;
    accountName: string;
    isPersonal: boolean;
  } | null>(null);
  const [requireRecruitmentInfo, setRequireRecruitmentInfo] = React.useState<{
    socialInsurance: string;
    preventEts: string;
    inSmokingArea: boolean;
  } | null>(null);
  const [storeInfo, setStoreInfo] = React.useState<{
    bizName: string;
    brandName: string;
    store_name: string;
    zipCode1: string;
    zipCode2: string;
    prefecture: string;
    city: string;
    address1: string;
    address2: string;
    phone: string;
    mailAddress: string;
    managerName: string;
    lat: string;
    lng: string;
    comment: string;
  } | null>(null);

  // メールアドレス変更情報
  const [emailInfo, setEmailInfo] = React.useState<{
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    managerBirthday: Date;
    businessName: string;
    zipCode1: string;
    zipCode2: string;
    phone: string;
    email: string;
  } | null>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const auth = useAuth();
  const { openErrorModal } = useErrorModal();
  const [addupdTMemberCognito] = useAddupdTMemberCognitoMutation();
  const [updateTmemberActive, { error: updateTmemberActiveError }] = useUpdateTMemberActiveByIdMutation();

  useEffect(() => {
    if (updateTmemberActiveError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [updateTmemberActiveError, openErrorModal]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [phase]);

  const signUp = async (data: { email: string; password: string }) => {
    try {
      if (tpmemInfo === null || mcoInfo === null || bankInfo === null || requireRecruitmentInfo === null) {
        throw new Error('入力内容が不足しています');
      }
      setIsLoading(true);

      const filePath = `store-data/${tpmemInfo.loginId}.${tpmemInfo.image.name.split('.').pop() ?? ''}`;

      await uploadFile({
        filePath,
        file: tpmemInfo.image,
      });

      await addupdTMemberCognito({
        context: { clientName: 'api_key' },
        variables: {
          first_name: tpmemInfo.firstName,
          last_name: tpmemInfo.lastName,
          first_kana: tpmemInfo.firstKana,
          last_kana: tpmemInfo.lastKana,
          dob: format(tpmemInfo.dob, 'yyyy-MM-dd'),
          phone: tpmemInfo.phone,
          login_id: tpmemInfo.loginId,
          email: data.email,
          password: data.password,
          open_time: `${tpmemInfo.openTime}:00`,
          close_time: `${tpmemInfo.closeTime}:00`,
          parking: tpmemInfo.parking,
          image: `${process.env.REACT_APP_FILE_READ_URL || ''}/${filePath}`,
          // 事業者タイプ 1:法人 2:自営
          corporate_type: isCorporation ? 1 : 2,
          corporate_no: mcoInfo.corporateNo,
          mco_name: mcoInfo.mcoName,
          mco_zip_code: mcoInfo.mcoZipCode,
          mco_state: mcoInfo.mcoState,
          mco_city: mcoInfo.mcoCity,
          mco_address1: mcoInfo.mcoAddress1,
          mco_address2: mcoInfo.mcoAddress2,
          mco_phone: mcoInfo.mcoPhone,
          mco_m_first_name: mcoInfo.mcoMFirstName,
          mco_m_last_name: mcoInfo.mcoMLastName,
          mco_m_first_kana: mcoInfo.mcoMFirstKana,
          mco_m_last_kana: mcoInfo.mcoMLastKana,
          mco_t_first_name: mcoInfo.mcoTFirstName,
          mco_t_last_name: mcoInfo.mcoTLastName,
          mco_t_first_kana: mcoInfo.mcoTFirstKana,
          mco_t_last_kana: mcoInfo.mcoTLastKana,
          mco_t_phone: mcoInfo.mcoTPhone,
          bank_code: bankInfo.bankCode,
          branch_no: bankInfo.branchNo,
          branch_name: bankInfo.branchName,
          // 預金種目 1:普通 2:当座
          account_type: bankInfo.accountType === '普通預金' ? '1' : '2',
          account_no: bankInfo.accountNo,
          account_name: bankInfo.accountName,
          // 個人/法人銀行口座 1:個人口座 2:法人口座
          is_personal: bankInfo.isPersonal ? 1 : 2,
          invited_input: tpmemInfo.invitedInput,
          tpmem_id: tpmemInfo.tpmemId,
          social_insurance: requireRecruitmentInfo.socialInsurance,
          prevent_ets: requireRecruitmentInfo.preventEts,
          in_smoking_area: requireRecruitmentInfo.inSmokingArea,
        },
      })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            openErrorModal({ message: '登録に失敗しました' });
          } else {
            const errorCode = result.data?.addupdTMemberCognito.ErrorCode;
            if (errorCode != null) {
              if (errorCode < 0) {
                switch (errorCode) {
                  case GraphQLErrorCode.SQL_ERROR:
                    openErrorModal({ message: 'SQLエラー' });
                    break;
                  case GraphQLErrorCode.COGNITOADD_ERROR:
                    openErrorModal({ message: 'cognito登録エラー' });
                    break;
                  case GraphQLErrorCode.INSUFFICLIENT_INFOMATION_ERROR:
                    openErrorModal({ message: '責任者情報不備' });
                    break;
                  case GraphQLErrorCode.ADDRESS_REGISTERED_ERROR:
                    openErrorModal({ message: 'このメールアドレスは登録済みです' });
                    break;
                  default:
                    openErrorModal({ message: '登録に失敗しました' });
                    break;
                }
              } else {
                // 表示用Emailセット
                setEmail(data.email);
                // 店舗コード メールアドレス変更用
                setTpCode(tpmemInfo?.loginId ?? '');
                // 確認コード再送用
                setStoreCode(tpmemInfo?.loginId ?? '');

                setPhase('inputConfirmationCode');
              }
            } else {
              openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
            }
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          });
        });
    } catch {
      openErrorModal({ message: '登録に失敗しました' });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmSignUp = async (confirmationCode: string) => {
    try {
      setIsLoading(true);
      await auth.confirmSignUp(storeCode ?? '', confirmationCode);
      setPhase('complete');
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
            openErrorModal({ message: '確認コードが正しくありません。' });
            break;
          case 'ExpiredCodeException':
            openErrorModal({ message: '確認コードが期限切れです。再送してください。' });
            break;
          case 'LimitExceededException':
            openErrorModal({ message: '確認コード入力回数が上限を超えました。時間をおいて再実行してください。' });
            break;
          case 'NotAuthorizedException':
            openErrorModal({ message: '確認済みです。ログインしてください。' });
            break;
          default:
            openErrorModal({ message: '認証に失敗しました' });
            break;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const reSendConfirmationCode = async () => {
    try {
      setIsLoading(true);
      await auth.reSendConfirmationCode(storeCode);
    } catch {
      openErrorModal({ message: 'エラーが発生しました' });
    } finally {
      setIsLoading(false);
    }
  };

  const registerContent = () => {
    switch (phase) {
      case 'selectBusinessAndBrand':
        return (
          <SelectBusinessAndBrand
            toNext={(args) => {
              setBizName(args.bizName);
              setBrandName(args.brandName);
              setBrandId(Number(args.brandId));
              setPhase('selectTpmem');
            }}
          />
        );
      case 'selectTpmem':
        if (!brandId) {
          return <div>エラーが発生しました</div>;
        }
        return (
          <SelectStore
            toNext={(_tpmemId) => {
              setTpmemId(_tpmemId);
              setPhase('detailTpmem');
            }}
            toBack={() => {
              setPhase('selectBusinessAndBrand');
            }}
            toStopRegistration={(_tpmemId) => {
              setTpmemId(_tpmemId);
              // 初期化
              setStoreInfo({
                bizName,
                brandName,
                store_name: '',
                zipCode1: '',
                zipCode2: '',
                prefecture: '',
                city: '',
                address1: '',
                address2: '',
                phone: '',
                mailAddress: '',
                managerName: '',
                lat: '',
                lng: '',
                comment: '',
              });

              setPhase('stopRegistration');
            }}
            brandId={brandId}
            defaultTpmemId={tpmemId}
          />
        );
      case 'stopRegistration':
        return (
          <StopRegistration
            storeInfo={storeInfo}
            toNext={(_storeInfo) => {
              setStoreInfo(_storeInfo as typeof storeInfo);
              setPhase('stopRegistrationContent');
            }}
            toBack={() => {
              setPhase('selectBusinessAndBrand');
            }}
          />
        );
      case 'stopRegistrationContent':
        if (storeInfo === null) {
          return <div>エラーが発生しました</div>;
        }

        return (
          <StopRegistrationContent
            bizName={storeInfo.bizName}
            brandName={storeInfo.brandName}
            tpmemName={storeInfo.store_name}
            zipCode1={storeInfo.zipCode1}
            zipCode2={storeInfo.zipCode2}
            prefecture={storeInfo.prefecture}
            city={storeInfo.city}
            address1={storeInfo.address1}
            address2={storeInfo.address2}
            phone={storeInfo.phone}
            lat={storeInfo.lat}
            lng={storeInfo.lng}
            managerName={storeInfo.managerName}
            mailAddress={storeInfo.mailAddress}
            comment={storeInfo.comment}
            toNext={() => {
              setPhase('sendcomplete');
            }}
            toBack={() => {
              setPhase('stopRegistration');
            }}
          />
        );
      case 'detailTpmem':
        if (!tpmemId) {
          return <div>エラーが発生しました</div>;
        }
        return (
          <DetailTpmem
            tpmemId={tpmemId}
            toNext={(_tpmemInfo) => {
              setTpmemInfo(_tpmemInfo);
              setPhase('corporationInfo');
            }}
            toBack={() => {
              setPhase('selectTpmem');
            }}
          />
        );
      case 'corporationInfo':
        return (
          <CorporationInfo
            toNext={(_mcoInfo) => {
              setIsCorporation(true);
              setMcoInfo(_mcoInfo);
              setPhase('bankInfo');
            }}
            toBack={() => {
              setPhase('detailTpmem');
            }}
            toEmployerInfo={() => {
              setIsCorporation(false);
              setPhase('employerInfo');
            }}
          />
        );
      case 'employerInfo':
        return (
          <EmployerInfo
            toNext={(_mcoInfo) => {
              setMcoInfo({
                ..._mcoInfo,
                corporateNo: '',
                mcoMFirstName: '',
                mcoMLastName: '',
                mcoMFirstKana: '',
                mcoMLastKana: '',
                mcoTFirstName: '',
                mcoTLastName: '',
                mcoTFirstKana: '',
                mcoTLastKana: '',
                mcoTPhone: '',
              });
              setPhase('bankInfo');
            }}
            toBack={() => {
              setIsCorporation(true);
              setPhase('corporationInfo');
            }}
          />
        );

      case 'bankInfo':
        return (
          <BankInfo
            toNext={(_bankInfo) => {
              setBankInfo(_bankInfo);
              setPhase('requiredRecruitment');
            }}
            toBack={() => {
              // 前のフェーズで法人か個人のどちらを選択したかで遷移先が異なる
              if (isCorporation) {
                setPhase('corporationInfo');
                return;
              }
              setPhase('employerInfo');
            }}
          />
        );
      case 'requiredRecruitment':
        return (
          <RequiredRecruitment
            toNext={(_requireRecruitmentInfo) => {
              setRequireRecruitmentInfo(_requireRecruitmentInfo);
              setPhase('confirmSubmitContent');
            }}
            toBack={() => {
              setPhase('bankInfo');
            }}
          />
        );
      case 'confirmSubmitContent':
        if (tpmemInfo === null || mcoInfo === null || bankInfo === null || requireRecruitmentInfo === null) {
          return <div>エラーが発生しました</div>;
        }
        return (
          <ConfirmSubmitContent
            image={tpmemInfo.image}
            code={tpmemInfo.loginId}
            bizName={tpmemInfo.bizName}
            brandName={tpmemInfo.brandName}
            tpmemName={tpmemInfo.tpmemName}
            address={tpmemInfo.tpmemAddress}
            phone={tpmemInfo.tpmemPhone}
            openTime={tpmemInfo.openTime}
            closeTime={tpmemInfo.closeTime}
            hasParking={tpmemInfo.parking}
            managerFamilyName={tpmemInfo.lastName}
            managerFirstName={tpmemInfo.firstName}
            managerBirthday={tpmemInfo.dob}
            managerPhone={tpmemInfo.phone}
            isCorporation={isCorporation}
            mcoName={mcoInfo.mcoName}
            mcoZipCode={mcoInfo.mcoZipCode}
            mcoPrefecture={mcoInfo.mcoState}
            mcoCity={mcoInfo.mcoCity}
            mcoAddress1={mcoInfo.mcoAddress1}
            mcoAddress2={mcoInfo.mcoAddress2}
            corporateNumber={mcoInfo.corporateNo}
            mcoPhone={mcoInfo.mcoPhone}
            mcoFamilyName={mcoInfo.mcoMLastName}
            mcoFirstName={mcoInfo.mcoMFirstName}
            mcoFamilyNameKana={mcoInfo.mcoMLastKana}
            mcoFirstNameKana={mcoInfo.mcoMFirstKana}
            mcoManagerFamilyName={mcoInfo.mcoTLastName}
            mcoManagerFirstName={mcoInfo.mcoTFirstName}
            mcoManagerFamilyNameKana={mcoInfo.mcoTLastKana}
            mcoManagerFirstNameKana={mcoInfo.mcoTFirstKana}
            mcoManagerPhone={mcoInfo.mcoTPhone}
            inviteCode={tpmemInfo.invitedInput}
            bankName={bankInfo.bankName}
            branchName={bankInfo.branchName}
            bankType={bankInfo.accountType}
            accountNumber={bankInfo.accountNo}
            isPersonalBank={bankInfo.isPersonal}
            accountHolder={bankInfo.accountName}
            socialInsurance={requireRecruitmentInfo.socialInsurance}
            passiveSmokingMeasure={requireRecruitmentInfo.preventEts}
            isWorkingSmokingArea={requireRecruitmentInfo.inSmokingArea}
            toNext={() => {
              setPhase('inputMailAndPassword');
            }}
            toBack={() => {
              setPhase('bankInfo');
            }}
          />
        );

      case 'confirmEmailContent':
        return (
          <ConfirmEmailContent
            tpCode={tpCode}
            firstName={emailInfo?.firstName ?? ''}
            lastName={emailInfo?.lastName ?? ''}
            firstKana={emailInfo?.firstKana ?? ''}
            lastKana={emailInfo?.lastKana ?? ''}
            managerBirthday={emailInfo?.managerBirthday ?? new Date()}
            businessName={emailInfo?.businessName ?? ''}
            zipCode1={emailInfo?.zipCode1 ?? ''}
            zipCode2={emailInfo?.zipCode2 ?? ''}
            phone={emailInfo?.phone ?? ''}
            email={emailInfo?.email ?? ''}
            toNext={() => {
              setPhase('completeEmail');
            }}
            toBack={() => {
              setPhase('emailUpdate');
            }}
          />
        );

      case 'inputMailAndPassword':
        return (
          <InputMailAndPassword
            toNext={(data) => {
              signUp(data);
            }}
            toBack={() => {
              setPhase('confirmSubmitContent');
            }}
          />
        );
      case 'inputConfirmationCode':
        return (
          <InputConfirmationCode
            dspEmail={email}
            toNext={(confirmationCode) => {
              confirmSignUp(confirmationCode);
              if (tpmemInfo?.tpmemId) {
                updateTmemberActive({ context: { clientName: 'api_key' }, variables: { id: tpmemInfo?.tpmemId } });
              }
            }}
            reSendConfirmationCode={reSendConfirmationCode}
            toEmail={() => {
              // 初期化
              setEmailInfo({
                firstName: '',
                lastName: '',
                firstKana: '',
                lastKana: '',
                managerBirthday: new Date(),
                businessName: '',
                zipCode1: '',
                zipCode2: '',
                phone: '',
                email: '',
              });
              setPhase('emailUpdate');
            }}
          />
        );
      case 'complete':
        return <Complete tpmemId={storeCode || ''} />;
      case 'completeEmail':
        return <CompleteEmail />;
      case 'sendcomplete':
        return <SendComplete tpmemId={tpmemInfo?.loginId || ''} />;
      case 'emailUpdate':
        return (
          <EmailUpdate
            tpcode={tpCode}
            emailInfo={emailInfo}
            toNext={(_emailInfo) => {
              setEmailInfo(_emailInfo as typeof emailInfo);
              setPhase('confirmEmailContent');
            }}
            toBack={() => {
              setPhase('inputConfirmationCode');
            }}
          />
        );

      default:
        return null;
    }
  };

  if (phase === 'termsOfUsePrivacyPolicy') {
    return <TermsOfUsePrivacyPolicy toNext={() => setPhase('selectBusinessAndBrand')} />;
  }

  return (
    <>
      {isLoading && <Loading />}
      {/*  feat_screen_01_00_start */}
      {!isMobile ? (
      <Box
        width="100%"
        py={40}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={32}
      >
        <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
          <img src="/bitravel_logo.png" alt="logo" width={104} />
          <Box>
            <Text variant="body14" bold color="darkBlue">
              加盟店管理
            </Text>
          </Box>
        </Box>
        {registerContent()}
      </Box>
      ) : (
          // Responsive components
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" px={16}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              pt={["selectBusinessAndBrand","termsOfUsePrivacyPolicy" ].includes(phase)  ? 121 : 24}
            >
              <img src="/bitravel_logo.png" alt="logo" width={204} />
              <Box pt={26} pb={24}>
                <Text variant="body16" bold color="darkBlue">
                  加盟店管理
                </Text>
              </Box>
            </Box>
            {registerContent()}
          </Box>
        )}
      {/*  feat_screen_01_00_end */}
    </>
  );
}
