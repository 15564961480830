import React, { useRef, useEffect } from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { Modal } from 'components/newModal';
import Webcam from "react-webcam";
import useIsMobile from 'hooks/responsive/useIsMobile';

interface Props {
  isOpen: boolean;
  recognizeFace: (cameraImg: string | null | undefined) => Promise<boolean>;
  onSucceed: () => void;
  onFail: () => void;
  onClose: () => void;
  onClickManual: () => void;
}

/* feat_screen_04-02-02-03_start */
type ModalPropType = {
  /* feat_screen_03-01-09_start */
  height?: number | string;
  width?: number | string;
  /* feat_screen_03-01-09_end */
  header?: JSX.Element;
  content?: JSX.Element;
  footer?: JSX.Element;
};
/* feat_screen_04-02-02-03_end */

// 顔認証タイマー間隔(秒) デフォルトで3秒
const TimerInterval = process.env.REACT_APP_REKOGNITION_TIMER || "3";
// リトライ回数 デフォルトで3回
const RetryCount = process.env.REACT_APP_REKOGNITION_RETRY_COUNT || "3";

function CameraModal(props: Props) {
  const { isOpen, recognizeFace, onSucceed, onFail, onClose, onClickManual } = props;
  
  // WEBカメラ
  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    if (!isOpen) return() => {};
    
    // 処理カウンタ
    let count = 1;

    // 処理中フラグ 処理中であれば顔認証処理を行わない
    let isProcess = false;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const timer = setInterval(async () => {
      // 処理中であれば何もしない
      if (isProcess) return;

      // 処理中フラグを立てる
      isProcess = true;

      // WEBカメラキャプチャ画像取得
      const cameraImage = webcamRef?.current?.getScreenshot();

      // 顔認証処理
      const result = await recognizeFace(cameraImage);

      if (result === true) onSucceed();
      
      count += 1;

      // リトライ回数を超えたら失敗とする
      if (count > parseInt(RetryCount, 10)) onFail();

      // 処理中フラグを下ろす
      isProcess = false;
    },  parseInt(TimerInterval, 10) * 1000);

    // クリーンアップ
    // タイマをクリアする
    return () => clearInterval(timer);
  }, [isOpen, recognizeFace, onSucceed, onFail]);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent:ModalPropType = {
    header: (
      <Text variant="h2" color="darkBlue">
        顔認証
      </Text>
    ),
    content: !isMobile ? (
      <Box>
        <Text variant="body14">顔の全体が映るようカメラを覗き込んでください。</Text>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pa={24}>
          <Webcam
            audio={false}
            width={540}
            height={405}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 540,
              height: 405,
              facingMode: "user"
            }}
          />
        </Box>
      </Box>
    ) : (
      /* feat_screen_04-02-02-03_start */
      <Box>
        <Text variant="body14">顔の全体が映るようカメラを覗き込んでください。</Text>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Webcam
            audio={false}
            width="100%"
            height="256"
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 312,
              height: 256,
              facingMode: "user"
            }}
          />
        </Box>
      </Box>
      /* feat_screen_04-02-02-03_end */
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onClickManual()}>
          手動認証
        </MainButton>
      </Box>
    ) : (
      /* feat_screen_04-02-02-03_start */
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
        <MainButton width={96} customHeight={32} variant="secondary" thin onClick={() => onClickManual()}>
          手動認証
        </MainButton>
      </Box>
      /* feat_screen_04-02-02-03_end */      
    ),
    /* feat_screen_04-02-02-03_start */
    width: !isMobile ? 1003 : "100%",
    height: !isMobile ? 640 : "unset",
    /* feat_screen_04-02-02-03_end */
  };

  return (
    <Modal
      header={modalContent.header}
      width={modalContent.width}
      height={modalContent.height}
      content={modalContent.content}
      footer={modalContent.footer}
      isOpen={isOpen}
      onClose={() => {}}
    />
  );
}

export default CameraModal;
