import React, { ReactNode, useMemo } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { LimitChanger } from './limitChanger';
import { Pagination } from './pagination';

export interface Props {
  children: ReactNode;
  limit: 10 | 20 | 30;
  onChangeLimit: (value: 10 | 20 | 30) => void;
  page: number;
  dataSize: number;
  onChangePage: (page: number) => void;
  // feat screen 04-01-01-01 start
  checkList?: number[];
  onClickDelete?: () => void;
  // feat screen 04-01-01-01 end
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledLimitWrapper = styled.div`
  height: 42px;
  padding: 16px 0;
  width: 100%;
  box-sizing: border-box;
`;

const StyledChildrenWrapper = styled.div`
  flex: auto;
  overflow: auto;
  height: 100%;
`;

const StyledPaginationWrapper = styled.div`
  /* feat screen 04-01-01-01 start */
  position: relative;
  /* feat screen 04-01-01-01 end */
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* feat_common_responsive_sidebar_start */
  @media only screen and (max-width: 768px) {
    height: 33px;
    justify-content: space-between;
    padding: 0 8px;
  }
  /* feat_common_responsive_sidebar_end */
`;

// feat screen 04-01-01-01 start
const StyledFooterDelete = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 47px;
  background-color: #fff;
  bottom: 46px;
  left: 0;
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  z-index: 4;
  @media only screen and (max-width: 768px) {
    bottom: 33px;
  }
`;
// feat screen 04-01-01-01 end

export function PaginationBox({
  children,
  limit,
  onChangeLimit,
  page,
  dataSize,
  onChangePage,
  checkList,
  onClickDelete,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const totalPage = useMemo(() => Math.ceil(dataSize / limit), [dataSize, limit]);
  return !isMobile ? (
    <StyledWrapper>
      <StyledLimitWrapper>
        <LimitChanger limit={limit} onClick={onChangeLimit} />
      </StyledLimitWrapper>
      <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      <StyledPaginationWrapper>
        {/* feat screen 04-01-01-01 start */}
        {!!checkList?.length && (
          <StyledFooterDelete>
            <Box ml={16}>
              <Text variant="body14">{checkList?.length} 件選択</Text>
            </Box>
            <Box mr={16}>
              <MainButton
                width={80}
                variant="warnSecondary"
                iconColor="red"
                iconSize={20}
                icon="trash"
                onClick={onClickDelete}
              >
                削除
              </MainButton>
            </Box>
          </StyledFooterDelete>
        )}
        {/* feat screen 04-01-01-01 end */}
        <Pagination page={page} totalPage={totalPage} dataSize={dataSize} onChange={onChangePage} />
      </StyledPaginationWrapper>
    </StyledWrapper>
  ) : (
    /* feat_common_responsive_pagination_box_start */
    <StyledWrapper>
      <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      <StyledPaginationWrapper>
        {!!checkList?.length && (
          <StyledFooterDelete>
            <Box ml={16}>
              <Text variant="body14">{checkList?.length} 件選択</Text>
            </Box>
            <Box mr={16}>
              <MainButton
                width={80}
                variant="warnSecondary"
                iconColor="red"
                iconSize={20}
                icon="trash"
                onClick={onClickDelete}
              >
                削除
              </MainButton>
            </Box>
          </StyledFooterDelete>
        )}
        <LimitChanger limit={limit} onClick={onChangeLimit} />
        <Pagination page={page} totalPage={totalPage} dataSize={dataSize} onChange={onChangePage} />
      </StyledPaginationWrapper>
    </StyledWrapper>
    /* feat_common_responsive_pagination_box_end */
  );
}
