/**
 * Figma ID: 08-07-07
 * 名称: 店舗ブロック
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function CompleteContent() {
  return (
    <Box width="100%">
      <Text variant="body14">店舗ブロック登録が完了しました。</Text>
    </Box>
  );
}

type CompleteFooterModel = {
  close: () => void;
};
export function CompleteFooter(props: CompleteFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { close } = props;
  return (
    <Box display="flex" gap={5} justifyContent="flex-end">
      {/* feat screem 08-07-07 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        閉じる
      </MainButton>
      {/* feat screem 08-07-07 end */}
    </Box>
  );
}
