import React from 'react';

import { useGetTMemberByIdQuery } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';

import CancelMembershipScreen from './screens/cancelMembership/CancelMembershipScreen';
import AlreadyCanceledScreen from './screens/cancelMembership/AlreadyCanceledScreen';

/**
 * Figma ID
 * 09-05-01
 * 退会
 */

function CancelMembership() {
  const { tpmemId: loginId } = useAuth();
  const { data, refetch, loading } = useGetTMemberByIdQuery({
    variables: {
      id: loginId || 0,
    },
  });

  const userAlreadyQuit = loginId && data ? data.getTMemberById.req_quit_date !== null : false;

  if (loading) {
    return <Loading />;
  }

  if (userAlreadyQuit) {
    return (
      <AlreadyCanceledScreen
        requestedQuitDate={data?.getTMemberById.req_quit_date || ''}
        expectedQuitDate={data?.getTMemberById.quit_date || ''}
      />
    );
  }

  return (
    <CancelMembershipScreen
      refetch={() => {
        refetch();
      }}
    />
  );
}

export default CancelMembership;
