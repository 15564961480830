/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { BelongTo, Sub } from 'pages/s04-02/components/const';
import { Info } from 'components/info';
import { VtBtmemBase } from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { AgreementStatus } from 'components/const';
import { identificationLabel } from 'components/labelList/identificationLabel';
import Divider from 'components/divider/divider';
import useIsMobile from 'hooks/responsive/useIsMobile';

function convertYesNoToLabel(param?: BelongTo | Sub | null) {
  switch (param) {
    case BelongTo.YES:
    case Sub.YES:
      return '有';
    case BelongTo.NO:
    case Sub.NO:
      return '無';
    default:
      return '';
  }
}

const statusToHidePrivateInfo = [AgreementStatus.COMPLETE, AgreementStatus.CANCEL, AgreementStatus.NO_SHOW];

function BasicInfo(props: {
  workingAgreementStatus: AgreementStatus;
  info: VtBtmemBase;
  /* feat_screen_04-02-01-03_start */
  isHasDivider: boolean;
   /* feat_screen_04-02-01-03_end */
}) {
  const {
    workingAgreementStatus,
    info,
    /* feat_screen_04-02-01-03_start */
    isHasDivider
    /* feat_screen_04-02-01-03_end */
  } = props;

  const nameItems = [
    { label: '氏名', content: info.name || '' },
    { label: '氏名（フリガナ）', content: info.kana || '' },
  ];

  const btmemIdItem = [{ label: '会員番号', content: info.btmem_id.toString().padStart(10, '0') }];
  const phoneItem = [{ label: '電話番号', content: info.phone || '' }];
  const stateCountItem = [{ label: '達成都道府県', content: info.state_count?.toString() || '' }];
  const idItem = [{ label: '本人確認', content: identificationLabel(info.identification ?? 0, true) }];

  const ageRankScoreItem = [
    { label: '年齢', content: info.age?.toString() || '' },
    { label: 'ランク', content: info.rank_name || '' },
    { label: 'スコア', content: info.score?.toString() || '' },
  ];
  const detailItems = [
    { label: 'バイト回数', content: `${String(info.worked_count)} ${info.badge_name || ''}` },
    { label: '住所', content: `${info.state || ''}${info.city || ''}${info.address || ''}${info.address2 || ''}` },
    { label: `所属店舗(${info.biz_name || ''})`, content: convertYesNoToLabel(info.belong) },
    { label: `サブ店舗(${info.biz_name || ''})`, content: convertYesNoToLabel(info.sub) },
    { label: '性別', content: info.gender || '' },
    { label: '保有資格', content: info.general_licenses || '' },
    { label: `${info.biz_name || ''}資格`, content: info.biz_license || '' },
    { label: '制服サイズ', content: info.uniform_size || '' },
    { label: '遅刻率', content: `${String(info.late_count)}回/${String(info.late_per)}%` },
    { label: 'キャンセル率', content: `${String(info.cancel_count)}回/${String(info.cancel_per)}%` },
    { label: 'ドタキャン率', content: `${String(info.imminent_count)}回/${String(info.imminent_per)}%` },
  ];

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box>
      <Info
        items={
          statusToHidePrivateInfo.includes(workingAgreementStatus)
            ? btmemIdItem.concat(ageRankScoreItem).concat(detailItems)
            : nameItems
                .concat(btmemIdItem)
                .concat(phoneItem)
                .concat(ageRankScoreItem)
                .concat(stateCountItem)
                .concat(detailItems)
                .concat(idItem)
        }
      />
    </Box>
  ): (
    /* feat_screen_04-02-01-03_start */
    <Box mt={isHasDivider ? 16 : 0}>
      {
        isHasDivider && (<Divider option="horizontal"/>)
      }
      <Info
        items={
          statusToHidePrivateInfo.includes(workingAgreementStatus)
            ? btmemIdItem.concat(ageRankScoreItem).concat(detailItems)
            : nameItems
                .concat(btmemIdItem)
                .concat(phoneItem)
                .concat(ageRankScoreItem)
                .concat(stateCountItem)
                .concat(detailItems)
                .concat(idItem)
        }
      />
    </Box>
    /* feat_screen_04-02-01-03_end */
  );
}

export default BasicInfo;
