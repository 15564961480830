import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { Modal } from 'components/newModal';
import useIsMobile from 'hooks/responsive/useIsMobile';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function CompleteModal(props: Props) {
  const { isOpen, onClose } = props;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        編集 完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14">店舗情報の編集が完了しました。</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton
          // feat_screen_09-01-05_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_09-01-05_end
          variant="secondary" 
          onClick={() => onClose()}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    // feat_screen_09-01-05_start
    width: !isMobile ? 384 : "100%",
    // feat_screen_09-01-05_end
    height: 'auto',
  };

  return (
    <Modal
      header={modalContent.header}
      width={modalContent.width}
      height={modalContent.height}
      content={modalContent.content}
      footer={modalContent.footer}
      isOpen={isOpen}
      onClose={() => {}}
    />
  );
}

export default CompleteModal;
