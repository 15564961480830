import { Box } from 'components/box';
import { Tab } from 'components/newTab';
import React from 'react';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider/divider';
import { QrCode, QrCodeType } from 'qrCodeComponent/qrCode';
import { useAuth } from 'hooks/authProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';

function Content(props: { checkin?: boolean; checkout?: boolean; brandId: number; tpmemId: number }) {
  const { checkin = false, checkout = false, brandId, tpmemId } = props;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box display="flex" flex="auto" flexDirection="column" height="calc(100% - 40px)">
      <Box display="flex" flexDirection="row" height={60} gap={8} alignItems="center">
        <Text color="darkBlue" variant="h2">
          {checkin && 'チェックイン'}
          {checkout && 'チェックアウト'}
        </Text>
        <Text color="blueGray" variant="caption12">
          {checkin && 'バイトラベラーのチェックインに使用する二次元バーコードです。'}
          {checkout && 'バイトラベラーのチェックアウトに使用する二次元バーコードです。'}
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box display="flex" flex="1" alignItems="center">
        <Box display="flex" flex="auto" flexDirection="column" alignItems="center" justifyContent="center">
          <QrCode
            type={checkin ? QrCodeType.CHECKIN : QrCodeType.CHECKOUT}
            brandId={brandId}
            tpmemId={tpmemId}
            size={300}
          />
          <Text color="darkBlue" variant="caption12">
            {checkin && 'チェックイン用二次元バーコード'}
            {checkout && 'チェックアウト用二次元バーコード'}
          </Text>
        </Box>
      </Box>
    </Box>
  ) : (
    // feat_screen_09-06-01_02_start
    <Box display="flex" flex="auto" flexDirection="column" gap={16}>
      <Box pt={10}>
        <Divider option="horizontal" /> 
      </Box>
      <Box display="flex" flexDirection="column" height={60} gap={8} alignItems="center">
        <Text color="darkBlue" variant="h2">
          {checkin && 'チェックイン'}
          {checkout && 'チェックアウト'}
        </Text>

        <Text align="center" color="blueGray" variant="caption12">
          {checkin && 'バイトラベラーのチェックインに使用する二次元バーコードです。'}
          {checkout && 'バイトラベラーのチェックアウトに使用する二次元バーコードです。'}
        </Text>
      </Box>
      <Box pt={8}>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" flex="1" alignItems="center">
        <Box display="flex" flex="auto" flexDirection="column" alignItems="center" justifyContent="center" gap={16}>
          <QrCode
            type={checkin ? QrCodeType.CHECKIN : QrCodeType.CHECKOUT}
            brandId={brandId}
            tpmemId={tpmemId}
            size={124}
          />
          <Text color="darkBlue" variant="caption12">
            {checkin && 'チェックイン用二次元バーコード'}
            {checkout && 'チェックアウト用二次元バーコード'}
          </Text>
        </Box>
      </Box>
    </Box>
    // feat_screen_09-06-01_02_end
  );
}

export default function Barcode() {
  const auth = useAuth();
  const { tpmemId, storeInfo } = auth;
  const brandId = storeInfo?.brandId ?? 0;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box display="flex" flex="auto" px={24} pt={16}>
      <Tab
        items={[
          {
            label: 'チェックイン',
            content: <Content checkin brandId={brandId} tpmemId={tpmemId ?? 0} />,
          },
          {
            label: 'チェックアウト',
            content: <Content checkout brandId={brandId} tpmemId={tpmemId ?? 0} />,
          },
        ]}
      />
    </Box>
  ) : (
    // feat_screen_09-06-01_start
    <Box display="flex" flex="auto" px={16} pt={12}  height="calc(100% - 64px)" overflow="auto">
      <Tab
        items={[
          {
            label: 'チェックイン',
            content: <Content checkin brandId={brandId} tpmemId={tpmemId ?? 0} />,
          },
          {
            label: 'チェックアウト',
            content: <Content checkout brandId={brandId} tpmemId={tpmemId ?? 0} />,
          },
        ]}
      />
    </Box>
    // feat_screen_09-06-01_end
  );
}
