import React, { useState } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider/divider';
import { MainButton } from 'components/mainButton';
import {
  useAddBArbeitScoreMutation,
  useGetVWorkScoreInfoWorkingByWorkIdMutation,
  VWorkScoreInfo,
} from 'graphql/graphql-mw';
import { AgreementStatus } from 'components/const';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import ReviewModal from './modal/ReviewModal';
import { ReviewsModel } from './types';
import { scoreToStars } from './common';
import { RecruitType } from '../../components/const';
/**
 * Figma ID: 04-02-07-03,04-02-07-04,04-02-07-08
 * 名称: レビュー
 */

function Review(props: { data?: ReviewsModel; status?: AgreementStatus | null; type?: number | null }) {
  const { bizId, btmemId, tpmemId, workId } = props?.data || {};
  const { status, type } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */  

  const [addBArbeitScore, { loading: addBArbeitScoreLoading }] = useAddBArbeitScoreMutation({});

  function submitReview(review: { score?: number; comment: string }) {
    if (
      typeof bizId !== 'number' ||
      typeof btmemId !== 'number' ||
      typeof tpmemId !== 'number' ||
      typeof workId !== 'number'
    ) {
      return Promise.reject();
    }
    return addBArbeitScore({
      variables: {
        biz_id: bizId,
        btmem_id: btmemId,
        comment: review.comment,
        score: review.score || 0,
        tpmem_id: tpmemId,
        work_id: workId,
      },
    });
  }
  function convertVWorkInfoToReview(data?: VWorkScoreInfo | null): ReviewsModel {
    return {
      from: {
        score: data?.tp_score || 0, // 店舗のスコア
        comment: data?.tp_comment || '', // 店舗へのコメント
        date: data?.bt_post_day || '', // バイトラベラーが投稿した日
        wantsToWorkAgain: data?.again || false,
      },
      to: {
        score: data?.bt_score || 0, // バイトラベラーのスコア
        comment: data?.bt_comment || '', // バイトラベラーへのコメント
        date: data?.tp_post_day || '', // 店舗が投稿した日
      },
    };
  }
  // レビュー情報
  const [getScoreInfoMutation, { data: workScoreInfoData, loading: workScoreInfoLoading }] =
    useGetVWorkScoreInfoWorkingByWorkIdMutation({
      variables: {
        work_id: Number(workId),
      },
      notifyOnNetworkStatusChange: true,
    });

  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (!workId) return;
    // レビュー情報
    getScoreInfoMutation({
      variables: {
        work_id: Number(workId),
      },
    })
      .then((result) => {
        if (result.errors) {
          openErrorModal({ message: 'レビュー情報が取得できませんでした。' });
        }
      })
      .catch(() => {
        openErrorModal({
          message: 'レビュー情報が取得できませんでした。',
        });
      });
  }, [getScoreInfoMutation, workId, openErrorModal]);
  // レビュー情報
  const reviewData = convertVWorkInfoToReview(workScoreInfoData?.getVWorkScoreInfoWorkingByWorkId.score);
  const { from, to } = reviewData || { from: undefined, to: undefined };

  const isRevireButton = status === AgreementStatus.COMPLETE && !to?.date && type === RecruitType.HIRED;

  const loading = workScoreInfoLoading || addBArbeitScoreLoading;


    return !isMobile ? (
        <>
            {loading && <Loading/>}
            <Box pa={18} width="100%">
                <Box display="flex" alignItems="center" mb={16}>
                    <Text variant="h2" color="darkBlue">
                        レビュー
                    </Text>
                </Box>
                <Divider option="horizontal"/>
                <Box display="flex" height="100%">
                    <Box display="flex" flexDirection="column" width="50%" mt={16}>
                        <Box mb={16}>
                            <Text variant="body14" bold color="darkBlue">
                                バイトラベラーからのレビュー
                            </Text>
                        </Box>
                        {from?.date ? (
                            <>
                                <Box mb={8}>
                                    <Text variant="caption12">{from.date}</Text>
                                </Box>
                                <Box>{scoreToStars(from.score)}</Box>
                                <Box mb={8}>
                                    <Text variant="body14">{from.comment}</Text>
                                </Box>
                                {from.wantsToWorkAgain && (
                                    <Box mb={8}>
                                        <Text variant="body14" color="blueGray" bold>
                                            またバイトしたいと言っています。
                                        </Text>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Box mb={8}>
                                <Text variant="body14">まだレビューされていません。</Text>
                            </Box>
                        )}
                    </Box>
                    <Box px={16} pt={16} pb={32}>
                        <Divider option="vertical"/>
                    </Box>
                    <Box display="flex" flexDirection="column" width="50%" mt={16}>
                        <Box mb={16} display="flex" alignItems="center" gap={32}>
                            <Text variant="body14" bold color="darkBlue">
                                バイトラベラーをレビューする
                            </Text>
                            <MainButton width={160} icon="edit" disabled={!isRevireButton}
                                        onClick={() => setIsModalOpen(true)}>
                                レビューを書く
                            </MainButton>
                        </Box>
                        {to?.date ? (
                            <>
                                <Box mb={8}>
                                    <Text variant="caption12">{to.date}</Text>
                                </Box>
                                <Box>{scoreToStars(to.score)}</Box>
                                <Box mb={8}>
                                    <Text variant="body14">{to.comment}</Text>
                                </Box>
                            </>
                        ) : (
                            <Box mb={8}>
                                <Text variant="body14">まだレビューしていません。</Text>
                            </Box>
                        )}
                    </Box>
                </Box>
                <ReviewModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={(review) => {
                        const promise = submitReview(review);
                        if (promise === undefined) {
                            return Promise.resolve(false);
                        }
                        return promise
                            .then((result) => {
                                const resultRows = result.data?.addBArbeitScore.resultRows;
                                if (typeof resultRows === 'number' && resultRows > 0) {
                                    return true;
                                }
                                return false;
                            })
                            .catch((error) => {
                                console.error('Error occurred:', error);
                                return false; // Approエラー
                            });
                    }}
                    refetch={() => {
                        getScoreInfoMutation();
                    }}
                />
            </Box>
        </>
    ) : (
        /* feat_screen_04-02-01-10_start */
        <>
            {loading && <Loading/>}
            <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 52px)" gap={16} pt={20} px={16}
                 overflow="auto">
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Text variant="h2" color="darkBlue">
                        レビュー
                    </Text>
                </Box>
                <Divider option="horizontal"/>
                <Box display="flex" height="100%" flexDirection="column" gap={16}>
                    <Box display="flex" flexDirection="column" width="100%" gap={16}>
                        <Box>
                            <Box>
                                <Text variant="body14" bold color="darkBlue">
                                    バイトラベラーからのレビュー
                                </Text>
                            </Box>
                            {from?.date ? (
                                <>
                                    <Box mt={8}>
                                        <Text variant="caption12">{from.date}</Text>
                                    </Box>
                                    <Box>{scoreToStars(from.score, isMobile)}</Box>
                                    <Box>
                                        <Text variant="body14">{from.comment}</Text>
                                    </Box>
                                    {from.wantsToWorkAgain && (
                                        <Box>
                                            <Text variant="body14" color="blueGray" bold>
                                                またバイトしたいと言っています。
                                            </Text>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Box mt={2}>
                                    <Text variant="body14">まだレビューされていません。</Text>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Divider option="horizontal"/>
                    <Box display="flex" flexDirection="column" width="100%" gap={16}>
                        <Box>
                            <Box display="flex" flexDirection="column" width="100%" gap={4} mb={8}>
                                <Text variant="body14" bold color="darkBlue">
                                    バイトラベラーをレビューする
                                </Text>
                                <MainButton width={160} icon="edit" disabled={!isRevireButton}
                                            onClick={() => setIsModalOpen(true)}>
                                    レビューを書く
                                </MainButton>
                            </Box>
                            {to?.date ? (
                                <>
                                    <Box mt={8}>
                                        <Text variant="caption12">{to.date}</Text>
                                    </Box>
                                    <Box>{scoreToStars(to.score, isMobile)}</Box>
                                    <Box mb={8}>
                                        <Text variant="body14">{to.comment}</Text>
                                    </Box>
                                </>
                            ) : (
                                <Box mb={8}>
                                    <Text variant="body14">まだレビューしていません。</Text>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <ReviewModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={(review) => {
                        const promise = submitReview(review);
                        if (promise === undefined) {
                            return Promise.resolve(false);
                        }
                        return promise
                            .then((result) => {
                                const resultRows = result.data?.addBArbeitScore.resultRows;
                                if (typeof resultRows === 'number' && resultRows > 0) {
                                    return true;
                                }
                                return false;
                            })
                            .catch((error) => {
                                console.error('Error occurred:', error);
                                return false; // Approエラー
                            });
                    }}
                    refetch={() => {
                        getScoreInfoMutation();
                    }}
                />
            </Box>
        </>
        /* feat_screen_04-02-01-10_end */
    );
}

export default Review;
