import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { useAuth } from 'hooks/authProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetVtBizBtmemCancelInfoByTpmemIdBtmemIdQuery } from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';

export function PenaltyRate(props: {
  /* feat_screen_05-01-07-01_add_props_start */ 
  blogFlag?: boolean;
  /* feat_screen_05-01-07-01_add_props_end */
}) {
  /* feat_screen_05-01-07-01_add_props_start */ 
  const { blogFlag } = props;
  /* feat_screen_05-01-07-01_add_props_end */ 

  const params = useParams<{ id: string }>();
  const id = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;
  const { openErrorModal } = useErrorModal();

  const {
    data: cancelInfoData,
    loading: cancelInfoLoading,
    error: cancelInfoError,
  } = useGetVtBizBtmemCancelInfoByTpmemIdBtmemIdQuery({
    variables: {
      tpmem_id: tpmemId,
      btmem_id: id,
    },
  });
  React.useEffect(() => {
    if (cancelInfoError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [cancelInfoError, openErrorModal]);

  const cancelInfo = cancelInfoData?.getVTBizBtmemCancelInfoByTpmemIdBtmemId.cancel;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box px={16} display="flex" flex="1" flexDirection="column">
      {cancelInfoLoading && <Loading />}
      <Box display="flex" flexDirection="row" py={18} justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          ペナルティ率
        </Text>
        <Box display="flex" flexDirection="row" gap={8} alignItems="center">
          <Text variant="body14" color="darkBlue">
            ペナルティポイント
          </Text>
          <Text variant="h2" color="darkBlue">
            {cancelInfo?.penalty || 0}ポイント
          </Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box display="flex" flexDirection="column" flex="1">
        <ScrollWrapper bottom={252}>
          <Info
            items={[
              {
                label: '遅刻率',
                content: `${cancelInfo?.late_count?.toString() || ''}回 / ${cancelInfo?.late_per?.toString() || ''}%`,
              },
              {
                label: 'キャンセル率',
                content: `${cancelInfo?.cancel_count?.toString() || ''}回 / ${
                  cancelInfo?.cancel_per?.toString() || ''
                }%`,
              },
              {
                label: 'ドタキャン率',
                content: `${cancelInfo?.imminent_count?.toString() || ''}回 / ${
                  cancelInfo?.imminent_per?.toString() || ''
                }%`,
              },
              {
                label: 'バックレ回数',
                content: `${cancelInfo?.skip_count?.toString() || ''}回`,
              },
            ]}
          />
          <Divider option="horizontal" />
        </ScrollWrapper>
      </Box>
    </Box>
  ) : (
    // feat_screen_05-01-07-01_start
    <Box px={16} display="flex" flex="1" flexDirection="column" width="calc(100vw - 32px)" height={`calc(100svh - ${blogFlag ? '413px' : '380px'})`} overflow="auto">
      {cancelInfoLoading && <Loading />}
      <Box display="flex" flexDirection="column" pt={18} pb={10}>
        <Box display="flex" justifyContent="center">
          <Text variant="h2" color="darkBlue">
            ペナルティ率
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" gap={8} alignItems="center" justifyContent="center">
          <Text variant="body14" color="darkBlue">
            ペナルティポイント
          </Text>
          <Text variant="h2" color="darkBlue">
            {cancelInfo?.penalty || 0}ポイント
          </Text>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box display="flex" flexDirection="column" flex="1">
          <Info
            items={[
              {
                label: '遅刻率',
                content: `${cancelInfo?.late_count?.toString() || ''}回 / ${cancelInfo?.late_per?.toString() || ''}%`,
              },
              {
                label: 'キャンセル率',
                content: `${cancelInfo?.cancel_count?.toString() || ''}回 / ${
                  cancelInfo?.cancel_per?.toString() || ''
                }%`,
              },
              {
                label: 'ドタキャン率',
                content: `${cancelInfo?.imminent_count?.toString() || ''}回 / ${
                  cancelInfo?.imminent_per?.toString() || ''
                }%`,
              },
              {
                label: 'バックレ回数',
                content: `${cancelInfo?.skip_count?.toString() || ''}回`,
              },
            ]}
          />
          <Divider option="horizontal" />
      </Box>
    </Box>
    // feat_screen_05-01-07-01_end
  );
}
