import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { Modal } from 'components/newModal';
import * as gql from 'graphql/graphql-mw';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ScrollableArea } from 'components/scrollableArea';

interface Props {
  isOpen: boolean;
  tpmemId: number;
  onClose: () => void;
}

function TrialTicketModal(props: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, tpmemId, onClose } = props;

  const [
    getVtTicketListByTpmemId,
    {
      data: { getVTTicketListByTpmemId } = {
        getVTTicketListByTpmemId: [] as gql.VtTicketList[],
      },
      loading: getVTTicketListByTpmemIdLoading,
      error: getVTTicketListByTpmemIdError,
    },
  ] = gql.useGetVtTicketListByTpmemIdLazyQuery({
    variables: { tpmem_id: tpmemId },
    fetchPolicy: 'no-cache',
  });
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (tpmemId === undefined || tpmemId === null || tpmemId === 0) {
      return;
    }
    getVtTicketListByTpmemId({ variables: { tpmem_id: tpmemId } });
  }, [getVtTicketListByTpmemId, tpmemId]);

  React.useEffect(() => {
    if (getVTTicketListByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVTTicketListByTpmemIdError, openErrorModal]);

  const itemMax = 27;
  const ItemName = (item: string | undefined | null) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.length > itemMax ? `${item.slice(0, itemMax)}…` : item;
  };

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        保有トライアルチケット一覧
      </Text>
    ),
    // feat screen 09-01-05 start
    content: !isMobile ? (
      <ScrollableArea>
        <Divider option="horizontal" />

        {getVTTicketListByTpmemIdLoading && <Loading />}
        <Box>
          {getVTTicketListByTpmemId?.map((val) => (
            <React.Fragment key={val?.item}>
              <Box display="flex" justifyContent="space-between" alignItems="center" height={37}>
                <Box display="flex" alignItems="center" gap={8}>
                  <Box display="flex" flexDirection="row" gap={8}>
                    <Box width={342}>
                      <Text variant="caption12" bold color="darkBlue">
                        {ItemName(val?.item)}
                      </Text>
                    </Box>
                    <Box width={97}>
                      <Text variant="caption12" color="liteGray">
                        {val?.award_date}
                      </Text>
                    </Box>
                  </Box>
                  <Divider option="vertical" length={21} />
                  <Box width={72} display="flex" flexDirection="row" gap={8} alignItems="center">
                    <Text variant="caption12" color="blueGray">
                      枚数
                    </Text>
                    <Text variant="body14" color="black">
                      {val?.enabled_count}
                    </Text>
                  </Box>
                  <Divider option="vertical" length={21} />
                  <Box width={183} display="flex" flexDirection="row" gap={8} alignItems="center">
                    <Text variant="caption12" color="blueGray">
                      有効期限
                    </Text>
                    <Text variant="body14" color="black">
                      {val?.end_date}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Divider option="horizontal" />
            </React.Fragment>
          ))}
        </Box>
      </ScrollableArea>
    ) : (
      // Responsive Component
      <Box overflow="auto" height="100%">
        <Divider option="horizontal" />

        {getVTTicketListByTpmemIdLoading && <Loading />}
        <Box pt={16}>
          {getVTTicketListByTpmemId?.map((val) => (
            <React.Fragment key={val?.item}>
              <Box display="flex" justifyContent="space-between" alignItems="center" pb={15}>
                <Box display="flex" flexDirection="column" gap={10} width="100%">
                  <Box display="block">
                    <Text variant="body14" bold color="darkBlue">
                      {ItemName(val?.item)}
                    </Text>
                  </Box>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Text variant="body14" color="liteGray">
                      {val?.award_date}
                    </Text>
                    <Divider option="vertical" length={21} />
                    <Box display="flex" flexDirection="column" alignItems="center" px={8}>
                      <Text variant="body14" color="blueGray">
                        枚数
                      </Text>
                      <Text variant="body14" color="black">
                        {val?.enabled_count}
                      </Text>
                    </Box>
                    <Divider option="vertical" length={21} />
                    <Box display="flex" flexDirection="column"  alignItems="center">
                      <Text variant="body14" color="blueGray">
                        有効期限
                      </Text>
                      <Text variant="body14" color="black">
                        {val?.end_date}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider option="horizontal" />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end">
        <MainButton width={!isMobile ? 104 : 96}  variant="secondary" thin={!isMobile} onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: !isMobile ? 800 : "100%",
    height: !isMobile ? 400 : "31.5svh",
    // feat screen 09-01-05 end
  };

  return (
    <Modal
      header={modalContent.header}
      width={modalContent.width}
      height={modalContent.height}
      content={modalContent.content}
      footer={modalContent.footer}
      isOpen={isOpen}
      onClose={() => {}}
    />
  );
}

export default TrialTicketModal;
