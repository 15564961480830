import React, { HTMLInputTypeAttribute } from 'react';
import { styled } from '@linaria/react';
import { Control } from 'react-hook-form';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { RhfInput } from '../rhfInput';
import { Icon } from '../icon';
import type { Name } from '../icon/icon';
import { GENERIC_COLOR, PRIMARY_COLOR } from '../assets/css/style';

export interface Props {
  id?: string;
  name: string;
  value?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  width?: number;
  fullWidth?: boolean;
  error?: boolean;
  onClick: () => void;
  control?: Control<any>;
}

const CustomButton = styled.div<{
  width?: number;
  color?: string;
  border?: string;
  borderRadius?: number;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 30px;
  height: 32px;
  background: ${({ color = PRIMARY_COLOR.PRIMARY_BLUE }) => color};
  border: 1px solid ${({ border = 0 }) => border};
  border-radius: 0 4px 4px 0;
  /* feat screen 08-07-03 start */
  @media only screen and (max-width: 768px) {
    min-width: 40px;
    min-height: 40px;
  }
  /* feat screen 08-07-03 end */
`;

export function FilterSearch({
  id,
  name,
  value,
  width = 169,
  control,
  placeholder,
  fullWidth,
  type = 'text',
  error = false,
  onClick,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const [inputType, setInputType] = React.useState(type);
  const iconStatus = () => {
    let result: { name: Name; color: string } = { name: 'eye', color: GENERIC_COLOR.SUPERLITE_GRAY };
    if (value === undefined || value === '') return result;
    if (inputType === 'password') {
      result = { name: 'eye', color: PRIMARY_COLOR.PRIMARY_BLUE };
    } else {
      result = { name: 'eyeSlash', color: PRIMARY_COLOR.PRIMARY_BLUE };
    }
    return result;
  };
  // feat screen 08-07-03 start
  return !isMobile ? (
    <div>
      {type === 'password' && (
        <div style={{ position: 'absolute', left: width - 30, top: 3 }}>
          <Icon
            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
            name={iconStatus().name}
            color={iconStatus().color}
          />
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <RhfInput
          id={id}
          name={name}
          type={inputType}
          width={width}
          placeholder={placeholder}
          fullWidth={fullWidth}
          error={error}
          control={control}
        />
        <CustomButton onClick={onClick}>
          <Icon name="search" />
        </CustomButton>
      </div>
    </div>
  ) : (
    // Responsive Component
    <div>
      {type === 'password' && (
        <div style={{ position: 'absolute', left: width - 30, top: 3 }}>
          <Icon
            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
            name={iconStatus().name}
            color={iconStatus().color}
          />
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{width : "100%"}}>
          <RhfInput
            id={id}
            name={name}
            type={inputType}
            width={width}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={error}
            control={control}
          />
        </div>
        <CustomButton onClick={onClick}>
          <Icon name="search" />
        </CustomButton>
      </div>
    </div>
  // feat screen 08-07-03 end
  );
}
