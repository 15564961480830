/**
 * Figma ID: 04-01-01-16
 * 名称: 費用明細詳細（タブ）
 */
import React from 'react';
import * as gql from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { Info } from 'components/info';
import useIsMobile from 'hooks/responsive/useIsMobile';

export interface CostData {
  costData?: gql.VtBtCostInfo;
}

export function CostDetails({ costData }: CostData) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const defaultPrice = '0';
  const defaultTime = '-';

  return (
    <Box>
      <Info
        items={[
          {
            label: '採用手数料',
            content: `¥${costData?.total_hire_fee || defaultPrice}`,
          },
          {
            label: '交通費',
            content: `¥${costData?.trans_fee || defaultPrice}`,
          },
          {
            label: '基本給',
            content: `¥${costData?.basic_salary || defaultPrice}（時給¥${costData?.hour_wage || defaultPrice} × ${
              costData?.work_time || defaultTime
            }）`,
          },
          {
            label: '深夜手当',
            content: `¥${costData?.latenight_pay || defaultPrice}（深夜割増時給¥${
              costData?.base_wage || defaultPrice
            } × ${String(costData?.latenight_time)}）`,
          },
          {
            label: '残業手当',
            content: `¥${costData?.overtime_pay || defaultPrice}（残業割増時給¥${
              costData?.base_wage || defaultPrice
            } × ${String(costData?.overtime_time)}）`,
          },
          {
            label: '各消費税（目安）',
            content: `¥${costData?.tax || defaultPrice}（採用手数料）`,
          },
            {
                label: '合計',
                content:  `¥${costData?.total || defaultPrice}`,
                labelBold: true,
                contentBold: true,
                colorContent: "darkGray",
            },
        ]}
      />
    </Box>
  );
}
