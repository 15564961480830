import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { ComboBox } from 'components/comboBox';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useGetMBankCodeQuery, useGetMBankBranchByCodeLazyQuery, VtMemberBase } from 'graphql/graphql-mw';
import React, { useMemo } from 'react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Controller, UseFormReturn } from 'react-hook-form';
import { BankFormType } from '../../types';
import { PersonalBank, AccountType } from '../../../const';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onNext: (data: BankFormType) => void;
  data?: VtMemberBase | null;
  form: UseFormReturn<BankFormType>;
  isBank: boolean;
}

function EditBankModal(props: Props) {
  const { isOpen, onClose, onNext, data, form, isBank } = props;
  const { data: { getMBankCode } = { getMBankCode: [] }, error: getMBankCodeError } = useGetMBankCodeQuery({
    context: { clientName: 'master' },
  });
  /* feat_screen_09-02-01_start */
  const isMobile = useIsMobile();
  /* feat_screen_09-02-01_end */
  const [fetchMBankBranchByCodeId, { error: getMBankBranchError }] = useGetMBankBranchByCodeLazyQuery();
  const [branchOptions, setBranchOptions] = React.useState<{ label: string; value: string; no: string }[]>([]);
  // 銀行Code
  const [bankCode, setBankCode] = React.useState<string>('');
  const { openErrorModal } = useErrorModal();
  const bankOptions = useMemo<{ label: string; value: string; code: string }[]>(() => {
    if (!getMBankCode) {
      return [];
    }
    return getMBankCode.map((item) => ({
      label: item?.name ?? '',
      value: item?.name ?? '',
      code: item?.code ?? '',
    }));
  }, [getMBankCode]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;
  function onClickNext() {
    handleSubmit((d) => {
      onNext({
        ...form.getValues(), // 他のフォームデータも含める場合
        bankCode,
      });
    })();
  }

  function onModalClose() {
    onClose();
  }
  React.useEffect(() => {
    if (getMBankCodeError && isOpen) {
      onClose();
      openErrorModal({
        message: '銀行データの取得ができませんでした。',
      });
    }
  }, [getMBankCodeError, openErrorModal, onClose, isOpen]);

  React.useEffect(() => {
    if (getMBankBranchError && isOpen) {
      onClose();
      openErrorModal({
        message: '支店データの取得ができませんでした。',
      });
    }
  }, [getMBankBranchError, openErrorModal, onClose, isOpen]);
  // 初期表示用
  React.useEffect(() => {
    if (isBank && getValues('bank') === undefined) {
      // 銀行・支店セット
      setValue('bank', data?.bank || '');
      setValue('branch_name', data?.branch_name || '');
    }
  }, [isBank, setValue, getValues, data?.bank, data?.branch_name]);

  // 支店ドロップダウン初期表示用
  React.useEffect(() => {
    if (isBank) {
      if (data?.bank !== undefined) {
        const selectedBank = getMBankCode?.find((item) => item?.name === data?.bank);
        // 選択された銀行Codeセット
        setBankCode(selectedBank?.code || '');
        if (selectedBank) {
          fetchMBankBranchByCodeId({
            context: { clientName: 'master' },
            variables: { mcode: selectedBank.code },
            fetchPolicy: 'no-cache',
          }).then(({ data: { getMBankBranchByCode } = { getMBankBranchByCode: [] } }) => {
            if (getMBankBranchByCode) {
              setBranchOptions(
                getMBankBranchByCode.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                  no: item?.branch ?? '',
                }))
              );
            }
          });
        }
      }
    }
  }, [isBank, fetchMBankBranchByCodeId, getMBankCode, setValue, data?.bank]);

  // 初期表示でbranch_noがセットされないので
  if (isBank && branchOptions.length > 0 && getValues('branch_no') === undefined) {
    const getBranch = branchOptions.find((branch) => branch.value === data?.branch_name);
    // 支店noセット
    if (getBranch) {
      setValue('branch_no', getBranch.no);
    }
  }
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'bank' && type === 'change') {
        const selectedBank = getMBankCode?.find((item) => item?.name === value.bank);
        // 選択された銀行Codeセット
        setBankCode(selectedBank?.code || '');
        if (selectedBank) {
          fetchMBankBranchByCodeId({
            context: { clientName: 'master' },
            variables: { mcode: selectedBank.code },
          }).then(({ data: { getMBankBranchByCode } = { getMBankBranchByCode: [] } }) => {
            if (getMBankBranchByCode) {
              setBranchOptions(
                getMBankBranchByCode.map((item) => ({
                  label: item?.name ?? '',
                  value: item?.name ?? '',
                  no: item?.branch ?? '',
                }))
              );
              setValue('branch_name', '', { shouldValidate: true });
            }
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, fetchMBankBranchByCodeId, getMBankCode, setValue]);

  const header = (
    <Text variant="h2" color="darkBlue">
      銀行口座編集
    </Text>
  );

  /* feat_screen_09-02-01_start */
  const content = !isMobile ? (
  /* feat_screen_09-02-01_end */
    <Box>
      <Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>法人/個人</InputLabel>
          <RhfRadioGroup
            rules={{ required: '法人/個人を選択してください。' }}
            defaultValue={
              data?.personal_bank !== undefined ? String(data.personal_bank) : String(PersonalBank.Corporation)
            }
            control={control}
            name="personal_bank"
            options={[
              { label: '法人', value: '法人' },
              { label: '個人', value: '個人' },
            ]}
          />
          {errors.personal_bank?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.personal_bank.message}
            </Text>
          )}
        </Box>
        <Box display="flex" gap={10}>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel width={368}>銀行</InputLabel>
            <Controller
              name="bank"
              control={control}
              rules={{ required: '銀行を選択してください。' }}
              render={({ field }) => (
                <ComboBox
                  width={328}
                  options={bankOptions}
                  onChange={(newValue) => {
                    field.onChange((newValue as { label: string; value: string })?.value);
                    const selectedBank = bankOptions.find(
                      (bank) => bank.value === (newValue as { label: string; value: string; code: string })?.value ?? ''
                    );
                    // 銀行Codeセット
                    if (selectedBank) {
                      setBankCode(selectedBank?.code);
                    }
                  }}
                  value={bankOptions.find((option) => option.value === field.value)}
                />
              )}
            />
            {errors.bank?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.bank.message}
              </Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel width={368}>支店</InputLabel>
            {/* 初期支店名選択 branchOptions.length */}
            {branchOptions.length > 0 && (
              <Controller
                name="branch_name"
                control={control}
                rules={{ required: '支店を選択してください。' }}
                render={({ field }) => (
                  <ComboBox
                    width={328}
                    options={branchOptions}
                    onChange={(newValue) => {
                      field.onChange((newValue as { label: string; value: string })?.value);
                      const selectedBank = branchOptions.find(
                        (branch) => branch.value === (newValue as { label: string; value: string })?.value ?? ''
                      );
                      // 支店noセット
                      if (selectedBank) {
                        setValue('branch_no', selectedBank.no);
                      }
                      // 選択前のエラーメッセージクリア
                      clearErrors('branch_name');
                    }}
                    value={branchOptions.find((option) => option.value === field.value)}
                  />
                )}
              />
            )}
            {errors.branch_name?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.branch_name.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>口座種別</InputLabel>
          <RhfRadioGroup
            rules={{ required: '口座種別を選択してください。' }}
            control={control}
            name="account_type"
            defaultValue={
              data?.account_type !== undefined ? String(data.account_type) : String(AccountType.SavingsAccount)
            }
            options={[
              { label: '普通預金', value: '普通' },
              { label: '当座預金', value: '当座' },
            ]}
          />
          {errors.account_type?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.account_type.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>口座番号</InputLabel>
          <RhfInput
            width={368}
            control={control}
            name="account_no"
            placeholder="口座番号を入力（7桁）"
            defaultValue={data?.account_no ?? ''}
            rules={{
              required: '口座番号を入力してください。',
              minLength: { value: 7, message: '7桁の数値で入力してください。' },
              maxLength: { value: 7, message: '7桁の数値で入力してください。' },
              pattern: {
                value: /^\d*$/,
                message: '7桁の数値で入力してください。',
              },
            }}
          />
          {errors.account_no?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.account_no.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel fullWidth>口座名義</InputLabel>
          <RhfInput
            width={368}
            rules={{
              required: '口座名義を入力してください。',
              pattern: {
                value: /^[ア-ンA-Z0-9 ()\-.]+$/g,
                message:
                  '口座名等で使用できる文字は、全角カナ（ヲと小文字を除く）、半角英大文字（A～Z）、半角数字（0～9）、半角スペース、半角記号 4 種類（ （ ） -〔ﾊｲﾌﾝ〕 . 〔ﾋﾟﾘｵﾄﾞ〕）です',
              },
            }}
            control={control}
            name="account_name"
            defaultValue={data?.account_name ?? ''}
            placeholder="口座名義を入力（セイとメイを分けてカタカナで入力）"
          />
          {errors.account_name?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.account_name.message}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
    /* feat_screen_09-02-01_start */
  ) : (
    <Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <InputLabel width={200}>法人/個人</InputLabel>
        <RhfRadioGroup
          rules={{ required: '法人/個人を選択してください。' }}
          defaultValue={
            data?.personal_bank !== undefined ? String(data.personal_bank) : String(PersonalBank.Corporation)
          }
          control={control}
          name="personal_bank"
          options={[
            { label: '法人', value: '法人' },
            { label: '個人', value: '個人' },
          ]}
        />
        {errors.personal_bank?.message && (
          <Text variant="caption12" color="cautionRed">
            {errors.personal_bank.message}
          </Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={16} mt={22} mb={14}>
        <Box display="flex" flexDirection="column" gap={2}>
          <InputLabel width={200}>銀行</InputLabel>
          <Controller
            name="bank"
            control={control}
            rules={{ required: '銀行を選択してください。' }}
            render={({ field }) => (
              <ComboBox
                width='100%'
                options={bankOptions}
                onChange={(newValue) => {
                  field.onChange((newValue as { label: string; value: string })?.value);
                  const selectedBank = bankOptions.find(
                    (bank) => bank.value === (newValue as { label: string; value: string; code: string })?.value ?? ''
                  );
                  // 銀行Codeセット
                  if (selectedBank) {
                    setBankCode(selectedBank?.code);
                  }
                }}
                value={bankOptions.find((option) => option.value === field.value)}
              />
            )}
          />
          {errors.bank?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.bank.message}
            </Text>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputLabel width={200}>支店</InputLabel>
          {/* 初期支店名選択 branchOptions.length */}
          {branchOptions.length > 0 && (
            <Controller
              name="branch_name"
              control={control}
              rules={{ required: '支店を選択してください。' }}
              render={({ field }) => (
                <ComboBox
                  width='100%'
                  options={branchOptions}
                  onChange={(newValue) => {
                    field.onChange((newValue as { label: string; value: string })?.value);
                    const selectedBank = branchOptions.find(
                      (branch) => branch.value === (newValue as { label: string; value: string })?.value ?? ''
                    );
                    // 支店noセット
                    if (selectedBank) {
                      setValue('branch_no', selectedBank.no);
                    }
                    // 選択前のエラーメッセージクリア
                    clearErrors('branch_name');
                  }}
                  value={branchOptions.find((option) => option.value === field.value)}
                />
              )}
            />
          )}
          {errors.branch_name?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.branch_name.message}
            </Text>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={4} mb={10}>
        <InputLabel width={200}>口座種別</InputLabel>
        <RhfRadioGroup
          rules={{ required: '口座種別を選択してください。' }}
          control={control}
          name="account_type"
          defaultValue={
            data?.account_type !== undefined ? String(data.account_type) : String(AccountType.SavingsAccount)
          }
          options={[
            { label: '普通預金', value: '普通' },
            { label: '当座預金', value: '当座' },
          ]}
        />
        {errors.account_type?.message && (
          <Text variant="caption12" color="cautionRed">
            {errors.account_type.message}
          </Text>
        )}
      </Box>
      <Box display='flex' flexDirection="column" gap={16} pt={4}>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputLabel width={200}>口座番号</InputLabel>
          <RhfInput
            fullWidth
            control={control}
            name="account_no"
            placeholder="口座番号を入力（7桁）"
            defaultValue={data?.account_no ?? ''}
            rules={{
              required: '口座番号を入力してください。',
              minLength: { value: 7, message: '7桁の数値で入力してください。' },
              maxLength: { value: 7, message: '7桁の数値で入力してください。' },
              pattern: {
                value: /^\d*$/,
                message: '7桁の数値で入力してください。',
              },
            }}
          />
          {errors.account_no?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.account_no.message}
            </Text>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputLabel width={200}>口座名義</InputLabel>
          <RhfInput
            fullWidth
            rules={{
              required: '口座名義を入力してください。',
              pattern: {
                value: /^[ア-ンA-Z0-9 ()\-.]+$/g,
                message:
                  '口座名等で使用できる文字は、全角カナ（ヲと小文字を除く）、半角英大文字（A～Z）、半角数字（0～9）、半角スペース、半角記号 4 種類（ （ ） -〔ﾊｲﾌﾝ〕 . 〔ﾋﾟﾘｵﾄﾞ〕）です',
              },
            }}
            control={control}
            name="account_name"
            defaultValue={data?.account_name ?? ''}
            placeholder="口座名義を入力（セイとメイを分けてカタカナで入力）"
          />
          {errors.account_name?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.account_name.message}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
    /* feat_screen_09-02-01_end */
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton 
        /* feat_screen_09-02-01_start */
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        /* feat_screen_09-02-01_end */
        variant="secondary"
        onClick={() => onModalClose()}
      >
        キャンセル
      </MainButton>
      <MainButton 
        /* feat_screen_09-02-01_start */
        width={!isMobile ? 104 : 96}
        thin={!isMobile}
        /* feat_screen_09-02-01_end */
        onClick={() => onClickNext()}>
        次へ
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      /* feat_screen_09-02-01_start */
      height={!isMobile ? "auto" : '74.5%'}
      width={!isMobile ? 800 : '100%'}
      /* feat_screen_09-02-01_end */
    />
  );
}

export default EditBankModal;
