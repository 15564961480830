/**
 * Figma ID: 08-09-01
 * 名称: 添付ファイル
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { useGetSTpmemRecruitByTpmemIdQuery, useUpdateSTpmemRecruitFileByTIdMutation } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { uploadFile } from 'components/api';
import { Modal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { downloadFromUrl } from 'components/pdf';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { useErrorModal } from 'components/error/errorModalProvider';

type DeletePhase = 'confirm' | 'complete';
export function Attachment() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const tpmemId = Number(auth.tpmemId);
  const { openErrorModal } = useErrorModal();
  const {
    data,
    loading,
    error: STpmemRecruitByTpmemIdError,
    refetch,
  } = useGetSTpmemRecruitByTpmemIdQuery({
    variables: { tpmem_id: tpmemId },
  });
  React.useEffect(() => {
    if (STpmemRecruitByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [STpmemRecruitByTpmemIdError, openErrorModal]);
  const [updateAttachedFile] = useUpdateSTpmemRecruitFileByTIdMutation();
  const [inputFile, setInputFile] = React.useState<File | null>(null);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [deletePhase, setDeletePhase] = React.useState<DeletePhase>('confirm');
  const [isNewUpload, setIsNewUpload] = React.useState(false);
  const attachedFileData = data?.getSTpmemRecruitByTpmemId;
  const fileName = `${auth.tpmemId ?? 0}-attached.pdf`;
  const inputPdfId = 'inputPdf';
  const uploadUrl = `store-data/${fileName}`;
  const s3Url = `${process.env.REACT_APP_FILE_READ_URL ?? ''}/${uploadUrl}` || '';
  const today = new Date();
  const date = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

  // 入力エリアクリア
  const inputClear = () => {
    const inputElement = document.getElementById(inputPdfId) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = '';
    }
  };

  const content = () =>
    attachedFileData?.attached_name ? (
      <Box justifyContent="space-between" display="flex" alignItems="center">
        {/* feat screen 09-05-01 start */}
        <Box display="grid" py={16} pb={!isMobile? 16 : 16} gap={!isMobile ? 0 : 4}>
          {/* feat screen 09-05-01 end */}
          <Text variant="body14">{attachedFileData?.attached_name}</Text>
          {/* feat screen 09-05-01 start */}
          <Text variant={!isMobile ? "caption11" : "caption12"} color="darkGray">
            {/* feat screen 09-05-01 end */}
            {attachedFileData?.attached_date}
          </Text>
        </Box>
        <ThreeDotLeader
          right={0}
          menu={[
            { label: '上書き', value: 'override' },
            { label: 'ダウンロード', value: 'download' },
            { label: '削除', value: 'delete' },
          ]}
          onClick={(val) => {
            setIsNewUpload(false);
            switch (val) {
              case 'download':
                downloadFromUrl(fileName, s3Url ?? '');
                break;
              case 'override':
                // ファイルアップロードダイアログを開く
                document.getElementById(inputPdfId)?.click();
                break;
              case 'delete':
                setIsOpenDeleteModal(true);
                break;
              default:
                break;
            }
          }}
        />
      </Box>
    ) : (
      // feat screen 09-05-01 start
      <Box justifyContent="space-between" display="flex" py={16} pb={!isMobile? 16 : 12} alignItems="center">
        {/* feat screen 09-05-01 end */}
        <Text variant="body14">未設定</Text>
        <ThreeDotLeader
          right={0}
          menu={[{ label: 'アップロード', value: 'upload' }]}
          onClick={(val) => {
            if (val === 'upload') {
              setIsNewUpload(true);
              // ファイルアップロードダイアログを開く
              document.getElementById(inputPdfId)?.click();
            }
          }}
        />
      </Box>
    );
  const updateFile = React.useCallback(() => {
    const fileToUpload = inputFile;
    if (!fileToUpload) return;
    uploadFile({ filePath: uploadUrl, file: fileToUpload }).then(async () => {
      await updateAttachedFile({
        variables: { tpmem_id: tpmemId, attached_date: date, attached_file: s3Url, attached_name: fileToUpload.name },
      })
        .then(() => {
          refetch();
          setIsOpenUpdateModal(false);
          setInputFile(null);
          inputClear(); // 入力エリアクリア
        })
        .catch((e) => {
          setIsOpenUpdateModal(false);
          setInputFile(null);
          inputClear(); // 入力エリアクリア
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          });
        });
    });
  }, [date, inputFile, refetch, s3Url, tpmemId, updateAttachedFile, uploadUrl, openErrorModal]);

  const deleteFile = () => {
    updateAttachedFile({
      variables: { tpmem_id: tpmemId, attached_date: '', attached_file: '', attached_name: '' },
    })
      .then(() => {
        refetch();
        setDeletePhase('complete');
      })
      .catch((e) => {
        setDeletePhase('confirm');
        setIsOpenDeleteModal(false);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  React.useEffect(() => {
    if (!inputFile) return;
    if (isNewUpload) {
      updateFile();
    } else {
      setIsOpenUpdateModal(true);
    }
  }, [inputFile, isNewUpload, updateFile]);

  // 同じファイルを選択した時の対応
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setInputFile(e.target.files?.[0]);
    }
  };

  // feat screen 08-09-01 start
  return !isMobile ? (
    <Box width="100%" px={24}>
      {loading && <Loading />}
      <Box display="flex" alignItems="center" gap={8} py={18}>
        <Text variant="h2" color="darkBlue">
          添付ファイル
        </Text>
        <Text variant="caption12" color="darkBlue">
          募集に添付できるPDFファイルを１ファイルのみアップロードできます。
        </Text>
      </Box>
      <Divider option="horizontal" />
      {content()}
      <Divider option="horizontal" />
      <Modal
        isOpen={isOpenUpdateModal}
        header={
          <Text variant="h2" color="darkBlue">
            上書き
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Box display="grid">
              <Text variant="body14">ファイルを上書きしますか？</Text>
              <Text variant="body14">上書きされたファイルは元に戻せません。</Text>
            </Box>
          </Box>
        }
        footer={
          <Box display="flex" gap={8} justifyContent="flex-end">
            <MainButton
              width={104}
              variant="secondary"
              onClick={() => {
                setIsOpenUpdateModal(false);
                setInputFile(null);
              }}
            >
              キャンセル
            </MainButton>
            <MainButton width={104} onClick={() => updateFile()}>
              アップロード
            </MainButton>
          </Box>
        }
        onClose={() => setIsOpenUpdateModal(false)}
      />
      <Modal
        isOpen={isOpenDeleteModal}
        header={
          <Text variant="h2" color="darkBlue">
            {deletePhase === 'confirm' ? '削除' : `削除 完了`}
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            {deletePhase === 'confirm' ? (
              <Text variant="body14">ファイルを削除しますか？</Text>
            ) : (
              <Text variant="body14">ファイルの削除が完了しました。</Text>
            )}
          </Box>
        }
        footer={
          <Box justifyContent="flex-end" display="flex">
            {deletePhase === 'confirm' ? (
              <Box gap={8} display="flex" justifyContent="flex-end">
                <MainButton width={104} variant="secondary" onClick={() => setIsOpenDeleteModal(false)}>
                  キャンセル
                </MainButton>
                <MainButton width={104} onClick={() => deleteFile()}>
                  削除
                </MainButton>
              </Box>
            ) : (
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setIsOpenDeleteModal(false);
                  setDeletePhase('confirm');
                }}
              >
                閉じる
              </MainButton>
            )}
          </Box>
        }
        onClose={() => {
          setIsOpenDeleteModal(false);
          setDeletePhase('confirm');
        }}
        height={200}
      />
      <input type="file" id={inputPdfId} hidden onChange={handleOnChange} />
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%" px={16}>
    {loading && <Loading/>}
      <Box display="flex" flexDirection="column" alignItems="center" pt={17} pb={12} gap={4}>
        <Text variant="h2" color="darkBlue">
          添付ファイル
        </Text>
        <Text align="center" display="block" variant="caption12" color="darkBlue">
          募集に添付できるPDFファイルを１ファイルのみアップロードできます。
        </Text>
      </Box>
      <Divider option="horizontal"/>
      {content()}
      <Divider option="horizontal"/>
      <Modal
        isOpen={isOpenUpdateModal}
        header={
          <Text variant="h2" color="darkBlue">
            上書き
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            <Box display="grid">
              <Text display="block" variant="body14">ファイルを上書きしますか？</Text>
              <Text display="block" variant="body14">上書きされたファイルは元に戻せません。</Text>
            </Box>
          </Box>
        }
        footer={
          <Box display="flex" gap={8} justifyContent="flex-end">
            <MainButton
              width={96}
              variant="secondary"
              onClick={() => {
                setIsOpenUpdateModal(false);
                setInputFile(null);
              }}
            >
              キャンセル
            </MainButton>
            <MainButton width={104} onClick={() => updateFile()}>
              アップロード
            </MainButton>
          </Box>
        }
        height="unset"
        width="100%"
        onClose={() => setIsOpenUpdateModal(false)}
      />
      <Modal
        isOpen={isOpenDeleteModal}
        header={
          <Text variant="h2" color="darkBlue">
            {deletePhase === 'confirm' ? '削除' : `削除 完了`}
          </Text>
        }
        content={
          <Box display="grid" alignItems="center" height="100%">
            {deletePhase === 'confirm' ? (
              <Text display="block" variant="body14">ファイルを削除しますか？</Text>
            ) : (
              <Text display="block" variant="body14">ファイルの削除が完了しました。</Text>
            )}
          </Box>
        }
        footer={
          <Box justifyContent="flex-end" display="flex">
            {deletePhase === 'confirm' ? (
              <Box gap={8} display="flex" justifyContent="flex-end">
                <MainButton width={96} variant="secondary" onClick={() => setIsOpenDeleteModal(false)}>
                  キャンセル
                </MainButton>
                <MainButton width={96} onClick={() => deleteFile()}>
                  削除
                </MainButton>
              </Box>
            ) : (
              <MainButton
                width={96}
                variant="secondary"
                onClick={() => {
                  setIsOpenDeleteModal(false);
                  setDeletePhase('confirm');
                }}
              >
                閉じる
              </MainButton>
            )}
          </Box>
        }
        onClose={() => {
          setIsOpenDeleteModal(false);
          setDeletePhase('confirm');
        }}
        height="unset"
        width="100%"
      />
      <input type="file" id={inputPdfId} hidden onChange={handleOnChange}/>
    </Box>
    // feat screen 08-09-01 end
  );
}
