/**
 * Figma ID: 08-07-06
 * 名称: 店舗ブロック
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import * as gql from 'graphql/graphql-mw';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';

type ConfirmContentModel = {
  selectedBlockTpmemList: { [key: string]: boolean };
  reason?: string;
  comment?: string;
};

function TpmemName(tpmemId: string) {
  const { openErrorModal } = useErrorModal();
  const { data, error: getVtMemberBaseByIdError } = gql.useGetVtMemberBaseByIdQuery({
    variables: { id: Number(tpmemId) },
  });
  React.useEffect(() => {
    if (getVtMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [getVtMemberBaseByIdError, openErrorModal]);
  return (
    <Box>
      <Text variant="body14">・{data?.getVTMemberBaseById.store_name}</Text>
    </Box>
  );
}
export function ConfirmContent(props: ConfirmContentModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { selectedBlockTpmemList, reason, comment } = props;
  const data = Object.keys(selectedBlockTpmemList);
  // feat screen 08-07-06 start
  return !isMobile ? (
    <Box width="100%">
      <Box>
        <Text variant="body14">以下の店舗をブロック登録します。よろしいですか？</Text>
      </Box>
      <Box py={16}>{data.map((val) => selectedBlockTpmemList[val] && TpmemName(val))}</Box>
      <Divider option="horizontal" />
      <Box py={16}>
        <Text variant="body14">店舗ブロック理由（正当な理由がない場合、運営がブロックを解除する場合があります）</Text>
      </Box>
      <Box>
        <Text variant="body14">・{reason}</Text>
      </Box>
      {reason === 'その他' && <Text variant="body14">{comment}</Text>}
    </Box>
  ) : (
    // Responsive Component
    <Box width="100%">
      <Box pt={2} pb={13}>
        <Text display="block" variant="body14">以下の店舗をブロック登録します。よろしいですか？</Text>
      </Box>
      <Box pb={6}>{data.map((val) => selectedBlockTpmemList[val] && TpmemName(val))}</Box>
      <Box py={16}>
        <Divider option="horizontal" />
      </Box>
      <Box pt={8} pb={12}>
        <Text variant="body14">店舗ブロック理由（正当な理由がない場合、運営がブロックを解除する場合があります）</Text>
      </Box>
      <Box>
        <Text variant="body14">・{reason}</Text>
      </Box>
      {reason === 'その他' && <Text variant="body14">{comment}</Text>}
    </Box>
    // feat screen 08-07-06 end
  );
}

type ConfirmFooterModel = {
  nextPhase: () => void;
  close: () => void;
  backPhase: () => void;
};
export function ConfirmFooter(props: ConfirmFooterModel) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { nextPhase, close, backPhase } = props;
  return (
    <Box display="flex" gap={8} justifyContent="flex-end">
      {/* feat screem 08-07-06 start */}
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={close}>
        キャンセル
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={backPhase}>
        戻る
      </MainButton>
      <MainButton width={!isMobile ? 104 : 96} onClick={nextPhase}>
        登録
      </MainButton>
      {/* feat screem 08-07-06 end */}
    </Box>
  );
}
