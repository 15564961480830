import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { reportMember } from 'components/api';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import useIsMobile from 'hooks/responsive/useIsMobile';

export function Confirm(
  onClose: () => void,
  onBack: () => void,
  onNext: () => void,
  baitravelerId: number,
  reason: string
): ModalRet {
  const { tpmemId } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        通報
      </Text>
    ),
    content: !isMobile ? (
      <>
        {isLoading && <Loading />}
        <Box display="flex" flexDirection="column" gap={17.5} pa={8} flex="1">
          <Text variant="body14">
            以下の内容でこのバイトラベラーを通報します。よろしければ送信を押してください。この操作は取り消せません。
          </Text>
          <Box display="flex" flexDirection="column">
            {reason?.split('\n').map((item) => (
              <Text variant="body14">{item}</Text>
            ))}
          </Box>
        </Box>
      </>
    ) : (
      /* feat_screen_05-01-01-12_start */
      <>
      {isLoading && <Loading />}
        <Box display="flex" flexDirection="column" gap={12} flex="1">
          <Text variant="body14">
            以下の内容でこのバイトラベラーを通報します。よろしければ送信を押してください。この操作は取り消せません。
          </Text>
          <Box display="flex" flexDirection="column">
            {reason?.split('\n').map((item) => (
              <Text variant="body14">{item}</Text>
            ))}
          </Box>
        </Box>
      </>
      /* feat_screen_05-01-01-12_end */
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          /* feat_screen_05-01-01-12_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          /* feat_screen_05-01-01-12_end */
          variant="secondary" 
          onClick={() => onClose()}
        >
          キャンセル
        </MainButton>
        <MainButton
          /* feat_screen_05-01-01-12_start */
          width={!isMobile ? 104 : 96} 
          thin={!isMobile} 
          /* feat_screen_05-01-01-12_end */
          variant="secondary" 
          onClick={() => onBack()}
        >
          戻る
        </MainButton>
        <MainButton
          /* feat_screen_05-01-01-12_start */
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          /* feat_screen_05-01-01-12_end */
          onClick={() => {
            if (!tpmemId) return;
            setIsLoading(true);
            reportMember({
              baitravelerId: baitravelerId.toString(),
              storeId: tpmemId.toString(),
              body: reason,
            })
              .then(() => {
                onNext();
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          送信
        </MainButton>
      </Box>
    ),
    /* feat_screen_05-01-01-12_start */
    width: !isMobile ? 800 : "100%",
    height: !isMobile ? 332 : 286,
    /* feat_screen_05-01-01-12_end */
  };

  return modalContent;
}
