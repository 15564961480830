import React, { useEffect, useMemo } from 'react';
import { Box } from 'components/box';
import { Chip } from 'components/chip';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { useLocation, useParams } from 'react-router-dom';
import { ContentsCard, PageBody } from 'components/assets/css/pages/pageStyle';
import { ScrollableArea } from 'components/scrollableArea';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import {
  useGetupdVtArbeitOfferInfoWorkingByIdMutation,
  useGetVbWorkingModListByWorkIdLazyQuery,
  useGetVtBtCostInfoWorkingByWorkIdLazyQuery,
  useGetVtWorkingListByWorkIdLazyQuery,
  VtArbeitOfferInfo,
  VbWorkingModList,
  GetVtBtCostInfoWorkingByWorkIdQuery,
  VtWorkingListR,
} from 'graphql/graphql-mw';

import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import RecruitInfo from './components/RecruitInfo';
import QRCodeFaceRecongnition from './components/QRCodeFaceRecognition';
import WorkLog from './components/WorkLog';
import Review from './components/Review';
import CostDetails from './components/CostDetails';
import { convertStatusToChipColor, convertStatusToLabel } from '../components/common';
import { RecruitType } from '../components/const';
import { CheckInInfoContext } from './components/common';
import { CostDetailsModel, CorrectionModel, WorkLogModel } from './components/types';
import { MenuIndexType } from '../components/type';

/**
 * Figma ID: 04-02-01-02
 * 名称: バイト募集詳細
 */

// dateString: YYYY年M月D日（曜日） h:m （例: 2023年6月1日(木) 8:15))
function convertStringToDate(dateString: string) {
  const regex = /(\d{4})年(\d{1,2})月(\d{1,2})日\([^)]+\) (\d{1,2}):(\d{1,2})/;
  const match = dateString.match(regex);

  if (match) {
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // 月は0から始まるため、-1を行います
    const day = parseInt(match[3], 10);
    const hour = parseInt(match[4], 10);
    const minute = parseInt(match[5], 10);

    return new Date(year, month, day, hour, minute);
  }
  return new Date();
}

function convertWorkingListToWorkLog(data?: VtWorkingListR | null): WorkLogModel {
  return {
    beginEnd: data?.begin_end || '',
    checkInTime: data?.qr_checkin || '',
    checkOutTime: data?.qr_checkout || '',
  };
}

function convertModListToCorrection(data: VbWorkingModList[]): CorrectionModel[] {
  return data.map((item) => ({
    id: item.mod_id || 0,
    status: item.status || 0,
    requestedCheckInTime: convertStringToDate(item.checkin || ''),
    requestedCheckOutTime: convertStringToDate(item.checkout || ''),
    requestedDateTime: item.checkin_out || '',
    breakTime: item.break_time || '',
    submittedDateTime: item.reg_date_w || '',
    totalSalary: item.total_salary || '',
    comment: item.reason || '',
    work_id: item.work_id || 0,
  }));
}

function convertCostInfoToCostDetails(data?: GetVtBtCostInfoWorkingByWorkIdQuery): CostDetailsModel {
  const { cost, working } = data?.getVTBtCostInfoWorkingByWorkId || {};

  return {
    totalHireFee: cost?.total_hire_fee || '',
    transFee: cost?.trans_fee || '',
    basicSalary: cost?.basic_salary || '',
    hourWage: cost?.hour_wage || '',
    workTime: cost?.work_time || '',
    baseWage: cost?.base_wage || '',
    latenightPay: cost?.latenight_pay || '',
    latenightTime: cost?.latenight_time || '',
    overtimePay: cost?.overtime_pay || '',
    overtimeTime: cost?.overtime_time || '',
    tax: cost?.tax || '',
    withholdingTax: cost?.withholding_tax || '',
    walletFee: cost?.use_wallet || 0, // wallet_feeはstringで扱いにくいので使わない。use_walletに同じ金額がnumberで入っている
    usedWalletAmount: cost?.use_wallet || 0,
    ticketFee: cost?.ticket_fee || '',
    usedTicketAmount: cost?.use_ticket || 0,
    totalAmount: cost?.total_amount || '',
    educationFee: cost?.educ_fee || 0,
    educationFeeTax: cost?.educ_fee_tax || 0,
    status: working?.[0]?.status,
    workId: cost?.work_id || 0,
  };
}

function isVtArbeitOfferInfo(data?: VtArbeitOfferInfo): data is VtArbeitOfferInfo {
  return data !== undefined;
}

function S0402Details() {
  const { id: offerId } = useParams();

  // 成約バイト状況のメニューの初期値を取得
  // ※HOME画面から遷移した際に、メニューの初期値を取得する
  const location = useLocation();
  const state = location?.state as MenuIndexType;
  const menuIndex = state?.menuIndex;
  const { openErrorModal } = useErrorModal();

  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(menuIndex || 0);
  // feat_common_responsive_tab_end

  // データ取得と同時に既読更新処理を行うためmutationです
  const [
    getOfferDataMutation,
    {
      data: arbeitOfferInfoData,
      loading: arbeitOfferInfoLoading,
      called: isGetOfferInfoQueryCalled,
      error: arbeitOfferInfoError,
    },
  ] = useGetupdVtArbeitOfferInfoWorkingByIdMutation({
    variables: {
      id: Number(offerId),
    },
    fetchPolicy: 'no-cache',
  });
  // エラー処理
  React.useEffect(() => {
    if (arbeitOfferInfoError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [arbeitOfferInfoError, openErrorModal]);

  const offerData = arbeitOfferInfoData?.getupdVTArbeitOfferInfoWorkingById.info;
  const workId = arbeitOfferInfoData?.getupdVTArbeitOfferInfoWorkingById.working[0]?.work_id;
  // レビューの通知取得
  const isNewly = !!arbeitOfferInfoData?.getupdVTArbeitOfferInfoWorkingById.working[0]?.newly_review;

  const [
    callWorkingDataByWorkIdQuery,
    { data: workingList, refetch: refetchWorkingList, loading: workingListLoading, error: workingListError },
  ] = useGetVtWorkingListByWorkIdLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  // エラー処理 バイト状況
  React.useEffect(() => {
    if (workingListError) {
      openErrorModal({
        message: 'バイト状況のデータが取得できませんでした。',
      });
    }
  }, [workingListError, openErrorModal]);

  const workingData = workingList?.getVTWorkingListByWorkId[0];

  useEffect(() => {
    if (!workId) return;

    callWorkingDataByWorkIdQuery({
      variables: {
        work_id: Number(workId),
      },
    });
  }, [workId, callWorkingDataByWorkIdQuery]);

  const [
    getWorkingModListQuery,
    {
      data: workingModListData,
      refetch: refetchWorkingModList,
      loading: workingModListLoading,
      error: workingModListError,
    },
  ] = useGetVbWorkingModListByWorkIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  // エラー処理 勤務時間修正依頼
  React.useEffect(() => {
    if (workingModListError) {
      openErrorModal({
        message: '勤務時間修正依頼のデータが取得できませんでした。',
      });
    }
  }, [workingModListError, openErrorModal]);

  const [
    getWorkCostInfoQuery,
    { data: workCostInfo, refetch: refetchWorkCostInfo, loading: workCostInfoLoading, error: workCostInfoError },
  ] = useGetVtBtCostInfoWorkingByWorkIdLazyQuery({
    variables: {
      work_id: Number(workId),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  // エラー処理 費用明細情報
  React.useEffect(() => {
    if (workCostInfoError) {
      openErrorModal({
        message: '費用明細情報のデータが取得できませんでした。',
      });
    }
  }, [workCostInfoError, openErrorModal]);

  useEffect(() => {
    if (!offerId) return;

    getOfferDataMutation({
      variables: { id: Number(offerId) },
    });
  }, [offerId, getOfferDataMutation]);

  useEffect(() => {
    if (!workId) return;

    getWorkingModListQuery({
      variables: {
        work_id: Number(workId),
      },
    });

    getWorkCostInfoQuery({
      variables: {
        work_id: Number(workId),
      },
    });
  }, [getWorkingModListQuery, getWorkCostInfoQuery, workId]);

  const workLogData = convertWorkingListToWorkLog(workingData);
  const modList = workingModListData?.getVBWorkingModListByWorkId.mod;
  const correctionData = convertModListToCorrection(modList || []);
  const costData = convertCostInfoToCostDetails(workCostInfo);

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const checkInInfo = useMemo(
    () => ({
      imageURL: workingData?.image || '',
      status: workingData?.status || -1,
      brandId: workingData?.brand_id || -1,
      tpmemId: workingData?.tpmem_id || -1,
      isFaceRecognized: workingData?.recognition || false,
    }),
    [workingData]
  );

  const loading = workCostInfoLoading || workingModListLoading || arbeitOfferInfoLoading || workingListLoading;

  // 成約バイト情報が見つからなかった場合
  if (!loading && !workId) {
    return <p>データが見つかりません</p>;
  }

  return !isMobile ? (
    <>
      {loading && <Loading />}
      {isVtArbeitOfferInfo(offerData) && (
        <>
          {/* <Box display="flex" alignItems="center" pb={24}> */}
          <Box display="flex" alignItems="center" mb={24}>
            <Chip color={convertStatusToChipColor(workingData?.status)} variant="big">
              {convertStatusToLabel(workingData?.status)}
            </Chip>
            <Box ml={8}>
              <Text variant="h1" color="darkBlue">
                {workingData?.offer_number}
              </Text>
            </Box>
          </Box>
          <PageBody bottom={60}>
            <ContentsCard>
              {workingData?.type === RecruitType.HIRED ? (
                <SubMenu
                  items={[
                    {
                      content: (
                        <RecruitInfo
                          data={{
                            ...offerData,
                            entry_id: workingData.entry_id || 0,
                            type: workingData.type,
                            btmem_id: workingData.btmem_id,
                            btmem_name: workingData.btmem_name || '',
                            workingAgreementStatus: workingData.status,
                          }}
                        />
                      ),

                      label: '募集情報',
                    },
                    {
                      content: (
                        <CheckInInfoContext.Provider value={checkInInfo}>
                          <QRCodeFaceRecongnition
                            workId={workingData.work_id}
                            refetch={() => {
                              refetchWorkingList();
                            }}
                          />
                        </CheckInInfoContext.Provider>
                      ),
                      label: '二次元バーコード/顔認証',
                    },
                    {
                      content: (
                        <WorkLog
                          workLogData={workLogData}
                          correctionData={correctionData}
                          status={workingData?.status}
                          refetch={() => {
                            refetchWorkingModList();
                          }}
                        />
                      ),
                      label: '勤務時間 / 修正',
                    },
                    {
                      content: (
                        <Review
                          data={{
                            bizId: workingData.biz_id,
                            btmemId: workingData.btmem_id,
                            tpmemId: workingData.tpmem_id,
                            workId: workingData.work_id,
                          }}
                          status={workingData?.status}
                          type={workingData?.type}
                        />
                      ),
                      label: 'レビュー',
                      hasNotification: isNewly,
                    },
                    {
                      content: (
                        <CostDetails
                          data={costData}
                          refetch={() => {
                            refetchWorkCostInfo();
                          }}
                        />
                      ),
                      label: '費用明細詳細',
                    },
                  ]}
                  defaultIndex={menuIndex}
                />
              ) : (
                <ScrollableArea>
                  <RecruitInfo
                    data={{
                      ...offerData,
                      entry_id: workingData?.entry_id || 0,
                      type: workingData?.type,
                      btmem_id: workingData?.btmem_id || 0,
                      btmem_name: workingData?.btmem_name || '',
                      workingAgreementStatus: workingData?.status || 0,
                    }}
                  />
                </ScrollableArea>
              )}
            </ContentsCard>
          </PageBody>
        </>
      )}
    </>
  ) : (
    /* feat_screen_04-02-01-02_start */
    <>
      {loading && <Loading />}
      {isVtArbeitOfferInfo(offerData) && (
        <Box display="flex" flexDirection="column" gap={16}>
          <MultiMenuHeader items={
              [
                {
                  label: "募集・採用"
                },
                {
                  label: "成約バイト状況"
                }
              ]
            }
            mainHeader="成約バイト状況"
          />
          <Box display="flex" flexDirection="column" px={16} gap={16} pb={16}>
            <Box display="flex" alignItems="center" gap={16}>
              <Chip minWidth={96} color={convertStatusToChipColor(workingData?.status)} variant="big">
                {convertStatusToLabel(workingData?.status)}
              </Chip>
              <Box>
                <Text variant="h2" color="darkBlue">
                  {workingData?.offer_number}
                </Text>
              </Box>
            </Box>
            <PageBody bottom={256}>
              <ContentsCard>
                {workingData?.type === RecruitType.HIRED ? (
                  <Dropdown
                    tab={tab}
                    setTab={setTab}
                    fullWidth
                    items={[
                      {
                        content: (
                          <RecruitInfo
                            data={{
                              ...offerData,
                              entry_id: workingData.entry_id || 0,
                              type: workingData.type,
                              btmem_id: workingData.btmem_id,
                              btmem_name: workingData.btmem_name || '',
                              workingAgreementStatus: workingData.status,
                            }}
                          />
                        ),

                        label: '募集情報',
                      },
                      {
                        content: (
                          <CheckInInfoContext.Provider value={checkInInfo}>
                            <QRCodeFaceRecongnition
                              workId={workingData.work_id}
                              refetch={() => {
                                refetchWorkingList();
                              }}
                            />
                          </CheckInInfoContext.Provider>
                        ),
                        label: '二次元バーコード/顔認証',
                      },
                      {
                        content: (
                          <WorkLog
                            workLogData={workLogData}
                            correctionData={correctionData}
                            status={workingData?.status}
                            refetch={() => {
                              refetchWorkingModList();
                            }}
                          />
                        ),
                        label: '勤務時間 / 修正',
                      },
                      {
                        content: (
                          <Review
                            data={{
                              bizId: workingData.biz_id,
                              btmemId: workingData.btmem_id,
                              tpmemId: workingData.tpmem_id,
                              workId: workingData.work_id,
                            }}
                            status={workingData?.status}
                            type={workingData?.type}
                          />
                        ),
                        label: 'レビュー',
                        hasNotification: isNewly,
                      },
                      {
                        content: (
                          <CostDetails
                            data={costData}
                            refetch={() => {
                              refetchWorkCostInfo();
                            }}
                          />
                        ),
                        label: '費用明細詳細',
                      },
                    ]}
                    defaultIndex={menuIndex}
                  />
                ) : (
                  <ScrollableArea>
                    <RecruitInfo
                      data={{
                        ...offerData,
                        entry_id: workingData?.entry_id || 0,
                        type: workingData?.type,
                        btmem_id: workingData?.btmem_id || 0,
                        btmem_name: workingData?.btmem_name || '',
                        workingAgreementStatus: workingData?.status || 0,
                      }}
                    />
                  </ScrollableArea>
                )}
              </ContentsCard>
            </PageBody>
          </Box>
        </Box>
      )}
    </>
    /* feat_screen_04-02-01-02_end */
  );
}

export default S0402Details;
