/**
 * Figma ID: 04-01-01, 04-01-02
 * 名称: バイト募集
 */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useReducer, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useScrollToFormError } from 'components/utils/useScrollToFormError';
import { styled } from '@linaria/react';
import { RoRes } from 'graphql/graphql-mw';
import { MainButton } from 'components/mainButton';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Tab } from 'components/newTab';
import { Modal } from 'components/newModal';
import {
  useCreateDailyDeliveryConnectApi,
  useCreateWeeklyDeliveryConnectApi,
  useCreateDailyDraftConnectApi,
  useCreateWeeklyDraftConnectApi,
} from 'pages/s04-01-01/common/hooks';
import { useNoticeOfEmploymentPdfPreview } from 'pdfComponent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useAuth } from 'hooks/authProvider';
import { Divider } from 'components/newDivider';
import { handleMutationResult } from 'components/graphqlResult/handleMutationResult';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import { ResultRows } from 'components/const';
// feat screen 04-01-01-01 start
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { MultiMenuHeader } from 'components/multiMenuHeader';
// feat screen 04-01-01-01 end
import { ModalCreateNew } from './components/modal/form';
import { ModalNewPreview } from './components/modal/preview';
import { Recruiting } from './components/recruiting';
import { Draft } from './components/draft';
import { FormData } from './common/formState';
import { ScheduleType } from './common/scheduleTypeContext';
import {
  State as WeeklyOfferNumberState,
  Action as WeekluOfferNumberAction,
  WeeklyOfferNumberContext,
} from './common/weeklyOfferNumberContext';
import { getPdfPreviewProps } from './common/utils';

const StyledDate = styled.div`
  font-size: 11px;
  color: #000;
  /* feat screen_04-01-03-16_start */
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  /* feat screen_04-01-03-16_end */
`;

enum ModalStep {
  NULL = 0,
  CREATE_NEW = 1,
  NEW_PREVIEW = 2,
  DELIVERY = 3,
  DELIVERY_DONE = 4,
  REGULAR_WEEKLY = 5,
  REGULAR_WEEKLY_DONE = 6,
  SAVE_DRAFT = 7,
}

enum ApiErrorCode {
  SUCCESS = 0,
  LIMIT = -1,
  UNKNOWN = -2,
}

type LocationState = {
  refetch?: boolean;
};

/**
 * 配信予定件数Reducer
 */
const weeklyOfferNumberInitialState: WeeklyOfferNumberState = { offerNumber: 0 };
const weeklyOfferNumberReducer = (
  state: WeeklyOfferNumberState,
  action: WeekluOfferNumberAction
): WeeklyOfferNumberState => {
  switch (action.type) {
    case 'SET_OFFER_NUMBER':
      return { offerNumber: action.content };
    default:
      throw new Error();
  }
};
/* feat screen_04-01_start */
interface PropState {
  openModal: boolean;
}
/* feat screen_04-01_end */
// バイト募集情報
export function S040101() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  /**
   * モーダル表示分け用パラメータ
   */
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.NULL);
  const [fixedFormData, setFixedFormData] = useState<FormData>({});
  const [hasNewlyItem, setHasNewlyItem] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [offers, setOffers] = useState<(RoRes | null)[]>([]);
  const [state, dispatch] = useReducer(weeklyOfferNumberReducer, weeklyOfferNumberInitialState);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [isDraftClicked, setIsDraftClicked] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  /* feat screen_04-01_start */
  const stateProps = (location?.state || {}) as PropState;
  const { openModal } = stateProps;
  useEffect(() => {
    if (openModal) {
      setModalStep(ModalStep.CREATE_NEW);
    }
  }, [openModal]);
  /* feat screen_04-01_end */
  const locationState = location.state as LocationState;
  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(0);
  // feat_common_responsive_tab_end

  /**
   * 複製・編集から戻ってきた場合にRefetchするかどうかを判定
   * 1回refetchしたらlocation.stateをクリアする
   */
  useEffect(() => {
    if (locationState?.refetch) {
      setShouldRefetch(true);
      location.state = undefined;
    }
  }, [locationState, location]);

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormData>({ reValidateMode: 'onSubmit' });

  useScrollToFormError({ isSubmitting, errors });

  const { openErrorModal } = useErrorModal();

  // 認証情報
  const auth = useAuth();
  const { tpmemId, storeInfo } = auth;

  /**
   * [API] Mutation（単日/下書き保存）
   */
  const { addTArbeitOfferMutation, setFormData: setDailyDraftFormData } = useCreateDailyDraftConnectApi(
    Number(tpmemId)
  );

  /**
   * [API] Mutation（週定期/下書き保存）
   */

  const {
    addTRegularlyOfferMutation,
    setFormData: setWeeklyDraftFormData,
    error: weeklyDraftCreationError,
  } = useCreateWeeklyDraftConnectApi(Number(tpmemId));

  /**
   * [API] Mutation（単日/配信）
   */
  const { addTArbeitOfferResMutation, setFormData: setCreateDailyDeliveryFormData } = useCreateDailyDeliveryConnectApi(
    Number(tpmemId)
  );

  /**
   * [API] Mutation（週定期/確定）
   */
  const { addTRegularlyOfferResMutation, setFormData: setCreateWeeklyDeliveryFormData } =
    useCreateWeeklyDeliveryConnectApi(Number(tpmemId));

  const apiError = weeklyDraftCreationError;

  useEffect(() => {
    if (apiError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  /**
   * [onSubmit] 下書き保存
   */
  const onClickDraftConfirm = handleSubmit((data) => {
    switch (data.check_schedule_type) {
      case ScheduleType.DAILY:
        setDailyDraftFormData(data);
        /**
         * Timeout入れないと送信データが更新されない
         */
        setTimeout(() => {
          addTArbeitOfferMutation()
            .then((result) => {
              if (!result.errors) {
                // ResultRowsをみないと登録できたかわからないため
                if (result.data?.addTArbeitOffer.resultRows === ResultRows.NOT_UPDATED) {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                } else {
                  /**
                   * リロード
                   * refetchが子コンポーネントあるのでnavigateでリロード
                   */
                  navigate('/s04-01-01', { state: { refetch: true }, replace: true });
                  setModalStep(ModalStep.SAVE_DRAFT);
                }
              } else {
                setModalStep(ModalStep.NULL);
                openErrorModal({ message: '下書き保存に失敗しました。' });
              }
            })
            .catch(() => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }, 500);
        break;
      case ScheduleType.WEEKLY:
        setWeeklyDraftFormData(data);
        /**
         * Timeout入れないと送信データが更新されない
         */
        setTimeout(() => {
          addTRegularlyOfferMutation()
            .then((result) => {
              if (!result.errors) {
                // ResultRowsをみないと登録できたかわからないため
                if (result.data?.addTRegularlyOffer.resultRows === ResultRows.NOT_UPDATED) {
                  setModalStep(ModalStep.NULL);
                  openErrorModal({ message: '下書き保存に失敗しました。' });
                } else {
                  /**
                   * リロード
                   * refetchが子コンポーネントあるのでnavigateでリロード
                   */
                  navigate('/s04-01-01', { state: { refetch: true }, replace: true });
                  setModalStep(ModalStep.SAVE_DRAFT);
                }
              } else {
                setModalStep(ModalStep.NULL);
                openErrorModal({ message: '下書き保存に失敗しました。' });
              }
            })
            .catch(() => {
              setModalStep(ModalStep.NULL);
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
        }, 500);
        break;
      default:
        break;
    }
  });

  /**
   * 確認
   */
  const onClickConfirm = handleSubmit((data) => {
    /**
     * Mutation用フォームデータをセット
     */
    switch (data.check_schedule_type) {
      case ScheduleType.DAILY:
        setCreateDailyDeliveryFormData(data);
        break;
      case ScheduleType.WEEKLY:
        setCreateWeeklyDeliveryFormData(data);
        break;
      default:
        break;
    }
    /**
     * Preview用フォームデータをセット
     */
    setFixedFormData(data);
    setModalStep(ModalStep.NEW_PREVIEW);
  });

  /**
   * [API] 配信（単日）
   */
  const onClickDailySave = () => {
    addTArbeitOfferResMutation()
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        } else {
          const errorCode = result.data?.addTArbeitOfferRes.ErrorCode;
          if (errorCode != null) {
            handleMutationResult(
              errorCode,
              () => {
                setOrderNumber(result.data?.addTArbeitOfferRes.offer_number?.toString() || '');
                setModalStep(ModalStep.DELIVERY_DONE);
              },
              ({ error }) => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  title: '配信できませんでした',
                  message: (
                    <Text variant="body14">
                      以下の理由により募集を配信できませんでした。
                      <br />
                      <Box pt={16}>{error.message}</Box>
                    </Text>
                  ),
                  onClose: error.requiredCallback,
                });
              }
            );
          } else {
            setModalStep(ModalStep.NULL);
            openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
          }
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  /**
   * [API] 配信（週定期）
   */
  const onClickWeeklySave = () => {
    addTRegularlyOfferResMutation()
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '配信に失敗しました。' });
        } else {
          const errorCode = result.data?.addTRegularlyOfferRes.ErrorCode;
          if (errorCode != null) {
            handleMutationResult(
              errorCode,
              () => {
                setOffers(result.data?.addTRegularlyOfferRes.offer || []);
                setModalStep(ModalStep.REGULAR_WEEKLY_DONE);
              },
              ({ error }) => {
                setModalStep(ModalStep.NULL);
                openErrorModal({
                  title: '配信できませんでした',
                  message: (
                    <Text variant="body14">
                      以下の理由により募集を配信できませんでした。
                      <br />
                      <Box pt={16}>{error.message}</Box>
                    </Text>
                  ),
                  onClose: error.requiredCallback,
                });
              }
            );
          } else {
            setModalStep(ModalStep.NULL);
            openErrorModal({ message: 'サーバからのエラーコードが不正です。' });
          }
        }
      })
      .catch(() => {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  const clearEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();
  };

  const doneEvent = () => {
    /**
     * モーダルを消す
     */
    setModalStep(ModalStep.NULL);
    /**
     * フォームデータをリセット
     */
    reset();
    /**
     * リロード
     * refetchが子コンポーネントあるのでnavigateでリロード
     */
    navigate(0);
  };

  /**
   * [hook] 労働条件通知書PDFプレビュー
   */
  const { previewPdf: previewNoticePdf, loading: previewLoading } = useNoticeOfEmploymentPdfPreview();

  /**
   * [onClick] 労働条件通知書PDFプレビュー
   */
  const onClickPreview = (shiftDate?: string) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (previewLoading) return;

        // PDFパラメータ取得
        const pdfProps = getPdfPreviewProps(fixedFormData, shiftDate);

        // PDFプレビュー
        const result = await previewNoticePdf(Number(auth?.tpmemId), pdfProps);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: 'プレビュー処理に失敗しました。' });
      }
    })();
  };

  const weeklyOrderNumberList = offers?.map((item, index) => (
    <React.Fragment key={`${String(item?.id)}${Math.random()}`}>
      <Box py={8}>
        <Text color="black" variant="body14">
          掲載No. {item?.offer_number || ''}
        </Text>
        <StyledDate>
          {item?.work_day || ''}（{item?.post_days || ''}）
        </StyledDate>
      </Box>
      <Divider option="horizontal" />
    </React.Fragment>
  ));

  function displayModal() {
    switch (modalStep) {
      case ModalStep.CREATE_NEW:
        return {
          // feat screen 04-01-03-01 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-01-03-01 end
          header: (
            <Text variant="h2" color="darkBlue">
              募集情報新規作成
            </Text>
          ),
          content: (
            <ModalCreateNew
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              mode="create"
              isDraftClicked={isDraftClicked}
            />
          ),
          footer: (
            // feat screen 04-01-03-01 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => {
                  setIsDraftClicked(true);
                  onClickDraftConfirm();
                }}
              >
                下書き保存
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  setIsDraftClicked(false);
                  onClickConfirm();
                }}
              >
                確認
              </MainButton>
            </Box>
            // feat screen 04-01-03-01 end
          ),
        };
      case ModalStep.NEW_PREVIEW:
        return {
          // feat screen 04-01-03-09 start
          width: !isMobile ? 800 : '100%',
          height: !isMobile ? '80%' : '72.5%',
          // feat screen 04-01-03-09 end
          header: (
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                募集情報新規作成 - プレビュー
              </Text>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY &&
                // feat screen 04-01-03-09 start
                !isMobile && (
                  <MainButton
                    onClick={() => {
                      onClickPreview();
                    }}
                    variant="secondary"
                    width={200}
                  >
                    労働条件通知書プレビュー
                  </MainButton>
                  // feat screen 04-01-03-09 end
                )}
            </Box>
          ),
          content: (
            // feat screen 04-01-03-09 add prop onClickPreview start
            <ModalNewPreview
              fixedFormData={fixedFormData}
              previewPdf={(shiftDate) => {
                onClickPreview(shiftDate);
              }}
              onClickPreview={onClickPreview}
            />
            // feat screen 04-01-03-09 add prop onClickPreview end
          ),
          footer: (
            // feat screen 04-01-03-09 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.CREATE_NEW)}
              >
                戻る
              </MainButton>
              {fixedFormData.check_schedule_type === ScheduleType.DAILY ? (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.DELIVERY);
                  }}
                >
                  作成
                </MainButton>
              ) : (
                <MainButton
                  width={!isMobile ? 104 : 96}
                  onClick={() => {
                    setModalStep(ModalStep.REGULAR_WEEKLY);
                  }}
                >
                  作成
                </MainButton>
              )}
            </Box>
            // feat screen 04-01-03-09 end
          ),
        };
      case ModalStep.DELIVERY:
        return {
          // feat screen 04-01-03-13 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-01-03-13 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">作成した募集を配信しますか？</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-13 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.NEW_PREVIEW)}
              >
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickDailySave()}>
                配信
              </MainButton>
              {/* feat screen 04-01-03-13 end */}
            </Box>
          ),
        };
      case ModalStep.DELIVERY_DONE:
        return {
          // feat screen 04-01-03-13 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 210 : 'unset',
          // feat screen 04-01-03-13 end
          header: (
            <Text variant="h2" color="darkBlue">
              配信 完了
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">募集の配信が完了しました。</Text>
              <br />
              <Text variant="body14">掲載No. {orderNumber}</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-13 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-03-13 end */}
            </Box>
          ),
        };
      case ModalStep.REGULAR_WEEKLY:
        return {
          // feat screen 04-01-03-09 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 238 : 'unset',
          // feat screen 04-01-03-09 end
          header: (
            <ModalHeaderContentWrapper>
              <Text variant="h2" color="darkBlue">
                週定期募集作成
              </Text>
            </ModalHeaderContentWrapper>
          ),
          content: (
            <>
              <Text variant="body14">週定期募集の作成を確定しますか？</Text>
              <br />
              <Text variant="body14">合計{state.offerNumber}件の募集の配信が予約されます。</Text>
              <br />
              <Text variant="body14">この操作は中止できません。</Text>
            </>
          ),
          footer: (
            // feat screen 04-01-03-09 start
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => clearEvent()}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                variant="secondary"
                onClick={() => setModalStep(ModalStep.NEW_PREVIEW)}
              >
                戻る
              </MainButton>
              <MainButton width={!isMobile ? 104 : 96} onClick={() => onClickWeeklySave()}>
                確定
              </MainButton>
            </Box>
            // feat screen 04-01-03-09 end
          ),
        };
      case ModalStep.REGULAR_WEEKLY_DONE:
        return {
          // feat screen 04-01-03-09 start
          width: !isMobile ? 424 : '100%',
          height: !isMobile ? 396 : '56%',
          // feat screen 04-01-03-09 end
          header: (
            <Text variant="h2" color="darkBlue">
              週定期募集作成 完了
            </Text>
          ),
          content: (
            <>
              <Box>
                <Text variant="body14">週定期募集の作成が完了しました。</Text>
                <br />
                <Text variant="body14">以下の項目が掲載予約されました。</Text>
              </Box>
              {/* feat screen 04-01-03-09 start */}
              <Box mt={!isMobile ? 4 : -4}>{weeklyOrderNumberList}</Box>
              {/* feat screen 04-01-03-09 end */}
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-09 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-03-09 end */}
            </Box>
          ),
        };
      case ModalStep.SAVE_DRAFT:
        return {
          // feat screen 04-01-03-09 start
          width: !isMobile ? 384 : '100%',
          height: !isMobile ? 189 : 'unset',
          // feat screen 04-01-03-09 end
          header: (
            <Text variant="h2" color="darkBlue">
              下書き保存
            </Text>
          ),
          content: <Text variant="body14">募集の下書きを保存しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen 04-01-03-09 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => doneEvent()}>
                閉じる
              </MainButton>
              {/* feat screen 04-01-03-09 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }
  const modalContent = displayModal();

  const memorizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  /* feat_screen_04-01-01-01 start */
  const StyledRelativeWrap = styled.div`
    display: block;
    position: relative;
  `;
  const StyledAbsoluteButton = styled.div`
    display: block;
    position: absolute;
    top: 70px;
    right: 20px;
  `;
  /* feat_screen_04-01-01-01 end */

  /**
   * Render
   */
  return (
    <WeeklyOfferNumberContext.Provider value={memorizedValue}>
      {/* feat_screen_04-01-01-02 start */}
      {!isMobile ? (
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          <Box height={30} mb={26} display="flex" alignItems="center" justifyContent="space-between">
            <Text color="darkBlue" variant="h1">
              バイト募集
            </Text>
            <MainButton icon="addCircle" onClick={() => setModalStep(ModalStep.CREATE_NEW)} variant="primary">
              募集作成
            </MainButton>
          </Box>
          <Box flex="auto">
            <Card overflow="hidden">
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" pt={24}>
                  <Tab
                    items={[
                      {
                        content: <Recruiting setHasNewlyItem={setHasNewlyItem} shouldRefetch={shouldRefetch} />,
                        hasNotification: hasNewlyItem,
                        label: '募集',
                      },
                      {
                        content: <Draft />,
                        label: '下書き',
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      ) : (
        <Box display="flex" overflow="auto" flexDirection="column" width="100vw" height="100%">
          <StyledRelativeWrap>
            <Box>
              <MultiMenuHeader
                items={[
                  {
                    label: '募集・採用',
                  },
                  {
                    label: 'バイト募集',
                  },
                ]}
                mainHeader="バイト募集"
              />
              <StyledAbsoluteButton>
                <MainButton
                  width={117}
                  customHeight={40}
                  icon="addCircle"
                  onClick={() => setModalStep(ModalStep.CREATE_NEW)}
                  variant="primary"
                >
                  募集作成
                </MainButton>
              </StyledAbsoluteButton>
            </Box>
          </StyledRelativeWrap>
          <Box px={16} mt={16} overflow="none" pb={isMobile ? 76 : 16}>
            <Card overflow="none">
              <Box display="flex" width="100%" flexDirection="column">
                <Box display="flex" width="100%" alignItems="center">
                  <Box display="flex" width="100%" flex="1">
                    <Dropdown
                      tab={tab}
                      setTab={setTab}
                      width="55%"
                      items={[
                        {
                          content: <Recruiting setHasNewlyItem={setHasNewlyItem} shouldRefetch={shouldRefetch} />,
                          hasNotification: hasNewlyItem,
                          label: '募集',
                        },
                        {
                          content: <Draft />,
                          label: '下書き',
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      )}
      {/* feat_screen_04-01-01-02 end */}
      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== ModalStep.NULL}
        onClose={() => clearEvent()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
    </WeeklyOfferNumberContext.Provider>
  );
}
