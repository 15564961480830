import { Modal } from 'components/newModal';
import { AddThanksMessageModalForm } from 'pages/s05-01/type';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddThanksMessage } from './addThanksMessage';
import { Confirm } from './confirm';
import { Complete } from './complete';

enum ModalStep {
  ADD_THANKS_MESSAGE = 1,
  CONFIRM = 2,
  COMPLETE = 3,
}

export function AddThanksMessageModal(props: { isOpen: boolean; onClose: () => void; refetch: () => void }) {
  const { isOpen, onClose, refetch } = props;

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.ADD_THANKS_MESSAGE);

  const { control, getValues, reset } = useForm<AddThanksMessageModalForm>();

  const onModalClose = () => {
    onClose();
    reset();
    setModalStep(ModalStep.ADD_THANKS_MESSAGE);
  };

  const addThanksMessageContent = AddThanksMessage(control, onModalClose, () => setModalStep(ModalStep.CONFIRM));

  const confirmContent = Confirm(
    getValues,
    onModalClose,
    () => setModalStep(ModalStep.ADD_THANKS_MESSAGE),
    () => {
      refetch();
      setModalStep(ModalStep.COMPLETE);
    }
  );

  const completeContent = Complete(onModalClose);

  const modalContent = {
    [ModalStep.ADD_THANKS_MESSAGE]: addThanksMessageContent,
    [ModalStep.CONFIRM]: confirmContent,
    [ModalStep.COMPLETE]: completeContent,
  };

  return <Modal isOpen={isOpen} onClose={onClose} {...modalContent[modalStep]} overflow="display" />;
}
