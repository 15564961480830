import { useRank } from 'hooks/master/useRank';
import { FormData } from 'pages/s04-01-01/common/formState';
import { autoRecruitPreviewItems } from './autoRecruitPreviewItems';

type Props = {
  rank: FormData['rank'];
  score: FormData['score'];
  hireFee: FormData['hire_fee'];
  cancelPer: FormData['cancel_per'];
  imminentPer: FormData['imminent_per'];
};

export function useAutoRecruitPreviewItems() {
  const { getRankLabel } = useRank();

  function createAutoRecruitePreviewItems(props: Props) {
    const { rank, score, hireFee, cancelPer, imminentPer } = props;
    return autoRecruitPreviewItems({
      rank: rank ? getRankLabel(Number(rank)) : undefined,
      score: score ? Number(score) : undefined,
      hireFee: hireFee ? Number(hireFee) : undefined,
      cancelPer: cancelPer ? Number(cancelPer) : undefined,
      imminentPer: imminentPer ? Number(imminentPer) : undefined,
    });
  }

  return { createAutoRecruitePreviewItems };
}
