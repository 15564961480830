import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';

export type VariantType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'bigTitle'
  | 'body18'
  // feat_common_responsive_add_size_text_start
  | 'body16p5'
  // feat_common_responsive_add_size_text_end
  | 'body16'
  // feat_common_responsive_add_size_text_start
  | 'body15'
  // feat_common_responsive_add_size_text_end  
  | 'body14'
  | 'caption12'
  | 'caption11'
  | 'chip10'
  // feat_common_responsive_add_size_text_start
  | 'chip9'
  // feat_common_responsive_add_size_text_end
;

export const colorPalette = {
  primaryBlue: '#008599',
  blue: '#00A5BF',
  darkBlue: '#16495F',
  blueGray: '#5F797D',
  liteBlue: '#F8FEFF',
  darkGray: '#484848',
  liteGray: '#969696',
  superLiteGray: '#DBDBDB',
  bgGray: '#FBFBFB',
  white: '#FFFFFF',
  black: '#000000',
  modalBg: 'Rgba(0,0,0,0.5)',
  redLite: '#F35555',
  cautionRed: '#E50000',
  okBlue: '#3170C8',
  warningYellow: '#E8A700',
  unlinkBlue: '#7CC1CC',
  unlinkBlue2: '#addae0',
} as const;

export type ColorKeyType = keyof typeof colorPalette;
type ColorValueType = typeof colorPalette[ColorKeyType];

export interface Props {
  children: ReactNode;
  variant?: VariantType;
  bold?: boolean;
  underline?: boolean;
  color?: ColorKeyType;
  align?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
  lineClamp?: number;
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  textOverflow?: 'clip' | 'ellipsis';
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial' | 'revert' | 'unset';
  // feat common styled mobile add keep-all start
  wordBreak?: 'normal' | 'break-all' | 'keep-all';
  // feat common styled mobile add keep-all end
}

const baseStyle = {
  fontFamily:
    'Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  fontStyle: 'normal',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
};

const StyledH1 = styled.h1<{
  color: ColorValueType;
  align?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
}>`
  ${baseStyle}
  color: ${({ color }) => color};
  text-align: ${({ align }) => align || 'left'};
  display: ${({ display }) => display || 'block'};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
`;

const StyledH2 = styled.h2<{
  color: ColorValueType;
  align?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
}>`
  ${baseStyle}
  color: ${({ color }) => color};
  text-align: ${({ align }) => align || 'left'};
  display: ${({ display }) => display || 'block'};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const StyledH3 = styled.h3<{
  color: ColorValueType;
  align?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
}>`
  ${baseStyle}
  color: ${({ color }) => color};
  text-align: ${({ align }) => align || 'left'};
  display: ${({ display }) => display || 'block'};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
`;

const StyledSpan = styled.span<{
  fontSize: number;
  fontWeight: number;
  lineHeight: number;
  underline?: boolean;
  color: ColorValueType;
  align?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
  lineClamp?: number;
  textOverflow?: 'clip' | 'ellipsis';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial' | 'revert' | 'unset';
  // feat common styled mobile add keep-all start
  wordBreak?: 'normal' | 'break-all'| 'keep-all';
  // feat common styled mobile add keep-all end
}>`
  ${baseStyle}
  color: ${({ color }) => color};
  text-align: ${({ align }) => align || 'left'};
  display: ${({ display, lineClamp }) => (lineClamp ? ' -webkit-box' : display || 'inline')};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ lineHeight }) => lineHeight}px;
  text-decoration-line: ${({ underline }) => (underline ? 'underline' : 'none')};
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp || 'none'};
  -webkit-box-orient: ${({ lineClamp }) => (lineClamp ? 'vertical' : 'inline-axis')};
  overflow: ${({ lineClamp, overflow }) => (lineClamp ? 'hidden' : overflow || 'visible')};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'clip'};
  word-break: ${({ wordBreak }) => wordBreak || 'normal'};
  /* feat common_mobile_style_start */
  @media only screen and (max-width: 768px) {
    text-decoration-line: ${({ underline,color }) => (underline ? `underline ${color}`: 'none')};
    word-break: ${({ wordBreak}) => wordBreak === 'break-all' || wordBreak === 'keep-all' ? wordBreak : 'break-all'};
  }
/* feat common_mobile_style_end */
`;

export function Text({
  children,
  variant = 'body18',
  bold,
  underline,
  color = 'black',
  align,
  display,
  lineClamp,
  textOverflow = 'clip',
  whiteSpace = 'normal',
  overflow = 'visible',
  wordBreak = 'normal'
}: Props) {
  switch (variant) {
    case 'h1':
      return (
        <StyledH1 color={colorPalette[color]} align={align} display={display}>
          {children}
        </StyledH1>
      );
    case 'h2':
      return (
        <StyledH2 color={colorPalette[color]} align={align} display={display}>
          {children}
        </StyledH2>
      );
    case 'h3':
      return (
        <StyledH3 color={colorPalette[color]} align={align} display={display}>
          {children}
        </StyledH3>
      );
    case 'bigTitle':
      return (
        <StyledSpan
          fontSize={24}
          fontWeight={400}
          lineHeight={36}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    case 'body18':
      return (
        <StyledSpan
          fontSize={18}
          fontWeight={bold ? 700 : 400}
          lineHeight={27}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_start
    case 'body16p5':
      return (
        <StyledSpan
          fontSize={16.5}
          fontWeight={bold ? 700 : 400}
          lineHeight={24}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_end
    case 'body16':
      return (
        <StyledSpan
          fontSize={16}
          fontWeight={bold ? 700 : 400}
          lineHeight={24}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_start
    case 'body15':
      return (
        <StyledSpan
          fontSize={15}
          fontWeight={bold ? 700 : 400}
          lineHeight={24}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_end
    case 'body14':
      return (
        <StyledSpan
          fontSize={14}
          fontWeight={bold ? 700 : 400}
          lineHeight={21}
          color={colorPalette[color]}
          align={align}
          display={display}
          underline={underline}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          lineClamp={lineClamp}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    case 'caption12':
      return (
        <StyledSpan
          fontSize={12}
          fontWeight={bold ? 700 : 400}
          lineHeight={18}
          color={colorPalette[color]}
          align={align}
          display={display}
          underline={underline}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          lineClamp={lineClamp}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    case 'caption11':
      return (
        <StyledSpan
          fontSize={11}
          // feat_common_responsive_add_size_text_start     
          fontWeight={bold ? 700 : 400}
          // feat_common_responsive_add_size_text_end     
          lineHeight={18}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    case 'chip10':
      return (
        <StyledSpan
          fontSize={10}
          fontWeight={bold ? 700 : 400}
          lineHeight={15}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_start
    case 'chip9':
      return (
        <StyledSpan
          fontSize={9.27}
          fontWeight={bold ? 700 : 400}
          lineHeight={16}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
    // feat_common_responsive_add_size_text_end
    default:
      return (
        <StyledSpan
          fontSize={18}
          fontWeight={bold ? 700 : 400}
          lineHeight={27}
          color={colorPalette[color]}
          align={align}
          display={display}
          lineClamp={lineClamp}
          textOverflow={textOverflow}
          whiteSpace={whiteSpace}
          overflow={overflow}
          wordBreak={wordBreak}
        >
          {children}
        </StyledSpan>
      );
  }
}
