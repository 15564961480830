import React from 'react';
import { Divider } from 'components/newDivider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from 'components/text';
import { Box } from 'components/box';

/**
 * Figma ID
 * 09-07-01
 * 決済会社情報
 */

function PaymentCompanyInfo() {
  // feat_screen_09-06-02_start
  const isMobile = useIsMobile();
  return !isMobile ? (
    // feat_screen_09-06-02_end
    <Box overflow="scroll" px={16} py={18} width="100%">
      <Box mx={8} mb={8}>
        <Text variant="h2" color="darkBlue">
          決済会社情報
        </Text>
      </Box>
      <Box mx={8} my={16}>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" flexDirection="column" gap={16} width={830}>
        <Box mx={8}>
          <Text variant="h3" color="blueGray">
            ご請求方法
          </Text>
        </Box>
        <Box mx={8}>
          <Text variant="body14">・請求書は毎月8日に、マネーフォワードケッサイ株式会社よりメール送信されます。</Text>
        </Box>
        <Box mx={8}>
          <Text variant="h3" color="blueGray">
            お支払い方法（銀行振込）
          </Text>
        </Box>
        <Box mx={8}>
          <Text variant="body14">
            ・お支払先口座&nbsp;&nbsp;&nbsp;：
            &nbsp;&nbsp;請求書に記載（マネーフォワードケッサイ株式会社の銀行口座になります。）
            <br />
            ・銀行振込手数料：&nbsp;&nbsp;貴社でのご負担をお願いいたします。
            <br />
            ・お支払期日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;請求書に記載。
            <br />
          </Text>
        </Box>
        <Box mx={8}>
          <Text variant="h3" color="blueGray">
            お支払い方法（口座振込）
          </Text>
        </Box>
        <Box mx={8}>
          <Text variant="body14">
            ・振替元口座&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;貴社申請登録の指定口座になります。
            <br />
            ・振替先口座&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;マネーフォワードケッサイ株式会社の銀行口座になります。
            <br />
            ・口座振替手数料：&nbsp;&nbsp;無料（マネーフォワードケッサイ株式会社が負担いたします。）
            <br />
            ・口座振替日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;原則、お取引の翌月27日となります。
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14" color="darkGray">
              ※新規に口座振替のご利用を希望される場合は、フィル株式会社までお問合せ頂くか、請求書受領後にマネーフォワード
              ケ
              <br />
              ッサイイン ボックス（
              <a href="https://b.mfk.jp/get-start">
                <Text variant="body14" color="darkGray">
                  https://b.mfk.jp/get-start
                </Text>
              </a>
              ）よりお申込みください。マネーフォワード ケッサイ
              インボックスには請求書送付先のメールアドレスにてログインできます。
            </Text>
          </Box>
        </Box>
        <Box mx={8}>
          <Text variant="h3" color="blueGray">
            その他
          </Text>
        </Box>
        <Box mx={8}>
          <Text variant="body14">
            ・マネーフォワード ケッサイはマネーフォワードケッサイ株式会社が運営しております。
            <br />
            ・当サービスではマネーフォワードケッサイ株式会社が、請求業務の受託及び代金債権の収受権限の付与を受け、又は、販売元か
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14">
              ら当該債権の債権譲渡を受けて、請求書の発行や代金の回収、管理を行っております。
              <br />
            </Text>
          </Box>
          <Text variant="body14">
            ・審査の結果、別のお支払方法をご提案する可能性がございます。予めご了承ください。
            <br />
            ・請求書の発行元およびご入金先口座名義は、マネーフォワードケッサイ株式会社となります。お間違えのないようご注意ください。
            <br />
            ・領収書の発行をご希望の場合、以下のお問い合わせフォームよりお問合せください。
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14" color="darkGray">
              <a href="https://inquiry.mfkessai.co.jp/articles/others/1">
                <Text variant="body14" color="darkGray">
                  https://inquiry.mfkessai.co.jp/articles/others/1
                </Text>
              </a>
              <br />
              ※発行までに、1週間ほどお時間をいただく場合がございます。
              <br />
              ※領収書の発行は、ご入金単位毎にお問い合わせいただく必要がございます。
            </Text>
          </Box>
          <Text variant="body14">・その他、よくあるご質問やお問い合わせにつきましては下記をご参照ください。</Text>
          <br />
          <Box mx={14}>
            <a href="https://inquiry.mfkessai.co.jp/">
              <Text variant="body14" color="darkGray">
                https://inquiry.mfkessai.co.jp/
              </Text>
            </a>
            <br />
            <Text variant="body14" color="darkGray">
              ※ご請求の内容（お宛名、明細、金額を含む）に関するお問い合わせにつきましては、フィル株式会社宛てにご連絡ください。
            </Text>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <a href="https://mfkessai.co.jp/kessai/buyer" target="_blank" rel="noreferrer">
            <img src="https://c.mfkessai.co.jp/img/material/ec/bnr01_468_150.png" alt="moneyforward kessai" />
          </a>
        </Box>
      </Box>
    </Box>
    // feat_screen_09-06-02_start
  ) : (
    <Box overflow="auto" height="calc(100% - 51px)" px={16} pt={16} width="100%">
      <Box display="flex" justifyContent='center'>
        <Text variant="h2" color="darkBlue">
          決済会社情報
        </Text>
      </Box>
      <Box my={16}>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" flexDirection="column"  gap={16} width='100%'>
        <Box display="flex" flexDirection="column" gap={8}>
          <Text variant="h3" color="blueGray">
            ご請求方法
          </Text>
          <Text variant="body14">・請求書は毎月8日に、マネーフォワードケッサイ株式会社よりメール送信されます。</Text>  
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Text variant="h3" color="blueGray">
            お支払い方法（銀行振込）
          </Text>
          <Text variant="body14">
            ・お支払先口座&nbsp;&nbsp;&nbsp;：
            &nbsp;&nbsp;請求書に記載（マネーフォワードケッサイ株式会社の銀行口座になります。）
            <br />
            ・銀行振込手数料：&nbsp;&nbsp;貴社でのご負担をお願いいたします。
            <br />
            ・お支払期日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;請求書に記載。
            <br />
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Text variant="h3" color="blueGray">
            お支払い方法（口座振込）
          </Text>
          <Text variant="body14">
            ・振替元口座&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;貴社申請登録の指定口座になります。
            <br />
            ・振替先口座&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;マネーフォワードケッサイ株式会社の銀行口座になります。
            <br />
            ・口座振替手数料：&nbsp;&nbsp;無料（マネーフォワードケッサイ株式会社が負担いたします。）
            <br />
            ・口座振替日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;原則、お取引の翌月27日となります。
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14" color="darkGray">
              ※新規に口座振替のご利用を希望される場合は、フィル株式会社までお問合せ頂くか、請求書受領後にマネーフォワード
              ケ
              <br />
              ッサイイン ボックス（
              <a href="https://b.mfk.jp/get-start">
                <Text variant="body14" color="darkGray">
                  https://b.mfk.jp/get-start
                </Text>
              </a>
              ）よりお申込みください。マネーフォワード ケッサイ
              インボックスには請求書送付先のメールアドレスにてログインできます。
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Text variant="h3" color="blueGray">
            その他
          </Text>
          <Text variant="body14">
            ・マネーフォワード ケッサイはマネーフォワードケッサイ株式会社が運営しております。
            <br />
            ・当サービスではマネーフォワードケッサイ株式会社が、請求業務の受託及び代金債権の収受権限の付与を受け、又は、販売元か
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14">
              ら当該債権の債権譲渡を受けて、請求書の発行や代金の回収、管理を行っております。
              <br />
            </Text>
          </Box>
          <Text variant="body14">
            ・審査の結果、別のお支払方法をご提案する可能性がございます。予めご了承ください。
            <br />
            ・請求書の発行元およびご入金先口座名義は、マネーフォワードケッサイ株式会社となります。お間違えのないようご注意ください。
            <br />
            ・領収書の発行をご希望の場合、以下のお問い合わせフォームよりお問合せください。
            <br />
          </Text>
          <Box mx={14}>
            <Text variant="body14" color="darkGray">
              <a href="https://inquiry.mfkessai.co.jp/articles/others/1">
                <Text variant="body14" color="darkGray">
                  https://inquiry.mfkessai.co.jp/articles/others/1
                </Text>
              </a>
              <br />
              ※発行までに、1週間ほどお時間をいただく場合がございます。
              <br />
              ※領収書の発行は、ご入金単位毎にお問い合わせいただく必要がございます。
            </Text>
          </Box>
          <Text variant="body14">・その他、よくあるご質問やお問い合わせにつきましては下記をご参照ください。</Text>
          <br />
          <Box mx={14}>
            <a href="https://inquiry.mfkessai.co.jp/">
              <Text variant="body14" color="darkGray">
                https://inquiry.mfkessai.co.jp/
              </Text>
            </a>
            <br />
            <Text variant="body14" color="darkGray">
              ※ご請求の内容（お宛名、明細、金額を含む）に関するお問い合わせにつきましては、フィル株式会社宛てにご連絡ください。
            </Text>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" width='100%' mb={16}>
          <a href="https://mfkessai.co.jp/kessai/buyer" target="_blank" rel="noreferrer">
            <img src="https://c.mfkessai.co.jp/img/material/ec/bnr01_468_150.png" alt="moneyforward kessai" width='100%'/>
          </a>
        </Box>
      </Box>
    </Box>
    // feat_screen_09-06-02_end
  );
}
export default PaymentCompanyInfo;
