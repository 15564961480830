// feat screen 00 start
/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { styled } from '@linaria/react';
import { DatePicker} from 'components/datePicker/quickDatePicker';
import { format, addMonths, subMonths, format as formatDate } from 'date-fns';
import { Icon } from 'components/icon';
import { QUICK_ACCESS_COLOR } from 'components/assets/css/style';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useGetVtWorkingListNewlyByTpmemIdQuery} from 'graphql/graphql-mw';
import { convertVTWorkingListToObject } from 'pages/s04-02/components/common';
import { AgreementStatus, RecruitStatus } from 'components/const';
import { Loading } from 'components/loading/loading';
import { useOfferListConnectApi } from 'pages/s04-01-01/common/hooks';
import { useGetBtmemOfferListConnectApi } from 'pages/s04-03/common/hooks';

const StyledCard = styled.div<{
  bgColor: string;
}>`
  display: block;
  background-color: ${({ bgColor }) => bgColor};
  width: 10px;
  height: 13px;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.img`
  height: 57.1px;
  width: 280px;
  object-fit: contain;
`;

const StyledButton = styled.button`
  display: block;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const StyledIcon = styled.div`
  transform: translateY(4px);
`;


export function Shortcuts() {
  const navigate = useNavigate();
  const { tpmemId: loginTpMemId } = useAuth();

  // ログインしていなければ、ログイン画面に遷移
  if (loginTpMemId === null) {
    navigate('/signin');
  }
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  const {
    data: vtWorkingListNewlyByTpmemIdData,
    loading: loadingWorkingList,
    error: vtWorkingListNewlyByTpmemIdError,
  } = useGetVtWorkingListNewlyByTpmemIdQuery({
    variables: {
      tpmem_id: loginTpMemId || 0,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: VtBtmemOfferList,
    loading: loadingOfferList,
    error: vtBtmemOfferListError,
  } = useGetBtmemOfferListConnectApi(Number(loginTpMemId));

  const {data : vtArbeitOfferListByTpmemIdData,  error: getVtArbeitOfferListByTpmemIdError } = useOfferListConnectApi(Number(loginTpMemId));
  useEffect(() => {
    if (vtWorkingListNewlyByTpmemIdError || getVtArbeitOfferListByTpmemIdError || vtBtmemOfferListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vtWorkingListNewlyByTpmemIdError,getVtArbeitOfferListByTpmemIdError, vtBtmemOfferListError, openErrorModal]);

 

  const vTWorkingList = vtWorkingListNewlyByTpmemIdData?.getVTWorkingListNewlyByTpmemId?.list?.filter(
    (e): e is Exclude<typeof e, null> => e !== null
  );

  const offerData = vtArbeitOfferListByTpmemIdData?.getVTArbeitOfferListByTpmemId?.list?.filter(
    (e): e is Exclude<typeof e, null> => e !== null
  ) || [];

  const reserveOfferList = VtBtmemOfferList?.getVTBtmemOfferListByTpmemId?.filter(
    (e): e is Exclude<typeof e, null> => e !== null
  ) || [];
  
  const data = convertVTWorkingListToObject(vTWorkingList || []);

  const handleNavigate = (link?: string, state?: object) => {
    if (link) {
      navigate(link, {state});
    }
  };

  const handleOnCalendarChange = (value : Date | null) => {
    const requestCorrectionData = data.filter(
      (d) =>
        (d.status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
        d.status === AgreementStatus.REQUEST_CORRECTION_REJECTED) && d.arbeitStartDate?.match(formatDate(value as Date,"yyyy年MM月dd日"))
    );

    const recruitingOffers = offerData?.filter(
      (d) =>
        d.status === RecruitStatus.RECRUITING && d.begin_date?.match(formatDate(value as Date,"yyyy年MM月dd日")) && (d.entry_count ?? 0) > 0
    );
    

    const reverseOffers = reserveOfferList?.filter(
      (d) => 
        {
          const formatBeginDay = d.begin_day ? formatDate(new Date(d.begin_day), 'yyyy年MM月dd日') : '';
          return formatBeginDay.match(formatDate(value as Date, "yyyy年MM月dd日"));
        }
    );
    
    setSelectedDate(value);
    handleNavigate('/s00/s00-02', {
      dateValue : value,
      isConfirmCorrection: requestCorrectionData.length > 0,
      hasApplicantConfirmation: recruitingOffers.length > 0,
      hasConfirmReverseOffer: reverseOffers.length > 0,
      rowObject: {
        link: `${"s04-01-01"}/${String(recruitingOffers[0]?.id)}`,
        id : recruitingOffers[0]?.id,
        tpmem_id : recruitingOffers[0]?.tpmem_id,
        status : recruitingOffers[0]?.status,
        main_count : recruitingOffers[0]?.main_count,
        sub_count : recruitingOffers[0]?.sub_count,
        normal_count : recruitingOffers[0]?.normal_count
      }
    });
  };

  return (
    // feat_common_responsive_layout_start
    <Box>
      {(loadingWorkingList && loadingOfferList) && <Loading />}
      <Box height={57}>
        <StyledLogoWrapper>
          <StyledLogo src="/bitravel_logo2.png" alt="logo"/>
        </StyledLogoWrapper>
      </Box>
      <Box height={68} mt={17}>
        <Box display='flex' justifyContent='space-between' width="100%" height={27} alignItems='center'>
          <Text variant='body18' bold>{format(selectedDate as Date ,'yyyy年MM月')}</Text>
          <Box display='flex' gap={6}>
            <StyledIcon>
              <Icon
                name="helpOutline"
                color="#000"
                onClick={() => {
                  const helpOfServiceUrl = process.env.REACT_APP_HELP_SERVICE;
                  if (helpOfServiceUrl) {
                    window.open(helpOfServiceUrl, '_blank');
                  }
                }}
              />
            </StyledIcon>
            <StyledButton onClick={() => {
              setSelectedDate(subMonths(selectedDate as Date, 1));
            }}>
              <Icon color="#000" name="chevronRwd" size={30}/>
            </StyledButton>
            <StyledButton onClick={() => {
              setSelectedDate(addMonths(selectedDate as Date, 1));
            }}>
              <Icon color="#000" name="chevronFwd" size={30}/>
            </StyledButton>
          </Box>
        </Box>
        <Box my={16} backgroundColor="bgYellow">
          <DatePicker 
            workingList={data || []} 
            offerData={offerData || []}
            reserveOffer={reserveOfferList || []}
            selected={selectedDate as Date} 
            onChange={(value) => handleOnCalendarChange(value)} 
          />
        </Box>
        <Box display='flex' gap={12} my={16}>
          <Box display='flex' gap={4} alignItems='center'>
            <StyledCard bgColor={ QUICK_ACCESS_COLOR.SUCCESS }/>
            <Text variant='body14'> 
              修正依頼
            </Text>
          </Box>
          <Box display='flex' gap={4} alignItems='center'>
            <StyledCard bgColor={ QUICK_ACCESS_COLOR.WARNING }/>
            <Text variant='body14'> 
              応募者有
            </Text>
          </Box>
          <Box display='flex' gap={4} alignItems='center'>
            <StyledCard bgColor={ QUICK_ACCESS_COLOR.INFO }/>
            <Text variant='body14'> 
              逆オファー有
            </Text>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' gap={16} pb={30}>
          <Box display='flex' gap={16}>
            <MainButton variant='secondary' customHeight={40} fullWidth onClick={() => {
              handleNavigate('/s09', {
                menuIndex: 5,
              });
            }}>出勤バーコード</MainButton>
            <MainButton variant='secondary' customHeight={40} fullWidth onClick={() => {
              handleNavigate('/s09', {
                menuIndex: 5,
                tabIndex: 1
              });
            }}>退勤バーコード</MainButton>
          </Box>
          <Box display='flex' gap={16}>
            <MainButton variant='secondary' customHeight={40} fullWidth onClick={() => {
              handleNavigate('/', {
                tabIndex: 3
              });
            }}>
              レビュー管理
            </MainButton>
            <MainButton variant='secondary' customHeight={40} fullWidth onClick={() => {
              handleNavigate('/s00/s00-01');
            }}>
              所属管理
            </MainButton>
          </Box>
          <MainButton variant='secondary' customHeight={40} fullWidth onClick={() => {
              handleNavigate('/', {
                tabIndex: 7
              });
            }}>通常ホーム</MainButton>
        </Box>
      </Box>
    </Box>
  );
}
// feat screen 00 end