/**
 * Figma ID: 04-01-01-02, 04-01-01-13, 04-01-01-23
 * 名称: バイト募集（募集情報、応募、応募/採用履歴）
 */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PageBody, ContentsCard } from 'components/assets/css/pages/pageStyle';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { Chip } from 'components/chip';
import { Box } from 'components/box';
import { Loading } from 'components/loading/loading';
import { ToggleButton } from 'components/toggleButton';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
// feat screen 04-01-01-02 start
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { MultiMenuHeader } from 'components/multiMenuHeader';
// feat screen 04-01-01-02 end
import { BasicInfo } from './components/basicInfo';
import { RecruitInfo } from './components/recruitInfo';
import { History } from './components/history';
import { RecruitmentStatus } from '../common/const';
import {
  useOfferInfoConnectApi,
  useUpdateOfferStatus6ByIdConnectApi,
  useUpdateOfferStatus1ByIdConnectApi,
} from '../common/hooks';

// パラメータ
interface State {
  id: number; // バイト募集ID
  id2: number; // 店舗会員ID
  status: number; // ステータス
  mainCount: number; // 所属応募人数
  subCount: number; // サブ応募人数
  normalCount: number; // 一般応募人数
  menuIndex?: number; // サブメニューのインデックス
}

enum ModalStep {
  NULL = 0,
  STATUS_CHANGE = 1,
  STATUS_CLOSE = 2,
  STATUS_CHANGE_SUSPENSION = 3,
  STATUS_CLOSE_SUSPENSION = 4,
}

// 募集情報
export function S040101Details() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { id } = useParams();
  // パラメータ取得
  const location = useLocation();
  const locationState = location.state as State;
  const [modalStep, setModalStep] = useState<ModalStep>(0);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const { openErrorModal } = useErrorModal();
  // feat_common_responsive_tab_start
  const [tab, setTab] = React.useState<number>(locationState?.menuIndex || 0);
  // feat_common_responsive_tab_end

  /**
   * ［API］募集情報取得
   */
  const {
    data: basicInfoResponse,
    loading: basicInfoLoading,
    error: basicInfoError,
    refetch: basicInfoRefetch,
  } = useOfferInfoConnectApi(Number(id));

  React.useEffect(() => {
    if (basicInfoError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [basicInfoError, openErrorModal]);

  const basicInfoData = basicInfoResponse?.getVTArbeitOfferInfoById.info;
  const parentEntryList = basicInfoResponse?.getVTArbeitOfferInfoById.list[0];
  const entryCount = basicInfoResponse?.getVTArbeitOfferInfoById.list[0].entry_count;
  const hasNewlyItem = (basicInfoResponse?.getVTArbeitOfferInfoById?.notify?.cnt ?? 0) > 0;

  const [currentToggleState, setCurrentToggleState] = useState<string>('');

  useEffect(() => {
    if (!parentEntryList?.status) return;
    setCurrentToggleState(
      parentEntryList?.status !== RecruitmentStatus.SUSPENSION_OF_RECRUITMENT ? 'active' : 'cessation'
    );
  }, [parentEntryList?.status]);

  /**
   * [API] Mutation 募集情報ステータス変更（アクティブ--->募集停止）
   */

  const {
    updateTArbeitOfferStatus6ByIdMutation: updateToSuspension,
    loading: toSuspensionLoading,
    error: toSuspensionError,
  } = useUpdateOfferStatus6ByIdConnectApi(Number(id));

  /**
   * [API] Mutation 募集情報ステータス変更（募集停止--->アクティブ）
   */

  const {
    updateTArbeitOfferStatus1ByIdMutation: updateToActive,
    loading: toActiveLoading,
    error: toActiveError,
  } = useUpdateOfferStatus1ByIdConnectApi(Number(id));

  const apiError = toSuspensionError || toActiveError;

  useEffect(() => {
    if (apiError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  // HOME画面からの遷移に対応
  useEffect(() => {
    setOrderNumber(basicInfoData?.offer_number || '');
  }, [basicInfoData, setOrderNumber]);

  /**
   * トグルボタン
   */
  const currentHandleToggleStatus = (value: string) => {
    if (value === 'cessation') {
      setModalStep(ModalStep.STATUS_CHANGE);
    } else {
      setModalStep(ModalStep.STATUS_CHANGE_SUSPENSION);
    }
  };

  const resetToggoleStatus = (value: string) => {
    setCurrentToggleState(value);
    setModalStep(ModalStep.NULL);
  };

  function displayModal() {
    switch (modalStep) {
      case ModalStep.STATUS_CHANGE:
        return {
          width: 384,
          // feat screen_04-01-01-01 start
          height: !isMobile ? 230 : "unset" ,
          // feat screen_04-01-01-01 end
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          // feat screen_04-01-01-03 start
          content: !isMobile ? (
            <>
              <Text variant="body14">この募集を停止中に変更しますか？</Text>
              <br />
              <Text variant="body14">申込み済みの採用が自動的にキャンセルになります。</Text>
            </>
          ) : (
            // Responsive Component
            <Text display="block" variant="body14">この募集を停止中に変更しますか？申込み済みの採用が自動的にキャンセルになります。</Text>
          ),
          // feat screen_04-01-01-03 end
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-01 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  updateToSuspension()
                    .then((result) => {
                      if (!result.errors) {
                        // ResultRowsをみないと更新できたかわからないため
                        if (result.data?.updateTArbeitOfferStatus6ById.resultRows === ResultRows.NOT_UPDATED) {
                          setModalStep(ModalStep.NULL);
                          openErrorModal({ message: 'ステータスの変更に失敗しました' });
                        } else {
                          setModalStep(ModalStep.STATUS_CLOSE);
                          basicInfoRefetch();
                          setCurrentToggleState('cessation');
                        }
                      } else {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({ message: 'ステータスの変更に失敗しました' });
                      }
                    })
                    .catch(() => {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                      });
                    });
                }}
              >
                変更
              </MainButton>
              {/* feat screen_04-01-01-01 end */}
            </Box>
          ),
        };
      case ModalStep.STATUS_CLOSE:
        return {
          width: 384,
          // feat screen_04-01-01-01 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-01 end
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更完了
            </Text>
          ),
          content: <Text variant="body14">ステータス変更が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-01 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => resetToggoleStatus('cessation')}>
                閉じる
              </MainButton>
              {/* feat screen_04-01-01-01 end */}
            </Box>
          ),
        };
      case ModalStep.STATUS_CHANGE_SUSPENSION:
        return {
          width: 384,
          // feat screen_04-01-01-01 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-01 end
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          content: <Text variant="body14">この募集をアクティブに変更しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-01 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={!isMobile ? 104 : 96}
                onClick={() => {
                  updateToActive()
                    .then((result) => {
                      if (!result.errors) {
                        // ResultRowsをみないと更新できたかわからないため
                        if (result.data?.updateTArbeitOfferStatus1ById.resultRows === ResultRows.NOT_UPDATED) {
                          setModalStep(ModalStep.NULL);
                          openErrorModal({ message: 'ステータスの変更に失敗しました' });
                        } else {
                          setModalStep(ModalStep.STATUS_CLOSE_SUSPENSION);
                          basicInfoRefetch();
                          setCurrentToggleState('active');
                        }
                      } else {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({ message: 'ステータスの変更に失敗しました' });
                      }
                    })
                    .catch(() => {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                      });
                    });
                }}
              >
                変更
              </MainButton>
              {/* feat screen_04-01-01-01 end */}
            </Box>
          ),
        };
      case ModalStep.STATUS_CLOSE_SUSPENSION:
        return {
          width: 384,
          // feat screen_04-01-01-01 start
          height: !isMobile ? 189 : "unset" ,
          // feat screen_04-01-01-01 end
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更完了
            </Text>
          ),
          content: <Text variant="body14">ステータス変更が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {/* feat screen_04-01-01-01 start */}
              <MainButton width={!isMobile ? 104 : 96} variant="secondary" onClick={() => resetToggoleStatus('active')}>
                閉じる
              </MainButton>
              {/* feat screen_04-01-01-01 end */}
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }

  const modalContent = displayModal();

  const generateChip = (status?: number) => {
    switch (status) {
      case RecruitmentStatus.BEFORE_RECRUITMENT:
        return (
          <Chip color="blueGray" variant="big">
            募集前
          </Chip>
        );
      case RecruitmentStatus.WANTED:
        return (
          <Chip color="darkBlue" variant="big">
            募集中
          </Chip>
        );
      case RecruitmentStatus.OFFER_FINALIZED:
        return (
          <Chip color="okBlue" variant="big">
            採用確定
          </Chip>
        );
      case RecruitmentStatus.RECRUITMENT_CLOSED:
        return (
          <Chip color="liteGray" variant="big">
            募集終了
          </Chip>
        );
      case RecruitmentStatus.SUSPENSION_OF_RECRUITMENT:
        return (
          <Chip color="redLite" variant="big">
            募集停止
          </Chip>
        );
      case RecruitmentStatus.FORCED_STOP:
        return (
          <Chip color="cautionRed" variant="big">
            強制停止
          </Chip>
        );
      default:
        return (
          <Chip color="blue" variant="big">
            募集前
          </Chip>
        );
    }
  };

  const generateToggleButton = (status: number) => {
    switch (status) {
      case RecruitmentStatus.WANTED:
      case RecruitmentStatus.SUSPENSION_OF_RECRUITMENT:
      case RecruitmentStatus.BEFORE_RECRUITMENT:
        // feat screen 04-01-01-02 start
        return !isMobile ? (
          <ToggleButton
            options={[
              {
                activeColor: '#E50000',
                label: '募集停止',
                value: 'cessation',
              },
              {
                label: 'アクティブ',
                value: 'active',
              },
            ]}
            currentValue={currentToggleState}
            onClick={currentHandleToggleStatus}
          />
        ) : (
          // Responsive Components
          <Box pb={14}>
            <ToggleButton
              width={194}
              options={[
                {
                  activeColor: '#E50000',
                  label: '募集停止',
                  value: 'cessation',
                },
                {
                  label: 'アクティブ',
                  value: 'active',
                },
              ]}
              currentValue={currentToggleState}
              onClick={currentHandleToggleStatus}
            />
          </Box>
        );
        // feat screen 04-01-01-02 end
      default:
        return null;
    }
  };

  // feat screen 04-01-01-01 start
  return !isMobile ? (
    <Box height="100%">
      {(basicInfoLoading || toSuspensionLoading || toActiveLoading) && <Loading />}
      <Box display="flex" mb={24}>
        <div>{parentEntryList?.status && generateChip(parentEntryList?.status)}</div>
        <div style={{ flexGrow: 2 }}>
          <Box ml={8}>
            <Text variant="h1">{orderNumber}</Text>
          </Box>
        </div>
        <div>{parentEntryList?.status && generateToggleButton(parentEntryList?.status)}</div>
      </Box>
      <PageBody bottom={60}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                content: (
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <BasicInfo
                      status={parentEntryList?.status ?? locationState.status}
                      basicInfoData={basicInfoData}
                      location={location}
                      setOrderNumber={setOrderNumber}
                    />
                  </div>
                ),
                label: '募集情報',
              },
              {
                content: (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <RecruitInfo count={entryCount || 0} />
                  </div>
                ),
                label: `応募(${String(entryCount || 0)})`,
                hasNotification: hasNewlyItem,
              },
              {
                content: (
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <History />
                  </div>
                ),
                label: '応募/採用履歴',
              },
            ]}
            defaultIndex={locationState?.menuIndex}
          />
        </ContentsCard>
      </PageBody>
      <Modal
        width={modalContent.width}
        height={modalContent.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(ModalStep.NULL)}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
      />
    </Box>
  ) : (
    // Responsive Component
    <Box>
      <MultiMenuHeader items={
        [
          {
            label: "募集・採用"
          },
          {
            label: "バイト募集"
          },
        ]}
        mainHeader="バイト募集"
      />
      <Box height="100%" width="100%" px={16}>
        <Box minHeight={64}>
          {(basicInfoLoading || toSuspensionLoading || toActiveLoading) && <Loading />}
          <Box display="flex" pt={16} justifyContent="space-between">
            <div style={{width: "80px"}}>{parentEntryList?.status && generateChip(parentEntryList?.status)}</div>
            <div>{parentEntryList?.status && generateToggleButton(parentEntryList?.status)}</div>
          </Box>
        </Box>
        <PageBody bottom={255}>
          <ContentsCard>
            <Box height="100%">
              <Dropdown
                tab={tab}
                setTab={setTab}
                width={142}
                fullWidthItem={["応募","応募/採用履歴"]}
                items={[
                  {
                    content: (
                      <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          height: "calc(100% -  52px)",
                        }}
                      >
                        <BasicInfo
                          status={parentEntryList?.status ?? locationState.status}
                          basicInfoData={basicInfoData}
                          location={location}
                          setOrderNumber={setOrderNumber}
                        />
                      </div>
                    ),
                    label: '募集情報',
                  },
                  {
                    content: (
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          overflow: 'hidden',
                          height: "calc(100% -  52px)",
                        }}
                      >
                        <RecruitInfo count={entryCount || 0}/>
                      </div>
                    ),
                    label: `応募(${String(entryCount || 0)})`,
                    hasNotification: hasNewlyItem,
                  },
                  {
                    content: (
                      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%', height: "calc(100% -  52px)"}}>
                        <History />
                      </div>
                    ),
                    label: '応募/採用履歴',
                  },
                ]}
                defaultIndex={locationState?.menuIndex}
              />
            </Box>
          </ContentsCard>
        </PageBody>
        <Modal
          width={modalContent.width}
          height={modalContent.height}
          isOpen={modalStep !== 0}
          onClose={() => setModalStep(ModalStep.NULL)}
          header={modalContent.header}
          content={modalContent.content}
          footer={modalContent.footer}
        />
      </Box>
    </Box>
  ) ;
    // feat screen 04-01-01-01 end
}
