import { Box } from 'components/box';
import { Tab } from 'components/newTab';
import { Dropdown } from 'components/dropdown';
import React from 'react';
import { Icon } from 'components/icon';
import type { Name } from 'components/icon/icon';
import { MessageStatus, SystemType } from 'components/const';
import useIsMobile from 'hooks/responsive/useIsMobile';
/* feat_screen_03-01_start */
import { useLocation } from 'react-router-dom';
/* feat_screen_03-01_end */
import { Content } from './content';
import { VTNewlyHome } from '../common/types';
import { useNotify } from '../provider/notifyProvider';

type ContentsPropListType = {
  label: string;
  column: string;
  messageStatus: MessageStatus;
  sysId: SystemType;
  iconName: string;
};
/* feat_screen_03-01_start */
interface PropState {
  tabIndex: number;
}
/* feat_screen_03-01_end */

// HOME画面コンテンツ一覧
// ※タブ毎のコンテンツの情報
const ContentsPropList: ContentsPropListType[] = [
  {
    label: '採用',
    column: 'employment',
    messageStatus: MessageStatus.ADOPTION,
    sysId: SystemType.BT_MEMBER,
    iconName: 'hail',
  },
  {
    label: '成約バイト',
    column: 'worker',
    messageStatus: MessageStatus.CONTRACT_ARBITE,
    sysId: SystemType.BT_MEMBER,
    iconName: 'swapHorizontal',
  },
  {
    label: '勤務時間修正',
    column: 'work_time',
    messageStatus: MessageStatus.WORK_HOUR_FIX,
    sysId: SystemType.BT_MEMBER,
    iconName: 'clock',
  },
  {
    label: 'レビュー',
    column: 'review',
    messageStatus: MessageStatus.REVIEW,
    sysId: SystemType.BT_MEMBER,
    iconName: 'star',
  },
  {
    label: '逆オファー',
    column: 'b_offer',
    messageStatus: MessageStatus.REVERSE_OFFER,
    sysId: SystemType.BT_MEMBER,
    iconName: 'handExtended',
  },
  {
    label: '所属店舗承認',
    column: 'belong',
    messageStatus: MessageStatus.STORE,
    sysId: SystemType.BT_MEMBER,
    iconName: 'howToReg',
  },
  {
    label: 'グループ招待',
    column: 'group',
    messageStatus: MessageStatus.GROUP,
    sysId: SystemType.MW_MEMBER,
    iconName: 'store',
  },
  {
    label: '運営より',
    column: 'u_notice',
    messageStatus: MessageStatus.NOTICE,
    sysId: SystemType.OW_MEMBER,
    iconName: 'mailOutline',
  },
];

export function Home(){
  const notifyContext = useNotify();
  const {notification} = notifyContext;
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  /* feat_screen_03-01_start */
  const location = useLocation();
  const state = (location?.state || {}) as PropState;
  const {tabIndex} = state;
  const [tab, setTab] = React.useState<number>(tabIndex || 0);
  /* feat_screen_03-01_end */
console.log(notification);
  const items: {
    label: string;
    content: React.ReactNode;
    hasNotification?: boolean | undefined;
    /* feat_screen_03-01_start */
    iconName?: string;
    /* feat_screen_03-01_end */
  }[]= ContentsPropList.map((item) => {

    // notify項目名
    const column = item.column as keyof VTNewlyHome;

    return {
      content: (
        <Content
          hasAlert={notification[column] === 1}
          column = {column}
          messageStatus={item.messageStatus}
          sysId={item.sysId}
          label={item.label}
          icon={<Icon name={item.iconName as Name} color='#16495F' />}
          clearButton
        />
      ),
      hasNotification: (notification[column] === 1),
      label: item.label,
      /* feat_screen_03-01_start */
      iconName: item.iconName,
       /* feat_screen_03-01_end */
    };
  });

  return !isMobile ? (
    <Box display='flex' flex='1'>
      <Tab items={items}/>
    </Box>
  ): (
    /* feat_screen_03-01_start */
    <Box display='flex' width="100%" flex='1'>
        <Dropdown tab={tab} setTab={setTab} items={items} width="55%"/>
    </Box>
    /* feat_screen_03-01_end */
  );
}