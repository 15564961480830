/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useEffect } from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { useForm, Controller } from 'react-hook-form';
import { Hours, Minutes } from 'components/select/selectOptions';
import { DatePicker } from 'components/datePicker';
import { styled } from '@linaria/react';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { ErrorMessage } from 'components/errorMessage';
import { RhfTextarea } from 'components/rhfTextarea';
import { RhfSelect } from 'components/rhfSelect';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { RejectionDataModel } from '../../types';

/**
 * Figma ID
 * 04-02-06-06, 04-02-06-07, 04-02-06-08
 * 勤務時間修正否認モーダル
 */

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${GENERIC_COLOR.WHITE};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;
  gap: 4px;
  /* feat_screen_04-02-06-06_start */
  @media only screen and (max-width: 768px) {
    padding: 12px;
  }
  /* feat_screen_04-02-06-06_end */
`;

interface RejectionDetails {
  checkInDate: Date;
  checkInHour: string;
  checkInMin: string;
  checkOutDate: Date;
  checkOutHour: string;
  checkOutMin: string;
  comment: string;
}

interface Props {
  isOpen: boolean;
  onModalClose: () => void;
  onDeny: () => void;
  onReject: (data: RejectionDataModel) => void;
  requestedCheckIn: Date;
  requestedCheckOut: Date;
  requestedDateTime: string;
  breakTime: string;
  totalSalary: string;
  reason: string;
}

enum ValidateStatus {
  VALID = 0,
  PREVIOUS = -1,
  EXCEEDED = -2,
  OTHERERROR = -3,
}

function RejectionModal(props: Props) {
  const {
    isOpen,
    onModalClose,
    onDeny,
    onReject,
    requestedCheckIn,
    requestedCheckOut,
    requestedDateTime,
    breakTime,
    totalSalary,
    reason,
  } = props;

  // '否認' or '拒否(バックレ認定)'
  const [mode, setMode] = React.useState<'reject' | 'deny'>('reject');
  const inHour = requestedDateTime ? requestedDateTime.split(' ')[1].split(':')[0] : '';
  const inMin = requestedDateTime ? requestedDateTime.split(' ')[1].split(':')[1] : '';
  const outHour = requestedDateTime ? requestedDateTime.split(' ')[3].split(':')[0] : '';
  const outMin = requestedDateTime ? requestedDateTime.split(' ')[3].split(':')[1] : '';
  const [valueCheck, setValueCheck] = React.useState<ValidateStatus>();

  const denyOption: { label: string; value: string }[] = [{ label: '--', value: '' }];
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm<RejectionDetails>({ reValidateMode: 'onChange' });

  const currentValues = watch();

  const valueConfirm = () => {
    if (currentValues.checkInDate == null || currentValues.checkOutDate == null) {
      return ValidateStatus.OTHERERROR;
    }
    const inData = new Date(
      currentValues.checkInDate.getFullYear(),
      currentValues.checkInDate.getMonth(),
      currentValues.checkInDate.getDate(),
      Number(currentValues.checkInHour),
      Number(currentValues.checkInMin)
    );
    const outData = new Date(
      currentValues.checkOutDate.getFullYear(),
      currentValues.checkOutDate.getMonth(),
      currentValues.checkOutDate.getDate(),
      Number(currentValues.checkOutHour),
      Number(currentValues.checkOutMin)
    );

    const diffDay = Math.floor((outData.getTime() - inData.getTime()) / (1000 * 60 * 60 * 24));
    if (inData >= outData) {
      return ValidateStatus.PREVIOUS;
    }
    if (diffDay > 1) {
      return ValidateStatus.EXCEEDED;
    }
    return ValidateStatus.VALID;
  };

  const onSubmit = handleSubmit((data) => {
    if (mode === 'reject') {
      const retValue = valueConfirm();
      setValueCheck(retValue);
      if (retValue !== ValidateStatus.VALID) {
        return;
      }
      const rejectionData: RejectionDataModel = {
        ...data,
        breakTime,
        totalSalary,
      };

      onReject(rejectionData);
    } else {
      onDeny();
    }
  });

  const onClear = () => {
    setMode('reject');
    setValue('checkInDate', requestedCheckIn);
    setValue('checkInHour', inHour);
    setValue('checkInMin', inMin);
    setValue('checkOutDate', requestedCheckOut);
    setValue('checkOutHour', outHour);
    setValue('checkOutMin', outMin);
    setValue('comment', '');
    setValueCheck(ValidateStatus.VALID);
  };

  function onClickDenial() {
    setMode('deny');
    setValue('checkInHour', '');
    setValue('checkInMin', '');
    setValue('checkOutHour', '');
    setValue('checkOutMin', '');
    setValue('comment', '出勤の実態がありません。');
    clearErrors();
  }

  function onClose() {
    onClear();
    clearErrors();
    onModalClose();
  }

  useEffect(() => {
    if (mode === 'deny') {
      return;
    }
    if (requestedCheckIn !== undefined) {
      setValue('checkInDate', requestedCheckIn);
    }
    if (requestedCheckOut !== undefined) {
      setValue('checkOutDate', requestedCheckOut);
    }
    if (requestedDateTime !== undefined && requestedDateTime !== '') {
      setValue('checkInHour', inHour);
      setValue('checkInMin', inMin);
      setValue('checkOutHour', outHour);
      setValue('checkOutMin', outMin);
    }
  }, [requestedCheckIn, requestedCheckOut, requestedDateTime, setValue, inHour, inMin, outHour, outMin, mode]);

  // useEffect(() => {
  //   const { checkInHour, checkInMin, checkOutHour, checkOutMin } = currentValues;
  //   if (checkInHour || checkInMin || checkOutHour || checkOutMin) {
  //     setMode('reject');
  //   }
  // }, [currentValues]);

  const validateRequiredTimeValue = useCallback(
    (value: string | Date | null | undefined, errorMessage: string) => {
      if (value === '' && mode === 'reject') {
        return errorMessage;
      }
      setValueCheck(ValidateStatus.VALID);
      return true;
    },
    [mode]
  );

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        勤務時間修正否認
      </Text>
    ),
    content: !isMobile ? (
      <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
        <Box>
          <Text variant="body14" color="black">
            勤務時間の修正案を入力し、確認を押してください。
          </Text>
        </Box>
        <StyledDiv>
          <Box>
            <Text variant="body14" bold>
              依頼内容
            </Text>
          </Box>
          <Box display="flex">
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                修正依頼時間
              </Text>
            </Box>
            <Box>
              <Text variant="body14">
                {requestedDateTime || ''}&nbsp;&nbsp;休憩{breakTime || ''}
              </Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                業務報酬合計（交通費込み）
              </Text>
            </Box>
            <Box>
              <Text variant="body14">¥{totalSalary}</Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box width={184}>
              <Text variant="body14" color="darkGray">
                修正依頼理由
              </Text>
            </Box>
            <Box>
              <Text variant="body14">{reason}</Text>
            </Box>
          </Box>
        </StyledDiv>
        <Box>
          <Box>
            <Text variant="h3" color="blueGray">
              チェックイン修正案
            </Text>
          </Box>
          <Box alignItems="center" display="flex" gap={8} mt={4}>
            {mode === 'reject' ? (
              <Controller
                name="checkInDate"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return 'チェックイン修正案（日付）を選択してください';
                    return true;
                  },
                }}
                render={(field) => (
                  <DatePicker
                    width={144}
                    placeholderText="日付を選択"
                    selected={getValues('checkInDate')}
                    onChange={(val) => {
                      setValueCheck(ValidateStatus.VALID);
                      field.field.onChange(val);
                    }}
                  />
                )}
              />
            ) : (
              <DatePicker
                open={mode !== 'deny'}
                width={144}
                placeholderText="--"
                selected={null}
                onChange={(val) => val}
              />
            )}
            <RhfSelect
              width={80}
              control={control}
              name="checkInHour"
              options={mode === 'reject' ? Hours : denyOption}
              defaultValue={mode === 'reject' ? inHour : '--'}
              placeholder={mode === 'reject' ? '時間' : '--'}
              rules={{
                validate: (value) => validateRequiredTimeValue(value, 'チェックイン修正案（時間）を選択してください'),
              }}
            />
            <Text color="blueGray" display="inline" variant="body14">
              :
            </Text>
            <RhfSelect
              width={80}
              control={control}
              name="checkInMin"
              options={mode === 'reject' ? Minutes : denyOption}
              defaultValue={mode === 'reject' ? inMin : '--'}
              placeholder={mode === 'reject' ? '分' : '--'}
              rules={{
                validate: (value) => validateRequiredTimeValue(value, 'チェックイン修正案（分）を選択してください'),
              }}
            />
          </Box>
          {(errors?.checkInDate || errors?.checkInHour || errors?.checkInMin) && (
            <Box>
              {errors.checkInDate && <ErrorMessage>{errors.checkInDate.message}</ErrorMessage>}
              {errors.checkInHour && <ErrorMessage>{errors.checkInHour.message}</ErrorMessage>}
              {errors.checkInMin && <ErrorMessage>{errors.checkInMin.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
        <Box mt={16}>
          <Box>
            <Text variant="h3" color="blueGray">
              チェックアウト修正案
            </Text>
          </Box>
          <Box alignItems="center" display="flex" gap={8} mt={4}>
            {mode === 'reject' ? (
              <Controller
                name="checkOutDate"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return 'チェックアウト修正案（日付）を選択してください';
                    return true;
                  },
                }}
                render={(field) => (
                  <DatePicker
                    width={144}
                    placeholderText="日付を選択"
                    selected={getValues('checkOutDate')}
                    onChange={(val) => {
                      setValueCheck(ValidateStatus.VALID);
                      field.field.onChange(val);
                    }}
                  />
                )}
              />
            ) : (
              <DatePicker
                open={mode !== 'deny'}
                width={144}
                placeholderText="--"
                selected={null}
                onChange={(val) => val}
              />
            )}
            <RhfSelect
              width={80}
              control={control}
              name="checkOutHour"
              options={mode === 'reject' ? Hours : denyOption}
              defaultValue={mode === 'reject' ? outHour : '--'}
              placeholder={mode === 'reject' ? '時間' : '--'}
              rules={{
                validate: (value) => validateRequiredTimeValue(value, 'チェックアウト修正案（時間）を選択してください'),
              }}
            />
            <Text color="blueGray" display="inline" variant="body14">
              :
            </Text>
            <RhfSelect
              width={80}
              control={control}
              name="checkOutMin"
              options={mode === 'reject' ? Minutes : denyOption}
              defaultValue={mode === 'reject' ? outMin : '--'}
              placeholder={mode === 'reject' ? '分' : '--'}
              rules={{
                validate: (value) => validateRequiredTimeValue(value, 'チェックアウト修正案（分）を選択してください'),
              }}
            />
          </Box>
          {(valueCheck || errors?.checkOutDate || errors?.checkOutHour || errors?.checkOutMin) && (
            <Box>
              {errors.checkOutDate && <ErrorMessage>{errors.checkOutDate.message}</ErrorMessage>}
              {errors.checkOutHour && <ErrorMessage>{errors.checkOutHour.message}</ErrorMessage>}
              {errors.checkOutMin && <ErrorMessage>{errors.checkOutMin.message}</ErrorMessage>}
              {valueCheck === ValidateStatus.PREVIOUS && (
                <ErrorMessage>チェックインより過去の日時は指定出来ません</ErrorMessage>
              )}
              {valueCheck === ValidateStatus.EXCEEDED && <ErrorMessage>労働時間が超過しています</ErrorMessage>}
              {valueCheck === ValidateStatus.OTHERERROR && <ErrorMessage>入力誤りがあります</ErrorMessage>}
            </Box>
          )}
        </Box>
        <Box display="flex" gap={16} mt={16}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClickDenial()} disabled={mode === 'deny'}>
            出勤実態無し
          </MainButton>
          <MainButton variant="clear" width={44} onClick={() => onClear()}>
            クリア
          </MainButton>
        </Box>
        <Box>
          <Box>
            <Text variant="h3" color="blueGray">
              コメント
            </Text>
          </Box>
          <Box display="flex" mt={4} width="100%">
            <RhfTextarea
              name="comment"
              control={control}
              height={74}
              fullWidth
              placeholder="コメントを入力"
              defaultValue=""
              rules={{ required: 'コメントを入力してください' }}
            />
          </Box>
          {errors?.comment && <Box>{errors.comment && <ErrorMessage>{errors.comment.message}</ErrorMessage>}</Box>}
        </Box>
      </Box>
    ) : (
    // feat_screen_04-02-06-06_start
      <Box display="flex" flexDirection="column" rowGap={16}>
        <Box>
          <Text variant="body14" color="black">
            勤務時間の修正案を入力し、確認を押してください。
          </Text>
        </Box>
        <StyledDiv>
          <Box>
            <Text variant="body14" bold>
              依頼内容
            </Text>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={16}>
            <Box width={140}>
              <Text variant="body14" color="darkGray">
                修正依頼時間
              </Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <Text variant="body14" align="right">
                  {requestedDateTime || ''}&nbsp;&nbsp;休憩{breakTime || ''}
                </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box width={140} display="flex" flexDirection="column">
              <Text variant="body14" color="darkGray">
                業務報酬合計
              </Text>
              <Text variant="body14" color="darkGray">
                (交通費込み)
              </Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Text align="right" variant="body14">¥{totalSalary}</Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box width={140}>
              <Text variant="body14" color="darkGray">
                修正依頼理由
              </Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Text align="right" variant="body14">{reason}</Text>
            </Box>
          </Box>
        </StyledDiv>
        <Box>
          <Box>
            <Text variant="h3" color="blueGray">
              チェックイン修正案
            </Text>
          </Box>
          <Box alignItems="center" display="flex" gap={16} mt={4}>
            {mode === 'reject' ? (
              <Box display='flex' width='50%'>
                <Controller
                  name="checkInDate"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return 'チェックイン修正案（日付）を選択してください';
                      return true;
                    },
                  }}
                  render={(field) => (
                    <DatePicker
                      width={144}
                      placeholderText="日付を選択"
                      selected={getValues('checkInDate')}
                      onChange={(val) => {
                        setValueCheck(ValidateStatus.VALID);
                        field.field.onChange(val);
                      }}
                    />
                  )}
                />
              </Box>
            ) : (
              <Box display='flex' width='50%'>
                <DatePicker
                  open={mode !== 'deny'}
                  width={144}
                  placeholderText="--"
                  selected={null}
                  onChange={(val) => val}
                />
              </Box>
            )}
            <Box display='flex' width='50%' gap={6} alignItems='center'>
              <RhfSelect
                fullWidth
                height={40}
                control={control}
                name="checkInHour"
                options={mode === 'reject' ? Hours : denyOption}
                defaultValue={mode === 'reject' ? inHour : '--'}
                placeholder={mode === 'reject' ? '時間' : '--'}
                rules={{
                  validate: (value) => validateRequiredTimeValue(value, 'チェックイン修正案（時間）を選択してください'),
                }}
              />
              <Text color="blueGray" display="inline" variant="body14">
                :
              </Text>
              <RhfSelect
                fullWidth
                height={40}
                control={control}
                name="checkInMin"
                options={mode === 'reject' ? Minutes : denyOption}
                defaultValue={mode === 'reject' ? inMin : '--'}
                placeholder={mode === 'reject' ? '分' : '--'}
                rules={{
                  validate: (value) => validateRequiredTimeValue(value, 'チェックイン修正案（分）を選択してください'),
                }}
              />
            </Box>
          </Box>
        </Box>
        {(errors?.checkInDate || errors?.checkInHour || errors?.checkInMin) && (
          <Box>
            {errors.checkInDate && <ErrorMessage>{errors.checkInDate.message}</ErrorMessage>}
            {errors.checkInHour && <ErrorMessage>{errors.checkInHour.message}</ErrorMessage>}
            {errors.checkInMin && <ErrorMessage>{errors.checkInMin.message}</ErrorMessage>}
          </Box>
        )}
        <Box>
          <Box>
            <Text variant="h3" color="blueGray">
              チェックアウト修正案
            </Text>
          </Box>
          <Box alignItems="center" display="flex" gap={15} mt={4}>
            {mode === 'reject' ? (
              <Box display='flex' width='50%'>
                <Controller
                name="checkOutDate"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return 'チェックアウト修正案（日付）を選択してください';
                    return true;
                  },
                }}
                render={(field) => (
                  <DatePicker
                    width={144}
                    placeholderText="日付を選択"
                    selected={getValues('checkOutDate')}
                    onChange={(val) => {
                      setValueCheck(ValidateStatus.VALID);
                      field.field.onChange(val);
                    }}
                  />
                )}
                />
              </Box>
            ) : (
              <Box display='flex' width='50%'>
                <DatePicker
                  open={mode !== 'deny'}
                  width={144}
                  placeholderText="--"
                  selected={null}
                  onChange={(val) => val}
                />
              </Box>
            )}
            <Box display='flex' width='50%' gap={6} alignItems='center'>
              <RhfSelect
                fullWidth
                height={40}
                control={control}
                name="checkOutHour"
                options={mode === 'reject' ? Hours : denyOption}
                defaultValue={mode === 'reject' ? outHour : '--'}
                placeholder={mode === 'reject' ? '時間' : '--'}
                rules={{
                  validate: (value) => validateRequiredTimeValue(value, 'チェックアウト修正案（時間）を選択してください'),
                }}
              />
              <Text color="blueGray" display="inline" variant="body14">
                :
              </Text>
              <RhfSelect
                fullWidth
                control={control}
                height={40}
                name="checkOutMin"
                options={mode === 'reject' ? Minutes : denyOption}
                defaultValue={mode === 'reject' ? outMin : '--'}
                placeholder={mode === 'reject' ? '分' : '--'}
                rules={{
                  validate: (value) => validateRequiredTimeValue(value, 'チェックアウト修正案（分）を選択してください'),
                }}
              />
            </Box>
          </Box>
        </Box>
        {(errors?.checkOutHour || errors?.checkOutMin) && (
          <Box>
            {errors.checkOutHour && <ErrorMessage>{errors.checkOutHour.message}</ErrorMessage>}
            {errors.checkOutMin && <ErrorMessage>{errors.checkOutMin.message}</ErrorMessage>}
          </Box>
        )}
        <Box display="flex" gap={16}>
          <MainButton customHeight={32} width={104} variant="secondary" thin onClick={() => onClickDenial()} disabled={mode === 'deny'}>
            出勤実態無し
          </MainButton>
            <MainButton customHeight={32} divider variant="clear" width={44} onClick={() => onClear()}>
              クリア
            </MainButton>
        </Box>
        <Box>
          <Box>
            <Text variant="h3" color="blueGray">
              コメント
            </Text>
          </Box>
          <Box display="flex" mt={4} width="100%">
            <RhfTextarea
              name="comment"
              control={control}
              height={98}
              fullWidth
              placeholder="コメントを入力"
              defaultValue=""
              rules={{ required: 'コメントを入力してください' }}
            />
          </Box>
          {errors?.comment && <Box>{errors.comment && <ErrorMessage>{errors.comment.message}</ErrorMessage>}</Box>}
        </Box>
      </Box>
    // feat_screen_04-02-06-06_end
    ),
    footer: !isMobile ? (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} thin onClick={() => onSubmit()}>
          確認
        </MainButton>
      </Box>
    ) : (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton customHeight={32} width={96} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton customHeight={32} width={96} thin onClick={() => onSubmit()}>
          確認
        </MainButton>
      </Box>
    ),
    // feat_screen_04-02-06-06_start
    height: !isMobile ? 'auto' : 589,
    width: !isMobile ? 800 : '100%',
    // feat_screen_04-02-06-06_end
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height={modalContent?.height}
      width={modalContent?.width}
    />
  );
}

export default RejectionModal;
