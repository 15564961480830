import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ChangeRateModalForm, ModalRet } from 'pages/s05-01/type';
import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import * as gql from 'graphql/graphql-mw';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ResultRows } from 'components/const';
import { handleMutationResult } from 'error';
import { BelongBtmemKind } from '../../const';

export function Confirm(
  getValues: UseFormGetValues<ChangeRateModalForm>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const params = useParams<{ id: string }>();
  const btmemId = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const [apiError, setApiError] = React.useState<RequiringCallbackError>();
  const { openErrorModal } = useErrorModal();
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  // 料率更新
  // ※ kindは0固定でよい
  const [updateTBelongBtmemEducFeeByIdBtmemIdKindMutation] = gql.useUpdateTBelongBtmemEducFeeByIdBtmemIdKindMutation({
    variables: {
      btmem_id: btmemId,
      educ_fee: parseInt(getValues('rate'), 10),
      id: tpmemId,
      kind: BelongBtmemKind.PRIMARY,
    },
  });

  React.useEffect(() => {
    if (apiError) {
      onClose();
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal, onClose]);

  const modalContent = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          料率変更
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: !isMobile ? (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" gap={16} flex="1">
          <Text variant="body14">以下の内容で育成料料率を変更しますか？</Text>
          <Text variant="body14">・{getValues('rate')}%</Text>
        </Box>
      </Box>
    ) : (
      // feat_screen_05-01-03-03_start
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" flex="1">
          <Text variant="body14">以下の内容で育成料料率を変更しますか？</Text>
          <Text variant="body14">・{getValues('rate')}%</Text>
        </Box>
      </Box>
      // feat_screen_05-01-03-03_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton
          // feat_screen_05-01-03-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-03-03_end
          variant="secondary" 
          onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          // feat_screen_05-01-03-03_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-03-03_end
          variant="secondary" 
          onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          // feat_screen_05-01-03-03_start
          width={!isMobile ? 104 : 96}
          thin={!isMobile}
          // feat_screen_05-01-03-03_end
          onClick={() => {
            updateTBelongBtmemEducFeeByIdBtmemIdKindMutation().then((result) => {
              if (result.data === undefined || result.data === null) return;
              const r = handleMutationResult(result.data.updateTBelongBtmemEducFeeByIdBtmemIdKind);
              if (r.isSuccessful) {
                // ResultRowsをみないと更新できたかわからないため
                if (result.data.updateTBelongBtmemEducFeeByIdBtmemIdKind.resultRows === ResultRows.NOT_UPDATED) {
                  onClose();
                  openErrorModal({
                    message: '変更できませんでした。',
                  });
                } else {
                  setApiError(undefined);
                  onNext();
                }
              } else {
                setApiError(r.error);
              }
            })
            .catch((e) => {
              onClose();
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
          }}
        >
          変更
        </MainButton>
      </Box>
    ),
    // feat_screen_05-01-03-03_start
    width: !isMobile ? 384 : "100%",
    height: !isMobile ? 226 : "unset",
    // feat_screen_05-01-03-03_end
  };

  return modalContent;
}
