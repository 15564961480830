import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text';

export interface Props {
  children: ReactNode;
}

const StyledErrorWrapper = styled.div`
  padding-left: 8px;
  height: 18px;
  display: flex;
  align-items: center;
  /* feat screen_01-01-02_start */
  @media only screen and (max-width: 768px) {
    height: unset;
    padding-left: 0px;
    padding-top: 4px;
  }
  /* feat screen_01-01-02_end */
`;

export function ErrorMessage({ children }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // feat screen 04-01-03-01 start
  return !isMobile ? (
    <StyledErrorWrapper>
      <Text variant="caption11" color="cautionRed">
        {children}
      </Text>
    </StyledErrorWrapper>
  ) : (
    // Responsive Component
    <StyledErrorWrapper>
      <Text variant="caption12" color="cautionRed">
        {children}
      </Text>
    </StyledErrorWrapper>
  );
  // feat screen 04-01-03-01 end
}
