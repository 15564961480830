import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text';

export interface Props {
  items: {
    label: string;
    contents: ReactNode[];
  }[];
}

const StyledRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
  border-bottom: #dbdbdb 1px solid;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled.div`
  min-width: 168px;
  /* feat_screen_04-02-01-04_start */
  @media only screen and (max-width: 768px) {
      min-width: 100px;
  }
  /* feat_screen_04-02-01-04_end */
`;

const StyledContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledContent = styled.div`
  width: 100%;
  border-bottom: #dbdbdb 1px solid;
  padding: 8px 0px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export function CategorizedInfo({ items }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return (
    // feat screen 04-01-01-13 start
    <div style={{ marginTop: !isMobile ? '8px' : 0 }}>
     {/* feat screen 04-01-01-13 end */}
      {items.map((item) => (
        <StyledRow key={item.label}>
          <StyledLabel>
            <Text variant="body14" color="darkGray">
              {item.label}
            </Text>
          </StyledLabel>
          <StyledContents>
            {item.contents.map((content) =>
              typeof content === 'string' ? (
                <StyledContent>
                  <Text variant="body14">{content}</Text>
                </StyledContent>
              ) : (
                <StyledContent>{content}</StyledContent>
              )
            )}
          </StyledContents>
        </StyledRow>
      ))}
    </div>
  );
}
