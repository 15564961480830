import { Buffer } from 'buffer';
import AwsConfigAuth from 'aws-config/auth';
import { Rekognition, DetectFacesRequest } from "@aws-sdk/client-rekognition";
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(AwsConfigAuth);

// 顔認証類似率閾値(デフォルトで90%)
const RekognitionSimilarity = process.env.REACT_APP_REKOGNITION_SIMILARITY || "90";

/**
 * 顔画像比較
 * 参照画像と比較画像の画像を比較して類似率以上の顔画像が存在するかチェックする
 * 
 * @param sourceImgBuffer : 参照画像 画像ファイルのバッファーを指定する
 * @param targetImgBuffer : 比較画像 画像ファイルのバッファーを指定する
 * @returns : 参照画像と比較画像の顔画像を比較して類似率以上の顔画像が存在する場合はtrueを返却する
 *           類似率以上の顔画像が存在しない場合はfalseを返却する
 *           処理に失敗した場合はfalseを返却する
 */
export const compareFace = async(sourceImgBuffer: Buffer, targetImgBuffer: Buffer): Promise<boolean> => {
  
  try {
    const credentials = await Auth.currentCredentials();
    const client = new Rekognition({credentials, region: AwsConfigAuth.region});

    // 顔画像比較
    const result = await client.compareFaces({
      SourceImage: {Bytes: sourceImgBuffer},
      TargetImage: {Bytes: targetImgBuffer},
      SimilarityThreshold: parseFloat(RekognitionSimilarity)
    });
    
    // 類似率の閾値以上の顔画像を取得する
    const faceMatches = result?.FaceMatches || [];
    // 類似率の閾値以上の顔画像が存在しない場合はfalseを返す
    if (!faceMatches.length) return false;
    
    return true;
  } catch (error) {
    // エラーログを出力する
    console.log(error);
    return false;
  }
};

/**
 * 顔画像存在チェック
 * 
 * @param imageBuffer 画像データ
 * @returns 顔画像が存在する場合はtrueを返却する 
 */
export const existsFaceImage = async(imageBuffer: Buffer): Promise<boolean> => {
  
  try {
    const credentials = await Auth.currentCredentials();
    const client = new Rekognition({credentials, region: AwsConfigAuth.region});
   
    const params: DetectFacesRequest = {
      Image: {
        Bytes: imageBuffer
      }
    };

    const rawResult = await client.detectFaces(params);

    if (!rawResult.FaceDetails?.length) return false;
    
    return true;
  } catch (error) {
    // エラーログを出力する
    console.log(error);
    return false;
  }
};
