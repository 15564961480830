type Props = {
  rank?: string;
  score?: number;
  hireFee?: number;
  cancelPer?: number;
  imminentPer?: number;
};

export function autoRecruitPreviewItems(props: Props) {
  const { rank, score, hireFee, cancelPer, imminentPer } = props;

  return [
    {
      content: rank !== undefined ? `${rank}以上` : '',
      label: '募集ランク',
    },
    {
      content: score !== undefined ? `${score.toString()}以上` : '',
      label: 'スコア',
    },
    {
      content: hireFee !== undefined ? `¥${hireFee.toLocaleString()}以下` : '',
      label: '採用手数料',
    },
    {
      content: cancelPer !== undefined ? `${cancelPer.toString()}%以下` : '',
      label: 'キャンセル率',
    },
    {
      content: imminentPer !== undefined ? `${imminentPer.toString()}%以下` : '',
      label: 'ドタキャン率',
    },
  ];
}
