import React from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Box } from 'components/box';
import { Text } from '../text';
import { Icon } from '../icon/icon';
import { Divider } from '../newDivider/divider';

export interface Props {
  page: number;
  totalPage: number;
  dataSize: number;
  onChange: (page: number) => void;
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  /* feat_common_responsive_pagination_start */
  @media only screen and (max-width: 768px) {
    padding-bottom: 4px;
    column-gap: 8px;
  }
  /* feat_common_responsive_pagination_end */
`;

const StyledPagerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  /* feat_common_responsive_pagination_start */
  @media only screen and (max-width: 768px) {
    column-gap: 2px;
    margin-right: -4px;
    margin-left: -4px;
  }
  /* feat_common_responsive_pagination_end */
`;

const StyledButton = styled.button`
  display: contents;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export function Pagination({ page: currentPage, totalPage, dataSize, onChange }: Props) {
  const pagesArray = React.useMemo(() => {
    if (currentPage <= 5) {
      return [...Array<number>(totalPage)].map((_, index) => index + 1).slice(0, 5);
    }
    if (currentPage + 5 > totalPage) {
      return [totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
    }
    return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
  }, [currentPage, totalPage]);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  return !isMobile ? (
    <StyledWrapper>
      <Text variant="caption11" color="darkGray">
        {currentPage} / {totalPage} ページ (全 {dataSize} 件)
      </Text>
      <StyledPagerWrapper>
        <StyledButton onClick={() => currentPage !== 1 && onChange(currentPage - 1)}>
          <Icon name="chevronRwd" />
        </StyledButton>
        {currentPage > 5 && (
          <>
            <StyledButton onClick={() => onChange(1)}>
              <Text variant="caption11" color="primaryBlue">
                1
              </Text>
            </StyledButton>
            <Text variant="caption11" color="superLiteGray">
              ...
            </Text>
          </>
        )}
        {pagesArray.map((page, index) => (
          <React.Fragment key={page}>
            <Divider option="vertical" length={8} />
            <StyledButton onClick={() => onChange(page)}>
              <Text variant="caption11" color={currentPage === page ? 'black' : 'primaryBlue'}>
                {String(page)}
              </Text>
            </StyledButton>
            {index === pagesArray.length - 1 && <Divider option="vertical" length={8} />}
          </React.Fragment>
        ))}
        {pagesArray[pagesArray.length - 1] < totalPage && (
          <>
            <Text variant="caption11" color="superLiteGray">
              ...
            </Text>
            <StyledButton onClick={() => onChange(totalPage)}>
              <Text variant="caption11" color="primaryBlue">
                {totalPage}
              </Text>
            </StyledButton>
          </>
        )}
        <StyledButton onClick={() => totalPage > 0 && currentPage !== totalPage && onChange(currentPage + 1)}>
          <Icon name="chevronFwd" />
        </StyledButton>
      </StyledPagerWrapper>
    </StyledWrapper>
  ): (
    /* feat_common_reponsive_pagination_start */
    <StyledWrapper>
      <Box display="flex" alignItems="center">
        <Text variant="caption11" color="darkGray">
          {currentPage} / {totalPage} ページ (全 {dataSize} 件)
        </Text>
      </Box>
      <StyledPagerWrapper>
        <StyledButton onClick={() => currentPage !== 1 && onChange(currentPage - 1)}>
          <Icon name="chevronRwd" size={30}/>
        </StyledButton>
        {pagesArray.map((page, index) => (
          currentPage === page && (
            <React.Fragment key={page}>
              <Divider option="vertical" length={8} />
              <StyledButton onClick={() => onChange(page)}>
                <Text variant="caption11" color={currentPage === page ? 'black' : 'primaryBlue'}>
                  {String(page)}
                </Text>
              </StyledButton>
              <Divider option="vertical" length={8} />
            </React.Fragment>
          )
        ))}
        <StyledButton onClick={() => totalPage > 0 && currentPage !== totalPage && onChange(currentPage + 1)}>
          <Icon name="chevronFwd" size={30}/>
        </StyledButton>
      </StyledPagerWrapper>
    </StyledWrapper>
    /* feat_common_reponsive_pagination_end */
  );
}
