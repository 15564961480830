// マイページメニューインデックス
export const MYPAGE_MENU_INDEX = {
  BankAccount: 1, // 銀行口座
  Group: 3, // グループ店舗
};
// 支払方法
export enum PaymentMethod {
  Transfer = 0, // 振込
  TransferRequesting = 1, // 振替依頼中
  Giro = 2, // 振替
}
// 口座
export const PersonalBank = {
  Person: '個人',
  Corporation: '法人',
};
// 口座種別
export const AccountType = {
  SavingsAccount: '普通',
  CurrentAccount: '当座',
};
