import React, { useMemo, useState, useEffect } from 'react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import { AgreementStatus } from 'components/const';
import { Tab } from 'components/newTab/tab';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useGetVtWorkingListNewlyByTpmemIdQuery } from 'graphql/graphql-mw';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  MainContentWrapper,
  MainContentHeader,
  MainContentArea,
  MainContentTitle,
} from 'components/pageLayout/mainContent';
import { Dropdown } from 'components/dropdown';
import { MultiMenuHeader } from 'components/multiMenuHeader';
import AllList from './components/tabs/AllList';
import FilteredListTemplate from './components/tabs/FilteredListTemplate';
import { CurrentFilterContext } from './components/Filter';
import { convertVTWorkingListToObject } from './components/common';
import { TabIndexType } from './components/type';
import { TabIndex } from './components/const';

function S0402() {
  const [inputPublishedNumber, setInputPublishedNumber] = useState<string>();
  const [publishedNumber, setPublishedNumber] = useState<string>();
  const [inputName, setInputName] = useState<string>();
  const [name, setName] = useState<string>();
  const [statuses, setStatuses] = useState<AgreementStatus[]>([AgreementStatus.ALL]);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  // 成約バイト状況のタブの初期値を取得
  // ※HOME画面から遷移した際に、タブの初期値を取得する
  const location = useLocation();
  const state = location?.state as TabIndexType;
  const tabIndex = state?.tabIndex;
  // feat_common_responsive_tab_start
  const dateValue = state?.dateValue;
  const [arbeitDate, setArbeitDate] = useState<Date | null>(dateValue || null);
  const [tab, setTab] = React.useState<number>(tabIndex || 0);
  // feat_common_responsive_tab_end

  const { tpmemId: loginTpMemId } = useAuth();
  const navigate = useNavigate();

  // ログインしていなければ、ログイン画面に遷移
  if (loginTpMemId === null) {
    navigate('/signin');
  }

  const {
    data: vtWorkingListNewlyByTpmemIdData,
    loading,
    refetch: refetchVtWorkingListNewlyByTpmemId,
    error: vtWorkingListNewlyByTpmemIdError,
  } = useGetVtWorkingListNewlyByTpmemIdQuery({
    variables: {
      tpmem_id: loginTpMemId || 0,
    },
    fetchPolicy: 'no-cache',
  });
  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  useEffect(() => {
    if (vtWorkingListNewlyByTpmemIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vtWorkingListNewlyByTpmemIdError, openErrorModal]);

  let vTWorkingList = vtWorkingListNewlyByTpmemIdData?.getVTWorkingListNewlyByTpmemId?.list?.filter(
    (e): e is Exclude<typeof e, null> => e !== null
  );
  let data = convertVTWorkingListToObject(vTWorkingList || []);

  const FilterContextValues = useMemo(
    () => ({
      publishedNumber,
      setPublishedNumber,
      inputPublishedNumber,
      setInputPublishedNumber,
      inputName,
      setInputName,
      name,
      setName,
      arbeitDate,
      setArbeitDate,
      statuses,
      setStatuses,
    }),
    [publishedNumber, inputPublishedNumber, name, inputName, arbeitDate, statuses]
  );

  let standByData = data.filter((d) => d.status === AgreementStatus.STAND_BY);
  let inProgressData = data.filter((d) => d.status === AgreementStatus.IN_PROGRESS);
  let waitingCompleteData = data.filter(
    (d) =>
      d.status === AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED ||
      d.status === AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT
  );
  let noCheckInOutData = data.filter(
    (d) =>
      d.status === AgreementStatus.NO_CHECK_IN ||
      d.status === AgreementStatus.NO_CHECK_OUT ||
      d.status === AgreementStatus.NO_CHECK_IN_OUT
  );
  let requestCorrectionData = data.filter(
    (d) =>
      d.status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
      d.status === AgreementStatus.REQUEST_CORRECTION_REJECTED
  );
  let completeData = data.filter((d) => d.status === AgreementStatus.COMPLETE);
  let cancelData = data.filter((d) => d.status === AgreementStatus.CANCEL);
  let noShowData = data.filter((d) => d.status === AgreementStatus.NO_SHOW);

  // タブ切替時 API CALL
  const handleTabClick = (index: number) => {
    // API CALL
    refetchVtWorkingListNewlyByTpmemId();
    vTWorkingList = vtWorkingListNewlyByTpmemIdData?.getVTWorkingListNewlyByTpmemId?.list?.filter(
      (e): e is Exclude<typeof e, null> => e !== null
    );
    data = convertVTWorkingListToObject(vTWorkingList || []);
    // フィルター処理
    switch (index) {
      case TabIndex.ALL_LIST:
        break;
      case TabIndex.STAND_BY:
        standByData = data.filter((d) => d.status === AgreementStatus.STAND_BY);
        break;
      case TabIndex.IN_PROGRESS:
        inProgressData = data.filter((d) => d.status === AgreementStatus.IN_PROGRESS);
        break;
      case TabIndex.WAITING_COMPLETE:
        waitingCompleteData = data.filter(
          (d) =>
            d.status === AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED ||
            d.status === AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT
        );
        break;
      case TabIndex.CHECK_IN_OUT:
        noCheckInOutData = data.filter(
          (d) =>
            d.status === AgreementStatus.NO_CHECK_IN ||
            d.status === AgreementStatus.NO_CHECK_OUT ||
            d.status === AgreementStatus.NO_CHECK_IN_OUT
        );
        break;
      case TabIndex.REQUEST_CORRECTION:
        requestCorrectionData = data.filter(
          (d) =>
            d.status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
            d.status === AgreementStatus.REQUEST_CORRECTION_REJECTED
        );
        break;
      case TabIndex.COMPLETE:
        completeData = data.filter((d) => d.status === AgreementStatus.COMPLETE);
        break;
      case TabIndex.CANCEL:
        cancelData = data.filter((d) => d.status === AgreementStatus.CANCEL);
        break;
      case TabIndex.NO_SHOW:
        noShowData = data.filter((d) => d.status === AgreementStatus.NO_SHOW);
        break;
      default:
        break;
    }
    // feat_screen_04-02-01-01_start
    navigate('/s04-02', {
      state: {
        tabIndex: index,
      },
      replace: true,
    });
    // feat_screen_04-02-01-end
  };

  // feat_screen_04-02-01-01_start
  useEffect(() => {
    navigate('/s04-02', {
      state: {
        tabIndex: tab,
        dateValue: arbeitDate,
      },
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arbeitDate]);
  // feat_screen_04-02-01-end

  return !isMobile ? (
    <CurrentFilterContext.Provider value={FilterContextValues}>
      <MainContentWrapper>
        <MainContentHeader>
          <MainContentTitle text="成約バイト状況" />
        </MainContentHeader>
        <MainContentArea>
          <Card overflow="hidden">
            <Box height="100%" pt={16}>
              <Tab
                items={[
                  {
                    content: <AllList data={data} />,
                    label: '一覧',
                    labelLength: 104,
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={standByData} />,
                    label: 'スタンバイ',
                    labelLength: 104,
                    hasNotification: standByData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={inProgressData} />,
                    label: 'バイト中',
                    labelLength: 104,
                    hasNotification: inProgressData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={waitingCompleteData} />,
                    label: '完了待ち',
                    labelLength: 104,
                    hasNotification: waitingCompleteData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={noCheckInOutData} />,
                    label: 'チェックイン/アウト',
                    labelLength: 152,
                    hasNotification: noCheckInOutData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={requestCorrectionData} />,
                    label: '時間修正',
                    labelLength: 104,
                    hasNotification: requestCorrectionData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={completeData} />,
                    label: '完了',
                    labelLength: 104,
                    hasNotification: completeData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={cancelData} />,
                    label: 'キャンセル',
                    labelLength: 104,
                    hasNotification: cancelData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={noShowData} />,
                    label: 'バックレ',
                    labelLength: 104,
                    hasNotification: noShowData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                ]}
                defaultIndex={tabIndex}
              />
            </Box>
          </Card>
        </MainContentArea>
        {loading && <Loading />}
      </MainContentWrapper>
    </CurrentFilterContext.Provider>
  ) : (
    /* feat_screen_04-02-01-01_start */
    <CurrentFilterContext.Provider value={FilterContextValues}>
      <Box display="flex" flexDirection="column" columnGap={16} width="100vw" height="100%">
        <Box>
          <MultiMenuHeader
            items={[
              {
                label: '募集・採用',
              },
              {
                label: '成約バイト状況',
              },
            ]}
            mainHeader="成約バイト状況"
          />
        </Box>
        <Box height="100%" display="flex" px={16} pt={16} pb={isMobile ? 76 : 16}>
          <Card>
            <Box flex="1">
              <Dropdown
                tab={tab}
                setTab={setTab}
                fullWidth
                items={[
                  {
                    content: <AllList data={data} />,
                    label: '一覧',
                    labelLength: 104,
                    hasNotification: data.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={standByData} />,
                    label: 'スタンバイ',
                    labelLength: 104,
                    hasNotification: standByData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={inProgressData} />,
                    label: 'バイト中',
                    labelLength: 104,
                    hasNotification: inProgressData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={waitingCompleteData} />,
                    label: '完了待ち',
                    labelLength: 104,
                    hasNotification: waitingCompleteData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={noCheckInOutData} />,
                    label: 'チェックイン/アウト',
                    labelLength: 152,
                    hasNotification: noCheckInOutData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={requestCorrectionData} />,
                    label: '時間修正',
                    labelLength: 104,
                    hasNotification: requestCorrectionData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={completeData} />,
                    label: '完了',
                    labelLength: 104,
                    hasNotification: completeData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={cancelData} />,
                    label: 'キャンセル',
                    labelLength: 104,
                    hasNotification: cancelData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                  {
                    content: <FilteredListTemplate data={noShowData} />,
                    label: 'バックレ',
                    labelLength: 104,
                    hasNotification: noShowData.some((d) => d.hasNotification),
                    callBack: (index) => handleTabClick(index),
                  },
                ]}
                defaultIndex={tabIndex}
              />
            </Box>
          </Card>
        </Box>
      </Box>
      {loading && <Loading />}
    </CurrentFilterContext.Provider>
    /* feat_screen_04-02-01-01_end */
  );
}

export default S0402;
