/**
 * Figma ID: 02-02-14
 * 名称: メールアドレス変更登録
 */
import React from 'react';

import { useGetTMemberCanChangeEmailLazyQuery } from 'graphql/graphql-mw';

import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { styled } from '@linaria/react';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { DatePicker } from 'components/datePicker';
import { useErrorModal } from 'components/error/errorModalProvider';
import { emailPattern } from 'components/const';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import getYear from 'date-fns/getYear';
import { ContentTitle } from '../component/ContentTitle';

type EmailInfo = {
  firstName: string;
  lastName: string;
  firstKana: string;
  lastKana: string;
  managerBirthday: Date;
  businessName: string;
  zipCode1: string;
  zipCode2: string;
  phone: string;
  email: string;
};

type Props = {
  tpcode: string;
  emailInfo: EmailInfo | null;
  toNext: (args: {
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    managerBirthday: Date;
    businessName: string;
    zipCode1: string;
    zipCode2: string;
    phone: string;
    email: string;
  }) => void;
  toBack: () => void;
};
// feat screen 02-02-05 start
const BoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #008599;
`;
// feat screen 02-02-05 end
export default function EmailUpdate({ tpcode, emailInfo, toNext, toBack }: Props) {
  const { openErrorModal } = useErrorModal();
  /* feat_screen_02-02-05_start */
  const isMobile = useIsMobile();
  /* feat_screen_02-02-05_end */
  const [getTMemberCanChangeEmail] = useGetTMemberCanChangeEmailLazyQuery();

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    managerFamilyName: string;
    managerFirstName: string;
    managerFamilyNameKana: string;
    managerFirstNameKana: string;
    managerBirthday: Date;
    store_name: string;
    zipCode1: string;
    zipCode2: string;
    managerPhone: string;
    mailAddress: string;
    confirmMailAddress: string;
  }>({
    reValidateMode: 'onChange',
  });

  const mailAddressWatch = watch('mailAddress');

  const onSubmit = handleSubmit((formData) => {
    getTMemberCanChangeEmail({
      context: { clientName: 'api_key' },
      variables: {
        code: tpcode,
        first_name: formData.managerFirstName,
        last_name: formData.managerFamilyName,
        first_kana: formData.managerFirstNameKana,
        last_kana: formData.managerFamilyNameKana,
        zip_code: formData.zipCode1 + formData.zipCode2,
        dob: `${String(formData.managerBirthday?.getFullYear())}-${String(
          (formData.managerBirthday?.getMonth() ?? 0) + 1
        )}-${String(formData.managerBirthday?.getDate())}`,
        mco_name: formData.store_name,
        phone: formData.managerPhone,
      },
    })
      .then((data) => {
        const errorCode = data.data?.getTMemberCanChangeEmail.ErrorCode;
        if (errorCode && errorCode < 0) {
          switch (errorCode) {
            case -51:
              openErrorModal({ message: 'この店舗は会員登録が完了しています。' });
              break;
            case -53:
              openErrorModal({ message: '入力情報が見つかりません。' });
              break;
            default:
              openErrorModal({ message: 'この店舗は会員として登録されておりません。' });
              break;
          }
        } else {
          toNext({
            firstName: formData.managerFirstName,
            lastName: formData.managerFamilyName,
            firstKana: formData.managerFirstNameKana,
            lastKana: formData.managerFamilyNameKana,
            managerBirthday: formData.managerBirthday,
            businessName: formData.store_name,
            zipCode1: formData.zipCode1,
            zipCode2: formData.zipCode2,
            phone: formData.managerPhone,
            email: formData.mailAddress,
          });
        }
      })
      .catch(() => {
        openErrorModal({ message: '入力された情報はデータベースに存在しません。' });
      });
  });

  const zipCode1Length = 3;
  const zipCode2Length = 4;
  // feat screen 02-02-05 start
  return !isMobile ? (
  // feat screen 02-02-05 end
    <Box display="grid" justifyContent="center" alignItems="center" pt={34} pb={34}>
      <ContentTitle title="メールアドレス変更依頼" />
      <Box height={50} display="flex" alignItems="center">
        <Text variant="body14">
          登録情報を再度入力して「運営へ報告」を押してください。運営にてユーザー登録情報の確認を行います。
        </Text>
      </Box>
      <Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名</InputLabel>
          <Box display="flex" gap={10}>
            <RhfInput
              defaultValue={emailInfo?.lastName || ''}
              rules={{ required: '責任者氏名を入力してください。' }}
              width={120}
              control={control}
              name="managerFamilyName"
              placeholder="姓"
            />
            <RhfInput
              defaultValue={emailInfo?.firstName || ''}
              rules={{ required: '責任者氏名を入力してください。' }}
              width={120}
              control={control}
              name="managerFirstName"
              placeholder="名"
            />
          </Box>
          {(errors.managerFamilyName || errors.managerFirstName) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyName?.message || errors.managerFirstName?.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名（フリガナ）</InputLabel>
          <Box display="flex" gap={10}>
            <RhfInput
              defaultValue={emailInfo?.lastKana || ''}
              rules={{
                required: '責任者氏名（フリガナ）を入力してください。',
                pattern: {
                  value: /^[ァ-ンヴー]*$/i,
                  message: 'カナ文字を入力してください',
                },
              }}
              width={120}
              control={control}
              name="managerFamilyNameKana"
              placeholder="セイ"
            />
            <RhfInput
              defaultValue={emailInfo?.firstKana || ''}
              rules={{
                required: '責任者氏名（フリガナ）を入力してください。',
                pattern: {
                  value: /^[ァ-ンヴー]*$/i,
                  message: 'カナ文字を入力してください',
                },
              }}
              width={120}
              control={control}
              name="managerFirstNameKana"
              placeholder="メイ"
            />
          </Box>
          {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者生年月日</InputLabel>
          <Controller
            name="managerBirthday"
            control={control}
            rules={{ required: '生年月日を入力してください' }}
            render={({ field }) => (
              <DatePicker
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  field.onChange(newValue);
                }}
                selected={field.value}
                yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                placeholderText="生年月日を入力"
              />
            )}
          />
          {errors.managerBirthday && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerBirthday.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号
          </Text>
          <Box>
            <RhfInput
              name="store_name"
              control={control}
              defaultValue={emailInfo?.businessName || ''}
              fullWidth
              placeholder=""
              rules={{ required: '事業者名または事業者氏名/屋号を入力' }}
            />
            {errors.store_name?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.store_name?.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の所在地郵便番号
          </Text>
          <Box display="flex" flexDirection="row" gap={16} alignItems="center">
            <Box>
              <RhfInput
                name="zipCode1"
                control={control}
                width={120}
                defaultValue={emailInfo?.zipCode1 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </Box>
            <Divider option="horizontal" length={7} />
            <Box>
              <RhfInput
                name="zipCode2"
                control={control}
                width={120}
                defaultValue={emailInfo?.zipCode2 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </Box>
          </Box>
          {(errors?.zipCode1 || errors.zipCode2) && (
            <Text variant="caption12" color="cautionRed">
              {errors.zipCode1?.message ?? errors.zipCode2?.message}
            </Text>
          )}
        </Box>

        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の電話番号
          </Text>
          <RhfInput
            defaultValue={emailInfo?.phone || ''}
            width={328}
            control={control}
            name="managerPhone"
            rules={{
              pattern: {
                value: /^\d*$/,
                message: '数字を使用してください。',
              },
            }}
            placeholder="事業者名または事業者氏名/屋号の所在地電話番号を入力"
          />
          {errors.managerPhone && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerPhone.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス</InputLabel>
          <RhfInput
            name="mailAddress"
            control={control}
            fullWidth
            defaultValue={emailInfo?.email || ''}
            placeholder="メールアドレス"
            rules={{
              required: 'メールアドレスを入力してください。',
              pattern: {
                value: emailPattern,
                message: '入力形式がメールアドレスではありません。',
              },
            }}
          />
          {errors.mailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.mailAddress.message}
            </Text>
          )}
        </Box>
        <Box height={78} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス（確認）</InputLabel>
          <RhfInput
            name="confirmMailAddress"
            control={control}
            fullWidth
            defaultValue=""
            placeholder="メールアドレス（確認）"
            rules={{
              required: 'メールアドレスを入力してください。',
              validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
            }}
          />
          {errors.confirmMailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.confirmMailAddress.message}
            </Text>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" gap={8}>
        <Box display="grid" justifyContent="center">
          <MainButton
            variant="secondary"
            onClick={() => {
              toBack();
            }}
          >
            確認コード入力に戻る
          </MainButton>
        </Box>
        <Box display="grid" justifyContent="center">
          <MainButton
            onClick={() => {
              onSubmit();
            }}
          >
            運営へ報告
          </MainButton>
        </Box>
      </Box>
    </Box>
    // feat screen 02-02-05 start
  ) : (
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="メールアドレス変更依頼" />
      <Box display="flex" alignItems="center" pt={18}>
        <Text variant="body14">
          登録情報を再度入力して「運営へ報告」を押してください。運営にてユーザー登録情報の確認を行います。
        </Text>
      </Box>
      <Box pt={14}>
        <Box display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名</InputLabel>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                defaultValue={emailInfo?.lastName || ''}
                rules={{ required: '責任者氏名を入力してください。' }}
                fullWidth
                control={control}
                name="managerFamilyName"
                placeholder="姓"
              />
            </div>
            <div style={{width: "100%"}}>
              <RhfInput
                defaultValue={emailInfo?.firstName || ''}
                rules={{ required: '責任者氏名を入力してください。' }}
                fullWidth
                control={control}
                name="managerFirstName"
                placeholder="名"
              />
            </div>
          </Box>
          {(errors.managerFamilyName || errors.managerFirstName) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyName?.message || errors.managerFirstName?.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者氏名（フリガナ）</InputLabel>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                defaultValue={emailInfo?.lastKana || ''}
                rules={{
                  required: '責任者氏名（フリガナ）を入力してください。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/i,
                    message: 'カナ文字を入力してください',
                  },
                }}
                fullWidth
                control={control}
                name="managerFamilyNameKana"
                placeholder="セイ"
              />
            </div>
            <div style={{width: "100%"}}>
              <RhfInput
                defaultValue={emailInfo?.firstKana || ''}
                rules={{
                  required: '責任者氏名（フリガナ）を入力してください。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/i,
                    message: 'カナ文字を入力してください',
                  },
                }}
                fullWidth
                control={control}
                name="managerFirstNameKana"
                placeholder="メイ"
              />
            </div>
          </Box>
          {(errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message) && (
            <Text variant="caption12" color="cautionRed">
              {errors.managerFamilyNameKana?.message || errors.managerFirstNameKana?.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>責任者生年月日</InputLabel>
          <Controller
            name="managerBirthday"
            control={control}
            rules={{ required: '生年月日を入力してください' }}
            render={({ field }) => (
              <DatePicker
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  field.onChange(newValue);
                }}
                selected={field.value}
                yearRange={_.range(getYear(new Date()) - 100, getYear(new Date()), 1)}
                placeholderText="生年月日を入力"
              />
            )}
          />
          {errors.managerBirthday && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerBirthday.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号
          </Text>
          <Box>
            <RhfInput
              name="store_name"
              control={control}
              defaultValue={emailInfo?.businessName || ''}
              fullWidth
              placeholder=""
              rules={{ required: '事業者名または事業者氏名/屋号を入力' }}
            />
            {errors.store_name?.message && (
              <Text variant="caption12" color="cautionRed">
                {errors.store_name?.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の所在地郵便番号
          </Text>
          <Box display="flex" gap={12} justifyContent='space-between'>
            <div style={{width: "100%"}}>
              <RhfInput
                name="zipCode1"
                control={control}
                fullWidth
                defaultValue={emailInfo?.zipCode1 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode1Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </div>
            {/* <Divider option="horizontal" length={7} /> */}
            <div style={{width: "100%"}}>
              <RhfInput
                name="zipCode2"
                control={control}
                fullWidth
                defaultValue={emailInfo?.zipCode2 || ''}
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください。',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字を使用してください。',
                  },
                  maxLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                  minLength: { value: zipCode2Length, message: `郵便番号の形式で入力してください` },
                }}
              />
            </div>
          </Box>
          {(errors?.zipCode1 || errors.zipCode2) && (
            <Text variant="caption12" color="cautionRed">
              {errors.zipCode1?.message ?? errors.zipCode2?.message}
            </Text>
          )}
        </Box>

        <Box pt={16} display="flex" flexDirection="column" gap={4}>
          <Text variant="h3" color="blueGray">
            事業者名または事業者氏名/屋号の電話番号
          </Text>
          <RhfInput
            defaultValue={emailInfo?.phone || ''}
            fullWidth
            control={control}
            name="managerPhone"
            rules={{
              pattern: {
                value: /^\d*$/,
                message: '数字を使用してください。',
              },
            }}
            placeholder="事業者名または事業者氏名/屋号の所在地電話番号を入力"
          />
          {errors.managerPhone && (
            <Box>
              <Text variant="caption12" color="cautionRed">
                {errors.managerPhone.message}
              </Text>
            </Box>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス</InputLabel>
          <RhfInput
            name="mailAddress"
            control={control}
            fullWidth
            defaultValue={emailInfo?.email || ''}
            placeholder="メールアドレス"
            rules={{
              required: 'メールアドレスを入力してください。',
              pattern: {
                value: emailPattern,
                message: '入力形式がメールアドレスではありません。',
              },
            }}
          />
          {errors.mailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.mailAddress.message}
            </Text>
          )}
        </Box>
        <Box pt={14} display="flex" flexDirection="column" gap={4}>
          <InputLabel>正しいメールアドレス（確認）</InputLabel>
          <RhfInput
            name="confirmMailAddress"
            control={control}
            fullWidth
            defaultValue=""
            placeholder="メールアドレス（確認）"
            rules={{
              required: 'メールアドレスを入力してください。',
              validate: (value) => value === mailAddressWatch || 'メースアドレスが一致しません。',
            }}
          />
          {errors.confirmMailAddress?.message && (
            <Text variant="caption12" color="cautionRed">
              {errors.confirmMailAddress.message}
            </Text>
          )}
        </Box>
      </Box>
      <Box display="flex" pb={24} pt={22} justifyContent="space-between" gap={5}>
        <MainButton
          fullWidth
          customHeight={40}
          variant="secondary"
          onClick={() => {
            toBack();
          }}
        >
          確認コード入力に戻る
        </MainButton>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            onSubmit();
          }}
        >
          運営へ報告
        </MainButton>
      </Box>
    </Box>
    // feat screen 02-02-05 start
  );
}
