import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';

/**
 * Figma ID
 * 04-02-06-10
 * 否認確認完了
 */

function DenialCompletedModal(props: { isOpen: boolean; onModalClose: () => void }) {
  const { isOpen, onModalClose } = props;

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        否認 完了
      </Text>
    ),
    content: (
      <Box>
        <Text variant="body14" color="black">
          勤務時間の修正を否認しました。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default DenialCompletedModal;
