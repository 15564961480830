import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'layout/layout';
import { useAuth } from 'hooks/authProvider';
import { Box } from 'components/box';
import { styled } from '@linaria/react';
import { Text } from 'components/text';
import { Stars } from 'components/stars';
import { TransferProcessStatus } from 'components/const';
import { useMainMenu } from 'mainMenu/useMainMenu';
import useIsMobile from 'hooks/responsive/useIsMobile';

const StyledHeaderContent = styled.div`
  /* feat_common_responsive_layout_start */
  @media only screen and (max-width: 768px) {
    margin: 0 4px;
  }
  /* feat_common_responsive_layout_end */
  padding: 4px 8px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

// このファイル内でuseAuthなどからユーザー名などの認証情報を注入する
export function MainLayout() {
  const { storeInfo, signOut, tpmemId } = useAuth();

  const location = useLocation();

  const { score, mainMenu, transferProcessStatus } = useMainMenu(tpmemId);

  const handleLogout = () => {
    signOut();
  };

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <Layout
      currentRoute={location.pathname}
      // Figma上ではメールアドレスを表示しているが、クエリからはメールアドレスが取得できないのと
      // ログインに使うのは t_base_tbl.code または t_member_tbl.login_id の値なのでその値を表示する
      mailAddress={storeInfo?.code ?? ''}
      userName={storeInfo?.name ?? ''}
      onClickLogout={handleLogout}
      mainMenuBarData={mainMenu}
      headerContent={
        !isMobile ? (
          <Box mx={12} mb={16}>
            <StyledHeaderContent>
              <Text variant="chip10" color="darkBlue" bold>
                {storeInfo?.name}
              </Text>
              {transferProcessStatus === TransferProcessStatus.UNAPPLIED ? (
                <Box borderRadius={4} backgroundColor="superLiteGray" display="flex" justifyContent="center">
                  <Text variant="caption12" bold color="blue">
                    与信審査中
                  </Text>
                </Box>
              ) : null}
              <Box display="flex">
                <Stars size={16} rate={score} monotone />
                <Text variant="caption12" color="darkBlue" bold>
                  {score}
                </Text>
              </Box>
            </StyledHeaderContent>
          </Box>
        ): (
          /* feat_common_responsive_main_layout_start */
          <Box mx={12} mb={15}>
            <StyledHeaderContent>
              <Box pl={8}>
                <Box>
                  <Text variant="caption12" color="darkBlue" bold>
                    {storeInfo?.name}
                  </Text>
                </Box>
                {transferProcessStatus === TransferProcessStatus.UNAPPLIED ? (
                  <Box borderRadius={4} backgroundColor="superLiteGray" display="flex" justifyContent="center">
                    <Text variant="caption12" bold color="blue">
                      与信審査中
                    </Text>
                  </Box>
                ) : null}
                <Box display="flex" pt={2} pl={1.4}>
                  <Stars size={14} rate={score} monotone />
                  <Box ml={2}>
                    <Text display="block" variant="caption12" color="darkBlue" bold>
                      {score}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </StyledHeaderContent>
          </Box>
          /* feat_common_responsive_main_layout_start */
        )
      }
    >
      <Outlet />
    </Layout>
  );
}
