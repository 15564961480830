import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './app.css';
import { S03 } from 'pages/s03';
import S0402 from 'pages/s04-02';
import S0402Details from 'pages/s04-02/[id]';
import { S0403 } from 'pages/s04-03';
import { S0403Details } from 'pages/s04-03/[id]';
import S09 from 'pages/s09';
import S08 from 'pages/s08';
import S06 from 'pages/s06';
import { SignUp } from 'pages/s01';
import { S0501 } from 'pages/s05-01';
import { S0501Detail } from 'pages/s05-01/[id]';
import { S06Details } from 'pages/s06/[id]';
import { S0701 } from 'pages/s07-01';
import { S0702 } from 'pages/s07-02';
import { S0502 } from 'pages/s05-02';
import { S0502Detail } from 'pages/s05-02/[id]';
import { useAuth } from 'hooks/authProvider';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import { MemberStoreStatus } from 'components/const';

import { MainLayout } from './MainLayout';
import { S040101Details } from './pages/s04-01-01/[id]';
import { S040101 } from './pages/s04-01-01';
import { Screen020201 } from './pages/s02/forgotPassword';
import { SignIn } from './pages/s02';
import { QuickAccess } from './pages/s00';
import { Shortcuts } from './pages/s00/shortcuts';
import { QuickLinks } from './pages/s00/quickLinks';
import { QuickApplies } from './pages/s00/quickApplies';

function ProtectedRoute({ children }: { children: ReactElement }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/signIn" replace />;
  }

  return children;
}

const defaultRoutes = (
  <>
    <Route path="/" element={<S03 />} />
    <Route index element={<S03 />} />
    <Route path="/s04-01-01" element={<S040101 />} />
    <Route path="/s04-01-01/:id" element={<S040101Details />} />
    <Route path="/s04-02" element={<S0402 />} />
    <Route path="/s04-02/:id" element={<S0402Details />} />
    <Route path="/s04-03" element={<S0403 />} />
    <Route path="/s04-03/:id" element={<S0403Details />} />
    <Route path="/s05-01" element={<S0501 />} />
    <Route path="/s05-01/:id" element={<S0501Detail />} />
    <Route path="/s05-02" element={<S0502 />} />
    <Route path="/s05-02/:id" element={<S0502Detail />} />
    <Route path="/s08" element={<S08 />} />
    { /* feat_screen 11 handle set tab select start */ }
    <Route path="/s08/:id" element={<S08 />} />
    { /* feat_screen 11 handle set tab select start */ }
    <Route path="/s06" element={<S06 />} />
    <Route path="/s07-01" element={<S0701 />} />
    <Route path="/s07-02" element={<S0702 />} />
    { /* feat_screen 11 handle set tab select start */ }
    <Route path="/s07-02/:id" element={<S0702 />} />
    { /* feat_screen 11 handle set tab select end */ }
    <Route path="/s06/:id" element={<S06Details />} />
    <Route path="/s09" element={<S09 />} />
  </>
);

const pausedMemberRoutes = (
  <>
    <Route path="/" element={<S09 />} />
    <Route index element={<S09 />} />
    <Route path="/s07-01" element={<S0701 />} />
    <Route path="/s09" element={<S09 />} />
  </>
);
// feat screen 00 start
const quickAccessRoute = (
  <>
    <Route path="/s00" element={<Shortcuts />} />
    <Route path="/s00/s00-01" element={<QuickLinks />}/>
    <Route path="/s00/s00-02" element={<QuickApplies />}/>
  </>
);
// feat screen 00 end

function App() {
  const auth = useAuth();
  const memberStoreStatus = useMemberStoreStatus();

  if (auth.isLoading) {
    return <div />;
  }

  const route = memberStoreStatus === MemberStoreStatus.PAUSE ? pausedMemberRoutes : defaultRoutes;

  return (
    <BrowserRouter>
      <Routes>
        {/* react-router-dom の OUTLET を使用し共通部分の無駄なレンダリングを回避 */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          {route}
        </Route>
        <Route path="signIn" element={<SignIn />} />
        <Route path="screen020201" element={<Screen020201 />} />
        <Route path="*" element={<p>Page Not Found</p>} />
        <Route path="/signUp" element={<SignUp />} />
        {/* feat add screen 00 start */}
        <Route 
          path="/s00" 
          element={
            <ProtectedRoute>
              <QuickAccess />
            </ProtectedRoute>
          }
        >
          {quickAccessRoute}
        </Route>
        {/* feat add screen 00 end */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
