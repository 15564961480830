import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useMemo, useEffect } from 'react';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { CategorizedInfo } from 'components/categorizedInfo';
import { Icon } from 'components/icon';
import Divider from 'components/divider/divider';
import { ScrollableArea } from 'components/scrollableArea';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
/**
 * Figma ID: 06-02-02
 * 名称: スキルチェックシート
 */

interface Props {
  userId: string;
}

export function SkillCheckSheet({ userId }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;
  const {
    data,
    loading,
    error: getVtRegBelongListSkillCheckByTIdBIdError,
  } = gql.useGetVtRegBelongListSkillCheckByTIdBIdQuery({
    variables: { tpmem_id: tpmemId || 0, btmem_id: Number(userId) },
    fetchPolicy: 'no-cache',
  });

  const { openErrorModal } = useErrorModal();
  useEffect(() => {
    if (getVtRegBelongListSkillCheckByTIdBIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtRegBelongListSkillCheckByTIdBIdError, openErrorModal]);

  const dataSkill = useMemo(
    () => (data?.getVTRegBelongListSkillCheckByTIdBId.skill || []) as gql.VtBtSkillCheckInfo[],
    [data]
  );

  const groupedByName = useMemo(
    () =>
      dataSkill?.reduce((acc: { [no: number]: gql.VtBtSkillCheckInfo[] }, item: gql.VtBtSkillCheckInfo) => {
        if (item.no) {
          if (!acc[item.no]) {
            acc[item.no] = [];
          }
          acc[item.no].push(item);
        }
        return acc;
      }, {}),
    [dataSkill]
  );

  const convertedData = useMemo(
    () =>
      Object.keys(groupedByName as gql.VtBtSkillCheck).map((key) => {
        if (!groupedByName) return null;
        const value = groupedByName[Number(key)];
        return {
          label: value?.[0].name || '',
          skills: value?.map((v) => ({
            name: v.check_item,
            checked: v.checked,
          })),
        };
      }),
    [groupedByName]
  );

  if (loading) {
    Loading();
  }

  return !isMobile ? (
    <Box px={16} pt={24} pb={56} width="100%">
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={18}>
        <Text variant="h2" color="darkBlue">
          スキルチェックシート
        </Text>
        <Box display="flex">
          <Box gap={8}>
            <Text variant="body14" color="darkBlue">
              ランク&nbsp;
            </Text>
          </Box>
          <Box gap={8}>
            <Text variant="body16" color="darkBlue" bold>
              {data?.getVTRegBelongListSkillCheckByTIdBId.list?.rank_name}
            </Text>
          </Box>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <ScrollableArea>
        <Box mb={16}>
          <CategorizedInfo
            items={convertedData?.map((d) => ({
              label: d?.label || '',
              contents: d?.skills.map((s) => (
                <Box display="flex" alignItems="center" gap={8} key={s.name}>
                  {s.checked ? <Icon color="#008599" name="check" /> : <Box width={24}>&nbsp;</Box>}
                  <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                    {s.name}
                  </Text>
                </Box>
              )) as React.ReactNode[],
            }))}
          />
        </Box>
      </ScrollableArea>
    </Box>
  ) : (
    // feat screen 06-02-01 start
    <Box px={16} pt={16} pb={56} width="100%">
      <Box display="flex" flexDirection="column" alignItems="center" mb={15}>
        <Text variant="h2" color="darkBlue">
          スキルチェックシート
        </Text>
        <Box display="flex">
          <Box gap={8}>
            <Text variant="body14" color="darkBlue">
              ランク&nbsp;
            </Text>
          </Box>
          <Box gap={8}>
            <Text variant="body16" color="darkBlue" bold>
              {data?.getVTRegBelongListSkillCheckByTIdBId.list?.rank_name}
            </Text>
          </Box>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box mb={16} mt={8}>
        <CategorizedInfo
          items={convertedData?.map((d) => ({
            label: d?.label || '',
            contents: d?.skills.map((s) => (
              <Box display="flex" alignItems="flex-start" gap={8} key={s.name}>
                <Box>
                  {s.checked ? <Icon color="#008599" name="check" /> : <Box width={24}>&nbsp;</Box>}
                </Box>
                <Text variant="body14" color={s.checked ? 'black' : 'liteGray'}>
                  {s.name}
                </Text>
              </Box>
            )) as React.ReactNode[],
          }))}
        />
      </Box>
    </Box>
    // feat screen 06-02-01 end
  );
}
