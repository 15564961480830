/**
 * Figma ID: 01-01-02
 * 名称:  店舗未登録の場合 確認
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { Loading } from 'components/loading/loading';
import { reportNewStore } from 'components/api';
import { useErrorModal } from 'components/error/errorModalProvider';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  bizName: string;
  brandName: string;
  tpmemName: string;
  zipCode1: string;
  zipCode2: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  phone: string;
  lat: string;
  lng: string;
  managerName: string;
  mailAddress: string;
  comment: string;
  toNext: () => void;
  toBack: () => void;
};

export function StopRegistrationContent({
  bizName,
  brandName,
  tpmemName,
  zipCode1,
  zipCode2,
  prefecture,
  city,
  address1,
  address2,
  phone,
  lat,
  lng,
  managerName,
  mailAddress,
  comment,
  toNext,
  toBack,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const section1 = [
    { label: '業種', value: bizName },
    { label: 'ブランド', value: brandName },
    { label: '店舗名', value: tpmemName },
    {
      label: '住所',
      value: `〒${zipCode1}-${zipCode2} ${prefecture}${city}${address1}${address2}`,
    },
    { label: '電話番号', value: phone },
    { label: '緯度/経度', value: `${lat} /  ${lng}` },
    { label: 'ご担当者様氏名', value: managerName },
    {
      label: 'メールアドレス',
      value: mailAddress,
    },
    { label: 'コメント', value: comment },
  ];
  const [isLoading, setIsLoading] = React.useState(false);
  const { openErrorModal } = useErrorModal();
  // screen 01-01-02 No24.1 start
  return !isMobile ? (
    <>
      {isLoading && <Loading />}
      <Box width={752} display="flex" flexDirection="column" gap={24}>
        <ContentTitle title="新規登録 - 店舗未登録の場合 確認" />
        <Box>
          <Text variant="body14">下記内容で送信します。よろしければ送信ボタンを押してください。</Text>
        </Box>
        <Box>
          <Divider option="horizontal" />
          <Box py={8}>
            {section1.map((item, index) => (
              <Box key={Number(index)}>
                <Box display="flex" alignItems="center">
                  <Box width={160}>
                    <Text variant="body14" color="darkGray">
                      {item.label}
                    </Text>
                  </Box>
                  <Text variant="body14">{charcterReplaceBr(item.value)}</Text>
                </Box>
                <Box py={8}>
                  <Divider option="horizontal" />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" gap={8} justifyContent="center">
          <MainButton variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            onClick={() => {
              setIsLoading(true);
              reportNewStore({
                managerName,
                mailAddress,
                bizName,
                brandName,
                tpmemName,
                zipCode: `${zipCode1}${zipCode2}`,
                prefecture,
                city,
                address1,
                address2,
                phone,
                lat,
                lng,
                comment,
              })
                .then(() => {
                  toNext();
                })
                .catch(() => {
                  openErrorModal({ message: '送信できませんでした。' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            送信
          </MainButton>
        </Box>
      </Box>
    </>
  ) : (
    // Responsive Component
    <>
      {isLoading && <Loading />}
      <Box width="100vw" display="flex" flexDirection="column" px={16}>
        <ContentTitle title="新規登録 - 店舗未登録の場合 確認" />
        <Box pt={18} pb={13}>
          <Text display='block' align='center' variant="body14">下記内容で送信します。よろしければ送信ボタンを押してください。</Text>
        </Box>
        <Box>
          <Divider option="horizontal" />
          <Box pt={18}>
            {section1.map((item, index) => (
              <Box key={Number(index)}>
                <Box display="flex" alignItems="center" justifyContent='space-between'>
                  <Box minWidth={160} maxWidth={160}>
                    <Text display='block' variant="body14" color="darkGray">
                      {item.label}
                    </Text>
                  </Box>
                  <Text display='block' align='right' variant="body14">{charcterReplaceBr(item.value)}</Text>
                </Box>
                {section1.length - 1 !== index && (
                  <Box pt={15} pb={16}>
                    <Divider option="horizontal" />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" pt={22} pb={18} justifyContent="space-between" gap={8}>
          <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
            戻る
          </MainButton>
          <MainButton
            fullWidth
            customHeight={40}
            onClick={() => {
              setIsLoading(true);
              reportNewStore({
                managerName,
                mailAddress,
                bizName,
                brandName,
                tpmemName,
                zipCode: `${zipCode1}${zipCode2}`,
                prefecture,
                city,
                address1,
                address2,
                phone,
                lat,
                lng,
                comment,
              })
                .then(() => {
                  toNext();
                })
                .catch(() => {
                  openErrorModal({ message: '送信できませんでした。' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            送信
          </MainButton>
        </Box>
      </Box>
    </>
  );
  // screen 01-01-02 No24.1 end
}
