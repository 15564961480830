import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ModalRet } from 'pages/s05-01/type';
import React from 'react';

export function Complete(
  onClose: ()=> void,
): ModalRet {

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */ 

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        Thanksメッセージ付与
      </Text>
    ),
    content: !isMobile ?(
      <Box display='flex' height='100%' flexDirection='column' flex='1' gap={16} pa={8}>
        <Text variant='body14'>
          Thanksメッセージの付与が完了しました。
        </Text>
      </Box>
    ) : (
      // feat_screen_05-01-05-04_start
      <Box display='flex' height='100%' flexDirection='column' flex='1'>
        <Text display="block" variant='body14'>
          Thanksメッセージの付与が完了しました。
        </Text>
      </Box>
      // feat_screen_05-01-05-04_end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton 
          // feat_screen_05-01-05-04_start
          width={!isMobile ? 104 : 96} 
          thin={!isMobile}
          // feat_screen_05-01-05-04_end
          variant="secondary" 
          onClick={() => onClose()}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    // feat_screen_05-01-05-04_start
    width: !isMobile ? 384 : "100%",
    height:!isMobile ? 189 : 169
    // feat_screen_05-01-05-04_end
  };

  return modalContent;
}