import React from 'react';
import {
  useGetVbMemberBaseByIdQuery,
  useGetMRejectReasonByKindQuery,
  GetVbMemberBaseByIdQuery,
  Exact,
  VbMemberBase,
  VtBizBtmemInfo,
} from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { InfoItemsType } from 'pages/s05-01/type';
import { Divider } from 'components/newDivider';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { identificationLabel } from 'components/labelList/identificationLabel';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { BtmemBlockFlag, EmployeeType, WorkStatus } from 'pages/s05-01/const';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult } from '@apollo/client';
import { AddBlockModal, AddReportModal, RemoveBlockModal } from 'pages/s05-01/modal';

enum ModalType {
  NONE = 'none',
  ADD_BLOCK = 'addBlock',
  REMOVE_BLOCK = 'removeBlock',
  ADD_REPORT = 'addReport',
}

export function BaseInfo(props: {
  refetchHeaderData: (
    variables?:
      | Partial<
          Exact<{
            id: number;
            tpmem_id: number;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetVbMemberBaseByIdQuery>>;
  /* feat_screen_05-01-01-02_add_props_start */ 
  blogFlag?: boolean;
  /* feat_screen_05-01-01-02_add_props_end */
}) {
  const { 
    refetchHeaderData ,
    /* feat_screen_05-01-01-02_add_props_start */ 
    blogFlag
    /* feat_screen_05-01-01-02_add_props_end */ 
  } = props;

  const params = useParams<{ id: string }>();
  const id = parseInt(params.id ?? '0', 10);

  const auth = useAuth();
  const tpmemId = auth.tpmemId ?? 0;

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.NONE);
  const { openErrorModal } = useErrorModal();
  const {
    data: { getVBMemberBaseById: { base, info } } = {
      getVBMemberBaseById: {
        base: {} as VbMemberBase,
        info: {} as VtBizBtmemInfo,
      },
    },
    loading: baseInfoLoading,
    error: baseInfoError,
    refetch: refetchBaseInfo,
  } = useGetVbMemberBaseByIdQuery({
    variables: { id, tpmem_id: tpmemId },
  });

  const BeitravelerRejectionReasonType = 3;
  const {
    data: rejectionReasonsData,
    loading: rejectionReasonsLoading,
    error: rejectionReasonsError,
  } = useGetMRejectReasonByKindQuery({
    variables: { kind: BeitravelerRejectionReasonType },
    context: { clientName: 'master' },
  });

  React.useEffect(() => {
    if (baseInfoError || rejectionReasonsError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [baseInfoError, rejectionReasonsError, openErrorModal]);

  const reasonOptions = rejectionReasonsData?.getMRejectReasonByKind?.map((reason) => ({
    label: reason?.reason ?? '',
    value: reason?.reason ?? '',
  }));

  const lcBizNameList = base.lc_biz_names?.split(',') ?? [];
  const licenceList = base.license_list?.split('\n') ?? [];

  const licenceContent: InfoItemsType = lcBizNameList.map((lcBizName, index) => ({
    label: `${lcBizName}資格`,
    content: licenceList?.[index],
  }));

  const bizName = info.biz_name ?? '';

  // belong_flag, sub_flag は「有」「無」の文字列のため、そのまま表示すれば良い。
  // APIに変更があった場合、このコメントの妥当性を再検証してください。
  const hasBelongingStore = info.belong_flag;
  const hasSubStore = info.sub_flag;
  // 他店/一般用
  const isOtherStore = info.type === EmployeeType.OTHER_STORE;
  // 他店/一般用 完了、キャンセル、バックレ
  const isOtherStoreNoDisp = isOtherStore && Object.values(WorkStatus).includes(info.work_status as WorkStatus);
  const belongStoreContent: InfoItemsType = [
    { label: `所属店舗(${bizName})`, content: hasBelongingStore },
    { label: `サブ店舗(${bizName})`, content: hasSubStore },
  ];

  const infoItems: InfoItemsType = [];

  if (!isOtherStoreNoDisp) {
    infoItems.push(
      { label: '氏名', content: base.name },
      { label: '氏名(フリガナ)', content: base.kana },
      { label: '会員番号', content: base.btmem_number },
      { label: '電話番号', content: base.phone },
      { label: '年齢', content: base.age },
      { label: 'ランク', content: info.rank_name },
      { label: 'スコア', content: base.score },
      { label: '達成都道府県', content: base.state_count },
      { label: 'バイト回数', content: `${base.worked_count ?? ''} ${base.badge_name ?? ''}` },
      {
        label: '住所',
        content: isOtherStore
          ? `${base.state ?? ''}`
          : `${base.state ?? ''}${base.city ?? ''} ${base.address ?? ''}${base.address2 ?? ''}`,
      },

      ...belongStoreContent.flat(),

      { label: '性別', content: base.gender },
      { label: '保有資格', content: base.general_licenses ?? '-' },

      ...licenceContent,

      { label: '制服サイズ', content: base.uniform_size },
      { label: '遅刻率', content: `${base.late_count ?? '-'}回/${base.late_per ?? '-'}%` },
      { label: 'キャンセル率', content: `${base.cancel_count ?? '-'}回/${base.cancel_per ?? '-'}%` },
      { label: 'ドタキャン率', content: `${base.imminent_count ?? '-'}回/${base.imminent_per ?? '-'}%` },
      { label: '本人確認', content: identificationLabel(base.identification || 0, true) }
    );
  } else {
    // 他店/一般用 完了、キャンセル、バックレ
    infoItems.push(
      { label: '会員番号', content: base.btmem_number },
      { label: '年齢', content: base.age },
      { label: 'ランク', content: base.rank_names },
      { label: 'スコア', content: base.score },
      { label: 'バイト回数', content: `${base.worked_count ?? ''} ${base.badge_name ?? ''}` },
      {
        label: '住所',
        content: isOtherStore
          ? `${base.state ?? ''}`
          : `${base.state ?? ''}${base.city ?? ''} ${base.address ?? ''}${base.address2 ?? ''}`,
      },

      ...belongStoreContent.flat(),

      { label: '性別', content: base.gender },
      { label: '保有資格', content: base.general_licenses },

      ...licenceContent,

      { label: '制服サイズ', content: base.uniform_size },
      { label: '遅刻率', content: `${base.late_count ?? '-'}回/${base.late_per ?? '-'}%` },
      { label: 'キャンセル率', content: `${base.cancel_count ?? '-'}回/${base.cancel_per ?? '-'}%` },
      { label: 'ドタキャン率', content: `${base.imminent_count ?? '-'}回/${base.imminent_per ?? '-'}%` }
    );
  }

  const loading = baseInfoLoading || rejectionReasonsLoading;

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return (
    <>
      {loading && <Loading />}
      {
        !isMobile ? (
          <Box px={16} display="flex" flex="1" flexDirection="column">
            <Box display="flex" flexDirection="row" py={16} justifyContent="space-between">
              <Text variant="h2" color="darkBlue">
                基本情報
              </Text>
              <Box display="flex" flexDirection="row" gap={8}>
                <MainButton
                  icon="campaign"
                  variant="warnSecondary"
                  iconColor="#E50000"
                  thin
                  onClick={() => setActiveModalType(ModalType.ADD_REPORT)}
                >
                  通報
                </MainButton>
                {info.block_flag === BtmemBlockFlag.TRUE ? (
                  <MainButton variant="warnSecondary" thin onClick={() => setActiveModalType(ModalType.REMOVE_BLOCK)}>
                    ブロック解除
                  </MainButton>
                ) : (
                  <MainButton
                    icon="doNotDisturb"
                    variant="warn"
                    iconColor="#FFFFFF"
                    thin
                    onClick={() => setActiveModalType(ModalType.ADD_BLOCK)}
                  >
                    ブロック
                  </MainButton>
                )}
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="column" flex="1">
              <ScrollWrapper bottom={272}>
                <Info items={infoItems} />
              </ScrollWrapper>
            </Box>
          </Box>
        ): (
          /* feat_screen_04-01-01-02_start */
          <Box width="calc(100vw - 32px)">
            <Box px={16} pb={16} display="flex" flex="1" flexDirection="column" height={`calc(100svh - ${blogFlag ? '413px' : '381px'})`} overflow="auto" width="100%">
              <Box display="flex" flexDirection="column" py={16} gap={16}>
                <Box display="flex" justifyContent="center">
                  <Text variant="h2" color="darkBlue">
                    基本情報
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={12} justifyContent="center">
                  <MainButton
                    icon="campaign"
                    variant="warnSecondary"
                    iconColor="#E50000"
                    iconSize={20}
                    thin
                    onClick={() => setActiveModalType(ModalType.ADD_REPORT)}
                    width={124}
                    customHeight={32}
                  >
                    通報
                  </MainButton>
                  {info.block_flag === BtmemBlockFlag.TRUE ? (
                    <MainButton variant="warnSecondary" onClick={() => setActiveModalType(ModalType.REMOVE_BLOCK)} width={124} customHeight={32}>
                      ブロック解除
                    </MainButton>
                  ) : (
                    <MainButton
                      icon="doNotDisturb"
                      variant="warn"
                      iconColor="#FFFFFF"
                      iconSize={20}
                      thin
                      onClick={() => setActiveModalType(ModalType.ADD_BLOCK)}
                      width={124}
                      customHeight={32}
                    >
                      ブロック
                    </MainButton>
                  )}
                </Box>
              </Box>
              <Divider option="horizontal"/>
              <Box display="flex" flexDirection="column" flex="1" mt={-3}>
                <Info items={infoItems} />
              </Box>
            </Box>
          </Box>
          /* feat_screen_04-01-01-02_end */
        )
      }
      <RemoveBlockModal
        isOpen={activeModalType === ModalType.REMOVE_BLOCK}
        onClose={() => setActiveModalType(ModalType.NONE)}
        refetch={() => {
          refetchBaseInfo();
          refetchHeaderData();
        }}
      />
      <AddBlockModal
        isOpen={activeModalType === ModalType.ADD_BLOCK}
        onClose={() => setActiveModalType(ModalType.NONE)}
        refetch={() => {
          refetchBaseInfo();
          refetchHeaderData();
        }}
        name={base.name ?? ''}
        reasonOptions={reasonOptions ?? []}
      />
      <AddReportModal
        baitravelerId={base.id ?? ''}
        isOpen={activeModalType === ModalType.ADD_REPORT}
        onClose={() => setActiveModalType(ModalType.NONE)}
        refetch={refetchBaseInfo}
      />
    </>
  );
}
