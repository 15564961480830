import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { format as formatDate } from 'date-fns';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-05-04
 * 退会完了モーダル
 */

function CompleteModal(props: { isOpen: boolean; onModalClose: () => void }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { isOpen, onModalClose } = props;

  const operationDate = new Date();

  // 退会予定日は、手続き日の同月末
  // 月は0から始まるので、手続き日の月に1を足す
  // 0日目を指定すると、その月の末日を取得できる

  // 手続き日に基づいて退会日を計算
  // 1～25日：当月末日
  // 26～末日：翌月末日
  let cancelDate;
  const day = operationDate.getDate();

  if (day <= 25) {
    // 当月末日
    cancelDate = new Date(operationDate.getFullYear(), operationDate.getMonth() + 1, 0);
  } else {
    // 翌月末日
    cancelDate = new Date(operationDate.getFullYear(), operationDate.getMonth() + 2, 0);
  }

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        退会手続き 完了
      </Text>
    ),
    // feat screen 09-05-04 start
    content: !isMobile  ? (
      <Box>
        <Box mb={16}>
          <Text variant="body14" color="black">
            退会手続きが完了しました。
          </Text>
        </Box>
        <Box my={16}>
          <Text variant="body14" color="blueGray" bold>
            手続き日
          </Text>
        </Box>
        <Box my={16} mx={8}>
          <li>
            <Text variant="body14">{formatDate(operationDate, 'yyyy年MM月dd日')}</Text>
          </li>
        </Box>
        <Box my={16}>
          <Text variant="body14" color="blueGray" bold>
            退会予定日
          </Text>
        </Box>
        <Box my={16} mx={8}>
          <li>
            <Text variant="body14">{formatDate(cancelDate, 'yyyy年MM月dd日')}</Text>
          </li>
        </Box>
      </Box>
    ): (
      // Responsive Component
      <Box>
        <Box pb={16}>
          <Text variant="body14" color="black">
            退会手続きが完了しました。
          </Text>
        </Box>
        <Box pb={13}>
          <Text display="block" variant="body14" color="blueGray" bold>
            手続き日
          </Text>
        </Box>
        <Box pb={13}>
          <li>
            <Text variant="body14">{formatDate(operationDate, 'yyyy年MM月dd日')}</Text>
          </li>
        </Box>
        <Box pb={13}>
          <Text display="block" variant="body14" color="blueGray" bold>
            退会予定日
          </Text>
        </Box>
        <Box>
          <li>
            <Text variant="body14">{formatDate(cancelDate, 'yyyy年MM月dd日')}</Text>
          </li>
        </Box>
      </Box>
    // feat screen 09-05-04 end
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {/* feat screen 09-05-05 start */}
        <MainButton width={!isMobile ? 104 : 96} variant="secondary" thin={!isMobile} onClick={() => onModalClose()}>
          閉じる
        </MainButton>
        {/* feat screen 09-05-05 end */}
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={modalContent?.header}
      content={modalContent?.content}
      footer={modalContent?.footer}
      height="auto"
      width={384}
    />
  );
}

export default CompleteModal;
