import { Box } from 'components/box';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import React, { useEffect, useMemo } from 'react';
import * as gql from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { Info } from 'components/info';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { StatusName } from 'pages/s06/const/const';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID: 06-02-01
 * 名称: 基本情報
 */

interface Props {
  userId: string;
}

export function BasicInfo({ userId }: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const auth = useAuth();
  const { tpmemId } = auth;
  const [update, { data, loading, error: getupdVtRegBelongInfoByTdBIdError }] =
    gql.useGetupdVtRegBelongInfoByTdBIdMutation({
      variables: { tpmem_id: tpmemId || 0, btmem_id: Number(userId) },
      fetchPolicy: 'no-cache',
    });

  const { openErrorModal } = useErrorModal();
  useEffect(() => {
    if (getupdVtRegBelongInfoByTdBIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getupdVtRegBelongInfoByTdBIdError, openErrorModal]);

  const items = useMemo(() => {
    const bizName = data?.getupdVTRegBelongInfoByTdBId.biz_name || '';
    switch (data?.getupdVTRegBelongInfoByTdBId.status) {
      case StatusName.APPROVED:
        return [
          {
            content: data?.getupdVTRegBelongInfoByTdBId.name,
            label: '氏名',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.kana,
            label: '氏名(フリガナ)',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.btmem_number,
            label: '会員番号',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.phone,
            label: '電話番号',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.age,
            label: '年齢',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.rank_name,
            label: 'ランク',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.score,
            label: 'スコア',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.state_count,
            label: '達成都道府県',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.worked_count,
            label: 'バイト回数',
          },
          {
            content: `${data?.getupdVTRegBelongInfoByTdBId.state || ''}${
              data?.getupdVTRegBelongInfoByTdBId.city || ''
            }${data?.getupdVTRegBelongInfoByTdBId.address || ''}${data?.getupdVTRegBelongInfoByTdBId.address2 || ''}`,
            label: '住所',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.belong_flag,
            label: `所属店舗(${bizName})`,
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.sub_flag,
            label: `サブ店舗(${bizName || ''})`,
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.gender,
            label: '性別',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.general_licenses,
            label: '保有資格',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.biz_licenses,
            label: `${bizName || ''}資格`,
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.uniform_size,
            label: '制服サイズ',
          },
          {
            content: `${data?.getupdVTRegBelongInfoByTdBId.late_count || ''}回 / ${
              data?.getupdVTRegBelongInfoByTdBId.late_per || ''
            }%`,
            label: '遅刻率',
          },
          {
            content: `${data?.getupdVTRegBelongInfoByTdBId.cancel_count || ''}回 / ${
              data?.getupdVTRegBelongInfoByTdBId.cancel_per || ''
            }%`,
            label: 'キャンセル率',
          },
          {
            content: `${data?.getupdVTRegBelongInfoByTdBId.imminent_count || ''}回 / ${
              data?.getupdVTRegBelongInfoByTdBId.imminent_per || ''
            }%`,
            label: 'ドタキャン率',
          },
          {
            content: identificationLabel(data?.getupdVTRegBelongInfoByTdBId.identification || 0, true),
            label: '本人確認',
          },
        ];
      default:
        return [
          {
            content: data?.getupdVTRegBelongInfoByTdBId.name,
            label: '氏名',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.age,
            label: '年齢',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.rank_name,
            label: 'ランク',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.state,
            label: '住所',
          },
          {
            content: data?.getupdVTRegBelongInfoByTdBId.gender,
            label: '性別',
          },
        ];
    }
  }, [data]);

  useEffect(() => {
    update();
  }, [update]);

  if (loading) {
    Loading();
  }

  return !isMobile ? (
    <Box px={16} pt={24} pb={56} width="100%">
      <Box mb={18}>
        <Text variant="h2" color="darkBlue">
          基本情報
        </Text>
      </Box>
      <Box mb={8}>
        <Divider option="horizontal" />
      </Box>
      <ScrollableArea>
        <Info items={items} />
        <Divider option="horizontal" />
      </ScrollableArea>
    </Box>
  ) : (
    // feat screen 06-02-01 start
    <Box px={16} pt={15} pb={56} width="100%">
      <Box mb={16} display="flex" justifyContent="center">
        <Text variant="h2" color="darkBlue">
          基本情報
        </Text>
      </Box>
      <Box>
        <Divider option="horizontal" />
      </Box>
      <ScrollableArea>
        <Info items={items} />
        <Divider option="horizontal" />
      </ScrollableArea>
    </Box>
  );
  // feat screen 06-02-01 end
}
