import React, { useContext } from 'react';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider/divider';
import { Text } from 'components/text';
import { QrCode, QrCodeType } from 'qrCodeComponent/qrCode';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { CheckInInfoContext } from '../common';

function CheckOut() {
  const { brandId, tpmemId } = useContext(CheckInInfoContext);
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  return !isMobile ? (
    <Box pa={18}>
      <Box display="flex" alignItems="center" gap={8} pb={16}>
        <Text variant="h2" color="darkBlue">
          二次元バーコード/顔認証
        </Text>
        <Text variant="caption12" color="darkBlue">
          チェックアウト時に使用する二次元バーコードです。
        </Text>
      </Box>
      <Divider option="horizontal" />

      <Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={32}>
          <QrCode type={QrCodeType.CHECKOUT} brandId={brandId || 0} tpmemId={tpmemId} size={190} />
          <Text variant="caption12" color="darkBlue">
            チェックアウト用二次元バーコード
          </Text>
        </Box>
      </Box>
    </Box>
  ): (
    /* feat_screen_04-02-01-09_start */
    <Box display="flex" flexDirection="column">
      <Box pt={10}>
        <Divider option="horizontal" />
      </Box>
      <Box display="flex" flexDirection="column" gap={16} pt={16}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Text variant="h2" color="darkBlue">
            二次元バーコード/顔認証
          </Text>
          <Text variant="caption12" color="darkBlue">
            チェックアウト時に使用する二次元バーコードです。
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" alignItems="center" gap={16}>
          <QrCode type={QrCodeType.CHECKOUT} brandId={brandId || 0} tpmemId={tpmemId} size={124} />
          <Text variant="caption12" color="darkBlue">
            チェックアウト用二次元バーコード
          </Text>
        </Box>
      </Box>
    </Box>
    /* feat_screen_04-02-01-09_end */
  );
}

export default CheckOut;
