/**
 * Figma ID: 01-01-07
 * 名称: 管理企業 / 雇用主
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { ContentTitle } from './component/ContentTitle';

type Props = {
  image: File;
  code: string;
  bizName: string;
  brandName: string;
  tpmemName: string;
  address: string;
  phone: string;
  openTime: string;
  closeTime: string;
  hasParking: boolean;
  managerFamilyName: string;
  managerFirstName: string;
  managerBirthday: Date;
  managerPhone: string;
  isCorporation: boolean;
  mcoName: string;
  mcoZipCode: string;
  mcoPrefecture: string;
  mcoCity: string;
  mcoAddress1: string;
  mcoAddress2: string;
  corporateNumber: string;
  mcoPhone: string;
  mcoFamilyName: string;
  mcoFirstName: string;
  mcoFamilyNameKana: string;
  mcoFirstNameKana: string;
  mcoManagerFamilyName: string;
  mcoManagerFirstName: string;
  mcoManagerFamilyNameKana: string;
  mcoManagerFirstNameKana: string;
  mcoManagerPhone: string;
  inviteCode: string;
  bankName: string;
  branchName: string;
  bankType: string;
  accountNumber: string;
  isPersonalBank: boolean;
  accountHolder: string;
  socialInsurance: string;
  passiveSmokingMeasure: string;
  isWorkingSmokingArea: boolean;
  toNext: () => void;
  toBack: () => void;
};

export function ConfirmSubmitContent({
  image,
  code,
  bizName,
  brandName,
  tpmemName,
  address,
  phone,
  openTime,
  closeTime,
  hasParking,
  managerFamilyName,
  managerFirstName,
  managerBirthday,
  managerPhone,
  isCorporation,
  mcoName,
  mcoZipCode,
  mcoPrefecture,
  mcoCity,
  mcoAddress1,
  mcoAddress2,
  corporateNumber,
  mcoPhone,
  mcoFamilyName,
  mcoFirstName,
  mcoFamilyNameKana,
  mcoFirstNameKana,
  mcoManagerFamilyName,
  mcoManagerFirstName,
  mcoManagerFamilyNameKana,
  mcoManagerFirstNameKana,
  mcoManagerPhone,
  inviteCode,
  bankName,
  branchName,
  bankType,
  accountNumber,
  isPersonalBank,
  accountHolder,
  socialInsurance,
  passiveSmokingMeasure,
  isWorkingSmokingArea,
  toNext,
  toBack,
}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_start */
  const [tpmemImage, setTpmemImage] = React.useState<string>();

  const section1 = [
    { label: 'ID', value: code },
    { label: '業種', value: bizName },
    { label: 'ブランド', value: brandName },
    { label: '店舗名', value: tpmemName },
    {
      label: '住所',
      value: address,
    },
    { label: '電話番号', value: phone },
    {
      label: '営業時間',
      value: `${openTime} 〜 ${closeTime}`,
    },
    { label: '従業員駐車場', value: hasParking ? '有' : '無' },
    { label: '責任者氏名', value: `${managerFamilyName} ${managerFirstName}` },
    {
      label: '責任者生年月日',
      value: `${String(managerBirthday?.getFullYear())}年${String(managerBirthday?.getMonth() ?? 0 + 1)}月${String(
        managerBirthday?.getDate()
      )}日`,
    },
    { label: '責任者電話番号', value: managerPhone },
  ];
  const section2 = [
    { label: '友達招待コード', value: inviteCode },
    {
      label: '銀行口座',
      // feat_screen_01-01-07_start
      value: !isMobile ?(
        <Box gap={5} display="flex">
          <Text variant="body14">
            {`${bankName} ${branchName} ${bankType} 
            ${accountNumber}`}
          </Text>
          <Text variant="body14" color="blueGray">
            {`${isPersonalBank ? '個人' : '法人'}`}
          </Text>
        </Box>
      ) : (
        // Responsive Component
        <Box gap={5} display="inline-block">
          <div style={{textAlign: "right"}}>
            <Text variant="body14" align='right'>
              {`${bankName} ${branchName} ${bankType} 
              ${accountNumber}`}
            </Text>
            <Text variant="body14" color="blueGray">
              {`${isPersonalBank ? '個人' : '法人'}`}
            </Text>
          </div>
        </Box>
        // feat_screen_01-01-07_end
      ),
    },
    { label: '口座名義', value: accountHolder },
    { label: '社会保険等', value: socialInsurance },
    { label: '受動喫煙防止措置', value: passiveSmokingMeasure },
    { label: '喫煙可能エリアでの作業', value: isWorkingSmokingArea ? '有' : '無' },
  ];

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result) {
        setTpmemImage(result as string);
      }
    };
    reader.readAsDataURL(image);
  }, [image]);

  // feat screen 01-01-07 start
  return !isMobile ? (
    <Box width={752} display="flex" flexDirection="column" gap={24}>
      <ContentTitle title="新規登録 - 確認" />

      <Box>
        <Text variant="body14">下記内容で登録します。よろしければ次へを押してください。</Text>
      </Box>

      <Box>
        <Box>
          <Divider option="horizontal" />
        </Box>
        <Box py={11}>
          <Text variant="body14" color="darkGray">
            店舗画像
          </Text>
          <Box pt={8}>
            <img src={tpmemImage} width="160" height="120" alt="tpmemImage" />
          </Box>
          <Box py={11}>
            <Divider option="horizontal" />
          </Box>
        </Box>
        {section1.map((item, index) => (
          <Box key={Number(index)}>
            <Box display="flex" alignItems="center">
              <Box width={160}>
                <Text variant="body14" color="darkGray">
                  {item.label}
                </Text>
              </Box>
              <Text variant="body14">{item.value}</Text>
            </Box>
            <Box py={11}>
              <Divider option="horizontal" />
            </Box>
          </Box>
        ))}

        <Box display="flex" alignItems="center">
          <Box width={160}>
            <Text variant="body14" color="darkGray">
              事業者
            </Text>
          </Box>
          {isCorporation ? (
            <Box>
              <Box>
                <Box pt={5}>
                  <Text variant="body14">{mcoName}</Text>
                </Box>
                <Box pt={8}>
                  <Text variant="body14">{`〒${mcoZipCode} ${mcoPrefecture}${mcoCity}${mcoAddress1}${mcoAddress2}`}</Text>
                </Box>
                <Box display="flex" alignItems="center" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      法人番号
                    </Text>
                  </Box>
                  <Text variant="body14">{corporateNumber}</Text>
                </Box>
                <Box display="flex" alignItems="center" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{mcoPhone}</Text>
                </Box>
                <Box display="flex" alignItems="start" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      代表者
                    </Text>
                  </Box>
                  <Box>
                    <Box>
                      <Text variant="body14">{`${mcoFamilyName} ${mcoFirstName}`}</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{`${mcoFamilyNameKana} ${mcoFirstNameKana}`}</Text>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      担当者
                    </Text>
                  </Box>
                  <Box>
                    <Box>
                      <Text variant="body14">{`${mcoManagerFamilyName} ${mcoManagerFirstName}`}</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{`${mcoManagerFamilyNameKana} ${mcoManagerFirstNameKana}`}</Text>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      担当者電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{mcoManagerPhone}</Text>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Box pt={5}>
                  <Text variant="body14">{mcoName}</Text>
                </Box>
                <Box pt={8}>
                  <Text variant="body14">{`〒${mcoZipCode} ${mcoPrefecture}${mcoCity}${mcoAddress1}${mcoAddress2}`}</Text>
                </Box>
                <Box display="flex" alignItems="center" pt={8}>
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{phone}</Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box py={11}>
          <Divider option="horizontal" />
        </Box>
        {section2.map((item, index) => (
          <Box key={Number(index)}>
            <Box display="flex" alignItems="center">
              <Box width={160} pb={2}>
                <Text variant="body14" color="darkGray">
                  {item.label}
                </Text>
              </Box>
              {typeof item.value === 'string' ? <Text variant="body14">{item.value}</Text> : item.value}
            </Box>
            <Box py={11}>
              <Divider option="horizontal" />
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" gap={5} justifyContent="center">
        <MainButton variant="secondary" onClick={() => toBack()}>
          戻る
        </MainButton>
        <MainButton
          onClick={() => {
            toNext();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  ): (
    // Responsive Component
    <Box width="100vw" display="flex" flexDirection="column" px={16}>
      <ContentTitle title="新規登録 - 確認" />

      <Box my={18} mb={14}>
        <Text display="block" variant="body14">下記内容で登録します。よろしければ次へを押してください。</Text>
      </Box>

      <Box>
        <Box>
          <Divider option="horizontal" />
        </Box>
        <Box mt={24}>
          <Text variant="body14" color="darkGray">
            店舗画像
          </Text>
          <Box mb={-4}>
            <img src={tpmemImage} width="160" height="120" alt="tpmemImage" />
          </Box>
          <Box py={16} pb={18}>
            <Divider option="horizontal" />
          </Box>
        </Box>
        {section1.map((item, index) => (
          <Box key={Number(index)}>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
              <Box width={160} minWidth={160}>
                <Text display="block" variant="body14" color="darkGray">
                  {item.label}
                </Text>
              </Box>
              <Box display="block">
                <Text display="block" align='right' variant="body14">{item.value}</Text>
              </Box>
            </Box>
            <Box py={15.5}>
              <Divider option="horizontal" />
            </Box>
          </Box>
        ))}

        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box width={75} minWidth={75} height="100%">
            <Text variant="body14" color="darkGray">
              事業者
            </Text>
          </Box>
          {!isCorporation ? (
            <Box flex='1'>
              <Box>
                <Box width={112} minWidth={112} display='flex' flexWrap='wrap'>
                  <Text variant="body14">{mcoName}</Text>
                </Box>
                <Box>
                  <Text variant="body14">{`〒${mcoZipCode} ${mcoPrefecture}${mcoCity}${mcoAddress1}${mcoAddress2}`}</Text>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={112} minWidth={112}>
                    <Text variant="body14" color="darkGray">
                      法人番号
                    </Text>
                  </Box>
                  <Text variant="body14">{corporateNumber}</Text>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={112} minWidth={112}>
                    <Text variant="body14" color="darkGray">
                      電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{mcoPhone}</Text>
                </Box>
                <Box display="flex" alignItems="start" justifyContent="space-between">
                  <Box width={112} minWidth={112}>
                    <Text variant="body14" color="darkGray">
                      代表者
                    </Text>
                  </Box>
                  <Box>
                    <Box>
                      <Text variant="body14">{`${mcoFamilyName} ${mcoFirstName}`}</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{`${mcoFamilyNameKana} ${mcoFirstNameKana}`}</Text>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="start" justifyContent="space-between">
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      担当者
                    </Text>
                  </Box>
                  <Box>
                    <Box>
                      <Text variant="body14">{`${mcoManagerFamilyName} ${mcoManagerFirstName}`}</Text>
                    </Box>
                    <Box>
                      <Text variant="body14">{`${mcoManagerFamilyNameKana} ${mcoManagerFirstNameKana}`}</Text>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      担当者電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{mcoManagerPhone}</Text>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box flex='1'>
              <Box>
                <Box>
                  <Text variant="body14">{mcoName}</Text>
                </Box>
                <Box>
                  <Text variant="body14">{`〒${mcoZipCode} ${mcoPrefecture}${mcoCity}${mcoAddress1}${mcoAddress2}`}</Text>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={112}>
                    <Text variant="body14" color="darkGray">
                      電話番号
                    </Text>
                  </Box>
                  <Text variant="body14">{phone}</Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box py={16}>
          <Divider option="horizontal" />
        </Box>
        {section2.map((item, index) => (
          <Box key={Number(index)}>
            <Box display="flex" alignItems="center" justifyContent='space-between'>
              <Box width={160} minWidth={160} pb={2}>
                <Text variant="body14" color="darkGray">
                  {item.label}
                </Text>
              </Box>
              {typeof item.value === 'string' ? <Text display='block' variant="body14"  align='right'>{item.value}</Text> : item.value}
            </Box>
            <Box py={16}>
              <Divider option="horizontal" />
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" py={24} justifyContent="space-between" gap={5}>
        <MainButton fullWidth customHeight={40} variant="secondary" onClick={() => toBack()}>
          戻る
        </MainButton>
        <MainButton
          fullWidth
          customHeight={40}
          onClick={() => {
            toNext();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  );
  // feat screen 01-01-07 end
}
