/**
 * Figma ID: 08-01-01
 * 名称: ランク別育成料
 */

import React from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
// import { ListRow } from 'components/list';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { HideWrapper } from 'components/assets/css/pages/pageStyle';
import { ScrollableArea } from 'components/scrollableArea';
import { Modal } from 'components/newModal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { RequiringCallbackError } from 'components/error/RequiringCallbackError';
import { handleMutationResult } from 'error';
import { useForm } from 'react-hook-form';
import { useAuth } from 'hooks/authProvider';
import { Loading } from 'components/loading/loading';
import { useGetVtLendStaffListByIdQuery, useUpdateTLendStaffByIdBtRIdMutation } from 'graphql/graphql-mw';
// feat screen 08-01-01 import start
import useIsMobile from 'hooks/responsive/useIsMobile';
import { MobileListRow } from '../../component/mobileComponent/listRow';
// feat screen 08-01-01 import end
import { CompleteValue, ConfirmValue, SetValueContent } from './ModalContent';
import { SetValueFooter } from './ModalFooter';

export type UpdateFeePhase = 'update' | 'confirm' | 'complete';

const ListWrapper = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

const LeftWrapper = styled.div`
  flex: 1;
`;
const CenterWrapper = styled.div`
  flex: 1;
  padding: 0 20px;
  box-sizing: border-box;
`;
const RightWrapper = styled.div`
  flex: 2;
  padding: 0 20px;
  box-sizing: border-box;
`;
const RightWrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export function EducationFeeByRank() {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { tpmemId } = useAuth();
  const { openErrorModal } = useErrorModal();

  const {
    data,
    loading,
    error: vTlendStaffListByIdError,
    refetch,
  } = useGetVtLendStaffListByIdQuery({
    variables: { id: Number(tpmemId) },
  });
  React.useEffect(() => {
    if (vTlendStaffListByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTlendStaffListByIdError, openErrorModal]);

  const [apiError, setApiError] = React.useState<RequiringCallbackError>();

  const [updateTLendStaff] = useUpdateTLendStaffByIdBtRIdMutation({
    onCompleted: (result) => {
      const r = handleMutationResult(result.updateTLendStaffByIdBtRId);
      if (r.isSuccessful) {
        setApiError(undefined);
      } else {
        setApiError(r.error);
      }
    },
  });

  const [isOpenSelectChangeModal, setIsOpenSelectChangeModal] = React.useState(false);
  const [selectEducationFee, setSelectEducationFee] = React.useState<{
    id: number;
    name: string;
    maxFee: string | number;
    minFee: string | number;
  }>();
  const [phase, setPhase] = React.useState<UpdateFeePhase>();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<{ input: string }>({ reValidateMode: 'onChange' });
  const inputWatch = watch('input');

  React.useEffect(() => {
    if (apiError) {
      setIsOpenSelectChangeModal(false);
      openErrorModal({
        title: apiError.title,
        message: apiError.message,
        onClose: apiError.requiredCallback,
      });
    }
  }, [apiError, openErrorModal]);

  React.useEffect(() => {
    if (inputWatch) {
      handleSubmit((val) => console.log(val))();
    }
  }, [handleSubmit, inputWatch]);

  const updateModalContent = () => {
    switch (phase) {
      case 'confirm':
        return <ConfirmValue updatePer={inputWatch} />;
      case 'complete':
        return <CompleteValue />;
      default:
        return (
          <SetValueContent
            educationFeeByRankId={selectEducationFee?.id}
            educationFeeByRankName={selectEducationFee?.name}
            educationFeeMax={selectEducationFee?.maxFee}
            educationFeeMin={selectEducationFee?.minFee}
            control={control}
            errors={errors}
          />
        );
    }
  };
  const toNextPhase = () => {
    handleSubmit(() => {
      switch (phase) {
        case 'confirm':
          updateTLendStaff({
            variables: {
              id: Number(tpmemId),
              bt_rank_id: Number(selectEducationFee?.id),
              educ_fee: Number(inputWatch),
            },
          })
            .then(() => {
              refetch();
              setPhase('complete');
            })
            .catch((e) => {
              setSelectEducationFee(undefined);
              setIsOpenSelectChangeModal(false);
              setPhase(undefined);
              reset();
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            });
          break;
        default:
          setPhase('confirm');
          break;
      }
    })();
  };
  React.useEffect(() => {
    if (selectEducationFee) {
      setIsOpenSelectChangeModal(true);
    } else {
      setIsOpenSelectChangeModal(false);
      setPhase(undefined);
      reset({ input: '' });
    }
  }, [inputWatch, reset, selectEducationFee]);
  // feat screen 08-01-01 start
  return !isMobile ? (
    <HideWrapper>
      {loading && <Loading />}
      <Box display="flex" alignItems="center" gap={8} py={18} pl={24}>
        <Text variant="h2" color="darkBlue">
          ランク別育成料
        </Text>
        <Text variant="caption12" color="darkBlue">
          各ランクに応じた育成料料率を設定します。
        </Text>
      </Box>
      <Box px={24}>
        <Divider option="horizontal" />
      </Box>
      <ScrollableArea>
        <Box display="block" alignItems="center" px={24} pb={100}>
          {data?.getVTLendStaffListById?.map((val) => (
            // feat add key start
            <ListWrapper key={val.bt_rank_id}>
              {/* // feat add key end */}
              <LeftWrapper>
                <Text variant="caption12" bold color="darkBlue">
                  {val?.rank_name}
                </Text>
              </LeftWrapper>
              <Divider option="vertical" length={18} />
              <CenterWrapper>
                <Text variant="caption12" color="darkBlue">
                  設定可能範囲: {val?.educ_fee_min}% 〜 {val?.educ_fee_max}%
                </Text>
              </CenterWrapper>
              <Divider option="vertical" length={18} />
              <RightWrapper>
                <Text variant="caption12" bold color="blueGray">
                  現在の料率: {val?.educ_fee_disp}
                </Text>
              </RightWrapper>
              <RightWrapperButton>
                <MainButton
                  variant="secondary"
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                  onClick={() =>
                    setSelectEducationFee({
                      id: val?.bt_rank_id ?? 0,
                      name: val?.rank_name ?? '',
                      maxFee: val?.educ_fee_max ?? '0',
                      minFee: val?.educ_fee_min ?? '0',
                    })
                  }
                  thin
                >
                  変更
                </MainButton>
              </RightWrapperButton>
            </ListWrapper>
          ))}
          <Box py={8}>
            <Text variant="caption12" color="darkBlue">
              ※ 育成料は所属バイトラベラーのページから個別に設定を変更することも可能です。
            </Text>
          </Box>
        </Box>
      </ScrollableArea>
      <Modal
        isOpen={isOpenSelectChangeModal}
        header={
          <Text variant="h2" color="darkBlue">
            ランク別育成料 {phase === 'complete' ? '完了' : ''}
          </Text>
        }
        footer={
          <SetValueFooter
            phase={phase}
            toNextPhase={toNextPhase}
            toBackPhase={() => setPhase('update')}
            close={() => setSelectEducationFee(undefined)}
          />
        }
        content={updateModalContent()}
        onClose={() => setIsOpenSelectChangeModal(false)}
        height={phase === 'complete' ? 189 : 275}
      />
    </HideWrapper>
  ) : (
    // Responsive Component
    <Box width="100%" height="calc(100% - 49px)" overflow="auto">
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" alignItems="center" pt={17} pb={12}>
        <Text variant="h2" color="darkBlue">
          ランク別育成料
        </Text>
        <Text display="block" variant="caption12" color="darkBlue">
          各ランクに応じた育成料料率を設定します。
        </Text>
      </Box>
      <Box px={16}>
        <Divider option="horizontal" />
      </Box>
      <Box>
        <Box display="block" alignItems="center" px={16}>
          {data?.getVTLendStaffListById?.map((val) => (
            <MobileListRow
              rowWidth="100%"
              key={val?.bt_rank_id}
              rowItem={[
                {
                  content: (
                    <Text variant="caption12" bold color="darkBlue">
                      {val?.rank_name}
                    </Text>
                  ),
                  width: '25%',
                  key: val?.rank_name ?? '',
                },
                {
                  content: (
                    <Text variant="caption12" color="blueGray">
                      設定可能範囲: {val?.educ_fee_min}% 〜 {val?.educ_fee_max}%
                    </Text>
                  ),
                  width: '25%',
                  key: val?.rank_name ?? '',
                  hasDivider: true,
                },
                {
                  content: (
                    <Text variant="caption12" color="blueGray" bold>
                      現在の料率: {val?.educ_fee_disp}
                    </Text>
                  ),
                  width: '25%',
                  key: val?.rank_name ?? '',
                  hasDivider: true,
                },
                {
                  content: (
                    <MainButton
                      variant="secondary"
                      fullWidth
                      icon="edit"
                      iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                      onClick={() =>
                        setSelectEducationFee({
                          id: val?.bt_rank_id ?? 0,
                          name: val?.rank_name ?? '',
                          maxFee: val?.educ_fee_max ?? '0',
                          minFee: val?.educ_fee_min ?? '0',
                        })
                      }
                    >
                      変更
                    </MainButton>
                  ),
                  width: '25%',
                  key: val?.rank_name ?? '',
                },
              ]}
            />
          ))}
          <Box pt={13} pb={10}>
            <Text display="block" variant="caption12" color="darkBlue">
              ※ 育成料は所属バイトラベラーのページから個別に設定を変更することも可能です。
            </Text>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={isOpenSelectChangeModal}
        header={
          <Text variant="h2" color="darkBlue">
            ランク別育成料 {phase === 'complete' ? '完了' : ''}
          </Text>
        }
        footer={
          <SetValueFooter
            phase={phase}
            toNextPhase={toNextPhase}
            toBackPhase={() => setPhase('update')}
            close={() => setSelectEducationFee(undefined)}
          />
        }
        content={updateModalContent()}
        onClose={() => setIsOpenSelectChangeModal(false)}
        height="unset"
        width="100%"
      />
    </Box>
  // feat screen 08-01-01 end
  );
}
