import { createContext, Dispatch } from 'react';

export enum ScheduleType {
  DAILY = 1,
  WEEKLY = 2,
}

export enum ScheduleTypeLabel {
  DAILY = '単日',
  WEEKLY = '週定期',
}

export type State = {
  scheduleType: number;
};

export type Action = {
  type: 'SET_SCHEDULE_TYPE';
  content: number;
};

type ScheduleTypeContextType = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const ScheduleTypeContext = createContext<ScheduleTypeContextType | undefined>(undefined);
