import { ContentsCard, PageBody } from 'components/assets/css/pages/pageStyle';
import React, { useEffect, useState } from 'react';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import { Avatar } from 'components/avatar';
import { Box } from 'components/box';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { useLocation } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { MemberStoreStatus, TransferProcessStatus } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useGetupdVtMemberBaseByIdMutation, useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery } from 'graphql/graphql-mw';
import { useAuth } from 'hooks/authProvider';
import { useMemberStoreStatus } from 'hooks/memberStoreStatusProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { styled } from '@linaria/react';
import { MainContentTitle } from 'components/pageLayout/mainContent';
import BasicInfo from './components/BasicInfo';
import BankAccount from './components/BankAccount';
import AccountManagement from './components/AccountManagement';
import GroupStores from './components/GroupStores';
import CancelMembership from './components/CancelMembership';
import Barcode from './components/barcode';
import PaymentCompanyInfo from './components/PaymentCompanyInfo';

type MyPageState = {
  menuIndex: number;
};

/**
 * Figma ID: 09-01-01
 * 名称: マイページ
 */

/* feat_screen_03_01_style_layout_start */
const StyleHeader = styled.div`
  height: 50px;
  width: 100%;
  background: #F8FEFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
`;

const StyleHeaderText = styled.div`
  padding-left: 16px;
`;
/* feat_screen_03_01_style_layout_end */

function S09() {
  // マイページのサブメニューの初期値を取得
  const location = useLocation();
  const memberStoreStatus = useMemberStoreStatus();
  const state = location?.state as MyPageState;
  const menuIndex = state?.menuIndex;
  const auth = useAuth();
  const myId = auth.tpmemId ?? 0;
  const { openErrorModal } = useErrorModal();
  const [readBasicData, { data: basicData, loading: baseDataLoading, error: baseDataError }] =
    useGetupdVtMemberBaseByIdMutation({
      variables: {
        id: myId,
      },
    });
  const [transferProcessStatus, setTransferProcessStatus] = useState<number>(0);

  const [getVtNewlyMenuHome] = useGetVtNewlyMenuHomeArbeitByTpmemIdLazyQuery({
    pollInterval: Number(process.env.REACT_APP_MENU_POLLING_INTERVAL),
  });

  useEffect(() => {
    readBasicData();
  }, [readBasicData]);
  React.useEffect(() => {
    if (baseDataError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [baseDataError, openErrorModal]);

  useEffect(() => {
    if (auth.tpmemId === null) return;
    getVtNewlyMenuHome({ variables: { tpmem_id: auth.tpmemId } }).then((result) => {
      const menuData = result.data?.getVTNewlyMenuHomeArbeitByTpmemId.menu;
      const transferProcessStatusData = menuData?.mfk_status;

      if (typeof transferProcessStatusData === 'number') {
        setTransferProcessStatus(transferProcessStatusData);
      }
    });
  }, [auth, getVtNewlyMenuHome]);

  const bizId = basicData?.getupdVTMemberBaseById.biz_id ?? 0;

  const refetch = () => {
    readBasicData();
  };


  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  /* feat_screen_05-02-01-02_start */
  const [tab, setTab] = React.useState<number>(menuIndex || 0);
  /* feat_screen_05-02-01-02_end */

  const avatarOverlap =
    memberStoreStatus === MemberStoreStatus.PAUSE
      ? {
          color: GENERIC_COLOR.CAUTION_RED,
          content: (
            <Text variant="body14" color="white" bold>
              休止
            </Text>
          ),
        }
      : undefined;

  const headerBackGroundColor = memberStoreStatus === MemberStoreStatus.PAUSE ? 'bgRed' : undefined;

  const subMenuItems = [
    {
      content: baseDataLoading ? <Loading /> : <BasicInfo basicData={basicData} refetchBasicInfo={refetch} />,
      label: '基本情報',
    },
    {
      content: baseDataLoading ? (
        <Loading />
      ) : (
        <BankAccount
          bankData={basicData?.getupdVTMemberBaseById}
          refetch={() => {
            refetch();
          }}
        />
      ),
      label: '銀行口座',
    },
    {
      content: (
        <AccountManagement
          tpmemId={myId}
          email={basicData?.getupdVTMemberBaseById?.email || ''}
          refetch={() => {
            refetch();
          }}
        />
      ),
      label: 'アカウント管理',
    },
    {
      content: <GroupStores bizId={bizId} />,
      label: 'グループ店舗',
      isDisable: transferProcessStatus === TransferProcessStatus.UNAPPLIED,
    },
    {
      content: <CancelMembership />,
      label: '退会',
      isDisable: transferProcessStatus === TransferProcessStatus.UNAPPLIED,
    },
  ];

  if (memberStoreStatus !== MemberStoreStatus.PAUSE) {
    subMenuItems.push({
      content: <Barcode />,
      label: '二次元バーコード',
    });
  }
  subMenuItems.push({
    content: <PaymentCompanyInfo />,
    label: '決済会社情報',
  });

  return !isMobile ? (
    <Box height="100%">
      <Box display="flex" alignItems="center" mb={24} backgroundColor={headerBackGroundColor}>
        <Avatar
          width={80}
          height={60}
          src={basicData?.getupdVTMemberBaseById.image || NoImageLarge}
          alt=""
          objectFit="cover"
          overlap={avatarOverlap}
        />
        <Box pl={24} backgroundColor="transparent">
          <Text variant="h1" color="darkBlue">
            {basicData?.getupdVTMemberBaseById.store_name}
          </Text>
        </Box>
      </Box>
      <PageBody bottom={89}>
        <ContentsCard>
          <SubMenu items={subMenuItems} defaultIndex={menuIndex || 0} />
        </ContentsCard>
      </PageBody>
    </Box>
  ) : (
    // feat_screen_09-01-01_start
    <Box height="100%">
      <StyleHeader>
        <StyleHeaderText>
          <MainContentTitle text="マイページ" />
        </StyleHeaderText>
      </StyleHeader>
      <Box display="flex" alignItems="center" backgroundColor={headerBackGroundColor} pa={16}>
        <Avatar
          width={80}
          height={60}
          src={basicData?.getupdVTMemberBaseById.image || NoImageLarge}
          alt=""
          objectFit="cover"
          overlap={avatarOverlap}
        />
        <Box pl={16} backgroundColor="transparent">
          <Text variant="body14" color="darkBlue" bold>
            {basicData?.getupdVTMemberBaseById.store_name}
          </Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" px={16}>
        <PageBody bottom={290}>
          <ContentsCard>
            <Dropdown items={subMenuItems} tab={tab} setTab={setTab} fullWidth/>
          </ContentsCard>
        </PageBody>
      </Box>
    </Box>
    // feat_screen_09-01-01_end
  );
}

export default S09;
