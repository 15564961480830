import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import React from 'react';
import { format as formatDate } from 'date-fns';
import useIsMobile from 'hooks/responsive/useIsMobile';

/**
 * Figma ID
 * 09-05-01
 * 退会済
 */

function AlreadyCanceledScreen(props: { requestedQuitDate: string; expectedQuitDate: string }) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { requestedQuitDate, expectedQuitDate } = props;

  // feat screen 09-05-01 start
  return !isMobile ? (
    <Box px={16} py={18} width="100%">
      <Box>
        <Box mx={8} mb={8} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Text variant="h2" color="darkBlue">
              退会
            </Text>
          </Box>
          <Box display="flex" gap={8}>
            <Box display="flex" alignItems="center" px={8} gap={8} border="1px solid #5F797D" borderRadius={2}>
              <Text variant="caption12" color="darkBlue">
                退会手続日
              </Text>
              <Text variant="h2" color="darkBlue">
                {formatDate(new Date(requestedQuitDate), 'yyyy年MM月dd日')}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" px={8} gap={8} border="1px solid #5F797D" borderRadius={2}>
              <Text variant="caption12" color="darkBlue">
                退会予定日
              </Text>
              <Text variant="h2" color="darkBlue">
                {formatDate(new Date(expectedQuitDate), 'yyyy年MM月dd日')}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box mx={8} my={16}>
          <Divider option="horizontal" />
        </Box>
        <Box ma={8}>
          <Text variant="body14">退会手続きが完了いたしました。</Text>
        </Box>
      </Box>
    </Box>
  ) : (
    // Resposive Component
    <Box px={16} width="100%">
      <Box>
        <Box display="flex" flexDirection="column">
          <Box py={16} pb={18}>
            <Text align="center" variant="h2" color="darkBlue">
              退会
            </Text>
          </Box>
          <Box display="flex" gap={8} flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="center" py={5} px={8} gap={8} border="1px solid #5F797D" borderRadius={4}>
              <Text display="block" variant="body14" color="darkBlue">
                退会手続日
              </Text>
              <Text display="block" variant="body14" color="darkBlue" bold>
                {formatDate(new Date(requestedQuitDate), 'yyyy年MM月dd日')}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" py={5} px={8} gap={8} border="1px solid #5F797D" borderRadius={4}>
              <Text display="block" variant="body14">
                退会予定日
              </Text>
              <Text display="block" variant="body14" color="darkBlue" bold>
                {formatDate(new Date(expectedQuitDate), 'yyyy年MM月dd日')}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box py={18} pt={13}>
          <Divider option="horizontal" />
        </Box>
        <Box>
          <Text display="block" variant="body14">退会手続きが完了いたしました。</Text>
        </Box>
      </Box>
    </Box>
    // feat screen 09-05-01 end
  );
}

export default AlreadyCanceledScreen;
