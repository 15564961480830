/**
 * Figma ID: 04-01-03-09, 04-01-03-10, 04-01-03-11
 * 名称: 募集情報新規作成 - プレビュー（モーダル）
 */
import React, { useContext, useEffect } from 'react';
import { VtArbeitOfferInfo } from 'graphql/graphql-mw';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Tab } from 'components/newTab';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Dropdown } from 'components/dropdown';
import { MainButton } from 'components/mainButton';
import { DailyPreviewInfo } from './dailyPrevewInfo';
import { WeeklyPreviewInfo } from './weeklyPreviewInfo';
import { WeeklyPreviewList } from './weeklyPreviewList';
import { ScheduleType } from '../../common/scheduleTypeContext';
import { WeeklyOfferNumberContext } from '../../common/weeklyOfferNumberContext';
import { FormData } from '../../common/formState';
import { getDatesBetween, filterByDayOfWeek, generateWeeklyShiftList } from '../../common/utils';

type Props = {
  fixedFormData?: FormData;
  basicInfoData?: VtArbeitOfferInfo;
  previewPdf: (shiftDate: string) => void;
  // feat screen 04-01-01-07 start
  onClickPreview?: () => void;
  // feat screen 04-01-01-07 end
};

type WeekData = {
  date: string;
  dayOfWeek: string;
};

type MappedData = {
  id: number;
  label: string;
  date: string;
};
// feat screen 04-01-01-07 add props onClickPreview start
export function ModalNewPreview({ fixedFormData, basicInfoData, previewPdf, onClickPreview }: Props) {
// feat screen 04-01-01-07 add props onClickPreview end
  
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  /* feat_screen_03-01_start */
  const [tab, setTab] = React.useState<number>(0);
  /* feat_screen_03-01_end */
  const context = useContext(WeeklyOfferNumberContext);
  if (!context) {
    throw new Error('Counter must be used within a CountProvider');
  }
  const { dispatch } = context;

  /**
   * 週定期日程作成
   */

  const start: Date = new Date(fixedFormData?.repeat_start_date as string);
  const end: Date = new Date(fixedFormData?.repeat_end_date as string);
  const datesArray: WeekData[] = getDatesBetween(start, end);
  const week: string[] = [];
  if (fixedFormData?.monday_flag) {
    week.push('月');
  }
  if (fixedFormData?.tuesday_flag) {
    week.push('火');
  }
  if (fixedFormData?.wednesday_flag) {
    week.push('水');
  }
  if (fixedFormData?.thursday_flag) {
    week.push('木');
  }
  if (fixedFormData?.friday_flag) {
    week.push('金');
  }
  if (fixedFormData?.saturday_flag) {
    week.push('土');
  }
  if (fixedFormData?.sunday_flag) {
    week.push('日');
  }
  const filteredWeeks: WeekData[] = filterByDayOfWeek(datesArray, week);
  const mappedWeeks: MappedData[] = generateWeeklyShiftList(fixedFormData as FormData, filteredWeeks);

  /**
   * 配信予定件数を親コンポーネントに渡す
   */
  useEffect(() => {
    dispatch({ type: 'SET_OFFER_NUMBER', content: mappedWeeks.length });
  }, [mappedWeeks.length, dispatch]);

  // feat screen 04-01-01-07 start
  return !isMobile ? (
    <Box>
      {Number(fixedFormData?.check_schedule_type) === ScheduleType.DAILY ? (
        <Box pr={8} pl={8}>
          <Box mb={18}>
            <Text variant="body14">募集情報を確認して、作成を押してください。</Text>
          </Box>
          <DailyPreviewInfo fixedFormData={fixedFormData} basicInfoData={basicInfoData} />
        </Box>
      ) : (
        <Box pr={8} pl={8}>
          <Box mb={18}>
            <Text variant="body14">募集情報を確認して、作成を押してください。</Text>
          </Box>
          <Tab
            items={[
              {
                content: <WeeklyPreviewInfo fixedFormData={fixedFormData} />,
                label: '募集情報',
              },
              {
                content: (
                  <WeeklyPreviewList mappedWeeks={mappedWeeks} previewPdf={(shiftDate) => previewPdf(shiftDate)} />
                ),
                label: 'シフト日一覧',
              },
            ]}
          />
        </Box>
      )}
    </Box>
  ) : (
    // Responsive Component
    <Box>
      {Number(fixedFormData?.check_schedule_type) === ScheduleType.DAILY ? (
        <Box>
          <Box display='flex' flexDirection='column' mb={16}>
            <Box display='flex' justifyContent='center' pb={24}>
              <MainButton onClick={() => onClickPreview?.()} variant="secondary" width={212}>
                労働条件通知書プレビュー
              </MainButton>
            </Box>
            <Text variant="body14">募集情報を確認して、作成を押してください。</Text>
          </Box>
          <Box mt={-2}>
            <DailyPreviewInfo fixedFormData={fixedFormData} basicInfoData={basicInfoData} />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb={16}>
            <Text display="block" variant="body14">募集情報を確認して、作成を押してください。</Text>
          </Box>
          <Dropdown 
            tab={tab} 
            setTab={setTab}
            fullWidth
            items={[
              {
                content: <WeeklyPreviewInfo fixedFormData={fixedFormData} />,
                label: '募集情報',
              },
              {
                content: (
                  <WeeklyPreviewList mappedWeeks={mappedWeeks} previewPdf={(shiftDate) => previewPdf(shiftDate)} />
                ),
                label: 'シフト日一覧',
              },
            ]}
          />
        </Box>
      )}
    </Box>
      // feat screen 04-01-01-07 end
  );
}
