import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Text } from '../text/text';

export const chipColors = {
  blue: '#00A5BF',
  okBlue: '#3170C8',
  darkBlue: '#16495F',
  redLite: '#F35555',
  cautionRed: '#E50000',
  warningYellow: '#E8A700',
  green: '#35A554',
  liteGray: '#969696',
  blueGray: '#5F797D',
} as const;

type ChipColorKeyType = keyof typeof chipColors;

type VariantType = 'normal' | 'big';

export interface Props {
  children: ReactNode;
  color: ChipColorKeyType;
  variant?: VariantType;
  width?: number;
  // feat_common_responsive_chip_add_props_start
  minWidth?: number;
  height?: number;
  // feat_common_responsive_chip_add_props_end
}

const StyledDiv = styled.div<{
  width: number;
  height: number;
  color: string;
  borderRadius: number;
  // feat_common_responsive_chip_add_props_start
  minWidth?: number;
  // feat_common_responsive_chip_add_props_end
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0;
  background: ${({ color }) => color};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  box-sizing: border-box;
  /* feat common_styled_chip_start */
  @media only screen and (max-width: 768px) {
    min-width: 56px;
    padding: ${({ width }) => width === 108 ? "0 16px" : 0}; 
    width: ${({ width }) => width !== 108 ? `${width}px` : "fit-content"};
    min-width: ${({ minWidth }) => minWidth ? `${minWidth}px` : "auto"};
  }
/* feat common_styled_chip_end */
`;

export function Chip({ 
  /* feat common_styled_chip_start */
  minWidth,
  height,
  /* feat common_styled_chip_end */
  children, color, variant = 'normal', width}: Props) {
  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  if (variant === 'big') {
    return (
      /* feat_common_responsive_chip_start */
      <StyledDiv minWidth={minWidth ?? 0} width={width ?? 108} height={height ?? 32} color={chipColors[color]} borderRadius={2}>
      {/* /* feat_common_responsive_chip_end */ }
        {/* feat_common_chip_styled_mobile start */}
        <Text variant={isMobile ? "body16": "body14"} wordBreak={isMobile ? "keep-all": "normal"} color="white" bold>
          {/* feat_common_chip_styled_mobile end */}
          {children}
        </Text>
      </StyledDiv>
    );
  }
  return (
    /* feat_common_responsive_chip_start */
    <StyledDiv width={width ?? 56} height={height ?? 16} color={chipColors[color]} borderRadius={1}>
    {/* feat_common_responsive_chip_end */}
      <Text variant="chip10" color="white" bold>
        {children}
      </Text>
    </StyledDiv>
  );
}
