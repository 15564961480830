import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { TextLink } from 'components/textLink';
import { Text } from 'components/text';
import useIsMobile from 'hooks/responsive/useIsMobile';
import React from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormType) => void;
  errorMessages?: string;
  newEmail: string;
  toBack: () => void;
}

interface FormType {
  code: string;
}

function ConfirmCodeModal(props: Props) {
  const { isOpen, onClose, onSubmit, errorMessages, newEmail, toBack } = props;

  const { handleSubmit, control, setValue, watch } = useForm<FormType>();

  /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */

  const currentCode = watch('code');

  function clearForm() {
    setValue('code', '');
  }

  function onModalClose() {
    clearForm();
    onClose();
  }

  function submit() {
    handleSubmit((data) => {
      onSubmit(data);
      clearForm();
    })();
  }

  const header = (
    <Text variant="h2" color="darkBlue">
      編集
    </Text>
  );

  const content = !isMobile ? (
    <Box pt={12} pb={20} pl={12}>
      <Box>
        <Box>
          <Text variant="body14" color="primaryBlue">
            {newEmail} に送信しました
          </Text>
        </Box>
        <Box>
          <Text variant="body14">メールに記載された確認コードを入力してください。</Text>
        </Box>
        <Box mt={24}>
          <RhfInput name="code" control={control} width={328} placeholder="6桁のコードを入力" defaultValue="" />
          {errorMessages && (
            <Text variant="caption12" color="cautionRed">
              {errorMessages}
            </Text>
          )}
        </Box>
        <Box mt={24}>
          <TextLink onClick={() => toBack()}>確認コードを再送</TextLink>
        </Box>
      </Box>
    </Box>
  ) : (
    // feat_screen_09-03-03_start
    <Box display="flex" flexDirection="column" gap={12}>
      <Box>
        <Text variant="body14" color="primaryBlue">
          {newEmail} に送信しました
        </Text>
      </Box>
      <Text display="block" variant="body14">メールに記載された確認コードを入力してください。</Text>
      <RhfInput 
        name="code" 
        control={control} 
        fullWidth
        placeholder="6桁のコードを入力" 
        defaultValue=""
      />
      {errorMessages && (
        <Text variant="caption12" color="cautionRed">
          {errorMessages}
        </Text>
      )}
      <Box display='flex' justifyContent='center'>
        <TextLink onClick={() => toBack()}>確認コードを再送</TextLink>
      </Box>
    </Box>
    // feat_screen_09-03-03_end
  );

  const footer = (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton
        // feat_screen_09-03-03_start
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-03-03_end
        variant="secondary" 
        onClick={() => onModalClose()}
      >
        キャンセル
      </MainButton>
      <MainButton 
        // feat_screen_09-03-03_start        
        width={!isMobile ? 104 : 96} 
        thin={!isMobile}
        // feat_screen_09-03-03_end
        onClick={() => submit()} 
        disabled={currentCode?.length !== 6}
      >
        認証
      </MainButton>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose()}
      header={header}
      content={content}
      footer={footer}
      height="auto"
      // feat_screen_09-03-03_start
      width={!isMobile ? 800 : "100%"}
      // feat_screen_09-03-03_end
    />
  );
}

export default ConfirmCodeModal;
